
export enum AttrFlag{
    Default = 0,
    Invisible = 1, //does not appear
    Constan = 2,
    Verification = 3,
    Preset = 4, //(no prompt during inserti

}

export enum BlockTypeFlag{
    SimpleBlock = 0, 
    AnonymousBlock = 1,
    BlockHasAttributes = 2,
    BlockIsExref = 4,
    NotUsed = 8,
    ExternallyDependent = 16,
    ResolvedExref = 32,
    DefinitionReferenced = 64
}

export enum HorizontalJustification{
    Left = 0,
    Center = 1,
    Right = 2
}

export enum VerticalJustification{
    Top = 0,
    Middle = 1,
    Bottom = 2,
    Baseline = 3
}

export enum LengthUnits{

Scientific = 1,
Decimal,
Engineering,
Architectural,
Fractional,

}

export enum InsertUnits {
    Unitless = 0,
    Inches,
    Feet,
    Miles,
    Millimeters,
    Centimeters,
    Meters,
    Kilometers,
    Microinches,
    Mils,
    Yards,
    Angstroms,
    Nanometers,
    Microns,
    Decimeters,
    Decameters,
    Hectometers,
    Gigameters,
    Astronomical_units,
    Light_years,
    Parsecs,
    US_Survey_Feet
}

export enum InsertUnitsForImage {
    None = 0,         
    Millimeter,   
    Centimeter,
    Meter,
    Kilometer,
    Inch,
    Foot,
    Yard,
    Mile
}

export enum LayerFlags {
    /** Layer is frozen */
    Frozen = 1,
    /** Layer is frozen by default in new viewports */
    FrozenInNewViewports = 2,
    /** Layer is locked */
    Locked = 4,
    /** Table entry is externally dependent on an xref */
    ExternallyDependent = 16,
    /** Externally dependent xref has been successfully resolved */
    XrefResolved = 32,
    /** Table entry was referenced by at least one entity in the drawing the last time the drawing was edited */
    ReferencedInLastEdit = 64,
}

export enum DxfLwPolylineFlag{
    closed=1,
    Plinegen = 128
}

export enum BlockTypeFlag {
    None = 0, // 0 = No flags
    Anonymous = 1, // 1 = Anonymous block
    NonConstantAttributes = 2, // 2 = Non-constant attributes
    ExternalReference = 4, // 4 = External reference (xref)
    XrefOverlay = 8, // 8 = Xref overlay
    ExternalDependent = 16, // 16 = Externally dependent
    ResolvedReference = 32, // 32 = Resolved external reference
    ReferencedExternal = 64, // 64 = Referenced external reference
}

export enum DuplicateRecordCloningFlag {
    NotApplicable = 0, // 0 = Not applicable
    KeepExisting = 1, // 1 = Keep existing
    UseClone = 2, // 2 = Use clone
    XrefName = 3, // 3 = <xref>$0$<name>
    ZeroName = 4, // 4 = $0$<name>
    UnmangleName = 5, // 5 = Unmangle name
}
