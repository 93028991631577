import { Vector2, Vector3 } from "math-ts";
import {
    Dxf3DFace,
    DxfAppId,
    DxfArc,
    DxfAttribute,
    DxfAttributeDefinition,
    DxfBlock,
    DxfBlockRecord,
    DxfDictionary,
    DxfDimStyle,
    DxfEndBlock,
    DxfEntity,
    DxfGeoData,
    DxfHatch,
    DxfImage,
    DxfImageDefinition,
    DxfImageDefReactor,
    DxfInsert,
    DxfLayer,
    DxfLayout,
    DxfLine,
    DxfLineType,
    DxfLwPolyline,
    DxfMText,
    DxfObject,
    DxfPlotSettings,
    DxfPolyline,
    DxfRasterVariables,
    DxfSortentsTable,
    DxfSpline,
    DxfSymbolTable,
    DxfText,
    DxfTextStyle,
    DxfVertex,
    DxfViewport,
    DxfVPort,
    DxfCircle,
} from "./DxfEntities";
import { IterUtils } from "engine-utils-ts";
import { HorizontalJustification, InsertUnitsForImage, VerticalJustification } from "./DxfEnums";

type FieldMapping = {
    code: number;
    defaultValue?: string | number;
    required?: boolean;
};

export type DxfDictionaryEntry = { entryName: string; entryHandle: string };
export type DxfScale = { numerator: number; denominator: number };
export type DxfRgb = { r: number; g: number; b: number };
export type DxfPropertyMap = Record<string, FieldMapping>;
export type DxfSerializedData = { code: number; value: string | number };
export type DxfMargin = {
    left: number;
    bottom: number;
    right: number;
    top: number;
};

export type DxfSerializationMap<T extends DxfObject> = Record<
    string,
    { serialize: (obj: T) => DxfSerializedData[] }
>;

export type DxfDeserializationMap<T extends DxfObject> = Record<
    number,
    string | ((obj: T, value: string | number) => void)
>;
export type DxfExtensionDictionary = { [appName: string]: string[] };

export function getFieldNameByCode(
    map: DxfPropertyMap | undefined,
    code: number
): string | undefined {
    if (!map) {
        return;
    }
    for (const [fieldName, fieldMapping] of Object.entries(map)) {
        if (fieldMapping.code === code) {
            return fieldName;
        }
    }
    return undefined;
}

function serializeXmlString(
    code: number,
    xmlString: string
): DxfSerializedData[] {
    const maxLength = 250;

    const result: DxfSerializedData[] = [];
    let currentSlice = "";
    
    if(xmlString.length< maxLength){
        result.push({code:301, value:xmlString})
        return result;
    }

    for (let i = 0; i < xmlString.length; i++) {
        currentSlice += xmlString[i];

        if(i === xmlString.length - 1){
            result.push({ code:301, value: currentSlice });
        }

        if (currentSlice.length >= maxLength) {
            result.push({ code:303, value: currentSlice });
            currentSlice = "";
        }
    }

    return result;
}

function serializeColor(codes: [number, number, number], color?: DxfRgb) {
    const serializedData: DxfSerializedData[] = [];
    if (color === undefined) {
        return serializedData;
    }

    serializedData.push({ code: codes[0], value: color.r });
    serializedData.push({ code: codes[1], value: color.g });
    serializedData.push({ code: codes[2], value: color.b });
    return serializedData;
}

function serializeScale(
    codes: [number, number],
    scale?: DxfScale
): DxfSerializedData[] {
    const serializedData: DxfSerializedData[] = [];
    if (!scale) {
        return serializedData;
    }
    serializedData.push({ code: codes[0], value: scale.denominator });
    serializedData.push({ code: codes[1], value: scale.numerator });
    return serializedData;
}

function serializeMargins(
    codes: [number, number, number, number],
    margin?: DxfMargin
): DxfSerializedData[] {
    const serializedData: DxfSerializedData[] = [];
    if (!margin) {
        return serializedData;
    }

    serializedData.push({ code: codes[0], value: margin.left });
    serializedData.push({ code: codes[1], value: margin.bottom });
    serializedData.push({ code: codes[2], value: margin.right });
    serializedData.push({ code: codes[3], value: margin.top });

    return serializedData;
}

function serializeSortentOrder(order:string[]):DxfSerializedData[]{
    const serializedOrder:DxfSerializedData[] = [];
    for (let i = 0; i < order.length; i += 2) {
        IterUtils.extendArray(serializedOrder, serializeProperty(331, order[i])); // Entity handle
        if (i + 1 < order.length) {
            IterUtils.extendArray(serializedOrder, serializeProperty(5, order[i + 1])); // Sort handle
        }
    }
    return serializedOrder;
}

export function serializeExtensionDictionary(
    dict?: DxfExtensionDictionary
): DxfSerializedData[] {
    const serializedData: DxfSerializedData[] = [];
    if (!dict) {
        return serializedData;
    }
    for (const [appName, handles] of Object.entries(dict)) {
        if(!handles.length){
            continue;
        }
        serializedData.push({ code: 102, value: `{${appName}` });
        for (const handle of handles) {
            serializedData.push({ code: 360, value: handle });
        }
        serializedData.push({ code: 102, value: "}" });
    }

    return serializedData;
}

function serializeVector(
    codes: number[],
    vec?: Vector3 | Vector2,
    defaultValue?: Vector3 | Vector2
): DxfSerializedData[] {
    const serializedData: DxfSerializedData[] = [];
    if (!vec) {
        return serializedData;
    }

    if (defaultValue) {
        if (vec instanceof Vector2 && defaultValue instanceof Vector2) {
            if (vec.equals(defaultValue)) {
                return serializedData;
            }
        } else if (vec instanceof Vector3 && defaultValue instanceof Vector3) {
            if (vec.equals(defaultValue)) {
                return serializedData;
            }
        } else {
            throw new Error(
                "Incompatible types for vector serialization: both `vec` and `defaultValue` must be of the same type (Vector2 or Vector3)."
            );
        }
    }

    const vectorData = vec.asArray();
    if (vectorData.length < codes.length) {
        throw new Error(
            `Insufficient group codes provided for vector serialization: expected ${vectorData.length} codes but got ${codes.length}.`
        );
    }

    for (let i = 0; i < codes.length; i++) {
        serializedData.push({ code: codes[i], value: vectorData[i] });
    }

    return serializedData;
}

function serializeProperty(
    code: number,
    value?: string | number,
    defaultValue?: string | number
): DxfSerializedData[] {
    const rule1 = value !== undefined;
    const rule2 =
        defaultValue === undefined || String(value) !== String(defaultValue);

    return rule1 && rule2 ? [{ code, value }] : [];
}

function deserializeXdict(entity: any, value: string) {
    const dict = entity.xDictionary as DxfExtensionDictionary | undefined;
    if (!dict) {
        return;
    }
    const keys = Object.keys(dict);
    if (keys.length === 0) {
        return;
    }
    const lastKey = keys[keys.length - 1];
    dict[lastKey].push(String(value));
}

function deserializeXdictKey(obj: any, value: string) {
    const appName = value.startsWith("{") ? value.slice(1) : undefined;
    const isClosing = value === "}";
    const dict = obj.xDictionary as DxfExtensionDictionary | undefined;

    if (!dict) {
        obj.xDictionary = {};
    }
    if (appName && obj.xDictionary) {
        obj.xDictionary[appName] = [];
    } else if (isClosing && dict) {
        return; // End of dictionary, no action needed
    }
}

//Tables

export class DxfSymbolTableProps {
    tableName: string = ""; // Group code 2 (Table name)
    handle: string = ""; // Group code 5 (Handle)
    xDictionary?: DxfExtensionDictionary; // Group code 102 (Start of extension dictionary, optional)
    ownerHandleSoftPointer?: string; // Group code 330 (Soft-pointer ID/handle to owner object)
    readonly subclassMarkerSymbolTable = "AcDbSymbolTable"; // Group code 100 (Subclass marker for AcDbSymbolTable)
    maximumEntriesInTable?: number; // Group code 70 (Maximum number of entries in table, optional)
    


    static deserializationMap: DxfDeserializationMap<DxfSymbolTable> = {
        2: (obj, value) => (obj.tableName = String(value)),
        5: (obj, value) => (obj.handle = String(value)),
        102: (obj, value) => {},
        330: (obj, value) => (obj.ownerHandleSoftPointer = String(value)),
        100: (obj, value) => {},
        70: (obj, value) => (obj.maximumEntriesInTable = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfSymbolTable> = {
        tableName: {
            serialize: (obj) => serializeProperty(2, obj.tableName),
        },
        handle: {
            serialize: (obj) => serializeProperty(5, obj.handle),
        },
        xDictionary: {
            serialize: (obj) =>
                serializeExtensionDictionary(obj.xDictionary),
        },
        ownerDictionaryHandle: {
            serialize: (obj) =>
                serializeProperty(360, obj.ownerDictionaryHandle),
        },
        ownerHandleSoftPointer: {
            serialize: (obj) =>
                serializeProperty(330, obj.ownerHandleSoftPointer),
        },
        subclassMarker: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerSymbolTable),
        },
        maximumEntriesInTable: {
            serialize: (obj) =>{
                const data:DxfSerializedData[] = []
                IterUtils.extendArray(data, serializeProperty(70, obj.maximumEntriesInTable));
                if(obj.tableName === "DIMSTYLE"){
                    IterUtils.extendArray(data, serializeProperty(100, "AcDbDimStyleTable"));
                    IterUtils.extendArray(data, serializeProperty(71, 0));
                }
                return data;
            }
                
        },
    };
}

//Table Entries
export class DxfSymbolTableEntryProps {

    handle: string = ""; // Code 5 (all except DIMSTYLE)

    xDictionary: DxfExtensionDictionary = {"ACAD_XDICTIONARY":[]}; // Custom type for extension dictionary
    reactorsDictionary: DxfExtensionDictionary = {"ACAD_REACTORS":[]}; // Custom type for reactors dictionary
    
    ownerDictionaryPointer?: string; // Code 330 (optional)

    ownerDictionaryHardPointer?: string; // Code 360 (optional)
    ownerObjectPointer?: string; // Code 330 (optional)
    readonly subclassMarkerSymbolTableEntry = "AcDbSymbolTableRecord"; // Code 100

    // Method to generate the map for serialization/deserialization
    static serializationMap: DxfSerializationMap<any> = {
        entityName: {
            serialize: (obj) =>
                obj.entityName ? [{ code: -1, value: obj.entityName }] : [],
        },
        handle: {
            serialize: (obj) => {
                if(obj instanceof DxfDimStyle){
                    return [{ code: 105, value: obj.handle }];
                }else{
                    return [{ code: 5, value: obj.handle }];
                }
            }

        },
        xDictionary:{
            serialize:(obj) => serializeExtensionDictionary(obj.xDictionary)
        },
        reactorsDictionary:{
            serialize:(obj) => serializeExtensionDictionary(obj.reactorsDictionary)
        },
        ownerDictionaryPointer: {
            serialize: (obj) =>
                obj.ownerDictionaryPointer
                    ? [{ code: 330, value: obj.ownerDictionaryPointer }]
                    : [],
        },
        ownerDictionaryHardPointer: {
            serialize: (obj) =>
                obj.ownerDictionaryHardPointer
                    ? [{ code: 360, value: obj.ownerDictionaryHardPointer }]
                    : [],
        },
        ownerObjectPointer: {
            serialize: (obj) =>
                obj.ownerObjectPointer
                    ? [{ code: 330, value: obj.ownerObjectPointer }]
                    : [],
        },
        subclassMarkerSymbolTableEntry: {
            serialize: (obj) => [
                {
                    code: 100,
                    value: obj.subclassMarkerSymbolTableEntry,
                },
            ],
        },
    };

    static deserializationMap: DxfDeserializationMap<any> = {
        5: "handle",
        105: "dimstyleHandle",
        330: (entity, value) => {
            // Handle multiple possible owners (based on the context of use)
            if (!entity.ownerDictionaryPointer && !entity.ownerObjectPointer) {
                entity.ownerDictionaryPointer = value;
            } else {
                entity.ownerObjectPointer = value;
            }
        },
        360: "ownerDictionaryHardPointer",
        100: "subclassMarkerSymbolTableEntry",
    };
}

export class DxfBlockRecordProps {
    // Fields in the exact order of the group codes
    readonly subclassMarkerBlockTableRecord = "AcDbBlockTableRecord"; // Code 100
    blockName: string = ""; // Code 2
    layoutHandle?: string; // Code 340 (optional)
    blockInsertionUnits?: number; // Code 70 (optional)
    blockExplodability?: number; // Code 280 (optional)
    blockScalability?: number; // Code 281 (optional)
    bitmapPreview?: string; // Code 310 (Binary data, optional)
    designCenterVersionNumber?: number; // Code 1070 (optional)
    insertUnits?: number; // Code 1070 (optional)

    static serializationMap: DxfSerializationMap<DxfBlockRecord> = {
        subclassMarkerBlockTableRecord: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerBlockTableRecord),
        },
        blockName: {
            serialize: (obj) => serializeProperty(2, obj.blockName),
        },
        layoutHandle: {
            serialize: (obj) => serializeProperty(340, obj.layoutHandle),
        },
        blockInsertionUnits: {
            serialize: (obj) => serializeProperty(70, obj.blockInsertionUnits),
        },
        blockExplodability: {
            serialize: (obj) => serializeProperty(280, obj.blockExplodability),
        },
        blockScalability: {
            serialize: (obj) => serializeProperty(281, obj.blockScalability),
        },
        bitmapPreview: {
            serialize: (obj) => serializeProperty(310, obj.bitmapPreview),
        },
        designCenterVersionNumber: {
            serialize: (obj) =>
                serializeProperty(1070, obj.designCenterVersionNumber),
        },
        insertUnits: {
            serialize: (obj) => serializeProperty(1070, obj.insertUnits),
        },
    };

    static deserializationMap: DxfDeserializationMap<any> = {
        100: "subclassMarkerBlockTableRecord",
        2: "blockName",
        340: "layoutHandle",
        70: (entity, value) => (entity.blockInsertionUnits = Number(value)),
        280: (entity, value) => (entity.blockExplodability = Number(value)),
        281: (entity, value) => (entity.blockScalability = Number(value)),
        310: "bitmapPreview",
        1070: (entity, value) => {
            // Handle based on context, either `designCenterVersionNumber` or `insertUnits`
            if (entity.designCenterVersionNumber === undefined) {
                entity.designCenterVersionNumber = Number(value);
            } else {
                entity.insertUnits = Number(value);
            }
        },
    };
}

export class DxfDimStyleProps {
    // Fields in the exact order of the group codes
    readonly subclassMarkerDimStyle = "AcDbDimStyleTableRecord"; // Code 100
    dimensionStyleName: string = ""; // Code 2
    standardFlags: number = 0; // Code 70
    dimpost?: string; // Code 3
    dimapost?: string; // Code 4
    dimblk?: string; // Code 5 (obsolete)
    dimblk1?: string; // Code 6 (obsolete)
    dimblk2?: string; // Code 7 (obsolete)
    dimscale?: number; // Code 40
    dimasz?: number; // Code 41
    dimexo?: number; // Code 42
    dimdli?: number; // Code 43
    dimexe?: number; // Code 44
    dimrnd?: number; // Code 45
    dimdle?: number; // Code 46
    dimtp?: number; // Code 47
    dimtm?: number; // Code 48
    dimtxt?: number; // Code 140
    dimcen?: number; // Code 141
    dimtsz?: number; // Code 142
    dimaltf?: number; // Code 143
    dimlfac?: number; // Code 144
    dimtvp?: number; // Code 145
    dimtfac?: number; // Code 146
    dimgap?: number; // Code 147
    dimaltrnd?: number; // Code 148
    dimtol?: number; // Code 71
    dimlim?: number; // Code 72
    dimtih?: number; // Code 73
    dimtoh?: number; // Code 74
    dimse1?: number; // Code 75
    dimse2?: number; // Code 76
    dimtad?: number; // Code 77
    dimzin?: number; // Code 78
    dimazin?: number; // Code 79
    dimalt?: number; // Code 170
    dimaltd?: number; // Code 171
    dimtofl?: number; // Code 172
    dimsah?: number; // Code 173
    dimtix?: number; // Code 174
    dimsoxd?: number; // Code 175
    dimclrd?: number; // Code 176
    dimclre?: number; // Code 177
    dimclrt?: number; // Code 178
    dimadec?: number; // Code 179
    dimunit?: number; // Code 270 (obsolete)
    dimdec?: number; // Code 271
    dimtdec?: number; // Code 272
    dimaltu?: number; // Code 273
    dimalttd?: number; // Code 274
    dimaunit?: number; // Code 275
    dimfrac?: number; // Code 276
    dimlunit?: number; // Code 277
    dimdsep?: number; // Code 278
    dimtmove?: number; // Code 279
    dimjust?: number; // Code 280
    dimsd1?: number; // Code 281
    dimsd2?: number; // Code 282
    dimtolj?: number; // Code 283
    dimtzin?: number; // Code 284
    dimaltsz?: number; // Code 285
    dimalttz?: number; // Code 286
    dimfit?: number; // Code 287 (obsolete)
    dimupt?: number; // Code 288
    dimatfit?: number; // Code 289
    dimtxsty?: string; // Code 340 (handle of referenced STYLE)
    dimldrblk?: string; // Code 341 (handle of referenced BLOCK)
    dimblkHandle?: string; // Code 342 (handle of referenced BLOCK)
    dimblk1Handle?: string; // Code 343 (handle of referenced BLOCK)
    dimblk2Handle?: string; // Code 344 (handle of referenced BLOCK)
    dimlwd?: number; // Code 371 (lineweight enum value)
    dimlwe?: number; // Code 372 (lineweight enum value)

    static serializationMap: DxfSerializationMap<DxfDimStyle> = {
        subclassMarkerDimStyle: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerDimStyle),
        },
        dimensionStyleName: {
            serialize: (obj) => serializeProperty(2, obj.dimensionStyleName),
        },
        standardFlags: {
            serialize: (obj) => serializeProperty(70, obj.standardFlags),
        },
        dimpost: {
            serialize: (obj) => serializeProperty(3, obj.dimpost),
        },
        dimapost: {
            serialize: (obj) => serializeProperty(4, obj.dimapost),
        },
        dimblk: {
            serialize: (obj) => serializeProperty(5, obj.dimblk),
        },
        dimblk1: {
            serialize: (obj) => serializeProperty(6, obj.dimblk1),
        },
        dimblk2: {
            serialize: (obj) => serializeProperty(7, obj.dimblk2),
        },
        dimscale: {
            serialize: (obj) => serializeProperty(40, obj.dimscale),
        },
        dimasz: {
            serialize: (obj) => serializeProperty(41, obj.dimasz),
        },
        dimexo: {
            serialize: (obj) => serializeProperty(42, obj.dimexo),
        },
        dimdli: {
            serialize: (obj) => serializeProperty(43, obj.dimdli),
        },
        dimexe: {
            serialize: (obj) => serializeProperty(44, obj.dimexe),
        },
        dimrnd: {
            serialize: (obj) => serializeProperty(45, obj.dimrnd),
        },
        dimdle: {
            serialize: (obj) => serializeProperty(46, obj.dimdle),
        },
        dimtp: {
            serialize: (obj) => serializeProperty(47, obj.dimtp),
        },
        dimtm: {
            serialize: (obj) => serializeProperty(48, obj.dimtm),
        },
        dimtxt: {
            serialize: (obj) => serializeProperty(140, obj.dimtxt),
        },
        dimcen: {
            serialize: (obj) => serializeProperty(141, obj.dimcen),
        },
        dimtsz: {
            serialize: (obj) => serializeProperty(142, obj.dimtsz),
        },
        dimaltf: {
            serialize: (obj) => serializeProperty(143, obj.dimaltf),
        },
        dimlfac: {
            serialize: (obj) => serializeProperty(144, obj.dimlfac),
        },
        dimtvp: {
            serialize: (obj) => serializeProperty(145, obj.dimtvp),
        },
        dimtfac: {
            serialize: (obj) => serializeProperty(146, obj.dimtfac),
        },
        dimgap: {
            serialize: (obj) => serializeProperty(147, obj.dimgap),
        },
        dimaltrnd: {
            serialize: (obj) => serializeProperty(148, obj.dimaltrnd),
        },
        dimtol: {
            serialize: (obj) => serializeProperty(71, obj.dimtol),
        },
        dimlim: {
            serialize: (obj) => serializeProperty(72, obj.dimlim),
        },
        dimtih: {
            serialize: (obj) => serializeProperty(73, obj.dimtih),
        },
        dimtoh: {
            serialize: (obj) => serializeProperty(74, obj.dimtoh),
        },
        dimse1: {
            serialize: (obj) => serializeProperty(75, obj.dimse1),
        },
        dimse2: {
            serialize: (obj) => serializeProperty(76, obj.dimse2),
        },
        dimtad: {
            serialize: (obj) => serializeProperty(77, obj.dimtad),
        },
        dimzin: {
            serialize: (obj) => serializeProperty(78, obj.dimzin),
        },
        dimazin: {
            serialize: (obj) => serializeProperty(79, obj.dimazin),
        },
        dimalt: {
            serialize: (obj) => serializeProperty(170, obj.dimalt),
        },
        dimaltd: {
            serialize: (obj) => serializeProperty(171, obj.dimaltd),
        },
        dimtofl: {
            serialize: (obj) => serializeProperty(172, obj.dimtofl),
        },
        dimsah: {
            serialize: (obj) => serializeProperty(173, obj.dimsah),
        },
        dimtix: {
            serialize: (obj) => serializeProperty(174, obj.dimtix),
        },
        dimsoxd: {
            serialize: (obj) => serializeProperty(175, obj.dimsoxd),
        },
        dimclrd: {
            serialize: (obj) => serializeProperty(176, obj.dimclrd),
        },
        dimclre: {
            serialize: (obj) => serializeProperty(177, obj.dimclre),
        },
        dimclrt: {
            serialize: (obj) => serializeProperty(178, obj.dimclrt),
        },
        dimadec: {
            serialize: (obj) => serializeProperty(179, obj.dimadec),
        },
        dimunit: {
            serialize: (obj) => serializeProperty(270, obj.dimunit),
        },
        dimdec: {
            serialize: (obj) => serializeProperty(271, obj.dimdec),
        },
        dimtdec: {
            serialize: (obj) => serializeProperty(272, obj.dimtdec),
        },
        dimaltu: {
            serialize: (obj) => serializeProperty(273, obj.dimaltu),
        },
        dimalttd: {
            serialize: (obj) => serializeProperty(274, obj.dimalttd),
        },
        dimaunit: {
            serialize: (obj) => serializeProperty(275, obj.dimaunit),
        },
        dimfrac: {
            serialize: (obj) => serializeProperty(276, obj.dimfrac),
        },
        dimlunit: {
            serialize: (obj) => serializeProperty(277, obj.dimlunit),
        },
        dimdsep: {
            serialize: (obj) => serializeProperty(278, obj.dimdsep),
        },
        dimtmove: {
            serialize: (obj) => serializeProperty(279, obj.dimtmove),
        },
        dimjust: {
            serialize: (obj) => serializeProperty(280, obj.dimjust),
        },
        dimsd1: {
            serialize: (obj) => serializeProperty(281, obj.dimsd1),
        },
        dimsd2: {
            serialize: (obj) => serializeProperty(282, obj.dimsd2),
        },
        dimtolj: {
            serialize: (obj) => serializeProperty(283, obj.dimtolj),
        },
        dimtzin: {
            serialize: (obj) => serializeProperty(284, obj.dimtzin),
        },
        dimaltsz: {
            serialize: (obj) => serializeProperty(285, obj.dimaltsz),
        },
        dimalttz: {
            serialize: (obj) => serializeProperty(286, obj.dimalttz),
        },
        dimfit: {
            serialize: (obj) => serializeProperty(287, obj.dimfit),
        },
        dimupt: {
            serialize: (obj) => serializeProperty(288, obj.dimupt),
        },
        dimatfit: {
            serialize: (obj) => serializeProperty(289, obj.dimatfit),
        },
        dimtxsty: {
            serialize: (obj) => serializeProperty(340, obj.dimtxsty),
        },
        dimldrblk: {
            serialize: (obj) => serializeProperty(341, obj.dimldrblk),
        },
        dimblkHandle: {
            serialize: (obj) => serializeProperty(342, obj.dimblkHandle),
        },
        dimblk1Handle: {
            serialize: (obj) => serializeProperty(343, obj.dimblk1Handle),
        },
        dimblk2Handle: {
            serialize: (obj) => serializeProperty(344, obj.dimblk2Handle),
        },
        dimlwd: {
            serialize: (obj) => serializeProperty(371, obj.dimlwd),
        },
        dimlwe: {
            serialize: (obj) => serializeProperty(372, obj.dimlwe),
        },
    };

    static deserializationMap: DxfDeserializationMap<DxfDimStyle> = {
        100: "subclassMarkerDimStyle",
        2: "dimensionStyleName",
        70: (obj, value) => (obj.standardFlags = Number(value)),
        3: "dimpost",
        4: "dimapost",
        5: "dimblk",
        6: "dimblk1",
        7: "dimblk2",
        40: (obj, value) => (obj.dimscale = Number(value)),
        41: (obj, value) => (obj.dimasz = Number(value)),
        42: (obj, value) => (obj.dimexo = Number(value)),
        43: (obj, value) => (obj.dimdli = Number(value)),
        44: (obj, value) => (obj.dimexe = Number(value)),
        45: (obj, value) => (obj.dimrnd = Number(value)),
        46: (obj, value) => (obj.dimdle = Number(value)),
        47: (obj, value) => (obj.dimtp = Number(value)),
        48: (obj, value) => (obj.dimtm = Number(value)),
        140: (obj, value) => (obj.dimtxt = Number(value)),
        141: (obj, value) => (obj.dimcen = Number(value)),
        142: (obj, value) => (obj.dimtsz = Number(value)),
        143: (obj, value) => (obj.dimaltf = Number(value)),
        144: (obj, value) => (obj.dimlfac = Number(value)),
        145: (obj, value) => (obj.dimtvp = Number(value)),
        146: (obj, value) => (obj.dimtfac = Number(value)),
        147: (obj, value) => (obj.dimgap = Number(value)),
        148: (obj, value) => (obj.dimaltrnd = Number(value)),
        71: (obj, value) => (obj.dimtol = Number(value)),
        72: (obj, value) => (obj.dimlim = Number(value)),
        73: (obj, value) => (obj.dimtih = Number(value)),
        74: (obj, value) => (obj.dimtoh = Number(value)),
        75: (obj, value) => (obj.dimse1 = Number(value)),
        76: (obj, value) => (obj.dimse2 = Number(value)),
        77: (obj, value) => (obj.dimtad = Number(value)),
        78: (obj, value) => (obj.dimzin = Number(value)),
        79: (obj, value) => (obj.dimazin = Number(value)),
        170: (obj, value) => (obj.dimalt = Number(value)),
        171: (obj, value) => (obj.dimaltd = Number(value)),
        172: (obj, value) => (obj.dimtofl = Number(value)),
        173: (obj, value) => (obj.dimsah = Number(value)),
        174: (obj, value) => (obj.dimtix = Number(value)),
        175: (obj, value) => (obj.dimsoxd = Number(value)),
        176: (obj, value) => (obj.dimclrd = Number(value)),
        177: (obj, value) => (obj.dimclre = Number(value)),
        178: (obj, value) => (obj.dimclrt = Number(value)),
        179: (obj, value) => (obj.dimadec = Number(value)),
        270: (obj, value) => (obj.dimunit = Number(value)),
        271: (obj, value) => (obj.dimdec = Number(value)),
        272: (obj, value) => (obj.dimtdec = Number(value)),
        273: (obj, value) => (obj.dimaltu = Number(value)),
        274: (obj, value) => (obj.dimalttd = Number(value)),
        275: (obj, value) => (obj.dimaunit = Number(value)),
        276: (obj, value) => (obj.dimfrac = Number(value)),
        277: (obj, value) => (obj.dimlunit = Number(value)),
        278: (obj, value) => (obj.dimdsep = Number(value)),
        279: (obj, value) => (obj.dimtmove = Number(value)),
        280: (obj, value) => (obj.dimjust = Number(value)),
        281: (obj, value) => (obj.dimsd1 = Number(value)),
        282: (obj, value) => (obj.dimsd2 = Number(value)),
        283: (obj, value) => (obj.dimtolj = Number(value)),
        284: (obj, value) => (obj.dimtzin = Number(value)),
        285: (obj, value) => (obj.dimaltsz = Number(value)),
        286: (obj, value) => (obj.dimalttz = Number(value)),
        287: (obj, value) => (obj.dimfit = Number(value)),
        288: (obj, value) => (obj.dimupt = Number(value)),
        289: (obj, value) => (obj.dimatfit = Number(value)),
        340: "dimtxsty",
        341: "dimldrblk",
        342: "dimblkHandle",
        343: "dimblk1Handle",
        344: "dimblk2Handle",
        371: (obj, value) => (obj.dimlwd = Number(value)),
        372: (obj, value) => (obj.dimlwe = Number(value)),
    };
}

export class DxfTextStyleProps {
    // Properties corresponding to the group codes
    readonly subclassMarkerTextStyle = "AcDbTextStyleTableRecord"; // Code 100
    styleName: string = "Arial"; // Code 2
    standardFlags: number = 0; // Code 70 (bit-coded values)
    fixedTextHeight?: number; // Code 40 (0 if not fixed)
    widthFactor?: number; // Code 41
    obliqueAngle?: number; // Code 50
    textGenerationFlags?: number; // Code 71
    lastHeightUsed?: number; // Code 42
    primaryFontFileName?: string; // Code 3
    bigFontFileName?: string; // Code 4
    truetypeFontAttributes?: string; // Code 1071

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfTextStyle> = {
        subclassMarkerTextStyle: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerTextStyle),
        },
        styleName: {
            serialize: (obj) => serializeProperty(2, obj.styleName),
        },
        standardFlags: {
            serialize: (obj) => serializeProperty(70, obj.standardFlags),
        },
        fixedTextHeight: {
            serialize: (obj) => serializeProperty(40, obj.fixedTextHeight),
        },
        widthFactor: {
            serialize: (obj) => serializeProperty(41, obj.widthFactor),
        },
        obliqueAngle: {
            serialize: (obj) => serializeProperty(50, obj.obliqueAngle),
        },
        textGenerationFlags: {
            serialize: (obj) => serializeProperty(71, obj.textGenerationFlags),
        },
        lastHeightUsed: {
            serialize: (obj) => serializeProperty(42, obj.lastHeightUsed),
        },
        primaryFontFileName: {
            serialize: (obj) => serializeProperty(3, obj.primaryFontFileName),
        },
        bigFontFileName: {
            serialize: (obj) => serializeProperty(4, obj.bigFontFileName),
        },
        truetypeFontAttributes: {
            serialize: (obj) =>
                serializeProperty(1071, obj.truetypeFontAttributes),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfTextStyle> = {
        100: "subclassMarkerTextStyle",
        2: "styleName",
        70: (obj, value) => (obj.standardFlags = Number(value)),
        40: (obj, value) => (obj.fixedTextHeight = Number(value)),
        41: (obj, value) => (obj.widthFactor = Number(value)),
        50: (obj, value) => (obj.obliqueAngle = Number(value)),
        71: (obj, value) => (obj.textGenerationFlags = Number(value)),
        42: (obj, value) => (obj.lastHeightUsed = Number(value)),
        3: "primaryFontFileName",
        4: "bigFontFileName",
        1071: "truetypeFontAttributes",
    };
}

export class DxfLineTypeProps {
    // Properties corresponding to the group codes
    readonly subclassMarkerLineStyle = "AcDbLinetypeTableRecord"; // Code 100
    lineTypeName: string = ""; // Code 2
    standardFlags: number = 0; // Code 70 (bit-coded values)
    description: string = ""; // Code 3
    alignmentCode: number = 65; // Code 72 (always 65, ASCII for 'A')
    numberOfElements: number = 0; // Code 73
    totalPatternLength: number = 0.0; // Code 40
    elementLengths?: number[]; // Code 49 (one entry per element)
    elementTypes?: number[]; // Code 74 (one entry per element)
    shapeNumbers?: number[]; // Code 75 (one entry per element if applicable)
    stylePointers?: string[]; // Code 340 (pointer to STYLE object, one per element)
    scaleValues?: number[]; // Code 46 (optional; multiple entries)
    rotationValues?: number[]; // Code 50 (rotation value in radians, one per element)
    xOffsets?: number[]; // Code 44 (X offset value; multiple entries)
    yOffsets?: number[]; // Code 45 (Y offset value; multiple entries)
    textStrings?: string[]; // Code 9 (text string; one per element if element type is 2)

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfLineType> = {
        subclassMarkerLineStyle: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerLineStyle),
        },
        lineTypeName: {
            serialize: (obj) => serializeProperty(2, obj.lineTypeName),
        },
        standardFlags: {
            serialize: (obj) => serializeProperty(70, obj.standardFlags),
        },
        description: {
            serialize: (obj) => serializeProperty(3, obj.description),
        },
        alignmentCode: {
            serialize: (obj) => serializeProperty(72, obj.alignmentCode),
        },
        numberOfElements: {
            serialize: (obj) => serializeProperty(73, obj.numberOfElements),
        },
        totalPatternLength: {
            serialize: (obj) => serializeProperty(40, obj.totalPatternLength),
        },
        elementLengths: {
            serialize: (obj) =>
                obj.elementLengths
                    ? obj.elementLengths.flatMap((length) =>
                          serializeProperty(49, length)
                      )
                    : [],
        },
        elementTypes: {
            serialize: (obj) =>
                obj.elementTypes
                    ? obj.elementTypes.flatMap((type) =>
                          serializeProperty(74, type)
                      )
                    : [],
        },
        shapeNumbers: {
            serialize: (obj) =>
                obj.shapeNumbers
                    ? obj.shapeNumbers.flatMap((shape) =>
                          serializeProperty(75, shape)
                      )
                    : [],
        },
        stylePointers: {
            serialize: (obj) =>
                obj.stylePointers
                    ? obj.stylePointers.flatMap((pointer) =>
                          serializeProperty(340, pointer)
                      )
                    : [],
        },
        scaleValues: {
            serialize: (obj) =>
                obj.scaleValues
                    ? obj.scaleValues.flatMap((scale) =>
                          serializeProperty(46, scale)
                      )
                    : [],
        },
        rotationValues: {
            serialize: (obj) =>
                obj.rotationValues
                    ? obj.rotationValues.flatMap((rotation) =>
                          serializeProperty(50, rotation)
                      )
                    : [],
        },
        xOffsets: {
            serialize: (obj) =>
                obj.xOffsets
                    ? obj.xOffsets.flatMap((xOffset) =>
                          serializeProperty(44, xOffset)
                      )
                    : [],
        },
        yOffsets: {
            serialize: (obj) =>
                obj.yOffsets
                    ? obj.yOffsets.flatMap((yOffset) =>
                          serializeProperty(45, yOffset)
                      )
                    : [],
        },
        textStrings: {
            serialize: (obj) =>
                obj.textStrings
                    ? obj.textStrings.flatMap((text) =>
                          serializeProperty(9, text)
                      )
                    : [],
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfLineType> = {
        100: "subclassMarkerLineStyle",
        2: "lineTypeName",
        70: (obj, value) => (obj.standardFlags = Number(value)),
        3: "description",
        72: (obj, value) => (obj.alignmentCode = Number(value)),
        73: (obj, value) => (obj.numberOfElements = Number(value)),
        40: (obj, value) => (obj.totalPatternLength = Number(value)),
        49: (obj, value) => {
            if (!obj.elementLengths) obj.elementLengths = [];
            obj.elementLengths.push(Number(value));
        },
        74: (obj, value) => {
            if (!obj.elementTypes) obj.elementTypes = [];
            obj.elementTypes.push(Number(value));
        },
        75: (obj, value) => {
            if (!obj.shapeNumbers) obj.shapeNumbers = [];
            obj.shapeNumbers.push(Number(value));
        },
        340: (obj, value) => {
            if (!obj.stylePointers) obj.stylePointers = [];
            obj.stylePointers.push(value.toString());
        },
        46: (obj, value) => {
            if (!obj.scaleValues) obj.scaleValues = [];
            obj.scaleValues.push(Number(value));
        },
        50: (obj, value) => {
            if (!obj.rotationValues) obj.rotationValues = [];
            obj.rotationValues.push(Number(value));
        },
        44: (obj, value) => {
            if (!obj.xOffsets) obj.xOffsets = [];
            obj.xOffsets.push(Number(value));
        },
        45: (obj, value) => {
            if (!obj.yOffsets) obj.yOffsets = [];
            obj.yOffsets.push(Number(value));
        },
        9: (obj, value) => {
            if (!obj.textStrings) obj.textStrings = [];
            obj.textStrings.push(value.toString());
        },
    };
}

export class DxfVPortProps {
    // Properties corresponding to the group codes
    readonly subclassMarkerVPort = "AcDbViewportTableRecord"; // Code 100
    viewportName: string = ""; // Code 2
    standardFlags: number = 0; // Code 70 (bit-coded values)
    lowerLeftCorner?: Vector2; // Codes 10, 20
    upperRightCorner?: Vector2; // Codes 11, 21
    viewCenter?: Vector2; // Codes 12, 22
    snapBase?: Vector2; // Codes 13, 23
    snapSpacing?: Vector2; // Codes 14, 24
    gridSpacing?: Vector2; // Codes 15, 25
    viewDirection?: Vector3; // Codes 16, 26, 36
    viewTarget?: Vector3; // Codes 17, 27, 37

    viewHeight?: number; // Code 40
    viewAspectRatio?: number; //code 41

    lensLength?: number; // Code 42
    frontClippingPlane?: number; // Code 43
    backClippingPlane?: number; // Code 44
    snapRotationAngle?: number; // Code 50
    viewTwistAngle?: number; // Code 51
    circleSides?: number; // Code 72
    frozenLayersHandles?: string[]; // Codes 331, 441
    plotStyleSheet?: string; // Code 1
    renderMode?: number; // Code 281
    viewMode?: number; // Code 71
    ucsIconSetting?: number; // Code 74
    ucsOrigin?: Vector3; // Codes 110, 120, 130
    ucsXAxis?: Vector3; // Codes 111, 121, 131
    ucsYAxis?: Vector3; // Codes 112, 122, 132
    ucsTableRecordHandle?: string; // Code 345
    baseUcsTableRecordHandle?: string; // Code 346
    orthographicUcsType?: number; // 79: Orthographic type of UCS (0-6)
    elevation?: number; // 146: Elevation value
    shadePlotSetting?: number; // 170: Shade plot setting
    majorGridLines?: number; // 61: Major grid lines
    backgroundObjectHandle?: string; // 332: Soft-pointer ID/handle to background object (optional)
    shadePlotObjectHandle?: string; // 333: Soft-pointer ID/handle to shade plot object (optional)
    visualStyleObjectHandle?: string; // 348: Hard-pointer ID/handle to visual style object (optional)
    defaultLightingOn?: boolean; // 292: Default Lighting On flag (0=off, 1=on)
    defaultLightingType?: number; // 282: Default Lighting type (0 = One distant light, 1 = Two distant lights)
    brightness?: number; // 141: Brightness value
    contrast?: number; // 142: Contrast value
    ambientColor?: string; // 63, 421, 431: Ambient color (hex or RGB format)

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfVPort> = {
        subclassMarkerVPort: {
            serialize: (obj: DxfVPort) =>
                serializeProperty(100, obj.subclassMarkerVPort),
        },
        viewportName: {
            serialize: (obj: DxfVPort) =>
                serializeProperty(2, obj.viewportName),
        },
        standardFlags: {
            serialize: (obj: DxfVPort) =>
                serializeProperty(70, obj.standardFlags),
        },
        lowerLeftCorner: {
            serialize: (obj: DxfVPort) =>
                serializeVector([10, 20], obj.lowerLeftCorner),
        },
        upperRightCorner: {
            serialize: (obj: DxfVPort) =>
                serializeVector([11, 21], obj.upperRightCorner),
        },
        viewCenter: {
            serialize: (obj: DxfVPort) =>
                serializeVector([12, 22], obj.viewCenter),
        },
        snapBase: {
            serialize: (obj: DxfVPort) =>
                serializeVector([13, 23], obj.snapBase),
        },
        snapSpacing: {
            serialize: (obj: DxfVPort) =>
                serializeVector([14, 24], obj.snapSpacing),
        },
        gridSpacing: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([15, 25], obj.gridSpacing),
        },
        viewDirection: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([16, 26, 36], obj.viewDirection),
        },
        viewTarget: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([17, 27, 37], obj.viewTarget),
        },
        viewHeight: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(40, obj.viewHeight),
        },
        viewAspectRatio: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(41, obj.viewAspectRatio),
        },
        lensLength: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(42, obj.lensLength),
        },
        frontClippingPlane: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(43, obj.frontClippingPlane),
        },
        backClippingPlane: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(44, obj.backClippingPlane),
        },

        snapRotationAngle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(50, obj.snapRotationAngle),
        },
        viewTwistAngle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(51, obj.viewTwistAngle),
        },
        circleSides: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(72, obj.circleSides),
        },
        frozenLayersHandles: {
            serialize: (obj: DxfVPortProps) =>
                obj.frozenLayersHandles
                    ? obj.frozenLayersHandles.flatMap((handle) =>
                          serializeProperty(331, handle)
                      )
                    : [],
        },
        plotStyleSheet: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(1, obj.plotStyleSheet),
        },
        renderMode: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(281, obj.renderMode),
        },
        viewMode: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(71, obj.viewMode),
        },
        ucsIconSetting: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(74, obj.ucsIconSetting),
        },
        ucsOrigin: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([110, 120, 130], obj.ucsOrigin),
        },
        ucsXAxis: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([111, 121, 131], obj.ucsXAxis),
        },
        ucsYAxis: {
            serialize: (obj: DxfVPortProps) =>
                serializeVector([112, 122, 132], obj.ucsYAxis),
        },
        ucsTableRecordHandle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(345, obj.ucsTableRecordHandle),
        },
        baseUcsTableRecordHandle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(346, obj.baseUcsTableRecordHandle),
        },
        orthographicUcsType: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(79, obj.orthographicUcsType),
        },
        elevation: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(146, obj.elevation),
        },
        shadePlotSetting: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(170, obj.shadePlotSetting),
        },
        majorGridLines: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(61, obj.majorGridLines),
        },
        backgroundObjectHandle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(332, obj.backgroundObjectHandle),
        },
        shadePlotObjectHandle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(333, obj.shadePlotObjectHandle),
        },
        visualStyleObjectHandle: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(348, obj.visualStyleObjectHandle),
        },
        defaultLightingOn: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(292, obj.defaultLightingOn ? 1 : 0),
        },
        defaultLightingType: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(282, obj.defaultLightingType),
        },
        brightness: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(141, obj.brightness),
        },
        contrast: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(142, obj.contrast),
        },
        ambientColor: {
            serialize: (obj: DxfVPortProps) =>
                serializeProperty(63, obj.ambientColor),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfVPort> = {
        100: "subclassMarkerVPort", // Subclass marker
        2: "viewportName", // Viewport name
        70: (obj, value) => (obj.standardFlags = Number(value)), // Standard flags
        10: (obj, value) =>
            ((obj.lowerLeftCorner ??= new Vector2()).x = Number(value)), // Lower-left corner (X)
        20: (obj, value) =>
            ((obj.lowerLeftCorner ??= new Vector2()).y = Number(value)), // Lower-left corner (Y)
        11: (obj, value) =>
            ((obj.upperRightCorner ??= new Vector2()).x = Number(value)), // Upper-right corner (X)
        21: (obj, value) =>
            ((obj.upperRightCorner ??= new Vector2()).y = Number(value)), // Upper-right corner (Y)
        12: (obj, value) =>
            ((obj.viewCenter ??= new Vector2()).x = Number(value)), // View center (X)
        22: (obj, value) =>
            ((obj.viewCenter ??= new Vector2()).y = Number(value)), // View center (Y)
        13: (obj, value) =>
            ((obj.snapBase ??= new Vector2()).x = Number(value)), // Snap base (X)
        23: (obj, value) =>
            ((obj.snapBase ??= new Vector2()).y = Number(value)), // Snap base (Y)
        14: (obj, value) =>
            ((obj.snapSpacing ??= new Vector2()).x = Number(value)), // Snap spacing (X)
        24: (obj, value) =>
            ((obj.snapSpacing ??= new Vector2()).y = Number(value)), // Snap spacing (Y)
        15: (obj, value) =>
            ((obj.gridSpacing ??= new Vector2()).x = Number(value)), // Grid spacing (X)
        25: (obj, value) =>
            ((obj.gridSpacing ??= new Vector2()).y = Number(value)), // Grid spacing (Y)
        16: (obj, value) =>
            ((obj.viewDirection ??= new Vector3()).x = Number(value)), // View direction (X)
        26: (obj, value) =>
            ((obj.viewDirection ??= new Vector3()).y = Number(value)), // View direction (Y)
        36: (obj, value) =>
            ((obj.viewDirection ??= new Vector3()).z = Number(value)), // View direction (Z)
        17: (obj, value) =>
            ((obj.viewTarget ??= new Vector3()).x = Number(value)), // View target (X)
        27: (obj, value) =>
            ((obj.viewTarget ??= new Vector3()).y = Number(value)), // View target (Y)
        37: (obj, value) =>
            ((obj.viewTarget ??= new Vector3()).z = Number(value)), // View target (Z)
        42: (obj, value) =>
            (obj.lensLength = value !== undefined ? Number(value) : undefined), // Lens length
        43: (obj, value) =>
            (obj.frontClippingPlane =
                value !== undefined ? Number(value) : undefined), // Front clipping plane
        44: (obj, value) =>
            (obj.backClippingPlane =
                value !== undefined ? Number(value) : undefined), // Back clipping plane
        45: (obj, value) =>
            (obj.viewHeight = value !== undefined ? Number(value) : undefined), // View height
        50: (obj, value) =>
            (obj.snapRotationAngle =
                value !== undefined ? Number(value) : undefined), // Snap rotation angle
        51: (obj, value) =>
            (obj.viewTwistAngle =
                value !== undefined ? Number(value) : undefined), // View twist angle
        72: (obj, value) =>
            (obj.circleSides = value !== undefined ? Number(value) : undefined), // Circle sides
        331: (obj, value) =>
            (obj.frozenLayersHandles ??= []).push(String(value)), // Soft-pointer to frozen layers
        441: (obj, value) =>
            (obj.frozenLayersHandles ??= []).push(String(value)), // Hard-pointer to frozen layers
        281: (obj, value) =>
            (obj.renderMode = value !== undefined ? Number(value) : undefined), // Render mode
        71: (obj, value) =>
            (obj.viewMode = value !== undefined ? Number(value) : undefined), // View mode
        74: (obj, value) =>
            (obj.ucsIconSetting =
                value !== undefined ? Number(value) : undefined), // UCS icon
        110: (obj, value) =>
            ((obj.ucsOrigin ??= new Vector3()).x = Number(value)), // UCS origin (X)
        120: (obj, value) =>
            ((obj.ucsOrigin ??= new Vector3()).y = Number(value)), // UCS origin (Y)
        130: (obj, value) =>
            ((obj.ucsOrigin ??= new Vector3()).z = Number(value)), // UCS origin (Z)
        111: (obj, value) =>
            ((obj.ucsXAxis ??= new Vector3()).x = Number(value)), // UCS X-axis (X)
        121: (obj, value) =>
            ((obj.ucsXAxis ??= new Vector3()).y = Number(value)), // UCS X-axis (Y)
        131: (obj, value) =>
            ((obj.ucsXAxis ??= new Vector3()).z = Number(value)), // UCS X-axis (Z)
        112: (obj, value) =>
            ((obj.ucsYAxis ??= new Vector3()).x = Number(value)), // UCS Y-axis (X)
        122: (obj, value) =>
            ((obj.ucsYAxis ??= new Vector3()).y = Number(value)), // UCS Y-axis (Y)
        132: (obj, value) =>
            ((obj.ucsYAxis ??= new Vector3()).z = Number(value)), // UCS Y-axis (Z)
        345: (obj, value) =>
            (obj.ucsTableRecordHandle =
                value !== undefined ? String(value) : undefined), // Named UCS handle
        346: (obj, value) =>
            (obj.baseUcsTableRecordHandle =
                value !== undefined ? String(value) : undefined), // Base UCS handle
        79: (obj, value) => {
            obj.orthographicUcsType = Number(value);
        },
        146: (obj, value) => {
            obj.elevation = value !== undefined ? Number(value) : undefined;
        },
        170: (obj, value) => {
            obj.shadePlotSetting =
                value !== undefined ? Number(value) : undefined;
        },
        61: (obj, value) => {
            obj.majorGridLines =
                value !== undefined ? Number(value) : undefined;
        },
        332: (obj, value) => {
            obj.backgroundObjectHandle =
                value !== undefined ? String(value) : undefined;
        },
        333: (obj, value) => {
            obj.shadePlotObjectHandle =
                value !== undefined ? String(value) : undefined;
        },
        348: (obj, value) => {
            obj.visualStyleObjectHandle =
                value !== undefined ? String(value) : undefined;
        },
        292: (obj, value) => {
            obj.defaultLightingOn = value === "1"; // True if "1", false if "0"
        },
        282: (obj, value) => {
            obj.defaultLightingType =
                value !== undefined ? Number(value) : undefined;
        },
        141: (obj, value) => {
            obj.brightness = value !== undefined ? Number(value) : undefined;
        },
        142: (obj, value) => {
            obj.contrast = value !== undefined ? Number(value) : undefined;
        },
        63: (obj, value) => {
            obj.ambientColor = value !== undefined ? String(value) : undefined;
        },
        421: (obj, value) => {
            obj.ambientColor = value !== undefined ? String(value) : undefined;
        },
        431: (obj, value) => {
            obj.ambientColor = value !== undefined ? String(value) : undefined;
        },
    };
}

export class DxfLayerProps {
    // Fields in the exact order of the group codes
    readonly subclassMarkerLayer = "AcDbLayerTableRecord"; // Code 100
    layerName: string = ""; // Code 2
    standardFlags: number = 0; // Code 70 (bit-coded values)
    colorNumber?: number; // Code 62 (negative value indicates the layer is off)
    linetypeName?: string; // Code 6
    plottingFlag: boolean = true; // Code 290 (0 = do not plot this layer)
    lineweightEnum?: number; // Code 370
    plotStyleNameHandle: string = "0"; // Code 390 (handle of PlotStyleName object)
    materialHandle?: string; // Code 347 (handle to Material object)

    // Method to generate the map for serialization/deserialization
    static deserializationMap: DxfDeserializationMap<any> = {
        100: "subclassMarkerLayer",
        2: "layerName",
        70: (entity, value) => (entity.standardFlags = Number(value)),
        62: (entity, value) => (entity.colorNumber = Number(value)),
        6: "linetypeName",
        290: (entity, value) => (entity.plottingFlag = Boolean(Number(value))), // Convert 1 to true, 0 to false
        370: (entity, value) => (entity.lineweightEnum = Number(value)),
        390: "plotStyleNameHandle",
        347: "materialHandle",
    };

    static serializationMap: DxfSerializationMap<DxfLayer> = {
        subclassMarkerLayer: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerLayer),
        },
        layerName: {
            serialize: (obj) => serializeProperty(2, obj.layerName),
        },
        standardFlags: {
            serialize: (obj) => serializeProperty(70, obj.standardFlags),
        },
        colorNumber: {
            serialize: (obj) => serializeProperty(62, obj.colorNumber),
        },
        linetypeName: {
            serialize: (obj) => serializeProperty(6, obj.linetypeName),
        },
        plottingFlag: {
            serialize: (obj) =>
                serializeProperty(290, Number(obj.plottingFlag), 1),
        },
        lineweightEnum: {
            serialize: (obj) => serializeProperty(370, obj.lineweightEnum),
        },
        plotStyleNameHandle: {
            serialize: (obj) => serializeProperty(390, obj.plotStyleNameHandle),
        },
        materialHandle: {
            serialize: (obj) => serializeProperty(347, obj.materialHandle),
        },
    };
}

export class DxfAppIdProps {
    // Properties in the exact order of the group codes
    readonly subclassMarkerAppId = "AcDbRegAppTableRecord"; // Code 100
    appName: string = ""; // Code 2
    standardFlags: number = 0; // Code 70 (bit-coded values)

    // Property Map
    static propertyMap: DxfPropertyMap = {
        subclassMarkerAppId: {
            code: 100,
            defaultValue: "AcDbRegAppTableRecord",
            required: true,
        },
        appName: { code: 2, required: true },
        standardFlags: { code: 70 },
    };

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfAppId> = {
        subclassMarkerAppId: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerAppId),
        },
        appName: {
            serialize: (obj) => serializeProperty(2, obj.appName),
        },
        standardFlags: {
            serialize: (obj) => serializeProperty(70, obj.standardFlags),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfAppId> = {
        100: "subclassMarkerAppId",
        2: "appName",
        70: (obj, value) => (obj.standardFlags = Number(value)),
    };
}

//Entities
export class DxfEntityProps {
    // Static fields for common graphical object group codes
    handle: string = ""; // Code 5 (Handle)
    xDictionary?: DxfExtensionDictionary;
    handleToOwner?: string; //code 330
    readonly subclassMarkerEntity = "AcDbEntity"; // Code 100 (AcDbEntity subclass marker)
    layer?: string; // Code 8 (Layer name)
    paperSpace: boolean = false; // Code 67 (Model space = 0, Paper space = 1)
    layoutTabName?: string; // Code 410 (Layout tab name)
    linetype?: string = "BYLAYER"; // Code 6 (Linetype name, default to BYLAYER)
    colorNumber?: number = 256; // Code 62 (Color number, default BYLAYER)
    lineweight?: number = 0; // Code 370 (Lineweight, default to 0)
    linetypeScale?: number = 1.0; // Code 48 (Linetype scale, default 1.0)
    objectVisibility?: number = 0; // Code 60 (Object visibility, 0 = visible, 1 = invisible)
    proxyEntityGraphicsBytes?: number; // Code 92 (Bytes in proxy entity graphics, optional)
    proxyEntityGraphicsData?: string; // Code 310 (Proxy entity graphics data, optional)
    colorValue?: number; // Code 420 (24-bit color value)
    colorName?: string; // Code 430 (Color name)
    transparencyValue?: number; // Code 440 (Transparency value)
    plotStyleHandle?: string; // Code 390 (Handle to plot style)
    shadowMode?: number; // Code 284 (Shadow mode, 0 = none, 1 = cast shadows, 2 = receive shadows)

    static deserializationMap: DxfDeserializationMap<any> = {
        5: "handle",
        102:(entity, value) => deserializeXdictKey(entity, String(value)),
        360:(entity, value) => deserializeXdict(entity, String(value)),
        330: "handleToOwner",
        100: "subclassMarkerEntity",
        8: "layer",
        67: (entity, value) => (entity.paperSpace = Boolean(Number(value))),
        410: "layoutTabName",
        6: "linetype",
        62: (entity, value) => (entity.colorNumber = Number(value)),
        370: (entity, value) => (entity.lineweight = Number(value)),
        48: (entity, value) => (entity.linetypeScale = Number(value)),
        60: "objectVisibility", //: { code: 60, defaultValue: 0 },
        92: "proxyEntityGraphicsBytes",
        310: "proxyEntityGraphicsData",
        420: (entity, value) => (entity.colorValue = Number(value)),
        430: "colorName",
        440: (entity, value) => (entity.transparencyValue = Number(value)),
        390: "plotStyleHandle",
        284: (entity, value) => (entity.shadowMode = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfEntity> = {
        subclassMarkerEntity: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerEntity),
        },
        handle: {
            serialize: (obj) => serializeProperty(5, obj.handle),
        },
        xDictionary:{
            serialize:(obj) => serializeExtensionDictionary(obj.xDictionary)
        },
        handleToOwner: {
            serialize: (obj) => serializeProperty(330, obj.handleToOwner),
        },
        layer: {
            serialize: (obj) => serializeProperty(8, obj.layer),
        },
        spaceIndicator: {
            serialize: (obj) => serializeProperty(67, Number(obj.paperSpace), 0),
        },
        layoutTabName: {
            serialize: (obj) => serializeProperty(410, obj.layoutTabName),
        },
        linetype: {
            serialize: (obj) => serializeProperty(6, obj.linetype),
        },
        colorNumber: {
            serialize: (obj) => serializeProperty(62, obj.colorNumber),
        },
        lineweight: {
            serialize: (obj) => serializeProperty(370, obj.lineweight),
        },
        linetypeScale: {
            serialize: (obj) => serializeProperty(48, obj.linetypeScale),
        },
        objectVisibility: {
            serialize: (obj) => serializeProperty(60, obj.objectVisibility),
        },
        proxyEntityGraphicsBytes: {
            serialize: (obj) =>
                serializeProperty(92, obj.proxyEntityGraphicsBytes),
        },
        proxyEntityGraphicsData: {
            serialize: (obj) =>
                serializeProperty(310, obj.proxyEntityGraphicsData),
        },
        colorValue: {
            serialize: (obj) => serializeProperty(420, obj.colorValue),
        },
        colorName: {
            serialize: (obj) => serializeProperty(430, obj.colorName),
        },
        transparencyValue: {
            serialize: (obj) => serializeProperty(440, obj.transparencyValue),
        },
        plotStyleHandle: {
            serialize: (obj) => serializeProperty(390, obj.plotStyleHandle),
        },
        shadowMode: {
            serialize: (obj) => serializeProperty(284, obj.shadowMode),
        },
    };
}

export class DxfLineProps {
    // Static fields for line group codes
    readonly subclassMarkerLine = "AcDbLine"; // Code 100 (Subclass marker for AcDbLine)
    thickness?: number = 0; // Code 39 (Thickness, default = 0)
    startPoint: Vector3 = new Vector3(0, 0, 0); // Code 10 (X value of start point, WCS)
    endPoint: Vector3 = new Vector3(0, 0, 0); // Code 11, 21, 31
    extrusionDirection?: Vector3; // Code 210 (Extrusion direction X, optional, default = 0)

    static deserializationMap: DxfDeserializationMap<DxfLine> = {
        39: (line, value) => (line.thickness = Number(value)),
        10: (line, value) => (line.startPoint.x = Number(value)),
        20: (line, value) => (line.startPoint.y = Number(value)),
        30: (line, value) => (line.startPoint.z = Number(value)),
        11: (line, value) => (line.endPoint.x = Number(value)),
        21: (line, value) => (line.endPoint.y = Number(value)),
        31: (line, value) => (line.endPoint.z = Number(value)),
        210: (line, value) => {
            (line.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (line, value) => {
            (line.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (line, value) => {
            (line.extrusionDirection ??= new Vector3()).z = Number(value);
        },
    };

    static serializationMap: DxfSerializationMap<DxfLine> = {
        subclassMarkerLine: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerLine),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness),
        },
        startPoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.startPoint),
        },
        endPoint: {
            serialize: (obj) => serializeVector([11, 21, 31], obj.endPoint),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
    };
}

export class DxfLwPolylineVertex {
    vertex: Vector3;
    startWidth: number = 0;
    endWidth: number = 0;
    bulge: number = 0;

    constructor(
        vertex: Vector3,
        bulge: number = 0,
        startWidth: number = 0,
        endWidth: number = 0
    ) {
        this.vertex = vertex;
        this.bulge = bulge;
        this.startWidth = startWidth;
        this.endWidth = endWidth;
    }
}



export class DxfLwPolylineProps {
    // Fields
    readonly subclassMarkerLwPolyline = "AcDbPolyline";
    numberOfVertices: number = 0; // 90
    polylineFlag: number = 0; // 70
    constantWidth?: number; // 43
    elevation?: number; // 38
    thickness?: number; // 39
    extrusionDirection?: Vector3; // 210, 220, 230
    vertices: DxfLwPolylineVertex[] = []; // Repeated group codes 10, 20, 30, 40, 41, 42

    // Static deserialization map
    static deserializationMap: DxfDeserializationMap<DxfLwPolyline> = {
        100: "subclassMarkerLwPolyline",
        90: "numberOfVertices",
        70: "polylineFlag",
        43: "constantWidth",
        38: "elevation",
        39: "thickness",
        210: (polyline, value) => {
            (polyline.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (polyline, value) => {
            (polyline.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (polyline, value) => {
            (polyline.extrusionDirection ??= new Vector3()).z = Number(value);
        },

        // Handle vertex data as arrays
        10: (polyline, value) => {
            const vertex = new DxfLwPolylineVertex(new Vector3(Number(value)));
            polyline.vertices.push(vertex);
        },
        20: (polyline, value) => {
            if (polyline.vertices.length > 0) {
                polyline.vertices[polyline.vertices.length - 1].vertex.y =
                    Number(value);
            }
        },
        30: (polyline, value) => {
            if (polyline.vertices.length > 0) {
                polyline.vertices[polyline.vertices.length - 1].vertex.z =
                    Number(value);
            }
        },
        40: (polyline, value) => {
            if (polyline.vertices.length > 0) {
                polyline.vertices[polyline.vertices.length - 1].startWidth =
                    Number(value);
            }
        },
        41: (polyline, value) => {
            if (polyline.vertices.length > 0) {
                polyline.vertices[polyline.vertices.length - 1].endWidth =
                    Number(value);
            }
        },
        42: (polyline, value) => {
            if (polyline.vertices.length > 0) {
                polyline.vertices[polyline.vertices.length - 1].bulge =
                    Number(value);
            }
        },
    };

    static serializationMap: DxfSerializationMap<DxfLwPolyline> = {
        subclassMarkerLwPolyline: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerLwPolyline),
        },
        numberOfVertices: {
            serialize: (obj) => serializeProperty(90, obj.numberOfVertices),
        },
        polylineFlag: {
            serialize: (obj) => serializeProperty(70, obj.polylineFlag),
        },
        constantWidth: {
            serialize: (obj) => serializeProperty(43, obj.constantWidth),
        },
        elevation: {
            serialize: (obj) => serializeProperty(38, obj.elevation),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness),
        },

        vertices: {
            serialize: (obj) =>
                obj.vertices.flatMap((vertex) => [
                    ...serializeVector([10, 20, 30], vertex.vertex),
                    ...serializeProperty(40, vertex.startWidth, 0),
                    ...serializeProperty(41, vertex.endWidth, 0),
                    ...serializeProperty(42, vertex.bulge, 0),
                ]),
        },

        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
    };
}

export class DxfSplineProps {
    // Static fields for spline group codes
    readonly subclassMarkerSpline = "AcDbSpline"; // Code 100 (Subclass marker for AcDbSpline)
    normalVector?: Vector3; // Code 210, 220, 230 (Normal vector, optional)
    splineFlag: number = 0; // Code 70 (Spline flag, bit coded)
    degree: number = 0; // Code 71 (Degree of the spline curve)
    numberOfKnots: number = 0; // Code 72 (Number of knots)
    numberOfControlPoints: number = 0; // Code 73 (Number of control points)
    numberOfFitPoints: number = 0; // Code 74 (Number of fit points)
    knotTolerance: number = 0.0000001; // Code 42 (Knot tolerance, default)
    controlPointTolerance: number = 0.0000001; // Code 43 (Control-point tolerance, default)
    fitTolerance: number = 0.0000000001; // Code 44 (Fit tolerance, default)
    startTangent?: Vector3; // Code 12, 22, 32 (Start tangent, optional)
    endTangent?: Vector3; // Code 13, 23, 33 (End tangent, optional)
    knots: number[] = []; // Code 40 (Knot values, one entry per knot)
    weights: number[] = []; // Code 41 (Weights, optional, one entry per control point)
    controlPoints: Vector3[] = []; // Code 10, 20, 30 (Control points in WCS)
    fitPoints: Vector3[] = []; // Code 11, 21, 31 (Fit points in WCS)

    static deserializationMap: DxfDeserializationMap<DxfSpline> = {
        100: (obj, value) => {},
        210: (obj, value) => {
            (obj.normalVector ??= new Vector3()).x = Number(value);
        },
        220: (obj, value) => {
            (obj.normalVector ??= new Vector3()).y = Number(value);
        },
        230: (obj, value) => {
            (obj.normalVector ??= new Vector3()).z = Number(value);
        },
        70: (obj, value) => (obj.splineFlag = Number(value)),
        71: (obj, value) => (obj.degree = Number(value)),
        72: (obj, value) => (obj.numberOfKnots = Number(value)),
        73: (obj, value) => (obj.numberOfControlPoints = Number(value)),
        74: (obj, value) => (obj.numberOfFitPoints = Number(value)),
        42: (obj, value) => (obj.knotTolerance = Number(value)),
        43: (obj, value) => (obj.controlPointTolerance = Number(value)),
        44: (obj, value) => (obj.fitTolerance = Number(value)),
        12: (obj, value) => {
            (obj.startTangent ??= new Vector3()).x = Number(value);
        },
        22: (obj, value) => {
            (obj.startTangent ??= new Vector3()).y = Number(value);
        },
        32: (obj, value) => {
            (obj.startTangent ??= new Vector3()).z = Number(value);
        },
        13: (obj, value) => {
            (obj.endTangent ??= new Vector3()).x = Number(value);
        },
        23: (obj, value) => {
            (obj.endTangent ??= new Vector3()).y = Number(value);
        },
        33: (obj, value) => {
            (obj.endTangent ??= new Vector3()).z = Number(value);
        },
        40: (obj, value) => {
            obj.knots.push(Number(value));
        },
        41: (obj, value) => {
            obj.weights.push(Number(value));
        },
        10: (obj, value) => {
            const point = new Vector3(Number(value), 0, 0);
            obj.controlPoints.push(point);
        },
        20: (obj, value) => {
            const lastPoint = obj.controlPoints[obj.controlPoints.length - 1];
            if (lastPoint) {
                lastPoint.y = Number(value);
            }
        },
        30: (obj, value) => {
            const lastPoint = obj.controlPoints[obj.controlPoints.length - 1];
            if (lastPoint) {
                lastPoint.z = Number(value);
            }
        },
        11: (obj, value) => {
            const point = new Vector3(Number(value), 0, 0);
            obj.fitPoints.push(point);
        },
        21: (obj, value) => {
            const lastPoint = obj.fitPoints[obj.fitPoints.length - 1];
            if (lastPoint) {
                lastPoint.y = Number(value);
            }
        },
        31: (obj, value) => {
            const lastPoint = obj.fitPoints[obj.fitPoints.length - 1];
            if (lastPoint) {
                lastPoint.z = Number(value);
            }
        },
    };

    static serializationMap: DxfSerializationMap<DxfSpline> = {
        subclassMarkerSpline: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerSpline),
        },
        normalVector: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.normalVector),
        },
        splineFlag: {
            serialize: (obj) => serializeProperty(70, obj.splineFlag),
        },
        degree: {
            serialize: (obj) => serializeProperty(71, obj.degree),
        },
        numberOfKnots: {
            serialize: (obj) => serializeProperty(72, obj.numberOfKnots),
        },
        numberOfControlPoints: {
            serialize: (obj) =>
                serializeProperty(73, obj.numberOfControlPoints),
        },
        numberOfFitPoints: {
            serialize: (obj) => serializeProperty(74, obj.numberOfFitPoints),
        },
        knotTolerance: {
            serialize: (obj) => serializeProperty(42, obj.knotTolerance),
        },
        controlPointTolerance: {
            serialize: (obj) =>
                serializeProperty(43, obj.controlPointTolerance),
        },
        fitTolerance: {
            serialize: (obj) => serializeProperty(44, obj.fitTolerance),
        },
        startTangent: {
            serialize: (obj) => serializeVector([12, 22, 32], obj.startTangent),
        },
        endTangent: {
            serialize: (obj) => serializeVector([13, 23, 33], obj.endTangent),
        },
        knots: {
            serialize: (obj) =>
                obj.knots.flatMap((knot) => serializeProperty(40, knot)),
        },
        weights: {
            serialize: (obj) =>
                obj.weights.flatMap((weight) => serializeProperty(41, weight)),
        },
        controlPoints: {
            serialize: (obj) =>
                obj.controlPoints.flatMap((point) =>
                    serializeVector([10, 20, 30], point)
                ),
        },
        fitPoints: {
            serialize: (obj) =>
                obj.fitPoints.flatMap((point) =>
                    serializeVector([11, 21, 31], point)
                ),
        },
    };
}

export class DxfPolylineProps {
    subclassMarker: "AcDb2dPolyline" | "AcDb3dPolyline" = "AcDb2dPolyline"; // or "AcDb3dPolyline", Group code 100
    dummyPoint?: Vector3; // Code 10, 20, 30 (dummy point for elevation)
    thickness?: number; // Code 39
    polylineFlag: number = 0; // Code 70 (bit-coded flags)
    startWidth: number = 0; // Code 40 (default start width)
    endWidth: number = 0; // Code 41 (default end width)
    meshMVertexCount: number = 0; // Code 71
    meshNVertexCount: number = 0; // Code 72
    smoothSurfaceMDensity: number = 0; // Code 73
    smoothSurfaceNDensity: number = 0; // Code 74
    smoothSurfaceType: number = 0; // Code 75
    extrusionDirection?: Vector3; // Code 210, 220, 230

    static deserializationMap: DxfDeserializationMap<DxfPolyline> = {
        100: (obj, value) => {
            if(value === "AcDb2dPolyline" ||  value === "AcDb3dPolyline"){
                obj.subclassMarker = value;
            }
            
        },
        10: (obj, value) => {
            (obj.dummyPoint ??= new Vector3()).x = Number(value);
        },
        20: (obj, value) => {
            (obj.dummyPoint ??= new Vector3()).y = Number(value);
        },
        30: (obj, value) => {
            (obj.dummyPoint ??= new Vector3()).z = Number(value);
        },
        39: (obj, value) => {
            obj.thickness = Number(value);
        },
        70: (obj, value) => {
            obj.polylineFlag = Number(value);
        },
        40: (obj, value) => {
            obj.startWidth = Number(value);
        },
        41: (obj, value) => {
            obj.endWidth = Number(value);
        },
        71: (obj, value) => {
            obj.meshMVertexCount = Number(value);
        },
        72: (obj, value) => {
            obj.meshNVertexCount = Number(value);
        },
        73: (obj, value) => {
            obj.smoothSurfaceMDensity = Number(value);
        },
        74: (obj, value) => {
            obj.smoothSurfaceNDensity = Number(value);
        },
        75: (obj, value) => {
            obj.smoothSurfaceType = Number(value);
        },
        210: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).z = Number(value);
        },
    };

    static serializationMap: DxfSerializationMap<DxfPolyline> = {
        subclassMarker: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarker),
        },
        dummyPoint: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.dummyPoint),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness),
        },
        polylineFlag: {
            serialize: (obj) => serializeProperty(70, obj.polylineFlag),
        },
        startWidth: {
            serialize: (obj) => serializeProperty(40, obj.startWidth),
        },
        endWidth: {
            serialize: (obj) => serializeProperty(41, obj.endWidth),
        },
        meshMVertexCount: {
            serialize: (obj) => serializeProperty(71, obj.meshMVertexCount),
        },
        meshNVertexCount: {
            serialize: (obj) => serializeProperty(72, obj.meshNVertexCount),
        },
        smoothSurfaceMDensity: {
            serialize: (obj) =>
                serializeProperty(73, obj.smoothSurfaceMDensity),
        },
        smoothSurfaceNDensity: {
            serialize: (obj) =>
                serializeProperty(74, obj.smoothSurfaceNDensity),
        },
        smoothSurfaceType: {
            serialize: (obj) => serializeProperty(75, obj.smoothSurfaceType),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
    };
}

export class DxfVertexProps {
    readonly subclassMarkerVertex = "AcDbVertex"; // Group code 100 (AcDbVertex)
    readonly subclassMarkerVertexDimension: "AcDb2dVertex" | "AcDb3dPolylineVertex" = "AcDb3dPolylineVertex"; // Group code 100
    location: Vector3 = new Vector3(); // Group codes 10, 20, 30 (location point)
    startWidth: number = 0; // Group code 40 (optional, default 0)
    endWidth: number = 0; // Group code 41 (optional, default 0)
    bulge: number = 0; // Group code 42 (optional, default 0)
    vertexFlags: number = 0; // Group code 70 (bit-coded)
    curveFitTangentDirection?: number; // Group code 50 (optional)
    meshVertexIndex1?: number; // Group code 71 (optional)
    meshVertexIndex2?: number; // Group code 72 (optional)
    meshVertexIndex3?: number; // Group code 73 (optional)
    meshVertexIndex4?: number; // Group code 74 (optional)
    vertexIdentifier?: number; // Group code 91 (optional)

    static deserializationMap: DxfDeserializationMap<DxfVertex> = {
        100: (obj, value) => {

            if (value === "AcDb2dVertex" || value === "AcDb3dPolylineVertex"){
                obj.subclassMarkerVertexDimension = value;
            }
        },
        10: (obj, value) => {
            (obj.location ??= new Vector3()).x = Number(value);
        },
        20: (obj, value) => {
            (obj.location ??= new Vector3()).y = Number(value);
        },
        30: (obj, value) => {
            (obj.location ??= new Vector3()).z = Number(value);
        },
        40: (obj, value) => {
            obj.startWidth = Number(value);
        },
        41: (obj, value) => {
            obj.endWidth = Number(value);
        },
        42: (obj, value) => {
            obj.bulge = Number(value);
        },
        70: (obj, value) => {
            obj.vertexFlags = Number(value);
        },
        50: (obj, value) => {
            obj.curveFitTangentDirection = Number(value);
        },
        71: (obj, value) => {
            obj.meshVertexIndex1 = Number(value);
        },
        72: (obj, value) => {
            obj.meshVertexIndex2 = Number(value);
        },
        73: (obj, value) => {
            obj.meshVertexIndex3 = Number(value);
        },
        74: (obj, value) => {
            obj.meshVertexIndex4 = Number(value);
        },
        91: (obj, value) => {
            obj.vertexIdentifier = Number(value);
        },
    };

    static serializationMap: DxfSerializationMap<DxfVertex> = {
        subclassMarkerVertex: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerVertex),
        },
        subclassMarker2dOr3dVertex: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerVertexDimension),
        },
        location: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.location),
        },
        startWidth: {
            serialize: (obj) => serializeProperty(40, obj.startWidth, 0),
        },
        endWidth: {
            serialize: (obj) => serializeProperty(41, obj.endWidth, 0),
        },
        bulge: {
            serialize: (obj) => serializeProperty(42, obj.bulge, 0),
        },
        vertexFlags: {
            serialize: (obj) => serializeProperty(70, obj.vertexFlags),
        },
        curveFitTangentDirection: {
            serialize: (obj) =>
                serializeProperty(50, obj.curveFitTangentDirection, 0),
        },
        meshVertexIndex1: {
            serialize: (obj) => serializeProperty(71, obj.meshVertexIndex1, 0),
        },
        meshVertexIndex2: {
            serialize: (obj) => serializeProperty(72, obj.meshVertexIndex2, 0),
        },
        meshVertexIndex3: {
            serialize: (obj) => serializeProperty(73, obj.meshVertexIndex3, 0),
        },
        meshVertexIndex4: {
            serialize: (obj) => serializeProperty(74, obj.meshVertexIndex4, 0),
        },
        vertexIdentifier: {
            serialize: (obj) => serializeProperty(91, obj.vertexIdentifier),
        },
    };
}



export class DxfTextProps {
    // Fields in the exact order of the group codes
    readonly subclassMarkerText = "AcDbText"; // Code 100
    thickness?: number = 0; // Code 39 (optional, default = 0)
    firstAlignmentPoint: Vector3 = new Vector3(0, 0, 0); // Code 10, 20, 30 (mandatory)
    textHeight: number = 1.0; // Code 40 (mandatory)
    textString: string = ""; // Code 1 (the text string itself, mandatory)
    rotation?: number = 0; // Code 50 (optional, default = 0)
    relativeXScaleFactor?: number = 1.0; // Code 41 (optional, default = 1)
    obliqueAngle?: number = 0; // Code 51 (optional, default = 0)
    textStyleName?: string = "STANDARD"; // Code 7 (optional, default = STANDARD)
    textGenerationFlags?: number = 0; // Code 71 (optional, default = 0)
    horizontalJustification?: number = 0; // Code 72 (optional, default = 0)
    secondAlignmentPoint?: Vector3; // Code 11, 21, 31 (optional, only meaningful for nonzero justification)
    extrusionDirection?: Vector3 = new Vector3(0, 0, 1); // Code 210, 220, 230 (optional, default = 0,0,1)
    verticalJustification?: number = 0; // Code 73 (optional, default = 0)

    static deserializationMap: DxfDeserializationMap<DxfText> = {
        100: "subclassMarkerText",
        39: "thickness",
        10: (text, value) => {
            (text.firstAlignmentPoint ??= new Vector3()).x = Number(value);
        },
        20: (text, value) => {
            (text.firstAlignmentPoint ??= new Vector3()).y = Number(value);
        },
        30: (text, value) => {
            (text.firstAlignmentPoint ??= new Vector3()).z = Number(value);
        },
        40: "textHeight",
        1: "textString",
        50: "textRotation",
        41: "relativeXScaleFactor",
        51: "obliqueAngle",
        7: "textStyleName",
        71: "textGenerationFlags",
        72: "horizontalJustification",
        11: (text, value) => {
            (text.secondAlignmentPoint ??= new Vector3()).x = Number(value);
        },
        21: (text, value) => {
            (text.secondAlignmentPoint ??= new Vector3()).y = Number(value);
        },
        31: (text, value) => {
            (text.secondAlignmentPoint ??= new Vector3()).z = Number(value);
        },
        210: (text, value) => {
            (text.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (text, value) => {
            (text.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (text, value) => {
            (text.extrusionDirection ??= new Vector3()).z = Number(value);
        },
        73: "verticalJustification",
    };

    // Static serialization map for DxfTextProps
    static serializationMap: DxfSerializationMap<DxfText> = {
        subclassMarkerText: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerText),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness, "0"),
        },
        firstAlignmentPoint: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.firstAlignmentPoint),
        },
        textHeight: {
            serialize: (obj) => serializeProperty(40, obj.textHeight),
        },
        defaultValue: {
            serialize: (obj) => serializeProperty(1, obj.textString),
        },
        rotation: {
            serialize: (obj) => serializeProperty(50, obj.rotation, "0"),
        },
        relativeXScaleFactor: {
            serialize: (obj) =>
                serializeProperty(41, obj.relativeXScaleFactor, "1"),
        },
        obliqueAngle: {
            serialize: (obj) => serializeProperty(51, obj.obliqueAngle, "0"),
        },
        textStyleName: {
            serialize: (obj) =>
                serializeProperty(7, obj.textStyleName, "STANDARD"),
        },
        textGenerationFlags: {
            serialize: (obj) =>
                serializeProperty(71, obj.textGenerationFlags, "0"),
        },
        horizontalJustification: {
            serialize: (obj) =>
                serializeProperty(72, obj.horizontalJustification, "0"),
        },
        secondAlignmentPoint: {
            serialize: (obj) =>
                serializeVector([11, 21, 31], obj.secondAlignmentPoint),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
        verticalJustification: {
            serialize: (obj) => {
                const data: DxfSerializedData[] = [];
                const subclass = serializeProperty(100, obj.subclassMarkerText);
                IterUtils.extendArray(data, subclass);
                const property = serializeProperty(
                    73,
                    obj.verticalJustification,
                    "0"
                );
                
                IterUtils.extendArray(data, property);
                return data;
            },
        },
    };
}

export class DxfInsertProps {
    // Fields in the exact order of the group codes
    readonly subclassMarkerInsert = "AcDbBlockReference"; // Code 100
    hasAttributes: boolean = false; // Code 66 (optional, default = 0)
    blockName: string = ""; // Code 2
    insertionPoint: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30
    scaleFactor?: Vector3 | undefined; // Codes 41, 42, 43
    rotationAngle?: number = 0; // Code 50 (optional, default = 0)
    columnCount?: number = 1; // Code 70 (optional, default = 1)
    rowCount?: number = 1; // Code 71 (optional, default = 1)
    columnSpacing?: number = 0; // Code 44 (optional, default = 0)
    rowSpacing?: number = 0; // Code 45 (optional, default = 0)
    extrusionDirection?: Vector3 = new Vector3(0, 0, 1); // Codes 210, 220, 230 (optional, default = 0, 0, 1)

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfInsert> = {
        subclassMarkerInsert: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerInsert),
        },
        attributesFollow: {
            serialize: (obj) =>
                obj.hasAttributes ? [{code:66, value:1}] : []
        },
        blockName: {
            serialize: (obj) => serializeProperty(2, obj.blockName),
        },
        insertionPoint: {
            serialize: (obj) =>
                serializeVector(
                    [10, 20, 30],
                    obj.insertionPoint,
                    new Vector3(0, 0, 0)
                ),
        },
        scaleFactor: {
            serialize: (obj) => serializeVector([41, 42, 43], obj.scaleFactor, new Vector3(1,1,1)),
        },
        rotationAngle: {
            serialize: (obj) => serializeProperty(50, obj.rotationAngle, "0"),
        },
        columnCount: {
            serialize: (obj) => serializeProperty(70, obj.columnCount, "1"),
        },
        rowCount: {
            serialize: (obj) => serializeProperty(71, obj.rowCount, "1"),
        },
        columnSpacing: {
            serialize: (obj) => serializeProperty(44, obj.columnSpacing, "0"),
        },
        rowSpacing: {
            serialize: (obj) => serializeProperty(45, obj.rowSpacing, "0"),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector(
                    [210, 220, 230],
                    obj.extrusionDirection,
                    new Vector3(0, 0, 1)
                ),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfInsert> = {
        100: "subclassMarkerInsert",
        66: (insert, value) => (insert.hasAttributes = Number(value)===1 ? true:false),
        2: "blockName",
        10: (insert, value) => {
            (insert.insertionPoint ??= new Vector3()).x = Number(value);
        },
        20: (insert, value) => {
            (insert.insertionPoint ??= new Vector3()).y = Number(value);
        },
        30: (insert, value) => {
            (insert.insertionPoint ??= new Vector3()).z = Number(value);
        },
        41: (insert, value) => {
            (insert.scaleFactor ??= new Vector3()).x = Number(value);
        },
        42: (insert, value) =>  {
            (insert.scaleFactor ??= new Vector3()).y = Number(value);
        },
        43: (insert, value) =>  {
            (insert.scaleFactor ??= new Vector3()).z = Number(value);
        },
        50: (insert, value) => (insert.rotationAngle = Number(value)),
        70: (insert, value) => (insert.columnCount = Number(value)),
        71: (insert, value) => (insert.rowCount = Number(value)),
        44: (insert, value) => (insert.columnSpacing = Number(value)),
        45: (insert, value) => (insert.rowSpacing = Number(value)),
        210: (insert, value) => {
            (insert.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (insert, value) => {
            (insert.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (insert, value) => {
            (insert.extrusionDirection ??= new Vector3()).z = Number(value);
        },
    };
}

export class DxfImageProps {
    // Fields
    readonly subclassMarkerImage = "AcDbRasterImage"; // Code 100
    classVersion?: number; // Code 90
    insertionPoint: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30
    uVector: Vector3 = new Vector3(1, 0, 0); // Codes 11, 21, 31
    vVector: Vector3 = new Vector3(0, 1, 0); // Codes 12, 22, 32
    imageSize: Vector2 = new Vector2(1, 1); // Codes 13, 23
    imageDefHandle?: string; // Code 340
    displayProperties?: number; // Code 70
    clippingState: number = 0; // Code 280 (default = 0)
    brightness: number = 50; // Code 281 (default = 50)
    contrast: number = 50; // Code 282 (default = 50)
    fade: number = 0; // Code 283 (default = 0)
    imageDefReactorHandle?: string; // Code 360
    clippingBoundaryType: number = 1; // Code 71
    clipBoundaryVertices: Vector2[] = []; // Codes 14, 24
    clipMode?: number; // Code 290 (default = 0)

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfImage> = {
        subclassMarkerImage: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerImage),
        },
        classVersion: {
            serialize: (obj) => serializeProperty(90, obj.classVersion),
        },
        insertionPoint: {
            serialize: (obj) =>
                serializeVector(
                    [10, 20, 30],
                    obj.insertionPoint,
                    new Vector3(0, 0, 0)
                ),
        },
        uVector: {
            serialize: (obj) =>
                serializeVector(
                    [11, 21, 31],
                    obj.uVector,
                    new Vector3(1, 0, 0)
                ),
        },
        vVector: {
            serialize: (obj) =>
                serializeVector(
                    [12, 22, 32],
                    obj.vVector,
                    new Vector3(0, 1, 0)
                ),
        },
        imageSize: {
            serialize: (obj) =>
                serializeVector([13, 23], obj.imageSize, new Vector2(1, 1)),
        },
        imageDefHandle: {
            serialize: (obj) => serializeProperty(340, obj.imageDefHandle),
        },
        displayProperties: {
            serialize: (obj) => serializeProperty(70, obj.displayProperties),
        },
        clippingState: {
            serialize: (obj) => serializeProperty(280, obj.clippingState, "0"),
        },
        brightness: {
            serialize: (obj) => serializeProperty(281, obj.brightness, "50"),
        },
        contrast: {
            serialize: (obj) => serializeProperty(282, obj.contrast, "50"),
        },
        fade: {
            serialize: (obj) => serializeProperty(283, obj.fade, "0"),
        },
        imageDefReactorHandle: {
            serialize: (obj) =>
                serializeProperty(360, obj.imageDefReactorHandle),
        },
        clippingBoundaryType: {
            serialize: (obj) => serializeProperty(71, obj.clippingBoundaryType),
        },
        clipBoundaryVertices: {
            serialize: (obj) =>
                obj.clipBoundaryVertices.flatMap((vertex) =>
                    serializeVector([14, 24], vertex)
                ),
        },
        clipMode: {
            serialize: (obj) => serializeProperty(290, obj.clipMode, "0"),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfImage> = {
        100: "subclassMarkerImage",
        90: (image, value) => (image.classVersion = Number(value)),
        10: (image, value) => {
            (image.insertionPoint ??= new Vector3()).x = Number(value);
        },
        20: (image, value) => {
            (image.insertionPoint ??= new Vector3()).y = Number(value);
        },
        30: (image, value) => {
            (image.insertionPoint ??= new Vector3()).z = Number(value);
        },
        11: (image, value) => {
            (image.uVector ??= new Vector3()).x = Number(value);
        },
        21: (image, value) => {
            (image.uVector ??= new Vector3()).y = Number(value);
        },
        31: (image, value) => {
            (image.uVector ??= new Vector3()).z = Number(value);
        },
        12: (image, value) => {
            (image.vVector ??= new Vector3()).x = Number(value);
        },
        22: (image, value) => {
            (image.vVector ??= new Vector3()).y = Number(value);
        },
        32: (image, value) => {
            (image.vVector ??= new Vector3()).z = Number(value);
        },
        13: (image, value) => {
            (image.imageSize ??= new Vector2()).x = Number(value);
        },
        23: (image, value) => {
            (image.imageSize ??= new Vector2()).y = Number(value);
        },
        340: "imageDefHandle",
        70: (image, value) => (image.displayProperties = Number(value)),
        280: (image, value) => (image.clippingState = Number(value)),
        281: (image, value) => (image.brightness = Number(value)),
        282: (image, value) => (image.contrast = Number(value)),
        283: (image, value) => (image.fade = Number(value)),
        360: "imageDefReactorHandle",
        71: (image, value) => (image.clippingBoundaryType = Number(value)),
        14: (image, value) => {
            const vertex = new Vector2(Number(value));
            image.clipBoundaryVertices.push(vertex);
        },
        24: (image, value) => {
            if (image.clipBoundaryVertices.length > 0) {
                image.clipBoundaryVertices[
                    image.clipBoundaryVertices.length - 1
                ].y = Number(value);
            }
        },
        290: (image, value) => (image.clipMode = Number(value)),
    };
}

export class DxfCircleProps {
    // Fields
    readonly subclassMarkerCircle = "AcDbCircle"; // Code 100
    thickness?: number; // Code 39
    center: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30
    radius: number = 1; // Code 40
    extrusionDirection?: Vector3 = new Vector3(0, 0, 1); // Codes 210, 220, 230

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfCircle> = {
        subclassMarker: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerCircle),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness, 0),
        },
        center: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.center),
        },
        radius: {
            serialize: (obj) => serializeProperty(40, obj.radius),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector(
                    [210, 220, 230],
                    obj.extrusionDirection,
                    new Vector3(0, 0, 1)
                ),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfCircle> = {
        100: "subclassMarkerCircle",
        39: (circle, value) => (circle.thickness = Number(value)),
        10: (circle, value) => {
            (circle.center ??= new Vector3()).x = Number(value);
        },
        20: (circle, value) => {
            (circle.center ??= new Vector3()).y = Number(value);
        },
        30: (circle, value) => {
            (circle.center ??= new Vector3()).z = Number(value);
        },
        40: (circle, value) => (circle.radius = Number(value)),
        210: (circle, value) => {
            (circle.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (circle, value) => {
            (circle.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (circle, value) => {
            (circle.extrusionDirection ??= new Vector3()).z = Number(value);
        },
    };
}

export class DxfArcProps {
    // Fields
    readonly subclassMarkerCircle = "AcDbCircle"; // Code 100 (Circle)
    thickness?: number; // Code 39
    center: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30
    radius: number = 1; // Code 40
    readonly subclassMarkerArc = "AcDbArc"; // Code 100 (Arc)
    startAngle: number = 0; // Code 50
    endAngle: number = 360; // Code 51
    extrusionDirection?: Vector3; // Codes 210, 220, 230

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfArc> = {
        subclassMarkerCircle: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerCircle),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness, "0"),
        },
        center: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.center),
        },
        radius: {
            serialize: (obj) => serializeProperty(40, obj.radius),
        },
        subclassMarkerArc: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerArc),
        },
        startAngle: {
            serialize: (obj) => serializeProperty(50, obj.startAngle, "0"),
        },
        endAngle: {
            serialize: (obj) => serializeProperty(51, obj.endAngle, "360"),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector(
                    [210, 220, 230],
                    obj.extrusionDirection,
                    new Vector3(0, 0, 1)
                ),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfArc> = {
        39: (arc, value) => (arc.thickness = Number(value)),
        10: (arc, value) => {
            (arc.center ??= new Vector3()).x = Number(value);
        },
        20: (arc, value) => {
            (arc.center ??= new Vector3()).y = Number(value);
        },
        30: (arc, value) => {
            (arc.center ??= new Vector3()).z = Number(value);
        },
        40: (arc, value) => (arc.radius = Number(value)),
        50: (arc, value) => (arc.startAngle = Number(value)),
        51: (arc, value) => (arc.endAngle = Number(value)),
        210: (arc, value) => {
            (arc.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (arc, value) => {
            (arc.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (arc, value) => {
            (arc.extrusionDirection ??= new Vector3()).z = Number(value);
        },
    };
}

export class Dxf3DFaceProps {
    // Fields
    readonly subclassMarker = "AcDbFace"; // Code 100
    firstCorner: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30
    secondCorner: Vector3 = new Vector3(0, 0, 0); // Codes 11, 21, 31
    thirdCorner: Vector3 = new Vector3(0, 0, 0); // Codes 12, 22, 32
    fourthCorner?: Vector3; // Codes 13, 23, 33
    invisibleEdgeFlags: number = 0; // Code 70

    // Serialization Map
    static serializationMap: DxfSerializationMap<Dxf3DFace> = {
        subclassMarker: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarker),
        },
        firstCorner: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.firstCorner),
        },
        secondCorner: {
            serialize: (obj) => serializeVector([11, 21, 31], obj.secondCorner),
        },
        thirdCorner: {
            serialize: (obj) => serializeVector([12, 22, 32], obj.thirdCorner),
        },
        fourthCorner: {
            serialize: (obj) =>{  
                if(!obj.fourthCorner){
                    return serializeVector([13, 23, 33], obj.thirdCorner);
                }else{
                    return serializeVector([13, 23, 33], obj.fourthCorner);
                }
                
            },
        },
        invisibleEdgeFlags: {
            serialize: (obj) =>
                serializeProperty(70, obj.invisibleEdgeFlags, "0"),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<Dxf3DFace> = {
        10: (face, value) => {
            (face.firstCorner ??= new Vector3()).x = Number(value);
        },
        20: (face, value) => {
            (face.firstCorner ??= new Vector3()).y = Number(value);
        },
        30: (face, value) => {
            (face.firstCorner ??= new Vector3()).z = Number(value);
        },
        11: (face, value) => {
            (face.secondCorner ??= new Vector3()).x = Number(value);
        },
        21: (face, value) => {
            (face.secondCorner ??= new Vector3()).y = Number(value);
        },
        31: (face, value) => {
            (face.secondCorner ??= new Vector3()).z = Number(value);
        },
        12: (face, value) => {
            (face.thirdCorner ??= new Vector3()).x = Number(value);
        },
        22: (face, value) => {
            (face.thirdCorner ??= new Vector3()).y = Number(value);
        },
        32: (face, value) => {
            (face.thirdCorner ??= new Vector3()).z = Number(value);
        },
        13: (face, value) => {
            (face.fourthCorner ??= new Vector3()).x = Number(value);
        },
        23: (face, value) => {
            (face.fourthCorner ??= new Vector3()).y = Number(value);
        },
        33: (face, value) => {
            (face.fourthCorner ??= new Vector3()).z = Number(value);
        },
        70: (face, value) => (face.invisibleEdgeFlags = Number(value)),
    };
}

export class DxfAttributeDefinitionProps {
    readonly subclassMarkerText = "AcDbText"; // Code 100 (Subclass marker for AcDbText)

    // Properties for AcDbText
    thickness?: number = 0; // Code 39 (optional; default = 0)
    firstAlignmentPoint: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30 (First alignment point in OCS)
    textHeight: number = 1; // Code 40 (Text height)
    defaultValue: string = ""; // Code 1 (Default value)
    textRotation?: number = 0; // Code 50 (Text rotation, optional; default = 0)
    relativeXScaleFactor?: number = 1; // Code 41 (Relative X scale factor, optional; default = 1)
    obliqueAngle?: number = 0; // Code 51 (Oblique angle, optional; default = 0)
    textStyleName: string = "STANDARD"; // Code 7 (Text style name, optional; default = STANDARD)
    textGenerationFlags?: number = 0; // Code 71 (Text generation flags, optional; default = 0)
    horizontalJustification?: number = 0; // Code 72 (Horizontal text justification type, optional; default = 0)
    secondAlignmentPoint?: Vector3; // Codes 11, 21, 31 (Second alignment point in OCS, optional)
    extrusionDirection?: Vector3 = new Vector3(0, 0, 1); // Codes 210, 220, 230 (Extrusion direction, optional; default = 0, 0, 1)

    // Static fields for attribute definition group codes
    readonly subclassMarkerAttributeDefinition = "AcDbAttributeDefinition"; // Code 100 (Subclass marker for AcDbAttributeDefinition)
    versionNumber: number = 0; // Code 280 (Version number)
    promptString: string = ""; // Code 3 (Prompt string)
    tagString: string = ""; // Code 2 (Tag string)
    attributeFlags: number = 0; // Code 70 (Attribute flags)
    fieldLength?: number; // Code 73 (Field length, optional)
    verticalTextJustificationType?: number; // Code 74 (Vertical text justification type, optional)
    lockPositionFlag?: number; // Code 280 (Lock position flag)

    static deserializationMap: DxfDeserializationMap<DxfAttributeDefinition> = {

        39: (obj, value) => (obj.thickness = Number(value)),
        10: (obj, value) => (obj.firstAlignmentPoint.x = Number(value)),
        20: (obj, value) => (obj.firstAlignmentPoint.y = Number(value)),
        30: (obj, value) => (obj.firstAlignmentPoint.z = Number(value)),
        40: (obj, value) => (obj.textHeight = Number(value)),
        1: (obj, value) => (obj.defaultValue = value as string),
        50: (obj, value) => (obj.textRotation = Number(value)),
        41: (obj, value) => (obj.relativeXScaleFactor = Number(value)),
        51: (obj, value) => (obj.obliqueAngle = Number(value)),
        7: (obj, value) => (obj.textStyleName = value as string),
        71: (obj, value) => (obj.textGenerationFlags = Number(value)),
        72: (obj, value) => (obj.horizontalJustification = Number(value)),
        11: (obj, value) => {
            (obj.secondAlignmentPoint ??= new Vector3()).x = Number(value);
        },
        21: (obj, value) => {
            (obj.secondAlignmentPoint ??= new Vector3()).y = Number(value);
        },
        31: (obj, value) => {
            (obj.secondAlignmentPoint ??= new Vector3()).z = Number(value);
        },
        210: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).z = Number(value);
        },

        280: (obj, value) => {
            if (obj.versionNumber === undefined) {
                obj.versionNumber = Number(value);
            } else {
                obj.lockPositionFlag = Number(value);
            }
        },
        3: (obj, value) => (obj.promptString = String(value)),
        2: (obj, value) => (obj.tagString = String(value)),
        70: (obj, value) => (obj.attributeFlags = Number(value)),
        73: (obj, value) => (obj.fieldLength = Number(value)),
        74: (obj, value) => (obj.verticalTextJustificationType = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfAttributeDefinition> = {


        subclassMarkerText: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerText),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness),
        },
        firstAlignmentPoint: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.firstAlignmentPoint),
        },
        textHeight: {
            serialize: (obj) => serializeProperty(40, obj.textHeight),
        },
        defaultValue: {
            serialize: (obj) => serializeProperty(1, obj.defaultValue),
        },
        textRotation: {
            serialize: (obj) => serializeProperty(50, obj.textRotation),
        },
        relativeXScaleFactor: {
            serialize: (obj) =>
                serializeProperty(41, obj.relativeXScaleFactor),
        },
        obliqueAngle: {
            serialize: (obj) => serializeProperty(51, obj.obliqueAngle),
        },
        textStyleName: {
            serialize: (obj) => serializeProperty(7, obj.textStyleName),
        },
        textGenerationFlags: {
            serialize: (obj) =>
                serializeProperty(71, obj.textGenerationFlags),
        },
        horizontalJustification: {
            serialize: (obj) =>
                serializeProperty(72, obj.horizontalJustification),
        },
        secondAlignmentPoint: {
            serialize: (obj) =>
                serializeVector([11, 21, 31], obj.secondAlignmentPoint),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection, new Vector3(0,0,1)),
        },

        subclassMarkerAttributeDefinition: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerAttributeDefinition),
        },
        versionNumber: {
            serialize: (obj) => serializeProperty(280, obj.versionNumber),
        },
        promptString: {
            serialize: (obj) => serializeProperty(3, obj.promptString),
        },
        tagString: {
            serialize: (obj) => serializeProperty(2, obj.tagString),
        },
        attributeFlags: {
            serialize: (obj) => serializeProperty(70, obj.attributeFlags),
        },
        fieldLength: {
            serialize: (obj) => serializeProperty(73, obj.fieldLength),
        },
        verticalTextJustificationType: {
            serialize: (obj) =>
                serializeProperty(74, obj.verticalTextJustificationType),
        },
        lockPositionFlag: {
            serialize: (obj) => serializeProperty(280, obj.lockPositionFlag),
        },
    };
}

export class DxfAttributeProps {
    // Static fields for group codes
    readonly subclassMarkerText = "AcDbText"; // Code 100 (Subclass marker for AcDbText)
    
    // Properties for AcDbText
    thickness?: number = 0; // Code 39 (optional; default = 0)
    startPoint: Vector3 = new Vector3(0, 0, 0); // Codes 10, 20, 30 (Text start point in OCS)
    textHeight: number = 1; // Code 40 (Text height)
    defaultValue: string = ""; // Code 1 (Default value)
    textRotation?: number = 0; // Code 50 (Text rotation, optional; default = 0)

    readonly subclassMarkerAttribute = "AcDbAttribute"; // Code 100 (Subclass marker for AcDbAttribute)
    // Properties for AcDbAttribute
    versionNumber: number = 0; // Code 280 (Version number)
    attributeTag: string = ""; // Code 2 (Attribute tag, string)
    attributeFlags: number = 0; // Code 70 (Attribute flags)
    fieldLength?: number = 0; // Code 73 (Field length, optional)
    relativeXScaleFactor?: number = 1; // Code 41 (Relative X scale factor, optional; default = 1)
    obliqueAngle?: number = 0; // Code 51 (Oblique angle, optional; default = 0)
    textStyleName?: string; // Code 7 (Text style name, optional; default = STANDARD)
    textGenerationFlags?: number = 0; // Code 71 (Text generation flags, optional; default = 0)
    horizontalJustification?: HorizontalJustification = 0; // Code 72 (Horizontal text justification type, optional; default = 0)
    verticalJustification?: VerticalJustification = 0; // Code 74 (Vertical text justification type, optional; default = 0)
    alignmentPoint?: Vector3; // Codes 11, 21, 31 (Alignment point in OCS, optional)
    extrusionDirection?: Vector3 = new Vector3(0, 0, 1); // Codes 210, 220, 230 (Extrusion direction, optional; default = 0, 0, 1)
    lockPositionFlag: boolean = false; // Code 280 (Lock position flag, optional)

    static deserializationMap: DxfDeserializationMap<DxfAttribute> = {
        100: (obj, value) => { },
        39: (obj, value) => (obj.thickness = Number(value)),
        10: (obj, value) => (obj.startPoint.x = Number(value)),
        20: (obj, value) => (obj.startPoint.y = Number(value)),
        30: (obj, value) => (obj.startPoint.z = Number(value)),
        40: (obj, value) => (obj.textHeight = Number(value)),
        1: (obj, value) => (obj.defaultValue = value as string),
        280: (obj, value) => {
            //obj.versionNumber = Number(value);
            //obj.lockPositionFlag = Boolean(Number(value))
        },
        2: (obj, value) => (obj.attributeTag = value as string),
        70: (obj, value) => (obj.attributeFlags = Number(value)),
        73: (obj, value) => (obj.fieldLength = Number(value)),
        50: (obj, value) => (obj.textRotation = Number(value)),
        41: (obj, value) => (obj.relativeXScaleFactor = Number(value)),
        51: (obj, value) => (obj.obliqueAngle = Number(value)),
        7: (obj, value) => (obj.textStyleName = value as string),
        71: (obj, value) => (obj.textGenerationFlags = Number(value)),
        72: (obj, value) => (obj.horizontalJustification = Number(value)),
        74: (obj, value) => (obj.verticalJustification = Number(value)),
        11: (obj, value) => {
            (obj.alignmentPoint ??= new Vector3()).x = Number(value);
        },
        21: (obj, value) => {
            (obj.alignmentPoint ??= new Vector3()).y = Number(value);
        },
        31: (obj, value) => {
            (obj.alignmentPoint ??= new Vector3()).z = Number(value);
        },
        210: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).z = Number(value);
        }
  
    };

    static serializationMap: DxfSerializationMap<DxfAttribute> = {
        subclassMarkerText: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerText),
        },
        thickness: {
            serialize: (obj) => serializeProperty(39, obj.thickness, 0),
        },
        startPoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.startPoint),
        },
        textHeight: {
            serialize: (obj) => serializeProperty(40, obj.textHeight),
        },
        textRotation: {
            serialize: (obj) => serializeProperty(50, obj.textRotation, 0),
        },
        defaultValue: {
            serialize: (obj) => serializeProperty(1, obj.defaultValue),
        },
        subclassMarkerAttribute: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerAttribute),
        },
        versionNumber: {
            serialize: (obj) => serializeProperty(280, obj.versionNumber),
        },
        attributeTag: {
            serialize: (obj) => serializeProperty(2, obj.attributeTag),
        },
        attributeFlags: {
            serialize: (obj) => serializeProperty(70, obj.attributeFlags),
        },
        fieldLength: {
            serialize: (obj) => serializeProperty(73, obj.fieldLength),
        },
        relativeXScaleFactor: {
            serialize: (obj) =>
                serializeProperty(41, obj.relativeXScaleFactor),
        },
        obliqueAngle: {
            serialize: (obj) => serializeProperty(51, obj.obliqueAngle, 0),
        },
        textStyleName: {
            serialize: (obj) => serializeProperty(7, obj.textStyleName, "STANDARD"),
        },
        textGenerationFlags: {
            serialize: (obj) =>
                serializeProperty(71, obj.textGenerationFlags),
        },
        horizontalJustification: {
            serialize: (obj) =>
                serializeProperty(72, obj.horizontalJustification, 0),
        },
        verticalJustification: {
            serialize: (obj) =>
                serializeProperty(74, obj.verticalJustification, 0),
        },
        alignmentPoint: {
            serialize: (obj) =>
                serializeVector([11, 21, 31], obj.alignmentPoint),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
        lockPositionFlag: {
            serialize: (obj) =>
                serializeProperty(280, Number(obj.lockPositionFlag)),
        },
    };
}


export class DxfMTextProps {
    // Static fields for MText group codes
    readonly subclassMarkerMText = "AcDbMText"; // Code 100 (Subclass marker for AcDbMText)
    insertionPoint: Vector3 = new Vector3(0, 0, 0); // Code 10, 20, 30 (X, Y, Z values of insertion point)
    textHeight?: number; // Code 40 (Nominal text height)
    rectWidth?: number; // Code 41 (Reference rectangle width)
    attachmentPoint?: number; // Code 71 (Attachment point)
    drawingDirection?: number; // Code 72 (Drawing direction)
    textString: string = ""; // Code 1, 3 (Text string, split across multiple group 1 or 3)
    textStyle: string = "STANDARD"; // Code 7 (Text style name, default "STANDARD")
    extrusionDirection?: Vector3; // Code 210, 220, 230 (Extrusion direction)
    xAxisDirection?: Vector3; // Code 11, 21, 31 (X-axis direction vector)
    rotationAngle?: number; // Code 50 (Rotation angle in radians)
    lineSpacingStyle?: number; // Code 73 (Mtext line spacing style)
    lineSpacingFactor?: number; // Code 44 (Mtext line spacing factor)
    backgroundFillSetting?: number; // Code 90 (Background fill setting)
    backgroundFillColor?: number; // Code 420-429 (Background color RGB)
    backgroundColorName?: string; // Code 430-439 (Background color name)
    fillBoxScale?: number; // Code 45 (Fill box scale)
    transparency?: number; // Code 441 (Transparency of background fill color)
    columnType?: number; // Code 75 (Column type)
    columnCount?: number; // Code 76 (Column count)
    columnFlowReversed?: number; // Code 78 (Column flow reversed)
    columnAutoHeight?: number; // Code 79 (Column autoheight)
    columnWidth?: number; // Code 48 (Column width)
    columnGutter?: number; // Code 49 (Column gutter)

    // Additional optional properties for columns (if any heights are defined)
    columnHeights?: number[]; // Followed by column count and heights (Code 50)

    static deserializationMap: DxfDeserializationMap<DxfMText> = {
        100: (obj, value) => {
            if (value !== obj.subclassMarkerMText) {
                throw new Error(`Unexpected subclass marker: ${value}`);
            }
        },
        10: (obj, value) => (obj.insertionPoint.x = Number(value)),
        20: (obj, value) => (obj.insertionPoint.y = Number(value)),
        30: (obj, value) => (obj.insertionPoint.z = Number(value)),
        40: (obj, value) => (obj.textHeight = Number(value)),
        41: (obj, value) => (obj.rectWidth = Number(value)),
        71: (obj, value) => (obj.attachmentPoint = Number(value)),
        72: (obj, value) => (obj.drawingDirection = Number(value)),
        1: (obj, value) => (obj.textString += String(value)),
        3: (obj, value) => (obj.textString += String(value)), // Concatenate additional text (group code 3)
        7: (obj, value) => (obj.textStyle = String(value)),
        210: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).x = Number(value);
        },
        220: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).y = Number(value);
        },
        230: (obj, value) => {
            (obj.extrusionDirection ??= new Vector3()).z = Number(value);
        },
        11: (obj, value) => {
            (obj.xAxisDirection ??= new Vector3()).x = Number(value);
        },
        21: (obj, value) => {
            (obj.xAxisDirection ??= new Vector3()).y = Number(value);
        },
        31: (obj, value) => {
            (obj.xAxisDirection ??= new Vector3()).z = Number(value);
        },
        50: (obj, value) => {
            if (obj.rotationAngle === undefined) {
                obj.rotationAngle = Number(value);
            } else {
                (obj.columnHeights ??= []).push(Number(value));
            }
        },
        73: (obj, value) => (obj.lineSpacingStyle = Number(value)),
        44: (obj, value) => (obj.lineSpacingFactor = Number(value)),
        90: (obj, value) => (obj.backgroundFillSetting = Number(value)),
        420: (obj, value) => (obj.backgroundFillColor = Number(value)),
        430: (obj, value) => (obj.backgroundColorName = String(value)),
        45: (obj, value) => (obj.fillBoxScale = Number(value)),
        441: (obj, value) => (obj.transparency = Number(value)),
        75: (obj, value) => (obj.columnType = Number(value)),
        76: (obj, value) => (obj.columnCount = Number(value)),
        78: (obj, value) => (obj.columnFlowReversed = Number(value)),
        79: (obj, value) => (obj.columnAutoHeight = Number(value)),
        48: (obj, value) => (obj.columnWidth = Number(value)),
        49: (obj, value) => (obj.columnGutter = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfMText> = {
        subclassMarkerMText: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerMText),
        },
        insertionPoint: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.insertionPoint),
        },
        textHeight: {
            serialize: (obj) => serializeProperty(40, obj.textHeight),
        },
        rectWidth: {
            serialize: (obj) => serializeProperty(41, obj.rectWidth),
        },
        attachmentPoint: {
            serialize: (obj) => serializeProperty(71, obj.attachmentPoint),
        },
        drawingDirection: {
            serialize: (obj) => serializeProperty(72, obj.drawingDirection),
        },
        textString: {
            serialize: (obj) => serializeProperty(1, obj.textString),
        },
        textStyle: {
            serialize: (obj) => serializeProperty(7, obj.textStyle),
        },
        extrusionDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.extrusionDirection),
        },
        xAxisDirection: {
            serialize: (obj) =>
                serializeVector([11, 21, 31], obj.xAxisDirection),
        },
        rotationAngle: {
            serialize: (obj) => serializeProperty(50, obj.rotationAngle),
        },
        lineSpacingStyle: {
            serialize: (obj) => serializeProperty(73, obj.lineSpacingStyle),
        },
        lineSpacingFactor: {
            serialize: (obj) => serializeProperty(44, obj.lineSpacingFactor),
        },
        backgroundFillSetting: {
            serialize: (obj) =>
                serializeProperty(90, obj.backgroundFillSetting),
        },
        backgroundFillColor: {
            serialize: (obj) => serializeProperty(420, obj.backgroundFillColor),
        },
        backgroundColorName: {
            serialize: (obj) => serializeProperty(430, obj.backgroundColorName),
        },
        fillBoxScale: {
            serialize: (obj) => serializeProperty(45, obj.fillBoxScale),
        },
        transparency: {
            serialize: (obj) => serializeProperty(441, obj.transparency),
        },
        columnType: {
            serialize: (obj) => serializeProperty(75, obj.columnType),
        },
        columnCount: {
            serialize: (obj) => serializeProperty(76, obj.columnCount),
        },
        columnFlowReversed: {
            serialize: (obj) => serializeProperty(78, obj.columnFlowReversed),
        },
        columnAutoHeight: {
            serialize: (obj) => serializeProperty(79, obj.columnAutoHeight),
        },
        columnWidth: {
            serialize: (obj) => serializeProperty(48, obj.columnWidth),
        },
        columnGutter: {
            serialize: (obj) => serializeProperty(49, obj.columnGutter),
        },
        columnHeights: {
            serialize: (obj) => [], // serializeList(50, obj.columnHeights),
        },
    };
}

export class DxfXrecordProps {
    // Static fields for Xrecord group codes
    readonly subclassMarkerXrecord = "AcDbXrecord"; // Code 100 (Subclass marker for AcDbXrecord)
    duplicateRecordCloningFlag?: number; // Code 280 (Duplicate record cloning flag)
    mTextFlag?: number; // Code 70 (MText flag)
    isReallyLockedFlag?: number; // Code 70 (Is really locked flag)
    secondaryAttributesCount?: number; // Code 70 (Secondary attributes count)
    secondaryAttributesPointerId?: number; // Code 340 (Secondary attributes pointer ID)
    alignmentPoint: Vector3 = new Vector3(0, 0, 0); // Code 10, 20, 30 (Alignment point, X, Y, Z)
    annotationScale?: number; // Code 40 (Annotation scale)
    tagString: string = ""; // Code 2 (Tag string)

    static deserializationMap: DxfDeserializationMap<any> = {
        100: (obj, value) => {
            if (value !== obj.subclassMarkerXrecord) {
                throw new Error(`Unexpected subclass marker: ${value}`);
            }
        },
        280: (obj, value) => {
            if (obj.duplicateRecordCloningFlag === undefined) {
                obj.duplicateRecordCloningFlag = Number(value);
            } else {
                // If it's already set, assign other flags
                obj.mTextFlag = Number(value);
            }
        },
        70: (obj, value) => {
            if (obj.mTextFlag === undefined) {
                obj.mTextFlag = Number(value);
            } else if (obj.isReallyLockedFlag === undefined) {
                obj.isReallyLockedFlag = Number(value);
            } else {
                obj.secondaryAttributesCount = Number(value);
            }
        },
        340: (obj, value) => (obj.secondaryAttributesPointerId = Number(value)),
        10: (obj, value) => (obj.alignmentPoint.x = Number(value)),
        20: (obj, value) => (obj.alignmentPoint.y = Number(value)),
        30: (obj, value) => (obj.alignmentPoint.z = Number(value)),
        40: (obj, value) => (obj.annotationScale = Number(value)),
        2: (obj, value) => (obj.tagString = String(value)),
    };

    static serializationMap: DxfSerializationMap<any> = {
        subclassMarkerXrecord: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerXrecord),
        },
        duplicateRecordCloningFlag: {
            serialize: (obj) =>
                serializeProperty(280, obj.duplicateRecordCloningFlag),
        },
        mTextFlag: {
            serialize: (obj) => serializeProperty(70, obj.mTextFlag),
        },
        isReallyLockedFlag: {
            serialize: (obj) => serializeProperty(70, obj.isReallyLockedFlag),
        },
        secondaryAttributesCount: {
            serialize: (obj) =>
                serializeProperty(70, obj.secondaryAttributesCount),
        },
        secondaryAttributesPointerId: {
            serialize: (obj) =>
                serializeProperty(340, obj.secondaryAttributesPointerId),
        },
        alignmentPoint: {
            serialize: (obj) =>
                serializeVector([10, 20, 30], obj.alignmentPoint),
        },
        annotationScale: {
            serialize: (obj) => serializeProperty(40, obj.annotationScale),
        },
        tagString: {
            serialize: (obj) => serializeProperty(2, obj.tagString),
        },
    };
}

export class DxfBlockProps {
    // Static fields for block group codes
    handle?: string; // Code 5 (Handle)
    ownerHandle?: string; // Code 330 (Soft-pointer ID/handle to owner object)
    readonly subclassMarkerEntity = "AcDbEntity"; // Code 100 (Subclass marker for AcDbEntity)
    layerName: string = ""; // Code 8 (Layer name)
    readonly subclassMarkerBlock = "AcDbBlockBegin"; // Code 100 (Subclass marker for AcDbBlockBegin)
    blockName: string = ""; // Code 2 (Block name)
    blockFlags: number = 0; // Code 70 (Block-type flags, bit-coded)
    basePoint: Vector3 = new Vector3(); // Codes 10, 20, 30 (Base point in WCS)
    xrefPathName?: string; // Code 1 (Xref path name, optional)
    blockDescription?: string; // Code 4 (Block description, optional)

    static deserializationMap: DxfDeserializationMap<DxfBlock> = {
        5: (obj, value) => (obj.handle = String(value)),
        330: (obj, value) => (obj.ownerHandle = String(value)),
        100: (obj, value) => {},
        8: (obj, value) => (obj.layerName = String(value)),
        2: (obj, value) => (obj.blockName = String(value)),
        70: (obj, value) => (obj.blockFlags = Number(value)),
        10: (obj, value) => (obj.basePoint.x = Number(value)),
        20: (obj, value) => (obj.basePoint.y = Number(value)),
        30: (obj, value) => (obj.basePoint.z = Number(value)),
        1: (obj, value) => (obj.xrefPathName = String(value)),
        4: (obj, value) => (obj.blockDescription = String(value)),
    };

    static serializationMap: DxfSerializationMap<DxfBlock> = {

        handle: {
            serialize: (obj) => serializeProperty(5, obj.handle),
        },
        ownerHandle: {
            serialize: (obj) => serializeProperty(330, obj.ownerHandle),
        },
        subclassMarkerEntity: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerEntity),
        },
        layerName: {
            serialize: (obj) => serializeProperty(8, obj.layerName),
        },
        subclassMarkerBlock: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerBlock),
        },
        blockName: {
            serialize: (obj) => serializeProperty(2, obj.blockName),
        },
        blockFlags: {
            serialize: (obj) => serializeProperty(70, obj.blockFlags),
        },
        basePoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.basePoint),
        },
        xrefPathName: {
            serialize: (obj) =>
                obj.xrefPathName ? serializeProperty(1, obj.xrefPathName) : [],
        },
        blockDescription: {
            serialize: (obj) =>
                obj.blockDescription ? serializeProperty(4, obj.blockDescription) : [],
        },
    };
}

export class DxfEndBlockProps {
    handle: string = ""; // Group code 5 (Handle, optional)
    xDictionary?: DxfExtensionDictionary; // Custom type for extension dictionary
    ownerHandleSoftPointer?: string; // Group code 330 (Soft-pointer ID/handle to owner object, optional)
    readonly subclassMarkerEntity = "AcDbEntity"; // Group code 100 (Subclass marker for AcDbEntity)
    layerName?: string; // Group code 8 (Layer name, optional)
    readonly subclassMarkerBlockEnd = "AcDbBlockEnd"; // Group code 100 (Subclass marker for AcDbBlockEnd)

    static deserializationMap: DxfDeserializationMap<DxfEndBlock> = {
        5: (obj, value) => (obj.handle = String(value)),
        102:(entity, value) => deserializeXdictKey(entity, String(value)),
        360:(entity, value) => deserializeXdict(entity, String(value)),
        330: (obj, value) => (obj.ownerHandleSoftPointer = String(value)),
        100: (obj, value) => {
            if (value !== "AcDbEntity" && value !== "AcDbBlockEnd") {
                throw new Error(`Unexpected subclass marker: ${value}`);
            }
        },
        8: (obj, value) => (obj.layerName = String(value)),
    };

    static serializationMap: DxfSerializationMap<DxfEndBlock> = {
        handle: {
            serialize: (obj) =>
                obj.handle ? serializeProperty(5, obj.handle) : [],
        },
        xDictionary: {
            serialize: (obj) =>
                serializeExtensionDictionary(obj.xDictionary),
        },
        ownerHandleSoftPointer: {
            serialize: (obj) =>
                obj.ownerHandleSoftPointer
                    ? serializeProperty(330, obj.ownerHandleSoftPointer)
                    : [],
        },
        subclassMarkerEntity: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerEntity),
        },
        layerName: {
            serialize: (obj) =>
                obj.layerName ? serializeProperty(8, obj.layerName) : [],
        },
        subclassMarkerBlockEnd: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerBlockEnd),
        },
    };
}

export class DxfCommonObjectProps {
    handle: string = ""; // Group code 5 (Handle, optional)
    xDictionary: DxfExtensionDictionary = {"ACAD_XDICTIONARY":[]}; // Custom type for extension dictionary
    reactorsDictionary: DxfExtensionDictionary = {"ACAD_REACTORS":[]}; // Custom type for reactors dictionary
    ownerHandle?: string; // Group code 330 (Soft-pointer ID/handle to owner object, optional)

    static deserializationMap: DxfDeserializationMap<any> = {
        5: (obj, value) => (obj.handle = String(value)),
        102:(entity, value) => deserializeXdictKey(entity, String(value)),
        360:(entity, value) => deserializeXdict(entity, String(value)),
        330: (obj, value) => {
            obj.ownerHandle = String(value);
        },
    };

    static serializationMap: DxfSerializationMap<any> = {
        handle: {
            serialize: (obj) =>
                obj.handle ? serializeProperty(5, obj.handle) : [],
        },
        xDictionary: {
            serialize: (obj) =>
                serializeExtensionDictionary(obj.xDictionary),
        },
        reactorsDictionary: {
            serialize: (obj) =>
                serializeExtensionDictionary(obj.reactorsDictionary),
        },
        ownerHandle: {
            serialize: (obj) => serializeProperty(330, obj.ownerHandle),
        },
    };
}

export class DxfImageDefinitionProps {
    readonly subclassMarkerImageDef = "AcDbRasterImageDef"; // Group code 100 (Subclass marker for AcDbRasterImageDef)
    classVersion: number = 0; // Group code 90 (Class version, default = 0)
    fileName: string = ""; // Group code 1 (File name of image)
    imageSize: Vector2 = new Vector2(); // Group codes 10, 20 (Image size in pixels: U, V values)
    pixelSize: Vector2 = new Vector2(); // Group codes 11, 21 (Default size of one pixel in AutoCAD units: U, V values)
    imageLoaded: boolean = true; // Group code 280 (Image-is-loaded flag)
    resolutionUnits: number = 0; // Group code 281 (Resolution units)

    static deserializationMap: DxfDeserializationMap<DxfImageDefinition> = {
        90: (obj, value) => (obj.classVersion = Number(value)),
        1: (obj, value) => (obj.fileName = String(value)),
        10: (obj, value) => (obj.imageSize.x = Number(value)),
        20: (obj, value) => (obj.imageSize.y = Number(value)),
        11: (obj, value) => (obj.pixelSize.x = Number(value)),
        21: (obj, value) => (obj.pixelSize.y = Number(value)),
        280: (obj, value) => (obj.imageLoaded = value === "1"),
        281: (obj, value) => (obj.resolutionUnits = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfImageDefinition> = {
        subclassMarkerImageDef: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerImageDef),
        },
        classVersion: {
            serialize: (obj) => serializeProperty(90, obj.classVersion),
        },
        fileName: {
            serialize: (obj) => serializeProperty(1, obj.fileName),
        },
        imageSize: {
            serialize: (obj) => serializeVector([10, 20], obj.imageSize),
        },
        pixelSize: {
            serialize: (obj) => serializeVector([11, 21], obj.pixelSize),
        },
        imageLoaded: {
            serialize: (obj) =>
                serializeProperty(280, obj.imageLoaded ? "1" : "0"),
        },
        resolutionUnits: {
            serialize: (obj) => serializeProperty(281, obj.resolutionUnits),
        },
    };
}

export class DxfRasterVariablesProps {
    // Static fields for raster variables group codes
    readonly subclassMarker = "AcDbRasterVariables"; // Code 100 (Subclass marker for AcDbRasterVariables)
    classVersion: number = 0; // Code 90 (Class version, default = 0)
    displayImageFrame: boolean = false; // Code 70 (Display-image-frame flag, default = false)
    imageDisplayQuality: boolean = false; // Code 71 (Image display quality, default = false)
    insertUnits: InsertUnitsForImage = InsertUnitsForImage.Foot; // Code 72 (AutoCAD units for inserting images, default = 0)

    static deserializationMap: DxfDeserializationMap<DxfRasterVariables> = {
        100: (obj, value) => {},
        90: (obj, value) => (obj.classVersion = Number(value)),
        70: (obj, value) => (obj.displayImageFrame = Boolean(Number(value))),
        71: (obj, value) => (obj.imageDisplayQuality = Boolean(Number(value))),
        72: (obj, value) => (obj.insertUnits = Number(value)),
    };

    static serializationMap: DxfSerializationMap<DxfRasterVariables> = {
        subclassMarker: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarker),
        },
        classVersion: {
            serialize: (obj) => serializeProperty(90, obj.classVersion),
        },
        displayImageFrame: {
            serialize: (obj) => serializeProperty(70, Number(obj.displayImageFrame)),
        },
        imageDisplayQuality: {
            serialize: (obj) => serializeProperty(71, Number(obj.imageDisplayQuality)),
        },
        insertUnits: {
            serialize: (obj) => serializeProperty(72, obj.insertUnits),
        },
    };
}

export class DxfImageDefReactorProps {
    // Fields
    readonly subclassMarkerImageDefReactor = "AcDbRasterImageDefReactor"; // Code 100
    classVersion: number = 2; // Code 90
    associatedImageObjectId?: string; // Code 330

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfImageDefReactor> = {
        subclassMarkerImageDefReactor: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerImageDefReactor),
        },
        classVersion: {
            serialize: (obj) => serializeProperty(90, obj.classVersion),
        },
        associatedImageObjectId: {
            serialize: (obj) =>
                serializeProperty(330, obj.associatedImageObjectId),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfImageDefReactor> = {
        100: "subclassMarkerImageDefReactor",
        90: (reactor, value) => (reactor.classVersion = Number(value)),
        330: (reactor, value) =>
            (reactor.associatedImageObjectId = String(value)),
    };
}

export class DxfLayoutProps {
    readonly subclassMarkerLayout = "AcDbLayout"; // Group code 100 (AcDbLayout)
    layoutName: string = ""; // Group code 1 (Layout name)
    flag: number = 0; // Group code 70 (Bit-coded flag for PSLTSCALE and LIMCHECK)
    tabOrder: number = 0; // Group code 71 (Tab order)
    minimumLimits: Vector2 = new Vector2(0, 0); // Group codes 10, 20 (Minimum limits: LIMMIN)
    maximumLimits: Vector2 = new Vector2(0, 0); // Group codes 11, 21 (Maximum limits: LIMMAX)
    insertionBasePoint: Vector3 = new Vector3(0, 0, 0); // Group codes 12, 22, 32 (Insertion base point: INSBASE)
    minimumExtents: Vector3 = new Vector3(0, 0, 0); // Group codes 14, 24, 34 (Minimum extents: EXTMIN)
    maximumExtents: Vector3 = new Vector3(0, 0, 0); // Group codes 15, 25, 35 (Maximum extents: EXTMAX)
    elevation: number = 0; // Group code 146 (Elevation)
    ucsOrigin: Vector3 = new Vector3(0, 0, 0); // Group codes 13, 23, 33 (UCS origin)
    ucsXAxis: Vector3 = new Vector3(1, 0, 0); // Group codes 16, 26, 36 (UCS X-axis)
    ucsYAxis: Vector3 = new Vector3(0, 1, 0); // Group codes 17, 27, 37 (UCS Y-axis)
    orthographicType: number = 0; // Group code 76 (Orthographic UCS type)
    associatedPaperSpaceBlockHandle?: string; // Group code 330 (Handle to associated paper space block)
    lastActiveViewportHandle?: string; // Group code 331 (Handle to last active viewport)
    namedUcsHandle?: string; // Group code 345 (Handle to named UCS)
    baseUcsHandle?: string; // Group code 346 (Handle to base UCS if orthographic)
    shadePlotHandle?: string; // Group code 333 (Handle to shade plot ID)

    static deserializationMap: DxfDeserializationMap<DxfLayout> = {
        1: (obj, value) => (obj.layoutName = String(value)),
        70: (obj, value) => (obj.flag = Number(value)),
        71: (obj, value) => (obj.tabOrder = Number(value)),
        10: (obj, value) => (obj.minimumLimits.x = Number(value)),
        20: (obj, value) => (obj.minimumLimits.y = Number(value)),
        11: (obj, value) => (obj.maximumLimits.x = Number(value)),
        21: (obj, value) => (obj.maximumLimits.y = Number(value)),
        12: (obj, value) => (obj.insertionBasePoint.x = Number(value)),
        22: (obj, value) => (obj.insertionBasePoint.y = Number(value)),
        32: (obj, value) => (obj.insertionBasePoint.z = Number(value)),
        14: (obj, value) => (obj.minimumExtents.x = Number(value)),
        24: (obj, value) => (obj.minimumExtents.y = Number(value)),
        34: (obj, value) => (obj.minimumExtents.z = Number(value)),
        15: (obj, value) => (obj.maximumExtents.x = Number(value)),
        25: (obj, value) => (obj.maximumExtents.y = Number(value)),
        35: (obj, value) => (obj.maximumExtents.z = Number(value)),
        146: (obj, value) => (obj.elevation = Number(value)),
        13: (obj, value) => (obj.ucsOrigin.x = Number(value)),
        23: (obj, value) => (obj.ucsOrigin.y = Number(value)),
        33: (obj, value) => (obj.ucsOrigin.z = Number(value)),
        16: (obj, value) => (obj.ucsXAxis.x = Number(value)),
        26: (obj, value) => (obj.ucsXAxis.y = Number(value)),
        36: (obj, value) => (obj.ucsXAxis.z = Number(value)),
        17: (obj, value) => (obj.ucsYAxis.x = Number(value)),
        27: (obj, value) => (obj.ucsYAxis.y = Number(value)),
        37: (obj, value) => (obj.ucsYAxis.z = Number(value)),
        76: (obj, value) => (obj.orthographicType = Number(value)),
        330: (obj, value) =>
            (obj.associatedPaperSpaceBlockHandle = String(value)),
        331: (obj, value) => (obj.lastActiveViewportHandle = String(value)),
        345: (obj, value) => (obj.namedUcsHandle = String(value)),
        346: (obj, value) => (obj.baseUcsHandle = String(value)),
        333: (obj, value) => (obj.shadePlotHandle = String(value)),
    };

    static serializationMap: DxfSerializationMap<DxfLayout> = {
        subclassMarkerLayout: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerLayout),
        },
        layoutName: {
            serialize: (obj) => serializeProperty(1, obj.layoutName),
        },
        flag: {
            serialize: (obj) => serializeProperty(70, obj.flag),
        },
        tabOrder: {
            serialize: (obj) => serializeProperty(71, obj.tabOrder),
        },
        minimumLimits: {
            serialize: (obj) => serializeVector([10, 20], obj.minimumLimits),
        },
        maximumLimits: {
            serialize: (obj) => serializeVector([11, 21], obj.maximumLimits),
        },
        insertionBasePoint: {
            serialize: (obj) =>
                serializeVector([12, 22, 32], obj.insertionBasePoint),
        },
        minimumExtents: {
            serialize: (obj) =>
                serializeVector([14, 24, 34], obj.minimumExtents),
        },
        maximumExtents: {
            serialize: (obj) =>
                serializeVector([15, 25, 35], obj.maximumExtents),
        },
        elevation: {
            serialize: (obj) => serializeProperty(146, obj.elevation),
        },
        ucsOrigin: {
            serialize: (obj) => serializeVector([13, 23, 33], obj.ucsOrigin),
        },
        ucsXAxis: {
            serialize: (obj) => serializeVector([16, 26, 36], obj.ucsXAxis),
        },
        ucsYAxis: {
            serialize: (obj) => serializeVector([17, 27, 37], obj.ucsYAxis),
        },
        orthographicType: {
            serialize: (obj) => serializeProperty(76, obj.orthographicType),
        },
        associatedPaperSpaceBlockHandle: {
            serialize: (obj) =>
                serializeProperty(330, obj.associatedPaperSpaceBlockHandle),
        },
        lastActiveViewportHandle: {
            serialize: (obj) =>
                serializeProperty(331, obj.lastActiveViewportHandle),
        },
        namedUcsHandle: {
            serialize: (obj) => serializeProperty(345, obj.namedUcsHandle),
        },
        baseUcsHandle: {
            serialize: (obj) => serializeProperty(346, obj.baseUcsHandle),
        },
        shadePlotHandle: {
            serialize: (obj) => serializeProperty(333, obj.shadePlotHandle),
        },
    };
}

export class DxfPlotSettingsProps {
    readonly subclassMarker = "AcDbPlotSettings"; // Group code 100 (Subclass marker)

    pageSetupName?: string = ""; // Group code 1 (Page Setup name)
    printerName?: string = ""; // Group code 2 (System printer or plot config file)
    paperSize?: string = ""; // Group code 4 (Paper size)
    plotViewName?: string = ""; // Group code 6 (Plot view name)
    unprintableMargin?: DxfMargin; // Group codes 40-43
    plotPaperSize?: Vector2; // Group codes 44, 45
    plotOrigin?: Vector2; // Group codes 46, 47
    plotWindowArea?: { lowerLeft: Vector2; upperRight: Vector2 }; // Group codes 48, 49, 140, 141
    customPrintScale?: { numerator: number; denominator: number }; // Group codes 142, 143

    plotLayoutFlags: number = 0; // Group code 70 (Bit-coded flag)
    plotPaperUnits?: number; // Group code 72 (Plot paper units) 0 = inches, 1 = mm, 2 = pixels
    plotRotation?: number; // Group code 73 (Plot rotation)
    plotType?: number; // Group code 74 (Plot type)
    standardScaleType?: number; // Group code 75 (Standard scale type)
    shadePlotMode?: number; // Group code 76 (ShadePlot mode)
    shadePlotResolutionLevel?: number; // Group code 77 (ShadePlot resolution level)
    shadePlotCustomDpi?: number; // Group code 78 (ShadePlot custom DPI, optional)
    scaleFactor?: number; // Group code 147 (Floating point scale factor for standard scale)

    paperImageOrigin?: Vector2; // Group codes 148, 149

    shadePlotHandle?: string; // Group code 333 (Optional ShadePlot handle)

    static deserializationMap: DxfDeserializationMap<DxfPlotSettings> = {
        1: (obj, value) => (obj.pageSetupName = String(value)),
        2: (obj, value) => (obj.printerName = String(value)),
        4: (obj, value) => (obj.paperSize = String(value)),
        6: (obj, value) => (obj.plotViewName = String(value)),
        40: (obj, value) => {
            obj.unprintableMargin ||= { left: 0, bottom: 0, right: 0, top: 0 };
            obj.unprintableMargin.left = Number(value);
        },
        41: (obj, value) => {
            obj.unprintableMargin ||= { left: 0, bottom: 0, right: 0, top: 0 };
            obj.unprintableMargin.bottom = Number(value);
        },
        42: (obj, value) => {
            obj.unprintableMargin ||= { left: 0, bottom: 0, right: 0, top: 0 };
            obj.unprintableMargin.right = Number(value);
        },
        43: (obj, value) => {
            obj.unprintableMargin ||= { left: 0, bottom: 0, right: 0, top: 0 };
            obj.unprintableMargin.top = Number(value);
        },
        44: (obj, value) => {
            obj.plotPaperSize = obj.plotPaperSize || new Vector2();
            obj.plotPaperSize.x = Number(value);
        },
        45: (obj, value) => {
            obj.plotPaperSize = obj.plotPaperSize || new Vector2();
            obj.plotPaperSize.y = Number(value);
        },
        46: (obj, value) => {
            obj.plotOrigin = obj.plotOrigin || new Vector2();
            obj.plotOrigin.x = Number(value);
        },
        47: (obj, value) => {
            obj.plotOrigin = obj.plotOrigin || new Vector2();
            obj.plotOrigin.y = Number(value);
        },
        48: (obj, value) => {
            obj.plotWindowArea = obj.plotWindowArea || {
                lowerLeft: new Vector2(),
                upperRight: new Vector2(),
            };
            obj.plotWindowArea.lowerLeft.x = Number(value);
        },
        49: (obj, value) => {
            obj.plotWindowArea = obj.plotWindowArea || {
                lowerLeft: new Vector2(),
                upperRight: new Vector2(),
            };
            obj.plotWindowArea.lowerLeft.y = Number(value);
        },
        140: (obj, value) => {
            obj.plotWindowArea = obj.plotWindowArea || {
                lowerLeft: new Vector2(),
                upperRight: new Vector2(),
            };
            obj.plotWindowArea.upperRight.x = Number(value);
        },
        141: (obj, value) => {
            obj.plotWindowArea = obj.plotWindowArea || {
                lowerLeft: new Vector2(),
                upperRight: new Vector2(),
            };
            obj.plotWindowArea.upperRight.y = Number(value);
        },
        142: (obj, value) => {
            obj.customPrintScale = obj.customPrintScale || {
                numerator: 1,
                denominator: 1,
            };
            obj.customPrintScale.numerator = Number(value);
        },
        143: (obj, value) => {
            obj.customPrintScale = obj.customPrintScale || {
                numerator: 1,
                denominator: 1,
            };
            obj.customPrintScale.denominator = Number(value);
        },
        70: (obj, value) => (obj.plotLayoutFlags = Number(value)),
        72: (obj, value) => (obj.plotPaperUnits = Number(value)),
        73: (obj, value) => (obj.plotRotation = Number(value)),
        74: (obj, value) => (obj.plotType = Number(value)),
        75: (obj, value) => (obj.standardScaleType = Number(value)),
        76: (obj, value) => (obj.shadePlotMode = Number(value)),
        77: (obj, value) => (obj.shadePlotResolutionLevel = Number(value)),
        78: (obj, value) => (obj.shadePlotCustomDpi = Number(value)),
        147: (obj, value) => (obj.scaleFactor = Number(value)),
        148: (obj, value) => {
            obj.paperImageOrigin ||= new Vector2();
            obj.paperImageOrigin.x = Number(value);
        },
        149: (obj, value) => {
            obj.paperImageOrigin ||= new Vector2();
            obj.paperImageOrigin.y = Number(value);
        },
        333: (obj, value) => (obj.shadePlotHandle = String(value)),
    };

    static serializationMap: DxfSerializationMap<DxfPlotSettings> = {
        subclassMarker: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarker),
        },
        pageSetupName: {
            serialize: (obj) => serializeProperty(1, obj.pageSetupName),
        },
        printerName: {
            serialize: (obj) => serializeProperty(2, obj.printerName),
        },
        paperSize: {
            serialize: (obj) => serializeProperty(4, obj.paperSize),
        },
        plotViewName: {
            serialize: (obj) => serializeProperty(6, obj.plotViewName),
        },
        unprintableMargin: {
            serialize: (obj) =>
                serializeMargins([40, 41, 42, 43], obj.unprintableMargin),
        },
        plotPaperSize: {
            serialize: (obj) => serializeVector([44, 45], obj.plotPaperSize),
        },
        plotOrigin: {
            serialize: (obj) => serializeVector([46, 47], obj.plotOrigin),
        },
        plotWindowArea: {
            serialize: (obj) => {
                const lowerLeft = serializeVector(
                    [48, 49],
                    obj.plotWindowArea?.lowerLeft
                );
                const upperRight = serializeVector(
                    [140, 141],
                    obj.plotWindowArea?.upperRight
                );
                IterUtils.extendArray(lowerLeft, upperRight);
                return lowerLeft;
            },
        },
        customPrintScale: {
            serialize: (obj) =>
                serializeScale([142, 143], obj.customPrintScale),
        },
        plotLayoutFlags: {
            serialize: (obj) => serializeProperty(70, obj.plotLayoutFlags),
        },
        plotPaperUnits: {
            serialize: (obj) => serializeProperty(72, obj.plotPaperUnits),
        },
        plotRotation: {
            serialize: (obj) => serializeProperty(73, obj.plotRotation),
        },
        plotType: {
            serialize: (obj) => serializeProperty(74, obj.plotType),
        },
        standardScaleType: {
            serialize: (obj) => serializeProperty(75, obj.standardScaleType),
        },
        shadePlotMode: {
            serialize: (obj) => serializeProperty(76, obj.shadePlotMode),
        },
        shadePlotResolutionLevel: {
            serialize: (obj) =>
                serializeProperty(77, obj.shadePlotResolutionLevel),
        },
        shadePlotCustomDpi: {
            serialize: (obj) => serializeProperty(78, obj.shadePlotCustomDpi),
        },
        scaleFactor: {
            serialize: (obj) => serializeProperty(147, obj.scaleFactor),
        },
        paperImageOrigin: {
            serialize: (obj) =>
                serializeVector([148, 149], obj.paperImageOrigin),
        },
        shadePlotHandle: {
            serialize: (obj) => serializeProperty(333, obj.shadePlotHandle),
        },
    };
}

export class DxfViewportProps {
    readonly subclassMarkerViewport = "AcDbViewport"; // Group code 100 (Subclass marker)

    centerPoint?: Vector3; // Group codes 10, 20, 30 (WCS center point)
    width?: number; // Group code 40 (Width in paper space units)
    height?: number; // Group code 41 (Height in paper space units)

    viewportStatus?: number; // Group code 68 (Viewport status field)
    viewportId: number = 0; // Group code 69 (Viewport ID)

    viewCenter?: Vector2; // Group codes 12, 22 (View center in DCS)
    snapBasePoint?: Vector2; // Group codes 13, 23 (Snap base point)
    snapSpacing?: Vector2; // Group codes 14, 24 (Snap spacing)
    gridSpacing?: Vector2; // Group codes 15, 25 (Grid spacing)
    viewDirection?: Vector2; // Group codes 16, 26, 36 (View direction vector in WCS)
    viewTarget?: Vector2; // Group codes 17, 27, 37 (View target point in WCS)

    perspectiveLensLength?: number; // Group code 42
    frontClipPlane?: number; // Group code 43 (Front clip plane Z value)
    backClipPlane?: number; // Group code 44 (Back clip plane Z value)
    viewHeight?: number; // Group code 45 (View height in model space units)
    snapAngle?: number; // Group code 50 (Snap angle)
    viewTwistAngle?: number; // Group code 51 (View twist angle)
    circleZoomPercent?: number; // Group code 72 (Circle zoom percent)

    viewportFlags?: number; // Group code 90 (Viewport status bit-coded flags)

    ucsOrigin?: Vector3; // Group codes 110, 120, 130 (UCS origin)
    ucsXAxis?: Vector3; // Group codes 111, 121, 131 (UCS X-axis)
    ucsYAxis?: Vector3; // Group codes 112, 122, 132 (UCS Y-axis)
    ucsType?: number; // Group code 79 (Orthographic type of UCS)
    elevation?: number; // Group code 146 (Elevation)
    shadePlotMode?: number; // Group code 170 (ShadePlot mode)

    renderMode?: number; // Group code 281 (Render mode)
    defaultLighting?: boolean; // Group code 292 (Default lighting flag)
    defaultLightingType?: number; // Group code 282 (Default lighting type)
    viewBrightness?: number; // Group code 141 (View brightness)
    viewContrast?: number; // Group code 142 (View contrast)

    ambientLightColor?: DxfRgb; // Group codes 63, 421, 431 (Ambient light color)

    static serializationMap: DxfSerializationMap<DxfViewport> = {
        subclassMarker: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerViewport),
        },
        centerPoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.centerPoint),
        },
        width: {
            serialize: (obj) => serializeProperty(40, obj.width),
        },
        height: {
            serialize: (obj) => serializeProperty(41, obj.height),
        },
        viewportStatus: {
            serialize: (obj) => serializeProperty(68, obj.viewportStatus),
        },
        viewportId: {
            serialize: (obj) => serializeProperty(69, obj.viewportId),
        },
        viewCenter: {
            serialize: (obj) => serializeVector([12, 22], obj.viewCenter),
        },
        snapBasePoint: {
            serialize: (obj) => serializeVector([13, 23], obj.snapBasePoint),
        },
        snapSpacing: {
            serialize: (obj) => serializeVector([14, 24], obj.snapSpacing),
        },
        gridSpacing: {
            serialize: (obj) => serializeVector([15, 25], obj.gridSpacing),
        },
        viewDirection: {
            serialize: (obj) => serializeVector([16, 26], obj.viewDirection),
        },
        viewTarget: {
            serialize: (obj) => serializeVector([17, 27], obj.viewTarget),
        },
        perspectiveLensLength: {
            serialize: (obj) =>
                serializeProperty(42, obj.perspectiveLensLength),
        },
        frontClipPlane: {
            serialize: (obj) => serializeProperty(43, obj.frontClipPlane),
        },
        backClipPlane: {
            serialize: (obj) => serializeProperty(44, obj.backClipPlane),
        },
        viewHeight: {
            serialize: (obj) => serializeProperty(45, obj.viewHeight),
        },
        snapAngle: {
            serialize: (obj) => serializeProperty(50, obj.snapAngle),
        },
        viewTwistAngle: {
            serialize: (obj) => serializeProperty(51, obj.viewTwistAngle),
        },
        circleZoomPercent: {
            serialize: (obj) => serializeProperty(72, obj.circleZoomPercent),
        },
        viewportFlags: {
            serialize: (obj) => serializeProperty(90, obj.viewportFlags),
        },
        ucsOrigin: {
            serialize: (obj) => serializeVector([110, 120, 130], obj.ucsOrigin),
        },
        ucsXAxis: {
            serialize: (obj) => serializeVector([111, 121, 131], obj.ucsXAxis),
        },
        ucsYAxis: {
            serialize: (obj) => serializeVector([112, 122, 132], obj.ucsYAxis),
        },
        ucsType: {
            serialize: (obj) => serializeProperty(79, obj.ucsType),
        },
        elevation: {
            serialize: (obj) => serializeProperty(146, obj.elevation),
        },
        shadePlotMode: {
            serialize: (obj) => serializeProperty(170, obj.shadePlotMode),
        },
        renderMode: {
            serialize: (obj) => serializeProperty(281, obj.renderMode),
        },
        defaultLighting: {
            serialize: (obj) =>
                serializeProperty(292, obj.defaultLighting ? 1 : 0),
        },
        defaultLightingType: {
            serialize: (obj) => serializeProperty(282, obj.defaultLightingType),
        },
        viewBrightness: {
            serialize: (obj) => serializeProperty(141, obj.viewBrightness),
        },
        viewContrast: {
            serialize: (obj) => serializeProperty(142, obj.viewContrast),
        },
        ambientLightColor: {
            serialize: (obj) =>
                serializeColor([63, 421, 431], obj.ambientLightColor),
        },
    };

    static deserializationMap: DxfDeserializationMap<DxfViewport> = {
        10: (obj, value) => {
            obj.centerPoint ||= new Vector3();
            obj.centerPoint.x = Number(value);
        },
        20: (obj, value) => {
            obj.centerPoint ||= new Vector3();
            obj.centerPoint.y = Number(value);
        },
        30: (obj, value) => {
            obj.centerPoint ||= new Vector3();
            obj.centerPoint.z = Number(value);
        },
        40: (obj, value) => (obj.width = Number(value)),
        41: (obj, value) => (obj.height = Number(value)),
        68: (obj, value) => (obj.viewportStatus = Number(value)),
        69: (obj, value) => (obj.viewportId = Number(value)),
        12: (obj, value) => {
            obj.viewCenter ||= new Vector2();
            obj.viewCenter.x = Number(value);
        },
        22: (obj, value) => {
            obj.viewCenter ||= new Vector2();
            obj.viewCenter.y = Number(value);
        },
        13: (obj, value) => {
            obj.snapBasePoint ||= new Vector2();
            obj.snapBasePoint.x = Number(value);
        },
        23: (obj, value) => {
            obj.snapBasePoint ||= new Vector2();
            obj.snapBasePoint.y = Number(value);
        },
        14: (obj, value) => {
            obj.snapSpacing ||= new Vector2();
            obj.snapSpacing.x = Number(value);
        },
        24: (obj, value) => {
            obj.snapSpacing ||= new Vector2();
            obj.snapSpacing.y = Number(value);
        },
        15: (obj, value) => {
            obj.gridSpacing ||= new Vector2();
            obj.gridSpacing.x = Number(value);
        },
        25: (obj, value) => {
            obj.gridSpacing ||= new Vector2();
            obj.gridSpacing.y = Number(value);
        },
        16: (obj, value) => {
            obj.viewDirection ||= new Vector2();
            obj.viewDirection.x = Number(value);
        },
        26: (obj, value) => {
            obj.viewDirection ||= new Vector2();
            obj.viewDirection.y = Number(value);
        },
        17: (obj, value) => {
            obj.viewTarget ||= new Vector2();
            obj.viewTarget.x = Number(value);
        },
        27: (obj, value) => {
            obj.viewTarget ||= new Vector2();
            obj.viewTarget.y = Number(value);
        },
        42: (obj, value) => (obj.perspectiveLensLength = Number(value)),
        43: (obj, value) => (obj.frontClipPlane = Number(value)),
        44: (obj, value) => (obj.backClipPlane = Number(value)),
        45: (obj, value) => (obj.viewHeight = Number(value)),
        50: (obj, value) => (obj.snapAngle = Number(value)),
        51: (obj, value) => (obj.viewTwistAngle = Number(value)),
        72: (obj, value) => (obj.circleZoomPercent = Number(value)),
        90: (obj, value) => (obj.viewportFlags = Number(value)),
        110: (obj, value) => {
            obj.ucsOrigin ||= new Vector3();
            obj.ucsOrigin.x = Number(value);
        },
        120: (obj, value) => {
            obj.ucsOrigin ||= new Vector3();
            obj.ucsOrigin.y = Number(value);
        },
        130: (obj, value) => {
            obj.ucsOrigin ||= new Vector3();
            obj.ucsOrigin.z = Number(value);
        },
        111: (obj, value) => {
            obj.ucsXAxis ||= new Vector3();
            obj.ucsXAxis.x = Number(value);
        },
        121: (obj, value) => {
            obj.ucsXAxis ||= new Vector3();
            obj.ucsXAxis.y = Number(value);
        },
        131: (obj, value) => {
            obj.ucsXAxis ||= new Vector3();
            obj.ucsXAxis.z = Number(value);
        },
        112: (obj, value) => {
            obj.ucsYAxis ||= new Vector3();
            obj.ucsYAxis.x = Number(value);
        },
        122: (obj, value) => {
            obj.ucsYAxis ||= new Vector3();
            obj.ucsYAxis.y = Number(value);
        },
        132: (obj, value) => {
            obj.ucsYAxis ||= new Vector3();
            obj.ucsYAxis.z = Number(value);
        },
        79: (obj, value) => (obj.ucsType = Number(value)),
        146: (obj, value) => (obj.elevation = Number(value)),
        170: (obj, value) => (obj.shadePlotMode = Number(value)),
        281: (obj, value) => (obj.renderMode = Number(value)),
        292: (obj, value) => (obj.defaultLighting = Boolean(Number(value))),
        282: (obj, value) => (obj.defaultLightingType = Number(value)),
        141: (obj, value) => (obj.viewBrightness = Number(value)),
        142: (obj, value) => (obj.viewContrast = Number(value)),
        63: (obj, value) => {
            obj.ambientLightColor ||= { r: 0, g: 0, b: 0 };
            obj.ambientLightColor.r = Number(value);
        },
        421: (obj, value) => {
            obj.ambientLightColor ||= { r: 0, g: 0, b: 0 };
            obj.ambientLightColor.g = Number(value);
        },
        431: (obj, value) => {
            obj.ambientLightColor ||= { r: 0, g: 0, b: 0 };
            obj.ambientLightColor.b = Number(value);
        },
    };
}

export class DxfDictionaryProps {
    readonly subclassMarkerDictionary = "AcDbDictionary"; // Group code 100 (Subclass marker)
    /**
     * code 280
     */
    hardOwnerFlag: boolean = true; // Group code 280 (Hard-owner flag: 1 = true, 0 = false)
    
    /**
     * code 281
     */
    duplicateRecordCloningFlag?: number; // Group code 281 (Duplicate record cloning flag)

    // New entry property: array of objects with entryName and entryHandle
    entries: DxfDictionaryEntry[] = [];

    static deserializationMap: DxfDeserializationMap<DxfDictionary> = {
        280: (obj, value) => {
            obj.hardOwnerFlag = Boolean(value); // Group code 280: Hard-owner flag
        },
        281: (obj, value) => {
            obj.duplicateRecordCloningFlag = Number(value); // Group code 281: Duplicate record cloning flag
        },
        3: (obj, value) => {
            // Group code 3: Entry name (for each dictionary entry)
            const entry = obj.entries.find((entry) => !entry.entryName);
            if (entry) {
                entry.entryName = String(value);
            }
        },
        350: (obj, value) => {
            // Group code 350: Soft-owner ID/handle to entry object
            const entry = obj.entries.find((entry) => !entry.entryHandle);
            if (entry) {
                entry.entryHandle = String(value);
            }
        },
    };

    static serializationMap: DxfSerializationMap<DxfDictionary> = {
        subclassMarker: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarkerDictionary),
        },
        hardOwnerFlag: {
            serialize: (obj) =>
                serializeProperty(280, Number(obj.hardOwnerFlag)), // Serialize 1 or 0 for the flag
        },
        duplicateRecordCloningFlag: {
            serialize: (obj) =>
                serializeProperty(281, obj.duplicateRecordCloningFlag),
        },
        entries: {
            serialize: (obj) => {
                const serializedEntries: DxfSerializedData[] = [];
                obj.entries.forEach((entry) => {
                    IterUtils.extendArray(
                        serializedEntries,
                        serializeProperty(3, entry.entryName)
                    );
                    IterUtils.extendArray(
                        serializedEntries,
                        serializeProperty(350, entry.entryHandle)
                    );
                });

                return serializedEntries;
            },
        },
    };
}

export class DxfSortentsTableProps {
    // Fields
    readonly subclassMarker = "AcDbSortentsTable"; // Code 100
    sortentsOwnerHandle: string = ""; // Code 330 currently only the *MODEL_SPACE or *PAPER_SPACE blocks
    sortentOrder: string[] = []; // Unified field for object handles in sort order

    // Serialization Map
    static serializationMap: DxfSerializationMap<DxfSortentsTable> = {
        subclassMarker: {
            serialize: (obj) =>
                serializeProperty(100, obj.subclassMarker),
        },
        ownerHandle: {
            serialize: (obj) =>
                serializeProperty(330, obj.ownerHandle),
        },
        sortentOrder: {
            serialize: (obj) => serializeSortentOrder(obj.sortentOrder),
        },
    };

    // Deserialization Map
    static deserializationMap: DxfDeserializationMap<DxfSortentsTable> = {
        330: "ownerHandle",
        331: (obj, value) => obj.sortentOrder.push(String(value)),
        5: (obj, value) => obj.sortentOrder.push(String(value)),
    };
}

export class DxfGeoDataProps {
    readonly subclassMarker = "AcDbGeoData"; // Group code 100 (Subclass marker)

    version: number = 3; // Group code 90 (Version: 2009 = 1, 2010 = 2)
    coordinateType: number = 0; // Group code 70 (Type of design coordinates: 0 = Unknown, 1 = Local grid, 2 = Projected grid, 3 = Geographic)
    hostBlockTableRecord?: string; // Group code 330 (ObjectId of host block table record)

    designPoint?: Vector3; // Group codes 10, 20, 30 (Design point in WCS coordinates)
    referencePoint?: Vector3; // Group codes 11, 21, 31 (Reference point in Local grid coordinates)

    northDirection?: Vector2; // Group codes 12, 22 (North direction vector in 2D)
    horizontalUnitScale?: number; // Group code 40 (Horizontal unit scale factor)
    verticalUnitScale?: number; // Group code 41 (Vertical unit scale factor)

    horizontalUnits?: number; // Group code 91 (Horizontal units as per AutoCAD enumeration)
    verticalUnits?: number; // Group code 92 (Vertical units as per AutoCAD enumeration)

    upDirection?: Vector3; // Group codes 210, 220, 230 (Up direction in 3D)
    scaleEstimationMethod?: number; // Group code 95 (Scale estimation method)
    seaLevelCorrection?: boolean; // Group code 294 (Bool flag for sea level correction)

    userScaleFactor?: number; // Group code 141 (User specified scale factor)
    seaLevelElevation?: number; // Group code 142 (Sea level elevation)
    projectionRadius?: number; // Group code 143 (Coordinate projection radius)

    coordinateSystemDefString?: string; // Group code 301 (Coordinate system definition string)
    geoRSSTag?: string; // Group code 302 (GeoRSS tag)
    observationFromTag: string = " "; // Group code 305 (Observation from tag)
    observationToTag: string = " "; // Group code 306 (Observation to tag)
    observationCoverageTag: string = " "; // Group code 307 (Observation coverage tag)

    geoMeshPointsCount?: number; // Group code 93 (Number of Geo-Mesh points)
    sourceMeshPoints?: Vector2[]; // Group codes 13, 23 (Source mesh points)
    destinationMeshPoints?: Vector2[]; // Group codes 14, 24 (Destination mesh points)

    facesCount?: number; // Group code 96 (Number of faces)
    faces?: number[][]; // Group codes 97, 98, 99 (Point indices for faces)

    static deserializationMap: DxfDeserializationMap<DxfGeoData> = {
        90: (obj, value) => (obj.version = Number(value)), // Version
        70: (obj, value) => (obj.coordinateType = Number(value)), // Coordinate type
        330: (obj, value) => (obj.hostBlockTableRecord = String(value)), // Host block table record ID
        10: (obj, value) => {
            obj.designPoint ||= new Vector3();
            obj.designPoint.x = Number(value); // Design point (WCS)
        },
        20: (obj, value) => {
            obj.designPoint ||= new Vector3();
            obj.designPoint.y = Number(value); // Design point (WCS)
        },
        30: (obj, value) => {
            obj.designPoint ||= new Vector3();
            obj.designPoint.z = Number(value); // Design point (WCS)
        },
        11: (obj, value) => {
            obj.referencePoint ||= new Vector3();
            obj.referencePoint.x = Number(value); // Reference point (Local grid)
        },
        21: (obj, value) => {
            obj.referencePoint ||= new Vector3();
            obj.referencePoint.y = Number(value); // Reference point (Local grid)
        },
        31: (obj, value) => {
            obj.referencePoint ||= new Vector3();
            obj.referencePoint.z = Number(value); // Reference point (Local grid)
        },
        12: (obj, value) => {
            obj.northDirection ||= new Vector2();
            obj.northDirection.x = Number(value); // North direction vector (2D)
        },
        22: (obj, value) => {
            obj.northDirection ||= new Vector2();
            obj.northDirection.y = Number(value); // North direction vector (2D)
        },
        40: (obj, value) => (obj.horizontalUnitScale = Number(value)), // Horizontal unit scale factor
        41: (obj, value) => (obj.verticalUnitScale = Number(value)), // Vertical unit scale factor
        91: (obj, value) => (obj.horizontalUnits = Number(value)), // Horizontal units (AutoCAD enum)
        92: (obj, value) => (obj.verticalUnits = Number(value)), // Vertical units (AutoCAD enum)
        210: (obj, value) => {
            obj.upDirection ||= new Vector3();
            obj.upDirection.x = Number(value); // Up direction (3D)
        },
        220: (obj, value) => {
            obj.upDirection ||= new Vector3();
            obj.upDirection.y = Number(value); // Up direction (3D)
        },
        230: (obj, value) => {
            obj.upDirection ||= new Vector3();
            obj.upDirection.z = Number(value); // Up direction (3D)
        },
        95: (obj, value) => (obj.scaleEstimationMethod = Number(value)), // Scale estimation method
        294: (obj, value) => (obj.seaLevelCorrection = Boolean(value)), // Sea level correction flag
        141: (obj, value) => (obj.userScaleFactor = Number(value)), // User scale factor
        142: (obj, value) => (obj.seaLevelElevation = Number(value)), // Sea level elevation
        143: (obj, value) => (obj.projectionRadius = Number(value)), // Coordinate projection radius
        301: (obj, value) => {
            value = String(value);
            if (value) {
                obj.coordinateSystemDefString += value;
            }
        }, // Coordinate system definition
        302: (obj, value) => {
            value = String(value);
            if (obj.geoRSSTag === undefined && value.length > 1) {
                obj.geoRSSTag = value;
            }
        }, // GeoRSS tag
        305: (obj, value) => (obj.observationFromTag = String(value)), // Observation from tag
        306: (obj, value) => (obj.observationToTag = String(value)), // Observation to tag
        307: (obj, value) => (obj.observationCoverageTag = String(value)), // Observation coverage tag
        93: (obj, value) => (obj.geoMeshPointsCount = Number(value)), // Number of Geo-Mesh points
        13: (obj, value) => {
            obj.sourceMeshPoints ||= [];
            obj.sourceMeshPoints.push(new Vector2(Number(value), 0)); // Source mesh point (X)
        },
        23: (obj, value) => {
            const point =
                obj.sourceMeshPoints?.[obj.sourceMeshPoints.length - 1];
            if (point) {
                point.y = Number(value); // Source mesh point (Y)
            }
        },
        14: (obj, value) => {
            obj.destinationMeshPoints ||= [];
            obj.destinationMeshPoints.push(new Vector2(Number(value), 0)); // Destination mesh point (X)
        },
        24: (obj, value) => {
            const point =
                obj.destinationMeshPoints?.[
                    obj.destinationMeshPoints.length - 1
                ];
            if (point) {
                point.y = Number(value); // Destination mesh point (Y)
            }
        },
        96: (obj, value) => (obj.facesCount = Number(value)), // Number of faces
        97: (obj, value) => {
            obj.faces ||= [];
            obj.faces.push([Number(value)]); // Face point indices
        },
        98: (obj, value) => {
            const face = obj.faces?.[obj.faces.length - 1];
            if (face) {
                face.push(Number(value)); // Additional face point index
            }
        },
        99: (obj, value) => {
            const face = obj.faces?.[obj.faces.length - 1];
            if (face) {
                face.push(Number(value)); // Additional face point index
            }
        },

        303: (obj, value) => {
            obj.coordinateSystemDefString ||= "";
            obj.coordinateSystemDefString += String(value).replace(/\n/g, "^J"); // Cell text string chunk
        },
    };

    static serializationMap: DxfSerializationMap<DxfGeoData> = {
        subclassMarker: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarker),
        },
        version: { serialize: (obj) => serializeProperty(90, obj.version) },
        coordinateType: {
            serialize: (obj) => serializeProperty(70, obj.coordinateType),
        },
        hostBlockTableRecord: {
            serialize: (obj) =>
                serializeProperty(330, obj.hostBlockTableRecord),
        },
        designPoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.designPoint),
        },
        referencePoint: {
            serialize: (obj) =>
                serializeVector([11, 21, 31], obj.referencePoint),
        },
        northDirection: {
            serialize: (obj) => serializeVector([12, 22], obj.northDirection),
        },
        horizontalUnitScale: {
            serialize: (obj) => serializeProperty(40, obj.horizontalUnitScale),
        },
        verticalUnitScale: {
            serialize: (obj) => serializeProperty(41, obj.verticalUnitScale),
        },
        horizontalUnits: {
            serialize: (obj) => serializeProperty(91, obj.horizontalUnits),
        },
        verticalUnits: {
            serialize: (obj) => serializeProperty(92, obj.verticalUnits),
        },
        upDirection: {
            serialize: (obj) =>
                serializeVector([210, 220, 230], obj.upDirection),
        },
        scaleEstimationMethod: {
            serialize: (obj) =>
                serializeProperty(95, obj.scaleEstimationMethod),
        },
        seaLevelCorrection: {
            serialize: (obj) =>
                serializeProperty(294, obj.seaLevelCorrection ? 1 : 0),
        },
        userScaleFactor: {
            serialize: (obj) => serializeProperty(141, obj.userScaleFactor),
        },
        seaLevelElevation: {
            serialize: (obj) => serializeProperty(142, obj.seaLevelElevation),
        },
        projectionRadius: {
            serialize: (obj) => serializeProperty(143, obj.projectionRadius),
        },
        coordinateSystemDefString: {
            serialize: (obj) => {
                if (obj.coordinateSystemDefString) {
                    return serializeXmlString(
                        303,
                        obj.coordinateSystemDefString
                    );
                } else {
                    return [];
                }
            },
        },
        geoRSSTag: {
            serialize: (obj) => serializeProperty(302, obj.geoRSSTag),
        },
        observationFromTag: {
            serialize: (obj) => serializeProperty(305, obj.observationFromTag),
        },
        observationToTag: {
            serialize: (obj) => serializeProperty(306, obj.observationToTag),
        },
        observationCoverageTag: {
            serialize: (obj) =>
                serializeProperty(307, obj.observationCoverageTag),
        },
        geoMeshPointsCount: {
            serialize: (obj) => serializeProperty(93, obj.geoMeshPointsCount),
        },
        sourceMeshPoints: {
            serialize: (obj) =>
                obj.sourceMeshPoints
                    ? obj.sourceMeshPoints.flatMap((pt) =>
                          serializeVector([13, 23], pt)
                      )
                    : [],
        },
        destinationMeshPoints: {
            serialize: (obj) =>
                obj.destinationMeshPoints
                    ? obj.destinationMeshPoints.flatMap((pt) =>
                          serializeVector([14, 24], pt)
                      )
                    : [],
        },
        facesCount: {
            serialize: (obj) => serializeProperty(96, obj.facesCount),
        },
        faces: {
            serialize: (obj) => [],
        },
    };
}

//Classes for hatch

export abstract class EdgeType {
    abstract getType(): string;
    abstract countVertices(): number;
    abstract serialize(): DxfSerializedData[];
}

export class PolylineBoundary extends EdgeType {
    private hasBulge: boolean = false; // Code 72
    isClosed: boolean = false; // Code 73
    private vertices: { location: Vector2; bulge?: number }[] = []; // Codes 10, 20, 42

    getType(): string {
        return "Polyline";
    }

    countVertices(): number {
        return this.vertices.length;
    }

    addVertex(location:Vector2, bulge?:number){
        if(bulge){
            this.hasBulge = true;
        }
        this.vertices.push({location, bulge});
    }

    serialize(): DxfSerializedData[] {
        const serialized: DxfSerializedData[] = [];
        IterUtils.extendArray(serialized, serializeProperty(72, Number(this.hasBulge)));  // Serialize bulge flag
        IterUtils.extendArray(serialized, serializeProperty(73, Number(this.isClosed)));  // Serialize closed flag
        IterUtils.extendArray(serialized, serializeProperty(93, this.countVertices()));

        for (const vertex of this.vertices) {
            IterUtils.extendArray(serialized, serializeProperty(10, vertex.location.x));  // X of the vertex
            IterUtils.extendArray(serialized, serializeProperty(20, vertex.location.y));  // Y of the vertex
            if (this.hasBulge) {
                IterUtils.extendArray(serialized, serializeProperty(42, vertex.bulge));  // Bulge value
            }
        }

        return serialized;
    }
}

export class CircleBoundary extends EdgeType {
    center: Vector2; // Code 10, 20
    radius: number; // Code 40
    startAngle: number;
    endEngle:number;
    conterClockWise: boolean = true;

    constructor(center:Vector2, radius:number, startAngle = 0, endAngle = 360){
        super();
        this.center = center;
        this.radius = radius;
        this.startAngle = startAngle;
        this.endEngle = endAngle;
    }

    getType(): string {
        return "Circle";
    }

    countVertices(): number {
        return 1; // Defined as a single shape
    }

    serialize(): DxfSerializedData[] {
        const serialized: DxfSerializedData[] = [];
        IterUtils.extendArray(serialized, serializeProperty(10, this.center.x));  // Center X
        IterUtils.extendArray(serialized, serializeProperty(20, this.center.y));  // Center Y
        IterUtils.extendArray(serialized, serializeProperty(40, this.radius));  // Radius
        IterUtils.extendArray(serialized, serializeProperty(50, this.startAngle));  // Start angle
        IterUtils.extendArray(serialized, serializeProperty(51, this.endEngle));  // End engle
        IterUtils.extendArray(serialized, serializeProperty(73, Number(this.conterClockWise)));

        return serialized;
    }
}

export class BoundaryPathData {
    boundaryTypeFlag: number = 1; // Code 92
    polylineEdges: PolylineBoundary[] = [];
    edges: EdgeType[] = []; // Polymorphic collection of edge types
    sourceBoundaryObjects: string[] = []; // Code 330

    countTotalVertices(): number {
        return this.edges.reduce((sum, edge) => sum + edge.countVertices(), 0);
    }

    /* addBoundary(boundary:EdgeType){

        this.boundaryTypeFlag |= 1;
        this.edges.push(boundary);
    } */

    addBoundary(boundary:EdgeType){
        if(boundary instanceof PolylineBoundary){
            this.polylineEdges.push(boundary);
            this.boundaryTypeFlag |= 2;
        }else{
            this.edges.push(boundary);
        }
    }

    serialize():DxfSerializedData[]{
        const serialized: DxfSerializedData[] = [];
        IterUtils.extendArray(serialized, serializeProperty(92, this.boundaryTypeFlag));
        if(!this.polylineEdges.length){

            let edgetypes = 0;

            const edgesData: DxfSerializedData[] = [];
            for(const edge of this.edges){

                IterUtils.extendArray(serialized, serializeProperty(93, edge.countVertices()));

                const type = edge.getType();
                let typecode = 0;

                switch(type){
                    case "Circle":
                        typecode = 2;
                        break;
                    case "Polyline":
                        typecode = 1;
                        break;
                    default:
                        break;
                }
                edgetypes |= typecode;
                IterUtils.extendArray(edgesData, edge.serialize());      
            }

            IterUtils.extendArray(serialized, serializeProperty(72, edgetypes));
            IterUtils.extendArray(serialized, edgesData);

        }else{
            
            
            for(const polylineEdge of this.polylineEdges){
                IterUtils.extendArray(serialized, polylineEdge.serialize());
            }
        }

        return serialized;
    }
}

export class GradientData {
    // Fields
    isGradient: boolean = false; // Code 450
    zeroReserved: number = 0; // Code 451
    colorDefinitionType: number = 0; // Code 452 (0 = Two-color gradient, 1 = Single-color gradient)
    numberOfColors: number = 0; // Code 453 (0 = Solid hatch, 2 = Gradient)
    rotationAngle: number = 0; // Code 460 (Rotation angle in radians for gradients)
    shiftValue: number = 0; // Code 461 (Blend of gradient definitions: 0.0 = unshifted, 1.0 = shifted)
    colorTintValue: number = 0; // Code 462 (Color tint value: range is 0.0 to 1.0)
    reservedValue: number = 0; // Code 463 (Reserved for future use)
    gradientType: string = "LINEAR"; // Code 470 (Default gradient type)

    // Deserialization Map
    static deserializationMap: Record<number, (data: GradientData, value: string | number) => void> = {
        450: (data, value) => (data.isGradient = Boolean(Number(value))),
        451: (data, value) => (data.zeroReserved = Number(value)),
        452: (data, value) => (data.colorDefinitionType = Number(value)),
        453: (data, value) => (data.numberOfColors = Number(value)),
        460: (data, value) => (data.rotationAngle = Number(value)),
        461: (data, value) => (data.shiftValue = Number(value)),
        462: (data, value) => (data.colorTintValue = Number(value)),
        463: (data, value) => (data.reservedValue = Number(value)),
        470: (data, value) => (data.gradientType = String(value)),
    };

    // Method to deserialize gradient data
    static deserialize(input: DxfSerializedData[]): GradientData {
        const gradientData = new GradientData();
        input.forEach(({ code, value }) => {
            const handler = this.deserializationMap[code];
            if (handler) {
                handler(gradientData, value);
            }
        });
        return gradientData;
    }

    // Method to serialize gradient data
    serialize(): DxfSerializedData[] {
        const serialized: DxfSerializedData[] = [];
        IterUtils.extendArray(serialized, serializeProperty(450, this.isGradient?1:0))
        IterUtils.extendArray(serialized, serializeProperty(451, this.zeroReserved))
        IterUtils.extendArray(serialized, serializeProperty(460, this.rotationAngle));
        IterUtils.extendArray(serialized, serializeProperty(461, this.shiftValue));
        IterUtils.extendArray(serialized, serializeProperty(452, this.colorDefinitionType));
        IterUtils.extendArray(serialized, serializeProperty(462, this.colorTintValue));
        IterUtils.extendArray(serialized, serializeProperty(453, this.numberOfColors));
        IterUtils.extendArray(serialized, serializeProperty(470, this.gradientType));
        
        //IterUtils.extendArray(serialized, serializeProperty(463, this.reservedValue));
        
        return serialized;
    }
}

export class DxfHatchProps {
    // Static fields for hatch group codes
    readonly subclassMarkerHatch = "AcDbHatch"; // Code 100 (Subclass marker for AcDbHatch)
    
    // Properties
    elevationPoint: Vector3 = new Vector3(0, 0, 0); // Code 10, 20, 30
    extrusionDirection: Vector3 = new Vector3(0, 0, 1); // Code 210, 220, 230
    patternName: string = ""; // Code 2
    solidFill: boolean = false; // Code 70
    associativityFlag: boolean = false; // Code 71
    boundaryPathCount: number = 0; // Code 91
    boundaryPaths: BoundaryPathData[] = []; // Boundary path data
    numberOfSourceObjects: number = 0; //Code 97
    hatchStyle: number = 1; // Code 75
    patternType: number = 0; // Code 76
    numberOfSeedPoints: number = 1; //Code 98
    seedPoints: Vector2[] = [new Vector2()];
    patternAngle: number = 0; // Code 52
    patternScale: number = 1; // Code 41
    patternDoubleFlag: boolean = false; // Code 77
    gradientData?: GradientData; // Gradient-specific data

    // Deserialization map
    static deserializationMap: DxfDeserializationMap<DxfHatch> = {
        100: (hatch, value) => {},
        10: (hatch, value) => (hatch.elevationPoint.x = Number(value)),
        20: (hatch, value) => (hatch.elevationPoint.y = Number(value)),
        30: (hatch, value) => (hatch.elevationPoint.z = Number(value)),
        210: (hatch, value) => (hatch.extrusionDirection.x = Number(value)),
        220: (hatch, value) => (hatch.extrusionDirection.y = Number(value)),
        230: (hatch, value) => (hatch.extrusionDirection.z = Number(value)),
        2: (hatch, value) => (hatch.patternName = String(value)),
        70: (hatch, value) => (hatch.solidFill = Boolean(Number(value))),
        71: (hatch, value) => (hatch.associativityFlag = Boolean(Number(value))),
        91: (hatch, value) => (hatch.boundaryPathCount = Number(value)),
        97: (hatch, value) => (hatch.numberOfSourceObjects = Number(value)),
        75: (hatch, value) => (hatch.hatchStyle = Number(value)),
        76: (hatch, value) => (hatch.patternType = Number(value)),
        52: (hatch, value) => (hatch.patternAngle = Number(value)),
        41: (hatch, value) => (hatch.patternScale = Number(value)),
        77: (hatch, value) => (hatch.patternDoubleFlag = Boolean(Number(value))),
        450: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).isGradient = Boolean(Number(value));
        },
        // Remaining gradient properties delegated to GradientData
        451: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).zeroReserved = Number(value);
        },
        452: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).colorDefinitionType = Number(value);
        },
        453: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).numberOfColors = Number(value);
        },
        460: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).rotationAngle = Number(value);
        },
        461: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).shiftValue = Number(value);
        },
        462: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).colorTintValue = Number(value);
        },
        470: (hatch, value) => {
            (hatch.gradientData ??= new GradientData()).gradientType = String(value);
        },
    };

    // Serialization map
    static serializationMap: DxfSerializationMap<DxfHatch> = {
        subclassMarkerHatch: {
            serialize: (obj) => serializeProperty(100, obj.subclassMarkerHatch),
        },
        elevationPoint: {
            serialize: (obj) => serializeVector([10, 20, 30], obj.elevationPoint),
        },
        extrusionDirection: {
            serialize: (obj) => serializeVector([210, 220, 230], obj.extrusionDirection),
        },
        patternName: {
            serialize: (obj) => serializeProperty(2, obj.patternName),
        },
        solidFill: {
            serialize: (obj) => serializeProperty(70, Number(obj.solidFill)),
        },
        associativityFlag: {
            serialize: (obj) => serializeProperty(71, Number(obj.associativityFlag)),
        },
        boundaryPathCount: {
            serialize: (obj) => serializeProperty(91, obj.boundaryPaths.length),
        },
        boundaryPaths:{
            serialize: (obj) => {
                const serialized: DxfSerializedData[] = [];
                for(const path of obj.boundaryPaths){
                    const serializedPath = path.serialize();
                    IterUtils.extendArray(serialized, serializedPath)
                }
                return serialized;
            }
        },
        numberOfSourceObjects: {
            serialize: (obj) => serializeProperty(97, obj.numberOfSourceObjects),
        },
        hatchStyle: {
            serialize: (obj) => serializeProperty(75, obj.hatchStyle),
        },
        patternType: {
            serialize: (obj) => serializeProperty(76, obj.patternType),
        },
        seedPoints: {
            serialize: (obj) => {
                const serialized: DxfSerializedData[] = serializeProperty(98, obj.seedPoints.length);
                
                for(const seed of obj.seedPoints){
                    const serializedSeed = serializeVector([10,20], seed)
                    IterUtils.extendArray(serialized, serializedSeed)
                }
                return serialized;
            }
        },
        patternAngle: {
            serialize: (obj) => serializeProperty(52, obj.patternAngle, 0),
        },
        patternScale: {
            serialize: (obj) => serializeProperty(41, obj.patternScale, 1),
        },
        patternDoubleFlag: {
            serialize: (obj) => []// serializeProperty(77, Number(obj.patternDoubleFlag)),
        },
        gradientData: {
            serialize: (obj) => obj.gradientData?.serialize() ?? [],
        },
    };
}