import type { SceneInstance, TrackerBuilderInfo, IndexWithInRowPositionPacked, PileFeaturesFlags, ConfigsCollection} from "bim-ts";

import {
	InRowPositionMask, AnyTrackerProps, BimProperty,
	calculateTrackersPilesTopsPositionsInLocalCoords, getRowIndex, getLocalPilesCoords,
	getPositionMask, PolylineGeometry, SceneObjDiff,
	StdMeshRepresentation, getPileTypeIcon, getPileFeaturesWithUndulation,
	getPileWeightClass, getPileMotorType, getPileStrengthModifier, getPileDamperType,
	getCharCodesForPileIndex, PileBinsConfig} from "bim-ts";

import { ESO } from "./ESO";
import type { ESOsHandlerInputs } from "./ESOsHandlerBase";
import { ESOsHandlerBase } from "./ESOsHandlerBase";
import type { ESOHandle } from "src/scene/ESOsCollection";
import type { SubmeshesCreationOutput, SubmeshesCreationResources } from "./ESSO";
import { ESSO } from "./ESSO";
import { Deleted, IterUtils, RGBA } from "engine-utils-ts";
import type { ObservableObject, Observer, RGBAHex } from "engine-utils-ts";
import { DefaultMap } from "engine-utils-ts";
import type { LodGroupLocalIdent} from "src/scene/LodGroups";
import { newLodGroupLocalIdent } from "src/scene/LodGroups";
import { Matrix4, Quaternion, Vec3One, Vec3X, Vec3Y, Vec3Z, Vec3Zero, Vector3 } from "math-ts";
import type { EngineSubmeshDescription, RenderJobOutput, RenderJobUpdater, SubmeshAllocArgs } from "src/scene/Submeshes2";
import { LodMask } from "src/scene/Submeshes2";
import { EngineMaterialId } from "src/pools/EngineMaterialId";
import { DefaultSelectionColor, SelectHighlightOverlayJobUpdater, SharedSelectHighlightJobUpdater } from "./ESSO_HighlightUpdaters";
import { InObjFullId, InObjIdType, InObjLocalId } from "src/scene/EngineSceneIds";
import { ESSO_StdSelf, SubmeshesInstancingGroup } from "./ESO_StdStatic";
import { ESO_Diff } from "./ESO_Diff";
import type { TrackersPilesMarkupSettings } from "src/TrackersPilesMarkupSettingsUiBindings";
import type { Texture } from "src/3rdParty/three";
import type { EngineFullGraphicsSettings } from "src/GraphicsSettingsFull";
import { ShaderFlags } from "src/shaders/ShaderFlags";
import type { UniformsFlat } from "src/composer/DynamicUniforms";
import { BasicAnalyticalRenderJobUpdater } from "./ESO_BasicAnalytical";
import { TextOverlayRenderJobUpdater, TextRenderJobUpdater, TextStyleOptions } from "./ESSO_TextAnnotation";
import { TextBlockGeometry } from "src/geometries/EngineGeoTextBlock";
import { TextLayoutOptions } from "src/three-mesh-ui/components/Text";
import { BlockOptions } from "src/three-mesh-ui/components/Block";
import type { IdEngineGeo } from "src/geometries/AllEngineGeometries";
import { FONT_ID } from "src/three-mesh-ui/content/FontLibrary";
import { TEXT_ALIGN_H } from "src/three-mesh-ui/utils/inline-layout/TextAlignH";
import { TEXT_ALIGN_V } from "src/three-mesh-ui/utils/inline-layout/TextAlignV";
import { PileIconsLoader } from "src/materials/PileIconsLoader";
import { StdInstancedMeshesRepresentation } from 'bim-ts';

const TrackerRowIndexPropertyPath = BimProperty.MergedPath(['circuit', 'position', 'row_index']);

export class ESO_Tracker extends ESO {
	rowIndexWithPositionPacked?: IndexWithInRowPositionPacked;
	pilesIndexesWithPositionPacked?: IndexWithInRowPositionPacked[];
	pilesFeatures?: PileFeaturesFlags[];

    constructor(sceneInstanceRef: Readonly<SceneInstance>) {
		super(sceneInstanceRef);

		if(sceneInstanceRef.type_identifier === 'any-tracker') {
			const props = sceneInstanceRef.propsAs(AnyTrackerProps);
			this.rowIndexWithPositionPacked = props.position.row_index?.value;
			this.pilesIndexesWithPositionPacked = props.piles.indices?.values;
			this.pilesFeatures = props.piles.active_configuration?.features;
		} else {
			this.rowIndexWithPositionPacked = sceneInstanceRef.properties.get(TrackerRowIndexPropertyPath)?.asNumber();
			this.pilesIndexesWithPositionPacked = undefined;
			this.pilesFeatures = undefined;
		}
	}
}

interface SharedPileBillboardRenderJobUpdatersProvider {
	getOrCreatePileBillboardRenderJobUpdater(trackerModel: string, pileReveal: number, pileFeatures: PileFeaturesFlags): RenderJobUpdater,
	getOrCreatePileBillboardHighlightRenderJobUpdater(pileFeatures: PileFeaturesFlags): RenderJobUpdater,
}

interface ESSO_TrackerPilesMarkupRepr {
	localPilesTopCoords: Vector3[], //coords of top points of piles in local space of a tracker
	pilesLength: number[],
	pilesReveal: number[],
	frameWidth: number,
	frameLength: number,
	trackerModel: string,
	pilesFeatures: PileFeaturesFlags[] | undefined,
	rowIndexWithPositionPacked: IndexWithInRowPositionPacked | undefined,
	pilesIndexesWithPositionPacked: IndexWithInRowPositionPacked[] | undefined,
	pileBillboardRenderJobUpdatersProvider: SharedPileBillboardRenderJobUpdatersProvider,
}

class ESSO_TrackerPilesMarkup extends ESSO<ESSO_TrackerPilesMarkupRepr> {

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		const maxPilesElevation = this.getMaxElevationPointFromPiles();
		const modelRightDirection = Vec3Zero.clone().setFromMatrixColumn(this.rootRef.worldMatrix, 0);
		const modelUpDirection = Vec3Zero.clone().setFromMatrixColumn( this.rootRef.worldMatrix, 1 );
		const signToGlobalRightDirection = Math.sign(Vec3X.dot(modelRightDirection));
		const signToGlobalUpDirection = Math.sign(Vec3Y.dot(modelUpDirection));

		this.createTrackerFrameOutlineSubmesh(maxPilesElevation, resoures, output);
		this.createTrackerFrameMidDashedLineSubmesh(maxPilesElevation, signToGlobalUpDirection, resoures, output);
		this.tryCreateTrackerRowIndexAnnotations(maxPilesElevation, signToGlobalUpDirection, resoures, output);
		this.createPileOutlineSubmeshes(this.repr.localPilesTopCoords, resoures, output);

		const billboardSize = this.repr.frameWidth * 1.5;
		const pileBillboardGeoId = resoures.geometries.trackerPileBillboardGeometries.allocateOrReferenceSingle({
			width: billboardSize,
			height: billboardSize,
		});

		if (pileBillboardGeoId === undefined) {
			resoures.logger.batchedError('tracker pile billboard geo is absent', this);
			return;
		}

		for (let i = 0; i < this.repr.localPilesTopCoords.length; ++i) {
			const pileTopCoord = this.repr.localPilesTopCoords[i];
			this.createPileBillboardSubmesh(i, pileBillboardGeoId, pileTopCoord, billboardSize, output);
			this.tryCreatePileIndexAnnotations(i, pileTopCoord, signToGlobalRightDirection, resoures, output);
        }
	}

	private createTrackerFrameOutlineSubmesh(
		maxPilesElevation: number,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput
	) {
		const frameOutlinePosition = new Vector3(0, 0, maxPilesElevation);
		const frameOutlineScale = new Vector3(this.repr.frameWidth, this.repr.frameLength, 1);
		const frameOutlineGeoId = resources.geometries.analytPolylines.allocateOrReferenceShared(DefaultTrackerFrameOutlineGeo);

		this.createOutlineSubmesh(LodMask.All, newLodGroupLocalIdent(0, 0), frameOutlineGeoId, frameOutlinePosition, output,
			frameOutlineScale, undefined, false);
	}

	private createTrackerFrameMidDashedLineSubmesh(
		maxPilesElevation: number,
		signToGlobalUpDirection: number,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput
	) {
		const midDahsedLinePosition = new Vector3(0, 0, maxPilesElevation);
		let halfMidDashedLineSize = this.repr.frameLength / 2;

		const positionMask = this.repr.rowIndexWithPositionPacked === undefined
			? InRowPositionMask.None
			: getPositionMask(this.repr.rowIndexWithPositionPacked);

		if(positionMask & InRowPositionMask.Begin) {
			halfMidDashedLineSize += DefaultTrackerRowIndexLineLength / 2;
			midDahsedLinePosition.y += signToGlobalUpDirection * DefaultTrackerRowIndexLineLength / 2;
		}
		if(positionMask & InRowPositionMask.End) {
			halfMidDashedLineSize += DefaultTrackerRowIndexLineLength / 2;
			midDahsedLinePosition.y -= signToGlobalUpDirection * DefaultTrackerRowIndexLineLength / 2;
		}

		const dashedLineGeoId = resources.geometries.analytPolylines.allocateOrReferenceShared(PolylineGeometry.newWithAutoIds([
			new Vector3(0, -halfMidDashedLineSize, 0),
			new Vector3(0, halfMidDashedLineSize, 0),
		], DefaultOutlineRadius));

		this.createOutlineSubmesh(LodMask.Lod0 | LodMask.Lod1, DefaultLodGroupLocalIdent, dashedLineGeoId,
			midDahsedLinePosition, output, undefined, undefined, true);
	}

	private createPileBillboardSubmesh(
		pileIndex: number,
		sharedPileBillboardGeoId: IdEngineGeo,
		pileTopCoord: Vector3,
		billboardSize: number,
		output: SubmeshesCreationOutput
	) {
		if(this.repr.pilesFeatures === undefined) {
			return;
		}

		const pileBillboardCoord = new Vector3(pileTopCoord.x, pileTopCoord.y, pileTopCoord.z + billboardSize / 2);
		const pileFeatures = this.repr.pilesFeatures[pileIndex];
		const pileReveal = this.repr.pilesReveal[pileIndex];

		const mainRenderJobupdaters = this.repr.pileBillboardRenderJobUpdatersProvider
			.getOrCreatePileBillboardRenderJobUpdater(this.repr.trackerModel, pileReveal, pileFeatures);
			
		const highlightRenderJobUpdater = this.repr.pileBillboardRenderJobUpdatersProvider
			.getOrCreatePileBillboardHighlightRenderJobUpdater(pileFeatures);

		const pileBillboardAllocArgs: SubmeshAllocArgs = {
			lodMask: LodMask.Lod0,
			lodGroupLocalIdent: DefaultLodGroupLocalIdent,
			descr: {
				geoId: sharedPileBillboardGeoId,
				materialId: EngineMaterialId.TrackersPilesBillboard,
				localTransforms: [new Matrix4().setPositionV(pileBillboardCoord)],
				mainRenderJobUpdater: mainRenderJobupdaters,
				overlayRenderJobUpdater: highlightRenderJobUpdater,
			},
			id: this.id,
			subObjectRef: this,
		}

		output.submeshes.push(pileBillboardAllocArgs);
	}

	private createPileOutlineSubmeshes(
		pileTopCoords: Vector3[],
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput,
	) {
		const pileOutlineGeoId = resources.geometries.analytPolylines.allocateOrReferenceShared(DefaultPileOutlineGeo);
		const localInstancingTransforms: Matrix4[] = new Array(pileTopCoords.length);

		for(let i = 0;i < pileTopCoords.length; ++i) {
			const pileTopCoord = pileTopCoords[i];
			const pileLength = this.repr.pilesLength[i] ?? 0;
			const pileOutlineZOffset = - pileLength / 2;
			const pileOutlineScale = new Vector3(1, 1, pileLength);
			const pileOutlineCoord = new Vector3(pileTopCoord.x, pileTopCoord.y, pileTopCoord.z + pileOutlineZOffset);
			localInstancingTransforms[i] = new Matrix4().compose(pileOutlineCoord, QuaternionIdentity, pileOutlineScale);
		}

		const outlineAllocArgs: SubmeshAllocArgs = {
			lodMask: LodMask.Lod0 | LodMask.Lod1,
			lodGroupLocalIdent: DefaultLodGroupLocalIdent,
			descr: {
				geoId: pileOutlineGeoId,
				materialId: EngineMaterialId.BasicAnalytical,
				localTransforms: localInstancingTransforms,
				mainRenderJobUpdater: DefaultOutlineRenderJobUpdater,
				overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
			},
			id: this.id,
			subObjectRef: this,
		}

		output.submeshes.push(outlineAllocArgs);
	}

	private tryCreateTrackerRowIndexAnnotations(
		maxPilesElevation: number,
		signToGlobalUpDirection: number,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput
	) {
		if(this.repr.rowIndexWithPositionPacked === undefined) {
			return;
		}

		const positionMask = getPositionMask(this.repr.rowIndexWithPositionPacked);
		if(positionMask === InRowPositionMask.None){
			return;
		}

		const rowIndex = getRowIndex(this.repr.rowIndexWithPositionPacked);
		const hexagonGeo = resources.geometries.analytPolylines.allocateOrReferenceShared(DefaultHexagonAnnotationGeo);
		const hexagonScaleSide = DefaultAnnotationBlockOptions.height * 2;
		const hexagonScale = new Vector3(hexagonScaleSide, hexagonScaleSide, 1);

		if(positionMask & InRowPositionMask.End) {
			this.createTrackerRowIndexAnnotation(maxPilesElevation, rowIndex, hexagonGeo, hexagonScale, -signToGlobalUpDirection, resources, output);
		}

		if(positionMask & InRowPositionMask.Begin) {
			this.createTrackerRowIndexAnnotation(maxPilesElevation, rowIndex, hexagonGeo, hexagonScale, signToGlobalUpDirection, resources, output);
		}
	}

	private createTrackerRowIndexAnnotation(
		maxPilesElevation: number,
		rowIndex: number,
		hexagonGeoId: IdEngineGeo,
		hexagonScale: Vector3,
		direction: number,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput
	) {
		const yCoord = direction * (this.repr.frameLength / 2 + DefaultTrackerRowIndexLineLength + hexagonScale.y / 2);
		const textcoord = new Vector3(0, yCoord, maxPilesElevation);
		this.createTexAnnotationSubmesh(textcoord, rowIndex.toString(), resources, output, true);

		this.createOutlineSubmesh(LodMask.Lod0 | LodMask.Lod1, DefaultLodGroupLocalIdent, hexagonGeoId,
			textcoord, output, hexagonScale, undefined, false);
	}

	private tryCreatePileIndexAnnotations(
		localPileIndex: number,
		pileTopCoord: Vector3,
		signToGlobalRightDirection: number,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput,
	) {
		if(this.repr.pilesIndexesWithPositionPacked === undefined) {
			return;
		}

		const pileIndexWithPositionPacked = this.repr.pilesIndexesWithPositionPacked[localPileIndex] ?? 0;
		const positionMask = getPositionMask(pileIndexWithPositionPacked);

		if(positionMask === InRowPositionMask.None) {
			return;
		}

		const hexagonGeoId = resources.geometries.analytPolylines.allocateOrReferenceShared(DefaultHexagonAnnotationGeo);
		const hexagonScaleSide = DefaultAnnotationBlockOptions.height * 2;
		const hexagonScale = new Vector3(hexagonScaleSide, hexagonScaleSide, 1);
		const hexagonRotation = Quaternion.fromAxisAngle(Vec3Z, 3.14159 / 2);

		const pileIndex = getRowIndex(pileIndexWithPositionPacked) - 1; //indexes start from 1 but need from 0 to convert char codes
		const charCodes: number[] = [];
		getCharCodesForPileIndex(pileIndex, charCodes);
		const pileIndexAsString = String.fromCharCode(...charCodes);

		if(positionMask & InRowPositionMask.Begin) {
			this.createPileIndexAnnotation(hexagonGeoId, -signToGlobalRightDirection, pileTopCoord, hexagonScale, hexagonRotation,
				pileIndexAsString, resources, output);
		}

		if(positionMask & InRowPositionMask.End) {
			this.createPileIndexAnnotation(hexagonGeoId, signToGlobalRightDirection, pileTopCoord, hexagonScale, hexagonRotation,
				pileIndexAsString, resources, output);
		}
	}

	private createPileIndexAnnotation(
		hexagonGeoId: IdEngineGeo,
		direction: number,
		pileTopCoord: Vector3,
		hexagonScale: Vector3,
		hexagonRotation: Quaternion,
		pileIndexAsString: string,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput
	) {
		const hexagonXCoord = direction * (this.repr.frameWidth / 2 + hexagonScale.x /2);
		const hexagonCoord = new Vector3(hexagonXCoord, 0, 0).add(pileTopCoord);
		this.createTexAnnotationSubmesh(hexagonCoord, pileIndexAsString, resources, output, true);

		this.createOutlineSubmesh(LodMask.Lod0 | LodMask.Lod1, DefaultLodGroupLocalIdent, hexagonGeoId,
			hexagonCoord, output, hexagonScale,hexagonRotation);
	}

	private createOutlineSubmesh(
		lodMask: LodMask,
		lodGroupLocalIdent: LodGroupLocalIdent,
		outlineGeoId: IdEngineGeo,
		outlineCoord: Vector3,
		output: SubmeshesCreationOutput,
		outlineScale: Vector3 | undefined = undefined,
		rotation: Quaternion | undefined = undefined,
		useDashes: boolean = false,
	) {
		const outlineAllocArgs: SubmeshAllocArgs = {
			lodMask: lodMask,
			lodGroupLocalIdent: lodGroupLocalIdent,
			descr: {
				geoId: outlineGeoId,
				materialId: EngineMaterialId.BasicAnalytical,
				localTransforms: [new Matrix4().compose(outlineCoord, rotation ?? QuaternionIdentity, outlineScale ?? Vec3One)],
				mainRenderJobUpdater: useDashes ? DashedOutlineRenderJobUpdater : DefaultOutlineRenderJobUpdater,
				overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
			},
			id: this.id,
			subObjectRef: this,
		}

		output.submeshes.push(outlineAllocArgs);
	}

	private createTexAnnotationSubmesh(
		annotationCoord: Vector3,
		annotationText: string,
		resources: SubmeshesCreationResources,
		output: SubmeshesCreationOutput,
		alignTexGeoWithCamera: boolean = false,
	) {
		const annotationTextOptions = { 
			layoutOptions: [ new TextLayoutOptions(annotationText, DefaultFontSize) ],
			styleOptions: DefaultTextStyleOptions,
		};

		const annotationBlockGeo = new TextBlockGeometry(annotationTextOptions, DefaultAnnotationBlockOptions);
		const annotationGeoId = resources.geometries.textAnnotations.allocateOrReferenceSingle(annotationBlockGeo);

		if(annotationGeoId === undefined)
		{
			resources.logger.batchedError('text annotation geo is absent', annotationBlockGeo);
			return
		}

		const annotationAllocArgs: SubmeshAllocArgs = {
			lodMask: LodMask.Lod0,
			lodGroupLocalIdent: DefaultLodGroupLocalIdent,
			descr: {
				geoId: annotationGeoId,
				localTransforms: [new Matrix4().setPositionV(annotationCoord)],
				materialId: EngineMaterialId.Text,
				mainRenderJobUpdater: alignTexGeoWithCamera ? CameraAlignedTextRenderJobUpdater
					: DefaultTextRenderJobUpdater,
				overlayRenderJobUpdater: alignTexGeoWithCamera ? CameraAlignedTextOverlayRenderJobUpdater
					: DefaultTextOverlayRenderJobUpdater,
			},
			id: this.id,
			subObjectRef: this,
		};

		output.submeshes.push(annotationAllocArgs);
	}

	private getMaxElevationPointFromPiles(): number {
		const topCoords = this.repr.localPilesTopCoords;
		let max = topCoords[0].z;

		for(let i = 1; i < topCoords.length; ++i) {
			const elevation = topCoords[i].z;
			if(elevation > max) {
				max = elevation;
			}
		}

		return max;
	}
}

export class ESO_TrackerHandler extends ESOsHandlerBase<ESO_Tracker> implements SharedPileBillboardRenderJobUpdatersProvider {

	readonly trackersPilesMarkupSettings: TrackersPilesMarkupSettings;
	readonly pileIconsLoader: PileIconsLoader;

	readonly pileBillboardRenderJobUpdatersMap: DefaultMap<number, TrackerPileBillboardRenderJobUpdater>;
	readonly pileBillboardHighlightRenderJobUpdatersMap: DefaultMap<number, TrackerPileBillboardHighlightRenderJobUpdater>;

	readonly configSubscriber : Observer;
	pileBinsConfig: PileBinsConfig | undefined;

	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
		trackersPilesMarkupSettings: ObservableObject<TrackersPilesMarkupSettings>,
		configs: ConfigsCollection,
	) {
		super(identifier, args);
		this._relevantBimUpdatesFlags |= SceneObjDiff.LegacyProps | SceneObjDiff.NewProps;
		this.trackersPilesMarkupSettings = trackersPilesMarkupSettings.poll();
		this.pileIconsLoader = new PileIconsLoader();
		this.pileBillboardRenderJobUpdatersMap = new DefaultMap((key) => this.createPileBillboardRenderJobUpdater(key));
		this.pileBillboardHighlightRenderJobUpdatersMap = new DefaultMap((key) => this.createPileBillboardHighlightRenderJobUpdater(key));

		trackersPilesMarkupSettings.observeObject({
            settings: { doNotNotifyCurrentState: true, immediateMode: true },
			onPatch: ({ patch }) => {
                if (patch.showTrackersPilesMarkup !== undefined) {
                    for(const handle of this._allObjectsHandled) {
						this.markDirty(handle, ESO_Diff.RepresentationBreaking);
					}
                }
			}
        });

		this.configSubscriber = configs.updatesStream.subscribe({
            onNext: (update) => {
                if (update instanceof Deleted) {
					return;
                }
				
                for (let i = 0; i < update.ids.length; i++) {
                    const id = update.ids[i];
                    const config = configs.peekById(id);
                    if (config?.type_identifier !== PileBinsConfig.name) {
                        continue;
                    }

					const newConfigProps = config.propsAs(PileBinsConfig);
					if(!this.isRepresentationNeedsUpdateFromBinsConfig(newConfigProps)) {
						return;
					}

					this.pileBinsConfig = newConfigProps;
					this.pileBillboardRenderJobUpdatersMap.clear();
					this.pileBillboardHighlightRenderJobUpdatersMap.clear();

					//todo: find a way to optimize this
					if(this.trackersPilesMarkupSettings.showTrackersPilesMarkup) {
						for(const handle of this._allObjectsHandled) {
							this.markDirty(handle, ESO_Diff.ForceReprRecheck);
						}
					}
					return;
                }
            },
        });
	}

	dispose() {
		this.configSubscriber.dispose();
		this.pileIconsLoader.dispose();
		this.pileBillboardRenderJobUpdatersMap.dispose();
		this.pileBillboardHighlightRenderJobUpdatersMap.dispose();
	}

	esosTypesToHandle() {
		return [ESO_Tracker];
	}

	applyBimDiffToESO(obj: ESO_Tracker, diff: SceneObjDiff, handle: ESOHandle): ESO_Diff {
		let appliedDiff = super.applyBimDiffToESO(obj, diff, handle);

		if((diff & (SceneObjDiff.LegacyProps | SceneObjDiff.NewProps)) === 0) {
			return appliedDiff;
		}
		
		let newRowIndex: IndexWithInRowPositionPacked | undefined;
		let newPilesIndexes: IndexWithInRowPositionPacked[] | undefined;
		let newPilesFeatures: PileFeaturesFlags[] | undefined;

		if(obj.sceneInstanceRef.type_identifier === 'any-tracker') {
			const props = obj.sceneInstanceRef.propsAs(AnyTrackerProps);
			newRowIndex = props.position.row_index?.value;
			newPilesIndexes = props.piles.indices?.values;
			newPilesFeatures = props.piles.active_configuration?.features;
		} else {
			newRowIndex = obj.sceneInstanceRef.properties.get(TrackerRowIndexPropertyPath)?.asNumber();
			newPilesIndexes = undefined
			newPilesFeatures = undefined;
		}

		if(newRowIndex !== obj.rowIndexWithPositionPacked ||
			newPilesIndexes !== obj.pilesIndexesWithPositionPacked ||
			newPilesFeatures !== obj.pilesFeatures) {

			obj.rowIndexWithPositionPacked = newRowIndex;
			obj.pilesIndexesWithPositionPacked = newPilesIndexes;
			obj.pilesFeatures = newPilesFeatures;

			if(this.trackersPilesMarkupSettings.showTrackersPilesMarkup) {
				appliedDiff |= ESO_Diff.RepresentationBreaking;
			}
		}

		return appliedDiff;
	}

	tryCreateESO(instance: SceneInstance): ESO_Tracker | undefined {
		if ((instance.type_identifier === 'tracker' || instance.type_identifier === 'any-tracker' || instance.type_identifier === 'sat') &&
			(instance.representation instanceof StdMeshRepresentation
				|| instance.representation instanceof StdInstancedMeshesRepresentation
			)
		) {
			return new ESO_Tracker(instance);
        }
		return undefined;
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_Tracker][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		if(this.trackersPilesMarkupSettings.showTrackersPilesMarkup) {
			return this.createTrackerPilesMarkupSubObjects(objectsToRealloc);
		}
		else {
			return this.createStandardTrackerSubObjects(objectsToRealloc);
		}
	}

	private createStandardTrackerSubObjects(objectsToRealloc: [ESOHandle, ESO_Tracker][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.mapIter(
			objectsToRealloc,
			([handle, eso]) => {
				const repr = eso.bimRepresentation();
				const instancedGroups = SubmeshesInstancingGroup.convertStdReprToInstancedGroupsRepr(repr);
				if (!instancedGroups){
					this.logger.batchedError('unsupported repr', repr);
					return [handle, []];
				}
				const subobjs: ESSO<any>[] = [new ESSO_StdSelf(
					eso,
					InObjFullId.new(handle, SelfId_0),
					instancedGroups,
					null
				)];

				return [handle, subobjs];
			}
		);
	}

	private createTrackerPilesMarkupSubObjects(objectsToRealloc: [ESOHandle, ESO_Tracker][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.mapIter(
			objectsToRealloc,
			([handle, eso]) => {
				const subobjs: ESSO<any>[] = [];

				const pilesMarkup = eso.sceneInstanceRef.type_identifier === 'any-tracker'
					? this.createTrackerPilesMarkupSubobjectForNewProps(eso, handle)
					: this.createTrackerPilesMarkupSubObjectForLegacyProps(eso, handle);

				subobjs.push(pilesMarkup);
				return [handle, subobjs]
			}
		);
	}

	private createTrackerPilesMarkupSubobjectForNewProps (eso: ESO_Tracker, handle: ESOHandle): ESSO_TrackerPilesMarkup {
		const props = eso.sceneInstanceRef.propsAs(AnyTrackerProps);
		const pilesTopCoords = calculateTrackersPilesTopsPositionsInLocalCoords(props);
		const pilesLength = props.piles.lengths?.values ?? [];
		const pilesReveal = props.piles.reveal?.values ?? [];
		const frameWidth = props.tracker_frame.dimensions.max_width!.as('m');
		const frameLength = props.tracker_frame.dimensions.length!.as('m');
		const trackerModdel = props.tracker_frame.commercial.model.value;

		const pilesMarkup = new ESSO_TrackerPilesMarkup(eso, InObjFullId.new(handle, SelfId_0), { 
			localPilesTopCoords: pilesTopCoords,
			pilesLength: pilesLength,
			pilesReveal: pilesReveal,
			frameWidth: frameWidth,
			frameLength: frameLength,
			trackerModel: trackerModdel,
			pilesFeatures: eso.pilesFeatures,
			rowIndexWithPositionPacked: eso.rowIndexWithPositionPacked,
			pilesIndexesWithPositionPacked: eso.pilesIndexesWithPositionPacked,
			pileBillboardRenderJobUpdatersProvider: this,
		}, null);

		return pilesMarkup;
	}

	private createTrackerPilesMarkupSubObjectForLegacyProps(eso: ESO_Tracker, handle: ESOHandle): ESSO_TrackerPilesMarkup {
		const instance = eso.sceneInstanceRef;
		const slopes = instance.properties.get("position | _segments-slopes")?.asText() ?? "";
		const reveal = instance.properties.get("tracker-frame | piles | max_reveal")?.as("m")!;
		const embedment = instance.properties.get("tracker-frame | piles | min_embedment")?.as("m")!;
		const moduleMountingWidth = instance.properties.get("tracker-frame | module_mounting | module_width")?.as("m")!;
		const moduleWidth = instance.properties.get("module | width")?.as("m")!;
		const frameWidth = instance.properties.get("tracker-frame | dimensions | max_width")?.as("m")!;
		const frameLength = instance.properties.get("tracker-frame | dimensions | length")?.as("m")!;
		const trackerModel = instance.properties.get("tracker-frame | commercial | model")?.asText()!;
		const pileLength = reveal + embedment;

		const buildParams: TrackerBuilderInfo = {
			useModulesRow: instance.properties.get("tracker-frame | dimensions | use_modules_row")?.asBoolean()!,
			modulesRow: instance.properties.get("tracker-frame | dimensions | modules_row")?.asText()!,
			modulesPerStringCountHorizontal: instance.properties.get("tracker-frame | string | modules_count_x")?.asNumber()!,
			stringsPerTrackerCount: instance.properties.get("tracker-frame | dimensions | strings_count")?.asNumber()!,
			moduleBayCount: instance.properties.get("tracker-frame | dimensions | module_bay_size")?.asNumber()!,
			moduleSize: moduleMountingWidth || moduleWidth,
			motorPlacementCoefficient: instance.properties.get("tracker-frame | dimensions | motor_placement")?.asNumber()!,
			pileGap: instance.properties.get("tracker-frame | dimensions | pile_bearings_gap")?.as("m")!,
			motorGap: instance.properties.get("tracker-frame | dimensions | motor_gap")?.as("m")!,
			stringGap: instance.properties.get("tracker-frame | dimensions | strings_gap")?.as("m")!,
			modulesGap: instance.properties.get("tracker-frame | dimensions | modules_gap")?.as("m")!,
		}

		const pilesTopCoords = getLocalPilesCoords(buildParams, slopes);
		pilesTopCoords.forEach((value) => value.z += reveal);
		const lengths: number[] = new Array(pilesTopCoords.length).fill(pileLength);
		const reveals: number[] = new Array(pilesTopCoords.length).fill(reveal);
		
		const pilesMarkup = new ESSO_TrackerPilesMarkup(eso, InObjFullId.new(handle, SelfId_0), { 
			localPilesTopCoords: pilesTopCoords,
			pilesLength: lengths,
			pilesReveal: reveals,
			frameWidth: frameWidth,
			frameLength: frameLength,
			trackerModel: trackerModel,
			pilesFeatures: eso.pilesFeatures,
			rowIndexWithPositionPacked: eso.rowIndexWithPositionPacked,
			pilesIndexesWithPositionPacked: eso.pilesIndexesWithPositionPacked,
			pileBillboardRenderJobUpdatersProvider: this,
		}, null);

		return pilesMarkup;
	}

	getOrCreatePileBillboardRenderJobUpdater(trackerModel: string, pileReveal: number, pileFeatures: PileFeaturesFlags): RenderJobUpdater {
		const pileIconIdent = getPileFeaturesWithUndulation(pileFeatures, 0); //exclude undulation info cause we dont need it for pile icons
		const color = this.getPileColor(trackerModel, pileReveal, pileFeatures);
		const renderJobUpdaterId = (color << 8) | pileIconIdent; //discard 8 most-left bits from color cause we dont need transparency info
		this.logger.assert((pileIconIdent >>> 8) === 0, 'pile features exceeds 8 bits, cannot pack render job updater id properly');
		return this.pileBillboardRenderJobUpdatersMap.getOrCreate(renderJobUpdaterId);
	}

	getOrCreatePileBillboardHighlightRenderJobUpdater(pileFeatures: PileFeaturesFlags): RenderJobUpdater {
		const pileIconIdent = getPileFeaturesWithUndulation(pileFeatures, 0); //exclude undulation info cause we dont need it for pile icons
		return this.pileBillboardHighlightRenderJobUpdatersMap.getOrCreate(pileIconIdent);
	}

	private createPileBillboardRenderJobUpdater(renderJobUpdaterId: number) {
		const color = renderJobUpdaterId >> 8;
		const pileIconIdent = (renderJobUpdaterId << 24) >>> 24;
		const texture = this.getPileIcon(pileIconIdent);
		return new TrackerPileBillboardRenderJobUpdater(texture, color as RGBAHex);
	}

	private createPileBillboardHighlightRenderJobUpdater(pileIconIdent: number) {
		const texture = this.getPileIcon(pileIconIdent);
		return new TrackerPileBillboardHighlightRenderJobUpdater(texture, EngineMaterialId.Highlight, true, DefaultSelectionColor, 0);
	}

	private getPileColor(trackerModel: string, pileReveal: number, pileFeatures: PileFeaturesFlags): number {
		const bins = this.pileBinsConfig?.getBinsByTracker(trackerModel)?.bins;

		if(bins !== undefined) {
			for (let i = 0; i < bins.length; ++i) {
				const revealBinDiff = pileReveal - bins[i].maxReveal.as('m');
				
				if (revealBinDiff <= 0) {
					const colorString = bins[i].getColor(getPileWeightClass(pileFeatures)).asHexRgbString();
					return RGBA.parseFromHexString(colorString);
				}
			}	
		}

		return DefaultPileIconColor;
	}

	private getPileIcon(pileIconIdent: number): Texture {
		const weight = getPileWeightClass(pileIconIdent);
		const motor = getPileMotorType(pileIconIdent);
		const strength = getPileStrengthModifier(pileIconIdent);
		const damper = getPileDamperType(pileIconIdent);
		const svgString = getPileTypeIcon(weight, motor, damper, strength);
		const texture = this.pileIconsLoader.get(pileIconIdent, svgString, 160, 160);
		return texture;
	}

	private isRepresentationNeedsUpdateFromBinsConfig(newConfigProps: PileBinsConfig) {	
		if(this.pileBinsConfig === undefined) {
			return true;
		} 
		
		if(this.pileBinsConfig.trackerBins.length !== newConfigProps.trackerBins.length) {
			return true;
		} 
		
		const trackerBinsCount = this.pileBinsConfig.trackerBins.length;
		for(let i = 0; i < trackerBinsCount; ++i) {
			const oldBins = this.pileBinsConfig.trackerBins[i].bins;
			const newBins = newConfigProps.trackerBins[i].bins;

			if(oldBins.length !== newBins.length) {
				return true;
			}
			
			const oldBinsCount = oldBins.length;
			for(let j = 0; j < oldBinsCount; ++j) {
				const oldBin = oldBins[j];
				const newBin = newBins[j];

				if(oldBin.maxReveal.as('m') !== newBin.maxReveal.as('m') ||
					oldBin.heavyColor.value !== newBin.heavyColor.value ||
					oldBin.standardColor.value !== newBin.standardColor.value
				) {
					return true;
				}
			}
		}

		return false;
	}
}

class TrackerPileBillboardRenderJobUpdater implements RenderJobUpdater {

    constructor(
        readonly texture: Texture,
		readonly color: RGBAHex,
    ) {
    }
    
    updaterRenderJob(
        submeshDescription: Readonly<EngineSubmeshDescription>,
        renderSettings: Readonly<EngineFullGraphicsSettings>,
        output: { flags: ShaderFlags; materialId: EngineMaterialId; uniforms: UniformsFlat; }
    ): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}

        output.materialId = submeshDescription.localDescr.materialId;
        output.uniforms.push('billboardImage', this.texture);
		output.uniforms.push('color', RGBA.RGBAHexToVec4(this.color))
    }
}

class TrackerPileBillboardHighlightRenderJobUpdater extends SelectHighlightOverlayJobUpdater {

	constructor(
		readonly texture: Texture,
		materialId: EngineMaterialId,
		disableInEdit: boolean,
		selectionMaxColor: RGBAHex,
		blendInitialColor01: number,
		lineGeoWidth: number = 5,
	) {
		super(materialId, disableInEdit, selectionMaxColor, blendInitialColor01, lineGeoWidth);
	}

	updaterRenderJob(
		submeshDescription: Readonly<EngineSubmeshDescription<Object>>,
		renderSettings: Readonly<EngineFullGraphicsSettings>,
		output: RenderJobOutput
	): void {
		if (submeshDescription.subObjectRef.isHidden) {
			return;
		}

		super.updaterRenderJob(submeshDescription, renderSettings, output);
		output.uniforms.push('billboardImage', this.texture);
	}
}

const SelfId_0 = InObjLocalId.new(InObjIdType.ObjSelf, 0);
const DefaultPileIconColor = RGBA.parseFromHexString("#a3a5a4");
const DefaultOutlineRenderJobUpdater = new BasicAnalyticalRenderJobUpdater(5);
const DashedOutlineRenderJobUpdater = new BasicAnalyticalRenderJobUpdater(5, true);
const DefaultLodGroupLocalIdent = newLodGroupLocalIdent(0, 0.18);

const DefaultTextStyleOptions = new TextStyleOptions();
const DefaultTextRenderJobUpdater = new TextRenderJobUpdater(FONT_ID.ROBOTO, RGBA.new(0, 0, 0, 1));
const DefaultTextOverlayRenderJobUpdater = new TextOverlayRenderJobUpdater(FONT_ID.ROBOTO, RGBA.new(0, 0, 0, 1));

const CameraAlignedTextRenderJobUpdater =
	new TextRenderJobUpdater(FONT_ID.ROBOTO, RGBA.new(0, 0, 0, 1), ShaderFlags.ALIGN_TEXT_GEO_WITH_CAMERA_UP);
const CameraAlignedTextOverlayRenderJobUpdater =
	new TextOverlayRenderJobUpdater(FONT_ID.ROBOTO, RGBA.new(0, 0, 0, 1), ShaderFlags.ALIGN_TEXT_GEO_WITH_CAMERA_UP);

const DefaultAnnotationBlockOptions = new BlockOptions(3, 2, 0, TEXT_ALIGN_H.CENTER, TEXT_ALIGN_V.CENTER, 'shrink');
const DefaultFontSize = 2;
const DefaultTrackerRowIndexLineLength = 6;
const DefaultOutlineRadius = 0.05;

const DefaultPileOutlineGeo = PolylineGeometry.newWithAutoIds([
	new Vector3(0, 0, -0.5),
	new Vector3(0, 0, 0.5),
], DefaultOutlineRadius);

const DefaultTrackerFrameOutlineGeo = PolylineGeometry.newWithAutoIds([
	new Vector3(-0.5, -0.5, 0),
	new Vector3(-0.5, 0.5, 0),
	new Vector3(0.5, 0.5, 0),
	new Vector3(0.5, -0.5, 0),
	new Vector3(-0.5, -0.5, 0),
]);

const DefaultHexagonAnnotationGeo = PolylineGeometry.newWithAutoIds([
	new Vector3(0, -0.5, 0),
	new Vector3(-0.433, -0.25, 0),
	new Vector3(-0.433, 0.25, 0),
	new Vector3(0, 0.5, 0),
	new Vector3(0.433, 0.25, 0),
	new Vector3(0.433, -0.25, 0),
	new Vector3(0, -0.5, 0)
], DefaultOutlineRadius) 

const QuaternionIdentity = Object.freeze(Quaternion.identity());
