import type { EventStackFrame, ScopedLogger, VersionedValue } from 'engine-utils-ts';
import { ObjectUtils } from 'engine-utils-ts';
import type { Matrix4, Transform } from 'math-ts';
import type { EntityIdAny } from 'verdata-ts';

import type { BasicAnalyticalRepresentation, IdBimGeo, IdBimMaterial, ObjectRepresentation } from '../';
import { BimProperty } from '../bimDescriptions/BimProperty';
import type { PropertiesCollection, PropertiesPatch } from '../bimDescriptions/PropertiesCollection';
import { type PropsGroupBase } from '../properties/Props';
import type { IdBimScene, SceneInstance, SceneInstancePatch, SceneInstances} from '../scene/SceneInstances';
import { SceneObjDiff } from 'src/scene/SceneObjDiff';
import type {
    AnySolverObjectInput, InObjectDependencies, SolverLegacyPropsInput
} from './ObjectsSelector';
import { PropsProxy } from './propsProxy/PropsProxy';
import type { PropsReadsHistory} from './propsProxy/PropsReadsHistory';
import { PropsReadsHistoryInterner } from './propsProxy/PropsReadsHistory';
import type { ReactiveSolverBase, SolverInstancePatchResult } from './ReactiveSolverBase';
import { RuntimeSystemExecutionStatus } from 'ui-bindings';
import type { AnySharedDependenciesInput, SharedGlobalsInput, GlobalArgsSelector } from './RuntimeGlobals';
import { PerInstanceCalculationsInvalidator } from './PerInstanceCalculationsInvalidator';
import { Quaternion } from 'math-ts';


export interface InObjectLastUsedDependencies {
    legacyProps: SolverLegacyPropsInput;
    newPropsReads: PropsReadsHistory | null;
    localTransform: Readonly<Transform>;
    worldMatrix: Readonly<Matrix4>;
    rotation: Readonly<Quaternion>;
    representation: Readonly<ObjectRepresentation> | null;
    representationAnalytical: Readonly<BasicAnalyticalRepresentation> | null;
}
export class ReactiveSolverRunner implements VersionedValue {
    readonly identifier: string;
    readonly logger: ScopedLogger;
    readonly solver: ReactiveSolverBase;

    readonly propsReadsHistoryInterner = new PropsReadsHistoryInterner();
    readonly reusedPropsProxy = new PropsProxy<PropsGroupBase>();
    readonly globalArgsSelector: GlobalArgsSelector | null;

    readonly perObjInvalidator: PerInstanceCalculationsInvalidator<Partial<InObjectLastUsedDependencies>, SolverInstancePatchResult>;
    readonly perChildInvalidator: PerInstanceCalculationsInvalidator<ChildLastUsed, undefined>;

    private _currentGlobalValue?: SharedGlobalsInput<GlobalArgsSelector>;

    readonly parentFlag: SceneObjDiff = 0;

    readonly typeIdentsAffected: string[];
    readonly childrenTypeIdentsAffected: string[];

    calculationsCount: number = 0;

    private _version: number = 0;
    
    private readonly inObjectInvalidationFlags: SceneObjDiff;
    private readonly childInvalidationFlags: number;

    constructor(logger: ScopedLogger, solver: ReactiveSolverBase) {
        this.identifier = solver.identifier;
        this.logger = logger.newScope(this.identifier);
        this.solver = solver;

        if (solver.childrenSelector) {
            this.parentFlag = SceneObjDiff.SpatialParentRef;
        }

        const inObjectDependencies = this.solver.objSelector.inObjectDependencies;

        let isCachable: boolean;
        if (solver.childrenSelector) {
            isCachable = false;
        } else {
            const invFlags = solver.objSelector.invalidationFlags;
            if (((invFlags & (SceneObjDiff.LegacyProps | SceneObjDiff.NewProps | SceneObjDiff.WorldPosition)) === invFlags)
                && !solver.objSelector.inObjectDependencies.worldMatrix
            ) {
                isCachable = true;
            } else {
                isCachable = false;
            }
        }

        if (solver.cache && !isCachable) {
            this.logger.warn(`caching supported for props only solvers`);
        }
        const useCache = solver.cache == undefined ? isCachable : solver.cache && isCachable;

        this.inObjectInvalidationFlags = solver.objSelector.invalidationFlags;
        this.childInvalidationFlags = solver.childrenSelector ? SceneObjDiff.LegacyProps | SceneObjDiff.NewProps : 0;

        this.perObjInvalidator = new PerInstanceCalculationsInvalidator({
            logger: this.logger.newScope('in-object'),
            checkIfArgsChanged: (diff, prev, curr) => !checkIfArgsAreTheSame(
                curr,
                diff & this.inObjectInvalidationFlags,
                prev,
                inObjectDependencies
            ),
            computationsCacheSize: useCache ? 2 : 0,
        });

        const childDeps = this.solver.childrenSelector;

        this.perChildInvalidator = new PerInstanceCalculationsInvalidator({
            logger: this.logger.newScope('children'),
            checkIfArgsChanged: (diff, prev, curr) => {
                const childDefaultProps = childDeps!.getDefaultPropsFor(curr.type_identifier);
                return !checkIfArgsAreTheSame(
                    curr,
                    diff & this.childInvalidationFlags,
                    { legacyProps: prev.propsUsed, newPropsReads: prev.newPropsReads },
                    { legacyProps: childDefaultProps }
                )
            },
            computationsCacheSize: 0,
        })

        this.globalArgsSelector = ObjectUtils.isObjectEmpty(solver.globalArgsSelector) ? null : solver.globalArgsSelector!;

        this.typeIdentsAffected = Array.from(this.solver.objSelector.objectTypeIdentifier);
        this.childrenTypeIdentsAffected = this.solver.childrenSelector?.identifiersWithProps.map(t => t.type_identifier) ?? [];
    }

    dispose() {
        this.perObjInvalidator.clear();
        this.perChildInvalidator.clear();
        this._version += 1;
    }

    version(): number {
        return this._version + this.perObjInvalidator.version() + this.perChildInvalidator.version();
    }

    status(): RuntimeSystemExecutionStatus {
        if (this.perObjInvalidator.hasDirtyIds() || this.perChildInvalidator.hasDirtyIds()) {
            return RuntimeSystemExecutionStatus.Waiting;
        }
        return RuntimeSystemExecutionStatus.Done;
    }

    invalidateCaches(args: {
        geometriesIds: Set<IdBimGeo>,
        materialsIds: Set<IdBimMaterial>,
    }) {
        this._version += 1;
        if (this.solver.invalidateInnerCache) {
            this.solver.invalidateInnerCache(args);
            this._version += 1;
        };
        if (!this.perObjInvalidator.computationsCacheSize) {
            return;
        }
        const idsReused: EntityIdAny[] = [];
        function containsIdsFromSet(setToCheck: Set<EntityIdAny>) {
            for (const id of idsReused) {
                if (setToCheck.has(id)) {
                    return true;
                }
            }
            return false;
        }
        this.perObjInvalidator.invalidateCachedOutputs((output) => {
            if (output.repr) {
                idsReused.length = 0;
                output.repr.geometriesIdsReferences(idsReused);
                if (containsIdsFromSet(args.geometriesIds)) {
                    return true;
                }
                idsReused.length = 0;
                output.repr.materialIdsReferences(idsReused);
                if (containsIdsFromSet(args.materialsIds)) {
                    return true;
                }
            }
            if (output.reprAnalytical) {
                idsReused.length = 0;
                output.reprAnalytical.geometriesIdsReferences(idsReused);
                if (containsIdsFromSet(args.geometriesIds)) {
                    return true;
                }
                idsReused.length = 0;
                output.reprAnalytical.materialIdsReferences(idsReused);
                if (containsIdsFromSet(args.materialsIds)) {
                    return true;
                }
            }
            return false;
        });
    }

    addNewFittingObjects(objs: Iterable<[IdBimScene, Readonly<SceneInstance>]>) {
        for (const [id, state] of objs) {
            if (this.typeIdentsAffected.includes(state.type_identifier)) {
                this.perObjInvalidator.addNewObject(id);
            }
            if (this.childrenTypeIdentsAffected.includes(state.type_identifier)) {
                this.perChildInvalidator.addNewObject(id);
            }
        }
    }

    removeObjects(ids: Iterable<IdBimScene>) {
        for (const id of ids) {
            const childState = this.perChildInvalidator.getLastArgs(id);
            if (childState) {
                this.perObjInvalidator.markDirtyIfIncluded(childState.parentId);
            }
        }
        this.perChildInvalidator.removeObjects(ids);
        this.perObjInvalidator.removeObjects(ids);
    }

    markDirtyFromUpdates(updates: [IdBimScene, SceneObjDiff, Readonly<SceneInstance>][]) {

        const HierarchyInvalidationFlag = this.solver.childrenSelector ? SceneObjDiff.SpatialChildrenList : 0;
        const ParentInvalidationFullFlags = this.inObjectInvalidationFlags | HierarchyInvalidationFlag;

        for (const [id, diff, state] of updates) {

            if ((diff & ParentInvalidationFullFlags)
                && this.typeIdentsAffected.includes(state.type_identifier)
            ) {
                if (diff & HierarchyInvalidationFlag) {
                    this.perObjInvalidator.markDirty(id);
                } else {
                    this.perObjInvalidator.checkAndMarkIBecamefDirty(diff, id, state);
                }
            }

            if ((diff & this.childInvalidationFlags)
                && this.childrenTypeIdentsAffected.includes(state.type_identifier)
            ) {
                if (this.perChildInvalidator.checkIfBecameDirty(diff, id, state)) {
                    this.perObjInvalidator.markDirtyIfIncluded(state.spatialParentId);
                }
            }
        }
    }

    markDirtyFromSharedArgsUpdate(sharedArgUpdated: string) {
        if (!this.globalArgsSelector) {
            this.logger.error('attempt to invalidate shared args on solver that does not use globalArgsSelector', this.identifier, sharedArgUpdated);
            return;
        }
        if (!this.globalArgsSelector[sharedArgUpdated]) {
            this.logger.error('attempt to invalidate shared args on solver that does not use them', this.identifier, sharedArgUpdated);
        }
        this.perObjInvalidator.forceMarkDirtyAllAdded();
        this.perObjInvalidator?.clearCache();
        this._version += 1;
    }

    forceMarkDirtyIfAdded(ids: IdBimScene[]) {
        this.perObjInvalidator.forceMarkDirtyIfAdded(ids);
        this.perChildInvalidator.forceMarkDirtyIfAdded(ids);
    }

    hasAnythingToRecalculate() {
        return this.perObjInvalidator.hasDirtyIds() || this.perChildInvalidator.hasDirtyIds();
    }

    recalculateDirty(
        instances: SceneInstances,
        sharedInputArgs: AnySharedDependenciesInput | null,
        timeEndLimit: number
    ) {
        try {
            this._currentGlobalValue = sharedInputArgs ?? undefined;
            this._recalculateDirty(instances, timeEndLimit)
        } finally {
            this._currentGlobalValue = undefined;
        }
    }

    _recalculateDirty(
        instances: SceneInstances,
        timeEndLimit: number
    ) {

        if (!this.hasAnythingToRecalculate()) {
            return;
        }

        if (this.perChildInvalidator.hasDirtyIds()) {
            for (const chId of this.perChildInvalidator.consumeAllDirtyIds()) {
                const childState = instances.peekById(chId);
                if (childState && childState.spatialParentId) {
                    this.perObjInvalidator.markDirtyIfIncluded(childState.spatialParentId);
                }
            }
        }

        this._version += 1;

        // this.logger.debug('recalculating', this.dirtyIds.length);

        this.logger.assert(Boolean(this.globalArgsSelector) === Boolean(this._currentGlobalValue), 'shared args passed sanity check');

        const solver = this.solver;
        const inObjDeps = this.solver.objSelector.inObjectDependencies;
        const inObjInvalidationFlags = this.solver.objSelector.invalidationFlags;

        const childrenFilter = this.solver.childrenSelector;
        //const childrenReqProps = childrenFilter?.usedPropsMergedPaths;
        const hierarchy = this.solver.childrenSelector ? instances.spatialHierarchy : null;

        const instancesPatches: [IdBimScene, SceneInstancePatch][] = [];

        // if (this.dirtyIds.length !== this._lastCalcDirtyIdsCount) {
        //     IterUtils.sortDedupNumbers(this.dirtyIds);
        //     if (this.solver.childrenSelector) {
        //         instances.spatialHierarchy.sortByDepth(this.dirtyIds);
        //     }
        // }

        while (this.perObjInvalidator.hasDirtyIds()) {

            if (performance.now() > timeEndLimit) {
                break;
            }

            const id = this.perObjInvalidator.consumeNextDirtyId();
            if (!id) {
                continue;
            }
            const objState = instances.peekById(id);
            if (!objState) {
                this.logger.batchedError("recalculation: affected object is unexpectedly absent, ", id);
                continue;
            }
            // if (!this.lastCalcArgs.has(id)) {
            //     this.logger.batchedError("recalculation: affected object is unexpectedly absent, ", id);
            //     continue;
            // }


            let children: Readonly<{
                id: IdBimScene,
                input: AnySolverObjectInput,
                proxy: PropsProxy<PropsGroupBase>
            }>[][] | undefined = undefined;
            let childrenArgs: AnySolverObjectInput[][] = []
            if (hierarchy && childrenFilter) {

                const childrenIter = hierarchy.iteratorOfChildrenOf(id);
                children = childrenFilter.identifiersWithProps.map(newArray);
                childrenArgs = childrenFilter.identifiersWithProps.map(newArray);
                if (childrenIter) {
                    for (const chId of childrenIter) {
                        const chS = instances.peekById(chId)!;

                        const indexProps = childrenFilter.getIndexAndPropsFor(chS.type_identifier);
                        if (indexProps === undefined) {
                            continue;
                        }
                        const [index, propsDefaults, newPropsDefaults] = indexProps;
                        const proxy = new PropsProxy<PropsGroupBase>();
                        const props = getInputObjectFromDefaults(
                            {
                                legacyProps: propsDefaults,
                                propsInOut: newPropsDefaults
                            },
                            inObjInvalidationFlags,
                            chS,
                            proxy
                        );
                        children[index].push({ input: props, id: chId, proxy });
                        childrenArgs[index].push(props)
                    }
                }
            }

            // compute
            let result: SolverInstancePatchResult;
            let lastArgs: Partial<InObjectLastUsedDependencies>;
            try {
                const cached = this.perObjInvalidator.tryGetCachedResult(objState);
                if (cached) {
                    result = cached.result;
                    lastArgs = cached.input;
                } else {
                    const solverArgs = getInputObjectFromDefaults(inObjDeps, inObjInvalidationFlags, objState, this.reusedPropsProxy);
                    result = solver.compute(solverArgs, this._currentGlobalValue ?? undefined, childrenArgs);
                    if (solverArgs.propsInOut) {
                        result.propsPatch = this.reusedPropsProxy.producePatch() ?? undefined;

                        let { propsInOut, ...restArgs } = solverArgs;
                        const readsHistory = this.reusedPropsProxy.getReadsHistory(this.propsReadsHistoryInterner);

                        lastArgs = {
                            ...restArgs,
                            newPropsReads: readsHistory,
                        };
                    } else {
                        lastArgs = solverArgs as Partial<InObjectLastUsedDependencies>;
                    }

                    this.perObjInvalidator.putIntoCache(lastArgs, result);
                }
            } catch (e) {
                this.logger.batchedError('runtime computation error', e);
                result = {};
                lastArgs = {};
            }

            this.calculationsCount += 1;
            this.perObjInvalidator.markCalculated(id, lastArgs);

            if (children?.length) {
                for (const childsPerType of children) {
                    for (const child of childsPerType) {
                        let childUsed: ChildLastUsed | null = this.perChildInvalidator.getLastArgs(child.id);
                        const readsHistory = child.proxy.getReadsHistory(this.propsReadsHistoryInterner);
                        if (!childUsed) {
                            childUsed = new ChildLastUsed(id, child.input.legacyProps ?? {}, readsHistory);
                        } else {
                            childUsed.parentId = id;
                            childUsed.propsUsed = child.input.legacyProps ?? {};
                            childUsed.newPropsReads = readsHistory;
                        }

                        this.perChildInvalidator.markCalculated(child.id, childUsed);
                    }
                }
            }

            let representation: ObjectRepresentation | undefined | null = result.repr;
            if (result.repr && objState.representation && result.repr.equals(objState.representation)) {
                representation = undefined;
            }

            let representationAnalytical: BasicAnalyticalRepresentation | undefined | null = result.reprAnalytical;
            if (result.reprAnalytical && objState.representationAnalytical &&
                result.reprAnalytical.equals(objState.representationAnalytical)
            ) {
                representationAnalytical = undefined;
            }

            const properties: PropertiesPatch = [];
            if (result.legacyProps) {
                for (const p of result.legacyProps) {
                    const mergedPath = BimProperty.MergedPath(p.path);
                    const stateProp = objState.properties.get(mergedPath);
                    p.readonly = p.readonly ?? true;
                    if (!stateProp?.valueEqual(p) || stateProp?.readonly !== p.readonly) {
                        p.isComputedBy = solver.identifier;
                        properties.push([mergedPath, p])
                    }
                }
            }
            if (result.removeProps?.length) {
                for (const mergedPath of result.removeProps) {
                    properties.push([mergedPath, null]);
                }
            }
            if (result.removePropsWithPrefixs?.length) {
                for (const prefix of result.removePropsWithPrefixs) {
                    for (const prop of objState.properties.values()) {
                        if (prop._mergedPath.startsWith(prefix)) {
                            properties.push([prop._mergedPath, null]);
                        }
                    }
                }
            }

            if (representation || representationAnalytical || properties.length || result.propsPatch) {
                instancesPatches.push([id, {
                    representation,
                    representationAnalytical,
                    properties,
                    props: result.propsPatch,
                }]);
            }
        }
        // this._lastCalcDirtyIdsCount = this.dirtyIds.length;

        // because all calculations at this point derived, no point in saving undo for them
        const eventParams: Partial<EventStackFrame> = {
            isEventDerived: true,
        };
        this.logger.debug('recalculated', instancesPatches.length);

        instances.applyPatches(instancesPatches, eventParams);
    }
}


export class ChildLastUsed {

    constructor(
        public parentId: IdBimScene,
        public propsUsed: SolverLegacyPropsInput,
        public newPropsReads: PropsReadsHistory | null,
    ) {
    }
}

const flagsThatInvalidateImmidiately = SceneObjDiff.Representation | SceneObjDiff.RepresentationAnalytical
    | SceneObjDiff.GeometryReferenced | SceneObjDiff.LocalTransform;

function checkIfArgsAreTheSame(
    currState: Readonly<SceneInstance>,
    flagsToCheck: SceneObjDiff,
    lastArgs: Readonly<Partial<InObjectLastUsedDependencies>>,
    inObjDeossDefaults: Partial<InObjectDependencies<SolverLegacyPropsInput, PropsGroupBase>>, 
): boolean {
    if (flagsToCheck & SceneObjDiff.LegacyProps) {
        if (!lastArgs.legacyProps || !checkIfPropsAreTheSame(currState.properties, lastArgs.legacyProps, inObjDeossDefaults.legacyProps!)) {
            return false;
        }
    }
    if (flagsToCheck & SceneObjDiff.NewProps) {
        if (lastArgs.newPropsReads && !lastArgs.newPropsReads.arePropsValuesTheSame(currState.props)) {
            return false;
        }
    }
    if (flagsToCheck & SceneObjDiff.WorldPosition) {
        if (lastArgs.rotation) {
            if (!lastArgs.rotation.equals(peekRotationFromWorldMatrixReused(currState.worldMatrix))) {
                return false;
            }
        } else {
            return false;
        }
    }
    if (flagsToCheck & flagsThatInvalidateImmidiately) {
        return false;
    }
    return true;
}


export function checkIfPropsAreTheSame(
    bimObjProps: PropertiesCollection,
    lastUsedProps: SolverLegacyPropsInput,
    defaultSolverProps: SolverLegacyPropsInput,
) {
    for (const key in lastUsedProps) {
        const lastProp = lastUsedProps[key];
        const objProp = bimObjProps.get(lastProp._mergedPath);
        if (objProp) {
            if (!lastProp.valueEqual(objProp)) {
                return false;
            }
        } else {
            // if not present in object, equal to default
            const defaultProp = defaultSolverProps[key];
            if (!defaultProp.valueEqual(lastProp)) {
                return false;
            }
        }
    }
    return true;
}

function newArray(): any[] {
	return [];
}

export function getInputPropsFromDefaults<Args extends SolverLegacyPropsInput>(
    defaults: SolverLegacyPropsInput,
    object: SceneInstance
): Args {
    const args: SolverLegacyPropsInput = {};
    // it is important that js specifies the order of iteration of object keys
    // runtime code relies on the same order of keys insertion as in default args
    for (const key in defaults) {
        let defaulProp = defaults[key];
        args[key] = object.properties.get(defaulProp._mergedPath) ?? defaulProp;
    }
    return args as Args;
}

export function getInputObjectFromDefaults(
    defaults: AnySolverObjectInput,
    invalidationFlags: SceneObjDiff,
    instance: SceneInstance,
    newPropsProxyReused: PropsProxy<PropsGroupBase>,
): AnySolverObjectInput {
    if (invalidationFlags === SceneObjDiff.LegacyProps) { // fast path for most solvers
        return {legacyProps: getInputPropsFromDefaults(defaults.legacyProps!, instance)};
    }
    const res: AnySolverObjectInput = {};
    if (defaults.legacyProps) {
        res.legacyProps = getInputPropsFromDefaults(defaults.legacyProps, instance);
    }
    if (defaults.propsInOut) {
        newPropsProxyReused.reset();
        newPropsProxyReused.setForProxying(instance.props);
        res.propsInOut = newPropsProxyReused.proxy;
    }
    if (defaults.localTransform) {
        res.localTransform = instance.localTransform;
    }
    if (defaults.worldMatrix) {
        res.worldMatrix = instance.worldMatrix;
    }
    if (defaults.rotation) {
        res.rotation = peekRotationFromWorldMatrixReused(instance.worldMatrix).clone();
    }
    if (defaults.representation !== undefined) {
        res.representation = instance.representation ?? defaults.representation;
    }
    if (defaults.representationAnalytical !== undefined) {
        res.representationAnalytical = instance.representationAnalytical ?? defaults.representationAnalytical;
    }
    return res;
}

const _reusedQuatForRotationCheck = new Quaternion();
function peekRotationFromWorldMatrixReused(matrix: Matrix4): Quaternion {
    return _reusedQuatForRotationCheck.setFromRotationMatrix(matrix).round().normalize();
}

