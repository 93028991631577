import { type Yield, CompressibleNumbersArray, WorkerClassPassRegistry } from 'engine-utils-ts';
import type { ShadingFactorsTable } from '..';
import type { NumberProperty } from '../properties/PrimitiveProps';
import type { PropValueTotalHash } from '../properties/Props';
import { getPropertyReferenceId } from '../properties/PropsRefsIds';
import { NumbersArrayProperty } from '../properties/spreadsheets/NumbersArrayProperty';
import { VirtualPropertyBase } from '../properties/VirtualProperty';
import { EnergyStageCalculateConfig, EnergyStageOverrideConfig, type EnergyStageCalcConfig } from './EnergyCalcConfig';
import { choosePvModuleTechnologyCoefssB0toB5 } from './EnergyEquipmentGlobals';
import { EnergyPipelineBuilder } from './EnergyPipelineBuilder';
import { EnergyPipelineStage } from './EnergyPipelineProperty';
import { energySucess, type EnergyResult, EnergyFailure } from './EnergyResult';
import { EnergyStageChartWPerArea, type EnergyStageChart, EnergyStageChartWPerAreaMulti, EnergyStageChartIIVV } from './EnergyStageCharts';
import type { BifacialityStageLossSettings, TemperatureLossCalculationSettings } from './EnergyStageSettingsConfig';
import type { EnergyYieldPropsGroup } from './EnergyYieldPropsGroup';
import type { PlaneOfArrayIrradianceWithWarnings } from './POA_Irradiance';

const Nominal_Irradiance = 1000;
const Nominal_Temperature = 25;

export interface StageEnergyConfigPerString {
    ['tilt']: EnergyStageCalcConfig;
    ['shading']: EnergyStageCalcConfig;
    ['bifaciality']: EnergyStageCalcConfig;
    ['soiling']: EnergyStageCalcConfig;
    ['incidence_angle']: EnergyStageCalcConfig;
    ['temperature']: EnergyStageCalcConfig;
    ['spectral_correction']: EnergyStageCalcConfig;
    ['module_quality']: EnergyStageCalcConfig;
    ['module_mismatch']: EnergyStageCalcConfig;
}


export type StringEnergyArgs = {

    stageEnergyConfig: StageEnergyConfigPerString;
    poa_data: PlaneOfArrayIrradianceWithWarnings;

    Ambient_Temperature: CompressibleNumbersArray | null,
    Wind_Speed: CompressibleNumbersArray | null,
    Relative_Humidity: CompressibleNumbersArray | null,

    Impp_STC: number;
    Vmpp_STC: number;
    Isc_STC: number;
    Voc_STC: number;
    VMaxUL_STC: number;
    Voltage_Temperature_Coefficients: number;
    Current_Temperature_Coefficients: number;
    Power_Temperature_Coefficients: number;
    ModuleTechnology: string;
    bifaciality_factor: number;

    shading_factors: ShadingFactorsTable | null;
    
    module_area: number;
    string_size: number;
    array_height_m: number;

    string_voltage: number;
    module_power_W: number;
}



export class EnergyYieldPerStringProducer extends VirtualPropertyBase<EnergyYieldPropsGroup, StringEnergyArgs> {

    constructor(args: StringEnergyArgs) {
        super(args);
        Object.freeze(this);
    }

    *_calculate(): Generator<Yield, EnergyYieldPropsGroup, unknown> {

        let {
            poa_data, Ambient_Temperature, Relative_Humidity, Wind_Speed, Impp_STC, Vmpp_STC, Isc_STC, Voc_STC, Voltage_Temperature_Coefficients, Current_Temperature_Coefficients, Power_Temperature_Coefficients, ModuleTechnology, bifaciality_factor, string_size, module_area, array_height_m, shading_factors, stageEnergyConfig,
        } = this.args;

        const area = module_area * string_size;

        let {
            sunCalculationsWarnings, positionCalcWarnings, altitude, declination, diffuse_horizontal_irradiance, direct_normal_irradiance, fresnel_multiplier, horizontal_irradiance, incidence_angle, longitude_subsolar_point, module_tilt_angle, poa_albedo, poa_beam, poa_diffuse, solar_time, sun_azimuth, sun_height,
        } = poa_data;

        const builder = new EnergyPipelineBuilder({
            nominal_dc_power: Impp_STC * Vmpp_STC * string_size,
            modules_area: module_area * string_size,
            shading_factors: shading_factors,
        });

        let additional_context: { [key: string]: NumbersArrayProperty | NumberProperty; } = {};

        builder.addContext('horizontal_irradiance', new NumbersArrayProperty(horizontal_irradiance));
        builder.addContext('direct_normal_irradiance', new NumbersArrayProperty(direct_normal_irradiance));
        builder.addContext('diffuse_horizontal_irradiance', new NumbersArrayProperty(diffuse_horizontal_irradiance));
        builder.addContext('sun_height', new NumbersArrayProperty(sun_height));
        builder.addContext('sun_azimuth', new NumbersArrayProperty(sun_azimuth));
        builder.addContext('poa_diffuse', new NumbersArrayProperty(poa_diffuse));
        builder.addContext('poa_albedo', new NumbersArrayProperty(poa_albedo));
        builder.addContext('poa_beam', new NumbersArrayProperty(poa_beam));
        builder.addContext('longitude_subsolar_point', new NumbersArrayProperty(longitude_subsolar_point));
        builder.addContext('incidence_angle', new NumbersArrayProperty(incidence_angle));
        builder.addContext('fresnel_multiplier', new NumbersArrayProperty(fresnel_multiplier));
        builder.addContext('declination', new NumbersArrayProperty(declination));
        builder.addContext('module_tilt_angle', new NumbersArrayProperty(module_tilt_angle));
        builder.addContext('solar_time', new NumbersArrayProperty(solar_time));

        builder.addStage(new EnergyPipelineStage(
            'horizontal_irradiance',
            energySucess(new EnergyStageChartWPerArea(area, horizontal_irradiance), sunCalculationsWarnings)
        ));

        const tiltCalcConfig = stageEnergyConfig.tilt;
        let tiltEnergyChart: EnergyResult<EnergyStageChart> | null = null;
        if (tiltCalcConfig instanceof EnergyStageCalculateConfig) {
            tiltEnergyChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
        } else {
            const reqeustedResultMultiplier = tiltCalcConfig?.multiplier ?? 1;
            const horIrradianceSum = horizontal_irradiance.sum();
            const poaSum = poa_beam.sum() + poa_diffuse.sum() + poa_albedo.sum();
            const requiredMultiplier = reqeustedResultMultiplier * horIrradianceSum / poaSum;

            poa_beam = poa_beam.map(poa_beam.unit, (v) => v * requiredMultiplier);
            poa_diffuse = poa_diffuse.map(poa_diffuse.unit, (v) => v * requiredMultiplier);
            poa_albedo = poa_albedo.map(poa_albedo.unit, (v) => v * requiredMultiplier);

            if (tiltCalcConfig instanceof EnergyStageOverrideConfig) {
                tiltEnergyChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
            }
        }
        if (tiltEnergyChart) {
            builder.addStage(new EnergyPipelineStage(
                'tilt',
                energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]), positionCalcWarnings)
            ));
        }

        const POA_Irradiance_nominal = CompressibleNumbersArray.map3(
            poa_beam, poa_diffuse, poa_albedo,
            'W/m2',
            (poa_beam, poa_diffuse, poa_albedo) => {
                return (poa_beam + poa_diffuse + poa_albedo);
            }
        );
        const nominal_power_chart = CompressibleNumbersArray.map(
            POA_Irradiance_nominal,
            'W',
            (irr) => {
                return irr / Nominal_Irradiance * Impp_STC * Vmpp_STC * string_size;
            }
        );
        builder.addNominalPowerChart(nominal_power_chart);


        const shadingCalcConfig = stageEnergyConfig.shading;
        let shadingEnergyChart: EnergyResult<EnergyStageChart> | null = null;
        if (shadingCalcConfig instanceof EnergyStageCalculateConfig) {
            if (shading_factors) {
                poa_beam = CompressibleNumbersArray.map3(
                    sun_height,
                    sun_azimuth,
                    poa_beam,
                    'W/m2',
                    (sun_height, sun_azimuth, poa_beam) => {
                        const directShading = shading_factors!.sampleAt(sun_height * 180 / Math.PI, sun_azimuth * 180 / Math.PI);
                        const shadingMult = 1 - directShading;
                        return poa_beam * shadingMult;
                    }
                );

                const diffuseShadingFactor = shading_factors.ambientShadingFactor();
                const diffuseShadingFactorMult = 1 - diffuseShadingFactor;
                poa_diffuse = CompressibleNumbersArray.map(
                    poa_diffuse,
                    'W/m2',
                    (poa_diffuse) => poa_diffuse * diffuseShadingFactorMult
                );

                shadingEnergyChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
            } else {
                shadingEnergyChart = EnergyFailure.new('ShadingNotCalculated');
            }

        } else if (shadingCalcConfig instanceof EnergyStageOverrideConfig) {

            const energyResultMultiplier = shadingCalcConfig.multiplier;
            poa_beam = poa_beam.map(poa_beam.unit, (v) => v * energyResultMultiplier);
            poa_diffuse = poa_diffuse.map(poa_diffuse.unit, (v) => v * energyResultMultiplier);
            poa_albedo = poa_albedo.map(poa_albedo.unit, (v) => v * energyResultMultiplier);
            const shadingEnergy = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
            shadingEnergyChart = shadingEnergy;
        }
        if (shadingEnergyChart) {
            builder.addStage(new EnergyPipelineStage(
                'shading',
                shadingEnergyChart
            ));
        }

        const bifacialityCalcConfig = stageEnergyConfig.bifaciality;
        let bifacialityChart: EnergyResult<EnergyStageChart> | null = null;
        if (bifacialityCalcConfig instanceof EnergyStageCalculateConfig) {
            const bifacialityConfig = bifacialityCalcConfig.settings as BifacialityStageLossSettings;
            const ground_albedo_factor = bifacialityConfig.ground_albedo.value;
            if (shading_factors && ground_albedo_factor > 0 && bifaciality_factor > 0) {
                // find out at which sun_height shadowing starts for bifaciality stage later
                let shadingStartsAtSunHeight: number = 0;
                let shadingStartAtModuleTilt: number = 0;
                const _ = CompressibleNumbersArray.map3(
                    sun_height,
                    module_tilt_angle,
                    sun_azimuth,
                    '',
                    (sun_height, module_tilt_angle, sun_azimuth) => {
                        const directShading = shading_factors!.sampleAt(sun_height, sun_azimuth);
                        if (directShading > 0 && sun_height > shadingStartsAtSunHeight) {
                            shadingStartAtModuleTilt = module_tilt_angle;
                            shadingStartsAtSunHeight = sun_height;
                        }
                        return 0;
                    }
                );

                // evaluate row to row space to neighbour trackers from shading
                const Pitch = array_height_m * Math.sin(shadingStartAtModuleTilt) / Math.tan(shadingStartsAtSunHeight)
                    + array_height_m * Math.cos(shadingStartAtModuleTilt)
                    + array_height_m;

                // for simplicity, do not introduce new light component, just increase diffuse light
                poa_diffuse = CompressibleNumbersArray.map5(
                    poa_diffuse,
                    horizontal_irradiance,
                    diffuse_horizontal_irradiance,
                    module_tilt_angle,
                    sun_height,
                    'W/m2',
                    (poa_diffuse, horizontal_irradiance, diffuse_horizontal_irradiance, module_tilt_angle, sun_height) => {

                        if (sun_height < 0.005) {
                            return poa_diffuse;
                        }

                        let reflection: number = 0;
                        const ground_direct_shading_factor = array_height_m / Pitch * (Math.cos(module_tilt_angle) + Math.sin(module_tilt_angle) / Math.tan(sun_height));
                        if (ground_direct_shading_factor < 0.999) {
                            reflection = horizontal_irradiance * (1 - ground_direct_shading_factor);
                        }

                        let diffuse: number = 0;
                        const diffuse_ground_shading_factor = Math.cos(module_tilt_angle) * array_height_m / Pitch;
                        if (diffuse_ground_shading_factor < 0.999) {
                            diffuse = diffuse_horizontal_irradiance * (1 - diffuse_ground_shading_factor);
                        }
                        return poa_diffuse + (reflection + diffuse) * ground_albedo_factor * bifaciality_factor;
                    }
                );
                bifacialityChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
            } else if (bifaciality_factor === 0) {
                bifacialityChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
            } else {
                bifacialityChart = EnergyFailure.new('ShadingNotCalculated');
            }

        } else if (bifacialityCalcConfig instanceof EnergyStageOverrideConfig) {
            const energyResultMultiplier = bifacialityCalcConfig.multiplier;
            poa_beam = poa_beam.map(poa_beam.unit, (v) => v * energyResultMultiplier);
            poa_diffuse = poa_diffuse.map(poa_diffuse.unit, (v) => v * energyResultMultiplier);
            poa_albedo = poa_albedo.map(poa_albedo.unit, (v) => v * energyResultMultiplier);
            bifacialityChart = energySucess(new EnergyStageChartWPerAreaMulti(area, [poa_beam, poa_albedo, poa_diffuse]));
        }

        if (bifacialityChart) {
            builder.addStage(new EnergyPipelineStage(
                'bifaciality',
                bifacialityChart
            ));
        }

        let poa_irradiance_soilig_multiplier: number;
        const soilingCalcConfig = stageEnergyConfig.soiling;
        if (soilingCalcConfig instanceof EnergyStageCalculateConfig) {
            builder.addStage(new EnergyPipelineStage('soiling', EnergyFailure.new('InvalidStageConfiguration')));
            poa_irradiance_soilig_multiplier = 1;
        } else if (soilingCalcConfig instanceof EnergyStageOverrideConfig) {
            builder.addPrevStageBasicMultiplier('soiling', soilingCalcConfig.multiplier);
            poa_irradiance_soilig_multiplier = soilingCalcConfig.multiplier;
        } else {
            poa_irradiance_soilig_multiplier = 1;
        }

        let POA_Irradiance: CompressibleNumbersArray;

        const incidenceAngleConfig = stageEnergyConfig.incidence_angle;
        if (incidenceAngleConfig instanceof EnergyStageCalculateConfig) {
            POA_Irradiance = CompressibleNumbersArray.map4(
                poa_beam, poa_diffuse, poa_albedo, fresnel_multiplier,
                'W/m2',
                (poa_beam, poa_diffuse, poa_albedo, POA_Beam_Fresnel_Multiplier) => {
                    const beam = poa_beam * POA_Beam_Fresnel_Multiplier;
                    return (beam + poa_diffuse + poa_albedo) * poa_irradiance_soilig_multiplier;
                }
            );
        } else if (incidenceAngleConfig instanceof EnergyStageOverrideConfig) {
            POA_Irradiance = CompressibleNumbersArray.map3(
                poa_beam, poa_diffuse, poa_albedo,
                'W/m2',
                (poa_beam, poa_diffuse, poa_albedo) => {
                    return (poa_beam + poa_diffuse + poa_albedo) * poa_irradiance_soilig_multiplier * incidenceAngleConfig.multiplier;
                }
            );
        } else {
            POA_Irradiance = CompressibleNumbersArray.map3(
                poa_beam, poa_diffuse, poa_albedo,
                'W/m2',
                (poa_beam, poa_diffuse, poa_albedo) => {
                    return (poa_beam + poa_diffuse + poa_albedo) * poa_irradiance_soilig_multiplier;
                }
            );
        }
        if (incidenceAngleConfig) {
            builder.addStage(new EnergyPipelineStage(
                'incidence_angle',
                energySucess(new EnergyStageChartWPerArea(area, POA_Irradiance))
            ));
        }


        // const pvConversionConfig = stageEnergyConfig.pv_conversion;
        // const output_with_STC_temp = CompressibleNumbersArray.map(
        //     POA_Irradiance,
        //     'W',
        //     (POA) => POA / Nominal_Irradiance * Impp_STC * Vmpp_STC * string_size
        // );
        // builder.addStage(new EnergyPipelineStage('pv_conversion', energySucess(new EnergyStageChartW(output_with_STC_temp))));
        let Impp = CompressibleNumbersArray.map(
            POA_Irradiance,
            'A',
            (Irradiance) => Impp_STC * (Irradiance / Nominal_Irradiance)
        );
        let Isc = CompressibleNumbersArray.map(
            POA_Irradiance,
            'A',
            (Irradiance) => Isc_STC * (Irradiance / Nominal_Irradiance)
        );
        let Vmpp = CompressibleNumbersArray.newSingleValue(
            'V',
            Vmpp_STC * string_size,
            POA_Irradiance.length,
            POA_Irradiance.orderingType
        );
        let Voc = CompressibleNumbersArray.newSingleValue(
            'V',
            Voc_STC,
            POA_Irradiance.length,
            POA_Irradiance.orderingType
        );
        const argsWarnings = this._checkStringModulesVoltage();
        builder.addStage(new EnergyPipelineStage('pv_conversion', energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc), argsWarnings)));


        const temperatureStageConfig = stageEnergyConfig.temperature;
        let temperatureStageResult: EnergyResult<EnergyStageChart>;
        if (temperatureStageConfig instanceof EnergyStageOverrideConfig) {
            const mult = temperatureStageConfig.multiplier;
            Impp = Impp.map(Impp.unit, (v) => v * mult);
            Isc = Isc.map(Isc.unit, (v) => v * mult);
            temperatureStageResult = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc));
        } else if (temperatureStageConfig instanceof EnergyStageCalculateConfig && Ambient_Temperature) {

            const module_absorption = (temperatureStageConfig.settings as TemperatureLossCalculationSettings).module_absorption.value;
            const heat_transfer = (temperatureStageConfig.settings as TemperatureLossCalculationSettings).heat_transfer.value;
            const convective_heat_transfer = (temperatureStageConfig.settings as TemperatureLossCalculationSettings).convective_heat_transfer.value;

            const temperature_loss_warnings: EnergyFailure[] = [];
            if (!Wind_Speed) {
                Wind_Speed = CompressibleNumbersArray.newSingleValue('m/s', 0, POA_Irradiance.length, POA_Irradiance.orderingType);
                temperature_loss_warnings.push(EnergyFailure.new('WindSpeedNotFound'));
            }
            const module_temperature = CompressibleNumbersArray.map3(
                POA_Irradiance,
                Ambient_Temperature,
                Wind_Speed,
                'C',
                (POA, AT, WS) => {
                    return AT +
                        module_absorption * POA * (1 - Impp_STC * Vmpp_STC / (module_area * Nominal_Irradiance)) / (heat_transfer + convective_heat_transfer * WS);
                }
            );
            additional_context['module_temperature'] = new NumbersArrayProperty(module_temperature);

            Impp = CompressibleNumbersArray.map2(
                module_temperature,
                POA_Irradiance,
                'A',
                (T, Irradiance) => (Impp_STC + Impp_STC * (T - Nominal_Temperature) * Current_Temperature_Coefficients) * (Irradiance / Nominal_Irradiance)
            );
            Isc = CompressibleNumbersArray.map2(
                module_temperature,
                POA_Irradiance,
                'A',
                (T, Irradiance) => (Isc_STC + Isc_STC * (T - Nominal_Temperature) * Current_Temperature_Coefficients) * (Irradiance / Nominal_Irradiance)
            );
            Vmpp = CompressibleNumbersArray.map(
                module_temperature,
                'V',
                T => (Vmpp_STC + Vmpp_STC * (T - Nominal_Temperature) * (Power_Temperature_Coefficients - Current_Temperature_Coefficients)) * string_size
            );
            Voc = CompressibleNumbersArray.map(
                module_temperature,
                'V',
                T => (Voc_STC + Voc_STC * (T - Nominal_Temperature) * Voltage_Temperature_Coefficients) * string_size
            );
            temperatureStageResult = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc), temperature_loss_warnings);
        } else {
            temperatureStageResult = EnergyFailure.new('AmbientTempNotFound');
        }

        builder.addStage(new EnergyPipelineStage(
            'temperature',
            temperatureStageResult
        ));

        let spectralCorrectionChart: EnergyResult<EnergyStageChart> | null;
        const spectralStageSettings = stageEnergyConfig.spectral_correction;
        if (spectralStageSettings instanceof EnergyStageOverrideConfig) {
            const spectral_multiplier = spectralStageSettings.multiplier;
            Impp = CompressibleNumbersArray.map(Impp, 'A', (Impp) => Impp * spectral_multiplier);
            Isc = CompressibleNumbersArray.map(Isc, 'A', (Isc) => Isc * spectral_multiplier);
            spectralCorrectionChart = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc));

        } else if (spectralStageSettings === null) {
            spectralCorrectionChart = null;

        } else if (Relative_Humidity && Ambient_Temperature) {
            // spectral correction
            additional_context['relative_humidity'] = new NumbersArrayProperty(Relative_Humidity);

            const humidityMultiplier = (Relative_Humidity.max()! > 1 || Relative_Humidity.unit === '%') ? 1 / 100 : 1;

            // precipitable water
            const Precipitable_water = CompressibleNumbersArray.map2(
                Ambient_Temperature,
                Relative_Humidity,
                '',
                (t, h) => {
                    h = h * humidityMultiplier;
                    const t_k = t + 273.15;
                    const theta = t_k / 273.15;
                    const e_s = Math.exp(22.33 - 49.14 * 100 / t_k - 10.922 * 100 * 100 / t_k / t_k - 0.39015 / 100 * t_k);
                    const rho_v = 216.7 * e_s * h / t_k;
                    const h_v = 0.4976 + 1.5265 * theta + Math.exp(13.6897 * theta - 14.9188 * theta * theta * theta);
                    const water = 0.1 * h_v * rho_v;

                    return water > 0.1 ? water : 0.1;
                }
            );
            additional_context['precipitable_water'] = new NumbersArrayProperty(Precipitable_water);


            // air mass
            const P = 100 * Math.pow((44331.514 - altitude) / 11880.516, 1 / 0.1902632);

            // Spectral correction coeffitients
            let module_coeffs: number[] = choosePvModuleTechnologyCoefssB0toB5(ModuleTechnology);

            const spectral_multiplier = CompressibleNumbersArray.map2(
                sun_height,
                Precipitable_water,
                '',
                (sun_height, Precipitable_water) => {

                    const cz = Math.cos(Math.PI / 2 - sun_height);
                    const air_mass = (((1.002432 * cz + 0.148386) * cz + 0.0096467) / (((cz + 0.149864) * cz + 0.0102963) * cz + 0.000303978)) * P / 101325;

                    let multiplier = module_coeffs[0]
                        + module_coeffs[1] * air_mass
                        + module_coeffs[2] * Precipitable_water
                        + module_coeffs[3] * Math.sqrt(air_mass)
                        + module_coeffs[4] * Math.sqrt(Precipitable_water)
                        + module_coeffs[5] * air_mass / Math.sqrt(Precipitable_water);

                    return multiplier;
                }
            );

            additional_context['spectral_multiplier'] = new NumbersArrayProperty(spectral_multiplier);

            Impp = CompressibleNumbersArray.map2(Impp, spectral_multiplier, 'A', (Impp, spectral_multiplier) => Impp * spectral_multiplier);
            Isc = CompressibleNumbersArray.map2(Isc, spectral_multiplier, 'A', (Isc, spectral_multiplier) => Isc * spectral_multiplier);

            spectralCorrectionChart = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc));
        } else if (!Relative_Humidity) {
            spectralCorrectionChart = EnergyFailure.new('RelativeHumidityNotFound');
        } else {
            spectralCorrectionChart = EnergyFailure.new('AmbientTempNotFound');
        }

        if (spectralCorrectionChart) {
            builder.addStage(new EnergyPipelineStage(
                'spectral_correction',
                spectralCorrectionChart
            ));
        }

        const moduleQualityStageSettings = stageEnergyConfig.module_quality;
        if (moduleQualityStageSettings instanceof EnergyStageOverrideConfig) {
            const mult = moduleQualityStageSettings.multiplier;
            if (mult) {
                Impp = CompressibleNumbersArray.map(Impp, 'A', (Impp) => Impp * mult);
                Isc = CompressibleNumbersArray.map(Isc, 'A', (Isc) => Isc * mult);
            }
            const moduleQualityChart = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc));
            builder.addStage(new EnergyPipelineStage(
                'module_quality',
                moduleQualityChart
            ));
        } else {
        }

        const moduleMismatchStageSettings = stageEnergyConfig.module_mismatch;
        if (moduleMismatchStageSettings instanceof EnergyStageOverrideConfig) {
            const mismatchLossMult = moduleMismatchStageSettings.multiplier;
            if (mismatchLossMult) {
                Impp = CompressibleNumbersArray.map(Impp, 'A', (Impp) => Impp * mismatchLossMult);
                Isc = CompressibleNumbersArray.map(Isc, 'A', (Isc) => Isc * mismatchLossMult);
            }
            const moduleMismatchChart = energySucess(new EnergyStageChartIIVV(Impp, Isc, Vmpp, Voc));
            builder.addStage(new EnergyPipelineStage(
                'module_mismatch',
                moduleMismatchChart
            ));
        } else {
        }

        return builder.finishGroup();
    }

    _checkStringModulesVoltage(): EnergyFailure[] {
        const checkModuleVoltageValue = this.args.Vmpp_STC <= 0;
        const stringWarnings: EnergyFailure[] = [];
        let warn = "";
        if(checkModuleVoltageValue) {
            warn += "\nThe module's voltage is zero.";
        }
        const checkModuleOpenCircuitVoltage = this.args.Voc_STC <= 0;
        if(checkModuleOpenCircuitVoltage) {
            warn += "\nThe module's open circuit voltage is zero.";
        }
        if(warn){
            stringWarnings.push(new EnergyFailure({ msg: warn, errorMsgIdent: 'InvalidModuleVoltageProperties', }));
        }
        return stringWarnings;
    }

    _checkStringModulesParams(): EnergyFailure[] {
        const stringWarnings: EnergyFailure[] = [];
        const checkOpenCircuitVoltage = this.args.Voc_STC > this.args.VMaxUL_STC;
        let warn = "";
        if (checkOpenCircuitVoltage) {
            warn += "\nMax system voltage is less than the module's open circuit voltage.";
        }
        const checkModuleVoltage = this.args.Vmpp_STC > this.args.Voc_STC;
        if (checkModuleVoltage) {
            warn += "\nThe module's voltage is less than the module's open circuit voltage.";
        }
        const checkModuleCurrent = this.args.Impp_STC > this.args.Isc_STC;
        if (checkModuleCurrent) {
            warn += "\nThe module's current is less than the module's short circuit current.";
        }
        const checkModulePower = Math.abs(this.args.module_power_W - this.args.Vmpp_STC * this.args.Impp_STC) > this.args.module_power_W * 0.05;
        if (checkModulePower) {
            warn += "\nThe module's power is less than the product of the module's voltage and current.";
        }
        if (warn) {
            stringWarnings.push(new EnergyFailure({ msg: warn, errorMsgIdent: 'InvalidModuleProperties', }));
        }

        return stringWarnings;
    }

    uniqueValueHash(): PropValueTotalHash {
        return getPropertyReferenceId(this);
    }

    workerPoolJobDurationEstimatorMs(): number {
        return 7;
    }
}
WorkerClassPassRegistry.registerClass(EnergyYieldPerStringProducer);

