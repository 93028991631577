import { AnyTrackerProps, Bim, Catalog, FixedTiltTypeIdent, getPileTypeIcon, getTrackerModel, IdBimScene, NumberProperty, PileBinsConfig, PileFeaturesFlags, PileWeightClass, RoadTypeIdent, SceneInstance, StringProperty, SubstationTypeIdent, TrackerBins, TrackerPile, TrackerPilesCollection, TrackerTypeIdent, TrackerWindPosition, unpackPileFeatures } from "bim-ts";
import { convertThrow, DefaultMap, IterUtils, PollablePromise, RGBA, RGBAHex, ScopedLogger, StringUtils, Success, Yield } from "engine-utils-ts";
import jsPDF from "jspdf";
import { Aabb2, Vector2 } from "math-ts";
import { addLatoFonts } from "../fonts/fonts";
import { convertPxToMm, EquipmentDefaultColor, RoadColor, SolarArraysTypes, SubstationColor } from "../PdfCommon";
import { PdfElement, PdfReportContext } from "../PdfReportBuilder";
import { convertSvgToDataURL } from "../convertSvgToDataURL";
import { TableBuilder, TableHeaderDescription, RowBaseType } from "../TableBuilder";
import { setTextStyle } from "../TextBuilder";
import { drawLayoutOnPageByTemplate2 } from "./SubareasBreakdownPage";

const windPositionPerColor = new Map([
    [TrackerWindPosition.Interior, RGBA.parseFromHexString("#11CC0F")],
    [TrackerWindPosition.Edge, RGBA.parseFromHexString("#0A6D84")],
    [TrackerWindPosition.EdgeBot, RGBA.parseFromHexString("#0C0CFC")],
    [TrackerWindPosition.EdgeTop, RGBA.parseFromHexString("#790B85")],
    [TrackerWindPosition.Exterior, RGBA.parseFromHexString("#E50C0B")],
]);

const namesMap = new Map<TrackerWindPosition, string>([
    [TrackerWindPosition.EdgeBot, "Edge bottom"]
]);

function getDisplayNameOfWindPosition(position: TrackerWindPosition){
    return namesMap.get(position) ?? StringUtils.capitalizeFirstLatterInWord(position);
}



export class ScreenshotByWindPositions extends PdfElement {
    readonly maxImageSizePx = 330;
    readonly headerOffsetPx = 30;    
    readonly widthBarPx = 24;
    readonly offsetBetweenBarsPx = 24;
    
    constructor(
        readonly bim: Bim,
        readonly logger: ScopedLogger,
    ){
        super();
    }

    *draw(context: PdfReportContext) {
        const {page, pageFrameOffsets, layoutDrawing} = context;
        const types = [
            RoadTypeIdent,
            ...SolarArraysTypes
        ];

        const instances = this.bim.instances.peekByTypeIdents(types);
        const idPerColor = new Map<IdBimScene, { borderColor: RGBAHex}>();
        const perWindPosition = new DefaultMap<TrackerWindPosition, IdBimScene[]>(() => []);
        for (const [id, instance] of instances) {
            if(SolarArraysTypes.includes(instance.type_identifier)){
                const windPosition = extractWindPosition(instance);
                if(!windPosition || !windPositionPerColor.has(windPosition)){
                    continue;
                }
                perWindPosition.getOrCreate(windPosition).push(id);
                idPerColor.set(id, {borderColor: windPositionPerColor.get(windPosition)!}); 
            } else if(instance.type_identifier === RoadTypeIdent){
                idPerColor.set(id, {borderColor: RoadColor});
            } else if(instance.type_identifier === SubstationTypeIdent){
                idPerColor.set(id, {borderColor: SubstationColor});
            }
        }
        yield* drawLayoutOnPageByTemplate2({
            page, 
            ids: instances.map(withId => withId[0]),
            layoutDrawing: layoutDrawing,
            layoutName: "Solar arrays by wind position map",
            offsets: pageFrameOffsets,
            maxWidthPx: this.maxImageSizePx,
            headerOffsetPx: this.headerOffsetPx,
            colorize: (id) => {
                return idPerColor.get(id) ?? {borderColor: EquipmentDefaultColor};
            },
            annotations: [],
        });

        this.drawWindPositionDiagram(context, perWindPosition);
    }

    private drawWindPositionDiagram({page, pageFrameOffsets}: PdfReportContext, perWindPosition: DefaultMap<TrackerWindPosition, IdBimScene[]>){ 
        const totalArraysCount = this.bim.instances.peekByTypeIdents(SolarArraysTypes).length;

        if(perWindPosition.size === 0){
            return;
        }

        const pageWidth = page.internal.pageSize.getWidth();
        const leftOffset = Math.ceil(pageWidth - convertPxToMm(pageFrameOffsets.leftRightOffsetPx + this.maxImageSizePx));
        const headerBarFontSize = 10;
        const valueBarFontSize = 13;
        const upOffset = convertPxToMm(this.maxImageSizePx + this.headerOffsetPx + pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx + headerBarFontSize);
        const maxWidth = 80;

        let startOffset = Math.ceil(upOffset);
        const maxLocalCount = IterUtils.maxBy(perWindPosition.values(), v => v.length)?.length ?? 0;
        for (const [windPosition, color] of windPositionPerColor) {
            const items = perWindPosition.get(windPosition);
            if(!items || items.length === 0){
                continue;
            }
            const x = leftOffset;
            const y = startOffset;
            setTextStyle(page, "bold", headerBarFontSize);
            const displayName = getDisplayNameOfWindPosition(windPosition);
            page.text(displayName, x, y - convertPxToMm(5));

            setTextStyle(page, "normal", valueBarFontSize);
            const ratio = items.length / totalArraysCount;
            const unit = "%";
            const percentStr = (ratio * 100).toFixed(2);
            const unitOffsetX = pageWidth - convertPxToMm(pageFrameOffsets.leftRightOffsetPx) - 12;
            const valueOffsetX = convertPxToMm(9);
            page.text(percentStr, unitOffsetX - page.getTextWidth(percentStr) - valueOffsetX, y + convertPxToMm(this.widthBarPx / 2));
            page.text(unit, unitOffsetX, y + convertPxToMm(this.widthBarPx / 2));

            const [r, g, b] = RGBA.toRgbArray(color);
            page.setFillColor(r, g, b);
            const barLength = maxLocalCount ? items.length / maxLocalCount * maxWidth : 0;
            if(!barLength){
                continue;
            }

            page.rect(
                x,
                y,
                barLength,
                convertPxToMm(this.widthBarPx),
                "F",
            );

            startOffset += convertPxToMm(this.offsetBetweenBarsPx + this.widthBarPx);
        }
    }
}

export function extractWindPosition(inst: SceneInstance){
    let windPosition: string | undefined = undefined;
    if(inst.props instanceof AnyTrackerProps){
        windPosition = inst.props.position.wind_load_position?.value;
    } else if(SolarArraysTypes.includes(inst.type_identifier)){
        windPosition = inst.properties.get("position | load_wind_position")?.asText();
    } else {
        console.error(`Failed to extract wind position for ${inst.type_identifier}`, inst);
    }
    return windPosition as TrackerWindPosition | undefined;
}

function extractModulesCount(inst: SceneInstance){
    let modulesCount: number | undefined = undefined;
    if(inst.props instanceof AnyTrackerProps){
        const stringModulesCount = inst.props.tracker_frame.string.modules_count.value;
        modulesCount = inst.props.tracker_frame.dimensions.strings_count.value * stringModulesCount;
    } else if(inst.type_identifier === TrackerTypeIdent) {
        const countX = inst.properties.get("tracker-frame | modules | modules_count_x")?.asNumber() ?? 0;
        const countY = inst.properties.get("tracker-frame | modules | modules_count_y")?.asNumber() ?? 0;
        modulesCount = countX * countY;
    } else if(inst.type_identifier === FixedTiltTypeIdent){
        modulesCount = inst.properties.get("modules | count")?.asNumber();
    } else {
        console.error(`Failed to extract modules count for ${inst.type_identifier}`, inst);
    }
    return modulesCount;
}

interface SolarArraysByWindPositionTableHeader extends TableHeaderDescription<SolarArraysByWindPositionTableProps> {}

interface SolarArraysByWindPositionTableProps extends RowBaseType {
    name: StringProperty;
    modules: NumberProperty | null;
    capacity: NumberProperty;
    capacityShare: NumberProperty;
    piles: NumberProperty;
    pilesShare: NumberProperty;
    pilesLength: NumberProperty;
    pilesLengthShare: NumberProperty;
    pilesWeight: NumberProperty;
    pilesWeightShare: NumberProperty;
}

export class SolarArraysByWindPositionTable extends PdfElement<PileTableContext> {
    private readonly upOffsetPx = 15;


    constructor(
        readonly bim: Bim,
        readonly catalog: Catalog,
        readonly pilesCollection: TrackerPilesCollection,
        readonly logger: ScopedLogger,
    ){
        super();
    }

    *draw(context: PdfReportContext<PileTableContext>): Generator<Yield, void, unknown> {
        yield Yield.Asap;
        this.drawTables(context);
    }

    private drawTables({page, pageFrameOffsets, context}: PdfReportContext<PileTableContext>){
        const startXPosPx = pageFrameOffsets.leftRightOffsetPx;
        const upOffsetPx = pageFrameOffsets.upOffsetPx + pageFrameOffsets.topContentOffsetPx + this.upOffsetPx;

        const offsetYpxAfterMainTable = this.drawSolarArraysByWindPositionTable(page, context.windPositionSolarArrayTable, startXPosPx, upOffsetPx);
        const pileByCrossSectionOffsets = this.drawPileByCrossSectionTable(
            page,
            context.pilesCrossSectionRows,
            startXPosPx,
            offsetYpxAfterMainTable,
        );
        const offsetBetweenTablesPx = 32;
        const offsetAfterPilesTable = this.drawPilesByTypeTable(
            page,
            context.pilesByTypeRowsTable,
            context.pileFeaturesIcons,
            startXPosPx + pileByCrossSectionOffsets.tableWidthPx + offsetBetweenTablesPx, 
            offsetYpxAfterMainTable, 
        );

        return Math.max(offsetYpxAfterMainTable, offsetAfterPilesTable, pileByCrossSectionOffsets.offsetYPx);
    }

    
    calculateContentSize(pageSize: Vector2, context: Readonly<PdfReportContext<PileTableContext>>) {
        const page = new jsPDF({unit: 'mm'});
        addLatoFonts(page);
        const offsetYPx = this.drawTables({...context, page});
        return Aabb2.empty().setFromPoints([
            new Vector2(0, 0),
            new Vector2(pageSize.x, convertPxToMm(offsetYPx + context.pageFrameOffsets.downOffsetPx + 10))
        ]);
    }

    private drawSolarArraysByWindPositionTable(
        page: jsPDF,
        solarArraysByWindPosRows: [SolarArraysByWindPositionTableProps, SolarArraysByWindPositionTableProps[]][],
        startXPosPx: number, 
        upOffsetPx: number, 
    ){
        const table = new TableBuilder<SolarArraysByWindPositionTableHeader, SolarArraysByWindPositionTableProps>(
            page, 
            this.bim.unitsMapper, 
            startXPosPx, 
            upOffsetPx
        );
        table.addTitle("Solar arrays by wind position");

        const configuredLengthUnit = this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'});
        const configuredWeightUnit = this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'kg'});
        const headers: SolarArraysByWindPositionTableHeader[] = [ 
            {description: "", key: "name", widthPx: 195, align: 'left'},
            {description: "Modules", key: "modules", widthPx: 48},
            {description: "Capacity", key: "capacity", widthPx: 88, unit: "MW, DC", convertUnit: "MW"},
            {description: "/ Share", key: "capacityShare", widthPx: 47, unit: "%", convertUnit: "%"},
            {description: "Piles", key: "piles", widthPx: 88},
            {description: "/ Share", key: "pilesShare", widthPx: 47, unit: "%", convertUnit: "%"},
            {description: "Piles length", key: "pilesLength", widthPx: 88, unit: `${configuredLengthUnit.unit}, total`, convertUnit: configuredLengthUnit.unit},
            {description: "/ Share", key: "pilesLengthShare", widthPx: 47, unit: "%", convertUnit: "%"},
            {description: "Piles weight", key: "pilesWeight", widthPx: 88, unit: `${configuredWeightUnit.unit}, total`, convertUnit: configuredWeightUnit.unit},
            {description: "/ Share", key: "pilesWeightShare", widthPx: 47, unit: "%", convertUnit: "%"},
        ];
        table.addHeader(headers);

        for (const [group, rows] of solarArraysByWindPosRows){
            table.addRow(group, true);
            for (const row of rows) {
                table.addRow(row);
            }
        }
        const offsetBetweenTablesPx = 30;
        const offsetYPx = table.offsetYPx + offsetBetweenTablesPx;

        return offsetYPx;
    }

    private drawPileByCrossSectionTable(
        page: jsPDF,
        crossSectionRows: PilesCrossSectionRowProps[],
        startXPosPx: number, 
        offsetYPx: number, 
    ){
        const table = new TableBuilder<PilesCrossSectionTableHeader, PilesCrossSectionRowProps>(
            page,
            this.bim.unitsMapper,
            startXPosPx,
            offsetYPx
        );

        table.addTitle("Piles by cross-section");

        const headers: PilesCrossSectionTableHeader[] = [
            {description: "Cross-section", key: "crossSection", widthPx: 55, align: 'left'},
            {description: "Piles", key: "piles", widthPx: 47},
            {description: "/ Share", key: "pilesShare", widthPx: 47, unit: "%", convertUnit: "%"},
        ];
        table.addHeader(headers);

        for (const row of crossSectionRows){
            table.addRow(row);
        }

        return {
            offsetYPx: table.offsetYPx,
            tableWidthPx: table.tableWidthPx,
        }
    }

private drawPilesByTypeTable(
        page: jsPDF,
        pilesByTypeRowsTable: [PilesByTypeRowProps, PilesByTypeRowProps[]][],
        pileFeaturesIcons: Map<PileFeaturesFlags, string>,
        startXPosPx: number, 
        offsetYPx: number, 
    ){
        const table = new TableBuilder<PilesByTypeTableHeader, PilesByTypeRowProps>(
            page,
            this.bim.unitsMapper,
            startXPosPx,
            offsetYPx,
            pileFeaturesIcons
        );
        table.addTitle("Piles schedule by type");
        const configuredLengthUnit = this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'm'});
        const configuredWeightUnit = this.bim.unitsMapper.mapToConfigured({value: 1, unit: 'kg'});
        const headers: PilesByTypeTableHeader[] = [
            {description: "", key: "type", widthPx: 197, align: 'left', icon: 'pileFeatures'},
            {description: "Piles", key: "piles", widthPx: 88},
            {description: "/ Share", key: "pilesShare", widthPx: 47, unit: "%", convertUnit: "%"},
            {description: "Piles length", key: "pilesLength", widthPx: 88, unit: `${configuredLengthUnit.unit}, total`, convertUnit: configuredLengthUnit.unit},
            {description: "/ Share", key: "pilesLengthShare", widthPx: 47, unit: "%", convertUnit: "%"},
            {description: "Piles weight", key: "pilesWeight", widthPx: 88, unit: `${configuredWeightUnit.unit}`, convertUnit: configuredWeightUnit.unit},
            {description: "/ Share", key: "pilesWeightShare", widthPx: 47, unit: "%", convertUnit: "%"},
        ];
        table.addHeader(headers);

        for (const [groupRow, rows] of pilesByTypeRowsTable){
            table.addRow(groupRow, true);
            for (const row of rows) {
                table.addRow(row);
            }
        }

        return table.offsetYPx;
    }
}
export interface PileTableContext {
    windPositionSolarArrayTable: [SolarArraysByWindPositionTableProps, SolarArraysByWindPositionTableProps[]][];
    pilesCrossSectionRows: PilesCrossSectionRowProps[],
    pilesByTypeRowsTable: [PilesByTypeRowProps, PilesByTypeRowProps[]][],
    pileFeaturesIcons: Map<PileFeaturesFlags, string>,
}

export function* createPileTableContext(bim: Bim, catalog: Catalog, pilesCollection: TrackerPilesCollection, logger: ScopedLogger): Generator<Yield, PileTableContext, unknown> {
    const windPositionSolarArrayTable = createSolarArraysByWindPositionRows(bim, catalog, pilesCollection, logger);
    const pilesCrossSectionRows = createPilesCrossSectionRows(pilesCollection);
    const pilesByTypeRowsTable = createPilesByTypeRows(bim, pilesCollection);

    const pileFeaturesIcons = new Map<number, string>();
    for (const [group, rows] of pilesByTypeRowsTable) {
        const features = group.pileFeatures;
        if(features){
            const iconData = yield* convertPileIconToPng(features.value);
            if(!(iconData instanceof Success)){
                logger.error(`Failed to convert pile icon to png`, iconData.errorMsg());
                continue;
            }
            pileFeaturesIcons.set(features.value, iconData.value);
        }
        for (const row of rows) {
            if(!row.pileFeatures){
                continue;
            }
            const iconData = yield* convertPileIconToPng(row.pileFeatures.value);
            if(!(iconData instanceof Success)){
                logger.error(`Failed to convert pile icon to png`, iconData.errorMsg());
                continue;
            }
            pileFeaturesIcons.set(row.pileFeatures.value, iconData.value);
        }
    }

    return {
        windPositionSolarArrayTable,
        pilesCrossSectionRows,
        pilesByTypeRowsTable,
        pileFeaturesIcons,
    };
}

function createSolarArraysByWindPositionRows(
    bim: Bim, 
    catalog: Catalog, 
    pilesCollection: TrackerPilesCollection, 
    logger: ScopedLogger
): [SolarArraysByWindPositionTableProps, SolarArraysByWindPositionTableProps[]][] {
    const solarArraysPerPositions = new DefaultMap<string, DefaultMap<TrackerWindPosition, IdBimScene[]>>(() => new DefaultMap(() => []));
    const solarArrays = bim.instances.peekByTypeIdents(SolarArraysTypes);
    for (const [id, inst] of solarArrays) {
        const solarArrayName = catalog.keyPropertiesGroupFormatters.format(inst.type_identifier, inst.properties, inst.props);
        if(solarArrayName === null){
            logger.error(`Failed to format solar array name for ${inst.type_identifier}`, inst);
            continue;
        }
        const windPosition = extractWindPosition(inst);
        if(!windPosition){
            logger.error(`Failed to extract wind position for ${inst.type_identifier}`, inst);
            continue;
        }
        const map = solarArraysPerPositions.getOrCreate(solarArrayName);
        map.getOrCreate(windPosition).push(id);
    }
    const sortedNames = Array.from(solarArraysPerPositions.keys()).sort();
    const piles = pilesCollection.poll();
    const trackersPerWindPosition: [SolarArraysByWindPositionTableProps, SolarArraysByWindPositionTableProps[]][] = [];
    let totalPilesCount = 0;
    let totalPilesLengthMeter = 0;
    let totalPileWeightKg = 0;
    let totalCapacityKW = 0;
    for (const name of sortedNames) {
        const group = solarArraysPerPositions.get(name);
        if(!group){
            continue;
        }
        const trackerGroupRows: SolarArraysByWindPositionTableProps[] = [];
        let groupPilesCount = 0;
        let groupPilesLengthMeter = 0;
        let groupPileWeightKg = 0;
        let groupCapacityKW = 0;
        let groupModulesCount = 0;
        for (const [windPosition] of windPositionPerColor) {
            const ids = group.get(windPosition) ?? [];
            let pilesCount = 0;
            let pilesLengthMeter = 0;
            let pileWeightKg = 0;
            let capacityKW = 0;
            const instances = bim.instances.peekByIds(ids);
            for (const [id, inst] of instances) {
                groupModulesCount += extractModulesCount(inst) ?? 0;
                capacityKW += inst.properties.get("circuit | aggregated_capacity | dc_power")?.as("kW") ?? 0;
                for (const pileId of pilesCollection.pilesPerTrackerId.iter(id)) {
                    const pile = piles.get(pileId);
                    if(!pile){
                        continue;
                    }
                    pilesCount++;
                    pilesLengthMeter += pile.length;
                    const weight = pile.getWeight();
                    pileWeightKg += convertThrow(weight.value, weight.unit, 'kg');
                }
            }
            trackerGroupRows.push({
                name: StringProperty.new({value: getDisplayNameOfWindPosition(windPosition)}),
                piles: NumberProperty.new({value: pilesCount}),
                pilesLength: NumberProperty.new({value: pilesLengthMeter, unit: 'm'}),
                pilesWeight: NumberProperty.new({value: pileWeightKg, unit: 'kg'}),
                capacity: NumberProperty.new({value: capacityKW, unit: 'kW'}),
                capacityShare: NumberProperty.new({value: 0}),
                pilesShare: NumberProperty.new({value: 0}),
                pilesLengthShare: NumberProperty.new({value: 0}),
                pilesWeightShare: NumberProperty.new({value: 0}),
                modules: null,
            });
            groupPilesCount += pilesCount;
            groupPilesLengthMeter += pilesLengthMeter;
            groupPileWeightKg += pileWeightKg;
            groupCapacityKW += capacityKW;
        }
        const groupRow: SolarArraysByWindPositionTableProps = { 
            name: StringProperty.new({value: name}),
            piles: NumberProperty.new({value: groupPilesCount}),
            pilesLength: NumberProperty.new({value: groupPilesLengthMeter, unit: 'm'}),
            pilesWeight: NumberProperty.new({value: groupPileWeightKg, unit: 'kg'}),
            capacity: NumberProperty.new({value: groupCapacityKW, unit: 'kW'}),
            modules: NumberProperty.new({value: groupModulesCount}),
            capacityShare: NumberProperty.new({value: 0}),
            pilesShare: NumberProperty.new({value: 0}),
            pilesLengthShare: NumberProperty.new({value: 0}),
            pilesWeightShare: NumberProperty.new({value: 0}),
        }
        totalPilesCount += groupPilesCount;
        totalPilesLengthMeter += groupPilesLengthMeter;
        totalPileWeightKg += groupPileWeightKg;
        totalCapacityKW += groupCapacityKW;
        trackersPerWindPosition.push([groupRow, trackerGroupRows]);
    }
    function calculateShares(rows: SolarArraysByWindPositionTableProps){
        rows.capacityShare = NumberProperty.new({ value: calcPercent(rows.capacity.as("kW"), totalCapacityKW), unit: '%'});
        rows.pilesShare = NumberProperty.new({ value: calcPercent(rows.piles.value, totalPilesCount), unit: '%'});
        rows.pilesLengthShare = NumberProperty.new({ value: calcPercent(rows.pilesLength.as("m"), totalPilesLengthMeter), unit: '%'});
        rows.pilesWeightShare = NumberProperty.new({ value: calcPercent(rows.pilesWeight.as("kg"), totalPileWeightKg), unit: '%'});
    }
    for (const [trackerGroup, windPositions] of trackersPerWindPosition) {
        calculateShares(trackerGroup);
        for (const windPosition of windPositions) {
            calculateShares(windPosition);
        }
    }

    return trackersPerWindPosition;
}

function calcPercent(value: number, total: number) { 
    return value && total ? Math.round(value / total * 100_00) / 100 : 0; 
}

interface PilesCrossSectionTableHeader extends TableHeaderDescription<PilesCrossSectionRowProps> {
    key: keyof PilesCrossSectionRowProps;
}

interface PilesCrossSectionRowProps extends RowBaseType {
    crossSection: StringProperty;
    piles: NumberProperty;
    pilesShare: NumberProperty;
}

function createPilesCrossSectionRows(pilesCollection: TrackerPilesCollection){
    const piles = pilesCollection.poll();
    const crossSections = new DefaultMap<
        string,
        {
            sortKey: number;
            piles: number;
        }
    >(() => ({
        sortKey: 0,
        piles: 0,
    }));
    for (const [_, pile] of piles) {
        let crossSection = pile.getCrossSectionStr();
        const sortKey = pile.shape;
        if(!crossSection || !pile.shape){
            crossSection = "N/A"
        }
        const row = crossSections.getOrCreate(crossSection);
        row.sortKey = Math.max(row.sortKey, sortKey);
        row.piles++;
    }
    const rows: PilesCrossSectionRowProps[] = [];
    const sortedCrossSections = Array.from(crossSections).sort((a, b) => a[1].sortKey - b[1].sortKey);
    for (const [crossSection, props] of sortedCrossSections) {
        rows.push({
            crossSection: StringProperty.new({value: crossSection}),
            piles: NumberProperty.new({value: props.piles}),
            pilesShare: NumberProperty.new({value: calcPercent(props.piles, piles.size), unit: '%'}),
        })
    }

    return rows;
}

interface PilesByTypeTableHeader extends TableHeaderDescription<PilesByTypeRowProps> {
    key: keyof PilesByTypeRowProps;
}

interface PilesByTypeRowProps extends RowBaseType {
    type: StringProperty;
    pileFeatures?: NumberProperty;
    piles: NumberProperty;
    pilesShare: NumberProperty;
    pilesLength: NumberProperty;
    pilesLengthShare: NumberProperty;
    pilesWeight: NumberProperty;
    pilesWeightShare: NumberProperty;
}

interface GroupedPilesByTypeProps { 
    weightClass: PileWeightClass;
    pileFeatures: PileFeaturesFlags;
    sortKey: number;
    piles: number;
    lengthMeter: number,
    weightKg: number,
}

function createPilesByTypeRows(bim: Bim, pilesCollection: TrackerPilesCollection):[PilesByTypeRowProps, PilesByTypeRowProps[]][]{
    const piles = pilesCollection.poll();
    const pilesTypes = new DefaultMap<string, GroupedPilesByTypeProps>(() => ({
        pileFeatures: 0,
        weightClass: 0,
        sortKey: 0,
        piles: 0,
        lengthMeter: 0,
        weightKg: 0,
    }));
    const binConfig = bim.configs.peekSingleton(PileBinsConfig.name)?.propsAs(PileBinsConfig);
    if(!binConfig){
        return [];
    }
    const trackerBinsMap = new DefaultMap<string, TrackerBins>((solarArrayType) => {
        const bins = binConfig.trackerBins.find(b => b.tracker.value === solarArrayType) 
            ?? TrackerBins.createDefaultTrackerBins(solarArrayType);
        return bins;
    });

    function getOrCreateTrackerBin(pile: TrackerPile){
        const solarArray = bim.instances.peekById(pile.parentId);
        const solarArrayType = solarArray ? getTrackerModel(solarArray) : undefined;
        if(!solarArrayType){
            return null;
        }
        const trackerBin = trackerBinsMap.getOrCreate(solarArrayType);
        return trackerBin;
    }
    let totalPilesCount = 0;
    let totalLengthMeter = 0;
    let totalWeightKg = 0;
    for (const [pileId, pile] of piles) {
        const bin = getOrCreateTrackerBin(pile);
        if(!bin){
            console.error(`Failed to get bin for pile ${pileId}`, pile);
            continue;
        }
        const pileFullName = pile.getPileFullName(bin);
        const sortKey = pile.features;
 
        const row = pilesTypes.getOrCreate(pileFullName);
        row.sortKey = Math.max(row.sortKey, sortKey);
        row.piles++;
        row.lengthMeter += pile.length;
        const weight = pile.getWeight();
        row.weightKg = convertThrow(weight.value, weight.unit, 'kg');
        row.weightClass = pile.getWeightClass();
        row.pileFeatures = pile.features;

        totalPilesCount++;
        totalLengthMeter += pile.length;
        totalWeightKg += row.weightKg;
    }
    const groupedPilesRows: [PilesByTypeRowProps, PilesByTypeRowProps[]][] = [];
    const perWeightClass = IterUtils.groupBy(pilesTypes, x => x[1].weightClass);
    const sortedPerWeightClass = Array.from(perWeightClass).sort((a, b) => a[0] - b[0]);

    for (const [weightClass, pilesGroupsPerFullName] of sortedPerWeightClass) {
        const groupPileCount = IterUtils.sum(pilesGroupsPerFullName, x => x[1].piles);
        const groupPileLength = IterUtils.sum(pilesGroupsPerFullName, x => x[1].lengthMeter);
        const groupPileWeight = IterUtils.sum(pilesGroupsPerFullName, x => x[1].weightKg);
        const groupRow: PilesByTypeRowProps = {
            type: StringProperty.new({value: PileWeightClass[weightClass]}),
            piles: NumberProperty.new({value: groupPileCount}),
            pilesShare: NumberProperty.new({value: calcPercent(groupPileCount, totalPilesCount), unit: '%'}),
            pilesLength: NumberProperty.new({value: groupPileLength, unit: 'm'}),
            pilesLengthShare: NumberProperty.new({value: calcPercent(groupPileLength, totalLengthMeter), unit: '%'}),
            pilesWeight: NumberProperty.new({value: groupPileWeight, unit: 'kg'}),
            pilesWeightShare: NumberProperty.new({value: calcPercent(groupPileWeight, totalWeightKg), unit: '%'}),
        }
        const rows: PilesByTypeRowProps[] = [];
        pilesGroupsPerFullName.sort((a, b) => a[1].sortKey - b[1].sortKey);
        for (const [fullName, props] of pilesGroupsPerFullName) {
            const row: PilesByTypeRowProps = { 
                type: StringProperty.new({value: fullName}),
                pileFeatures: NumberProperty.new({value: props.pileFeatures}),
                piles: NumberProperty.new({value: props.piles}),
                pilesShare: NumberProperty.new({value: calcPercent(props.piles, totalPilesCount), unit: '%'}),
                pilesLength: NumberProperty.new({value: props.lengthMeter, unit: 'm'}),
                pilesLengthShare: NumberProperty.new({value: calcPercent(props.lengthMeter, totalLengthMeter), unit: '%'}),
                pilesWeight: NumberProperty.new({value: props.weightKg, unit: 'kg'}),
                pilesWeightShare: NumberProperty.new({value: calcPercent(props.weightKg, totalWeightKg), unit: '%'}),
            };
            rows.push(row);
        }
        groupedPilesRows.push([groupRow, rows]);
    }

    return groupedPilesRows;
}

function* convertPileIconToPng(pileFeatures: PileFeaturesFlags){
    const flags = unpackPileFeatures(pileFeatures);
    const svg = getPileTypeIcon(
        flags.weight_class,
        flags.motor,
        flags.damper,
        flags.modifier,
        "#0F1314B2",
    );
    const dataPromise = convertSvgToDataURL(svg);
    const imageData = yield* PollablePromise.generatorWaitFor(dataPromise); 

    return imageData;
}