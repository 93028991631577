import type {  LazyVersioned} from "engine-utils-ts";
import { DefaultMapObjectKey, IterUtils, LazyDerivedAsync,  ObjectUtils,  Yield, replaceCurrencyUnitWithSymbol } from "engine-utils-ts";
import type { Bim, IdBimScene} from "src";
import { BimProperty, FixedTiltTypeIdent, NumberProperty, SceneObjDiff, TrackerTypeIdent } from "src";
import { TrackerFrameFormatterPropsGroup, TrackerFrameTypeIdentifier, getLoadWindPositionCostMultiplier } from "src/archetypes/TrackerFrame";
import { extractValueUnitPropsGroup, type NamedBimPropertiesGroup } from "src/bimDescriptions/NamedBimPropertiesGroup";
import { extractIntoNamedPropsGroup } from "src/bimDescriptions/PropertiesGroupFormatter";
import type { CostComponents, CostComponentsNonNullable, CostsConfigProvider, EstimateCost, InstanceCost, SingleEstimateProvider} from "src/cost-model/capital";
import { CostHierarchy, CostSourceType, createEmptyCostComponents, createEmptyCostComponentsNonNullable, createMiscCategory, fillModelBasedCostCategory, fillModelBasedTopLevelCategory } from "src/cost-model/capital";
import type { MatchesCostCategory } from 'src/cost-model/capital';

export function create_Racking(
    bim: Bim,
    provider: CostsConfigProvider,
    totalDC: LazyVersioned<NumberProperty>,
) {
    const grouped = createGroupedFrames(bim);

    const frameCosts = provider.lazyInstanceCostsByType(TrackerFrameTypeIdentifier);

    const result = LazyDerivedAsync.new4<
        CostHierarchy,
        UniqueTrackerFrameGroup[],
        EstimateCost[],
        InstanceCost[],
        NumberProperty
    >(
        'racking',
        [bim.unitsMapper],
        [
            grouped,
            provider.allEstimateCosts,
            frameCosts,
            totalDC,
        ],
        function* ([grouped, estimates, frameCosts, totalDC]) {
            const costUnit = bim.unitsMapper.mapToConfigured({ value: 0, unit: 'usd' }).unit!;

            // extract final result
            const hierarchy = new CostHierarchy();

            for (const isUndulated of IterUtils.groupBy(grouped, x => x.uniqueProps.undulated.asBoolean())) {
                const isUndulatedHierarchy = new CostHierarchy();

                for (const manufacturerGroup of IterUtils.groupBy(isUndulated[1], x => x.uniqueProps.manufacturer.asText())) {
                    const manufacturerHierarchy = new CostHierarchy();

                    for (const modelGroup of IterUtils.groupBy(manufacturerGroup[1], x => x.uniqueProps.model.asText())) {
                        const modelHierarchy = new CostHierarchy();

                        for (const moduleCountGroup of IterUtils.groupBy(modelGroup[1], x => x.uniqueProps.moduleCountX.asNumber())) {
                            const moduleCountHierarchy = new CostHierarchy();

                            for (const positionGroup of IterUtils.groupBy(moduleCountGroup[1], x => x.uniqueProps.loadWindPosition.asText())) {
                                const sample = positionGroup[1][0];
                                const defaults = createSolarArrayFrameDefaultCostPerEach(sample.uniqueProps);

                                const totalIds = new Set<IdBimScene>();
                                positionGroup[1].forEach(x => x.arrayIds.forEach(y => totalIds.add(y)));
                                const uniqueProps = extractValueUnitPropsGroup(positionGroup[1][0].uniqueProps);

                                const matchesSceneInstance: MatchesCostCategory = (query) => {
                                    if (query.type_identifier !== TrackerFrameTypeIdentifier) {
                                        return false;
                                    }
                                    let trackerFrameProps: typeof TrackerFrameUniqueProps;
                                    if (query.si?.type_identifier === TrackerTypeIdent) {
                                        trackerFrameProps = query.si.properties.extractPropertiesGroup(TrackerFrameUniqueProps);
                                    } else if (query.si?.type_identifier === FixedTiltTypeIdent) {
                                        const fixedTiltProps = query.si.properties.extractPropertiesGroup(FixedTiltFrameUniqueProps);
                                        trackerFrameProps = fixedTiltToTrackerFrameProps(fixedTiltProps);
                                    } else if (query.si?.type_identifier === 'any-tracker') {
                                        const props = extractIntoNamedPropsGroup(AnyTrackerFrameUniqueProps, query.si.properties, query.si.props);
                                        trackerFrameProps = anyTrackerToTrackerFrameProps(props)
                                    } else {
                                        return false;
                                    }
                                    trackerFrameProps = extractValueUnitPropsGroup(trackerFrameProps);
                                    const result = ObjectUtils.areObjectsEqual(trackerFrameProps, uniqueProps);
                                    return result;
                                }


                                // each
                                {
                                    const overrides = frameCosts.find(x =>
                                        ObjectUtils.areObjectsEqual(x.props, uniqueProps)
                                    )?.costs ?? createEmptyCostComponents();
                                    const totalCount = IterUtils.sum(positionGroup[1], x => x.frameCount);
                                    const category = moduleCountHierarchy.add({
                                        description: { value: uniqueProps.loadWindPosition.asText() },
                                        relatedSceneInstanceIds: totalIds,
                                        costUnit: {
                                            options: [replaceCurrencyUnitWithSymbol(costUnit) + '/each' ],
                                            index: 0,
                                        },
                                        quantity: {
                                            value: NumberProperty.new({ value: totalCount }),
                                            integer: true,
                                        },
                                        matchesSceneInstance,
                                    })[1];


                                    const updateCosts = (newCosts: CostComponents) => {
                                        provider.findAndUpdateInstanceCost(
                                            (prev) => (prev.costs = newCosts, prev),
                                            { instance_type: TrackerFrameTypeIdentifier, props: uniqueProps }
                                        )
                                    }

                                    fillModelBasedCostCategory(category, overrides, defaults, updateCosts, totalCount);
                                }
                            }
                            const category = moduleCountHierarchy.addRoot({ description: { value: moduleCountGroup[0].toString() + ' MOD' } });
                            moduleCountHierarchy.sumupChildren(category[0]);
                            modelHierarchy.merge(moduleCountHierarchy);
                        }
                        const category = modelHierarchy.addRoot({ description: { value: modelGroup[0].toString() } });
                        modelHierarchy.sumupChildren(category[0]);
                        manufacturerHierarchy.merge(modelHierarchy);
                    }
                    const categoryEach = manufacturerHierarchy.addRoot({ description: { value: manufacturerGroup[0] } })
                    manufacturerHierarchy.sumupChildren(categoryEach[0]);
                    isUndulatedHierarchy.merge(manufacturerHierarchy);
                }
                const groupTitle = isUndulated[0] ? 'Undulated' : 'Rigid';
                const categoryEach = isUndulatedHierarchy.addRoot({ description: { value: groupTitle } })
                isUndulatedHierarchy.sumupChildren(categoryEach[0]);
                hierarchy.merge(isUndulatedHierarchy);
            }

            // create misc cable misc each
            {
                const id = 'racking-misc';
                const state: SingleEstimateProvider = {
                    id: id,
                    defaults: createEmptyCostComponentsNonNullable(),
                    value: estimates.find(x => x.id.value === id),
                    update: (fn) => provider.findAndUpdateEstimateCost(id, fn),
                }
                hierarchy.add(createMiscCategory(state, bim.unitsMapper, totalDC, { defaultCostSource: CostSourceType.BenchmarkLumpSum }))
            }

            // create racking root category
            {
                const root = hierarchy.addRoot({ description: { value: RackingCategoryName } });
                const defaults = createEmptyCostComponentsNonNullable();
                defaults.materialCost = NumberProperty.new({ value: 0.09 });
                fillModelBasedTopLevelCategory(
                    root[0],
                    hierarchy,
                    'racking-benchmark',
                    estimates,
                    provider.findAndUpdateEstimateCost,
                    defaults,
                    bim.unitsMapper,
                    totalDC,
                );
            }

            return hierarchy;
        }
    )
    return result
}

export const RackingCategoryName = 'Racking'

function trackerFramePropsHash(props: typeof TrackerFrameUniqueProps) {
    return [
        props.manufacturer.asText(),
        props.model.asText(),
        props.moduleCountX.asNumber(),
        props.loadWindPosition.value ?? '',
        props.undulated.asBoolean() ? 'undulated' : 'rigid',
    ].join('/')
}

export function createGroupedFrames(bim: Bim) {
    const trackers = bim.instances.getLazyListOfTypes({
        type_identifiers: [TrackerTypeIdent],
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });
    const fixedTilts = bim.instances.getLazyListOfTypes({
        type_identifiers: [FixedTiltTypeIdent],
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });
    const anyTracker = bim.instances.getLazyListOfTypes({
        type_identifiers: ['any-tracker'],
        relevantUpdateFlags: SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
    });
    const result = LazyDerivedAsync.new3(
        'groupedFrames',
        [],
        [trackers, fixedTilts, anyTracker],
        function* ([trackers, fixedTilts, anyTracker]) {
            const uniqueFixedTiltGroups = new DefaultMapObjectKey<
                typeof TrackerFrameUniqueProps,
                { frameCount: number, arrayIds: Set<IdBimScene>, title: string }
            >({
                unique_hash: trackerFramePropsHash,
                valuesFactory: () => ({ frameCount: 0, arrayIds: new Set(), title: '' })
            })

            for (const chunk of IterUtils.splitIterIntoChunks(trackers, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.mapIter(IterUtils.groupBy(chunk, (o) => {
                    const props = o[1].properties.extractPropertiesGroup(TrackerFrameUniqueProps);
                    return trackerFramePropsHash(props)
                }), x => x[1]);
                for (const members of grouped) {
                    const [sample] = members;
                    const uniqueProps = sample[1].properties.extractPropertiesGroup(TrackerFrameUniqueProps, { valueUnitOnly: true })
                    const group = uniqueFixedTiltGroups.getOrCreate(Object.freeze(uniqueProps));
                    members.forEach(x => group.arrayIds.add(x[0]));
                    group.frameCount += members.length;
                }
            }

            for (const chunk of IterUtils.splitIterIntoChunks(fixedTilts, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.mapIter(IterUtils.groupBy(chunk, (o) => {
                    const props = o[1].properties.extractPropertiesGroup(FixedTiltFrameUniqueProps);
                    const frameProps = fixedTiltToTrackerFrameProps(props);
                    return trackerFramePropsHash(frameProps)
                }), x => x[1]);
                for (const members of grouped) {
                    const [sample] = members;
                    const uniqueProps = sample[1].properties.extractPropertiesGroup(FixedTiltFrameUniqueProps)
                    const frameUniqueProps = extractValueUnitPropsGroup(fixedTiltToTrackerFrameProps(uniqueProps));
                    const group = uniqueFixedTiltGroups.getOrCreate(Object.freeze(frameUniqueProps));
                    members.forEach(x => group.arrayIds.add(x[0]));
                    group.frameCount += members.length;
                }
            }

            for (const chunk of IterUtils.splitIterIntoChunks(anyTracker, 10e3)) {
                yield Yield.Asap;
                const grouped = IterUtils.mapIter(IterUtils.groupBy(chunk, (o) => {
                    const props = extractIntoNamedPropsGroup(AnyTrackerFrameUniqueProps, o[1].properties, o[1].props)
                    const frameProps = anyTrackerToTrackerFrameProps(props);
                    return trackerFramePropsHash(frameProps)
                }), x => x[1]);
                for (const members of grouped) {
                    const [sample] = members;
                    const uniqueProps = extractValueUnitPropsGroup(
                        extractIntoNamedPropsGroup(AnyTrackerFrameUniqueProps, sample[1].properties, sample[1].props)
                    );
                    const frameUniqueProps = extractValueUnitPropsGroup(anyTrackerToTrackerFrameProps(uniqueProps));
                    const group = uniqueFixedTiltGroups.getOrCreate(Object.freeze(frameUniqueProps));
                    members.forEach(x => group.arrayIds.add(x[0]));
                    group.frameCount += members.length;
                }
            }


            const result: UniqueTrackerFrameGroup[] = Array.from(uniqueFixedTiltGroups.entries()).map(([k, v]) => ({
                uniqueProps: k,
                ...v
            }))
            return result;
        }
    )
    return result;
}


export const TrackerFrameUniqueProps = {
    manufacturer: BimProperty.NewShared({
        path: ['tracker-frame', 'commercial', 'manufacturer'],
        value: 'unknown_manufacturer',
    }),
    model: BimProperty.NewShared({
        path: ['tracker-frame', 'commercial', 'model'],
        value: 'unknown_model',
    }),
    moduleCountX: BimProperty.NewShared({
        path: ['tracker-frame', 'modules', 'modules_count_x'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'load_wind_position'],
        value: 'none',
    }),
    undulated: BimProperty.NewShared({
        path: ['position', 'undulated'],
        value: false
    })

} satisfies NamedBimPropertiesGroup

export const FixedTiltFrameUniqueProps = {
    manufacturer: BimProperty.NewShared({
        path: ['commercial', 'manufacturer'],
        value: 'unknown_manufacturer',
    }),
    model: BimProperty.NewShared({
        path: ['commercial', 'model'],
        value: 'unknown_model',
    }),
    modulesCountX: BimProperty.NewShared({
        path: ['modules', 'count_x'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'load_wind_position'],
        value: 'none',
    }),
} satisfies NamedBimPropertiesGroup

export function fixedTiltToTrackerFrameProps(fixedTilt: typeof FixedTiltFrameUniqueProps): typeof TrackerFrameUniqueProps {
    return {
        loadWindPosition: TrackerFrameUniqueProps.loadWindPosition.withDifferentValue(fixedTilt.loadWindPosition.asText()),
        model: TrackerFrameUniqueProps.model.withDifferentValue(fixedTilt.model.asText()),
        manufacturer: TrackerFrameUniqueProps.manufacturer.withDifferentValue(fixedTilt.manufacturer.asText()),
        moduleCountX: TrackerFrameUniqueProps.moduleCountX.withDifferentValue(fixedTilt.modulesCountX.asNumber()),
        undulated: TrackerFrameUniqueProps.undulated.withDifferentValue(false),
    }
}

export const AnyTrackerFrameUniqueProps = {
    manufacturer: BimProperty.NewShared({
        path: ['tracker_frame', 'commercial', 'manufacturer'],
        value: 'unknown_manufacturer',
    }),
    model: BimProperty.NewShared({
        path: ['tracker_frame', 'commercial', 'model'],
        value: 'unknown_model',
    }),
    moduleCountX: BimProperty.NewShared({
        path: ['tracker_frame', 'modules', 'modules_count_x'],
        value: 0,
    }),
    loadWindPosition: BimProperty.NewShared({
        path: ['position', 'wind_load_position'],
        value: 'none',
    }),
    undulated: BimProperty.NewShared({
        path: ['position', 'undulated'],
        value: false
    }),
} satisfies NamedBimPropertiesGroup

export function anyTrackerToTrackerFrameProps(anyTracker: typeof AnyTrackerFrameUniqueProps): typeof TrackerFrameUniqueProps {
    return {
        loadWindPosition: TrackerFrameUniqueProps.loadWindPosition.withDifferentValue(anyTracker.loadWindPosition.asText()),
        model: TrackerFrameUniqueProps.model.withDifferentValue(anyTracker.model.asText()),
        manufacturer: TrackerFrameUniqueProps.manufacturer.withDifferentValue(anyTracker.manufacturer.asText()),
        moduleCountX: TrackerFrameUniqueProps.moduleCountX.withDifferentValue(anyTracker.moduleCountX.asNumber()),
        undulated: TrackerFrameUniqueProps.undulated.withDifferentValue(anyTracker.undulated.asBoolean()),
    }
}

type UniqueTrackerFrameGroupData = {
    frameCount: number,
    arrayIds: Set<IdBimScene>,
    title: string
}

interface UniqueTrackerFrameGroup extends UniqueTrackerFrameGroupData {
    uniqueProps: typeof TrackerFrameUniqueProps,
}

export function createSolarArrayFrameDefaultCostPerEach(props: typeof TrackerFrameUniqueProps) {
    const costMultiplier = getLoadWindPositionCostMultiplier(props.loadWindPosition.asText());
    const undulatedMultiplier = props.undulated.asBoolean() ? 1.15 : 1;
    const multiplier = costMultiplier * undulatedMultiplier;
    const costs: CostComponentsNonNullable = {
        materialCost: NumberProperty.new({ value: props.moduleCountX.value * 80 * multiplier, unit: 'usd' }),
        laborTimeUnits: NumberProperty.new({ value: 15 }),
        laborTimeUnitCost: NumberProperty.new({ value: 51, unit: 'usd' }),
        laborCost: NumberProperty.new({ value: 51 * 15, unit: 'usd' }),
        equipmentCost: NumberProperty.new({ value: 100, unit: 'usd' }),
        serviceCost: NumberProperty.new({ value: 0 }),
    }
    return costs
}

export const FixedTiltFrameRelatedProps = {
    model: BimProperty.NewShared({
        path: ['commercial', 'model'],
        value: 'unknown_model',
    }),
    stringCount: BimProperty.NewShared({
        path: ['dimensions', 'strings_count'],
        value: 0,
    }),
    stringModulesCount: BimProperty.NewShared({
        path: ['modules', 'count_x'],
        value: 0,
    }),
}

export function fixedTiltToTrackerFrameFormatterProps(input: typeof FixedTiltFrameRelatedProps): typeof TrackerFrameFormatterPropsGroup {
    const output: typeof TrackerFrameFormatterPropsGroup = {
        model: TrackerFrameFormatterPropsGroup.model.withDifferentValue(input.model.asText()),
        mountingStandard: TrackerFrameFormatterPropsGroup.mountingStandard.withDifferentValue(''),
        stringCount: TrackerFrameFormatterPropsGroup.stringCount.withDifferentValue(input.stringCount.asNumber()),
        stringModulesCount: TrackerFrameFormatterPropsGroup.stringModulesCount.withDifferentValue(input.stringModulesCount.asNumber())
    }
    return output;
}
