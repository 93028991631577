import { Failure, IterUtils, Success } from ".";
import type {Config, RangeTuple} from "quick-score";
import { quickScore, Range, DefaultConfig } from "quick-score";

export class StringUtils {
    static reverse(line: string) {
        return line.split("").reverse().join("");
    }

    static numberToSignedString(value: number, fractionDigits?:number) {
        if (fractionDigits != undefined) {
            return value > 0 ? `+${value.toFixed(fractionDigits)}` : value.toFixed(fractionDigits);
        } else {
            return value > 0 ? `+${value}` : `${value}`;
        }
    }

    static capitalizeFirstLatterInWord(line: string):string{
        const splitted = line.split(/[\_\s]/g);
        const firstWord = splitted[0].charAt(0).toUpperCase() + splitted[0].slice(1);
        const newLine:string[] = [firstWord];
        IterUtils.extendArray(newLine, splitted.slice(1));
        return newLine.join(' ');
    }

    static capitalizeFirstCharInEachWord(text: string): string {
        const bytes = text.split('');
        const matches = text.matchAll(/\w+/g)
        for (const match of matches) {
            const index = match.index;
            if (typeof index !== 'number' || index < 0) {
                continue
            }
            bytes[index] = bytes[index].toUpperCase();
        }
        return bytes.join('');
    }

    static roundedForUi(value: number) {
        if (isNaN(value)) {
            return '-/-';
        }
        if (Math.abs(value) < 0.0001) {
            return +(value.toFixed(6));
        }
        if (Math.abs(value) < 0.001) {
            return +(value.toFixed(5));
        }
        if (Math.abs(value) > 1000) {
            return +(value.toFixed(1));
        }
        if (Math.abs(value) > 100) {
            return +(value.toFixed(2));
        }
        if (Math.abs(value) > 10) {
            return +(value.toFixed(3));
        }
        return +(value.toFixed(4));
    }

    /**
     * 
     * @param str string containing named args, for instance: "object id is ${id}"
     * @param args named args, for instance: {id: 5}
     * @returns string with replaced named args
     */
    static formatStringWithArgs(
        str: string,
        args: { [key: string]: string | number | boolean | (number|string)[] }
    ): Success<string> {
        let unfoundArgs: string[] = [];
        const res = str.replace(/\${([^}]*)}/g, (match, key) => {
            let arg = args[key];
            if (arg === undefined) {
                unfoundArgs.push(key);
                return match;
            }
            if (Array.isArray(arg)) {
                arg = arg.join(', ');
            }
            return arg.toString();
        });
        return new Success(
            res,
            unfoundArgs.length === 0 ? undefined : unfoundArgs.map(k => new Failure({msg: `missing arg: ${k}`}))
        );
    }

    static tryParseAsNumberIfExact(str: string | undefined): number | string {
        if (str === undefined) {
            return '';
        }
        if (str.length === 0) {
            return str;
        }
    
        // The problem with parseFloat() is that it will return a number if the string contains any number, even if the string doesn't contain only and exactly a number:
        // parseFloat("2016-12-31")  // returns 2016
        // parseFloat("1-1") // return 1
        // parseFloat("1.2.3") // returns 1.2
    
        //The problem with Number() is that it will return a number in cases where the passed value is not a number at all!
        // Number("") // returns 0
        // Number(" ") // returns 0
        // Number(" \u00A0   \t\n\r") // returns 0
        const strTrimmed = str.trim();
    
        const asNumber = parseFloat(str);
        if (Number.isFinite(asNumber) && Number(strTrimmed) === asNumber) {
            return asNumber;
        }
        return strTrimmed
    }

    static fuzzyScore(
        string: string,
        query: string,
        matchesOuput?: RangeTuple[],
        transformedString: string = string.toLocaleLowerCase(),
        transformedQuery: string = query.toLocaleLowerCase(),
        config: Config = DefaultConfig,
        stringRange: Range = new Range(0, string.length)
    ) {
        return quickScore(string, query, matchesOuput, transformedString, transformedQuery, config, stringRange);
    }

    static chooseBestMatch(
        strings: string[],
        query: string,
        minimumScoreNormalized: number,
    ): string | undefined {
        let bestYet: string | undefined = undefined;
        let bestScoreYet = -1;
        for (const str of strings) {
            const score = quickScore(str, query);
            if (score >= minimumScoreNormalized && score > bestScoreYet) {
                bestYet = str;
                bestScoreYet = score;
            }
        }
        return bestYet;
    }

    static findCommonSubstring(strings: string[], fromIndex: number = 0) {
        let i = fromIndex;
        let char = "";
        let substring = "";
        while (true) {
            char = strings[0][i];
            for (const string of strings) {
                if (i >= string.length || string[i] !== char) {
                    return substring;
                }
            }
            substring += char;
            ++i;
        }
    }
}