import { Euler, Matrix4, Transform, Vector2, Vector3 } from "math-ts";
import type { Bim} from "..";
import { BimMaterialStdRenderParams, ExtrudedPolygonGeometry, PolylineGeometry, StdInstancedMeshesRepresentation, StdSubmeshRepresentation, TrackerPartType, trackersPartsCache } from "..";
import { BimProperty } from "../bimDescriptions/BimProperty";
import type { PropertiesPatch } from "../bimDescriptions/PropertiesCollection";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import { BimPropertiesFormatter, sceneInstanceHierarchyPropsRegistry } from "../catalog/SceneInstanceHierarchyPropsRegistry";
import type { AssetBasedCatalogItemCreators } from "../catalog/CatalogItemCollection";
import type { ReactiveSolverBase, SolverInstancePatchResult } from "../runtime/ReactiveSolverBase";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { SceneInstanceShapeMigration } from "../scene/SceneInstancesArhetypes";
import { registerEquipmentCommonAssetToCatalogItem } from "./EquipmentCommon";
import { LegacyLogger } from "engine-utils-ts";

export const TrackerFrameTypeIdentifier = 'tracker-frame';

export function getModulesRows(rows:string):[number[], number[]] {
    const parts = rows.split(/\s*(?:;|-|\s|,|$)\s*/);
    const modulesRows:number[] = [];
    let motorsPlace:number[] = [];
    let counter = 0;

    for (const part of parts) {
        const number = parseInt(part);
        if (!isNaN(number) && isFinite(number) && number >= 0) {
            counter += number;
            modulesRows.push(number);
        } else if (part.toUpperCase() === "M") {
            motorsPlace.push(counter);
        }
    }


    return [modulesRows, motorsPlace];
}



export function createTrackerFramePropsInsideTracker_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const prefixesToMove = [
        ['dimensions'],
        ['string'],
        ['commercial'],
    ];
    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [],
        },
        patch: (inst) => {
            const migratePropsPatch: PropertiesPatch = [];
            for (const prefixPath of prefixesToMove) {
                const props = inst.properties.getPropStartingWith(BimProperty.MergedPath(prefixPath));
                for (const prop of props) {
                    const newPath = ['tracker-frame', ...prop.path];
                    migratePropsPatch.push(
                        // remove old prop
                        [
                            BimProperty.MergedPath(prop.path),
                            null
                        ],
                        // create new prop
                        [
                            BimProperty.MergedPath(newPath),
                            {
                                ...prop,
                                path: newPath,
                            }
                        ]
                    )
                }
            }
            inst.properties.applyPatch(migratePropsPatch);
        }
    }
    
}
export function createTrackerFrameMountingProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const mountingStdPath = ['tracker-frame', 'module_mounting', 'standard'];
    const mountingWidthPath = ['tracker-frame', 'module_mounting', 'module_width'];
    return {
        toVersion,
        validation: {
            updatedProps: [
                { path: mountingStdPath },
                { path: mountingWidthPath },
            ],
            deletedProps: [],
        },
        patch: (inst) => {
            
            mountingWidth: {
                const mountingWidth =
                    inst.properties.get(BimProperty.MergedPath(mountingWidthPath));
                if (mountingWidth) {
                    // already exist. skip
                    break mountingWidth;
                }
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(mountingWidthPath),
                        {
                            path: mountingWidthPath,
                            value: 0,
                            unit: "ft",
                        }
                    ]
                ])
            }

            mountingStd: {
                const mountingStd =
                    inst.properties.get(BimProperty.MergedPath(mountingStdPath));
                if (mountingStd) {
                    // already exist. skip
                    break mountingStd;
                }
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(mountingStdPath),
                        {
                            path: mountingStdPath,
                            value: "",
                        }
                    ]
                ])
            }

        }
    }
}

export function fillMissingUseModuleRowField_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const useModulesRowPath = ['tracker-frame', 'dimensions', 'use_modules_row'];
    return {
        toVersion,
        validation: {
            updatedProps: [
                { path: useModulesRowPath },
            ],
            deletedProps: [],
        },
        patch: (inst) => {
            const useModulesRow =
                inst.properties.get(BimProperty.MergedPath(useModulesRowPath));
            if (useModulesRow) {
                // already exist. skip
                return;
            }
            inst.properties.applyPatch([
                [
                    BimProperty.MergedPath(useModulesRowPath),
                    {
                        path: useModulesRowPath,
                        value: false,
                    }
                ]
            ])
        }
    }
}

export function undulatedProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [
                { path: ['position', 'slope'] },
            ],
        },
        patch: (inst) => {
            const slope = inst.properties.get(BimProperty.MergedPath(['position', 'slope']));
            if (slope !== undefined) {
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(['position', 'slope_first_to_last']),
                        {
                            path: ['position', 'slope_first_to_last'],
                            value: slope.value,
                            unit: slope.unit
                        }
                    ], [
                        BimProperty.MergedPath(['position', 'slope']),
                        null
                    ]
                ])
            }
        }
    }
}

export function removeMaxSlopeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    return {
        toVersion,
        validation: {
            updatedProps: [],
            deletedProps: [
                { path: ['position', 'max_slope'] },
            ],
        },
        patch: (inst) => {
            inst.properties.applyPatch([
                [
                    BimProperty.MergedPath(['position', 'max_slope']), 
                    null
                ]
            ]);
        }
    }
}

export function registerTrackerFrame(bim: Bim) {
    bim.instances.archetypes.registerArchetype({
        type_identifier: TrackerFrameTypeIdentifier,
        mandatoryProps: [],
        propsShapeMigrations: migrations(),
    })
    bim.reactiveRuntimes.registerRuntimeSolver(trackerFrameMeshGenerator(bim));
}

function migrations() {
    return [
        createTrackerFrameMountingProps_ShapeMigration(1),
        createPilesProps_ShapeMigration(2),
        createTrackerFrameSeries_ShapeMigration(3),
        createPilesProps_ShapeMigration(4),
        fillMissingUseModuleRowField_ShapeMigration(5),
    ]
}

export function registerTrackerFrameAssetToCatalogItem(group: AssetBasedCatalogItemCreators) {
    registerEquipmentCommonAssetToCatalogItem(TrackerFrameTypeIdentifier, group);
}

export const TrackerFrameFormatterPropsGroup = {
    model: BimProperty.NewShared({
        path: ['tracker-frame', 'commercial', 'model'],
        value: 'unknown_model',
    }),
    mountingStandard: BimProperty.NewShared({
        path: ['tracker-frame', 'module_mounting', 'standard'],
        value: '',
    }),
    stringCount: BimProperty.NewShared({
        path: ['tracker-frame', 'dimensions', 'strings_count'],
        value: 0,
    }),
    stringModulesCount: BimProperty.NewShared({
        path: ['tracker-frame', 'string', 'modules_count_x'],
        value: 0,
    }),
}

export const TrackerFrameKeyProps = {
    length: BimProperty.NewShared({
        path: ['tracker-frame', 'dimensions', 'length'],
        value: 0,
        unit: 'm',
    }),
    moduleWidth: BimProperty.NewShared({
        path: ['tracker-frame', 'module_mounting', 'module_width'],
        value: 0,
        unit: 'ft',
    }),
    pilesCount: BimProperty.NewShared({
        path: ['tracker-frame', 'piles', 'count'],
        value: 0,
    }),
    ...TrackerFrameFormatterPropsGroup,
};

export function registerTrackerFrameKeyPropsGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        TrackerFrameTypeIdentifier,
        new PropertiesGroupFormatter(
            TrackerFrameFormatterPropsGroup,
            (props, unitsMapper) => {
                return [
                    [
                        props.model,
                        props.mountingStandard,
                    ].map(x => x.asText()),
                    props.stringCount.asNumber() * props.stringModulesCount.asNumber() + 'MOD',
                ].flat().join(' ');
            }
        )
    )
}

export function createPilesProps_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const minEmbedmentPath = ['tracker-frame', 'piles', 'min_embedment'];
    const maxRevealPath = ['tracker-frame', 'piles', 'max_reveal'];
    return {
        toVersion,
        validation: {
            updatedProps: [
                { path: minEmbedmentPath },
                { path: maxRevealPath },
            ],
            deletedProps: [],
        },
        patch: (inst) => {
            
            maxReveal: {
                const maxReveal =
                    inst.properties.get(BimProperty.MergedPath(maxRevealPath));
                if (maxReveal) {
                    // already exist. skip
                    break maxReveal;
                }
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(maxRevealPath),
                        {
                            path: maxRevealPath,
                            value: 2.44,
                            unit: "m",
                            numeric_range: [0, 100],
                        }
                    ]
                ])
            }

            minEmbedment: {
                const minEmbedment =
                    inst.properties.get(BimProperty.MergedPath(minEmbedmentPath));
                if (minEmbedment) {
                    // already exist. skip
                    break minEmbedment;
                }
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(minEmbedmentPath),
                        {
                            path: minEmbedmentPath,
                            value: 1.2192,
                            unit: "m",
                            numeric_range: [0, 100],
                        }
                    ]
                ])
            }

        }
    }
}

export function createTrackerFrameSeries_ShapeMigration(toVersion: number)
    : SceneInstanceShapeMigration
{
    const seriesPath = ['tracker-frame', 'commercial', 'series'];
    return {
        toVersion,
        validation: {
            updatedProps: [
                { path: seriesPath },
            ],
            deletedProps: [],
        },
        patch: (inst) => {
            
            series: {
                const series =
                    inst.properties.get(BimProperty.MergedPath(seriesPath));
                if (series) {
                    // already exist. skip
                    break series;
                }
                inst.properties.applyPatch([
                    [
                        BimProperty.MergedPath(seriesPath),
                        {
                            path: seriesPath,
                            value: "",
                        }
                    ]
                ])
            }

        }
    }
}



export function trackerFrameMeshGenerator(bim: Bim): ReactiveSolverBase {

    const DefaultTrackerFrameMeshGenInput = {
        legacyProps: {
            string_modules_count_x: BimProperty.NewShared({path: ['tracker-frame', "string", "modules_count_x"], value: 0}),
            string_modules_count_y: BimProperty.NewShared({path: ['tracker-frame', "string", "modules_count_y"], value: 0}),
            tracker_strings_count: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "strings_count"], value: 0}),
            tracker_module_bay_size: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "module_bay_size"], value: 1}),
            tracker_pile_reveal: BimProperty.NewShared({path: ["tracker-frame", "piles", "max_reveal"], value: 0.5, unit: 'm'}),
            tracker_pile_embedment: BimProperty.NewShared({path: ["tracker-frame", "piles", "min_embedment"], value: 0.5, unit: 'm'}),
            tracker_pile_bearings_gap: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "pile_bearings_gap"], value: 0, unit: 'm'}),
            tracker_strings_gap: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "strings_gap"], value: 0, unit: 'm'}),
            modules_gap: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "modules_gap"], value: 0, unit: 'm'}),
            tracker_motor_placement: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "motor_placement"], value: 0}),
            tracker_motor_gap: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "motor_gap"], value: 0, unit: 'm'}),
            modules_row: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "modules_row"], value: " ", readonly: false}),
            use_modules_row: BimProperty.NewShared({path: ["tracker-frame", "dimensions", "use_modules_row"], value: false, readonly: false}),
            mounting_module_width: BimProperty.NewShared({ path: ["tracker-frame", "module_mounting", "module_width"], value: 0, unit: 'm' }),
        },
    }


    return new SolverObjectInstance({
        solverIdentifier: 'tracker-frame-mesh-gen',
        objectsDefaultArgs: DefaultTrackerFrameMeshGenInput,
        objectsIdentifier: TrackerFrameTypeIdentifier,
        cache: true,
        solverFunction: (inputObj): SolverInstancePatchResult => {

            const props = inputObj.legacyProps;

            const mounting_module_width = props.mounting_module_width.as('m');

            let module_size_x = mounting_module_width;
            const module_size_y = 0;

            let string_modules_count_x = props.string_modules_count_x.asNumber();
            const string_modules_count_y = props.string_modules_count_y.asNumber();
            const modules_gap = props.modules_gap.as('m');


            const tracker_strings_count = props.tracker_strings_count.asNumber();
            const [modulesRow] = getModulesRows(props.modules_row.value);
            if(props.use_modules_row.value){
                let modules_count_x = 0;
                for (const module of modulesRow) {
                    modules_count_x += module;
                }
                string_modules_count_x = Math.ceil(modules_count_x/tracker_strings_count);
            }


            const parts = trackersPartsCache.acquire({
                useModulesRow: props.use_modules_row.value,
                modulesRow: props.modules_row.value,
                modulesPerStringCountHorizontal: string_modules_count_x,
                stringsPerTrackerCount: tracker_strings_count,
                moduleBayCount: props.tracker_module_bay_size.asNumber(),
                moduleSize: module_size_x,
                motorPlacementCoefficient: props.tracker_motor_placement.asNumber(),
                pileGap: props.tracker_pile_bearings_gap.as('m'),
                motorGap: props.tracker_motor_gap.as('m'),
                stringGap: props.tracker_strings_gap.as('m'),
                modulesGap: modules_gap,
            }).parts;

            const submeshesResult: StdSubmeshRepresentation[] = [];

            const poleRadius = 0.05;
            const pileHeight = props.tracker_pile_reveal.as('m') + props.tracker_pile_embedment.as('m');
            const trackersCenterHeight = pileHeight + poleRadius * 1;

            const moduleTotalY = module_size_y + modules_gap;
            const stringTotalY = string_modules_count_y * moduleTotalY;

            let tracker_length = Math.abs(parts[parts.length-1].centerOffset-parts[0].centerOffset)+2*poleRadius;
            const tracker_width = module_size_y*string_modules_count_y +(string_modules_count_y-1)*modules_gap;

            for (const part of parts) {
                if(part.ty & TrackerPartType.Edge){
                    tracker_length-= poleRadius;
                }
            }

            const angle = 0;

            const rotateAroundPoleMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(angle, 0, 0)
            );
            const rotateAroundZMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(0, 0, Math.PI / 2)
            );
            const rotateAroundYMatrix = new Matrix4().makeRotationFromEuler(
                new Euler(0, angle, 0)
            );
            const rotateAroundPoint = new Matrix4().setPosition(0, 0, pileHeight);
            const rotateModuleAroundPoleMatrix = new Matrix4()
                .getInverse(rotateAroundPoint)
                .premultiply(rotateAroundPoleMatrix)
                .premultiply(rotateAroundZMatrix)
                .premultiply(rotateAroundPoint);
            const matReused = new Matrix4();

            // const rotateEverythingToPlusY = new Matrix4().getInverse(rotateAroundPoint)
            //     .premultiply(rotateAroundZMatrix).premultiply(rotateAroundPoint);

            const pipePilesMatId = bim.bimMaterials.shared!.get({
                name: "default",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#FFFFFF",
                    0,
                    0.2,
                    0.9
                ),
            })!;

            const pileGeoId = bim.polylineGeometries.shared!.get(
                PolylineGeometry.newWithAutoIds(
                    [new Vector3(), new Vector3(0, 0, pileHeight)],
                    poleRadius
                )
            )!;

            const motorMatId = bim.bimMaterials.shared!.get({
                name: "motor",
                stdRenderParams: new BimMaterialStdRenderParams(
                    "#555555",
                    0,
                    0.7,
                    0.4
                ),
            })!;
            const motorShell: Vector2[] = [
                new Vector2(-1, 0),
                new Vector2(-0.5, -1),
                new Vector2(0.5, -1),
                new Vector2(1, 0),
            ];
            if (props.tracker_motor_gap.as('m') > 0) {
                motorShell.push(new Vector2(0.5, 1), new Vector2(-0.5, 1));
            }
            const motorGeoId = bim.extrudedPolygonGeometries.shared!.get(
                ExtrudedPolygonGeometry.newWithAutoIds(motorShell, [], -1, 1)
            )!;
            let pilesCount = 0;
            for (const part of parts) {
                if (part.ty & TrackerPartType.Pile) {
                    submeshesResult.push(
                        new StdSubmeshRepresentation(
                            pileGeoId,
                            pipePilesMatId,
                            new Transform(new Vector3(0, part.centerOffset, 0))
                        )
                    );
                    pilesCount++;
                }
                if (part.ty & TrackerPartType.Motor) {
                    matReused
                        .makeRotationX(Math.PI / 2)
                        .premultiply(rotateAroundYMatrix)
                        .setPosition(0, part.centerOffset, trackersCenterHeight);
                    const tr = new Transform();
                    tr.setFromMatrix4(matReused);
                    tr.scale.multiplyScalar(0.25);
                    tr.scale.z *= 0.5;
                    submeshesResult.push(
                        new StdSubmeshRepresentation(motorGeoId, motorMatId, tr)
                    );
                }
            }

            if (parts.length >= 2) {
                const startOffset = parts[0].centerOffset;
                const poleLength =
                    parts[parts.length - 1].centerOffset - startOffset;

                const poleStart = new Vector3(0, 0, 0);
                const poleEnd = new Vector3(0, poleLength, 0);

                const pipeGeoId = bim.polylineGeometries.shared!.get(
                    PolylineGeometry.newWithAutoIds([poleStart, poleEnd], poleRadius)
                )!;
                submeshesResult.push(
                    new StdSubmeshRepresentation(
                        pipeGeoId,
                        pipePilesMatId,
                        new Transform(new Vector3(0, startOffset, pileHeight))
                    )
                );
            }

            const totalModulesCountX = string_modules_count_x * tracker_strings_count;
            const totalModulesCountY = string_modules_count_y;


            const repr = submeshesResult.length ? new StdInstancedMeshesRepresentation(
				[],
				null
			) : null;


			// lod representation
            return {
                repr,
                legacyProps:[
                    { path: ["tracker-frame", "dimensions", "length"], value: tracker_length, unit: "m" },
                    { path: ["tracker-frame", "dimensions", "max_width"], value: tracker_width, unit: "m" },
					{ path: ["tracker-frame", "piles", "count"], value: pilesCount },
                    { path: ["tracker-frame", "piles", "length"], value: pileHeight, unit: "m"},
                    { path: ["tracker-frame", "dimensions", "module_bay_size"], value: props.tracker_module_bay_size.value, readonly: props.use_modules_row.value },
                    { path: ["tracker-frame", "dimensions", "modules_row"], value: props.modules_row.value ? props.modules_row.value: " ", readonly: !props.use_modules_row.value },
                    { path: ["tracker-frame", "dimensions", "motor_placement"], value: props.tracker_motor_placement.value, readonly: props.use_modules_row.value },
                    { path: ["tracker-frame", "string", "modules_count_x"], value: string_modules_count_x, readonly: props.use_modules_row.value },
                    { path: ["tracker-frame", "modules", "modules_count_x"], readonly: true, value: totalModulesCountX },
                    { path: ["tracker-frame", "modules", "modules_count_y"], readonly: true, value: totalModulesCountY },
                ]
            };
        }
    })
}

sceneInstanceHierarchyPropsRegistry.set(
    TrackerFrameTypeIdentifier,
    [
        new BimPropertiesFormatter(
            {
                model: BimProperty.NewShared({
                    path: ['tracker-frame', 'commercial', 'model'],
                    value: '',
                }),
            },
            (props) => [props.model.asText() || 'model not specified'],
        ),
        new BimPropertiesFormatter(
            {
                module_mounting_standard: BimProperty.NewShared({
                    path: ['tracker-frame', 'module_mounting', 'standard'],
                    value: '',
                }),
            },
            (props) => [props.module_mounting_standard.asText() || 'mounting standard not specified'],
        )
    ]
);


export const loadWindPositions = ['interior', 'exterior', 'edge_top', 'edge_bot', 'none', 'edge'] as const;

export type LoadWindPosition = typeof loadWindPositions[number]

export function getLoadWindPositionCostMultiplier(_position: string) {
    if (!loadWindPositions.includes(_position as LoadWindPosition)) {
        LegacyLogger.warn('load wind position not valid', _position);
        return 1;
    }
    const position = _position as LoadWindPosition;
    switch (position) {
        case ('interior'): {
            return 1;
        }
        case ('exterior'): {
            return 1.2;
        }
        case ('edge_top'): {
            return 1.1;
        }
        case ('edge_bot'): {
            return 1.1;
        }
        case ('edge'): {
            return 1;
        }
        case ('none'): {
            return 1;
        }
    }
}
