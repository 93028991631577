export enum ChartViewOptionFlags {
    None = 0,
    Chart = 1 << 0,
    RoundMinMax = 1 << 1,
    AutoBinCount = 1 << 2,
    BinsCount = 1 << 3,

    ChartLegend = 1 << 4,
    ColoriseObjects = 1 << 5,

    ErroneousInstances = 1 << 6,

    All = Chart | RoundMinMax | AutoBinCount | BinsCount | ChartLegend | ColoriseObjects | ErroneousInstances
}
