import type { NamedEvents} from 'engine-utils-ts';
import { LazyBasic, LegacyLogger, ObservableObject, UndoStack } from 'engine-utils-ts';
import {
    Aabb, DEG2RAD, KrMath, Matrix3, Matrix4, Plane, Spherical, Vec3Zero, Vector2, Vector3
} from 'math-ts';

import { Math as _Math, OrthographicCamera, PerspectiveCamera } from '../3rdParty/three';
import {
    CameraFOVSum, CameraMaxFOV, CameraProjChangeTime, CameraRotationSpeed1stPerson, CameraRotationSpeed3rdPerson,
    FlyingAccelSpeed, FlyingMaxSpeed, FlyingStartSpeed, FocusDurationMultiplier, HomeCameraPhiOrtho, HomeCameraTheta,
    MinOrthoSize
} from '../Constants';
import { CameraProjectionMode } from '../EngineConsts';
import { IntersectionType } from '../geometries/GeometryUtils';
import type { GraphicsSettings } from '../GraphicsSettings';
import { FrustumExt, ViewCullBit } from '../structs/FrustumExt';
import { KrFrustum } from '../structs/KrFrustum';
import type { Time } from '../time/TIme';
import type { TotalBounds } from '../TotalBounds';
import { Easing } from '../utils/Easing';
import Utils from '../utils/Utils';
import type { CameraSettings } from './ControlsApi';

export const CameraNearMin = 0.25;
export const CameraFarMin = 1000;

const Deg2Rad = Math.PI / 180;
const EPS = 0.000001;

export type KrCamera = PerspectiveCamera | OrthographicCamera;

interface CameraInternalState extends CameraSettings {
	projectionMode: CameraProjectionMode,
	targetPoint: [number, number, number],
	spherical: {radius: number, theta: number, phi: number},
	orthoSize: number,
}

export class MovementControls {
	readonly graphicsSettings: GraphicsSettings;

	readonly camera_undo: UndoStack;
	obs_state: ObservableObject<CameraInternalState>;

	readonly pCamera: PerspectiveCamera = new PerspectiveCamera(50, 1.0, 0.5, 300);
	readonly oCamera: OrthographicCamera = new OrthographicCamera(-1.0, 1.0, 1.0, -1.0, 0.5, 300); // zoom property is used below as size

	camera: KrCamera = this.pCamera;
	frustum: FrustumExt = new FrustumExt(ViewCullBit, this.camera);

	observableCamera: LazyBasic<KrCamera> = new LazyBasic(
		'camera',
		this.camera.clone(),
	);

	readonly flyVector: Vector3 = Vector3.zero();
	flySpeed: number = 0;
	private flyStartTime: number = 0;

	target: Vector3 = Vector3.zero();
	speedMultiplier: number = 1;

	readonly spherical: Spherical = new Spherical(100, HomeCameraPhiOrtho, HomeCameraTheta); // radius is ignored  by ortho camera

	orthoSize: number = 1;
	minDistance: number = 0.03;

	readonly time: Time;
	readonly sceneBounds: TotalBounds;
	readonly interactiveObjectsActive = null;

	private zoomSpeed: number = 0.15;
	targetPosition: Vector3 | null = null;
	targetSpherical: Spherical | null = null;
	targetOrthoSize: number | null = null;

	private focustStartTime: number = 0;
	private currentFocusDuration: number = 0;

	private typeChangeStartTime: number | null = null;

	private aspect: number = 1;

	private startedRotationUpside = true;

	private sphericalDelta = new Spherical();

	private panOffset = Vector3.zero();

	private needsUpdate: boolean = true;
	noUpdateCounter: number = 0;
	noMoveCounter: number = 0;

	constructor(time: Time, sceneBounds: TotalBounds, NamedEvents: NamedEvents, graphicsSettings: GraphicsSettings) {
		this.camera_undo = new UndoStack('cameraUndo', 10);
		this.obs_state = new ObservableObject<CameraInternalState>({
			identifier: 'CameraSettings',
			initialState: {
				projectionMode: CameraProjectionMode.Orthographic,
				targetPoint: this.target.asArray(),
				spherical: {
					radius: this.spherical.radius,
					phi: this.spherical.phi,
					theta: this.spherical.theta,
				},
				orthoSize: this.getOrthoSize(),
			},
			throttling: {
				onlyFields: ['targetPoint', 'spherical', 'orthoSize'],
			},
			undoStack: this.camera_undo
		});
		this.time = time;
		this.sceneBounds = sceneBounds;
		this.graphicsSettings = graphicsSettings;

		const legacyCameraParallelEvent = NamedEvents.registerEvent('isCameraParallel');
		this.obs_state.observeObject({
			settings: {immediateMode: true, doNotNotifyCurrentState: true },
			onPatch: ({ patch }) => {
				if (patch.projectionMode != undefined) {
					legacyCameraParallelEvent.notify_later_legacy(patch.projectionMode === CameraProjectionMode.Orthographic);
					this.typeChangeStartTime = performance.now() / 1000;
				}
				if (patch.targetPoint || patch.spherical || patch.orthoSize) {
					const spherical = patch.spherical ?
						new Spherical().set(patch.spherical.radius, patch.spherical.phi, patch.spherical.theta)
						: this.spherical.clone();
					// set target should skip update if we are already there
					this.setTarget(
						patch.targetPoint ? Vector3.fromArray(patch.targetPoint, 0) : this.target,
						spherical,
						patch.orthoSize ?? this.getOrthoSize(),
						this.camera_undo.isUndoingOrRedoing() ? 0.5 : 1,
					);
				}
			}
		});
	};


	update() {

		// if (this.camera.matrixWorld.elements.includes(NaN)) {
		// 	console.error(this.camera.matrixWorld.elements);
		// } else {
		// 	console.log('fine');

		// }

		let updated = this._fly();
		updated = this.stepToTarget(this.time.animFrameStart) || updated;
		updated = this.healthCheck() || updated;
		updated = (this.typeChangeStartTime !== null) || updated;

		if (!this.needsUpdate
			&& !updated
			&& this.sphericalDelta.theta === 0
			&& this.sphericalDelta.phi === 0
			&& this.sphericalDelta.radius === 0
			&& this.panOffset.equals(Vec3Zero)
			&& !this.sceneBounds.updated
		) {
			this.noUpdateCounter += 1;
			if (this.noUpdateCounter == 3) {
				this.obs_state.applyPatch({
					patch: {
						targetPoint: this.target.asArray(),
						spherical: this.spherical,
						orthoSize: this.getOrthoSize(),
					}
				});
			}
			return false;
		}
		this.noUpdateCounter = 0;
		this.needsUpdate = false;

		this.spherical.phi += this.sphericalDelta.phi;
		this.spherical.phi %= (Math.PI * 2);
		if ( this.spherical.phi < -Math.PI) {
			 this.spherical.phi += Math.PI * 2;
		}
		if ( this.spherical.phi > Math.PI) {
			 this.spherical.phi -= Math.PI * 2;
		}

		if (Math.abs(this.spherical.phi % Math.PI) < EPS) { // hack: make spherical safe
			this.spherical.phi += EPS;
		}

		this.spherical.theta += this.startedRotationUpside ? this.sphericalDelta.theta : -this.sphericalDelta.theta;
		this.spherical.radius = KrMath.clamp(this.spherical.radius, this.minDistance, this.getMaxDistance());

		this.target.add(this.panOffset);

		const threeTarget = new Vector3(this.target.x, this.target.y, this.target.z);
		{
			setPerspCameraFromTarget(this.pCamera, this.aspect, this.spherical, threeTarget, this.isUpside());
			setNearFar(this.pCamera, this.sceneBounds.bounds);
			this.pCamera.updateProjectionMatrix();// projection matrix uses near/far, important to update it after near/far
		}
		{
			const cam = this.oCamera;

			const offsetThree = new Vector3().setFromSpherical(this.spherical).normalize().multiplyScalar(this.getMaxDistance());
			cam.position.copy(threeTarget).add(offsetThree);
			cam.up.set(0, 0, this.isUpside() ? 1 : -1);
			cam.lookAt(threeTarget);


			setOrthoCameraAspect(cam, this.aspect);
			cam.zoom = 1 / this.orthoSize;

			cam.updateMatrixWorld(true);
			setNearFar(cam, this.sceneBounds.bounds);
			cam.updateProjectionMatrix();// projection matrix uses near/far, important to update it after near/far
		}
		if (this.graphicsSettings.horizontal_pivot_mode && !this.isFirstPerson()) {
			const v = this.target.clone().sub(this.camera.position);
			if (Math.abs(v.z / v.length()) > 0.1) {
				const mult = -this.panOffset.z / v.z;
				this.target.add(v.multiplyScalar(mult));
			}
		}

		this.sphericalDelta.set(0, 0, 0);
		this.panOffset.copy(Vec3Zero);

		if (this.typeChangeStartTime !== null) {
			const t = this.time.animFrameStart - this.typeChangeStartTime;
			let p = _Math.clamp(t / CameraProjChangeTime, 0, 1);

			if (!(p < 0.999)) {
				this.typeChangeStartTime = null;
			} else {
				if (this.getProjType() === CameraProjectionMode.Perspective) { // going from ortho back to perspective
					p = 1 - p;
				}

				p = Easing.easeOutExpo(p);

				Utils.lerpArrays(this.pCamera.projectionMatrix.elements, this.oCamera.projectionMatrix.elements, this.pCamera.projectionMatrix.elements, p);
				this.pCamera.projectionMatrixInverse.getInverse(this.pCamera.projectionMatrix);
				// Utils.lerpArrays(this.pCamera.projectionMatrixInverse.elements, this.oCamera.projectionMatrixInverse.elements, this.pCamera.projectionMatrixInverse.elements, p);
				Utils.lerpArrays(this.pCamera.matrixWorld.elements, this.oCamera.matrixWorld.elements, this.pCamera.matrixWorld.elements, p);
			}
		}

		this.camera = this.getProjType() === CameraProjectionMode.Orthographic && this.typeChangeStartTime === null
			? this.oCamera : this.pCamera;


		this.frustum.update(this.camera);

		this.observableCamera.replaceWith(this.camera);

		return true;

	}

	_fly = function () {

		const m1 = new Matrix4();

		return function (this:MovementControls): boolean {
			// console.log('flying', this.flySpeed, this.flyVector);

			if (this.isFlying()) {

				this.setProjType(CameraProjectionMode.Perspective);
				this.stopFocusing();

				if (this.flySpeed === 0) { // start movement if isn't
					this.toggleFirstPerson(true);
					this.flyStartTime = this.time.animFrameStart;
					this.flySpeed = FlyingStartSpeed;
				} else {
					this.flySpeed = Math.min(FlyingMaxSpeed, this.flySpeed + FlyingAccelSpeed * (this.time.animFrameStart - this.flyStartTime));
				}

				var flyVector = this.flyVector.clone();
				flyVector.normalize();

				let flySpeedMult = this.flySpeed * this.time.animDelta * this.speedMultiplier;
				m1.extractRotation(this.pCamera.matrix);
				let t = Vector3.zero();

				t.setFromMatrixColumn(m1, 0).normalize().multiplyScalar(flySpeedMult * flyVector.x);
				t.z = 0;
				this.panOffset.add(t);

				t.setFromMatrixColumn(m1, 1).normalize().multiplyScalar(flySpeedMult * flyVector.z);
				t.x = t.y = 0;
				this.panOffset.add(t);

				t.setFromMatrixColumn(m1, 2).normalize().multiplyScalar(flySpeedMult * flyVector.y * -1);
				t.z = 0;
				this.panOffset.add(t);

				return true;
			}
			this.flySpeed = 0;
			return false;
		}
	}()


	getMaxDistance () {
		return MovementControls.getMaxDistance(this.sceneBounds.bounds);
	};

	static getMaxDistance(bounds: Aabb) {
		return Math.max(100, KrMath.ceilToPowerOf(bounds.getSize().length() * 3, 2));
	}

	_panLeft(distance: number, objectMatrix: Matrix4) {
		const v_t = Vector3.zero();
		v_t.setFromMatrixColumn(objectMatrix, 0); // get X column of objectMatrix
		v_t.multiplyScalar(-distance);
		this.panOffset.add(v_t);
	}

	_panUp(distance: number, objectMatrix: Matrix4) {
		const v_t = Vector3.zero();
		v_t.setFromMatrixColumn(objectMatrix, 1); // get Y column of objectMatrix
		v_t.multiplyScalar(distance);
		this.panOffset.add(v_t);
	}

	// deltaX and deltaY are in pixels; right and down are positive
	pan (deltaX: number, deltaY: number) {
		if (this.camera instanceof PerspectiveCamera) {
			var offset = Vector3.zero();
			// perspective
			const position_t = new Vector3(0, 0, 0).copy(this.camera.position);
			offset.copy(position_t).sub(this.target);
			let targetDistance = offset.length() + 4;

			// half of the fov is center to top of screen
			targetDistance *= Math.tan((this.camera.fov / 2) * Deg2Rad);

			// we actually don't use screenWidth, since perspective camera is fixed to screen height
			this._panLeft(2 * deltaX * targetDistance * this.camera.aspect, this.camera.matrix);
			this._panUp(  2 * deltaY * targetDistance, this.camera.matrix);

		} else if (this.camera instanceof OrthographicCamera) {
			// orthographic
			this._panLeft(deltaX * (this.orthoSize * this.aspect), this.camera.matrix);
			this._panUp( deltaY * (this.orthoSize), this.camera.matrix);
		} else {
			// camera neither orthographic nor perspective
			LegacyLogger.error('controls encoutered unknown camera type');
		}
	};


	getCameraTargetAngles(): { phi:number, theta:number } {
		let sph = this.targetSpherical;
		if (!sph) {
			sph = this.spherical;
		}
		return { phi: sph.phi, theta: sph.theta };
	}

	stopFocusing () {
		this.targetPosition = null;
		this.targetSpherical = null;
	}

	stopFocusingRotation () {
		this.targetSpherical = null;
	}

	// bounceBackToOrthoNextTime() {
	// 	this.toBounceBackToOrtho = true;
	// }

	setTarget(targetPosition: Vector3, targetSpherical: Spherical, targetOrthoSize: number, durationMultiplier: number) {
		if (
			this.target.equals(targetPosition)
			&& Utils.sphericalsEqual(this.spherical, targetSpherical)
			&& this.getOrthoSize() == targetOrthoSize
		) {
			return;
		}

		const phiDiff = Math.abs((	targetSpherical.phi - this.spherical.phi) % (2 * Math.PI));
		const thetaDiff = Math.abs((targetSpherical.theta - this.spherical.theta) % (2 * Math.PI));

		const newCameraPos_t = Vector3.zero().setFromSphericalCoords(
			targetSpherical.radius, targetSpherical.phi, targetSpherical.theta
		).add(targetPosition);
		const distance = newCameraPos_t.distanceTo(this.camera.position);
		const distanceDuration = Math.pow(distance, 1 / 4);
		const angleDuration = (phiDiff + thetaDiff) * 2;

		let duration = Math.max(distanceDuration, angleDuration);

		duration *= FocusDurationMultiplier * durationMultiplier;

		duration = Math.min(duration, 10);

		if (duration >= 0) {
			this.currentFocusDuration = duration;
			this.targetPosition = targetPosition.clone();
			this.targetSpherical = targetSpherical.clone();
			this.targetOrthoSize = targetOrthoSize;
			this.focustStartTime = this.time.animFrameStart;
			// this.camera_undo.fo
		}
	}

	focusCameraOnPlane(plane: Plane, point: Vector3, v3Size: Vector3) {
		this.setProjType(CameraProjectionMode.Orthographic);

		const cameraOffset = plane.normal.clone();
		if (!this.isUpside()) {
			cameraOffset.multiplyScalar(-1);
		}
		const targetSph = new Spherical().setFromCartesianCoords(cameraOffset.x, cameraOffset.y, cameraOffset.z);
		// put angle around vertical axis to 1 of 4 possible states
		if (Math.abs(Math.abs(targetSph.phi - Math.PI / 2) - Math.PI / 2) < 0.01) {
			targetSph.theta = KrMath.roundTo(this.spherical.theta, Math.PI / 2);
		}
		if (!this.isUpside()) {
			targetSph.phi -= Math.PI;
		}

		const targetPoint_t = plane.projectPoint_t(point);
		v3Size.applyAbs(); // because of rounding errors sometimes component can be small negative number
		const box = Aabb.empty().setFromCenterAndSize(targetPoint_t, v3Size);

		this.focusCameraOnBounds(box, targetSph.phi, targetSph.theta);
	}

	focusCameraOnBounds (bounds: Aabb, phi?:number, theta?: number, durationMultiplier?: number) {
		if (!bounds) {
			LegacyLogger.error('focus target should be Box3');
			return;
		}
		const targetPos = bounds.getCenter(Vector3.zero());

		const targetSpherical = (this.targetSpherical ?? this.spherical).clone();
		if (phi != undefined){
			targetSpherical.phi = phi;
		}
		if (theta != undefined) {
			targetSpherical.theta = theta;
		}

		let { distance, orthoSize } = cameraDistanceForBounds(
			this.pCamera.fov, this.aspect, bounds, targetSpherical.theta, targetSpherical.phi
		);
		distance = _Math.clamp(distance, this.minDistance, this.getMaxDistance());
		if (this.graphicsSettings.zoom_to_cursor && this.getProjType() === CameraProjectionMode.Orthographic) {
			orthoSize += 2500 / (orthoSize + 50);
		}
		targetSpherical.radius = distance;

		if (this.getProjType() == CameraProjectionMode.Perspective) {
			const cam = new PerspectiveCamera();
			const fr = new KrFrustum();
			const sph = targetSpherical.clone();
			setNearFar(cam, this.sceneBounds.bounds);
			cam.near * 0.5;
			cam.far *= 2;
			for (const lerpCoeff of [0.5, 0.6, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95]) {
				sph.radius = targetSpherical.radius * lerpCoeff;
				setPerspCameraFromTarget(cam, this.aspect, sph, new Vector3().copy(targetPos), this.isUpside())
				fr.setFromCamera(cam);
				if (fr.intersect_box(bounds) == IntersectionType.Full) {
					targetSpherical.radius = sph.radius;
					break;
				}
			}
		}
		const duration: number = Utils.isNumber(durationMultiplier) ? durationMultiplier! : 1;
		this.setTarget(targetPos, targetSpherical, orthoSize, duration);
	}

	stepToTarget (now:number) {
		if (!this.targetPosition) {
			return false;
		}
		const t = _Math.clamp((now - this.focustStartTime) / this.currentFocusDuration, 0, 1);
		let easedT = (t < 0.97) ? Easing.easeOutQuad(t) : 1;
		this.target.lerpTo(this.targetPosition, easedT);
		if (this.targetSpherical) {
			this.targetSpherical.phi %= (Math.PI * 2);
			this.targetSpherical.theta %= (Math.PI * 2);
			this.spherical.radius = _Math.lerp(this.spherical.radius, this.targetSpherical.radius, easedT);
			this.orthoSize = _Math.lerp(this.orthoSize, this.targetOrthoSize!, easedT);
			this.spherical.theta =  lerpAngle(this.spherical.theta, this.targetSpherical.theta, easedT);
			this.spherical.phi = lerpAngle(this.spherical.phi, this.targetSpherical.phi, easedT);
		}
		let isTargetInPlace = this.target.distanceTo(this.targetPosition) < this.spherical.radius * 0.001;
		if (this.targetSpherical) {
			const v1 = new Vector2(this.targetSpherical.theta, this.targetSpherical.phi);
			const v2 = new Vector2(this.spherical.theta, this.spherical.phi);
			if (v1.distanceTo(v2) > 0.2 * DEG2RAD) {
				isTargetInPlace = false;
			}
			const radiusDistance = Math.abs(this.targetSpherical.radius - this.spherical.radius)
			if (radiusDistance / this.spherical.radius > 0.01) {
				isTargetInPlace = false;
			}
		}
		if (isTargetInPlace) {
			easedT = 1;
		}
		if (easedT === 1) {
			this.target.copy(this.targetPosition);
			if (this.targetSpherical) {
				this.spherical.copy(this.targetSpherical);
			}
			this.stopFocusing();
		}
		return true;
	}

	isFlying () {
		return this.flyVector.length() > 0;
	}

	stopFlying () {
		this.flyVector.setScalar(0);
	}

	isFirstPerson(): boolean {
		return this.isRadiusFirstPerson(this.spherical.radius);
	}

	isRadiusFirstPerson (radius: number) {
		return radius <= this.minDistance * 1.5;
	}

	toggleFirstPerson (b_enabled: boolean) {
		if (this.isRadiusFirstPerson(this.spherical.radius) === b_enabled) {
			return;
		}
		if (b_enabled) {
			this.spherical.radius = this.minDistance;
		} else {
			this.spherical.radius = _Math.lerp(this.minDistance, this.getMaxDistance(), 0.15);
		}
		const offset = Vector3.zero().setFromSphericalCoords(
			this.spherical.radius, this.spherical.phi, this.spherical.theta
		);
		this.target.copy(this.camera.position).sub(offset);
	}

	healthCheck() {
		let updated = false;
		for (let i = 0; i < 3; i++){
			const tv = this.target.getComponent(i);
			if (!Utils.isNumber(tv)) {
				this.target.setComponent(i, 0);
				updated = true;
				LegacyLogger.deferredWarn('controls invalid target value', tv);
			}
		}
		if (!(this.spherical.radius >= this.minDistance)) {
			updated = true;
			this.spherical.radius = this.minDistance;
		}
		if (!Utils.isNumber(this.orthoSize)) {
			LegacyLogger.deferredWarn('controls orthoSize value', this.orthoSize);
			this.orthoSize = MinOrthoSize;
			updated = true;
		}
		return updated;
	}

	rotateLeft(angle: number) {
		this.sphericalDelta.theta -= angle;
	}

	rotateUp(angle: number) {
		this.sphericalDelta.phi -= angle;
	}

	isUpside() {
		return MovementControls.isUpside(this.spherical.phi);
	}
	
	static isUpside(phi: number) { 
		return phi <= Math.PI && phi >= 0;
	}

	dolly(dollyScale: number) {
		dollyScale = Math.sign(dollyScale) * this.zoomSpeed;
		const newRad = this.spherical.radius + (this.spherical.radius + 2) * dollyScale;
		this.spherical.radius = _Math.clamp(newRad, this.minDistance, this.getMaxDistance());
		this.orthoSize = Math.max(MinOrthoSize, this.orthoSize + (this.orthoSize + 2) * dollyScale);
		this.needsUpdate = true;
	}

	handleMouseMoveRotate(mousePosDelta: Vector2, newTargetPoint?: Vector3) {
		const rotateSpeed0 = KrMath.lerp(
			CameraRotationSpeed1stPerson,
			CameraRotationSpeed3rdPerson,
			KrMath.clamp((this.spherical.radius - this.minDistance) / 1, 0, 1)
		);
		this.rotateLeft(2 * Math.PI * mousePosDelta.x * rotateSpeed0 * (this.isUpside() ? 1 : -1));
		this.rotateUp( 2 * Math.PI * mousePosDelta.y * rotateSpeed0);
	}

	handleMouseMoveDolly (mousePosDelta: Vector2) {
		this.dolly(mousePosDelta.y * 0.000001);
	}

	handleMouseWheel (wheelDeltaY: number, mousePosOffset?: Vector2) {
		if (mousePosOffset !== undefined) {
			this.pan(mousePosOffset.x, mousePosOffset.y);
			this.dolly(wheelDeltaY);
			this.pan(-mousePosOffset.x, -mousePosOffset.y);
		} else {
			this.dolly(wheelDeltaY);
		}
	}

	handleMouseMovePan (mousePosDelta: Vector2) {
		this.stopFocusing();
		this.pan(mousePosDelta.x, mousePosDelta.y);
	}

	setAspect(aspect: number) {
		this.needsUpdate = true;
		this.aspect = aspect;
	}
	getAspect() {
		return this.aspect;
	}

	setProjType(type: CameraProjectionMode) {
		if (this.getProjType() === type) {
			return;
		}
		this.needsUpdate = true;
		this.obs_state.applyPatch({ patch: { projectionMode: type } });
	}

	getProjType(): CameraProjectionMode {
		return this.obs_state.poll().projectionMode;
	}

	getOrthoSize(): number {
		return this.orthoSize;
	}
}


export function setNearFar (camera: KrCamera, sceneBounds: Aabb): void {
	// set camera near far
	// when user is not inside sceneBounds can safely increase near plane
	// purposufely make near and far bounds change in big discrete steps
	// otherwise slight changes of scene bounds will trigger camera projection matrix change
	// which will force redrawing of the whole frame, when it may not be necessary
	// for instance, moving around transparent object a little bit out of the current scene border
	// while opaque objects are already drawn completely and cached in their own buffer

	const cameraForward = new Vector3().setFromMatrixColumn(camera.matrixWorld, 2).multiplyScalar(-1);
	const cameraNearPlane = Plane.allocateZero().setFromNormalAndCoplanarPoint(cameraForward, camera.position);

	const {min, max} = cameraNearPlane.distancesToAabb(sceneBounds);

	let near = KrMath.floorToPowerOf(min * 0.5, 1.5);
	let far = KrMath.ceilToPowerOf(max * 1.1, 1.5);

	camera.near = (near > CameraNearMin && near < Infinity) ? near : CameraNearMin;
	camera.far = (far > CameraFarMin && far < Infinity) ? far : CameraFarMin;
};

const globalUpVector = Object.freeze(Vector3.allocate(0, 0, 1));

export const cameraDistanceForBounds = function () {

	const sph = new Spherical(1, 1, 1);
	const forward = Vector3.zero();
	const box = Aabb.empty();
	const m3 = new Matrix3();


	return function (fov:number, aspect:number, box3: Aabb, theta: number, phi: number):
		{ distance: number, orthoSize: number }
	{

		if (phi % Math.PI < 0.0001) { 	// in case up vector equals global up vector
			phi += 0.0001;				// otherwise angle around vertical axis will be lost
		}
		sph.theta = theta;
		sph.phi = phi;

		forward.setFromSphericalCoords(sph.radius, sph.phi, sph.theta).normalize();
		const right_t = Vector3.crossVectors(forward, globalUpVector).normalize();
		const up_t = Vector3.crossVectors(right_t, forward).normalize();

		m3.set(right_t.x, right_t.y, right_t.z, up_t.x, up_t.y, up_t.z, forward.x, forward.y, forward.z);

		box.copy(box3);
		box.applyMatrix3(m3);

		const addMultH = Math.abs(Math.abs(theta / Deg2Rad) % 90 - 45) / 45 * 0.08 + 1.0;
		const addMultV = Math.abs(Math.abs(phi / Deg2Rad) % 90 - 45) / 45 * 0.08 + 1.0;

		const bSize_t = box.getSize();
		const boxInitSize_t = box3.getSize();
		boxInitSize_t.setScalar(boxInitSize_t.maxComponent());
		bSize_t.lerpTo(boxInitSize_t, 0.1);

		const sizeX = bSize_t.x / aspect * addMultH;
		const sizeY = bSize_t.y * addMultV;
		const sizeZ = bSize_t.z;

		let distV = sizeY / Math.tan(fov * 0.5 * Deg2Rad) * 0.5;
		let distH = sizeX / Math.tan(fov * 0.5 * Deg2Rad) * 0.5;

		let perspDistance = Math.max(distV, distH) + (sizeZ) * 0.5;
		perspDistance = Math.max(perspDistance, CameraNearMin * 2);

		const orthoSize = Math.max(sizeY, sizeX, MinOrthoSize);

		return { distance: perspDistance, orthoSize: orthoSize };
	};
}();

function lerpAngle(from:number, to:number, t:number) {
	let d = to - from;
	if (Math.abs(d) > Math.PI) {
		from += Math.PI * 2 * Math.sign(d);
	}

	return _Math.lerp(from, to, t);
}


export function setPerspCameraFromTarget(cam: PerspectiveCamera, aspect: number, targetSph: Spherical, targetPos: Vector3, upside: boolean) {

	const offsetThree = new Vector3().setFromSpherical(targetSph);
	cam.position.copy(targetPos).add(offsetThree);
	cam.up.set(0, 0, upside ? 1 : -1);
	cam.lookAt(targetPos);

	setPerspCameraAspectFov(cam, aspect);

	cam.updateMatrixWorld(true);
	cam.updateProjectionMatrix();
}


export function setPerspCameraAspectFov(cam: PerspectiveCamera, aspect: number) {
	cam.aspect = aspect;
	const fovSum = CameraFOVSum;
	const fovMax = CameraMaxFOV;
	const x = aspect;
	const y = 1;
	let vertFov = fovSum * (y / (x + y));
	const horFov = vertFov * aspect;
	const maxFov = Math.max(vertFov, horFov);
	if (maxFov > fovMax) {
		vertFov *= fovMax / maxFov;
	}
	cam.fov = vertFov;
}

export function setOrthoCameraAspect(cam: OrthographicCamera, aspect: number) {
	cam.left = aspect * -0.5;
	cam.right = aspect * 0.5;
	cam.bottom = -0.5;
	cam.top = 0.5;
}