import { Vector2, Vector3 } from "math-ts";
import {
    BoundaryPathData,
    Dxf3DFaceProps,
    DxfAppIdProps,
    DxfArcProps,
    DxfAttributeDefinitionProps,
    DxfAttributeProps,
    DxfBlockProps,
    DxfBlockRecordProps,
    DxfCircleProps,
    DxfCommonObjectProps,
    DxfDeserializationMap,
    DxfDictionaryEntry,
    DxfDictionaryProps,
    DxfDimStyleProps,
    DxfEndBlockProps,
    DxfEntityProps,
    DxfExtensionDictionary,
    DxfGeoDataProps,
    DxfHatchProps,
    DxfImageDefinitionProps,
    DxfImageDefReactorProps,
    DxfImageProps,
    DxfInsertProps,
    DxfLayerProps,
    DxfLayoutProps,
    DxfLineProps,
    DxfLineTypeProps,
    DxfLwPolylineProps,
    DxfLwPolylineVertex,
    DxfMargin,
    DxfMTextProps,
    DxfPlotSettingsProps,
    DxfPolylineProps,
    DxfRasterVariablesProps,
    DxfRgb,
    DxfSerializationMap,
    DxfSerializedData,
    DxfSortentsTableProps,
    DxfSplineProps,
    DxfSymbolTableEntryProps,
    DxfSymbolTableProps,
    DxfTextProps,
    DxfTextStyleProps,
    DxfVertexProps,
    DxfViewportProps,
    DxfVPortProps,
    GradientData,

} from "./DxfEnityProps";
import { IterUtils } from "engine-utils-ts";
import { AttrFlag, BlockTypeFlag, DuplicateRecordCloningFlag, DxfLwPolylineFlag, HorizontalJustification, InsertUnitsForImage, LayerFlags, VerticalJustification } from "./DxfEnums";
import { DxfProperties } from "./DxfProperties";
import { DxfHandle } from "./DxfFileFactory";

export abstract class DxfObject {
    type: string;

    constructor(type: string) {
        this.type = type;
    }

    serialize(): DxfSerializedData[] {
        const code = this instanceof DxfVariable ? 9 : 0;
        return [{ code, value: this.type }];
    }

    static deserialize(parameters: DxfProperties): DxfObject | undefined {
        let entityType =
            (parameters.get(0) as string) ?? (parameters.get(9) as string);
        if (!entityType) {
            return undefined; // If no entity type is found, return undefined
        }

        const deserializationMap: {
            [key: string]: (params: DxfProperties) => DxfObject | undefined;
        } = {
            SECTION: DxfSection.deserialize,
            ENDSEC: DxfEndSection.deserialize,
            TABLE: DxfSymbolTable.deserialize,
            ENDTAB: DxfEndTable.deserialize,
            BLOCK_RECORD: DxfBlockRecord.deserialize,
            DIMSTYLE: DxfDimStyle.deserialize,
            LAYER: DxfLayer.deserialize,
            BLOCK: DxfBlock.deserialize,
            ENDBLK: DxfEndBlock.deserialize,
            LWPOLYLINE: DxfLwPolyline.deserialize,
            SPLINE: DxfSpline.deserialize,
            LINE: DxfLine.deserialize,
            ARC: DxfArc.deserialize,
            CIRCLE: DxfCircle.deserialize,
            TEXT: DxfText.deserialize,
            MTEXT: DxfMText.deserialize,
            INSERT: DxfInsert.deserialize,
            ATTRIB: DxfAttribute.deserialize,
            ATTDEF: DxfAttributeDefinition.deserialize,
            "3DFACE": Dxf3DFace.deserialize,
            IMAGE: DxfImage.deserialize,
            IMAGEDEF: DxfImageDefinition.deserialize,
            LAYOUT: DxfLayout.deserialize,
            VIEWPORT: DxfViewport.deserialize,
            PLOTSETTINGS: DxfPlotSettings.deserialize,
            VPORT: DxfVPort.deserialize,
            SEQEND: DxfSeqEnd.deserialize,
            APPID: DxfAppId.deserialize,
            STYLE: DxfTextStyle.deserialize,
            LTYPE: DxfLineType.deserialize,
            DICTIONARY: DxfDictionary.deserialize,
            GEODATA: DxfGeoData.deserialize,
        };


        if (entityType.startsWith("$")) {
            return DxfVariable.deserialize(parameters);
        }

        const deserializer = deserializationMap[entityType];
        if (deserializer) {
            return deserializer(parameters);
        }

        return undefined;
    }

    protected serializeObjectProperty(
        objectSerializationMap: DxfSerializationMap<any>
    ): DxfSerializedData[] {
        const fieldEntityData = Object.values(objectSerializationMap).flatMap(
            (config) => config.serialize(this)
        );

        return fieldEntityData;
    }
}

export class DxfSection extends DxfObject {
    name: string;
    constructor(name: string) {
        super("SECTION");
        this.name = name;
    }

    static override deserialize(props: DxfProperties): DxfSection | undefined {
        const name = props.get(2) as string;
        if (!name) {
            return undefined;
        }
        const section = new DxfSection(name);
        return section;
    }

    override serialize(): DxfSerializedData[] {
        const data = super.serialize();
        data.push({code:2, value:this.name});
        return data;
    }
}

export class DxfEndSection extends DxfObject {

    constructor() {
        super("ENDSEC");
    }

    static override deserialize(props: DxfProperties): DxfEndSection {

        const section = new DxfEndSection();
        return section;
    }
}

export class DxfVariable extends DxfObject {
    parameters: { code: number; value: number | string }[] = [];

    constructor(
        name: string,
        parameters: DxfProperties
    ) {
        name = name.toUpperCase();
        if (!name.startsWith("$")) {
            name = "$" + name;
        }
        super(name);
        for (const parameter of parameters.entries()) {
            this.parameters.push(parameter);
        }
    }

    getParameterByCode(code:number):string | number | undefined{
        return this.parameters.find(x => x.code === code)?.value;
    }

    override serialize(): DxfSerializedData[] {
        const serializeData = super.serialize();
        for (const parameter of this.parameters) {
            serializeData.push(parameter);
        }
        return serializeData;
    }
    static override deserialize(props: DxfProperties): DxfVariable | undefined {
        const name = props.get(9) as string;
        if (!name || !name.startsWith("$")) {
            return undefined;
        }
        props.remove(9);

        const variable = new DxfVariable(name, props);
        return variable;
    }
}

export class DxfSymbolTable extends DxfObject implements DxfSymbolTableProps {
    tableName: string = "";
    handle: string = "";
    xDictionary?: DxfExtensionDictionary;
    ownerDictionaryHandle?: string | undefined;
    ownerHandleSoftPointer?: string | undefined;
    readonly subclassMarkerSymbolTable = "AcDbSymbolTable";
    maximumEntriesInTable: number = 0;

    constructor(init?: Partial<DxfSymbolTable> | string) {
        super("TABLE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    static override deserialize(properties: DxfProperties): DxfSymbolTable {
        const table = new DxfSymbolTable();
        let currentMap = DxfSymbolTableProps.deserializationMap;

        for (const { code, value } of properties.entries()) {
            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (table as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(table, value);
                }
            }
        }

        return table;
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldTextData = this.serializeObjectProperty(
            DxfSymbolTableProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldTextData);

        return serialized;
    }
}

export class DxfEndTable extends DxfObject {

    constructor(){
        super("ENDTAB")
    }
    
    static override deserialize(props: DxfProperties): DxfEndTable {
        const endtab = new DxfEndTable();
        return endtab;
    }
}

//Dxf Table Entries

export class DxfTableEntry extends DxfObject implements DxfSymbolTableEntryProps {
    xDictionary: DxfExtensionDictionary = {"ACAD_XDICTIONARY":[]};
    reactorsDictionary: DxfExtensionDictionary = {"ACAD_REACTORS":[]};
    entityName?: string | undefined;
    handle: string = "";
    ownerDictionaryPointer?: string | undefined;
    ownerDictionaryHardPointer?: string | undefined;
    ownerObjectPointer?: string | undefined;
    readonly subclassMarkerSymbolTableEntry = "AcDbSymbolTableRecord";

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();

        const fieldEntityData = this.serializeObjectProperty(
            DxfSymbolTableEntryProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldEntityData);

        return serialized;
    }

    addXDictionary(ownerDictionaryHandle:string){
        this.xDictionary["ACAD_XDICTIONARY"].push(ownerDictionaryHandle)
    }

    protected static processTableEntryProperties<T extends DxfTableEntry>(
        entry: T,
        properties: DxfProperties,
        map: DxfDeserializationMap<T>
    ): T {

        let currentMap = DxfSymbolTableEntryProps.deserializationMap;

        for (const { code, value } of properties.entries()) {
            if (code === 100 && value !== "AcDbSymbolTableRecord") {
                currentMap = map;
            }

            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (entry as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(entry, value);
                }
            }
        }
        return entry;
    }
}

export class DxfAppId extends DxfTableEntry implements DxfAppIdProps {
    readonly subclassMarkerAppId = "AcDbRegAppTableRecord";
    appName: string = "";
    standardFlags: number = 0;

    constructor(init?: Partial<DxfAppId>|string) {
        super("APPID");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfAppIdProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfAppId {
        const appId = new DxfAppId();
        super.processTableEntryProperties(
            appId,
            properties,
            DxfAppIdProps.deserializationMap
        );
        return appId;
    }
}

export class DxfTextStyle extends DxfTableEntry implements DxfTextStyleProps {
    readonly subclassMarkerTextStyle = "AcDbTextStyleTableRecord";
    styleName: string = "Arial";
    standardFlags: number = 0;
    fixedTextHeight?: number | undefined;
    widthFactor?: number | undefined;
    obliqueAngle?: number | undefined;
    textGenerationFlags?: number | undefined;
    lastHeightUsed?: number | undefined;
    primaryFontFileName?: string | undefined;
    bigFontFileName?: string | undefined;
    truetypeFontAttributes?: string | undefined;

    constructor(init?: Partial<DxfTextStyle>|string) {
        super("STYLE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfTextStyleProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfTextStyle {
        const style = new DxfTextStyle();
        super.processTableEntryProperties(
            style,
            properties,
            DxfTextStyleProps.deserializationMap
        );
        return style;
    }
}

export class DxfLineType extends DxfTableEntry implements DxfLineTypeProps {
    readonly subclassMarkerLineStyle = "AcDbLinetypeTableRecord";
    lineTypeName: string = "";
    standardFlags: number = 0;
    description: string = "";
    alignmentCode: number = 0;
    numberOfElements: number = 0;
    totalPatternLength: number = 0.0;
    elementLengths?: number[] | undefined;
    elementTypes?: number[] | undefined;
    shapeNumbers?: number[] | undefined;
    stylePointers?: string[] | undefined;
    scaleValues?: number[] | undefined;
    rotationValues?: number[] | undefined;
    xOffsets?: number[] | undefined;
    yOffsets?: number[] | undefined;
    textStrings?: string[] | undefined;

    constructor(init?: Partial<DxfLineType>|string) {
        super("LTYPE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfLineTypeProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfLineType {
        const style = new DxfLineType();
        super.processTableEntryProperties(
            style,
            properties,
            DxfLineTypeProps.deserializationMap
        );
        return style;
    }
}

export class DxfVPort extends DxfTableEntry implements DxfVPortProps {
    readonly subclassMarkerVPort = "AcDbViewportTableRecord";
    viewportName: string = "";
    standardFlags: number = 0;
    lowerLeftCorner?: Vector2 | undefined;
    upperRightCorner?: Vector2 | undefined;
    viewCenter?: Vector2 | undefined;
    snapBase?: Vector2 | undefined;
    snapSpacing?: Vector2 | undefined;
    gridSpacing?: Vector2 | undefined;
    viewDirection?: Vector3 | undefined;
    viewTarget?: Vector3 | undefined;
    lensLength?: number | undefined;
    frontClippingPlane?: number | undefined;
    backClippingPlane?: number | undefined;
    viewHeight?: number | undefined;
    viewAspectRatio?: number | undefined;
    snapRotationAngle?: number | undefined;
    viewTwistAngle?: number | undefined;
    circleSides?: number | undefined;
    frozenLayersHandles?: string[] | undefined;
    plotStyleSheet?: string | undefined;
    renderMode?: number | undefined;
    viewMode?: number | undefined;
    ucsIconSetting?: number | undefined;
    ucsOrigin?: Vector3 | undefined;
    ucsXAxis?: Vector3 | undefined;
    ucsYAxis?: Vector3 | undefined;
    ucsTableRecordHandle?: string | undefined;
    baseUcsTableRecordHandle?: string | undefined;
    orthographicUcsType?: number | undefined;
    elevation?: number | undefined;
    shadePlotSetting?: number | undefined;
    majorGridLines?: number | undefined;
    backgroundObjectHandle?: string | undefined;
    shadePlotObjectHandle?: string | undefined;
    visualStyleObjectHandle?: string | undefined;
    defaultLightingOn?: boolean | undefined;
    defaultLightingType?: number | undefined;
    brightness?: number | undefined;
    contrast?: number | undefined;
    ambientColor?: string | undefined;

    constructor(init?: Partial<DxfVPort>|string) {
        super("VPORT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfVPortProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfVPort {
        const vport = new DxfVPort();
        super.processTableEntryProperties(
            vport,
            properties,
            DxfVPortProps.deserializationMap
        );
        return vport;
    }
}

export class DxfBlockRecord
    extends DxfTableEntry
    implements DxfBlockRecordProps
{
    readonly subclassMarkerBlockTableRecord = "AcDbBlockTableRecord";
    blockName: string = "";
    layoutHandle?: string | undefined;
    blockInsertionUnits?: number | undefined;
    blockExplodability?: number | undefined;
    blockScalability?: number | undefined;
    bitmapPreview?: string | undefined;
    designCenterVersionNumber?: number | undefined;
    insertUnits?: number | undefined;

    constructor(init?: Partial<DxfBlockRecord> | string) {
        super("BLOCK_RECORD");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfBlockRecordProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfBlockRecord {
        const blockRecord = new DxfBlockRecord();
        super.processTableEntryProperties(
            blockRecord,
            properties,
            DxfBlockRecordProps.deserializationMap
        );
        return blockRecord;
    }
}

export class DxfDimStyle extends DxfTableEntry implements DxfDimStyleProps {
    readonly subclassMarkerDimStyle = "AcDbDimStyleTableRecord";
    dimensionStyleName: string = "";
    standardFlags: number = 0;
    dimpost?: string | undefined;
    dimapost?: string | undefined;
    dimblk?: string | undefined;
    dimblk1?: string | undefined;
    dimblk2?: string | undefined;
    dimscale?: number | undefined;
    dimasz?: number | undefined;
    dimexo?: number | undefined;
    dimdli?: number | undefined;
    dimexe?: number | undefined;
    dimrnd?: number | undefined;
    dimdle?: number | undefined;
    dimtp?: number | undefined;
    dimtm?: number | undefined;
    dimtxt?: number | undefined;
    dimcen?: number | undefined;
    dimtsz?: number | undefined;
    dimaltf?: number | undefined;
    dimlfac?: number | undefined;
    dimtvp?: number | undefined;
    dimtfac?: number | undefined;
    dimgap?: number | undefined;
    dimaltrnd?: number | undefined;
    dimtol?: number | undefined;
    dimlim?: number | undefined;
    dimtih?: number | undefined;
    dimtoh?: number | undefined;
    dimse1?: number | undefined;
    dimse2?: number | undefined;
    dimtad?: number | undefined;
    dimzin?: number | undefined;
    dimazin?: number | undefined;
    dimalt?: number | undefined;
    dimaltd?: number | undefined;
    dimtofl?: number | undefined;
    dimsah?: number | undefined;
    dimtix?: number | undefined;
    dimsoxd?: number | undefined;
    dimclrd?: number | undefined;
    dimclre?: number | undefined;
    dimclrt?: number | undefined;
    dimadec?: number | undefined;
    dimunit?: number | undefined;
    dimdec?: number | undefined;
    dimtdec?: number | undefined;
    dimaltu?: number | undefined;
    dimalttd?: number | undefined;
    dimaunit?: number | undefined;
    dimfrac?: number | undefined;
    dimlunit?: number | undefined;
    dimdsep?: number | undefined;
    dimtmove?: number | undefined;
    dimjust?: number | undefined;
    dimsd1?: number | undefined;
    dimsd2?: number | undefined;
    dimtolj?: number | undefined;
    dimtzin?: number | undefined;
    dimaltsz?: number | undefined;
    dimalttz?: number | undefined;
    dimfit?: number | undefined;
    dimupt?: number | undefined;
    dimatfit?: number | undefined;
    dimtxsty?: string | undefined;
    dimldrblk?: string | undefined;
    dimblkHandle?: string | undefined;
    dimblk1Handle?: string | undefined;
    dimblk2Handle?: string | undefined;
    dimlwd?: number | undefined;
    dimlwe?: number | undefined;

    getSerializationMap(): DxfSerializationMap<DxfDimStyle> {
        return {};
    }

    constructor(init?: Partial<DxfDimStyle>|string) {
        super("DIMSTYLE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfDimStyleProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfDimStyle {
        const dimstyle = new DxfDimStyle();
        super.processTableEntryProperties(
            dimstyle,
            properties,
            DxfDimStyleProps.deserializationMap
        );
        return dimstyle;
    }
}

export class DxfLayer extends DxfTableEntry implements DxfLayerProps {
    layerName: string = "0";
    standardFlags: number = 0;
    colorNumber?: number | undefined;
    linetypeName?: string | undefined = "Continuous";
    plottingFlag: boolean = true;
    lineweightEnum?: number | undefined;
    plotStyleNameHandle: string = "0";
    materialHandle?: string | undefined;
    readonly subclassMarkerLayer = "AcDbLayerTableRecord";

    constructor(init?: Partial<DxfLayer>|string) {
        super("LAYER");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    addFlag(flag: LayerFlags): void {
        this.standardFlags |= flag;
    }

    // Method to check if a flag is set
    hasFlag(flag: LayerFlags): boolean {
        return (this.standardFlags & flag) !== 0;
    }

    // Method to remove a flag
    removeFlag(flag: LayerFlags): void {
        this.standardFlags &= ~flag;
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfLayerProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfLayer {
        const layer = new DxfLayer();
        super.processTableEntryProperties(
            layer,
            properties,
            DxfLayerProps.deserializationMap
        );
        return layer;
    }
}

// Dxf Entities

export class DxfEntity extends DxfObject implements DxfEntityProps {
    handle: string = "";
    xDictionary: DxfExtensionDictionary = {"ACAD_XDICTIONARY":[]} ;
    handleToOwner?: string;
    readonly subclassMarkerEntity = "AcDbEntity";
    layer?: string | undefined;
    paperSpace: boolean = false;
    layoutTabName?: string | undefined;
    linetype?: string | undefined;
    colorNumber?: number | undefined;
    lineweight?: number | undefined;
    linetypeScale?: number | undefined;
    objectVisibility?: number | undefined;
    proxyEntityGraphicsBytes?: number | undefined;
    proxyEntityGraphicsData?: string | undefined;
    colorValue?: number | undefined;
    colorName?: string | undefined;
    transparencyValue?: number | undefined;
    plotStyleHandle?: string | undefined;
    shadowMode?: number | undefined;

    addXDictionary(ownerDictionaryHandle:string){
        this.xDictionary["ACAD_XDICTIONARY"].push(ownerDictionaryHandle)
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();

        const fieldEntityData = this.serializeObjectProperty(
            DxfEntityProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldEntityData);

        return serialized;
    }

    protected static processEntityProperties<T extends DxfEntity>(
        entity: T,
        properties: DxfProperties,
        map: DxfDeserializationMap<T>
    ): T {
        let currentMap = DxfEntityProps.deserializationMap;

        for (const { code, value } of properties.entries()) {
            if (code === 100 && value !== "AcDbEntity") {
                currentMap = map;
            }

            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (entity as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(entity, value);
                }
            }
        }
        return entity;
    }
}

export class DxfLine extends DxfEntity implements DxfLineProps {
    readonly subclassMarkerLine: "AcDbLine" = "AcDbLine";
    thickness?: number;
    startPoint: Vector3 = new Vector3();
    endPoint: Vector3 = new Vector3();
    extrusionDirection?: Vector3;

    // Override the type to be "LINE"
    constructor(init?: Partial<DxfLine>|string) {
        super("LINE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfLineProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(
        properties: DxfProperties
    ): DxfLine | undefined {
        const line = new DxfLine();
        super.processEntityProperties(
            line,
            properties,
            DxfLineProps.deserializationMap
        );
        return line;
    }
}

export class DxfLwPolyline extends DxfEntity implements DxfLwPolylineProps {
    readonly subclassMarkerLwPolyline = "AcDbPolyline";
    numberOfVertices: number = 0;
    polylineFlag: number = 0;
    constantWidth?: number | undefined;
    elevation?: number | undefined;
    thickness?: number | undefined;
    extrusionDirection?: Vector3 | undefined;
    vertices: DxfLwPolylineVertex[] = [];

    get closed():boolean{
        return this.hasPolylineFlag(DxfLwPolylineFlag.closed);
    }
    set closed(closed:boolean){
        if(this.closed){
            if(!closed){
                this.removeBlockTypeFlag(DxfLwPolylineFlag.closed);
            }
        }else{
            if(closed){
                this.addPolylineFlag(DxfLwPolylineFlag.closed)
            }
        }

    }

    constructor(init?: Partial<DxfLine>|string) {
        super("LWPOLYLINE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    addVertex(coordinate:Vector3 | Vector2, bulge?:number){
        const coord = new Vector3(...coordinate.asArray());
        const dxfVert = new DxfLwPolylineVertex(coord, bulge);
        this.vertices.push(dxfVert);
        this.numberOfVertices += 1
    }

    addPolylineFlag(flag: DxfLwPolylineFlag): void {
        this.polylineFlag |= flag;
    }

    // Method to check if a flag is set
    hasPolylineFlag(flag: DxfLwPolylineFlag): boolean {
        return (this.polylineFlag & flag) !== 0;
    }

    // Method to remove a flag
    removeBlockTypeFlag(flag: DxfLwPolylineFlag): void {
        this.polylineFlag &= ~flag;
    }


    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfLwPolylineProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfLwPolyline {
        const polyLine = new DxfLwPolyline();
        super.processEntityProperties(
            polyLine,
            properties,
            DxfLwPolylineProps.deserializationMap
        );
        return polyLine;
    }
}

export class DxfText extends DxfEntity implements DxfTextProps {
    readonly subclassMarkerText = "AcDbText";
    thickness?: number | undefined;
    firstAlignmentPoint: Vector3 = new Vector3();
    textHeight: number = 1;
    textString: string = "";
    rotation?: number | undefined;
    relativeXScaleFactor?: number | undefined;
    obliqueAngle?: number | undefined;
    textStyleName?: string | undefined;
    textGenerationFlags?: number | undefined;
    horizontalJustification?: number | undefined;
    secondAlignmentPoint?: Vector3 | undefined;
    extrusionDirection?: Vector3 | undefined;
    verticalJustification?: number | undefined;

    constructor(init?: Partial<DxfText>|string) {
        super("TEXT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfTextProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfText {
        const text = new DxfText();
        super.processEntityProperties(
            text,
            properties,
            DxfTextProps.deserializationMap
        );
        return text;
    }
}

export class DxfInsert extends DxfEntity implements DxfInsertProps {
    readonly subclassMarkerInsert = "AcDbBlockReference";
    hasAttributes: boolean = false;
    blockName: string = "";
    insertionPoint: Vector3 = new Vector3();
    scaleFactor?: Vector3 | undefined;
    rotationAngle?: number | undefined;
    columnCount?: number | undefined;
    rowCount?: number | undefined;
    columnSpacing?: number | undefined;
    rowSpacing?: number | undefined;
    extrusionDirection?: Vector3 | undefined;

    constructor(init?: Partial<DxfInsert>|string) {
        super("INSERT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfInsertProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfInsert {
        const dxfObject = new DxfInsert();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfInsertProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfImage extends DxfEntity implements DxfImageProps {
    readonly subclassMarkerImage = "AcDbRasterImage";
    classVersion?: number | undefined;
    insertionPoint: Vector3 = new Vector3();
    uVector: Vector3 = new Vector3();
    vVector: Vector3 = new Vector3();
    imageSize: Vector2 = new Vector2();
    imageDefHandle?: string | undefined;
    displayProperties?: number | undefined;
    clippingState: number = 0;
    brightness: number = 50;
    contrast: number = 50;
    fade: number = 0;
    imageDefReactorHandle?: string | undefined;
    clippingBoundaryType: number = 1;
    clipBoundaryVertices: Vector2[] = [];
    clipMode?: number | undefined;

    constructor(init?: Partial<DxfImage>|string) {
        super("IMAGE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfImageProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfImage {
        const dxfObject = new DxfImage();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfImageProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfCircle extends DxfEntity implements DxfCircleProps {
    readonly subclassMarkerCircle = "AcDbCircle";
    thickness?: number | undefined;
    center: Vector3 = new Vector3();
    radius: number = 0;
    extrusionDirection?: Vector3;

    constructor(init?: Partial<DxfCircle>|string) {
        super("CIRCLE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfCircleProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfCircle {
        const dxfObject = new DxfCircle();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfCircleProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfArc extends DxfEntity implements DxfArcProps {
    readonly subclassMarkerCircle = "AcDbCircle";
    thickness?: number | undefined;
    center: Vector3 = new Vector3();
    radius: number = 0;
    readonly subclassMarkerArc = "AcDbArc";
    startAngle: number = 0;
    endAngle: number = 360;
    extrusionDirection?: Vector3;

    constructor(init?: Partial<DxfArc>|string) {
        super("ARC");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfArcProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfArc {
        const dxfObject = new DxfArc();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfArcProps.deserializationMap
        );
        return dxfObject;
    }
}

export class Dxf3DFace extends DxfEntity implements Dxf3DFaceProps {
    readonly subclassMarker = "AcDbFace";
    firstCorner: Vector3 = new Vector3();
    secondCorner: Vector3 = new Vector3();
    thirdCorner: Vector3 = new Vector3();
    fourthCorner?: Vector3 | undefined;
    invisibleEdgeFlags: number = 0;

    constructor(init?: Partial<Dxf3DFace>|string) {
        super("3DFACE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            Dxf3DFaceProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): Dxf3DFace {
        const dxfObject = new Dxf3DFace();
        super.processEntityProperties(
            dxfObject,
            properties,
            Dxf3DFaceProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfMText extends DxfEntity implements DxfMTextProps {
    readonly subclassMarkerMText = "AcDbMText";
    insertionPoint: Vector3 = new Vector3();
    textHeight?: number | undefined;
    rectWidth?: number | undefined;
    attachmentPoint?: number | undefined;
    drawingDirection?: number | undefined;
    textString: string = "";
    textStyle: string = "STANDART";
    extrusionDirection?: Vector3 | undefined;
    xAxisDirection?: Vector3 | undefined;
    rotationAngle?: number | undefined;
    lineSpacingStyle?: number | undefined;
    lineSpacingFactor?: number | undefined;
    backgroundFillSetting?: number | undefined;
    backgroundFillColor?: number | undefined;
    backgroundColorName?: string | undefined;
    fillBoxScale?: number | undefined;
    transparency?: number | undefined;
    columnType?: number | undefined;
    columnCount?: number | undefined;
    columnFlowReversed?: number | undefined;
    columnAutoHeight?: number | undefined;
    columnWidth?: number | undefined;
    columnGutter?: number | undefined;
    columnHeights?: number[] | undefined;

    constructor(init?: Partial<DxfMText>|string) {
        super("MTEXT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldLineData = this.serializeObjectProperty(
            DxfMTextProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldLineData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfMText {
        const dxfObject = new DxfMText();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfMTextProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfAttributeDefinition
    extends DxfEntity
    implements DxfAttributeDefinitionProps
{
    
    readonly subclassMarkerText = "AcDbText";
    thickness?: number | undefined;
    firstAlignmentPoint: Vector3 = new Vector3();
    textHeight: number = 1;
    defaultValue: string = "";
    textRotation?: number | undefined;
    relativeXScaleFactor?: number | undefined;
    obliqueAngle?: number | undefined;
    textStyleName: string = "STANDARD";
    textGenerationFlags?: number | undefined;
    horizontalJustification?: number | undefined;
    secondAlignmentPoint?: Vector3 | undefined;
    extrusionDirection?: Vector3 | undefined;
    readonly subclassMarkerAttributeDefinition = "AcDbAttributeDefinition";
    versionNumber: number = 0;
    promptString: string = "";
    tagString: string = "";
    attributeFlags: number = 0;
    fieldLength?: number | undefined;
    verticalTextJustificationType?: number | undefined;
    lockPositionFlag?: number | undefined;

    constructor(init?: Partial<DxfMText>|string) {
        super("ATTDEF");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldAttDefData = this.serializeObjectProperty(
            DxfAttributeDefinitionProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldAttDefData);

        return serialized;
    }

    static override deserialize(
        properties: DxfProperties
    ): DxfAttributeDefinition {
        const dxfObject = new DxfAttributeDefinition();
        super.processEntityProperties(
            dxfObject, 
            properties, 
            DxfAttributeDefinitionProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfAttribute
    extends DxfEntity
    implements DxfAttributeProps
{
    readonly subclassMarkerText = "AcDbText";
    thickness?: number | undefined;
    startPoint: Vector3 = new Vector3(0, 0, 0);
    textHeight: number = 1;
    defaultValue: string = "";
    readonly subclassMarkerAttribute = "AcDbAttribute";
    versionNumber: number = 0;
    attributeTag: string = "";
    attributeFlags: number = 0;
    fieldLength?: number | undefined;
    textRotation?: number | undefined;
    relativeXScaleFactor?: number | undefined;
    obliqueAngle?: number | undefined;
    textStyleName?: string = "STANDARD";
    textGenerationFlags?: number | undefined;
    horizontalJustification?: HorizontalJustification;
    verticalJustification?: VerticalJustification;
    alignmentPoint?: Vector3 | undefined;
    extrusionDirection?: Vector3 | undefined;
    lockPositionFlag: boolean = false;
 

    constructor(init?: Partial<DxfMText>|string) {
        super("ATTRIB");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    addFlag(flag: AttrFlag): void {
        this.attributeFlags |= flag;
    }

    // Method to check if a flag is set
    hasFlag(flag: AttrFlag): boolean {
        return (this.attributeFlags & flag) !== 0;
    }

    // Method to remove a flag
    removeFlag(flag: AttrFlag): void {
        this.attributeFlags &= ~flag;
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldTextData = this.serializeObjectProperty(
            DxfAttributeProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldTextData);

        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfAttribute {
        const dxfObject = new DxfAttribute();
        super.processEntityProperties(
            dxfObject, 
            properties, 
            DxfAttributeProps.deserializationMap
        );

        return dxfObject;
    }

    
}

export class DxfSpline extends DxfEntity implements DxfSplineProps {
    readonly subclassMarkerSpline = "AcDbSpline";
    normalVector?: Vector3 | undefined;
    splineFlag: number = 8;
    degree: number = 2;
    numberOfKnots: number = 0;
    numberOfControlPoints: number = 0;
    numberOfFitPoints: number = 0;
    knotTolerance: number = 0.0000001;
    controlPointTolerance: number = 0.0000001;
    fitTolerance: number = 0.0000000001;
    startTangent?: Vector3 | undefined;
    endTangent?: Vector3 | undefined;
    knots: number[] = [];
    weights: number[] = [];
    controlPoints: Vector3[] = [];
    fitPoints: Vector3[] = [];

    constructor(init?: Partial<DxfSpline> | string) {
        super("SPLINE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfSplineProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfSpline {
        const dxfObject = new DxfSpline();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfSplineProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfVertex extends DxfEntity implements DxfVertexProps{
    readonly subclassMarkerVertex = "AcDbVertex";
    subclassMarkerVertexDimension: "AcDb2dVertex" | "AcDb3dPolylineVertex" = "AcDb3dPolylineVertex";
    location: Vector3 = new Vector3();
    startWidth: number = 0;
    endWidth: number = 0;
    bulge: number = 0;
    vertexFlags: number = 0;
    curveFitTangentDirection?: number | undefined;
    meshVertexIndex1?: number | undefined;
    meshVertexIndex2?: number | undefined;
    meshVertexIndex3?: number | undefined;
    meshVertexIndex4?: number | undefined;
    vertexIdentifier?: number | undefined;

    constructor(init?: Partial<DxfVertex> | string) {
        super("VERTEX");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfVertexProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfVertex {
        const dxfObject = new DxfVertex();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfVertexProps.deserializationMap
        );
        return dxfObject;
    }

}

export class DxfPolyline extends DxfEntity implements DxfPolylineProps{
    subclassMarker: "AcDb2dPolyline" | "AcDb3dPolyline" = "AcDb3dPolyline";
    dummyPoint?: Vector3 | undefined;
    thickness?: number | undefined;
    polylineFlag: number = 0;
    startWidth: number = 0;
    endWidth: number = 0;
    meshMVertexCount: number = 0;
    meshNVertexCount: number = 0;
    smoothSurfaceMDensity: number = 0;
    smoothSurfaceNDensity: number = 0;
    smoothSurfaceType: number = 0;
    extrusionDirection?: Vector3 | undefined;

    constructor(init?: Partial<DxfPolyline> | string) {
        super("POLYLINE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        };
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfPolylineProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfPolyline {
        const dxfObject = new DxfPolyline();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfPolylineProps.deserializationMap
        );
        return dxfObject;
    }

}

export class DxfHatch extends DxfEntity implements DxfHatchProps{
    readonly subclassMarkerHatch = "AcDbHatch";
    elevationPoint: Vector3 = new Vector3();
    extrusionDirection: Vector3= new Vector3(0,0,1);
    patternName: string = "SOLID";
    solidFill: boolean = true;
    associativityFlag: boolean = false;
    boundaryPathCount: number = 0;
    boundaryPaths: BoundaryPathData[] = [];
    numberOfSourceObjects: number = 0;
    hatchStyle: number = 1;
    patternType: number = 1;
    patternAngle: number = 0;
    patternScale: number = 1;
    numberOfSeedPoints: number = 1;
    seedPoints: Vector2[] = [new Vector2()];
    patternDoubleFlag: boolean = false;
    gradientData?: GradientData | undefined;


    constructor(init?: Partial<DxfSeqEnd> | string) {
        super("HATCH");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfHatchProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfHatch {
        const dxfObject = new DxfHatch();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfHatchProps.deserializationMap
        );
        return dxfObject;
    }
    
}

export class DxfSeqEnd extends DxfEntity {
    constructor(init?: Partial<DxfSeqEnd> | string) {
        super("SEQEND");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfSeqEnd {
        const dxfObject = new DxfSeqEnd();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfEntityProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfViewport extends DxfEntity implements DxfViewportProps {
    readonly subclassMarkerViewport = "AcDbViewport";
    centerPoint?: Vector3 | undefined;
    width?: number | undefined;
    height?: number | undefined;
    viewportStatus?: number | undefined;
    viewportId: number = 0;
    viewCenter?: Vector2 | undefined;
    snapBasePoint?: Vector2 | undefined;
    snapSpacing?: Vector2 | undefined;
    gridSpacing?: Vector2 | undefined;
    viewDirection?: Vector2 | undefined;
    viewTarget?: Vector2 | undefined;
    perspectiveLensLength?: number | undefined;
    frontClipPlane?: number | undefined;
    backClipPlane?: number | undefined;
    viewHeight?: number | undefined;
    snapAngle?: number | undefined;
    viewTwistAngle?: number | undefined;
    circleZoomPercent?: number | undefined;
    viewportFlags?: number | undefined;
    ucsOrigin?: Vector3 | undefined;
    ucsXAxis?: Vector3 | undefined;
    ucsYAxis?: Vector3 | undefined;
    ucsType?: number | undefined;
    elevation?: number | undefined;
    shadePlotMode?: number | undefined;
    renderMode?: number | undefined;
    defaultLighting?: boolean | undefined;
    defaultLightingType?: number | undefined;
    viewBrightness?: number | undefined;
    viewContrast?: number | undefined;
    ambientLightColor?: DxfRgb | undefined;

    constructor(init?: Partial<DxfViewport> | string) {
        super("VIEWPORT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfViewportProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfViewport {
        const dxfObject = new DxfViewport();
        super.processEntityProperties(
            dxfObject,
            properties,
            DxfViewportProps.deserializationMap
        );
        return dxfObject;
    }
}

//Blocks

export class DxfBlock extends DxfObject implements DxfBlockProps {

    handle: string = "";
    ownerHandle?: string | undefined;
    readonly subclassMarkerEntity = "AcDbEntity";
    layerName: string = "0";
    readonly subclassMarkerBlock = "AcDbBlockBegin";
    blockName: string = "";
    blockFlags: number = 0;
    basePoint: Vector3 = new Vector3();
    xrefPathName?: string | undefined;
    blockDescription?: string | undefined;

    constructor(init?: Partial<DxfBlock> | string) {
        super("BLOCK");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    addBlockFlag(flag: BlockTypeFlag): void {
        this.blockFlags |= flag;
    }

    // Method to check if a flag is set
    hasBlockFlag(flag: BlockTypeFlag): boolean {
        return (this.blockFlags & flag) !== 0;
    }

    // Method to remove a flag
    removeBlockTypeFlag(flag: BlockTypeFlag): void {
        this.blockFlags &= ~flag;
    }

    
    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfBlockProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfBlock {
        const dxfObject = new DxfBlock();
        let currentMap = DxfBlockProps.deserializationMap;

        for (const { code, value } of properties.entries()) {
            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (dxfObject as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(dxfObject, value);
                }
            }
        }

        return dxfObject;
    }
}

export class DxfEndBlock extends DxfObject implements DxfEndBlockProps {
    handle: string = "";
    xDictionary?: DxfExtensionDictionary | undefined;
    ownerHandleSoftPointer?: string | undefined;
    readonly subclassMarkerEntity = "AcDbEntity";
    layerName?: string | undefined;
    readonly subclassMarkerBlockEnd = "AcDbBlockEnd";

    constructor(init?: Partial<DxfEndBlock> | string) {
        super("ENDBLK");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfEndBlockProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfEndBlock {
        const dxfObject = new DxfEndBlock();
        let currentMap = DxfEndBlockProps.deserializationMap;

        for (const { code, value } of properties.entries()) {
            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (dxfObject as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(dxfObject, value);
                }
            }
        }

        return dxfObject;
    }
}

//Objects
export class DxfCommonObject extends DxfObject implements DxfCommonObjectProps {

    handle: string = "";
    xDictionary: DxfExtensionDictionary = {"ACAD_XDICTIONARY":[]};
    reactorsDictionary: DxfExtensionDictionary = {"ACAD_REACTORS":[]};
    ownerHandle?: string | undefined;

    addAcadReactor(reactor:string){
        this.reactorsDictionary["ACAD_REACTORS"].push(reactor);
    }
    addXDictionary(dictionaryHandle:string){
        this.xDictionary["ACAD_XDICTIONARY"].push(dictionaryHandle);
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();

        const fieldObjectData = this.serializeObjectProperty(
            DxfCommonObjectProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldObjectData);

        return serialized;
    }

    protected static processObjectProperties<T extends DxfCommonObject>(
        entity: T,
        properties: DxfProperties,
        ...map: DxfDeserializationMap<T>[]
    ): T {
        let currentMap = DxfCommonObjectProps.deserializationMap;
        let i = 0;
        for (const { code, value } of properties.entries()) {
            if (code === 100) {
                currentMap = map[i];
                i++;
            }

            const handler = currentMap[code];
            if (handler) {
                if (typeof handler === "string") {
                    (entity as any)[handler] = value;
                } else if (typeof handler === "function") {
                    handler(entity, value);
                }
            }
        }
        return entity;
    }
}

export class DxfDictionary extends DxfCommonObject implements DxfDictionaryProps {
    readonly subclassMarkerDictionary = "AcDbDictionary";
    hardOwnerFlag: boolean = true;
    duplicateRecordCloningFlag: number = 1;
    readonly entries: DxfDictionaryEntry[] = [];

    constructor(init?: Partial<DxfDictionary> | string) {
        super("DICTIONARY");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    addEntry(entryHandle:string, entryName:string){
        this.entries.push({entryHandle, entryName});
    }

    addDuplicateFlag(flag: DuplicateRecordCloningFlag): void {
        this.duplicateRecordCloningFlag |= flag;
    }

    // Method to check if a flag is set
    hasDuplicateFlag(flag: DuplicateRecordCloningFlag): boolean {
        return (this.duplicateRecordCloningFlag & flag) !== 0;
    }

    // Method to remove a flag
    removeDuplicateFlag(flag: DuplicateRecordCloningFlag): void {
        this.duplicateRecordCloningFlag &= ~flag;
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfDictionaryProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfDictionary {
        const dxfObject = new DxfDictionary();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfDictionaryProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfSortentsTable extends DxfCommonObject implements DxfSortentsTableProps{
    readonly subclassMarker = "AcDbSortentsTable";
    sortentsOwnerHandle: string = "";
    sortentOrder: string[] = [];

    constructor(init?: Partial<DxfSortentsTable> | string) {
        super("SORTENTSTABLE");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfSortentsTableProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfSortentsTable {
        const dxfObject = new DxfSortentsTable();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfSortentsTableProps.deserializationMap
        );
        return dxfObject;
    }
    
}

export class DxfImageDefinition
    extends DxfCommonObject
    implements DxfImageDefinitionProps
{
    readonly subclassMarkerImageDef = "AcDbRasterImageDef";
    classVersion: number = 0;
    fileName: string = "";
    imageSize: Vector2 = new Vector2();
    pixelSize: Vector2 = new Vector2();
    imageLoaded: boolean = true;
    resolutionUnits: number = 0;

    constructor(init?: Partial<DxfImageDefinition> | string) {
        super("IMAGEDEF");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfImageDefinitionProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfImageDefinition {
        const dxfObject = new DxfImageDefinition();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfImageDefinitionProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfImageDefReactor extends DxfCommonObject implements DxfImageDefReactorProps{
    readonly subclassMarkerImageDefReactor = "AcDbRasterImageDefReactor";
    classVersion: number = 2;
    associatedImageObjectId?: string | undefined;

    constructor(init?: Partial<DxfImageDefReactor> | string) {
        super("IMAGEDEF_REACTOR");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfImageDefReactorProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfImageDefReactor {
        const dxfObject = new DxfImageDefReactor();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfImageDefReactorProps.deserializationMap
        );
        return dxfObject;
    }
    
}

export class DxfRasterVariables extends DxfCommonObject implements DxfRasterVariablesProps{

    readonly subclassMarker = "AcDbRasterVariables";
    classVersion: number = 0;
    displayImageFrame: boolean = false;
    imageDisplayQuality: boolean = false;
    insertUnits: InsertUnitsForImage = InsertUnitsForImage.Foot;

    constructor(init?: Partial<DxfRasterVariables> | string) {
        super("RASTERVARIABLES");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfRasterVariablesProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfRasterVariables {
        const dxfObject = new DxfRasterVariables();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfRasterVariablesProps.deserializationMap
        );
        return dxfObject;
    }

    
}

export class DxfPlotSettings
    extends DxfCommonObject
    implements DxfPlotSettingsProps
{
    readonly subclassMarker = "AcDbPlotSettings";
    pageSetupName?: string | undefined = " ";
    printerName?: string | undefined = "none_device";
    paperSize?: string | undefined = " ";
    plotViewName?: string | undefined = " ";
    unprintableMargin?: DxfMargin | undefined;
    plotPaperSize?: Vector2 | undefined;
    plotOrigin?: Vector2 | undefined;
    plotWindowArea?: { lowerLeft: Vector2; upperRight: Vector2 } | undefined;
    customPrintScale?: { numerator: number; denominator: number } | undefined;
    plotLayoutFlags: number = 0;
    plotPaperUnits?: number | undefined;
    plotRotation?: number | undefined;
    plotType?: number | undefined;
    standardScaleType?: number | undefined;
    shadePlotMode?: number | undefined;
    shadePlotResolutionLevel?: number | undefined;
    shadePlotCustomDpi?: number | undefined;
    scaleFactor?: number | undefined;
    paperImageOrigin?: Vector2 | undefined;
    shadePlotHandle?: string | undefined;

    constructor(init?: Partial<DxfPlotSettings> | string) {
        super("PLOTSETTINGS");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfPlotSettingsProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfPlotSettings {
        const dxfObject = new DxfPlotSettings();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfPlotSettingsProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfGeoData extends DxfCommonObject implements DxfGeoDataProps {
    readonly subclassMarker = "AcDbGeoData";
    version: number = 2;
    coordinateType: number = 0;
    hostBlockTableRecord?: string | undefined;
    designPoint?: Vector3 | undefined;
    referencePoint?: Vector3 | undefined;
    northDirection?: Vector2 | undefined;
    horizontalUnitScale?: number | undefined;
    verticalUnitScale?: number | undefined;
    horizontalUnits?: number | undefined;
    verticalUnits?: number | undefined;
    upDirection?: Vector3 | undefined;
    scaleEstimationMethod?: number | undefined;
    seaLevelCorrection?: boolean | undefined;
    userScaleFactor?: number | undefined;
    seaLevelElevation?: number | undefined;
    projectionRadius?: number | undefined;
    coordinateSystemDefString?: string | undefined;
    geoRSSTag?: string | undefined;
    observationFromTag: string = " ";
    observationToTag: string = " ";
    observationCoverageTag: string = " ";
    geoMeshPointsCount?: number | undefined;
    sourceMeshPoints?: Vector2[] | undefined;
    destinationMeshPoints?: Vector2[] | undefined;
    facesCount?: number | undefined;
    faces?: number[][] | undefined;

    constructor(init?: Partial<DxfGeoData> | string) {
        super("GEODATA");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldData = this.serializeObjectProperty(
            DxfGeoDataProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldData);
        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfGeoData {
        const dxfObject = new DxfGeoData();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfGeoDataProps.deserializationMap
        );
        return dxfObject;
    }
}

export class DxfLayout
    extends DxfCommonObject
    implements DxfPlotSettingsProps, DxfLayoutProps
{
    readonly subclassMarker = "AcDbPlotSettings";
    pageSetupName?: string | undefined;
    printerName?: string | undefined;
    paperSize?: string | undefined;
    plotViewName?: string | undefined;
    unprintableMargin?: DxfMargin | undefined;
    plotPaperSize?: Vector2 | undefined;
    plotOrigin?: Vector2 | undefined;
    plotWindowArea?: { lowerLeft: Vector2; upperRight: Vector2 } | undefined;
    customPrintScale?: { numerator: number; denominator: number } | undefined;
    plotLayoutFlags: number = 0;
    plotPaperUnits?: number | undefined;
    plotRotation?: number | undefined;
    plotType?: number | undefined;
    standardScaleType?: number | undefined;
    shadePlotMode?: number | undefined;
    shadePlotResolutionLevel?: number | undefined;
    shadePlotCustomDpi?: number | undefined;
    scaleFactor?: number | undefined;
    paperImageOrigin?: Vector2 | undefined;
    shadePlotHandle?: string | undefined;

    readonly subclassMarkerLayout = "AcDbLayout";
    layoutName: string = "";
    flag: number = 1;
    tabOrder: number = 1;
    minimumLimits: Vector2 = new Vector2();
    maximumLimits: Vector2 = new Vector2();
    insertionBasePoint: Vector3 = new Vector3();
    minimumExtents: Vector3 = new Vector3();
    maximumExtents: Vector3 = new Vector3();
    elevation: number = 0;
    ucsOrigin: Vector3 = new Vector3();
    ucsXAxis: Vector3 = new Vector3(1, 0, 0);
    ucsYAxis: Vector3 = new Vector3(0, 1, 0);
    orthographicType: number = 0;
    associatedPaperSpaceBlockHandle?: string | undefined;
    lastActiveViewportHandle?: string | undefined;
    namedUcsHandle?: string | undefined;
    baseUcsHandle?: string | undefined;

    constructor(init?: Partial<DxfImageDefinition> | string) {
        super("LAYOUT");
        if (typeof init === "string") {
            this.handle = init;
        } else if (init !== undefined) {
            if (!init.handle) {
                this.handle = DxfHandle.handle();
            }
            Object.assign(this, init);
        }
    }

    override serialize(): DxfSerializedData[] {
        const serialized = super.serialize();
        const fieldplotData = this.serializeObjectProperty(
            DxfPlotSettingsProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldplotData);
        const fieldAttData = this.serializeObjectProperty(
            DxfLayoutProps.serializationMap
        );
        IterUtils.extendArray(serialized, fieldAttData);

        return serialized;
    }

    static override deserialize(properties: DxfProperties): DxfLayout {
        const dxfObject = new DxfLayout();
        super.processObjectProperties(
            dxfObject,
            properties,
            DxfPlotSettingsProps.deserializationMap,
            DxfLayoutProps.deserializationMap
        );
        return dxfObject;
    }
}
