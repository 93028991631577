import { ExecutionThreadPreference, IterUtils, JobExecutor, registerExecutor } from 'engine-utils-ts';
import { ContoursOrientation, Vector2 } from 'math-ts';
import type { Layout, Point, Sample, Tracker } from './regular-filler/solver';
import { solve as regularFillerSolver, trim_tracker } from './regular-filler/solver';
import type { MaximizeRowToRowArgs, MaximizeRowToRowResult, PolygonFitterArgs, PolygonFitterResult} from './CommonUtils';
import { find_total_value, prepare_polygons, trimSupportRoads, unionHRoads } from './CommonUtils';
import { unpackContoursForRoadsTrim } from '../roads/RoadsTrim';


 
export class RegularFillerExecutor extends JobExecutor<PolygonFitterArgs, PolygonFitterResult> {
    execute(args: PolygonFitterArgs): PolygonFitterResult {
        const { polygons, v_roads } = prepare_polygons(
            args.contours, 
            args.roads,
            args.support_roads, 
            args.global_support_roads, 
            args.support_roads_step, 
            args.support_road_offset, 
            args.max_tracker_width, 
            args.row_to_row
        );

        const layouts: Layout[] = [];
        let tan = args.angle;
        for (const polygon of polygons) {
            /*if (args.angle === undefined) {
                tan = find_best_tan(polygon);
            }*/

            const solverArgs = {
                contours: polygon,
                samples: args.samples,
                max_rank: args.column_height,
                tracker_width: args.max_tracker_width,
                row_to_row: args.row_to_row,
                row_to_line: args.equipment_road_offset,
                offset: args.ext,
                gap: args.gap,
                step: 1,
                tan: tan,
                min_block_value: args.min_block_value,
                max_block_value: args.max_block_value
            };

            IterUtils.extendArray(layouts, regularFillerSolver(solverArgs));
        }

        if (v_roads.length > 0 || args.roads.edges.length > 0) {
            const contours = args.contours.map(c => c.map(p => new Vector2(p.x, p.y)));
            const { contoursSegments, pointsInContourChecker } = 
                unpackContoursForRoadsTrim(contours, ContoursOrientation.ClockwiseOuter);
            const newVRoads = trimSupportRoads(v_roads, contoursSegments, pointsInContourChecker);
            unionHRoads(layouts, pointsInContourChecker);

            return { layouts, vRoads: newVRoads };
        } else {
            return { layouts, vRoads: v_roads };
        }
    }

    executionPreference(_data: PolygonFitterArgs): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
    }
}
registerExecutor(RegularFillerExecutor);


export class MaximizeRowToRowRegularExecutor extends JobExecutor<MaximizeRowToRowArgs, MaximizeRowToRowResult> {
    execute(args: MaximizeRowToRowArgs): MaximizeRowToRowResult {
        const { layouts, v_roads, row_to_row } = maximizeRowToRow(args);
        
        if (v_roads.length > 0 || args.roads.edges.length > 0) {
            const contours = args.contours.map(c => c.map(p => new Vector2(p.x, p.y)));
            const { contoursSegments, pointsInContourChecker } = 
                unpackContoursForRoadsTrim(contours, ContoursOrientation.ClockwiseOuter);
            const newVRoads = trimSupportRoads(v_roads, contoursSegments, pointsInContourChecker);
            unionHRoads(layouts, pointsInContourChecker);
            return { layouts, vRoads: newVRoads, row_to_row };
        } else {
            return { layouts, vRoads: [], row_to_row };
        }
    }

    executionPreference(_data: MaximizeRowToRowArgs): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
    }
}
registerExecutor(MaximizeRowToRowRegularExecutor);


function maximizeRowToRow(args: MaximizeRowToRowArgs, n: number = 10): { 
    layouts: Layout[], v_roads: [Point, Point][], row_to_row: number 
} {
    let polygons_v_roads = prepare_polygons(
        args.contours, 
        args.roads,
        args.support_roads, 
        args.global_support_roads, 
        args.road_step, 
        args.support_road_offset, 
        args.max_tracker_width, 
        args.min_row_to_row
    );
    let v_roads = polygons_v_roads.v_roads;

    let layouts: Layout[] = [];
    let tan = args.angle;
    for (const polygon of polygons_v_roads.polygons) {
        /*if (args.angle === undefined) {
            tan = find_best_tan(polygon);
        }*/

        const solverArgs = {
            contours: polygon,
            samples: args.samples,
            max_rank: args.column_height,
            tracker_width: args.max_tracker_width,
            row_to_row: args.min_row_to_row,
            row_to_line: args.equipment_road_offset,
            offset: args.ext,
            gap: args.gap,
            step: 1,
            tan: tan,
            min_block_value: args.min_block_value,
            max_block_value: args.max_block_value
        };

        IterUtils.extendArray(layouts, regularFillerSolver(solverArgs));
    }

    const total_value = find_total_value(layouts, args.samples);
    if (total_value < args.min_total_value) {
        return { layouts: layouts, v_roads: polygons_v_roads.v_roads, row_to_row: args.min_row_to_row };
    }
    let cntr = 1, lb = args.min_row_to_row, ub = 2 * args.min_row_to_row;
    for (; cntr < n; ++cntr) {
        if (args.support_roads && !args.global_support_roads) {
            polygons_v_roads = prepare_polygons(
                args.contours, 
                args.roads,
                args.support_roads, 
                args.global_support_roads, 
                args.road_step, 
                args.support_road_offset, 
                args.max_tracker_width, 
                ub
            );
        }
        const new_layouts: Layout[] = [];
        for (const polygon of polygons_v_roads.polygons) {
            /*if (args.angle === undefined) {
                tan = find_best_tan(polygon);
            }*/
    
            const solverArgs = {
                contours: polygon,
                samples: args.samples,
                max_rank: args.column_height,
                tracker_width: args.max_tracker_width,
                row_to_row: ub,
                row_to_line: args.equipment_road_offset,
                offset: args.ext,
                gap: args.gap,
                step: 1,
                tan: tan,
                min_block_value: args.min_block_value,
                max_block_value: args.max_block_value
            };
    
            IterUtils.extendArray(new_layouts, regularFillerSolver(solverArgs));
        }
        const total_value = find_total_value(new_layouts, args.samples);
        if (total_value < args.min_total_value) {
            break;
        } else {
            layouts = new_layouts;
            v_roads = polygons_v_roads.v_roads;
            lb = ub;
            ub *= 2;
        }
    }
    for (; cntr < n; ++cntr) {
        const mid = (lb + ub) / 2;
        if (args.support_roads && !args.global_support_roads) {
            polygons_v_roads = prepare_polygons(
                args.contours, 
                args.roads,
                args.support_roads, 
                args.global_support_roads, 
                args.road_step, 
                args.support_road_offset, 
                args.max_tracker_width, 
                mid
            );
        }
        const new_layouts: Layout[] = [];
        for (const polygon of polygons_v_roads.polygons) {
            /*if (args.angle === undefined) {
                tan = find_best_tan(polygon);
            }*/
    
            const solverArgs = {
                contours: polygon,
                samples: args.samples,
                max_rank: args.column_height,
                tracker_width: args.max_tracker_width,
                row_to_row: mid,
                row_to_line: args.equipment_road_offset,
                offset: args.ext,
                gap: args.gap,
                step: 1,
                tan: tan,
                min_block_value: args.min_block_value,
                max_block_value: args.max_block_value
            };
    
            IterUtils.extendArray(new_layouts, regularFillerSolver(solverArgs));
        }
        const total_value = find_total_value(new_layouts, args.samples);
        if (total_value >= args.min_total_value) {
            layouts = new_layouts;
            v_roads = polygons_v_roads.v_roads;
            lb = mid;
        } else {
            ub = mid;
        }
    }
    return { layouts: layouts, v_roads: v_roads, row_to_row: lb };
}

export function trimTrackerRegular(
    contour: Point[], tracker: Tracker, width: number, samples: Sample[]
) {
    return trim_tracker(contour, tracker, width, samples);
}