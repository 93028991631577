import type { Bim,
    GaugePack, IdBimGeo, IdBimScene, MathSolversApi, NumberProperty, RepresentationBase,
    SceneInstance, SceneInstancePatch} from 'bim-ts';
import {
    DC_CNSTS,
    FixedTiltTypeIdent,
    RoadTypeIdent, TrackerTypeIdent} from 'bim-ts';
import {
    BasicAnalyticalRepresentation, BimProperty, BoundaryType, PolylineGeometry, PropertiesCollection, SceneInstances
} from 'bim-ts';
import { type Aabb, Aabb2} from 'math-ts';
import { Clipper, Matrix4, PolygonUtils, Transform, Vector2, Vector3 } from 'math-ts';

import { convertRoads } from '../farm-layout/LayoutService';
import {
    calcLength, equalPoints, getTransformedBBox2dAndCenterOfInstanceWithoutZRotation, getTransformedBBox2AndCenter, isEqual, isPointInside, mergeEdges, orderContour, orderHoles
} from '../LayoutUtils';
import { PointsSet } from '../PointsSet';

import type { CableDef } from '../trenches/TrenchService';
import type { LongTask, Result, TasksRunner} from 'engine-utils-ts';
import { DefaultMap, DefaultRgbaPalette, Failure, IterUtils, LogLevel, ObjectUtils, PollablePromise, RgbaPalette, ScopedLogger, Success, Yield } from 'engine-utils-ts';
import type { GroupedNotificationGenerator, UiBindings } from 'ui-bindings';
import { NotificationDescription, NotificationType } from 'ui-bindings';
import { createDebugBoxes, createDebugPolylines } from '../DebugUtils';
import { notificationSource } from '../Notifications';
import type { AcRouterNewRequest, AcRouterNewResponse, AcRouterRequest, AcRouterResponse, AcWiringGridRequest, AcWiringResponse, CableData, Circuit, Edge, InvertorACWiringData } from './MvWiringSolverTypes';
import type { Point } from '../farm-layout/LayoutSolversTypes';
import { calculateTrackerDimensions } from '../TrackerCommon';

export enum AcWiringSolver{
    acWiringGrid = 'grid',
    acRouterNew = 'router-new',
}

export interface AcWiringConfig {
    boundariesIds: IdBimScene[];
    substationIds: IdBimScene[];
    cabinetInstance: SceneInstance;
    wiresIds: number[];
    acWiringSolver: AcWiringSolver;
    maxPowerMvCircuitVA: number;
    maxVoltageDropPercent: number;
    temperature: NumberProperty,
    generateLvWires: boolean;
    lowVoltageCables: [number, CableDef][];
    aboveGround: boolean;
    wire_cost: number,
    trench_cost: number;
}

export interface AcWiringInput {
    generateLvWires: boolean;
    lowVoltageCables: [number, CableDef][];
    boxes: Vector2[][][];
    excludeBoundaries: Vector2[][];
    transformers: TransformerData[];
    substations: SubstationData[];
    maxVoltageDropPercent: number;
    maxPowerMvCircuitVA: number;
    cabinet: SceneInstance;
    wires: WireData[];
    offset: number;
    acWiringSolver: AcWiringSolver;
    max_length: number;
    roads: Vector2[][];
    aboveGround: boolean;
    wire_cost: number,
    trench_cost: number;
}

export interface WireDef {
    id: IdBimScene;
    parent: IdBimScene | 0;
    sourceId: number;
    polyline: Vector3[];
    current: number;
    power: number;
    circuit: number;
}

export interface WireData {
    id: number;
    label: string;
    material:string;
    size:string;
    temperature:number;
    current: number;
    resistivityOmMeter: number;
}

export interface TransformerData {
    id: IdBimScene;
    current: number;
    power: number;
    position: Vector2;
}

export interface SubstationData {
    id: IdBimScene;
    position: Vector2;
    elevation: number;
    bbox: Vector2[];
    operatingVoltage: number;
}

export const wiringNotificationGroup = "Generating Wirings";

export async function placeCablesOnFarmLayout(
    rawInput: Readonly<AcWiringConfig>,
    bim: Bim,
    mathSolversApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    gaugePack: GaugePack,
    notificationGroup: GroupedNotificationGenerator
) {
    const logger = new ScopedLogger('wiring-service', LogLevel.Default);

    await placeCablesOnFarmLayoutWithData(tasksRunner, rawInput, gaugePack, mathSolversApi, bim, uiBindings, logger, notificationGroup);
}

const SolarArrayTypes = [TrackerTypeIdent, FixedTiltTypeIdent, "any-tracker"];

async function placeCablesOnFarmLayoutWithData(
    tasksRunner: TasksRunner,
    rawInput: Readonly<AcWiringConfig>,
    gaugePack: GaugePack,
    mathSolverApi: MathSolversApi,
    bim: Bim,
    uiBindings: UiBindings,
    logger:ScopedLogger,
    notificationGroup: GroupedNotificationGenerator
) {

    const TIMEOUT = 900_000;
    try {
        const task: LongTask<any> = tasksRunner.newLongTask<any>({
            defaultGenerator: _arrangeWiring(
                rawInput,
                gaugePack,
                bim,
                mathSolverApi,
                tasksRunner,
                uiBindings,
                logger,
                notificationGroup
            ),
            taskTimeoutMs: TIMEOUT
        });
        
        uiBindings.addNotification(
            notificationGroup.addRootNotification(
                NotificationDescription.newWithTask({
                    source: notificationSource,
                    key: 'generateWirings',
                    taskDescription: {
                        task,
                        resultHeaderGetter: (result) => result instanceof Success ?  'Generating wirings done' : 'Wirings generation failed'
                    },
                    type: NotificationType.Info,
                    addToNotificationsLog: true
                })
            )
        );
        await task.asPromise();
    } catch (e) {
        logger.error(e);
    }
}


export function* convertAcWiringInput(
    rawInput: Readonly<AcWiringConfig>,
    bim: Bim,
    gaugePack: GaugePack,
    logger: ScopedLogger,
    uiBindings: UiBindings,
    notificationGroup: GroupedNotificationGenerator,
): Generator<Yield, AcWiringInput, unknown> {
    yield Yield.Asap;
    const wires: WireData[] = [];
    let max_length: number = 0;
    const inputBoundariesIds = new Set(rawInput.boundariesIds);
    const excludeBoundaries = IterUtils.filterMap(bim.extractBoundaries(), b => {
        if(b.boundaryType !== BoundaryType.Exclude 
            || !inputBoundariesIds.has(b.bimObjectId)){
            return;
        }
        const contour = PolygonUtils.simplifyContour(b.pointsWorldSpace);
        if(contour.length < 3){
            return;
        }
        return orderContour(contour);
    });

    for (const id of rawInput.wiresIds) {
        const gauge = gaugePack.gauges.get(id);
        if(!gauge){
            logger.error('Wire with id not found', id);
            continue;
        }
        const current = gauge.approxAmpacityByTemperature(rawInput.temperature, !rawInput.aboveGround)?.as('A');
        const resistivityOmMeter = gauge.approxAcResistivityByTemperature(rawInput.temperature)?.as('Om/m');
        if (current === undefined || resistivityOmMeter === undefined) {
            logger.error("can't approximate values", [
                "current:",
                current,
                "resistivityOmMeter:",
                resistivityOmMeter,
            ]);
            continue;
        }
        wires.push({
            id: id,
            current,
            label: gauge.getFullName(),
            material:gauge.material,
            size: gauge.name,
            temperature: rawInput.temperature.as('C'),
            resistivityOmMeter,
        });
    }

    const goemetriesAabbs = bim.allBimGeometries.aabbs.poll();
    const reprsBboxes = new DefaultMap<RepresentationBase, Aabb>(r => r.aabb(goemetriesAabbs));
    
    yield Yield.Asap;
    let roadsWidth: number | undefined = 0;
    const roads:Vector2[][] = [];
    for (const [i, road] of bim.instances.peekByTypeIdent(RoadTypeIdent).entries()) {
        const roadsEdges = convertRoads([road], bim); 
        for (const edge of roadsEdges.edges) {
            const stP = roadsEdges.points[edge.fst];
            const enP = roadsEdges.points[edge.snd];
            const dir = new Vector2(enP.x - stP.x, enP.y - stP.y);
            if(dir.length() < 1e-3){
                continue;
            }
            const norm = new Vector2(dir.y, -dir.x).normalize().multiplyScalar(edge.width*0.5);
            const p1 = new Vector2(stP.x, stP.y).add(norm);
            const p2 = p1.clone().add(dir);
            const p3 = p2.clone().add(norm.clone().negate().multiplyScalar(2));
            const p4 = p3.clone().add(dir.clone().negate());
    
            const box:Vector2[]=[p1, p2, p3, p4];
            roads.push(box);
    
            if (roadsWidth < edge.width) {
                roadsWidth = edge.width;
            }
        }
        if(i % 1000 === 0) {
            yield Yield.Asap;
        }
    }
    yield Yield.Asap;

    const substationsMap = new Map<IdBimScene, SubstationData>();
    const transformers: TransformerData[] = [];
    const boxes: Vector2[][][] = [];
    let max_width_trackers: number = 0;
    for (const substationId of rawInput.substationIds) {
        const substationInst = bim.instances.peekById(substationId);
        if (substationInst && substationInst.representation) {
            const aabb = reprsBboxes.getOrCreate(substationInst.representation);

            const [bbox, position] = getTransformedBBox2AndCenter(aabb, substationInst);
            const operating_voltage = substationInst.properties.get("circuit | aggregated_capacity | operating_voltage")?.as('V')!;
            if(!operating_voltage || operating_voltage === 0){
                logger.error('operating_voltage is not calculated', [substationId, substationInst]);
                throw new Error('operating_voltage is not calculated');
            }
            substationsMap.set(substationId, {
                id: substationId,
                position: position.xy(),
                bbox,
                elevation: position.z,
                operatingVoltage: operating_voltage,
            });
        }

        const substationTrackers: Vector2[][] = [];
        const substationChildren = bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf({ids: [substationId]});
        for (const childId of substationChildren) {
            const instance = bim.instances.peekById(childId);

            if (instance?.type_identifier === "transformer" && instance.representation) {
                const current = instance.properties.get("circuit | block_capacity | output_max_current")?.as("A")!;
                const power = instance.properties.get("circuit | block_capacity | ac_power")?.as("W")!;
                const length = instance.properties.get("dimensions | length")?.as("m")!;
                const width = instance.properties.get("dimensions | width")?.as("m")!;
                max_length = Math.max(max_length, length, width);

                const aabb = reprsBboxes.getOrCreate(instance.representation);
                const position = getBBoxCenterOfInstance(instance, aabb);
                transformers.push({
                    id: childId,
                    current,
                    position,
                    power
                });
            }
            if (instance?.type_identifier === "inverter" || instance?.type_identifier === "combiner-box") {
                const length = instance.properties.get("dimensions | length")?.as("m")!;
                const width = instance.properties.get("dimensions | width")?.as("m")!;
                max_length = Math.max(max_length, length, width);
            }

            if(!instance?.representation){
                continue;
            }
            if(!SolarArrayTypes.includes(instance.type_identifier)){
                continue;
            }
    
            const aabb = reprsBboxes.getOrCreate(instance.representation);
            const [box] = getTransformedBBox2dAndCenterOfInstanceWithoutZRotation(instance, aabb);
            const dimensions = calculateTrackerDimensions(instance);

            max_width_trackers = Math.max(max_width_trackers, dimensions.width);

            substationTrackers.push(box);
            if(substationTrackers.length % 1000 === 0){
                yield Yield.NextFrame;
            }
        }
        boxes.push(substationTrackers);

        yield Yield.Asap;
    }
    const sameTransformersPositions: IdBimScene[] = [];
    const positionsSet = new PointsSet();

    const idsMap = new Map<number, IdBimScene>();
    const resultTransformers: TransformerData[] = [];
    for (const transformer of transformers) {
        const idx = positionsSet.Idx(transformer.position);
        if(idsMap.has(idx)){
            const sameTransformerPos = idsMap.get(idx)!;
            sameTransformersPositions.push(sameTransformerPos);
            sameTransformersPositions.push(transformer.id);
        } else {
            resultTransformers.push(transformer);
            idsMap.set(idx, transformer.id);
        }
    }

    if(sameTransformersPositions.length > 0){
        uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newWithActionToStart({
                    type: NotificationType.Warning,
                    source: notificationSource,
                    key: 'sameTransformersPositions',
                    descriptionArg: sameTransformersPositions,
                    removeAfterMs: 10_000,
                    addToNotificationsLog: true,
                    actionDescription: {
                        name: 'Select',
                        actionArgs: [sameTransformersPositions],
                        action: (ids) => bim.instances.setSelected(ids)
                    }
                })
            )
        );
    }


    const input: AcWiringInput = {
        generateLvWires: rawInput.generateLvWires,
        lowVoltageCables: rawInput.lowVoltageCables,
        transformers: resultTransformers,
        substations: Array.from(substationsMap.values()),
        boxes,
        //Note: One meter offset is min distance between trackers
        offset: Math.max(max_width_trackers + 1, 2),
        wires,
        cabinet: rawInput.cabinetInstance,
        acWiringSolver: rawInput.acWiringSolver,
        max_length,
        excludeBoundaries: excludeBoundaries,
        maxVoltageDropPercent: rawInput.maxVoltageDropPercent,
        roads,
        maxPowerMvCircuitVA: rawInput.maxPowerMvCircuitVA,
        aboveGround: rawInput.aboveGround,
        trench_cost: rawInput.trench_cost,
        wire_cost: rawInput.wire_cost,
    };
    return input;
}

function getBBoxCenterOfInstance(instance: SceneInstance, aabb: Aabb): Vector2 {
    let center = aabb.getCenter_t().applyMatrix4(instance.worldMatrix);
    return new Vector2(center.x, center.y);
}

export interface AcWiringResult{
    wires: WireDef[],
    mergedPolygons: Point[][],
    scs: ScDef[],
    transformers: TransformerDef[],
    paths: RawEdge[][][],
    edges: EdgeTypeWithPoint[],
    boxes: Point[][]
}

export interface RawEdge{
    path:[Point, Point],
    indeces:[number, number]
}
export type EdgeTypeWithPoint = {path:[Point, Point], indeces:[number, number]}&EdgeType;

async function callAcWiringService(input: AcWiringInput, bim:Bim, mathSolverApi: MathSolversApi, tasksRunner:TasksRunner, uiBindings: UiBindings, logger: ScopedLogger, notificationGroup: GroupedNotificationGenerator): Promise<AcWiringResult> {
    const pointsSet = new PointsSet();
    const domain: number[][] = [];
    logger.debug('Start merge polygons');

    const task = tasksRunner.newLongTask({
        defaultGenerator: mergePolygons(input),
        taskTimeoutMs: 300_000,
    });
    const [mergedPolygons, _] = await task.asPromise();

    logger.debug('End merge polygons');
    const orderedContours = orderHoles(mergedPolygons);
    for (const contour of orderedContours) {
        const ids = pointsSet.IdxArray(contour);
        domain.push(ids);
    }
    const inverters: InvertorACWiringData[] = [];
    const substations: number[] = [];
    for (const transformer of input.transformers) {
        const idx = pointsSet.Idx(transformer.position);
        inverters.push({
            position: idx,
            current: transformer.current,
        });
    }
    for (const substation of input.substations) {
        const idx = pointsSet.Idx(substation.position);
        substations.push(idx);
    }
    const orderedWires = input.wires
        .slice()
        .sort((a, b) =>
            a.current > b.current ? 1 : b.current > a.current ? -1 : 0
        );
    const operating_voltage = input.substations[0].operatingVoltage;

    const circuit_capacity = input.maxPowerMvCircuitVA / (operating_voltage * 1.732);
    const max_wire_resistivity = Math.max(...orderedWires.map((w) => w.resistivityOmMeter));
    const points = pointsSet.ToArray();
    const request: AcRouterRequest | AcWiringGridRequest = {
        points,
        domain,
        circuit_capacity: circuit_capacity,
        inverters,
        substations,
        progress_time_limit: 20,
        total_time_limit: 110,
        cabels:
            input.acWiringSolver === 'grid'
                ? orderedWires.map((w) => ({
                      capacity: w.current,
                      resistance: w.resistivityOmMeter,
                      cost: max_wire_resistivity/w.resistivityOmMeter,
                  }))
                : [],
    };

    const response = await callAcWiringAlgo({
        mathSolverApi,
        points,
        domain,
        circuit_capacity: circuit_capacity,
        inverters,
        substations,
        progress_time_limit: 20,
        total_time_limit: 110,
        cabels: orderedWires.map((w) => ({
            capacity: w.current,
            resistance: w.resistivityOmMeter,
            cost: max_wire_resistivity / w.resistivityOmMeter,
        })),
        algo: input.acWiringSolver,
        trench_cost: input.trench_cost,
        wire_cost: input.wire_cost,
    });

    const [wires, scs, invertersDefs, edges] = convertAcEquipment(
        request,
        response,
        bim,
        orderedWires,
        input
    );
    const set = new Set(invertersDefs.map((i) => i.id));
    const ids = input.transformers.filter((t) => !set.has(t.id)).map(t => t.id);
    if (ids.length > 0) {
        uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newWithActionToStart({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key: 'isolatedTransformers',
                    descriptionArg: ids.join(', '),
                    removeAfterMs: 10_000,
                    addToNotificationsLog: true,
                    actionDescription: {
                        name: 'Select',
                        actionArgs: [ids],
                        action: (ids) => bim.instances.setSelected(ids)
                    }
                })
            )
        );
        bim.instances.setSelected(ids);
    }

    return  {
        wires,
        mergedPolygons,
        scs,
        transformers: invertersDefs,
        paths:response.circuits.map(c=>c.paths.map(p=>p.map(e=>{return{path: [response.points[e.fst], response.points[e.snd] ], indeces:[e.fst, e.snd]}}))),
        edges:edges.map(e=>{return{
            ...e,
            path:[response.points[e.fst], response.points[e.snd]],
            indeces:[e.fst, e.snd]
        }}),
        boxes:mergedPolygons
    };
}

async function callAcWiringAlgo(args: {
    mathSolverApi: MathSolversApi,
    algo: AcWiringSolver,
    points: Point[];
    domain: number[][];
    inverters: InvertorACWiringData[];
    circuit_capacity: number;
    substations: number[];
    total_time_limit: number;
    progress_time_limit: number;
    cabels: CableData[];
    trench_cost: number;
    wire_cost: number;
}): Promise<AcWiringResponse> {
    if (args.algo === AcWiringSolver.acWiringGrid) {
        return await args.mathSolverApi.callSolver<AcWiringGridRequest, AcWiringResponse>({
            solverName: 'ac-wiring-grid',
            solverType: 'multi',
            request:{
                points: args.points,
                cabels: args.cabels,
                circuit_capacity: args.circuit_capacity,
                domain: args.domain,
                inverters: args.inverters,
                progress_time_limit: args.progress_time_limit,
                substations: args.substations,
                total_time_limit: args.total_time_limit,
        }});
    } else if (args.algo === AcWiringSolver.acRouterNew) {
        const request: AcRouterNewRequest = {
            points: args.points.map((p) => [p.x, p.y]),
            circuit_capacity: args.circuit_capacity,
            holes: args.domain,
            inverters: args.inverters.map((i) => ({
                position: i.position,
                value: i.current,
            })),
            substation: args.substations[0],
            trench_cost: args.trench_cost,
            wire_cost: args.wire_cost,
        };
        const response = await args.mathSolverApi.callSolver<AcRouterNewRequest, AcRouterNewResponse>({
            solverName: 'ac-router-new',
            solverType: 'multi',
            request,
        });
        return convertRouterResponse(response, request);
    } else {
        throw new Error("unrecognized wiring algorithm : ", args.algo);
    }
}

function convertRouterResponse(
    response: AcRouterResponse | AcRouterNewResponse,
    request: AcRouterRequest | AcRouterNewRequest
): AcWiringResponse {
    const circuits: Circuit[] = [];
    if(response.circuits){
        for (const circuit of response.circuits) {
            const paths: Edge[][] = [];
            for (const path of circuit.paths) {
                const edges: Edge[] = [];
                for (let i = 0; i < path.length - 1; i++) {
                    const fst = path[i];
                    const snd = path[i + 1];
                    edges.push({ fst, snd, cabels: undefined });
                }
                paths.push(edges);
            }
            circuits.push({ paths, inverters: circuit.inverters });
        }
    }
    const points: Point[] = [];
    for (const p of request.points) {
        if (Array.isArray(p)) {
            points.push({ x: p[0], y: p[1] });
        } else {
            points.push(p);
        }
    }
    return {
        points,
        circuits,
    };
}

function getWireIds(
    wires: WireData[],
    polyline: Vector3[],
    current: number,
    power: number,
    max_voltage_drop: number,
    cables:number[]|undefined
): IdBimScene[] {
    if(cables&&cables.length>0){
        return cables.map(cablesIndex=>(wires[cablesIndex].id));
    }
    let wireData: WireData = wires[wires.length - 1];
    const length = calcLength(polyline);

    for (const wire of wires) {
        const losses = 3 * Math.pow(current, 2) * length * wire.resistivityOmMeter;
        const voltage_drop = (losses * 100) / power;
        if (wire.current >= current && voltage_drop <= max_voltage_drop) {
            wireData = wire;
            break;
        }
    }

    return [wireData.id];
}

function* _arrangeWiring(
    rawInput: Readonly<AcWiringConfig>,
    gaugePack: GaugePack,
    bim: Bim,
    mathSolverApi: MathSolversApi,
    tasksRunner: TasksRunner,
    uiBindings: UiBindings,
    logger: ScopedLogger,
    notificationGroup: GroupedNotificationGenerator
) {

    deleteWiresByParents(
        rawInput.substationIds,
        bim,
        ["wire", "trench", "sectionalizing-cabinet"],
    );
    //recalculate properties of instances
    yield* bim.runBasicUpdatesTillCompletion({ forceRun: false});
    
    const input = yield* convertAcWiringInput(rawInput, bim, gaugePack, logger, uiBindings, notificationGroup);
    if(input.transformers.length === 0){
        uiBindings.addNotification(
            notificationGroup.addNotification(
                NotificationDescription.newBasic({
                    type: NotificationType.Error,
                    source: notificationSource,
                    key: 'noFeasibleOptions',
                    removeAfterMs: 5_000,
                    addToNotificationsLog: true
                })
            )
        );
        return;
    };

    yield Yield.Asap;
    const pr = callAcWiringService(
        input,
        bim,
        mathSolverApi,
        tasksRunner,
        uiBindings,
        logger,
        notificationGroup
    );
    logger.debug(`wiring solver is starting`, input);
    const debug = false;
    const substationIds = input.substations.map((s) => s.id);
    const substationInstances = bim.instances.peekByIds(substationIds);
    if (substationInstances.size > 0) {
        const patternPath = ["circuit", "mv_wiring", "max_voltage_drop"];
        bim.instances.applyPatchTo(
            {
                properties: [
                    [
                        BimProperty.MergedPath(patternPath),
                        {
                            path: patternPath,
                            value: input.maxVoltageDropPercent,
                            unit: "%",
                            readonly: true,
                        },
                    ],
                ],
            },
            substationIds
        );
    }

    const layoutResult: Result<AcWiringResult> =
        yield* PollablePromise.generatorWaitFor<AcWiringResult>(pr);
    logger.debug(`wiring solver finished`);

    if (layoutResult instanceof Failure) {
        throw new Error(`layout fetch error` + layoutResult.toString());
    }

    const acWiringResult = layoutResult.value;

    if (debug) {
        const task: LongTask<[Point[][], Point[][]]> = tasksRunner.newLongTask<
            [Point[][], Point[][]]
        >({
            defaultGenerator: mergePolygons(input),
            taskTimeoutMs: 300_000,
        });
        const mergedPolygons: Result<[Point[][], Point[][]]> =
            yield* PollablePromise.generatorWaitFor<[Point[][], Point[][]]>(
                task.asPromise()
            );
        if (mergedPolygons instanceof Success) {
            const polygons = mergedPolygons.value;
            createDebugBoxes({
                boxes: polygons[0],
                hulls: polygons[1],
                bim,
                mainParentId: input.substations[0].id,
            });
    
            for (const transformer of [
                ...input.transformers,
                ...input.substations,
            ]) {
                if (
                    polygons[0].some((p) => isPointInside(transformer.position, p))
                ) {
                    console.log("transformer id: " + transformer.id);
                }
            }
            createDebugPolylines(acWiringResult, bim, input.substations[0].id);
        }
    }

    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
    const polylinesToAlloc: [IdBimGeo, Partial<PolylineGeometry>][] = [];
    const patches: [IdBimScene, SceneInstancePatch][] = [];

    const instances = [...acWiringResult.transformers, ...input.substations];
    const objectsPositions = new Map<IdBimScene, Matrix4>();
    for (const instance of instances) {
        const mtrx = bim.instances.peekById(instance.id)?.worldMatrix;
        if (mtrx) {
            objectsPositions.set(instance.id, mtrx);
        }
    }
    const palette = new RgbaPalette(DefaultRgbaPalette.slice());
    let reusableMatrix1 = new Matrix4();
    function addClonedSceneInstanceWith(wire: WireDef): IdBimScene {
        const wireData = input.wires.find((w) => w.id === wire.sourceId);
        if (wireData === undefined) {
            throw new Error(`unexpected absence of wire ${wire.sourceId}`);
        }

        const mainParentMatrix = wire.parent
            ? bim.instances.peekById(wire.parent)?.worldMatrix ??
              objectsPositions.get(wire.parent)
            : undefined;
        const parentGlobalPos = mainParentMatrix
            ? Transform.fromMatrix(mainParentMatrix).position
            : new Vector3();

        const points = wire.polyline.map(
            (p) => new Vector3(p.x, p.y, parentGlobalPos.z)
        );
        const objectWorldMatrix = new Matrix4().setPositionV(points[0]);
        objectsPositions.set(wire.id, objectWorldMatrix);

        const inverseWorldMatrix =
            reusableMatrix1.getInverse(objectWorldMatrix);
        const localPoints = points.map((p) =>
            p.clone().applyMatrix4(inverseWorldMatrix)
        );
        const polylineGeometry: Partial<PolylineGeometry> =
            PolylineGeometry.newWithAutoIdsFiltered(localPoints, 0.5);

        const newGeoId = bim.polylineGeometries.reserveNewId();
        polylinesToAlloc.push([newGeoId, polylineGeometry]);

        const localTransform = new Transform();
        let bimParentId: IdBimScene | undefined = undefined;
        localTransform.setFromMatrix4(objectWorldMatrix);

        const properties = new PropertiesCollection([
            new BimProperty({
                path: ["commercial", "material"],
                value: wireData.material,
            }),
            new BimProperty({
                path: ["commercial", "asset"],
                value: wireData.id,
                description: "asset=mv-wire-spec=",
                readonly: true,
            }),
            new BimProperty({
                path: ["commercial", "size"],
                value: wireData.size,
            }),
            new BimProperty({
                path: ["commercial", "temperature"],
                value: wireData.temperature,
                unit: "C",
            }),
            new BimProperty({
                path: ["commercial", "resistivity"],
                value: wireData.resistivityOmMeter * 1e3,
                unit: "Om/km",
            }),
            new BimProperty({
                path: ["commercial", "current"],
                value: wireData.current,
                unit: "A",
            }),
            new BimProperty({
                path: ["cost_bs", "level 1"],
                value: "ELECTRICAL SUBTOTAL",
            }),
            new BimProperty({ path: ["cost_bs", "level 2"], value: "AC" }),
            new BimProperty({
                path: ["computed_result", "circuit_index"],
                value: wire.circuit + 1,
                numeric_step: 1,
                readonly: true,
            }),
        ]);

        const state: Partial<SceneInstance> = {
            type_identifier: "wire",
            representationAnalytical: new BasicAnalyticalRepresentation(
                newGeoId
            ),
            spatialParentId: bimParentId,
            localTransform,
            colorTint: palette.get(wire.circuit + 1),
            properties,
        };

        sceneInstancesToAlloc.push([wire.id, state]);

        return wire.id;
    }


    function addClonedSceneInstanceWithScs(sc: ScDef): IdBimScene {
        const stateSource = sc.sourceInstance;
        if (stateSource === undefined) {
            throw new Error(
                `unexpected absence of bim instance ${sc.sourceInstance}`
            );
        }

        const newId = sc.id;
        const objectWorldMatrix = new Matrix4().setPositionV(sc.vector3);
        objectsPositions.set(newId, objectWorldMatrix);

        let bimParentId: IdBimScene | undefined = undefined;
        const localTransform = Transform.fromMatrix(objectWorldMatrix);

        const properties = stateSource.properties.clone();

        const state: Partial<SceneInstance> = {
            flags: stateSource.flags,

            type_identifier: stateSource.type_identifier,
            name: stateSource.name,
            properties,

            spatialParentId: bimParentId,

            localTransform,
        };

        sceneInstancesToAlloc.push([newId, state]);

        return newId;
    }

    for (const obj of acWiringResult.scs) {
        addClonedSceneInstanceWithScs(obj);
    }

    for (const obj of acWiringResult.wires) {
        addClonedSceneInstanceWith(obj);
    }

    const objsByType = [
        acWiringResult.transformers,
        acWiringResult.scs,
        acWiringResult.wires,
    ];
    for (const objs of objsByType) {
        for (const obj of objs) {
            patches.push([
                obj.id,
                {
                    spatialParentId: obj.parent ? obj.parent : undefined,
                    localTransform:
                        SceneInstances.getLocalTransformRelativeToParentMatrix(
                            obj.parent
                                ? objectsPositions.get(obj.parent)
                                : undefined,
                            objectsPositions.get(obj.id)!
                        ),
                },
            ]);
        }
    }

    yield Yield.NextFrame;

    const ALLOCATION_SIZE = 3000;
    logger.debug(`count of wires to allocate - ${polylinesToAlloc.length}`);
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        polylinesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.polylineGeometries.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }
    logger.debug(
        `count of instances to allocate - ${sceneInstancesToAlloc.length}`
    );
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        sceneInstancesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    bim.instances.applyPatches(patches);

    logger.debug("allocating wires finished");
}



function* mergePolygons(input:AcWiringInput): Generator<Yield, [Vector2[][], Vector2[][]]> {
    const OFFSET_ITEMS_COUNT = 2500;
    yield Yield.Asap;
    const hulls:Vector2[][] =[];
    for (const chunk of IterUtils.splitArrayIntoChunks(input.boxes, OFFSET_ITEMS_COUNT)) {
        for (const boxes of chunk) {
            IterUtils.extendArray(hulls, boxes);
        }
        yield Yield.Asap;
    }

    const OFFSET: number = input.offset;

    const instancesContours :[Vector2, Vector2[]][] = [];
    for (const transformer of input.transformers) {
        const len = input.max_length * 2;
        const pos = transformer.position;
        const box:Vector2[] = [
            new Vector2(pos.x - len, pos.y - len),
            new Vector2(pos.x + len, pos.y - len),
            new Vector2(pos.x + len, pos.y + len),
            new Vector2(pos.x - len, pos.y + len),
        ];

        instancesContours.push([pos, box]);
    }

    for (const substation of input.substations) {
        instancesContours.push([substation.position, substation.bbox]);
    }

    const roadsContours:Vector2[][] = [];
    for (const road of input.roads) {
        roadsContours.push(road);
    }

    yield Yield.Asap;
    const outsideOffsetShapes: Vector2[][] = [];
    for (const chunk of IterUtils.splitArrayIntoChunks(hulls, OFFSET_ITEMS_COUNT)) {
        const offsetHulls = Clipper.offsetPolygons2D(chunk, OFFSET);
        IterUtils.extendArray(outsideOffsetShapes, offsetHulls);
        yield Yield.Asap;
    }
    yield Yield.Asap;
    const UNION_ITEMS_COUNT = 1;
    let unionShape: Vector2[][] = [outsideOffsetShapes[0]];
    const firstPart = outsideOffsetShapes.slice(1);
    for (const chunk of IterUtils.splitArrayIntoChunks(firstPart, UNION_ITEMS_COUNT)) {
        for (const shape of chunk) {
            const toUnion = unionShape.slice();
            toUnion.push(shape);
            unionShape = Clipper.unionPolygons2D(toUnion);
        }
        yield Yield.Asap;
    }


    let insideOffsetShapes = Clipper.offsetPolygons2D(unionShape, -1 * OFFSET);
    yield Yield.Asap;

    for (const chunk of IterUtils.splitArrayIntoChunks(input.excludeBoundaries, UNION_ITEMS_COUNT)) {
        for (const shape of chunk) {
            const toUnion = insideOffsetShapes.slice();
            toUnion.push(shape);
            insideOffsetShapes = Clipper.unionPolygons2D(toUnion);
        }
        yield Yield.Asap;
    }

    yield Yield.Asap;
    const newShapes: Vector2[][] = [];
    const toSubtractPolygons = roadsContours.slice();
    IterUtils.extendArray(toSubtractPolygons, instancesContours.map(i => i[1]));
    const orderedToSubtract = orderHoles(toSubtractPolygons);
    const reused1 = Aabb2.empty();
    const reused2 = Aabb2.empty();
    for (const offsetShape of insideOffsetShapes) {
        reused1.setFromPoints(offsetShape);
        const toSubtract = orderedToSubtract.filter(c => {
            reused2.setFromPoints(c);
            return reused1.intersectsBox2(reused2);
        });
        const subtractShape = Clipper.subtractPolygons(orderContour(offsetShape), toSubtract);

        IterUtils.extendArray(newShapes, subtractShape);
        yield Yield.Asap;
    }
    yield Yield.Asap;

    return [newShapes, hulls];
}

export function deleteWiresByParents(
  parents: IdBimScene[],
  bim: Bim,
  types = ["lv-wire", "wire", "trench", "sectionalizing-cabinet"],
): void {
    const idsToDelete = new Set<IdBimScene>();
    const typesToDelete = new Set<string>(types);
    const typesToPatch = new Set<string>(["transformer"]);
    const patches = new Map<IdBimScene, SceneInstancePatch>();
    bim.instances.spatialHierarchy.sortByDepth(parents);

    for (const parent of parents) {
        if(idsToDelete.has(parent)){
            continue;
        }
        const parentInst = bim.instances.peekById(parent);
        if(!parentInst){
            console.warn("Scene instance is undefined for id:", parent);
            continue;
        }
        const parentMatrix = parentInst.worldMatrix;
        bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
            parent, 
            (child) => {
                const instance = bim.instances.peekById(child);
                if (instance && typesToDelete.has(instance.type_identifier)) {
                    idsToDelete.add(child);
                }
                if (
                    instance &&
                    typesToPatch.has(instance.type_identifier) &&
                    parentInst.type_identifier === "substation"
                ) {
                    const inst = bim.instances.peekById(child)!;
                    const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
                            parentMatrix,
                            inst.worldMatrix
                        );
                    if (
                        !ObjectUtils.areObjectsEqual(
                            localTransform,
                            inst.localTransform
                        )
                    ) {
                        patches.set(child, {
                            spatialParentId: parent,
                            localTransform,
                        });
                    }
                }
                return true;
            },
        true);
    }
    bim.instances.applyPatches(Array.from(patches));
    bim.instances.delete(Array.from(idsToDelete));
}

function convertAcEquipment(
    request: AcRouterRequest | AcWiringGridRequest,
    response: AcWiringResponse,
    bim: Bim,
    wiresData: WireData[],
    input: AcWiringInput
): [WireDef[], ScDef[], TransformerDef[], EdgeType[]] {
    const scGen = new ScGenerator(bim, request, response, input);
    const result = scGen.getEquipment(wiresData);
    return result;
}

type EdgeType = {
    fst: number;
    snd: number;
    current: number;
    power: number;
    circuit: number;
    cables: number[];
};

type ScDef = {
    id: IdBimScene;
    vector3: Vector3;
    sourceInstance: SceneInstance;
    parent: IdBimScene | 0;
};
type EquipmentType ={
    id: IdBimScene;
    circuit: number;
    parent: IdBimScene | 0;
}
type TransformerDef = {
    id: IdBimScene;
    parent: IdBimScene | 0;
};

interface TransformerPosition extends EquipmentPosition {
    index: number
};

interface EquipmentPosition extends EquipmentType {
    position: number;
};

class EdgesMap {
    private readonly totalValue: Map<string, EdgeType>;

    constructor() {
        this.totalValue = new Map<string, EdgeType>();
    }

    add(edge: EdgeType) {
        const hash = this.getEdgeHash(edge);
        let item = this.totalValue.get(hash);
        if (!item) {
            this.totalValue.set(hash, edge);
        } else {
            item.current += edge.current;
            item.power += edge.power;
        }
    }

    toArray(): EdgeType[] {
        return Array.from(this.totalValue.values());
    }

    private getEdgeHash(edge: EdgeType): string {
        const fstHash = Math.min(edge.fst, edge.snd);
        const sndHash = Math.max(edge.fst, edge.snd);
        const cablesHash = edge.cables ? edge.cables.slice().sort().join() : '';
        return `${fstHash}${sndHash}${edge.circuit}${cablesHash}`;
    }
}

class ScGenerator {
    private readonly bim: Bim;
    private readonly request: AcRouterRequest | AcWiringGridRequest;
    private readonly response: AcWiringResponse;
    private readonly input: AcWiringInput;

    constructor(
        bim: Bim,
        request: AcRouterRequest | AcWiringGridRequest,
        response: AcWiringResponse,
        input: AcWiringInput
    ) {
        this.bim = bim;
        this.request = request;
        this.response = response;
        this.input = input;
    }

    getEquipment(
        wiresData: WireData[]
    ): [WireDef[], ScDef[], TransformerDef[], EdgeType[]] {
        const substations: number[] = [];
        for (const substation of this.request.substations) {
            const index = this.getPointIndex(this.request.points[substation]);
            substations.push(index);
        }
        const positionsMap = this.addIds(substations);
        const edges = this.createEdges();
        const scsPositions = this.createScsPositions(edges, positionsMap);
        const polylines = mergeEdges<EdgeType, PolylineWireType>(
            edges,
            (point, newEdge, edge) => {
                return this.isEqualEdge(point, newEdge, edge, positionsMap);
            },
            (poly, edge) => {
                return {
                    id: this.getNewId(),
                    polyline: poly.slice(),
                    circuit: edge.circuit,
                    current: edge.current,
                    power: edge.power,
                    parent: 0,
                    cables: edge.cables,
                };
            }
        );
        const circuits = this.orderPaths(polylines, positionsMap, substations);
        const wires = this.createWires(polylines, wiresData, circuits);
        const scs = this.convertScs(scsPositions, circuits);
        const transformers = this.convertTransformers(circuits, positionsMap);
        return [wires, scs, transformers, edges];
    }

    private getNewId(): IdBimScene {
        return this.bim.instances.reserveNewId();
    }

    private createScsPositions(
        edges: EdgeType[],
        positionsMap: PositionMap
    ): EquipmentPosition[] {
        function AddPosition(points: Map<number, {count:number, wiresTypes:string[]}>, pos: number, edge:EdgeType) {
            const  value =points.get(pos);
            const cablesHash = edge.cables? edge.cables.slice().sort().join(): '';
            const counter = value ? { count: value.count + 1, wiresTypes:[...value.wiresTypes, cablesHash]  }: { count: 1, wiresTypes:[cablesHash]  };
            points.set(pos, counter);
        }
        const circuitsByPointsMap = new Map<number, Map<number, {count:number, wiresTypes:string[]}>>();
        for (const edge of edges) {
            let points = circuitsByPointsMap.get(edge.circuit);
            if (!points) {
                points = new Map();
                circuitsByPointsMap.set(edge.circuit, points);
            }
            AddPosition(points, edge.fst, edge);
            AddPosition(points, edge.snd, edge);
        }
        const positions: EquipmentPosition[] = [];
        for (const [circuit, points] of circuitsByPointsMap) {
            for (const [point, {count, wiresTypes}] of points) {
                const wire = wiresTypes[0];
                if ((count > 2||wiresTypes.some(w=>w!==wire)) && !positionsMap.has(circuit, point)) {
                    const id = this.getNewId();
                    positions.push({
                        id,
                        position: point,
                        circuit,
                        parent: 0,
                    });
                    positionsMap.add(circuit, point, id);
                }
            }
        }
        return positions;
    }

    private convertScs(
        positions: EquipmentPosition[],
        circuits: IdBimScene[][][]
    ): ScDef[] {
        const maxSubstationElevation = IterUtils.max(this.input.substations.map(s => s.elevation));
        this.setParents(positions, circuits);
        const result: ScDef[] = [];
        for (const position of positions) {
            const pos = this.response.points[position.position];
            result.push({
                id: position.id,
                vector3: new Vector3(pos.x, pos.y, maxSubstationElevation),
                sourceInstance: this.input.cabinet,
                parent: position.parent,
            });
        }

        return result;
    }

    private convertTransformers(circuits: IdBimScene[][][],  positionMap: PositionMap): TransformerDef[] {
        const positions: TransformerPosition[] = [];
        for (let i = 0; i < this.response.circuits.length; i++) {
            const circuit = this.response.circuits[i];
            for (const index of circuit.inverters) {
                const id = this.input.transformers[index].id;
                const posIndex = positionMap.getById(i, id);
                if (posIndex) {
                    positions.push({
                        id: this.input.transformers[index].id,
                        position: posIndex,
                        circuit: i,
                        index,
                        parent: 0,
                    });
                }else{
                    console.error(`Transformer with id ${id} not found!`);
                }
            }
        }
        this.setParents(positions, circuits);

        const result: TransformerDef[] = [];
        for (const position of positions) {
            result.push({
                id: position.id,
                parent: position.parent,
            });
        }

        return result;
    }

    private setParents<T extends EquipmentType>(
        equipments: T[],
        circuits: IdBimScene[][][]
    ) {
        for (const sc of equipments) {
            const circuit = circuits[sc.circuit];
            for (const path of circuit) {
                const index = path.findIndex((p) => p === sc.id);
                const nextIndex = index + 1;
                if (index < 0 || path.length === nextIndex) {
                    continue;
                }

                sc.parent = path[nextIndex];
                break;
            }
        }
    }

    private orderPaths(
        wires: PolylineWireType[],
        positionsMap: PositionMap,
        substations: number[]
    ): IdBimScene[][][] {
        const convertedCircuits: IdBimScene[][][] = [];
        const wiresWithSet = wires.map((w) => {
            return {
                id: w.id,
                polyline: new Set<number>(w.polyline),
                circuit: w.circuit,
            };
        });

        for (
            let circuitIdx = 0;
            circuitIdx < this.response.circuits.length;
            circuitIdx++
        ) {
            const circuit = this.response.circuits[circuitIdx];

            const convertedCircuit: IdBimScene[][] = [];
            for (const path of circuit.paths) {
                const orderedPath: Set<number> = new Set<number>();
                for (let i = 0; i < path.length; i++) {
                    const edge = path[i];
                    const nextEdge = path[i + 1];
                    if (
                        nextEdge === undefined ||
                        (edge.fst !== nextEdge.fst && edge.fst !== nextEdge.snd)
                    ) {
                        orderedPath.add(edge.fst);
                        orderedPath.add(edge.snd);
                    } else {
                        orderedPath.add(edge.snd);
                        orderedPath.add(edge.fst);
                    }
                }
                const arrayEquipment = Array.from(orderedPath).filter((s) =>
                    positionsMap.has(circuitIdx, s)
                );
                if (substations.includes(arrayEquipment[0])) {
                    arrayEquipment.reverse();
                }
                const equipments: IdBimScene[] = [];
                for (let i = 0; i < arrayEquipment.length; i++) {
                    const first = arrayEquipment[i];
                    const next = arrayEquipment[i + 1];
                    const pos = positionsMap.get(circuitIdx, first)!;
                    equipments.push(pos);
                    const wire = wiresWithSet.find(
                        (w) =>
                            w.circuit === circuitIdx &&
                            w.polyline.has(first) &&
                            w.polyline.has(next)
                    );
                    if (wire) {
                        equipments.push(wire.id);
                    }
                }

                convertedCircuit.push(equipments);
            }
            convertedCircuits.push(convertedCircuit);
        }
        return convertedCircuits;
    }

    private addIds(allSubstations: number[]): PositionMap {
        const positionMap = new PositionMap();
        const substationsPositions = new Map<number, IdBimScene>();
        for (let i = 0; i < this.input.substations.length; i++) {
            const id = this.input.substations[i].id;
            const pos = allSubstations[i];
            substationsPositions.set(pos, id);
        }

        for (
            let circuitIdx = 0;
            circuitIdx < this.response.circuits.length;
            circuitIdx++
        ) {
            const circuit = this.response.circuits[circuitIdx];
            for (let index = 0; index < circuit.inverters.length; index++) {
                const inverter = circuit.inverters[index];

                const position = this.getInverterIndex(circuit.paths[index], allSubstations);

                positionMap.add(
                    circuitIdx,
                    position,
                    this.input.transformers[inverter].id
                );
            }
            const substations = new Set<number>();
            for (const path of circuit.paths) {
                for (const edge of path) {
                    if (substationsPositions.has(edge.fst)) {
                        substations.add(edge.fst);
                    }
                    if (substationsPositions.has(edge.snd)) {
                        substations.add(edge.snd);
                    }
                }
            }
            for (const pos of substations) {
                positionMap.add(
                    circuitIdx,
                    pos,
                    substationsPositions.get(pos)!
                );
            }
        }

        return positionMap;
    }

    private getInverterIndex(path: Edge[], substations: number[]): number {
        if (path.length > 1) {
            const first = path[0];
            const last = path[path.length - 1];
            if (
                substations.includes(first.fst) ||
                substations.includes(first.snd)
            ) {
                const previous = path[path.length - 2];
                return [previous.fst, previous.snd].includes(last.fst)
                    ? last.snd
                    : last.fst;
            } else {
                const next = path[1];
                return [next.fst, next.snd].includes(first.fst)
                    ? first.snd
                    : first.fst;
            }
        } else {
            return substations.includes(path[0].fst)
                ? path[0].snd
                : path[0].fst;
        }
    }

    private getPointIndex(point: Point): number {
        for (let index = 0; index < this.response.points.length; index++) {
            const p = this.response.points[index];
            if (equalPoints(point, p)) {
                return index;
            }
        }
        throw new Error("Point not found");
    }

    private createWires(
        mergedWires: PolylineWireType[],
        wiresData: WireData[],
        circuits: IdBimScene[][][]
    ): WireDef[] {
        const wires: WireDef[] = [];
        this.setParents(mergedWires, circuits);
        const circuitsByPlace: Set<IdBimScene>[] = [];
        for (const circuit of circuits) {
            const set = new Set<IdBimScene>();
            for (const path of circuit) {
                for (const id of path) {
                    set.add(id);
                }
            }
            circuitsByPlace.push(set);
        }
        for (const wire of mergedWires) {
            const polyline: Vector3[] = wire.polyline.map((e) => {
                const pos = this.response.points[e];
                return new Vector3(pos.x, pos.y, 0);
            });
            const current = wire.current;
            const power = wire.power;
            //const setEquipments = circuitsByPlace[wire.circuit];
            // const substation = this.input.substations.find((s) =>
            //     setEquipments.has(s.id)
            // );
            // if (!substation) {
            //     throw Error("Not found substation in circuit");
            // }
            // const max_voltage_drop = substation.max_voltage_drop;

            const sourceIds = getWireIds(
                wiresData,
                polyline,
                current,
                power,
                this.input.maxVoltageDropPercent,
                wire.cables
            );
            for (let i = 0; i < sourceIds.length; i++) {
                const sourceId = sourceIds[i];
                wires.push({
                    id: i === 0 ? wire.id : this.getNewId(),
                    polyline,
                    current,
                    power,
                    sourceId,
                    parent: wire.parent,
                    circuit: wire.circuit,
                });
            }

        }
        return wires;
    }

    private createEdges(): EdgeType[] {
        const totalValue: EdgesMap = new EdgesMap();
        const circuits = this.response.circuits;
        for (let circuitIdx = 0; circuitIdx < circuits.length; circuitIdx++) {
            const circuit = circuits[circuitIdx];
            for (let i = 0; i < circuit.paths.length; i++) {
                const path = circuit.paths[i];
                const transformer = this.input.transformers[circuit.inverters[i]];
                for (const edge of path) {
                    totalValue.add({
                        fst: edge.fst,
                        snd: edge.snd,
                        current: transformer.current,
                        power: transformer.power,
                        circuit: circuitIdx,
                        cables: edge.cabels || [],
                    });
                }
            }
        }

        const edges = totalValue.toArray();
        return edges;
    }

    private isEqualEdge(
        pos: number,
        edge: EdgeType,
        otherEdge: EdgeType,
        positionsMap: PositionMap
    ): boolean {
        const isEqualProps =
            isEqual(edge.circuit, otherEdge.circuit) &&
            isEqual(edge.current, otherEdge.current) &&
            isEqual(edge.power, otherEdge.power);
        const isEqualPos =
            isEqual(pos, otherEdge.fst) || isEqual(pos, otherEdge.snd);
        const isEquipment = !positionsMap.has(edge.circuit, pos);
        return isEqualProps && isEqualPos && isEquipment;
    }
}

export function* _arrangeLvWiring(
    input: [number, CableDef][],
    substation: IdBimScene,
    bim: Bim,
    log: ScopedLogger,
) {
    const logger = log.newScope('arranging lv wires');
    logger.info(`wiring solver is starting`);

    const sceneInstancesToAlloc: [IdBimScene, Partial<SceneInstance>][] = [];
    const polylinesToAlloc: [IdBimGeo, Partial<PolylineGeometry>][] = [];
    const parentsPatch: [IdBimScene, Partial<SceneInstance>][] = [];

    const areaIndexPerParent = new Map<IdBimScene, number>();
    const parents = input.map(i=>i[1].parent);
    const areaIndexPath = ['circuit', 'position', 'area_index'];
    const parentInstances = bim.instances.peekByIds(parents);
    for (const [parentId, inst] of parentInstances) {
        const areaIndex = inst.properties.get(BimProperty.MergedPath(areaIndexPath))?.asNumber();
        if(areaIndex === undefined){
            continue;
        }
        areaIndexPerParent.set(parentId, areaIndex);
    }

    let reusableMatrix1 = new Matrix4();
    function addClonedSceneInstanceWith(cable: CableDef): IdBimScene | 0 {

        const newGeoId = bim.polylineGeometries.reserveNewId();
        if(cable.polyline === null){
            logger.error(`polyline is undefined`);
            return 0;
        }
        const newId = bim.instances.idsProvider.reserveNewId();

        const spatialParentId = cable.parent;
        const mainParentMatrix = bim.instances.peekById(spatialParentId)?.worldMatrix;
        if (!mainParentMatrix) {
            logger.error(`unexpected parent matrix absence`, spatialParentId);
            return 0;
        }
        const parentGlobalPos = Transform.fromMatrix(mainParentMatrix);
        const points = cable.polyline.map(
            (v) => new Vector3(v.x, v.y, 0)
        );

        const objectWorldMatrix = new Matrix4()
            .makeTranslation(points[0].x, points[0].y, points[0].z)

        const localTransform = SceneInstances.getLocalTransformRelativeToParentMatrix(
            mainParentMatrix,
            objectWorldMatrix
        );

        const inverseWorldMatrix = reusableMatrix1.getInverse(objectWorldMatrix);
        const localPoints = points.map(p => p.clone().applyMatrix4(inverseWorldMatrix));

        const polylineGeometry: Partial<PolylineGeometry> = PolylineGeometry.newWithAutoIdsFiltered(localPoints, 0.5);
        polylinesToAlloc.push([newGeoId, polylineGeometry]);

        const properties = [
            // SPECIFICATION
            new BimProperty({
                path: ['specification', 'type'],
                value: cable.type || "none",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'asset'],
                value: cable.gauge ?? -1,
                description: 'asset=lv-wire-spec=',
                numeric_step: 1,
                readonly: false,
            }),
            new BimProperty({
                path: ['specification', 'assetName'],
                value: cable.gaugeName ?? " ",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'gauge'],
                value: cable.gaugeSpec?.name || "none",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'material'],
                value: cable.gaugeSpec?.material || "none",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'phase'],
                value: cable.pairingType || "none",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'temperature'],
                value: cable.temperature,
                unit: 'C',
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'ampacity'],
                value: cable.maxAmp,
                unit: 'A',
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'isBuried'],
                value: cable.isBuried ?? false,
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'constraints'],
                value: cable.constraints?.join(', ') || "none",
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'resistivity'],
                value: cable.resistivity ?? "none",
                unit: 'Om/kft',
                readonly: true,
            }),
            new BimProperty({
                path: ['specification', 'reactance'],
                value: cable.reactance ?? "none",
                unit: 'Om/kft',
                readonly: true,
            }),

            // COMPUTED_RESULT
            new BimProperty({
                path: ['computed_result', 'length'],
                value: cable.length,
                unit:'m',
                readonly: true,
            }),
            new BimProperty({
                path: ['computed_result', 'operating_current'],
                value: cable.operatingCurrent,
                unit: 'A',
                readonly: true,
            }),
            new BimProperty({
                path: ['computed_result', 'max_current'],
                value: cable.maxCurrent,
                unit: 'A',
                readonly: true,
            }),
            new BimProperty({
                path: ['computed_result', 'power'],
                value: cable.power,
                unit: 'W',
                readonly: true,
            }),
        ];

        if (cable.merginStats) {
            properties.push(
                new BimProperty({
                    path: ['computed_result', 'merging_length'],
                    value: (cable.merginStats.mergingLengthMeters ?? 0),
                    unit: 'm',
                    readonly: true,
                }),
                new BimProperty({
                    path: ['computed_result', 'merging_groups'],
                    value: (cable.merginStats.mergingGroups ?? 0),
                    readonly: true,
                    numeric_step: 1,
                }),
            )
        }

        const areaIndex = areaIndexPerParent.get(spatialParentId);
        if(areaIndex !== undefined){
            properties.push(new BimProperty({
                path: areaIndexPath,
                value: areaIndex,
                readonly: true,
            }));
        }

        //COST BS
        properties.push(new BimProperty({path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL"}));
        if (cable?.type === DC_CNSTS.ConductorType.AcFeeder) {
            properties.push(
                new BimProperty({ path: ["cost_bs", "level 2"], value: "AC" })
            );
        } else {
            properties.push(
                new BimProperty({ path: ["cost_bs", "level 2"], value: "DC" })
            );
        }
        
        const state: Partial<SceneInstance> = {
            type_identifier: "lv-wire",
            representationAnalytical: new BasicAnalyticalRepresentation(
                newGeoId
            ),
            spatialParentId,
            localTransform,
            properties: new PropertiesCollection(properties),
        };

        sceneInstancesToAlloc.push([newId, state]);
        parentsPatch.push([newId, {spatialParentId}])

        return newId;
    }

    for (const [_id, cable] of input) {
        if (cable.polyline && calcLength(cable.polyline) > 0) {
            addClonedSceneInstanceWith(cable);
        }
    }

    yield Yield.NextFrame;

    const ALLOCATION_SIZE = 3000;
    logger.info(`count of wires to allocate - ${polylinesToAlloc.length}`);
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        polylinesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.polylineGeometries.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }
    logger.info(
        `count of instances to allocate - ${sceneInstancesToAlloc.length}`
    );
    for (const objectsChunk of IterUtils.splitArrayIntoChunks(
        sceneInstancesToAlloc,
        ALLOCATION_SIZE
    )) {
        bim.instances.allocate(objectsChunk);
        yield Yield.NextFrame;
        yield Yield.NextFrame;
    }

    logger.info("allocating wires finished");
}

class PositionMap {
    private readonly pos: Map<number, IdBimScene>[];
    private readonly ids: Map<IdBimScene, number>[];

    constructor() {
        this.pos = [];
        this.ids = [];
    }

    add(circuit: number, position: number, id: IdBimScene) {
        const posByCircuits = this.pos[circuit];
        const idsByCircuit = this.ids[circuit];
        if (posByCircuits) {
            posByCircuits.set(position, id);
            idsByCircuit.set(id, position);
        } else {
            this.pos[circuit] = new Map<number, IdBimScene>([[position, id]]);
            this.ids[circuit] = new Map<IdBimScene, number>([[id, position]]);
        }
    }

    has(circuit: number, position: number): boolean {
        return this.pos[circuit]?.has(position);
    }

    get(circuit: number, position: number): IdBimScene | undefined {
        return this.pos[circuit]?.get(position);
    }

    getById(circuit: number, id: IdBimScene): number | undefined {
        return this.ids[circuit]?.get(id);
    }
}

interface PolylineWireType extends EquipmentType {
    polyline: number[];
    current: number;
    power: number;
    cables?: number[];
}
