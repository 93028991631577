import { Matrix4 } from 'math-ts';
import { type Bim, type BasicAnalyticalRepresentation, AnyTrackerProps, StringProperty } from '../..';
import type { BimPropertyData } from '../../bimDescriptions/BimProperty';
import { BimProperty } from '../../bimDescriptions/BimProperty';
import type { ReactiveSolverBase } from '../ReactiveSolverBase';
import { SolverObjectInstance } from '../SolverObjectInstance';
import { SolverPropsInObject } from '../SolverPropsInObject';
import { SolverPropsWithChildren } from '../SolverPropsWithChildren';
import { SolverObjectInstanceWithChildren } from '../SolverObjectInstanceWithChildren';

const CommonSolarCircuitDefaultArgs = {
    circuit_max_current: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "max_current"], value: 0, unit: "A" }),
    circuit_dc_power: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "dc_power"], value: 0, unit: "kW" }),
    circuit_strings_count: BimProperty.NewShared({ path: ["circuit", "equipment", "strings_count"], value: 0 }),
    circuit_modules_count: BimProperty.NewShared({ path: ["circuit", "equipment", "modules_count"], value: 0 }),
    circuit_operating_voltage: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "operating_voltage"], value: 0, unit: "V" }),
    circuit_operating_current: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "operating_current"], value: 0, unit: "A" }),

    circuit_wiring_total_losses: BimProperty.NewShared({ path: ["circuit", "mv_wiring", "total_losses"], value: 0, unit: "kW" }),
    circuit_wiring_voltage_drop: BimProperty.NewShared({ path: ["circuit", "mv_wiring", "avarage_voltage_drop"], value: 0, unit: "%" }),
};

const SectionalizeCabinetCircuitArgs = {
    ...CommonSolarCircuitDefaultArgs,
    transformers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "transformers_count"], value: 0}),
    inverter_count: BimProperty.NewShared({ path: ["circuit", "equipment", "inverters_count"], value: 0}),
    cb_count: BimProperty.NewShared({ path: ["circuit", "equipment", "cb_count"], value: 0}),
    sc_count: BimProperty.NewShared({ path: ["circuit", "equipment", "sc_count"], value: 0}),
    trackers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "trackers_count"], value: 0}),
    ac_power: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "ac_power"], value: 0}),
};

const SolarTransformerCircuitArgs = {
    ...CommonSolarCircuitDefaultArgs,
    sc_count: BimProperty.NewShared({ path: ["circuit", "equipment", "sc_count"], value: 0}),
    transformers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "transformers_count"], value: 0}),
    inverter_count: BimProperty.NewShared({ path: ["circuit", "equipment", "inverters_count"], value: 0 }),
    cb_count: BimProperty.NewShared({ path: ["circuit", "equipment", "cb_count"], value: 0 }),
    trackers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "trackers_count"], value: 0 }),
    ac_power: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "ac_power"], value: 0, unit: "kW" }),
    lv_voltage: BimProperty.NewShared({ path: ["input", "lv_voltage"], value: 0, unit: "V" }),
    mv_voltage: BimProperty.NewShared({ path: ["output", "mv_voltage"], value: 0, unit: "V" }),
    out_operating_voltage: BimProperty.NewShared({path:["circuit", "aggregated_capacity", "output_operating_voltage"], value:0, unit:"V", readonly:true}),
    out_max_current: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "output_max_current"], value: 0, unit: "A" }),
    out_operating_current: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "output_operating_current"], value: 0, unit: "A" }),
};

const SolarInvertorCircuitArgs = {
    ...CommonSolarCircuitDefaultArgs,
    max_power: BimProperty.NewShared({ path: ["inverter", "max_power"], value: 0, unit: "kW"}),
    max_current_output:  BimProperty.NewShared({ path: ["inverter", "max_current_output"], value: 0, unit: "A"}),
    nominal_ac_voltage: BimProperty.NewShared({ path: ["inverter", "nominal_ac_voltage"], value: 0, unit: "V"}),
    cb_count: BimProperty.NewShared({ path: ["circuit", "equipment", "cb_count"], value: 0}),
    trackers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "trackers_count"], value: 0}),
};

const CombinerBoxArgs = {
    ...CommonSolarCircuitDefaultArgs,
    inverter_count: BimProperty.NewShared({ path: ["circuit", "equipment", "inverters_count"], value: 0}),
    trackers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "trackers_count"], value: 0}),
    ac_power: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "ac_power"], value: 0, unit: "kW"}),
};

const WireArgs = {
    ...CommonSolarCircuitDefaultArgs,
    sc_count: BimProperty.NewShared({ path: ["circuit", "equipment", "sc_count"], value: 0}),
    transformers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "transformers_count"], value: 0}),
    inverter_count: BimProperty.NewShared({ path: ["circuit", "equipment", "inverters_count"], value: 0}),
    cb_count: BimProperty.NewShared({ path: ["circuit", "equipment", "cb_count"], value: 0}),
    trackers_count: BimProperty.NewShared({ path: ["circuit", "equipment", "trackers_count"], value: 0}),
    ac_power: BimProperty.NewShared({ path: ["circuit", "aggregated_capacity", "ac_power"], value: 0}),
};

interface ParamsCircuitPropsCalculator{
    includeWiring: boolean,
    includeACPower?: boolean,
    includeEquipmentCount?: boolean,
}

interface EquipmentCountProps {
    sc_count: BimProperty,
    transformers_count: BimProperty,
    inverter_count: BimProperty,
    cb_count: BimProperty,
    trackers_count: BimProperty,
}

interface AcPowerProperty {
    ac_power: BimProperty;
}

type CommonSolarCircuitProps = typeof CommonSolarCircuitDefaultArgs & Partial<EquipmentCountProps> & Partial<AcPowerProperty>;

class CommonSolarCircuitPropsCalculator {
    modules_count = 0;
    strings_count = 0;
    dc_power = 0;
    operating_voltage = 0;
    operating_current = 0;
    max_current: number = 0;

    losses: number = 0;
    voltage_drop: number = 0;
    acDcRatio: number = 0;

    ac_power: number = 0;

    sc_count: number = 0;
    transformers_count: number = 0;
    inverter_count: number = 0;
    cb_count: number = 0;
    trackers_count: number = 0;

    private addCommon(obj: CommonSolarCircuitProps) {
        this.modules_count += obj.circuit_modules_count.asNumber();
        this.strings_count += obj.circuit_strings_count.asNumber();

        this.dc_power += obj.circuit_dc_power.as("kW");

        this.losses += obj.circuit_wiring_total_losses.as("kW");
    }

    private addAcPower(obj: Partial<AcPowerProperty>) {
        if (obj.ac_power?.value) {
            this.ac_power += obj.ac_power.as("kW");
        }
    }

    private addEquipmentCountProps(children: Partial<EquipmentCountProps>) {
        if (children.sc_count) {
            this.sc_count += children.sc_count.asNumber();
        }
        if (children.transformers_count) {
            this.transformers_count += children.transformers_count.asNumber();
        }
        if (children.inverter_count) {
            this.inverter_count += children.inverter_count.asNumber();
        }
        if (children.cb_count) {
            this.cb_count += children.cb_count.asNumber();
        }
        if (children.trackers_count) {
            this.trackers_count += children.trackers_count.asNumber();
        }
    }

    addFrom(obj: CommonSolarCircuitProps) {
        this.addCommon(obj);
        this.addEquipmentCountProps(obj);
        this.addAcPower(obj);

        this.operating_voltage =
            this.operating_voltage > 0
                ? Math.min(
                      this.operating_voltage,
                      obj.circuit_operating_voltage.as("V")
                  )
                : obj.circuit_operating_voltage.as("V");
        this.max_current += obj.circuit_max_current.as("A");
        this.operating_current += obj.circuit_operating_current.as("A");
    }

    addAfterWire(obj: CommonSolarCircuitProps) {
        this.addCommon(obj);
        this.addEquipmentCountProps(obj);
        this.addAcPower(obj);
    }

    addAfterTransformer(obj: typeof SolarTransformerCircuitArgs) {
        this.addCommon(obj);
        this.addEquipmentCountProps(obj);
        this.addAcPower(obj);
        this.operating_voltage = calculateTransformerVoltage(
            this.operating_voltage,
            obj.out_operating_voltage
        );

        this.max_current += obj.out_max_current.as("A");
        this.operating_current += obj.out_operating_current.as("A");
    }

    addAfterInverter(obj: typeof SolarInvertorCircuitArgs) {
        this.addCommon(obj);
        this.addEquipmentCountProps(obj);
        this.ac_power += Math.min(
            obj.circuit_dc_power.as("kW"),
            obj.max_power.as("kW")
        );

        const outInverterVoltage = obj.nominal_ac_voltage;
        const max_power = obj.max_power;
        this.operating_voltage = this.operating_voltage > 0
            ? Math.min(this.operating_voltage, outInverterVoltage.as("V"))
            : outInverterVoltage.as("V");
        const maxOutInvertorCurrentA = obj.max_current_output.as("A")
            ? obj.max_current_output.as("A")
            : max_power.as("W") / outInverterVoltage.as("V") / Math.cbrt(3);
        this.max_current += maxOutInvertorCurrentA;
        this.operating_current +=
            obj.circuit_dc_power.as("kW") >= max_power.as("kW")
                ? maxOutInvertorCurrentA
                : obj.circuit_operating_current.as("A");
    }

    private additionalProps(ac_power: number) {
        this.voltage_drop =
            this.losses && ac_power ? (this.losses / ac_power) * 100 : 0;
        this.acDcRatio =
            ac_power && this.dc_power
                ? Math.round((this.dc_power / ac_power) * 1e2) * 1e-2
                : 0;
    }

    asKeyValue(
        ac_power: number | undefined
    ): typeof CommonSolarCircuitDefaultArgs & {
        circuit_ac_dc_ratio: BimProperty;
    } {
        if (ac_power !== undefined) {
            this.additionalProps(ac_power);
        }
        return {
            circuit_max_current: BimProperty.NewShared({
                path: ["circuit", "aggregated_capacity", "max_current"],
                value: this.max_current,
                unit: "A",
            }),
            circuit_dc_power: BimProperty.NewShared({
                path: ["circuit", "aggregated_capacity", "dc_power"],
                value: this.dc_power,
                unit: "kW",
            }),
            circuit_ac_dc_ratio: BimProperty.NewShared({
                path: ["circuit", "aggregated_capacity", "dc/ac_ratio"],
                value: this.acDcRatio,
            }),
            circuit_strings_count: BimProperty.NewShared({
                path: ["circuit", "equipment", "strings_count"],
                value: this.strings_count,
            }),
            circuit_modules_count: BimProperty.NewShared({
                path: ["circuit", "equipment", "modules_count"],
                value: this.modules_count,
            }),
            circuit_operating_voltage: BimProperty.NewShared({
                path: ["circuit", "aggregated_capacity", "operating_voltage"],
                value: this.operating_voltage,
                unit: "V",
            }),
            circuit_operating_current: BimProperty.NewShared({
                path: ["circuit", "aggregated_capacity", "operating_current"],
                value: this.operating_current,
                unit: "A",
            }),
            circuit_wiring_voltage_drop: BimProperty.NewShared({
                path: ["circuit", "mv_wiring", "avarage_voltage_drop"],
                value: this.voltage_drop,
                unit: "%",
            }),
            circuit_wiring_total_losses: BimProperty.NewShared({
                path: ["circuit", "mv_wiring", "total_losses"],
                value: this.losses,
                unit: "kW",
            }),
        };
    }

    private defaultParamsInAsBimProps: ParamsCircuitPropsCalculator = {
        includeWiring: true,
    };

    asBimProps(
        params: ParamsCircuitPropsCalculator = this.defaultParamsInAsBimProps
    ): BimPropertyData[] {
        const result: BimPropertyData[] = [];
        if (params.includeACPower) {
            this.additionalProps(this.ac_power);
            result.push({
                path: ["circuit", "aggregated_capacity", "dc/ac_ratio"],
                value: this.acDcRatio,
            });
            result.push({
                path: ["circuit", "aggregated_capacity", "ac_power"],
                value: this.ac_power,
                unit: "kW",
            });
        }
        if (params.includeWiring) {
            const wiringProps = [
                {
                    path: ["circuit", "mv_wiring", "avarage_voltage_drop"],
                    value: this.voltage_drop,
                    unit: "%",
                },
                {
                    path: ["circuit", "mv_wiring", "total_losses"],
                    value: this.losses,
                    unit: "kW",
                },
            ];
            result.push(...wiringProps);
        }
        if (params.includeEquipmentCount) {
            result.push(
                {
                    path: ["circuit", "equipment", "sc_count"],
                    value: this.sc_count,
                },
                {
                    path: ["circuit", "equipment", "transformers_count"],
                    value: this.transformers_count,
                },
                {
                    path: ["circuit", "equipment", "inverters_count"],
                    value: this.inverter_count,
                },
                {
                    path: ["circuit", "equipment", "cb_count"],
                    value: this.cb_count,
                },
                {
                    path: ["circuit", "equipment", "trackers_count"],
                    value: this.trackers_count,
                }
            );
        }
        result.push(
            {
                path: ["circuit", "equipment", "modules_count"],
                value: this.modules_count,
            },
            {
                path: ["circuit", "aggregated_capacity", "dc_power"],
                value: this.dc_power,
                unit: "kW",
            },
            {
                path: ["circuit", "equipment", "strings_count"],
                value: this.strings_count,
            },
            {
                path: ["circuit", "aggregated_capacity", "max_current"],
                value: this.max_current,
                unit: "A",
            },
            {
                path: ["circuit", "aggregated_capacity", "operating_current"],
                value: this.operating_current,
                unit: "A",
            },
            {
                path: ["circuit", "aggregated_capacity", "operating_voltage"],
                value: this.operating_voltage,
                unit: "V",
            }
        );

        return result;
    }
}

function calculateTransformerCurrent(
    current: BimProperty,
    inTransformerVoltage: BimProperty,
    outTransformerVoltage: BimProperty
): number {
    return (current.as("A") * inTransformerVoltage.as("V")) / outTransformerVoltage.as("V");
}

function calculateTransformerVoltage(operating_voltage:number, outTransformerVoltage:BimProperty):number{
    return operating_voltage > 0
        ? Math.min(operating_voltage, outTransformerVoltage.as("V"))
        : outTransformerVoltage.as("V");
}

export function solarSubstationsCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {};

    return SolverPropsWithChildren.new8(
        "substation-circuits",
        "substation",
        defaultInObjectArgs,
        [
            { identifier: "sectionalizing-cabinet", args: SectionalizeCabinetCircuitArgs },
            { identifier: "transformer", args: SolarTransformerCircuitArgs },
            { identifier: "inverter", args: SolarInvertorCircuitArgs },
            { identifier: "combiner-box", args: CombinerBoxArgs },
            { identifier: "tracker", args: CommonSolarCircuitDefaultArgs },
            { identifier: "wire", args: WireArgs},
            { identifier: "fixed-tilt", args: CommonSolarCircuitDefaultArgs },
            { identifier: "any-tracker", args: CommonSolarCircuitDefaultArgs },
        ],
        (_inObjectArgs, [scChildren, transformerChildren, invertorChildren, cbChildren, trackerChildren, wireChildren, fixedTrackerChildren, anyTrackers]) => {

            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();

            for (const child of scChildren) {
                commonCircuitProps.addFrom(child);
            }
            for (const child of transformerChildren) {
                commonCircuitProps.addAfterTransformer(child);;
            }
            for (const child of invertorChildren) {
                commonCircuitProps.inverter_count += 1;
                commonCircuitProps.addAfterInverter(child);
            }
            for (const child of cbChildren) {
                commonCircuitProps.cb_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of trackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of fixedTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of anyTrackers) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of wireChildren) {
                commonCircuitProps.addFrom(child);
            }

            return [
                ...commonCircuitProps.asBimProps({includeWiring: true, includeACPower: true, includeEquipmentCount: true}),
            ];
        }
    );
}

export function scCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {};

    return SolverPropsWithChildren.new8(
        "sectionalizing-cabinet-circuits",
        "sectionalizing-cabinet",
        defaultInObjectArgs,
        [
            { identifier: "sectionalizing-cabinet", args: SectionalizeCabinetCircuitArgs },
            { identifier: "transformer", args: SolarTransformerCircuitArgs },
            { identifier: "inverter", args: SolarInvertorCircuitArgs },
            { identifier: "combiner-box", args: CombinerBoxArgs },
            { identifier: "tracker", args: CommonSolarCircuitDefaultArgs },
            { identifier: "wire", args: WireArgs},
            { identifier: "fixed-tilt", args: CommonSolarCircuitDefaultArgs },
            { identifier: "any-tracker", args: CommonSolarCircuitDefaultArgs },
        ],
        (_inObjectArgs, [scChildren, transformerChildren, invertorChildren, cbChildren, trackerChildren, wireChildren, fixedTrackerChildren, anyTrackerChildren]) => {

            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();
            commonCircuitProps.sc_count += 1;

            for (const child of scChildren) {
                commonCircuitProps.addFrom(child);
            }
            for (const child of transformerChildren) {
                commonCircuitProps.addAfterTransformer(child);
            }
            for (const child of invertorChildren) {
                commonCircuitProps.inverter_count += 1;
                commonCircuitProps.addAfterInverter(child);
            }
            for (const child of cbChildren) {
                commonCircuitProps.cb_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of trackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of fixedTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of wireChildren) {
                commonCircuitProps.addFrom(child);
            }
            for (const child of anyTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }

            return [
                ...commonCircuitProps.asBimProps({includeWiring: true, includeACPower: true, includeEquipmentCount: true}),
            ];
        }
    );
}

export function solarTransformersCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {
        lv_voltage: BimProperty.NewShared({ path: ["input", "lv_voltage"], value: 0, unit: "V" }),
        mv_voltage: BimProperty.NewShared({ path: ["output", "mv_voltage"], value: 0, unit: "V" }),
     };

    return SolverPropsWithChildren.new7(
        "transformer-circuits",
        "transformer",
        defaultInObjectArgs,
        [
            { identifier: "sectionalizing-cabinet", args: SectionalizeCabinetCircuitArgs },
            { identifier: "inverter", args: SolarInvertorCircuitArgs },
            { identifier: "combiner-box", args: CombinerBoxArgs },
            { identifier: "tracker", args: CommonSolarCircuitDefaultArgs },
            { identifier: "wire", args: WireArgs },
            { identifier: "fixed-tilt", args: CommonSolarCircuitDefaultArgs },
            { identifier: "any-tracker", args: CommonSolarCircuitDefaultArgs },
        ],
        (inObjectArgs, [scChildren, invertorChildren, cbChildren, trackerChildren, wireChildren, fixedTrackerChildren, anyTrackerChildren]) => {

            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();
            commonCircuitProps.transformers_count += 1;

            for (const child of scChildren) {
                commonCircuitProps.addFrom(child);
            }
            for (const child of invertorChildren) {
                commonCircuitProps.inverter_count += 1;
                commonCircuitProps.addAfterInverter(child);
            }
            for (const child of cbChildren) {
                commonCircuitProps.cb_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of trackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of fixedTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of anyTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }

            const block_ac_power = commonCircuitProps.ac_power;
            const commonProps = commonCircuitProps.asKeyValue(block_ac_power);
            let out_max_current = calculateTransformerCurrent(
                commonProps.circuit_max_current,
                inObjectArgs.lv_voltage,
                inObjectArgs.mv_voltage
            );

            let out_operating_current = calculateTransformerCurrent(
                commonProps.circuit_operating_current,
                inObjectArgs.lv_voltage,
                inObjectArgs.mv_voltage
                );
            let out_operating_voltage = inObjectArgs.mv_voltage.as('V');
            const block_out_max_current = out_max_current;
            const block_out_operating_current = out_operating_current;
            const block_out_operating_voltage = out_operating_voltage;
            for (const child of wireChildren) {
                out_max_current += child.circuit_max_current.as("A");
                out_operating_current += child.circuit_operating_current.as("A");
                out_operating_voltage = calculateTransformerVoltage(out_operating_voltage, child.circuit_operating_voltage);
                commonCircuitProps.addAfterWire(child);
            }

            return [
                ...commonCircuitProps.asBimProps({includeWiring:true, includeACPower: true, includeEquipmentCount: true}),
                { path: ["circuit", "aggregated_capacity", "output_max_current"], value: out_max_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "output_operating_current"], value: out_operating_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "output_operating_voltage"], value: out_operating_voltage, unit: "V" },

                { path: ["circuit", "block_capacity", "ac_power"], value: block_ac_power, unit: "kW" },
                { path: ["circuit", "block_capacity", "dc_power"], value: commonProps.circuit_dc_power.as('kW'), unit: "kW" },
                { path: ["circuit", "block_capacity", "dc/ac_ratio"], value: commonProps.circuit_ac_dc_ratio.asNumber() },
                { path: ["circuit", "block_capacity", "max_current"], value: commonProps.circuit_max_current.as("A"), unit: "A" },
                { path: ["circuit", "block_capacity", "operating_current"], value: commonProps.circuit_operating_current.as("A"), unit: "A" },
                { path: ["circuit", "block_capacity", "operating_voltage"], value: commonProps.circuit_operating_voltage.as("V"), unit: "V" },

                { path: ["circuit", "block_capacity", "output_max_current"], value: block_out_max_current, unit: "A" },
                { path: ["circuit", "block_capacity", "output_operating_current"], value: block_out_operating_current, unit: "A" },
                { path: ["circuit", "block_capacity", "output_operating_voltage"], value: block_out_operating_voltage, unit: "V" },
            ];
        }
    );
}

export function solarInvertersCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {};

    return SolverPropsWithChildren.new4(
        "inverter-circuits",
        "inverter",
        defaultInObjectArgs,
        [
            { identifier: "combiner-box", args: CombinerBoxArgs },
            { identifier: "tracker", args: CommonSolarCircuitDefaultArgs },
            { identifier: "fixed-tilt", args: CommonSolarCircuitDefaultArgs },
            { identifier: "any-tracker", args: CommonSolarCircuitDefaultArgs },
        ],
        (_inObjectArgs, [cbChildren, trackerChildren, fixedTrackerChildren, anyTrackerChildren]) => {

            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();

            for (const child of cbChildren) {
                commonCircuitProps.cb_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of trackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of fixedTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of anyTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }

            return [
                ...commonCircuitProps.asBimProps({ includeWiring: false, includeEquipmentCount: true }),
            ];
        }
    );
}

export function solarInverterInObjectPropsCalculator(): ReactiveSolverBase {
    const objectsDefaultArgs = {
        m_dc_inputs_number: BimProperty.NewShared({ path: ["module", "dc_inputs_number"], value: 0}),
        m_max_voltage_input: BimProperty.NewShared({ path: ["module", "max_voltage_input"], value: 0, unit: "V"}),
        m_max_current_input: BimProperty.NewShared({ path: ["module", "max_current_input"], value: 0, unit: "A"}),
        m_max_current_output: BimProperty.NewShared({ path: ["module", "max_current_output"], value: 0, unit: "A"}),
        m_max_power: BimProperty.NewShared({ path: ["module", "max_power"], value: 0, unit: "kW"}),
        modules_count: BimProperty.NewShared({ path: ["inverter", "modules_count"], value: 0 }),
        nominal_ac_voltage: BimProperty.NewShared({ path: ["module", "nominal_ac_voltage"], value: 0, unit: "V" }),
        min_mppt_voltage: BimProperty.NewShared({ path: ["module", "min_mppt_voltage"], value: 0, unit: "V" }),
        max_mppt_voltage: BimProperty.NewShared({ path: ["module", "max_mppt_voltage"], value: 0, unit: "V" }),
    };

    return new SolverPropsInObject({
        solverIdentifier: "inverter-props",
        objectsIdentifier: "inverter",
        objectsDefaultArgs,

        solverFunction: (args) => {
            const count = args.modules_count.asNumber();

            return [
                { path: ["inverter", "dc_inputs_number"], value: args.m_dc_inputs_number.asNumber()},
                { path: ["inverter", "max_voltage_input"], value: args.m_max_voltage_input.as("V"), unit: "V"},
                { path: ["inverter", "max_current_input"], value: count * args.m_max_current_input.as("A"), unit: "A"},
                { path: ["inverter", "max_current_output"], value: count * args.m_max_current_output.as("A"), unit: "A"},
                { path: ["inverter", "max_power"], value: count * args.m_max_power.as("kW"), unit: "kW"},
                { path: ["inverter", "nominal_ac_voltage"], value: args.nominal_ac_voltage.as("V"), unit: "V"},
                { path: ["inverter", "min_mppt_voltage"], value: args.min_mppt_voltage.as("V"), unit: "V"},
                { path: ["inverter", "max_mppt_voltage"], value: args.max_mppt_voltage.as("V"), unit: "V"},
            ];
        },
    });
}

export function combinerBoxesCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {};

    return SolverPropsWithChildren.new4(
        "combiners-circuits",
        "combiner-box",
        defaultInObjectArgs,
        [
            { identifier: "inverter", args: SolarInvertorCircuitArgs },
            { identifier: "tracker", args: CommonSolarCircuitDefaultArgs },
            { identifier: "fixed-tilt", args: CommonSolarCircuitDefaultArgs },
            { identifier: "any-tracker", args: CommonSolarCircuitDefaultArgs },
        ],
        (_inObjectArgs, [invertorChildren, trackerChildren, fixedTrackerChildren, anyTrackerChildren]) => {
            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();

            for (const child of invertorChildren) {
                commonCircuitProps.inverter_count += 1;
                commonCircuitProps.addAfterInverter(child);
            }

            for (const child of trackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of fixedTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }
            for (const child of anyTrackerChildren) {
                commonCircuitProps.trackers_count += 1;
                commonCircuitProps.addFrom(child);
            }

            return [
                ...commonCircuitProps.asBimProps({ includeWiring: false, includeACPower: true, includeEquipmentCount: true }),
            ];
        }
    );
}

export function fixedTrackersEnergyCalculator(): ReactiveSolverBase {
    const objectsDefaultArgs = {
        module_maximum_power: BimProperty.NewShared({ path: ["module", "maximum_power"], value: 0, unit: "W"}),
        module_current: BimProperty.NewShared({ path: ["module", "current"], value: 0, unit: "A"}),
        module_voltage: BimProperty.NewShared({ path: ["module", "voltage"], value: 0, unit: "V"}),
        module_max_current: BimProperty.NewShared({ path: ["module", "short_circuit_current"], value: 0, unit: "A"}),
        module_max_voltage: BimProperty.NewShared({ path: ["module", "max_system_voltage"], value: 0, unit: "V"}),

        modules_count: BimProperty.NewShared({ path: ["modules", "count"], value: 0 }),
        modules_count_x: BimProperty.NewShared({ path: ["modules", "count_x"], value: 0 }),

        strings_count: BimProperty.NewShared({ path: ["dimensions", "strings_count"], value: 0}),
        string_modules_count: BimProperty.NewShared({ path: ["string", "modules_count"], value: 0}),
    };

    return new SolverPropsInObject({
        solverIdentifier: "fixed-tracker-energy",
        objectsIdentifier: "fixed-tilt",
        objectsDefaultArgs,
        cache: true,
        solverFunction: (args) => {
            const strings_count = args.strings_count.asNumber();
            const module_current = args.module_current.as("A");
            const module_voltage = args.module_voltage.as("V");
            const module_max_current = args.module_max_current.as("A");
            const module_max_voltage = args.module_max_voltage.as("V");
            const module_maximum_power = args.module_maximum_power.as("W");

            const tracker_modules_count = args.modules_count.asNumber();

            const stringVoltage = module_voltage * args.string_modules_count.asNumber();
            const stringPower = module_maximum_power * args.string_modules_count.asNumber();

            const trackerDcPower = module_maximum_power * tracker_modules_count * 1e-3;

            const circuit_max_voltage = module_max_voltage;
            const circuit_operating_voltage = stringVoltage;

            const circuit_max_current = module_max_current * strings_count;
            const circuit_operating_current = module_current * strings_count;


            return [
                { path: ["string", "current"], value: module_current, unit: "A" },
                { path: ["string", "voltage"], value: stringVoltage, unit: "V" },
                { path: ["string", "power"], value: stringPower, unit: "W" },
                { path: ["string", "max_current"], value: module_max_current, unit: "A" },

                { path: ["circuit", "equipment", "modules_count"], value: tracker_modules_count },
                { path: ["circuit", "aggregated_capacity", "dc_power"], value: trackerDcPower, unit: "kW" },
                { path: ["circuit", "equipment", "strings_count"], value: strings_count },
                { path: ["circuit", "aggregated_capacity", "max_current"], value: circuit_max_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "operating_current"], value: circuit_operating_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "max_voltage"], value: circuit_max_voltage, unit: "V" },
                { path: ["circuit", "aggregated_capacity", "operating_voltage"], value: circuit_operating_voltage, unit: "V" },
            ];
        },
    });
}


export function trackersPropsCalculator(): ReactiveSolverBase {
    const objectsDefaultArgs = {
        module_maximum_power: BimProperty.NewShared({ path: ["module", "maximum_power"], value: 0, unit: "W"}),
        module_current: BimProperty.NewShared({ path: ["module", "current"], value: 0, unit: "A"}),
        module_voltage: BimProperty.NewShared({ path: ["module", "voltage"], value: 0, unit: "V"}),
        module_max_current: BimProperty.NewShared({ path: ["module", "short_circuit_current"], value: 0, unit: "A"}),
        module_max_voltage: BimProperty.NewShared({ path: ["module", "max_system_voltage"], value: 0, unit: "V"}),

        string_modules_count_x: BimProperty.NewShared({ path: ["tracker-frame", "string", "modules_count_x"], value: 0 }),
        string_modules_count_y: BimProperty.NewShared({ path: ["tracker-frame", "string", "modules_count_y"], value: 0 }),
        string_modules_count: BimProperty.NewShared({ path: ["tracker-frame", "string", "modules_count"], value: 0 }),

        strings_count: BimProperty.NewShared({ path: ["tracker-frame", "dimensions", "strings_count"], value: 0}),
    };

    return new SolverPropsInObject({
        solverIdentifier: "tracker-props",
        objectsIdentifier: "tracker",
        objectsDefaultArgs,
        cache: true,
        solverFunction: (args) => {
            const string_count = args.strings_count.asNumber();
            const module_current = args.module_current.as("A");
            const module_voltage = args.module_voltage.as("V");
            const module_max_current = args.module_max_current.as("A");
            const module_max_voltage = args.module_max_voltage.as("V");
            const module_maximum_power = args.module_maximum_power.as("W");
            const string_modules_count_x = args.string_modules_count_x.asNumber();
            const string_modules_count_y = args.string_modules_count_y.asNumber();
            const string_modules_count = string_modules_count_x * string_modules_count_y;

            const stringVoltage = module_voltage * string_modules_count;
            const stringPower = module_maximum_power * string_modules_count;

            const tracker_modules_count = string_modules_count * string_count;
            const trackerDcPower = string_count * stringPower * 1e-3;

            const circuit_max_voltage = module_max_voltage;
            const circuit_operating_voltage = stringVoltage;

            const circuit_max_current = module_max_current * string_count;
            const circuit_operating_current = module_current * string_count;


            return [
                { path: ["tracker-frame", "string", "current"], value: module_current, unit: "A" },
                { path: ["tracker-frame", "string", "voltage"], value: stringVoltage, unit: "V" },
                { path: ["tracker-frame", "string", "power"], value: stringPower, unit: "W" },
                { path: ["tracker-frame", "string", "max_current"], value: module_max_current, unit: "A" },
                { path: ["tracker-frame", "string", "modules_count"], value: string_modules_count, numeric_step:1 },

                { path: ["circuit", "equipment", "modules_count"], value: tracker_modules_count },
                { path: ["circuit", "aggregated_capacity", "dc_power"], value: trackerDcPower, unit: "kW" },
                { path: ["circuit", "equipment", "strings_count"], value: string_count },
                { path: ["circuit", "aggregated_capacity", "max_current"], value: circuit_max_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "operating_current"], value: circuit_operating_current, unit: "A" },
                { path: ["circuit", "aggregated_capacity", "max_voltage"], value: circuit_max_voltage, unit: "V" },
                { path: ["circuit", "aggregated_capacity", "operating_voltage"], value: circuit_operating_voltage, unit: "V" },
            ];
        },
    });
}

export function wiresCircuitCalculator(): ReactiveSolverBase {
    const defaultInObjectArgs = {
        length: BimProperty.NewShared({ path: ["dimensions", "length"], value: 0, unit: "m" }),
        resistance: BimProperty.NewShared({ path: [ "computed_result", "resistance"], value: 0, unit: "Om" }),
    };

    return SolverPropsWithChildren.new2(
        "wire-circuits",
        "wire",
        defaultInObjectArgs,
        [
            { identifier: "sectionalizing-cabinet", args: SectionalizeCabinetCircuitArgs },
            { identifier: "transformer", args: SolarTransformerCircuitArgs },
        ],
        (inObjectArgs, [scChildren, transformerChildren]) => {

            const commonCircuitProps = new CommonSolarCircuitPropsCalculator();

            for (const child of scChildren) {
                commonCircuitProps.addFrom(child);
            }
            for (const child of transformerChildren) {
                commonCircuitProps.addAfterTransformer(child);
            }
            const ac_powerKW = commonCircuitProps.ac_power;
            const commonProps = commonCircuitProps.asKeyValue(ac_powerKW);
            const length = 3 * inObjectArgs.length.as("m");

            const lossesKW = Math.pow(commonProps.circuit_operating_current.as("A"), 2) * inObjectArgs.resistance.as("Om") * 1e-3;
            const voltage_drop = ac_powerKW === 0 || lossesKW === 0 ? 0 : lossesKW / ac_powerKW;

            const total_lossesKW = lossesKW + commonProps.circuit_wiring_total_losses.as('kW');
            const avarage_voltage_drop = ac_powerKW === 0 ? 0 : total_lossesKW / ac_powerKW;

            return [
                ...commonCircuitProps.asBimProps({includeWiring: false, includeACPower: true, includeEquipmentCount: true}),
                { path: [ "circuit", "mv_wiring", "total_losses"], value: total_lossesKW, unit: "kW" },
                { path: [ "circuit", "mv_wiring", "avarage_voltage_drop"], value: avarage_voltage_drop * 100, unit: "%" },
                { path: [ "computed_result", "length"], value: length, unit: "m" },
                { path: [ "computed_result", "losses"], value: lossesKW, unit: "kW" },
                { path: [ "computed_result", "voltage_drop"], value: voltage_drop * 100, unit: "%" },
            ];
        }
    );
}

export function wiresPropsCalculator(bim:Bim): ReactiveSolverBase {
    const objectsDefaultArgs = {
        legacyProps: {
            resistivity: BimProperty.NewShared({ path: ["commercial", "resistivity"], value: 0, unit: "Om/m" }),
        },
        representationAnalytical: null as BasicAnalyticalRepresentation | null,
        worldMatrix: new Matrix4(),
    };

    return new SolverObjectInstance({
        solverIdentifier: "wires-props",
        objectsIdentifier: "wire",
        objectsDefaultArgs,
        cache: false,
        solverFunction: (args) => {
            let length = 0;

			const geoId = args.representationAnalytical?.geometryId;
			if (geoId) {
				const polyline = bim.polylineGeometries.peekById(geoId);
				if(polyline){
					length = polyline.length()
				}
			}

            const resistivity = args.legacyProps.resistivity.as("Om/m");
            const resistance = 3 * length * resistivity;

            return {
                legacyProps: [
                    { path: ["dimensions", "length"], value: length, unit: "m" },
                    { path: [ "computed_result", "resistance"], value: resistance, unit: "Om" },
                ],
            };
        },
    });
}


export function groupCostAggregator(): ReactiveSolverBase {
    const args = {
        totalCost: BimProperty.NewShared({ path: ["cost", "total_cost"], value: 0 }),
        groupCost: BimProperty.NewShared({ path: ["cost", "group_cost"], value: 0 }),
    };

    return SolverPropsWithChildren.new10(
        'group-cost-aggregator',
        [
            'transformer',
            'inverter',
            'combiner-box',
            'tracker',
            'substation',
            'fixed-tilt',
            'lv-wire',
            'wire',
            'sectionalizing-cabinet',
            'any-tracker',
        ],
        args,
        [
            { identifier: 'transformer', args },
            { identifier: 'inverter', args },
            { identifier: 'combiner-box', args },
            { identifier: 'tracker', args },
            { identifier: 'substation', args },
            { identifier: 'fixed-tilt', args },
            { identifier: 'lv-wire', args },
            { identifier: 'wire', args },
            { identifier: 'sectionalizing-cabinet', args },
            { identifier: 'any-tracker', args },
        ],
        (inArgs, childrenArgs) => {
            let groupCost = 0;
            for (const childs of childrenArgs) {
                for (const child of childs) {
                    groupCost += child.groupCost.asNumber()
                        ? child.groupCost.asNumber() : child.totalCost.asNumber();
                }
            }
            return [
                {
                    path: ['cost', 'group_cost'],
                    unit: 'usd',
                    value: groupCost + inArgs.totalCost.asNumber(),
                },
            ]
        }
    )
}

export function groupLvWiring(): ReactiveSolverBase {
    return new SolverObjectInstanceWithChildren({
        solverIdentifier: 'groupLvWiring',
        solverFunction: (inst, globals, children) => {
            let total = 0;
            for (const group of children ?? []) {
                for (const input of group ?? []) {
                    if (!input.propsInOut) {
                        continue;
                    }
                    const anyTrackerProps = input.propsInOut as AnyTrackerProps;
                    total += anyTrackerProps.tracker_frame.dimensions.length?.as('m') ?? 0
                }
            }
            const props = inst.propsInOut;
            props.tracker_frame.commercial.series = StringProperty.new({ value: total.toString() })
            return {}
        },
        objectsIdentifier: 'any-tracker',
        objectsDefaultArgs: {
            propsInOut: new AnyTrackerProps({})
        },
        globalArgsSelector: {},
        childrenIdWithArgs: [
            { identifier: 'any-tracker', legacyPropsDefaults: {}, newPropsDefaults: new AnyTrackerProps({}) }
        ]
    })
}

export function blockCostAggregator(): ReactiveSolverBase {
    const args = {
        totalCost: BimProperty.NewShared({ path: ["cost", "total_cost"], value: 0 }),
        groupCost: BimProperty.NewShared({ path: ["cost", "group_cost"], value: 0 }),
    };

    return SolverPropsWithChildren.new6(
        'block-cost-aggregator',
        'transformer',
        args,
        [
            { identifier: 'inverter', args },
            { identifier: 'combiner-box', args },
            { identifier: 'tracker', args },
            { identifier: 'fixed-tilt', args },
            { identifier: 'lv-wire', args },
            { identifier: 'any-tracker', args },
        ],
        (inArgs, childrenArgs) => {
            let groupCost = 0;
            for (const childs of childrenArgs) {
                for (const child of childs) {
                    groupCost += child.groupCost.asNumber()
                        ? child.groupCost.asNumber() : child.totalCost.asNumber();
                }
            }
            return [
                {
                    path: ['cost', 'block_cost'],
                    unit: 'usd',
                    value: groupCost + inArgs.totalCost.asNumber(),
                },
            ]
        }
    )
}
