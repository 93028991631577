import type {
    LazyVersioned,
    LazyVersionedPollingCache,
    PollWithVersionResult,
    ResultAsync,
} from "engine-utils-ts";
import {
    convertUnits,
    DefaultMap,
    ErrorUtils,
    Failure,
    InProgress,
    IterUtils,
    LazyDerived,
    LazyDerivedAsync,
    LogLevel,
    ScopedLogger,
    Success,
    VersionedInvalidator,
    Yield,
    type Result,
} from "engine-utils-ts";
import type {
    Bim,
    Catalog,
    FarmLayoutConfig,
    IdBimScene,
    IdPile,
    PropertiesGroupFormatters,
    PropertyGroup,
    SceneInstance,
    TrackerPile,
    TrackerPilesCollection,
    UnitsMapper,
} from "..";
import {
    deserializeProjectMetricsResponse,
    NumberRangeProperty,
    serializeProjectMetricsResponse,
} from "..";
import {
    CombinerBoxTypeIdent,
    ConfigUtils,
    EnergyYieldPropsGroup,
    EnergyYieldSiteProps,
    FarmLayoutConfigType,
    FixedTiltTypeIdent,
    InverterTypeIdent,
    NumberProperty,
    PVModuleTypeIdent,
    SceneObjDiff,
    SectionalizingCabinetIdent,
    StringProperty,
    SubstationTypeIdent,
    TrackerTypeIdent,
    TransformerIdent,
    calculateGlobalRowsHeights,
    calculateRowsHeights,
} from "..";
import { LayoutMetricsUtils } from "./MetricsUtils";
import { isTrackerConnected } from "../energy/EnergyUtils";
import { AnyTrackerProps } from "../anyTracker/AnyTracker";
import { calculatePileWeight } from "src/anyTracker/PileProfileType";
import { TransformerProps } from "src/archetypes/transformer/Transformer";

const TotalMetricId = "total";

export enum AreaTypeEnum {
    AllSiteArea = "All Site Area",
    UnallocatedArea = "Unlocated Area",
    Subarea = "Subarea",
    Total = "Total",
}

export type EquipmentArea = SubareaEquipment | TotalEquipment;

interface SubareaEquipment {
    id: string;
    name: string;
    type: Exclude<AreaTypeEnum, "Total">;
    connectedTo: IdBimScene;
    areaIndex: number;
    equipment: IdBimScene[];
}

interface TotalEquipment {
    id: string;
    name: string;
    type: Extract<AreaTypeEnum, "Total">;
    equipment: IdBimScene[];
}

export interface LayoutEquipment {
    areas: EquipmentArea[];
}

export class MetricsGroup<
    T extends Partial<ProjectMetricsType> = Partial<ProjectMetricsType>,
> {
    id: string;
    type: AreaTypeEnum;
    metrics: T | null;
    areaIndex?: number;

    constructor(args: {
        id: string;
        type: AreaTypeEnum;
        metrics: T | null;
        areaIndex: number | undefined;
    }) {
        this.id = args.id;
        this.type = args.type;
        this.metrics = args.metrics;
        this.areaIndex = args.areaIndex;
    }
}

export interface ProjectMetricsType extends PropertyGroup {
    gcr: NumberProperty;
    row_to_row: NumberProperty;
    row_to_row_range: NumberRangeProperty;
    pattern: StringProperty;

    pv_modules: Record<string, NumberProperty>;
    pv_modules_area: NumberProperty;
    pv_modules_area_footprint: NumberProperty;
    pv_modules_area_buildable_area: NumberProperty;
    any_trackers: Record<string, NumberProperty>;
    trackers: Record<string, NumberProperty>;
    fixed_tilt: Record<string, NumberProperty>;
    combiner_boxes: Record<string, NumberProperty>;
    inverters: Record<string, NumberProperty>;
    transformers: Record<string, NumberProperty>;
    sectionalizing_cabinets: Record<string, NumberProperty>;
    substations: Record<string, NumberProperty>;

    footprint: NumberProperty;
    site_area: NumberProperty;
    buildable_area: NumberProperty;
    roads_total_area: NumberProperty;
    roads_total_length: NumberProperty;
    equipment_roads_width: NumberProperty;
    equipment_roads_width_range: NumberRangeProperty;
    support_roads_width: NumberProperty;
    support_roads_width_range: NumberRangeProperty;
    solar_array_row_height: Record<string, NumberProperty>;
    north_facing_trackers: NumberProperty;

    max_cb_si_circuit_height: Record<string, NumberProperty>;
    string_count: NumberProperty;
    dc_total: NumberProperty;
    dc_per_tracker: Record<string, NumberProperty>;
    dc_per_pv_module: Record<string, NumberProperty>;
    not_connected_to_transformer_dc: NumberProperty | null;
    ac_total: NumberProperty;
    dc_ac_ratio: NumberProperty;
    lv_loss: NumberProperty;
    average_lv_voltage_drop: NumberProperty;
    mv_loss: NumberProperty;
    average_mv_voltage_drop: NumberProperty;

    trenches_volume: NumberProperty;
    trenches_length: NumberProperty;
    piles_weight: NumberProperty;
    cut: NumberProperty;
    fill: NumberProperty;
    cut_fill_total: NumberProperty;
    cut_fill_net_balance: NumberProperty;
    cut_fill_total_area: NumberProperty;
    cut_area: NumberProperty;
    fill_area: NumberProperty;

    energy_yield_total: NumberProperty;
    energy_yield_daily: NumberProperty;
    energy_performance: NumberProperty;
    specific_annual_yield: NumberProperty;

    construction_cost_breakdown: Record<string, NumberProperty>;
    construction_cost_per_DC_Watt: Record<string, NumberProperty>;
    construction_total_cost: NumberProperty | null;
    construction_total_cost_per_DC_Watt: NumberProperty | null;

    lcoe: Record<string, NumberProperty>;
}

const trackersTypeIdents = [
    TrackerTypeIdent,
    FixedTiltTypeIdent,
    "any-tracker",
];

export type EquipmentMetrics = Partial<
    Pick<
        ProjectMetricsType,
        | "pv_modules"
        | "trackers"
        | "any_trackers"
        | "fixed_tilt"
        | "combiner_boxes"
        | "inverters"
        | "transformers"
        | "sectionalizing_cabinets"
        | "substations"
    >
>;

type EquipmentMapTo = {
    toType: string;
    countFn: (si: SceneInstance) => number | null;
};

class EquipmentMetricsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<EquipmentMetrics>[]>>;
    constructor(
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        bim: Bim,
        catalog: Catalog,
        logger: ScopedLogger,
    ) {
        this.name = "equipment-metrics-lazy";
        const electricalEquipmentTypes: string[] = [
            CombinerBoxTypeIdent,
            InverterTypeIdent,
            TransformerIdent,
            SectionalizingCabinetIdent,
            SubstationTypeIdent,
        ];
        const equipmentTypes: string[] = [
            PVModuleTypeIdent,
            ...trackersTypeIdents,
            ...electricalEquipmentTypes,
        ];
        const equipmentTypesSet = new Set(equipmentTypes);
        const equipmentMappings = new Map<string, EquipmentMapTo[]>();
        for (const type of trackersTypeIdents) {
            const mapping: EquipmentMapTo[] = [
                {
                    toType: type,
                    countFn: () => 1,
                },
                {
                    toType: PVModuleTypeIdent,
                    countFn: (si) =>
                        si.properties
                            .get("circuit | equipment | modules_count")
                            ?.asNumber() ?? null,
                },
            ];
            equipmentMappings.set(type, mapping);
        }

        for (const type of electricalEquipmentTypes) {
            const mapTo: EquipmentMapTo[] = [
                { toType: type, countFn: () => 1 },
            ];
            equipmentMappings.set(type, mapTo);
        }

        const lazyListOfInstances = bim.instances.getLazyListOfCollection({
            relevantUpdateFlags:
                SceneObjDiff.LegacyProps | SceneObjDiff.NewProps,
        });
        this.calculator = LazyDerivedAsync.new1(
            this.name,
            [lazyListOfInstances],
            [layoutEquipmentLazy],
            function* ([layoutEquipment]) {
                const result: MetricsGroup<EquipmentMetrics>[] = [];

                for (const area of layoutEquipment.areas) {
                    yield Yield.Asap;
                    const equipment = bim.instances.peekByIds(area.equipment);
                    const equipmentsPerType = new DefaultMap<
                        string,
                        DefaultMap<string, { count: number }>
                    >(() => new DefaultMap(() => ({ count: 0 })));
                    for (const [id, si] of equipment) {
                        if (!equipmentTypesSet.has(si.type_identifier)) {
                            continue;
                        }
                        const mappings = equipmentMappings.get(
                            si.type_identifier,
                        );
                        if (!mappings) {
                            logger.error(
                                `Mappings not found for ${si.type_identifier}`,
                                id,
                            );
                            continue;
                        }
                        for (const mapTo of mappings) {
                            const equipmentName =
                                catalog.keyPropertiesGroupFormatters.format(
                                    mapTo.toType,
                                    si.properties,
                                    si.props,
                                );
                            const count = mapTo.countFn(si);

                            if (!equipmentName || count === null) {
                                logger.error(
                                    `Equipment name or count not found for ${si.type_identifier}`,
                                    id,
                                    count,
                                );
                                continue;
                            }
                            equipmentsPerType
                                .getOrCreate(mapTo.toType)
                                .getOrCreate(equipmentName).count += count;
                        }
                    }
                    const metrics: Record<
                        string,
                        Record<string, NumberProperty>
                    > = {};
                    for (const type of equipmentTypes) {
                        const equipments = equipmentsPerType.get(type);
                        if (!equipments) {
                            continue;
                        }
                        if (!metrics[type]) {
                            metrics[type] = {};
                        }
                        for (const [equipmentName, stats] of equipments) {
                            metrics[type][equipmentName] = NumberProperty.new({
                                value: stats.count,
                                step: 1,
                                isReadonly: true,
                            });
                        }
                    }
                    const equipmentMetrics: Partial<EquipmentMetrics> = {
                        pv_modules: metrics[PVModuleTypeIdent],
                        trackers: metrics[TrackerTypeIdent],
                        any_trackers: metrics["any-tracker"],
                        fixed_tilt: metrics[FixedTiltTypeIdent],
                        combiner_boxes: metrics[CombinerBoxTypeIdent],
                        inverters: metrics[InverterTypeIdent],
                        transformers: metrics[TransformerIdent],
                        sectionalizing_cabinets:
                            metrics[SectionalizingCabinetIdent],
                        substations: metrics[SubstationTypeIdent],
                    };

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: equipmentMetrics,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }

                return result;
            },
        );
    }
}

export type FarmLayoutConfigSubAreaMetrics = Partial<
    Pick<
        ProjectMetricsType,
        | "row_to_row"
        | "row_to_row_range"
        | "equipment_roads_width"
        | "equipment_roads_width_range"
        | "support_roads_width"
        | "support_roads_width_range"
        | "pattern"
    >
>;

class LayoutInputMetrics implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<
        Result<MetricsGroup<FarmLayoutConfigSubAreaMetrics>[]>
    >;

    constructor(
        bim: Bim,
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        logger: ScopedLogger,
    ) {
        this.name = "layout-input-metrics";

        this.calculator = LazyDerived.new2(
            this.name,
            null,
            [
                bim.configs.getLazyListOf({
                    type_identifier: FarmLayoutConfigType,
                }),
                layoutEquipmentLazy,
            ],
            ([configs, layoutEquipment]) => {
                const configsMap = IterUtils.newMapFromIter(
                    configs,
                    ([_, c]) => c.connectedTo,
                    ([_, c]) => c,
                );

                const result: MetricsGroup<FarmLayoutConfigSubAreaMetrics>[] =
                    [];
                for (const area of layoutEquipment.areas) {
                    if (area.type === AreaTypeEnum.Total) {
                        continue;
                    }
                    const config = configsMap.get(area.connectedTo);
                    if (!config) {
                        logger.error(`Config not found for ${area.name}`);
                        continue;
                    }
                    if (area.areaIndex === undefined) {
                        // Total skip for layout input metrics
                        continue;
                    }
                    const settings =
                        config.get<FarmLayoutConfig>().site_areas[
                            area.areaIndex
                        ].settings;
                    if (!settings) {
                        logger.error(`Subarea not found for ${area.name}`);
                        continue;
                    }
                    const layoutInput: FarmLayoutConfigSubAreaMetrics = {
                        equipment_roads_width: NumberProperty.new({
                            ...settings.roads.equipment_road_width,
                            isReadonly: true,
                        }),
                        support_roads_width: NumberProperty.new({
                            ...settings.roads.support_road_width,
                            isReadonly: true,
                        }),
                        row_to_row: NumberProperty.new({
                            ...settings.spacing.row_to_row_space,
                            isReadonly: true,
                        }),
                        pattern: StringProperty.new({
                            ...settings.electrical.scheme,
                            isReadonly: true,
                        }),
                    };
                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: layoutInput,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }
                const totalArea = layoutEquipment.areas.find(
                    (a) => a.type === AreaTypeEnum.Total,
                );
                if (totalArea) {
                    const minRowToRow = IterUtils.min(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.row_to_row?.value,
                        ),
                    );
                    const maxRowToRow = IterUtils.max(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.row_to_row?.value,
                        ),
                    );
                    const supportRoadsWidthMin = IterUtils.min(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.support_roads_width?.value,
                        ),
                    );
                    const supportRoadsWidthMax = IterUtils.max(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.support_roads_width?.value,
                        ),
                    );
                    const equipmentRoadsWidthMin = IterUtils.min(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.equipment_roads_width?.value,
                        ),
                    );
                    const equipmentRoadsWidthMax = IterUtils.max(
                        IterUtils.filterMap(
                            result,
                            (r) => r.metrics?.equipment_roads_width?.value,
                        ),
                    );
                    result.push(
                        new MetricsGroup({
                            id: totalArea.id,
                            type: totalArea.type,
                            metrics: {
                                row_to_row_range:
                                    minRowToRow != null && maxRowToRow != null
                                        ? NumberRangeProperty.new({
                                              value: [minRowToRow, maxRowToRow],
                                              unit: "ft",
                                          })
                                        : undefined,
                                support_roads_width_range:
                                    supportRoadsWidthMin != null &&
                                    supportRoadsWidthMax != null
                                        ? NumberRangeProperty.new({
                                              value: [
                                                  supportRoadsWidthMin,
                                                  supportRoadsWidthMax,
                                              ],
                                              unit: "ft",
                                          })
                                        : undefined,
                                equipment_roads_width_range:
                                    equipmentRoadsWidthMin != null &&
                                    equipmentRoadsWidthMax != null
                                        ? NumberRangeProperty.new({
                                              value: [
                                                  equipmentRoadsWidthMin,
                                                  equipmentRoadsWidthMax,
                                              ],
                                              unit: "ft",
                                          })
                                        : undefined,
                            },
                            areaIndex: extractAreaIndex(totalArea),
                        }),
                    );
                }

                return new Success(result);
            },
        );
    }
}
export type LayoutRowHeights = Partial<
    Pick<
        ProjectMetricsType,
        "max_cb_si_circuit_height" | "solar_array_row_height"
    >
>;
class LayoutRowHeightsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<LayoutRowHeights>[]>>;

    constructor(
        bim: Bim,
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        logger: ScopedLogger,
    ) {
        this.name = "layout-row-heights-metrics-lazy";
        const sceneInstancesLazy = bim.instances.getLazyListOfTypes({
            type_identifiers: [...trackersTypeIdents, TransformerIdent],
            relevantUpdateFlags:
                SceneObjDiff.Representation |
                SceneObjDiff.RepresentationAnalytical |
                SceneObjDiff.WorldPosition |
                SceneObjDiff.SpatialChildrenList |
                SceneObjDiff.SpatialParentRef,
        });
        this.calculator = LazyDerivedAsync.new1(
            this.name,
            [sceneInstancesLazy],
            [layoutEquipmentLazy],
            function* ([layoutEquipment]) {
                const result: MetricsGroup<LayoutRowHeights>[] = [];

                for (const area of layoutEquipment.areas) {
                    yield Yield.NextFrame;

                    const trackerParentIds = new Set<IdBimScene>();
                    const instances = bim.instances.peekByIds(area.equipment);
                    for (const [_id, inst] of instances) {
                        if (!inst.spatialParentId) {
                            continue;
                        }
                        if (trackersTypeIdents.includes(inst.type_identifier)) {
                            trackerParentIds.add(inst.spatialParentId);
                        }
                    }
                    yield Yield.Asap;

                    const totalHeights = yield* calculateGlobalRowsHeights(
                        bim,
                        area.equipment,
                    );

                    const heights = new DefaultMap<number, { count: number }>(
                        () => ({ count: 0 }),
                    );
                    let counter = 0;
                    for (const trackerParent of trackerParentIds) {
                        counter++;
                        const heightPreference = calculateRowsHeights(
                            bim,
                            trackerParent,
                        );
                        heights.getOrCreate(
                            IterUtils.max(heightPreference.keys()) ?? 0,
                        ).count += 1;
                        if (counter % 5 === 0) {
                            yield Yield.Asap;
                        }
                    }
                    yield Yield.Asap;

                    const metric: LayoutRowHeights = {};

                    if (heights.size) {
                        metric.max_cb_si_circuit_height = {};
                        metric.solar_array_row_height = {};
                        const sortedHeights = Array.from(
                            heights.entries(),
                        ).sort((a, b) => a[0] - b[0]);
                        for (const [height, stat] of sortedHeights) {
                            const name =
                                "Solar arrays circuit length " + height;
                            const prop = NumberProperty.new({
                                value: stat.count,
                                isReadonly: true,
                                step: 1,
                            });
                            metric.max_cb_si_circuit_height[name] = prop;
                        }

                        const sortedTotalHeights = Array.from(
                            totalHeights.entries(),
                        ).sort((a, b) => a[0] - b[0]);
                        for (const [height, stat] of sortedTotalHeights) {
                            const name = "Row height " + height;
                            const prop = NumberProperty.new({
                                value: stat,
                                isReadonly: true,
                                step: 1,
                            });
                            metric.solar_array_row_height[name] = prop;
                        }
                    }

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: metric,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }

                return result;
            },
        );
    }
}

export type GeneralMetrics = Partial<
    Pick<
        ProjectMetricsType,
        | "gcr"
        | "pv_modules_area"
        | "pv_modules_area_footprint"
        | "pv_modules_area_buildable_area"
    >
>;

class GeneralMetricsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<GeneralMetrics>[]>>;

    constructor(
        bim: Bim,
        catalog: Catalog,
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        logger: ScopedLogger,
    ) {
        this.name = "general-metrics-lazy";

        const metricsUtils = new LayoutMetricsUtils(bim, logger);
        const farmConfigsLazy = bim.configs.getLazyListOf({
            type_identifier: FarmLayoutConfigType,
        });
        this.calculator = LazyDerivedAsync.new2(
            this.name,
            null,
            [layoutEquipmentLazy, farmConfigsLazy],
            function* ([layoutEquipment, configs]) {
                const result: MetricsGroup<GeneralMetrics>[] = [];
                for (let i = 0; i < layoutEquipment.areas.length; i++) {
                    yield Yield.Asap;
                    const area = layoutEquipment.areas[i];
                    const perSubstation = new Map<IdBimScene, IdBimScene[]>();
                    if (area.type === AreaTypeEnum.Total) {
                        for (const [_, config] of configs) {
                            perSubstation.set(
                                config.connectedTo,
                                bim.instances.spatialHierarchy.gatherIdsWithSubtreesOf(
                                    { ids: [config.connectedTo] },
                                ),
                            );
                        }
                    } else {
                        perSubstation.set(area.connectedTo, area.equipment);
                    }

                    const gcr = metricsUtils.calculateGCR(
                        configs,
                        perSubstation,
                    );

                    const modulesByName = new DefaultMap<
                        string,
                        { count: number }
                    >(() => ({ count: 0 }));
                    const pvModuleDimensions = new Map<
                        string,
                        { length: NumberProperty; width: NumberProperty }
                    >();
                    const instances = bim.instances.peekByIds(area.equipment);
                    for (const [_id, si] of instances) {
                        if (!trackersTypeIdents.includes(si.type_identifier)) {
                            continue;
                        }
                        const equipmentName =
                            catalog.keyPropertiesGroupFormatters.format(
                                si.type_identifier,
                                si.properties,
                                si.props,
                            );
                        const count =
                            si.properties
                                .get("circuit | equipment | modules_count")
                                ?.asNumber() ?? null;
                        if (count && equipmentName) {
                            modulesByName.getOrCreate(equipmentName).count +=
                                count;
                            let length: number | undefined;
                            let width: number | undefined;
                            if (si.props instanceof AnyTrackerProps) {
                                length = si.props.module.length.as("ft");
                                width = si.props.module.width.as("ft");
                            } else {
                                length = si.properties
                                    .get("module | length")
                                    ?.as("ft");
                                width = si.properties
                                    .get("module | width")
                                    ?.as("ft");
                            }

                            if (
                                length !== undefined &&
                                width !== undefined &&
                                !pvModuleDimensions.has(equipmentName)
                            ) {
                                pvModuleDimensions.set(equipmentName, {
                                    length: NumberProperty.new({
                                        value: length,
                                        unit: "ft",
                                    }),
                                    width: NumberProperty.new({
                                        value: width,
                                        unit: "ft",
                                    }),
                                });
                            }
                        }
                    }

                    const moduleArea = metricsUtils.calculateTotalModulesArea(
                        modulesByName,
                        pvModuleDimensions,
                    );
                    const metric: GeneralMetrics = {
                        gcr: gcr,
                        pv_modules_area: moduleArea,
                        pv_modules_area_footprint: undefined,
                        pv_modules_area_buildable_area: undefined,
                    };

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: metric,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }

                return result;
            },
        );
    }
}

type PilesMetrics = Partial<Pick<ProjectMetricsType, "piles_weight">>;

class PilesMetricsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<PilesMetrics>[]>>;
    constructor(
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        pilesCollection: TrackerPilesCollection,
        unitMapper: UnitsMapper,
        logger: ScopedLogger,
    ) {
        this.name = "piles-metrics-lazy";
        this.calculator = LazyDerivedAsync.new2(
            this.name,
            [],
            [layoutEquipmentLazy, pilesCollection],
            function* ([layoutEquipment, piles]) {
                const result: MetricsGroup<PilesMetrics>[] = [];
                for (const area of layoutEquipment.areas) {
                    yield Yield.Asap;
                    const piles: [IdPile, TrackerPile][] = [];
                    for (const id of area.equipment) {
                        const trackerPiles =
                            pilesCollection.pilesPerTrackerId.iter(id);
                        const pilesMap =
                            pilesCollection.peekByIds(trackerPiles);
                        for (const [pileId, pile] of pilesMap) {
                            piles.push([pileId, pile]);
                        }
                    }

                    let totalWeightKg = 0;
                    for (const [_, pile] of piles) {
                        const weight = calculatePileWeight(
                            pile.shape,
                            pile.getLength(),
                        );
                        if (weight instanceof Failure) {
                            logger.error(
                                "Failed to calculate pile weight",
                                weight.errorMsg(),
                            );
                            continue;
                        }
                        const weightKg = convertUnits(
                            weight.value.value,
                            weight.value.unit,
                            "kg",
                        );
                        if (weightKg instanceof Success) {
                            totalWeightKg += weightKg.value;
                        } else {
                            logger.error(
                                "Failed to convert weight to kg",
                                weightKg.errorMsg(),
                            );
                        }
                    }

                    const pilesMetrics: PilesMetrics = {
                        piles_weight: NumberProperty.new({
                            value: totalWeightKg,
                            unit: "kg",
                        }),
                    };

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: pilesMetrics,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }
                return result;
            },
        );
    }
}

export type CivilMetrics = Partial<
    Pick<
        ProjectMetricsType,
        | "cut"
        | "fill"
        | "cut_fill_total"
        | "cut_fill_net_balance"
        | "cut_area"
        | "fill_area"
        | "cut_fill_total_area"
        | "north_facing_trackers"
        | "trenches_volume"
        | "trenches_length"
        | "string_count"
        | "roads_total_length"
        | "roads_total_area"
    >
>;

class CivilMetricsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<CivilMetrics>[]>>;

    constructor(
        bim: Bim,
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        logger: ScopedLogger,
    ) {
        const lazyListOfInstances = bim.instances.getLazyListOfCollection({
            relevantUpdateFlags:
                SceneObjDiff.LegacyProps | SceneObjDiff.NewProps,
        });

        this.name = "civil-metrics-lazy";
        const qtyProcessingPerType =
            LayoutMetricsUtils.createQtyCalculatorsPerType();
        this.calculator = LazyDerivedAsync.new1(
            this.name,
            [lazyListOfInstances],
            [layoutEquipmentLazy],
            function* ([layoutEquipment]) {
                const result: MetricsGroup<CivilMetrics>[] = [];
                for (const area of layoutEquipment.areas) {
                    yield Yield.Asap;
                    const qtyProps = new DefaultMap<
                        string,
                        { count: number; unit?: string }
                    >(() => ({ count: 0 }));
                    let northFacingTrackersCount: number = 0;
                    const items = bim.instances.peekByIds(area.equipment);
                    for (const [id, si] of items) {
                        // quantities
                        for (const fn of qtyProcessingPerType.getOrCreate(
                            si.type_identifier,
                        )) {
                            const items = fn(si);
                            for (const { amount, name, unit } of items) {
                                const prop = qtyProps.getOrCreate(name);
                                prop.count += amount;
                                prop.unit = unit;
                            }
                        }
                        // trackers
                        if (
                            ["tracker", "fixed-tilt"].includes(
                                si.type_identifier,
                            )
                        ) {
                            // north-facing stats
                            const facingDirection = si.properties
                                .get("position | slope-direction")
                                ?.asText();
                            if (facingDirection === "north-facing") {
                                northFacingTrackersCount++;
                            }
                        }
                    }

                    const perNumberProp: Record<string, NumberProperty> = {};
                    if (qtyProps.size) {
                        for (const [name, stat] of qtyProps) {
                            perNumberProp[name] = NumberProperty.new({
                                value: stat.count,
                                unit: stat.unit,
                                isReadonly: true,
                                step: !stat.unit ? 1 : undefined,
                            });
                        }
                    }

                    if (northFacingTrackersCount) {
                        perNumberProp["north_facing_trackers"] =
                            NumberProperty.new({
                                value: northFacingTrackersCount,
                                step: 1,
                                isReadonly: true,
                            });
                    }

                    const civilMetrics: CivilMetrics = {
                        cut: perNumberProp["cut"],
                        fill: perNumberProp["fill"],
                        cut_fill_net_balance: perNumberProp["cut_fill_net_balance"],
                        cut_fill_total: perNumberProp["cut_fill_total"],
                        north_facing_trackers: perNumberProp["north_facing_trackers"],
                        trenches_volume: perNumberProp["trenches_volume"],
                        trenches_length: perNumberProp["trenches_length"],
                        string_count: perNumberProp["string_count"],
                        roads_total_area: perNumberProp["roads_total_area"],
                        roads_total_length: perNumberProp["roads_total_length"],
                        cut_fill_total_area: perNumberProp["cut_fill_total_area"],
                        cut_area: perNumberProp["cut_area"],
                        fill_area: perNumberProp["fill_area"],
                    };

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: civilMetrics,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                }

                return result;
            },
        );
    }
}

export type PowerMetricsType = Pick<
    ProjectMetricsType,
    | "ac_total"
    | "dc_total"
    | "dc_per_tracker"
    | "dc_per_pv_module"
    | "dc_ac_ratio"
    | "lv_loss"
    | "average_lv_voltage_drop"
    | "mv_loss"
    | "average_mv_voltage_drop"
    | "not_connected_to_transformer_dc"
>;

export class PowerMetricsCalculator implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<ResultAsync<MetricsGroup<PowerMetricsType>[]>>;
    constructor(
        layoutEquipmentLazy: LazyVersioned<LayoutEquipment>,
        bim: Bim,
        keyPropsGroupFormatter: PropertiesGroupFormatters,
        logger: ScopedLogger,
    ) {
        this.name = "power-metric-lazy";
        const instances = bim.instances.getLazyListOfTypes({
            type_identifiers: [TransformerIdent, SubstationTypeIdent],
            relevantUpdateFlags: SceneObjDiff.LegacyProps,
        });

        this.calculator = LazyDerivedAsync.new1(
            this.name,
            [instances],
            [layoutEquipmentLazy],
            function* ([layoutEquipment]) {
                yield Yield.NextFrame;
                const result: MetricsGroup<PowerMetricsType>[] = [];
                for (const area of layoutEquipment.areas) {
                    let totalAcPowerKWatt = 0;
                    let totalMvLossesKWatt = 0;
                    let totalLvLossesKWatt = 0;
                    const dcPowerPerTracker = new DefaultMap<
                        string,
                        { powerDckW: number }
                    >(() => ({ powerDckW: 0 }));
                    const dcPowerPerPvModule = new DefaultMap<
                        string,
                        { powerDckW: number }
                    >(() => ({ powerDckW: 0 }));
                    const instances = bim.instances.peekByIds(area.equipment);
                    let not_connected_to_transformer_dc_kW = 0;
                    for (const [id, si] of instances) {
                        if (si.type_identifier === SubstationTypeIdent) {
                            totalMvLossesKWatt +=
                                si.properties
                                    .get("circuit | mv_wiring | total_losses")
                                    ?.as("kW") ?? 0;
                        }
                        if (si.type_identifier === TransformerIdent) {
                            totalAcPowerKWatt +=
                                si.properties
                                    .get("circuit | block_capacity | ac_power")
                                    ?.as("kW") ?? 0;
                            const props = si.propsAs(TransformerProps);
                            totalLvLossesKWatt +=
                                props.lv_wiring?.total_losses?.as('kW') ?? 0;
                        }
                        if (trackersTypeIdents.includes(si.type_identifier)) {
                            const powerDc =
                                si.properties
                                    .get(
                                        "circuit | aggregated_capacity | dc_power",
                                    )
                                    ?.as("kW") ?? 0;
                            const name = keyPropsGroupFormatter.format(
                                si.type_identifier,
                                si.properties,
                                si.props,
                            );

                            if (name) {
                                const tracker =
                                    dcPowerPerTracker.getOrCreate(name);
                                tracker.powerDckW += powerDc;
                            } else {
                                logger.error(
                                    `Name not found for ${si.type_identifier}`,
                                    id,
                                );
                            }

                            const pvModuleName = keyPropsGroupFormatter.format(
                                PVModuleTypeIdent,
                                si.properties,
                                si.props,
                            );
                            if (pvModuleName) {
                                const pvModule =
                                    dcPowerPerPvModule.getOrCreate(
                                        pvModuleName,
                                    );
                                pvModule.powerDckW += powerDc;
                            } else {
                                logger.error(
                                    `Name not found for pv-module`,
                                    id,
                                );
                            }
                            if (!isTrackerConnected(id, bim)) {
                                not_connected_to_transformer_dc_kW += powerDc;
                            }
                        }
                    }

                    let totalDcPowerKWatt = 0;
                    const dc_per_tracker: Record<string, NumberProperty> = {};
                    for (const [name, power] of dcPowerPerTracker) {
                        totalDcPowerKWatt += power.powerDckW;
                        dc_per_tracker[name] = NumberProperty.new({
                            value: power.powerDckW,
                            unit: "kW",
                            isReadonly: true,
                        });
                    }

                    const dc_per_pv_module: Record<string, NumberProperty> = {};
                    for (const [name, power] of dcPowerPerPvModule) {
                        dc_per_pv_module[name] = NumberProperty.new({
                            value: power.powerDckW,
                            unit: "kW",
                            isReadonly: true,
                        });
                    }

                    const powerMetrics: PowerMetricsType = {
                        ac_total: NumberProperty.new({
                            value: totalAcPowerKWatt,
                            unit: "kW",
                            isReadonly: true,
                        }),
                        dc_total: NumberProperty.new({
                            value: totalDcPowerKWatt,
                            unit: "kW",
                            isReadonly: true,
                        }),
                        dc_per_tracker: dc_per_tracker,
                        dc_per_pv_module: dc_per_pv_module,
                        dc_ac_ratio: NumberProperty.new({
                            value:
                                totalDcPowerKWatt && totalAcPowerKWatt
                                    ? totalDcPowerKWatt / totalAcPowerKWatt
                                    : 0,
                            isReadonly: true,
                        }),
                        lv_loss: NumberProperty.new({
                            value: totalLvLossesKWatt,
                            unit: "kW",
                            isReadonly: true,
                        }),
                        average_lv_voltage_drop: NumberProperty.new({
                            value:
                                totalLvLossesKWatt && totalDcPowerKWatt
                                    ? (totalLvLossesKWatt / totalDcPowerKWatt) *
                                      100
                                    : 0,
                            unit: "%",
                            isReadonly: true,
                        }),
                        mv_loss: NumberProperty.new({
                            value: totalMvLossesKWatt,
                            unit: "kW",
                            isReadonly: true,
                        }),
                        average_mv_voltage_drop: NumberProperty.new({
                            value:
                                totalMvLossesKWatt && totalAcPowerKWatt
                                    ? (totalMvLossesKWatt / totalAcPowerKWatt) *
                                      100
                                    : 0,
                            unit: "%",
                            isReadonly: true,
                        }),
                        not_connected_to_transformer_dc:
                            not_connected_to_transformer_dc_kW
                                ? NumberProperty.new({
                                      value: not_connected_to_transformer_dc_kW,
                                      unit: "kW",
                                      isReadonly: true,
                                  })
                                : null,
                    };

                    result.push(
                        new MetricsGroup({
                            id: area.id,
                            type: area.type,
                            metrics: powerMetrics,
                            areaIndex: extractAreaIndex(area),
                        }),
                    );
                    yield Yield.Asap;
                }

                return result;
            },
        );
    }
}

type BuildableAreaMetricType = Pick<
    ProjectMetricsType,
    "buildable_area" | "site_area"
>;
class BuildableAreaMetric implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<
        ResultAsync<MetricsGroup<BuildableAreaMetricType>[]>
    >;

    constructor(
        private readonly bim: Bim,
        private readonly logger: ScopedLogger,
    ) {
        this.name = "buildable-area-metric";
        const boundaryList = bim.instances.getLazyListOf({
            type_identifier: "boundary",
            relevantUpdateFlags:
                SceneObjDiff.NewProps |
                SceneObjDiff.LegacyProps |
                SceneObjDiff.WorldPosition |
                SceneObjDiff.GeometryReferenced |
                SceneObjDiff.RepresentationAnalytical,
        });
        this.calculator = LazyDerivedAsync.new1(
            "buildable-area",
            [],
            [boundaryList],
            function* ([instances]) {
                yield Yield.Asap;
                const layoutMetrics = new LayoutMetricsUtils(bim, logger);
                const areas =
                    yield* layoutMetrics.calculateBuildableArea(instances);
                return [
                    new MetricsGroup({
                        id: TotalMetricId,
                        type: AreaTypeEnum.Total,
                        metrics: {
                            buildable_area: areas.buildable_area,
                            site_area: areas.site_area,
                        },
                        areaIndex: undefined,
                    }),
                ];
            },
        );
    }
}

type FootprintAreaMetricType = Pick<ProjectMetricsType, "footprint">;

class FootprintAreaMetric implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<
        ResultAsync<MetricsGroup<FootprintAreaMetricType>[]>
    >;

    constructor(
        private readonly bim: Bim,
        private readonly logger: ScopedLogger,
    ) {
        this.name = "footprint-area-metric";
        const instancesList = this.bim.instances.getLazyListOfTypes({
            type_identifiers: [
                ...trackersTypeIdents,
                CombinerBoxTypeIdent,
                InverterTypeIdent,
                TransformerIdent,
                SectionalizingCabinetIdent,
                SubstationTypeIdent,
            ],
            relevantUpdateFlags:
                SceneObjDiff.WorldPosition |
                SceneObjDiff.Representation |
                SceneObjDiff.RepresentationAnalytical,
        });
        this.calculator = LazyDerivedAsync.new1(
            "buildable-area",
            [],
            [instancesList],
            function* ([instances]) {
                yield Yield.Asap;

                const layoutMetrics = new LayoutMetricsUtils(bim, logger);
                const substations: IdBimScene[] = [];
                for (const [id, inst] of instances) {
                    if (inst.type_identifier === SubstationTypeIdent) {
                        substations.push(id);
                    }
                }
                const footprint =
                    yield* layoutMetrics.calculateFootprintArea(substations);

                return [
                    new MetricsGroup({
                        id: TotalMetricId,
                        type: AreaTypeEnum.Total,
                        metrics: {
                            footprint,
                        },
                        areaIndex: undefined,
                    }),
                ];
            },
        );
    }
}

type EnergyYieldMetricsType = Partial<
    Pick<
        ProjectMetricsType,
        | "energy_yield_total"
        | "energy_yield_daily"
        | "energy_performance"
        | "specific_annual_yield"
    >
>;

class EnergyYieldMetrics implements ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<Result<MetricsGroup<EnergyYieldMetricsType>[]>>;
    constructor(bim: Bim, logger: ScopedLogger) {
        const energyYieldPropsLazy =
            bim.runtimeGlobals.getAsLazyVersionedByIdent(
                EnergyYieldSiteProps,
                EnergyYieldPropsGroup,
            );
        this.name = "energy-yield-metrics";
        this.calculator = LazyDerived.new1(
            this.name,
            null,
            [energyYieldPropsLazy],
            ([props]) => {
                const result: MetricsGroup<EnergyYieldMetricsType>[] = [];
                if (props instanceof Success) {
                    const energyYieldProps: EnergyYieldMetricsType = {
                        energy_performance: props.value.performance_ratio,
                        energy_yield_total: props.value.annual_yield,
                        energy_yield_daily: props.value.daily_yield,
                        specific_annual_yield:
                            props.value.specific_annual_yield,
                    };
                    result.push(
                        new MetricsGroup({
                            id: TotalMetricId,
                            type: AreaTypeEnum.Total,
                            metrics: energyYieldProps,
                            areaIndex: undefined,
                        }),
                    );
                } else if (props instanceof InProgress) {
                    //skip
                } else if (props instanceof Failure) {
                    logger.error(
                        "Energy yield props failed: ",
                        props.errorMsg(),
                    );
                } else {
                    logger.error("Energy yield props not found");
                }
                return new Success(result);
            },
        );
    }
}

export type CostReportRelatedMetrics = Partial<
    Pick<
        ProjectMetricsType,
        | "construction_cost_breakdown"
        | "construction_cost_per_DC_Watt"
        | "construction_total_cost"
        | "construction_total_cost_per_DC_Watt"
        | "lcoe"
    >
> | null;

export class CostMetricsGroup implements ProjectMetricCalculator {
    name: string;
    calculator: LazyDerivedAsync<MetricsGroup[]>;
    constructor(
        costMetrics: LazyVersioned<ResultAsync<CostReportRelatedMetrics>>,
    ) {
        this.name = "cost-metrics";
        this.calculator = LazyDerivedAsync.new1(
            this.name,
            null,
            [costMetrics],
            function* ([props]) {
                yield Yield.Asap;
                const result: MetricsGroup[] = [];
                result.push(
                    new MetricsGroup({
                        id: TotalMetricId,
                        type: AreaTypeEnum.Total,
                        metrics: props,
                        areaIndex: undefined,
                    }),
                );
                return result;
            },
        );
    }
}

interface ProjectMetricCalculator {
    name: string;
    calculator: LazyVersioned<
        ResultAsync<MetricsGroup[]> | Result<MetricsGroup[]>
    >;
    dispose?(): void;
}

export class LayoutMetricsLazyCalculators {
    private readonly _calculators = new Map<string, ProjectMetricCalculator>();

    constructor(private readonly logger: ScopedLogger) {}

    register(metrics: ProjectMetricCalculator) {
        if (this._calculators.has(metrics.name)) {
            ErrorUtils.logThrow(
                "Calculator with the same name already exists",
                metrics,
            );
        }
        this._calculators.set(metrics.name, metrics);
    }

    build() {
        const logger = this.logger;
        return LazyDerivedAsync.fromArr(
            "combined-metrics-lazy-calculators",
            null,
            IterUtils.mapIter(this._calculators.values(), (v) => v.calculator),
            function* (metrics) {
                yield Yield.NextFrame;
                return mergeMetricsGroups(metrics, logger);
            },
        ).withThrottling(250);
    }

    dispose() {
        for (const calculator of this._calculators.values()) {
            calculator.dispose?.();
        }
    }
}

export interface KeyMetrics extends PropertyGroup {
    totalAcPower: NumberProperty;
    totalDcPower: NumberProperty;
    totalLosses: NumberProperty;
    buildableArea: NumberProperty | null;
    footprintArea: NumberProperty;
    moduleArea: NumberProperty;
    gcr: NumberProperty;
}

function createKeyMetricsLazy(
    buildableArea: BuildableAreaMetric,
    footprintArea: FootprintAreaMetric,
    powerMetrics: PowerMetricsCalculator,
    generalMetrics: GeneralMetricsCalculator,
) {
    return LazyDerivedAsync.new4(
        "key-metrics-lazy",
        [],
        [
            buildableArea.calculator,
            footprintArea.calculator,
            powerMetrics.calculator,
            generalMetrics.calculator,
        ],
        function* ([
            buildableAreaMetrics,
            footprintAreaMetrics,
            powerMetrics,
            generalMetrics,
        ]) {
            yield Yield.NextFrame;
            const power = powerMetrics.find(
                (m) => m.type === AreaTypeEnum.Total,
            )?.metrics;
            const general = generalMetrics.find(
                (m) => m.type === AreaTypeEnum.Total,
            )?.metrics;
            const buildableArea = buildableAreaMetrics.find(
                (m) => m.type === AreaTypeEnum.Total,
            )?.metrics;
            const footprintArea = footprintAreaMetrics.find(
                (m) => m.type === AreaTypeEnum.Total,
            )?.metrics;

            const keyMetrics: KeyMetrics = {
                totalAcPower:
                    power?.ac_total ??
                    NumberProperty.new({
                        value: 0,
                        unit: "kW",
                        isReadonly: true,
                    }),
                totalDcPower:
                    power?.dc_total ??
                    NumberProperty.new({
                        value: 0,
                        unit: "kW",
                        isReadonly: true,
                    }),
                totalLosses:
                    power?.mv_loss ??
                    NumberProperty.new({
                        value: 0,
                        unit: "kW",
                        isReadonly: true,
                    }),
                buildableArea: buildableArea?.buildable_area ?? null,
                footprintArea:
                    footprintArea?.footprint ??
                    NumberProperty.new({
                        value: 0,
                        unit: "ha",
                        isReadonly: true,
                    }),
                gcr:
                    general?.gcr ??
                    NumberProperty.new({
                        value: 0,
                        unit: "%",
                        isReadonly: true,
                    }),
                moduleArea:
                    general?.pv_modules_area ??
                    NumberProperty.new({
                        value: 0,
                        unit: "ha",
                        isReadonly: true,
                    }),
            };
            return keyMetrics;
        },
    );
}

export class ProjectMetrics
    implements LazyVersioned<ResultAsync<MetricsGroup[]>>
{
    private _invalidator: VersionedInvalidator;
    private _logger: ScopedLogger;
    private _metricsCalculators: LayoutMetricsLazyCalculators;
    private _metrics: LazyDerivedAsync<MetricsGroup[]>;
    private _status: LazyVersioned<boolean>;

    public readonly areasContext: LazyVersioned<LayoutEquipment>;
    public readonly keyMetrics: LazyDerivedAsync<KeyMetrics>;

    constructor(
        public readonly bim: Bim,
        public readonly catalog: Catalog,
        public readonly pilesCollection: TrackerPilesCollection,
        public readonly costMetrics: LazyVersioned<
            ResultAsync<CostReportRelatedMetrics>
        >,
    ) {
        this._logger = new ScopedLogger("layout-metrics");
        this.areasContext = this._createAreasContext();
        this._metricsCalculators = new LayoutMetricsLazyCalculators(
            this._logger,
        );
        const footprintArea = new FootprintAreaMetric(bim, this._logger);
        const buildableArea = new BuildableAreaMetric(bim, this._logger);
        const powerMetrics = new PowerMetricsCalculator(
            this.areasContext,
            bim,
            catalog.keyPropertiesGroupFormatters,
            this._logger,
        );
        const generalMetrics = new GeneralMetricsCalculator(
            bim,
            catalog,
            this.areasContext,
            this._logger,
        );
        this.keyMetrics = createKeyMetricsLazy(
            buildableArea,
            footprintArea,
            powerMetrics,
            generalMetrics,
        );

        this._metricsCalculators.register(
            new CostMetricsGroup(this.costMetrics),
        );
        this._metricsCalculators.register(
            new EnergyYieldMetrics(bim, this._logger),
        );
        this._metricsCalculators.register(
            new PilesMetricsCalculator(
                this.areasContext,
                pilesCollection,
                bim.unitsMapper,
                this._logger,
            ),
        );
        this._metricsCalculators.register(
            new LayoutRowHeightsCalculator(
                bim,
                this.areasContext,
                this._logger,
            ),
        );
        this._metricsCalculators.register(
            new LayoutInputMetrics(bim, this.areasContext, this._logger),
        );
        this._metricsCalculators.register(generalMetrics);
        this._metricsCalculators.register(
            new EquipmentMetricsCalculator(
                this.areasContext,
                bim,
                catalog,
                this._logger,
            ),
        );
        this._metricsCalculators.register(
            new CivilMetricsCalculator(bim, this.areasContext, this._logger),
        );
        this._metricsCalculators.register(powerMetrics);
        this._metricsCalculators.register(buildableArea);
        this._metricsCalculators.register(footprintArea);

        this._metrics = this._metricsCalculators.build();

        const runtimesStatus = LazyDerivedAsync.new1(
            "bim-runtimes-status",
            [],
            [this.bim.getRuntimesCompletionStatusLazy()],
            function* ([runtimesStatus]) {
                for (let i = 0; i < 10; i++) {
                    yield Yield.NextFrame;
                }
                return runtimesStatus;
            },
        );
        this._status = LazyDerived.new2<
            boolean,
            ResultAsync<boolean>,
            ResultAsync<MetricsGroup[]>
        >(
            "metrics-status",
            null,
            [runtimesStatus, this._metrics],
            ([runtimesStatus, metrics]) => {
                return (
                    runtimesStatus instanceof Success &&
                    runtimesStatus.value &&
                    metrics instanceof Success
                );
            },
        );

        this._invalidator = new VersionedInvalidator([this._metrics]);
    }

    *waitTillCompletion(
        timeoutMs: number = 30_000,
    ): Generator<Yield, Result<MetricsGroup[]>> {
        return yield* this._metrics.waitTillCompletion(timeoutMs);
    }

    pollWithVersion(
        cache?: LazyVersionedPollingCache,
    ): PollWithVersionResult<Readonly<ResultAsync<MetricsGroup[]>>> {
        return this._metrics.pollWithVersion(cache);
    }

    getCalculationStatus(): LazyVersioned<boolean> {
        return this._status;
    }

    poll(cache?: LazyVersionedPollingCache): Readonly<ResultAsync<MetricsGroup[]>> {
        return this._metrics.poll(cache);
    }

    version(cache?: LazyVersionedPollingCache): number {
        return this._invalidator.version(cache);
    }

    dispose(): void {
        this._metricsCalculators.dispose();
    }

    test() {
        const metrics = this._metrics.poll();
        let value: MetricsGroup[] = [];
        if (metrics instanceof Success) {
            value = metrics.value;
        } else if (metrics instanceof InProgress) {
            value = metrics.lastSuccessful ?? [];
        } else {
            return;
        }
        const logger = this._logger.newScope(
            "test-serialization",
            LogLevel.Debug,
        );
        logger.info("Metrics", value);
        const bytes = serializeProjectMetricsResponse(value, logger);
        logger.info("Metrics bytes", bytes.length);
        const metrics2 = deserializeProjectMetricsResponse(bytes, logger);
        logger.info("Metrics2", metrics2);
    }

    private _createAreasContext(): LazyDerived<LayoutEquipment> {
        const lazyListOfInstances = this.bim.instances.getLazyListOfCollection({
            relevantUpdateFlags:
                SceneObjDiff.NewProps | SceneObjDiff.LegacyProps,
        });
        const farmConfigsLazy = this.bim.configs.getLazyListOf({
            type_identifier: FarmLayoutConfigType,
        });
        return LazyDerived.new2(
            "areas-context",
            null,
            [lazyListOfInstances, farmConfigsLazy],
            ([instances, configs]) => {
                const areas: (SubareaEquipment | TotalEquipment)[] = [];
                for (const [configId, config] of configs) {
                    const substation = this.bim.instances.peekById(
                        config.connectedTo,
                    );
                    if (!substation) {
                        continue;
                    }
                    const allSiteAreaIds: IdBimScene[] = [];
                    const idsPerSubarea = new DefaultMap<number, IdBimScene[]>(
                        () => [],
                    );
                    this.bim.instances.spatialHierarchy.traverseRootToLeavesDepthFirstFrom(
                        config.connectedTo,
                        (id) => {
                            const inst = this.bim.instances.peekById(id);
                            if (!inst) {
                                return true;
                            }
                            allSiteAreaIds.push(id);
                            const areaIdx =
                                inst.properties
                                    .get("circuit | position | area_index")
                                    ?.asNumber() ?? 0;
                            idsPerSubarea.getOrCreate(areaIdx).push(id);
                            return true;
                        },
                    );
                    const unallocatedIds = new Set(allSiteAreaIds);
                    for (const [idx, ids] of idsPerSubarea) {
                        if (idx <= 1) {
                            continue;
                        }
                        for (const id of ids) {
                            unallocatedIds.delete(id);
                        }
                    }

                    if (configs.length > 1) {
                        //All site area
                        areas.push({
                            id: `${configId}|0`,
                            type: AreaTypeEnum.AllSiteArea,
                            name: "Buildable area",
                            equipment: allSiteAreaIds,
                            connectedTo: config.connectedTo,
                            areaIndex: 0,
                        });
                    }

                    const farmConfig = config.get<FarmLayoutConfig>();
                    //Add unallocated area
                    if (farmConfig.site_areas.length > 2) {
                        areas.push({
                            id: `${configId}|1`,
                            type: AreaTypeEnum.UnallocatedArea,
                            name: "Unallocated",
                            equipment: Array.from(unallocatedIds),
                            connectedTo: config.connectedTo,
                            areaIndex: 1,
                        });
                    }

                    for (let i = 2; i < farmConfig.site_areas.length; i++) {
                        const area = farmConfig.site_areas[i];

                        const name =
                            i === 0
                                ? "Buildable area"
                                : i === 1
                                  ? "Unallocated"
                                  : "Subarea " + (i - 1);
                        areas.push({
                            id: `${configId}|${i}`,
                            type: area.zones
                                ? AreaTypeEnum.Subarea
                                : area.boundaries
                                  ? AreaTypeEnum.AllSiteArea
                                  : AreaTypeEnum.UnallocatedArea,
                            name: name,
                            equipment: idsPerSubarea.getOrCreate(i),
                            connectedTo: config.connectedTo,
                            areaIndex: i,
                        });
                    }
                }

                areas.push({
                    id: TotalMetricId,
                    name: "Total/Avg",
                    type: AreaTypeEnum.Total,
                    equipment: instances.map(([id, _]) => id),
                });

                return {
                    areas,
                };
            },
        );
    }
}

export function mergeMetricsGroups(
    metrics: MetricsGroup[][],
    logger: ScopedLogger,
): MetricsGroup[] {
    const result = new Map<string, MetricsGroup>();
    for (const metricByArea of metrics) {
        for (const area of metricByArea) {
            if (area.metrics) {
                let group = result.get(area.id);
                if (!group) {
                    group = new MetricsGroup({
                        id: area.id,
                        type: area.type,
                        metrics: {},
                        areaIndex: area.areaIndex,
                    });
                    result.set(area.id, group);
                }
                if (area.type !== group.type) {
                    logger.error(
                        `Area type mismatch ${area.type} !== ${group.type}`,
                    );
                }
                ConfigUtils.copyTo(
                    area.metrics,
                    group.metrics as PropertyGroup,
                );
            }
        }
    }
    return Array.from(result.values());
}

function extractAreaIndex(area: EquipmentArea): number | undefined {
    return area.type !== AreaTypeEnum.Total ? area.areaIndex : undefined;
}
