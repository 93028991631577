import type { DC_CNSTS, IdBimScene, MathSolversApi, SceneInstance } from "bim-ts";
import { trimTrackerIrregular, trimTrackerRegular } from "bim-ts";
import { DefaultMap, IterUtils, LogLevel, ScopedLogger } from "engine-utils-ts";
import { Aabb2, KrMath, Vector2 } from 'math-ts';
import { CIHorTrunkbusSolver, CIHorTrunkbusTrenchSolver, CIMultVertTrunkbusSolver, CIMultiharnessSolver, ElectricalPatternSolvers, SIMultiharnessSolver, checkIfEquipmentNeedsRotation, convertTracker, filterRoads } from "./ElectricalPatternSolvers";
import type { BlockExecuterNewAlgorithmRequest, BlockExecuterNewAlgorithmResponse, BlockWithLabel, Point, RoadSideType, Row, Site } from "./LayoutSolversTypes";
import type { TrackersLayout } from "./FarmLayoutSolvers";
import { FarmLayoutSolvers } from "./FarmLayoutSolvers";

export type PatternName = DC_CNSTS.PatternName;

export interface Contour {
    id: IdBimScene;
    contour: Vector2[];
    rotated: Vector2[];
    include: boolean;
    equipmentBoundary: boolean;
}

export enum EquipmentRoadsOption {
    Generate,
    UseExisting,
}
export enum SupportRoadsOption {
    Ignore,
    Generate,
    UseExisting,
}

export type ExistingRoadsType = {
    points: Vector2[];
    edges: { id: IdBimScene; fst: number; snd: number; width: number }[];
};

export enum LayoutGenerationTarget {
    NoBlockingMaxDc,
    NoBlockingTargetDc,
    NoBlockingTargetDcAndMaxR2R,
    BlockingMaxDc,
    BlockingTargetDc,
}

export function isBlockingLayoutGenTarget(target: LayoutGenerationTarget): boolean {
    return target === LayoutGenerationTarget.BlockingMaxDc 
        || target === LayoutGenerationTarget.BlockingTargetDc;
}

export interface LayoutInput {
    contours: Contour[];
    selectedAreaIndex: number;
    basePos: Vector2;
    
    layoutGenerationTarget: LayoutGenerationTarget;
    targetDcPowerW?: number;
    optimizeForRectShapeBlocks: boolean;
    pattern: PatternName;
    necMultiplier: number;
    rowToRowSpace: number;
    heightPreference: number;
    trackers: TrackerData[];
    combinerBox: CombinerBoxData;
    blocksSettings: BlockSetting[];

    ewEquipmentRoadsOrientation: boolean;
    skidParallelToRoad: boolean;
    middleRoadPosition: boolean; 
    equipmentGlassToGlass: number;
    supportGlassToGlass: number;
    equipmentRoadWidth: number;
    supportRoadWidth: number;
    crossRoad: boolean;
    angleDeg: number;
    shiftDeg: number | undefined;
    road_side: RoadSideType,
    outerOffset: number;
    inverter_offset: number;
    transformer_offset: number;
    equipmentOffset: number;
    block_offset:number;
    equipmentRoadsOption: EquipmentRoadsOption;
    supportRoadsOption: SupportRoadsOption;
    globalSupportRoads: boolean;
    maxSupportRoadsStepM: number;
    existingRoads: ExistingRoadsType,
    alignArrays: boolean;
    offsetBetweenInverterAndTransformer: number;
}

export interface BlockSetting {
    label: number,
    transformer: TransformerData;
    inverter: InverterData;

    ilr_min: number,
    ilr_max: number,
    number_of_blocks: number | undefined,
    number_of_inverters: number | undefined,
}


export interface TrackerData {
    src: SceneInstance;
    isFixedTilt:boolean;
    lengthM: number;
    dcPowerW: number;
    maxCurrentA: number;
    width: number;
    stringMaxCurrentA: number;
    stringPowerW: number;
}

export interface TransformerData {
    src: SceneInstance;
    powerW: number;
    size: Vector2;
}

export interface InverterData {
    src: SceneInstance;
    maxPowerW: number;
    maxCurrentOutputA: number;
    maxCurrentInputA: number;
    dcInputsNumber: number;
    size: Vector2;
}

export interface CombinerBoxData {
    src: SceneInstance;
    currentA: number;
}

export interface RawLayout {
    roads: RoadDef[];
    trackers: TrackerPositionType[];
    combinerBoxes: ObjectPositionType[];
    stringInverters: ObjectPositionType[];
    centralInverters: ObjectPositionType[];
    transformers: ObjectPositionType[];
}

export interface FarmLayout {
    layout: RawLayout;
    max_r2r_result: number | undefined;
}

export interface ObjectPositionType {
    id: number;
    type: string,
    src: SceneInstance;
    position: Vector2;
    size?: Vector2;
    parentId?: number;
    rotateDeg: number;
    label?: number;
}

export interface TrackerPositionType extends ObjectPositionType {
    r: number;
    s: number;
    v: number;
    blockLabel?: number;
    transformerId?: number;
}

export interface RoadDef {
    polyline: [Vector2, Vector2];
    width: number;
}

export async function generateFarmLayout(input: LayoutInput, mathSolverApi: MathSolversApi): Promise<FarmLayout> {

    const farmLayout: FarmLayout = {
        layout : {
            roads: [],
            centralInverters: [],
            combinerBoxes: [],
            stringInverters: [],
            trackers: [],
            transformers: [],
        },
        max_r2r_result: undefined,
    }
    if (!isBlockingLayoutGenTarget(input.layoutGenerationTarget) || input.equipmentRoadsOption === EquipmentRoadsOption.UseExisting) {
        const trackersLayout = await generateTrackersLayout(
            {
                ...input, 
                maxTrimPowers: new Array(input.blocksSettings.length).fill(0),
                blocksSettings: input.blocksSettings.map(b => ({...b, ilr_min: 0, ilr_max: 10 })),
            },
            mathSolverApi, 
        );
        if(!trackersLayout.sites){
            return farmLayout;
        }
        farmLayout.max_r2r_result = trackersLayout.max_r2r_result;
        let trackersPerBlock: Row[][] = trackersLayout.sites.map(t => t.trackers);
        let equipment_roads = trackersLayout.equipment_roads.slice();
        let support_roads = trackersLayout.support_roads.slice();
        const isTargetDc = input.layoutGenerationTarget === LayoutGenerationTarget.NoBlockingTargetDc 
            || input.layoutGenerationTarget === LayoutGenerationTarget.BlockingTargetDc
        if(isTargetDc){
            const blockExecuterResponse = await blockLayout(
                [{ v_min: 0, v_max: input.targetDcPowerW!,}], 
                mathSolverApi, 
                false, 
                input.targetDcPowerW, 
                trackersLayout.sites, 
            );
            const roads = filterRoads(blockExecuterResponse, trackersLayout);
            equipment_roads = roads.equipment_roads;
            support_roads = roads.support_roads;
            trackersPerBlock = blockExecuterResponse.blocks.map(t => t.trackers);
        }
        let counter = 0;
        for (const block of trackersPerBlock) {
            for (const tracker of block) {
                farmLayout.layout.trackers.push(
                    convertTracker(++counter, input.trackers, tracker)
                );
            }
        }

        for (const road of equipment_roads) {
            farmLayout.layout.roads.push({ 
                width: input.equipmentRoadWidth, 
                polyline: [new Vector2(road.lhs.x, road.lhs.y), new Vector2(road.rhs.x, road.rhs.y)] 
            });
        }
        for (const road of support_roads) {
            farmLayout.layout.roads.push({ 
                width: input.supportRoadWidth, 
                polyline: [new Vector2(road.lhs.x, road.lhs.y), new Vector2(road.rhs.x, road.rhs.y)] 
            });
        }
    } else if (isBlockingLayoutGenTarget(input.layoutGenerationTarget)) {
        const electricalPatternSolvers = new ElectricalPatternSolvers(
            mathSolverApi, [
            ['CI_HorTrunkbus', new CIHorTrunkbusSolver()],
            ['CI_HorTrunkbus_Trench', new CIHorTrunkbusTrenchSolver()],
            ['CI_MultVertTrunkbus', new CIMultVertTrunkbusSolver()],
            //['CI_VertTrunkbus', new CIVertTrunkbusSolver()],
            ['CI_Multiharness', new CIMultiharnessSolver()],
            ['CI_Multiharness_BusEW', new CIMultiharnessSolver()],
            ['SI_Multiharness', new SIMultiharnessSolver()],
        ]);

        let additionalPower = 0;
        const layoutModifier = new NestedLayoutModifier(input);
        let maxTrimPowers: number[] = new Array(input.blocksSettings.length).fill(0);
        for (let it = 0; it < 3; ++it) {
            const targetDcPowerW = input.targetDcPowerW ? input.targetDcPowerW + additionalPower : undefined;
            const trackersLayout = await generateTrackersLayout({
                ...input,
                maxTrimPowers: maxTrimPowers,
            }, mathSolverApi);

            farmLayout.layout = await electricalPatternSolvers.solve({
                contours: input.contours,
                trackersLayout, 
                rowToRowSpace: input.rowToRowSpace,
                pattern: input.pattern, 
                maxTrimPowers: maxTrimPowers,
                equipmentRoadsOption: input.equipmentRoadsOption,
                blocksSettings: input.blocksSettings,
                find_max_power: input.layoutGenerationTarget === LayoutGenerationTarget.BlockingMaxDc,
                targetDcPowerW,
                trackers: input.trackers,
                combinerBox: input.combinerBox,
                necMultiplier: input.necMultiplier,
                road_side: input.road_side,
                outerOffset: input.outerOffset,
                inverter_offset: input.inverter_offset,
                transformer_offset: input.transformer_offset,
                equipmentRoadWidth: input.equipmentRoadWidth,
                offsetBetweenInverterAndTransformer: input.offsetBetweenInverterAndTransformer,
                supportRoadWidth: input.supportRoadWidth,
                ewEquipmentRoads: input.ewEquipmentRoadsOrientation,
                skidParallelToRoad: input.skidParallelToRoad,
            });

            const modifiedLayout = layoutModifier.replaceOrRemoveTrackers(farmLayout.layout);
            farmLayout.layout = modifiedLayout.layout;
            maxTrimPowers = modifiedLayout.maxTrimPowers;
            additionalPower += modifiedLayout.additionalPower;

            if(!modifiedLayout.shouldContinue){
                break;
            }
        }
    }

    return farmLayout;
}
export interface BlocksResponse {
    blocks: BlockWithLabel[],
    emptyBlockIndexes: number[],
}

export async function groupTrackersInBlocks(
    blocksSettings: BlockSetting[],
    pattern: PatternName,
    find_max_power: boolean,
    targetDcPowerW: number | undefined,
    mathSolverApi: MathSolversApi, 
    sites: Site[], 
    maxTrimPowers: number[]
): Promise<BlocksResponse>{
    const samples: { v_min: number; v_max: number; n?: number; }[] = [];

    for (let i = 0; i < blocksSettings.length; ++i) {
        const block = blocksSettings[i];
        const { v_min, v_max } = calcBlockPowerRange(pattern, block);
        const vMinWithAdditionalPower = v_min + maxTrimPowers[i];
        samples.push({
            v_min: vMinWithAdditionalPower,
            v_max: Math.max(v_max, vMinWithAdditionalPower),
            n: block.number_of_blocks
        });
    }
    const response: BlocksResponse = await blockLayout(samples, mathSolverApi, find_max_power, targetDcPowerW, sites);
    return response;
}

async function blockLayout(
    samples: { v_min: number; v_max: number; n?: number; }[], 
    mathSolverApi: MathSolversApi,
    findMax: boolean, 
    targetDcPowerW: number|undefined, 
    sites: Site[], 
) {

    let maxDc: number = 0;
    for (const site of sites) {
        for (const t of site.trackers) {
            maxDc += t.v;
        }
    }
    const request: BlockExecuterNewAlgorithmRequest = {
        patches: sites.map((s) => ({
            trackers: s.trackers,
            lines: s.lines.map<[Point, Point]>((l) => [{ x: l.lhs.x, y: l.lhs.y }, { x: l.rhs.x, y: l.rhs.y }]),
        })),
        samples,
        target_value: findMax ? maxDc : targetDcPowerW,
    };
    const blockExecuterResponse = await mathSolverApi.callSolver<
        BlockExecuterNewAlgorithmRequest,
        BlockExecuterNewAlgorithmResponse
    >({
        solverName: "blocks-extractor-new",
        solverType: "single",
        request,
    });

    const response: BlocksResponse = {
        blocks: [],
        emptyBlockIndexes: []
    };

    for (let idx = 0; idx < blockExecuterResponse.blocks.length; idx++) {
        const block = blockExecuterResponse.blocks[idx];
        if (block.length === 0 && response.emptyBlockIndexes) {
            response.emptyBlockIndexes.push(idx);
        }
        for (const patch of block) {
            response.blocks.push({
                label: patch.sample,
                lines: patch.lines.map(l => ({ lhs: new Vector2(l[0].x, l[0].y), rhs: new Vector2(l[1].x, l[1].y) })),
                trackers: patch.trackers
            });
        }
    }

    return response;
}

export function calcBlockPowerRange(pattern: PatternName, block: BlockSetting) {
    let v_min = 0;
    let v_max = 0;
    if (pattern === 'SI_Multiharness' && block.number_of_inverters) {
        v_min = block.inverter.maxPowerW * block.ilr_min * block.number_of_inverters;
        v_max = block.inverter.maxPowerW * block.ilr_max * block.number_of_inverters;
    } else if (pattern === 'SI_Multiharness' && !block.number_of_inverters) {
        v_min = block.transformer.powerW * block.ilr_min;
        v_max = block.transformer.powerW * block.ilr_max;
    } else {
        v_min = block.inverter.maxPowerW * block.ilr_min;
        v_max = block.inverter.maxPowerW * block.ilr_max;
    }

    return {v_min, v_max};
}

async function generateTrackersLayout(
    input: LayoutInput & { maxTrimPowers: number[] },
    mathSolversApi: MathSolversApi,
): Promise<TrackersLayout> {
    const block_offset = input.block_offset - input.equipmentOffset > 0
        ? input.block_offset - input.equipmentOffset
        : 0;
    const max_tracker_width = IterUtils.max(input.trackers.map(t => t.width))!;

    const solvers = new FarmLayoutSolvers(mathSolversApi);
    if(input.equipmentRoadsOption === EquipmentRoadsOption.UseExisting){
        const layout = await solvers.generateLayoutUsingExistingRoads({
            contours: input.contours,
            trackers: input.trackers,
            max_tracker_width,
            rowToRowSpace: input.rowToRowSpace,
            equipmentGlassToGlass: input.equipmentGlassToGlass,
            existingRoads: input.existingRoads,
            equipmentOffset: input.equipmentOffset,
            alignArrays: input.alignArrays,
            shiftDeg: input.shiftDeg,
        });
        return layout;
    }
    const shiftTan = input.shiftDeg !== undefined ? Math.tan(KrMath.degToRad(input.shiftDeg)) : undefined
    let layout: TrackersLayout;
    if (input.layoutGenerationTarget === LayoutGenerationTarget.NoBlockingTargetDcAndMaxR2R) {
        layout = await solvers.generateLayoutAndMaximizeRowToRow({
            contours: input.contours,
            pattern: input.pattern,
            blocksSettings: input.blocksSettings,
            trackers: input.trackers,
            block_offset,
            max_tracker_width,
            rowToRowSpace: input.rowToRowSpace,
            heightPreference: input.heightPreference,
            equipmentGlassToGlass: input.equipmentGlassToGlass,
            targetDcPowerW: input.targetDcPowerW!,
            shiftTan: shiftTan,
            roadStep: input.maxSupportRoadsStepM,
            globalSupportRoads: input.globalSupportRoads,
            supportRoadsOption: input.supportRoadsOption,
            supportGlassToGlass: input.supportGlassToGlass,
            roads: input.existingRoads,
            alignArrays: input.alignArrays,
            invertersIgnore: !isBlockingLayoutGenTarget(input.layoutGenerationTarget),
            equipmentOffset: input.equipmentOffset,
        });
    } else if (input.ewEquipmentRoadsOrientation) {
        layout = await solvers.generateLayoutWithEwEquipmentRoads({
            contours: input.contours,
            pattern: input.pattern,
            blocksSettings: input.blocksSettings,
            trackers: input.trackers,
            block_offset,
            max_tracker_width,
            rowToRowSpace: input.rowToRowSpace,
            heightPreference: input.heightPreference,
            equipmentGlassToGlass: input.equipmentGlassToGlass,
            shiftTan: shiftTan!,
            roadStep: input.maxSupportRoadsStepM,
            globalSupportRoads: input.globalSupportRoads,
            supportRoadsOption: input.supportRoadsOption,
            supportGlassToGlass: input.supportGlassToGlass,
            existingRoads: input.existingRoads,
            alignArrays: input.alignArrays,
            invertersIgnore: !isBlockingLayoutGenTarget(input.layoutGenerationTarget),
            equipmentOffset: input.equipmentOffset,
        });
    } else if(!input.ewEquipmentRoadsOrientation){
        layout = await solvers.generateLayoutWithNsEquipmentRoads({
            contours: input.contours,
            shiftDeg: input.shiftDeg,
            pattern: input.pattern,
            blocksSettings: input.blocksSettings,
            maxTrimPowers: input.maxTrimPowers,
            trackers: input.trackers,
            block_offset,
            max_tracker_width,
            rowToRowSpace: input.rowToRowSpace,
            heightPreference: input.heightPreference,
            equipmentGlassToGlass: input.equipmentGlassToGlass,
            roadStep: input.maxSupportRoadsStepM,
            supportRoadsOption: input.supportRoadsOption,
            supportGlassToGlass: input.supportGlassToGlass,
            alignArrays: input.alignArrays,
            equipmentOffset: input.equipmentOffset, 
            optimizeForRectShapeBlocks: input.optimizeForRectShapeBlocks,        
            middleRoadPosition: input.middleRoadPosition,
            existingRoads: input.existingRoads,
        });
    } else {
        console.error('Unsupported equipment roads orientation', input);
        throw new Error('Unsupported equipment roads orientation');
    }

    return layout;
}

class NestedLayoutModifier {
    private readonly logger = new ScopedLogger('ReplaceTrackers');
    private readonly iterationPerPower: number[];
    private maxTrimPowers: number[];

    constructor(readonly input: LayoutInput){
        this.logger = new ScopedLogger('NestedLayoutModifier', LogLevel.Info);
        this.iterationPerPower = [];
        this.maxTrimPowers = new Array(this.input.blocksSettings.length).fill(0);
    }

    replaceOrRemoveTrackers(rawLayout: RawLayout) {
        this.logger.debug("Iteration ", this.iterationPerPower.length + 1);
        let additionalPower = 0;
        if (rawLayout.centralInverters.length === 0 && rawLayout.transformers.length === 0) { 
            return {
                layout: rawLayout, 
                maxTrimPowers: this.maxTrimPowers.slice(), 
                additionalPower, 
                shouldContinue: false
            };
        }

        const equipmentBboxes = this.initializeEquipmentBboxes(rawLayout);

        const trackers = rawLayout.trackers.slice();
        const powerBeforeTrim = IterUtils.sum(trackers, t => t.v);
        this.iterationPerPower.push(powerBeforeTrim);

        const blockLabelPerCutPower = new DefaultMap<number, { cutPower: DefaultMap<number, number[]>}>(
            () => ({ cutPower: new DefaultMap(() => []), totalPower: 0 })
        );
        const reusedAabb = Aabb2.empty();
        for (let i = 0; i < trackers.length; ++i) {
            const tracker = trackers[i];
            const blockLabelGroup = blockLabelPerCutPower.getOrCreate(tracker.blockLabel!);
            const block = blockLabelGroup.cutPower.getOrCreate(tracker.transformerId!);
            
            const trackerBbox = reusedAabb.setFromCenterAndSize(tracker.position, tracker.size!);
            for (const equipmentBbox of equipmentBboxes) {
                if (!equipmentBbox.intersectsBox2(trackerBbox)) {
                    continue;
                }
                const trackerToTrim = { 
                    x: tracker.position.x, 
                    y: tracker.position.y - 0.5 * this.input.trackers[tracker.s].lengthM, 
                    s: tracker.s, 
                    r: tracker.r 
                };

                const trimFunction = this.input.alignArrays ? trimTrackerRegular : trimTrackerIrregular;

                const trackerNew = trimFunction(
                    equipmentBbox.cornerPoints().map(p => p.clone()), 
                    trackerToTrim, 
                    tracker.size!.x, 
                    this.input.trackers.map((t, i) => ({h: t.lengthM, o: 0, v: t.dcPowerW}))
                );
                const trackerPower = this.input.trackers[tracker.s].dcPowerW;
                if(!trackerNew){
                    block.push(trackerPower);
                    trackers.splice(i, 1);
                    --i;
                    break;
                }

                if(trackerNew.s !== tracker.s){
                    const sampleNew = this.input.trackers[trackerNew.s];
                    block.push(trackerPower - sampleNew.dcPowerW);
                    const row = {
                        x: trackerNew.x - 0.5 * tracker.size!.x,
                        y: trackerNew.y,
                        w: tracker.size!.x,
                        h: sampleNew.lengthM,
                        v: sampleNew.dcPowerW,
                        r: trackerNew.r,
                        s: trackerNew.s
                    };
                    trackers[i] = convertTracker(tracker.id, this.input.trackers, row, tracker.parentId, tracker.blockLabel);
                }
            }
        }
        let shouldContinue = false;
        const totalPower = IterUtils.sum(trackers, tr => tr.v);
        this.logger.debug('powerBefore', powerBeforeTrim, 'totalPower', totalPower, blockLabelPerCutPower);
        if(totalPower !== this.iterationPerPower[0]){
            shouldContinue = true;
            this.maxTrimPowers.fill(0);
            for (const [label, block] of blockLabelPerCutPower) {
                const blockSettingsIdx = this.input.blocksSettings.findIndex(b => b.label === label);
                if(blockSettingsIdx < 0){
                    this.logger.error('Block not found', label);
                    continue;
                }
                
                const maxCutPowerInBlock = IterUtils.max(IterUtils.mapIter(block.cutPower.values(), p => IterUtils.sum(p, x => x)));                
                this.maxTrimPowers[blockSettingsIdx] = maxCutPowerInBlock ?? 0;
            }
        }
        this.logger.debug('trim power', this.maxTrimPowers);
        if(this.input.targetDcPowerW && totalPower < this.input.targetDcPowerW) {
            shouldContinue = true;
            additionalPower = this.input.targetDcPowerW - totalPower;
        }

        return {
            layout: {
                ...rawLayout,
                trackers,
            },
            maxTrimPowers: this.maxTrimPowers.slice(),
            additionalPower,
            shouldContinue,
        }
    }

    private initializeEquipmentBboxes(rawLayout: RawLayout){
        const shouldRotateEquipment = checkIfEquipmentNeedsRotation(
            this.input.ewEquipmentRoadsOrientation, 
            this.input.skidParallelToRoad
        );
    
        function makeContour(position: Vector2, size: Vector2): Aabb2{
            return Aabb2.empty().setFromCenterAndSize(position, size);
        }

        const equipmentBboxes: Aabb2[] = [];
        IterUtils.extendArray(equipmentBboxes, rawLayout.centralInverters.map(i => {
            const size = shouldRotateEquipment ? new Vector2(i.size!.y, i.size!.x) : i.size!;
            return makeContour(i.position, size);
        }));
        IterUtils.extendArray(equipmentBboxes, rawLayout.transformers.map(t => {
            const size = shouldRotateEquipment ? new Vector2(t.size!.y, t.size!.x) : t.size!;
            return makeContour(t.position, size);
        }));

        return equipmentBboxes;
    }
}