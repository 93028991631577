import { ObjectUtils, type DefaultMap } from 'engine-utils-ts';
import type { Matrix4, Transform} from 'math-ts';
import { Vector2} from 'math-ts';
import { Aabb, Aabb2 } from 'math-ts';
import type { EntityIdAny} from 'verdata-ts';
import { entityTypeFromId } from 'verdata-ts';

import type { IdBimImage } from '../BimImages';
import type { IdBimMaterial } from '../BimMaterials';
import type { IdBimGeo } from '../geometries/BimGeometries';
import { BimGeometryType } from '../geometries/BimGeometries';
import type { TerrainGeoVersionSelector} from '../terrain/TerrainTile';
import { TerrainTileId } from '../terrain/TerrainTile';
import { TerrainTile } from '../terrain/TerrainTile';
import type { RegularHeightmapGeometries } from '../geometries/RegularHeightmapGeometry';

const EmptyAabb = ObjectUtils.deepFreeze(Aabb.empty());

export type GeometiresAabbsSoure = ReadonlyMap<IdBimGeo, Aabb>;

export interface RepresentationBase {
	geometriesIdsReferences(result: IdBimGeo[]): void;
	materialIdsReferences(result: IdBimMaterial[]): void;
	imagesReferenced(result: IdBimImage[]): void;

	aabb(geometriesAabbs: ReadonlyMap<IdBimGeo, Aabb>): Readonly<Aabb>;
	equals(repr: RepresentationBase): boolean;

	isRuntimeGenerated(): boolean;
}


export class BasicAnalyticalRepresentation implements RepresentationBase {
	constructor(
		public readonly geometryId: IdBimGeo,
	) {
		Object.freeze(this);
	}

	geometriesIdsReferences(result: EntityIdAny[]): void {
		result.push(this.geometryId);
	}
	materialIdsReferences(_result: EntityIdAny[]): void {
	}
	imagesReferenced(_result: EntityIdAny[]): void {

	}

	aabb(geometries: GeometiresAabbsSoure): Readonly<Aabb> {
		return geometries.get(this.geometryId) ?? EmptyAabb;
	}

	equals(repr: BasicAnalyticalRepresentation) {
		return this.geometryId === repr.geometryId;
	}

	isRuntimeGenerated(): boolean {
		return false;
	}

	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>
	): BasicAnalyticalRepresentation {
		return new BasicAnalyticalRepresentation(
			geometries.getOrCreate(this.geometryId),
		);
	}
}


const reusedAabb = Aabb.empty();

export type ObjectRepresentation =
	StdMeshRepresentation
	| StdInstancedMeshesRepresentation
	| SceneImageRepresentation
	| HeightmapRepresentation
	| TerrainHeightMapRepresentation
	;


export class StdSubmeshRepresentation {
	constructor(
		public readonly geometryId: IdBimGeo,
		public readonly materialId: IdBimMaterial,
		public readonly transform: Transform | null,
	) {
		Object.freeze(this);
	}

	equals(repr: StdSubmeshRepresentation) {
		if (this.transform === null) {
			if (repr.transform !== null) {
				return false;
			}
		} else {
			if (repr.transform === null || !this.transform.equals(repr.transform)) {
				return false;
			}
		}
		return this.geometryId === repr.geometryId && this.materialId === repr.materialId;
	}

	static arraysEqual(a: StdSubmeshRepresentation[], b: StdSubmeshRepresentation[]): boolean {
		if (a.length !== b.length) {
			return false;
		}

		for (let i = 0; i < a.length; ++i) {
			if (!a[i].equals(b[i])) {
				return false;
			}
		}
		return true;
	}

	withRemappedIds(geometries: DefaultMap<IdBimGeo, IdBimGeo>, materials: DefaultMap<IdBimMaterial, IdBimMaterial>): StdSubmeshRepresentation {
		return new StdSubmeshRepresentation(
			geometries.getOrCreate(this.geometryId),
			materials.getOrCreate(this.materialId),
			this.transform,
		);
	}
}

export class StdSubmeshesLod {
	constructor(
		public readonly submeshes: StdSubmeshRepresentation[],
		public readonly enableAfterDetailSize: number, // enable when smallest details of previous lod are not visible on the screen
	) {
		Object.freeze(this.submeshes);
		Object.freeze(this);
	}

	equals(lod: StdSubmeshesLod) {
		return this.enableAfterDetailSize === lod.enableAfterDetailSize 
			&& StdSubmeshRepresentation.arraysEqual(this.submeshes, lod.submeshes);
	}

	aabb(geometriesAabbs: GeometiresAabbsSoure): Readonly<Aabb> {
		const result = Aabb.empty();
		for (const s of this.submeshes) {
			const sAabb = geometriesAabbs.get(s.geometryId);
			if (!sAabb) {
				continue;
			}
			if (s.transform) {
				reusedAabb.copy(sAabb).applyTransform(s.transform);
				result.union(reusedAabb);
			} else {
				result.union(sAabb);
			}
		}
		return result;
	}
}

export class StdMeshRepresentation implements RepresentationBase {
	constructor(
		public submeshes: StdSubmeshRepresentation[],
		public lod1: StdSubmeshesLod | null = null,
		public _isRuntimeGenerated: boolean = false,
	) {
		Object.freeze(this.submeshes);
		Object.freeze(this);
	}

	isRuntimeGenerated(): boolean {
		return this._isRuntimeGenerated;
	}

	geometriesIdsReferences(result: EntityIdAny[]): void {
		for (const s of this.submeshes) {
			result.push(s.geometryId);
		}
		if (this.lod1) {
			for (const s of this.lod1.submeshes) {
				result.push(s.geometryId);
			}
		}
	}
	materialIdsReferences(result: EntityIdAny[]): void {
		for (const s of this.submeshes) {
			result.push(s.materialId);
		}
		if (this.lod1) {
			for (const s of this.lod1.submeshes) {
				result.push(s.materialId);
			}
		}
	}
	imagesReferenced(_result: EntityIdAny[]): void {
	}

	aabb(geometriesAabbs: GeometiresAabbsSoure): Readonly<Aabb> {
		const result = Aabb.empty();
		for (const s of this.submeshes) {
			const sAabb = geometriesAabbs.get(s.geometryId);
			if (!sAabb) {
				continue;
			}
			if (s.transform) {
				reusedAabb.copy(sAabb).applyTransform(s.transform);
				result.union(reusedAabb);
			} else {
				result.union(sAabb);
			}
		}
		return result;
	}

	equals(repr: RepresentationBase): boolean {
		if (!(repr instanceof StdMeshRepresentation)) {
			return false;
		}

		if (this === repr) {
			return true;
		}

		if (this.lod1 !== repr.lod1) {
			if (this.lod1 === null || repr.lod1 === null) {
				return false;
			}

			if (!this.lod1.equals(repr.lod1)) {
				return false;
			}
		}

		return StdSubmeshRepresentation.arraysEqual(this.submeshes, repr.submeshes);
	}

	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>, 
		images: DefaultMap<IdBimImage, IdBimImage>
	): StdMeshRepresentation {
		const newSubmeshes = this.submeshes.map(s => s.withRemappedIds(geometries, materials));

		let newLod1: StdSubmeshesLod | null = null;
		if (this.lod1) {
			const newLod1Submeshes = this.lod1.submeshes.map(s => s.withRemappedIds(geometries, materials));
			newLod1 = new StdSubmeshesLod(newLod1Submeshes, this.lod1.enableAfterDetailSize);
		}
		return new StdMeshRepresentation(newSubmeshes, newLod1, this._isRuntimeGenerated);
	}

}

export class SubmeshInstancesGroup {
	constructor(
		public readonly ident: string,
		public readonly geometryId: IdBimGeo,
		public readonly materialId: IdBimMaterial,
		public readonly transforms: Matrix4[],
	) {
	}

	equals(other: SubmeshInstancesGroup): boolean {
		if (this.geometryId !== other.geometryId || this.materialId !== other.materialId || this.transforms.length !== other.transforms.length) {
			return false;
		}
		for (let i = 0; i < this.transforms.length; ++i) {
			if (!this.transforms[i].equals(other.transforms[i])) {
				return false;
			}
		}
		return true;
	}

	withRemappedIds(geometries: DefaultMap<IdBimGeo, IdBimGeo>, materials: DefaultMap<IdBimMaterial, IdBimMaterial>): SubmeshInstancesGroup {
		return new SubmeshInstancesGroup(
			this.ident,
			geometries.getOrCreate(this.geometryId),
			materials.getOrCreate(this.materialId),
			this.transforms,
		);
	}
}

export class StdInstancedMeshesRepresentation implements RepresentationBase {

	constructor(
		public readonly groups: SubmeshInstancesGroup[],
		public readonly lod1: StdSubmeshesLod | null,
	) {
		for (const inst of this.groups) {
			Object.freeze(inst.transforms);
			Object.freeze(inst);
		}
		Object.freeze(this.groups);
		Object.freeze(this);
	}


	isRuntimeGenerated(): boolean {
		return true;
	}

	geometriesIdsReferences(result: IdBimGeo[]): void {
		for (const inst of this.groups) {
			result.push(inst.geometryId);
		}
		if (this.lod1) {
			for (const inst of this.lod1.submeshes) {
				result.push(inst.geometryId);
			}
		}
	}
	materialIdsReferences(result: IdBimMaterial[]): void {
		for (const inst of this.groups) {
			result.push(inst.materialId);
		}
		if (this.lod1) {
			for (const inst of this.lod1.submeshes) {
				result.push(inst.materialId);
			}
		}
	}
	imagesReferenced(result: IdBimImage[]): void {
	}
	aabb(geometriesAabbs: ReadonlyMap<IdBimGeo, Aabb>): Readonly<Aabb> {
		const result = Aabb.empty();
		const reusedAabb = Aabb.empty();
		for (const inst of this.groups) {
			const geoAabb = geometriesAabbs.get(inst.geometryId);
			if (geoAabb) {
				for (const transform of inst.transforms) {
					reusedAabb.copy(geoAabb);
					reusedAabb.applyMatrix4(transform);
					result.union(reusedAabb);
				}
			}
		}
		return result;
	}
	equals(repr: RepresentationBase): boolean {
		if (!(repr instanceof StdInstancedMeshesRepresentation)) {
			return false;
		}
		if (this.lod1 && repr.lod1 && !this.lod1.equals(repr.lod1)) {
			return false;
		} else if (Boolean(this.lod1) !== Boolean(repr.lod1)) {
			return false;
		}

		if (this.groups.length !== repr.groups.length) {
			return false;
		}

		for (let i = 0; i < this.groups.length; ++i) {
			const a = this.groups[i];
			const b = repr.groups[i];
			if (!a.equals(b)) {
				return false;
			}
		}
		return true;
	}

	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>, 
		images: DefaultMap<IdBimImage, IdBimImage>
	): StdInstancedMeshesRepresentation {
		const newInstanceGroups = this.groups.map(s => s.withRemappedIds(geometries, materials));

		let newLod1: StdSubmeshesLod | null = null;
		if (this.lod1) {
			const newLod1Submeshes = this.lod1.submeshes.map(s => s.withRemappedIds(geometries, materials));
			newLod1 = new StdSubmeshesLod(newLod1Submeshes, this.lod1.enableAfterDetailSize);
		}
		return new StdInstancedMeshesRepresentation(newInstanceGroups, newLod1);
	}
}


export class SceneImageRepresentation implements RepresentationBase {
	constructor(
		public readonly imageId: IdBimImage,
		public readonly worldSize: Vector2,
	) {
		Object.freeze(this);
	}

	isRuntimeGenerated(): boolean {
		return false;
	}

	geometriesIdsReferences(_result: EntityIdAny[]): void {
	}
	materialIdsReferences(_result: EntityIdAny[]): void {
	}
	imagesReferenced(result: EntityIdAny[]): void {
		result.push(this.imageId);
	}

	aabb(_geometriesAabbs: GeometiresAabbsSoure): Readonly<Aabb> {
		return Aabb.allocFromArray([
			-this.worldSize.x * 0.5, - this.worldSize.y * 0.5, 0,
			this.worldSize.x * 0.5, this.worldSize.y * 0.5, 0,
		]);
	}

	equals(repr: RepresentationBase): boolean {
		if (!(repr instanceof SceneImageRepresentation)) {
			return false;
		}

		return this.imageId === repr.imageId && this.worldSize.equals(repr.worldSize);
	}


	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>, 
		images: DefaultMap<IdBimImage, IdBimImage>
	): SceneImageRepresentation {
		return new SceneImageRepresentation(
			images.getOrCreate(this.imageId),
			this.worldSize,
		);
	}
}

export class HeightmapRepresentation implements RepresentationBase {
	constructor(
		public readonly heightmapGeoId: IdBimGeo,
		public readonly heightmapImageId: IdBimImage | 0 = 0,
	) {
		Object.freeze(this);
	}

	isRuntimeGenerated(): boolean {
		return false;
	}

	geometriesIdsReferences(result: EntityIdAny[]): void {
		result.push(this.heightmapGeoId);
	}
	materialIdsReferences() {
	}
	imagesReferenced(result: EntityIdAny[]): void {
		if (this.heightmapImageId) {
			result.push(this.heightmapImageId);
		}
	}

	aabb(geometriesAabbs: GeometiresAabbsSoure): Readonly<Aabb> {
		const geoAabb =  geometriesAabbs.get(this.heightmapGeoId);
		return geoAabb ?? EmptyAabb;
	}

	equals(repr: RepresentationBase): boolean {
		if (!(repr instanceof HeightmapRepresentation)) {
			return false;
		}

		return this.heightmapGeoId === repr.heightmapGeoId && this.heightmapImageId === repr.heightmapImageId;
	}

	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>, 
		images: DefaultMap<IdBimImage, IdBimImage>
	): HeightmapRepresentation {
		return new HeightmapRepresentation(
			geometries.getOrCreate(this.heightmapGeoId),
			this.heightmapImageId ? images.getOrCreate(this.heightmapImageId) : 0,
		);
	}
}




export class TerrainHeightMapRepresentation implements RepresentationBase {

	constructor(
		public readonly tileSize: number,
		public readonly tiles: ReadonlyMap<TerrainTileId, TerrainTile>,
	) {
		Object.freeze(this);
	}

	isRuntimeGenerated(): boolean {
		return false;
	}

	geometriesIdsReferences(result: EntityIdAny[]): void {
		for (const tile of this.tiles.values()) {
			result.push(tile.initialGeo);
			if (tile.updatedGeo) {
				result.push(tile.updatedGeo);
			}
		}
	}
	materialIdsReferences() {
	}
	imagesReferenced(result: EntityIdAny[]): void {
	}

	aabb(geometriesAabbs: GeometiresAabbsSoure): Readonly<Aabb> {
		const totalAabb = Aabb.empty();
		for (const [tileId, tile] of this.tiles) {
			{
				let geoAabb =  geometriesAabbs.get(tile.initialGeo);
				if (geoAabb) {
					if (entityTypeFromId<BimGeometryType>(tile.initialGeo) === BimGeometryType.HeightmapRegular) {
						geoAabb = geoAabb.clone();
						tileId.offsetAabb(geoAabb, this.tileSize);
					}
					totalAabb.union(geoAabb);
				}
			}
			{
				let geoAabb =  geometriesAabbs.get(tile.updatedGeo);
				if (geoAabb) {
					if (entityTypeFromId<BimGeometryType>(tile.updatedGeo) === BimGeometryType.HeightmapRegular) {
						geoAabb = geoAabb.clone();
						tileId.offsetAabb(geoAabb, this.tileSize);
					}
					totalAabb.union(geoAabb);
				}
			}
		}
		return totalAabb;
	}

	equals(repr: RepresentationBase): boolean {
		if (!(repr instanceof TerrainHeightMapRepresentation)) {
			return false;
		}

		if (this.tileSize !== repr.tileSize || this.tiles.size !== repr.tiles.size) {
			return false;
		}

		for (const [id, a] of this.tiles) {
			const b = repr.tiles.get(id);
			if (b === undefined || a.initialGeo !== b.initialGeo || a.updatedGeo !== b.updatedGeo) {
				return false;
			}
		}
		return true;
	}

	withRemappedIds(
		geometries: DefaultMap<IdBimGeo, IdBimGeo>, 
		materials: DefaultMap<IdBimMaterial, IdBimMaterial>, 
		images: DefaultMap<IdBimImage, IdBimImage>
	): TerrainHeightMapRepresentation {
		const newTiles = new Map<TerrainTileId, TerrainTile>();
		for (const [id, tile] of this.tiles) {
			const newTile = new TerrainTile(
				tile.initialGeo ? geometries.getOrCreate(tile.initialGeo) : 0,
				tile.updatedGeo ? geometries.getOrCreate(tile.updatedGeo) : 0,
			);
			newTiles.set(id, newTile);
		}
		return new TerrainHeightMapRepresentation(this.tileSize, newTiles);
	}

	findDiscontinuities(
		regularHeightmapGeometries: RegularHeightmapGeometries, version: TerrainGeoVersionSelector
	): [[TerrainTileId, Vector2, number], [TerrainTileId, Vector2, number]][] {
		const tilesIdsAabb2 = Aabb2.empty();
		for (const [id, _] of this.tiles) {
			tilesIdsAabb2.expandByPoint(id);
		}

		const discontinuities: [[TerrainTileId, Vector2, number], [TerrainTileId, Vector2, number]][] = [];

		for (let tileIdY = tilesIdsAabb2.min.y; tileIdY < tilesIdsAabb2.max.y; ++tileIdY) {
			for (let tileIdX = tilesIdsAabb2.min.x; tileIdX < tilesIdsAabb2.max.x; ++tileIdX) {
				const tileId = TerrainTileId.new(tileIdX, tileIdY);
				const tile = this.tiles.get(tileId);
				const tileGeo = regularHeightmapGeometries.peekById(tile?.selectGeoId(version) ?? 0);
				if (!tileGeo) {
					continue;
				}

				let neighborTileId = TerrainTileId.new(tileIdX + 1, tileIdY);
				let neighborTile = this.tiles.get(neighborTileId);
				let neighborTileGeo = regularHeightmapGeometries.peekById(neighborTile?.selectGeoId(version) ?? 0);
				if (neighborTileGeo) {
					for (let iy = 0; iy <= tileGeo.ySegmentsCount; ++iy) {
						const elevation = tileGeo.readElevationAtInds(tileGeo.xSegmentsCount, iy);
						const neighbourElevation = neighborTileGeo.readElevationAtInds(0, iy);
						if (!Object.is(elevation, neighbourElevation) && elevation !== neighbourElevation) {
							discontinuities.push([
								[tileId, new Vector2(tileGeo.xSegmentsCount, iy), elevation], 
								[neighborTileId, new Vector2(0, iy), neighbourElevation]
							]);
						}
					}
				}

				neighborTileId = TerrainTileId.new(tileIdX, tileIdY + 1);
				neighborTile = this.tiles.get(neighborTileId);
				neighborTileGeo = regularHeightmapGeometries.peekById(neighborTile?.selectGeoId(version) ?? 0);
				if (neighborTileGeo) {
					for (let ix = 0; ix <= tileGeo.xSegmentsCount; ++ix) {
						const elevation = tileGeo.readElevationAtInds(ix, tileGeo.ySegmentsCount);
						const neighbourElevation = neighborTileGeo.readElevationAtInds(ix, 0);
						if (!Object.is(elevation, neighbourElevation) && elevation !== neighbourElevation) {
							discontinuities.push([
								[tileId, new Vector2(ix, tileGeo.ySegmentsCount), elevation], 
								[neighborTileId, new Vector2(ix, 0), neighbourElevation]
							]);
						}
					}
				}
			}
		}

		return discontinuities;
	}
}
