import { Aabb2, KrMath, Matrix4, PolygonUtils, Transform, Vector2, Vector3 } from 'math-ts';
import { NotificationDescription, NotificationType, type FileImporterContext } from 'ui-bindings';
import { DefaultMap, IterUtils, Yield } from 'engine-utils-ts';
import { notificationSource } from '../Notifications';
import { SceneInstance, Bim, BimPatch, IdBimScene, SceneInstancePatch, TerrainTileId, triangulateSurface, cutTerrainIntoTiles, TerrainTile, TerrainHeightMapRepresentation, newDefaultTerrainInstance, BimProperty, IdInEntityLocal, LocalIdsEdge, SegmentInterp, LocalIdsCounter, SegmentInterpLinear, GraphGeometry, BasicAnalyticalRepresentation, Points2D, ExtrudedPolygonGeometry, WgsProjectionOrigin, WGSCoord, UnitsMapper, Catalog, ProjectionInfo, AnyTrackerProps, AssetId } from 'bim-ts';
import { contoursSimplifyingEps } from '../CommonExportSettings';
import { convertTerrainGeometryToTerrainHeightmapInstance } from 'src/TerrainToRegularGridConverter';

export class GroundImportSettings {
	as_regular_geometry: boolean;

    constructor(asRegularGeometry: boolean = true) {
        this.as_regular_geometry = asRegularGeometry;
    }
}

export interface LayoutJson {
    Contour3D: BorderJson[],
    Transformers: TransformerDataInput[]
    Roads: RoadDataInput[],
    Trackers: ShadingDataInput[],
    Substations?:SubstationDataInput[],
    GeoData?: LayoutGeoData,
    PluginVersion?: string,
    Surfaces:SurfaceJson[],
}

export interface BorderJson{
    name?:string;
    points:Vector3[];
}

export interface SurfaceJson {
    Name: string;
    LinearCoordsMinMax2d?: number[],
    GeographicCoordsMinMax2d?: number[],
    InnerPoints3d: (Float64Array|number[]),
    ContoursPoints3d: (Float64Array|number[])[],
}

export interface LayoutGeoData{
    ReferencePoint?:{
        Altitude: number,
        Longitude: number,
        Latitude: number
    };
    ReferencePointOrigin?: Vector3;
    ProjectionInfo?: ProjectionInfo;
}

export interface RoadDataInput {
    Points: Vector3[];
    Width: number;
}

export interface SubstationDataInput {
    Name: string;
    Origin: number[];
    Rotation: number;
}

export interface TransformerDataInput {
    Name: string;
    Origin: number[];
    Id: string;
    Rotation: number;
    BlockNumber: number;
}

export interface ShadingDataInput {
    Name: string;
    Origin: number[];
    Rotation: number;
    TransformerId: string,
    Width: number;
    Length: number;
    NumberOfModulesPerString: number;
    NumberOfModulesPerRow: number;
    ModuleModel: string;
    ModulePower: string;
    Manufacturer: string;

}

export enum TrackerMatch {
    Size,
    Modules,
    Model,
    Manufacturer
}

type InstanceGetter = (type: 'trackers' | 'skid', name: string) => SceneInstance | undefined;

export function allocateEquipment(
    bim: Bim,
    layoutJson: LayoutJson,
    instanceGetter: InstanceGetter,
    correction: Vector2,
    context: FileImporterContext, 
    catalog: Catalog
): { 
    bimPatch: BimPatch, 
    matixes: Map<IdBimScene, Matrix4>, 
    patches: [IdBimScene, SceneInstancePatch][] 
} {

    if(!layoutJson.Transformers?.length){
        context.logger.info(`No transformers found in the file.`);
    }
    if(!layoutJson.Trackers?.length){
        context.logger.info(`No trackers found in the file.`);
    }
    if(!layoutJson.Substations?.length){
        context.logger.info(`No substations found in the file.`);
    }

    const bimPatch = new BimPatch();
    const matixes: Map<IdBimScene, Matrix4> = new Map();
    const trPatches: [IdBimScene, SceneInstancePatch][] = [];

    function pointToLocalCoords(point: [number, number, number]): Vector3 {
        return new Vector3(point[0] - correction.x, point[1] - correction.y, 0)
    }

    function normalizedRadians(rotation: number): number {
        const normAngle = (rotation % (2 * Math.PI) - 2 * Math.PI) % (2 * Math.PI);
        return  KrMath.roundTo(normAngle, 0.0001);
    }

    function catalogInstanceToBim(
        instance: SceneInstance,
        coordinates?: number[],
        rotation?: number,
        parentId?: IdBimScene
    ): IdBimScene | undefined {
        const position = coordinates as [number, number, number];
        if (position == null) {
            context.logger.error(`failed to allocate ${instance.type_identifier}. Bad coodrdinates.`);
            return undefined;
        }

        const newInstance = getInstance(instance);

        if (newInstance == null) {
            context.logger.error(`failed to allocate ${instance.type_identifier}.Can't create scene instance from catalog instance.`);
            return undefined;
        }

        const newInstanceId: IdBimScene = bim.instances.idsProvider.reserveNewId();
        const localPosition = pointToLocalCoords(position);
        const matrix = new Matrix4();

        if (rotation) {
            rotation = +rotation.toFixed(4);
            matrix.makeRotationZ(normalizedRadians(rotation));
        }
        matrix.setPosition(localPosition.x, localPosition.y, localPosition.z);

        if (parentId != null) {
            newInstance.spatialParentId = parentId;
        }

        bimPatch.instances.toAlloc.push([newInstanceId, newInstance]);
        matixes.set(newInstanceId, matrix);
        return newInstanceId;
    }

    const substations = layoutJson.Substations ?? [];
    const trackers = layoutJson.Trackers;
    let transformers = layoutJson.Transformers;

    //Insert Substation
    let substationId: IdBimScene | undefined = undefined;
    const substationCatalogInstances = getCatalogInstances<SceneInstance>(catalog, 'substation');
    if(substationCatalogInstances.length){
        const substationInstance = substationCatalogInstances[0];

        if(substations.length){
            for(const substation of substations){
                substationId = catalogInstanceToBim(substationInstance, substation.Origin, substation.Rotation);
            }


        }else if (trackers.length) {
            
            if (substationCatalogInstances.length) {
                const substationCoords = getLayoutMaxPoint(layoutJson);
                substationId = catalogInstanceToBim(substationInstance, [substationCoords.x, substationCoords.y, substationCoords.z]);
            }
        }


    }

    

    if (trackers != null) {
        const trackergroups = IterUtils.groupBy(trackers, (tracker) => tracker.TransformerId);
        for (const groupData of trackergroups) {
            const trackers = groupData[1];
            const key = groupData[0];
            let transfId: IdBimScene | undefined = undefined;
            const index = transformers.findIndex(t => t.Id === key);

            if (index !== -1) {
                const transformer = transformers[index];
                transformers.splice(index, 1);



                const instance = instanceGetter('skid', transformer.Name);
                if (instance) {
                    transfId = catalogInstanceToBim(instance, transformer.Origin, transformer.Rotation, substationId);
                    if (transfId) {
                        trPatches.push([
                            transfId,
                            {
                                properties: [
                                    [
                                        "circuit | position | block_number",
                                        {
                                            path: ["circuit", "position", "block_number"],
                                            value: transformer.BlockNumber,
                                            numeric_step: 1
                                        },
                                    ],
                                ],
                            },
                        ]);
                    }
                }

            }

            if (transfId === undefined && substationId !== undefined) {
                transfId = substationId;
            }

            for (const t of trackers) {
                const instance = instanceGetter('trackers', t.Name);
                if (instance) {
                    catalogInstanceToBim(instance, t.Origin, t.Rotation, transfId);
                }
            }
        }

        for (const transformer of transformers) {
            const instance = instanceGetter('skid', transformer.Name);
            if (instance) {
                catalogInstanceToBim(instance, transformer.Origin, transformer.Rotation, substationId);
            }
        }
    }

    return { bimPatch: bimPatch, matixes: matixes, patches: trPatches };
}

export function* importSurface(
	bim: Bim,  
	surface: SurfaceJson, 
	correcrion:Vector3, 
	context: FileImporterContext,
	params: GroundImportSettings) {
	{
		// try to find and filter out lone distant points
		// sometimes we have garbage data ¯\_(ツ)_/¯

		const pointsIndsPerBigTile = new DefaultMap<TerrainTileId, number[]>(() => []);
		const FilteringOutCheckTileSize = 250;
		const reusedV = new Vector2();
		for (let i = 0; i < surface.InnerPoints3d.length; i += 3) {
			const x = surface.InnerPoints3d[i + 0];
			const y = surface.InnerPoints3d[i + 1];
			reusedV.set(x, y);
			const tile = TerrainTileId.newFromPoint(reusedV, FilteringOutCheckTileSize);
			pointsIndsPerBigTile.getOrCreate(tile).push(i / 3);
		};

		const erroneousPointsIndices: number[] = [];
		outerPerTileLoop:
		for (const [tileId, pointsInds] of pointsIndsPerBigTile) {
			if (pointsInds.length < 3) {
				// do we have neighbors?
				for (const x of [-1, 1]) {
					for (const y of [-1, 1]) {
						const tile = TerrainTileId.new(tileId.x + x, tileId.y + y);
						if (pointsIndsPerBigTile.has(tile)) {
							continue outerPerTileLoop;
						}
					}
				}
				// should be removed
				erroneousPointsIndices.push(...pointsInds)
			}
		}
		if (erroneousPointsIndices.length > 0) {
			context.logger.error('filtering out potentially erroneous surface points', erroneousPointsIndices);
			const indsSet = new Set(erroneousPointsIndices.flatMap(i => [i * 3, i * 3 + 1, i * 3 + 2]));
			surface.InnerPoints3d = surface.InnerPoints3d.filter((_, i) => !indsSet.has(i));
			context.sendNotification(NotificationDescription.newBasic({
				source: notificationSource,
				key: 'importSurfacePointsFilteredOut',
				descriptionArg: [erroneousPointsIndices.length],
				type: NotificationType.Warning,
				addToNotificationsLog: true,
			}));
		}
	}

	yield Yield.Asap;

	for (const contour of surface.ContoursPoints3d) {
		for (let i = 0; i < contour.length; i += 3) {
			contour[i + 0] -= correcrion.x;
			contour[i + 1] -= correcrion.y;
		}
	}
	for (let i = 0; i < surface.InnerPoints3d.length; i += 3) {
		surface.InnerPoints3d[i + 0] -= correcrion.x;
		surface.InnerPoints3d[i + 1] -= correcrion.y;
	}

	yield Yield.Asap;

	let startT = performance.now();
	const fullGeometry = yield* triangulateSurface(surface.InnerPoints3d, surface.ContoursPoints3d);
	console.log('points in polygons duration ms ', performance.now() - startT);

	yield Yield.Asap;
	if (params.as_regular_geometry) {
		// do conversion on full geometry, then split into tiles
		// to avoid artifacs on tiles borders
		const terrainBimPatch = yield* convertTerrainGeometryToTerrainHeightmapInstance(fullGeometry, bim, surface.Name, context);
		return terrainBimPatch;

	} else {
		const bimPatch = new BimPatch();
		const TERRAIN_TILE_SIZE = 255;

		startT = performance.now();
		const triangulationTiles = yield* cutTerrainIntoTiles({
			positionsFlat3d: fullGeometry.points3d,
			indices: fullGeometry.trianglesIndices,
			tileSize: TERRAIN_TILE_SIZE, // HARDCODE FOR NOW
		});
		console.log('cutting terrain into tiles duration ms ', performance.now() - startT);

		const reprTiles = new Map<TerrainTileId, Readonly<TerrainTile>>();

		for (const [tile, geometry] of triangulationTiles) {
			const geoId = bim.irregularHeightmapGeometries.reserveNewId();
			bimPatch.geometries.toAlloc.push([geoId, geometry]);
			reprTiles.set(tile, new TerrainTile(geoId, 0));
		}

		const representation = new TerrainHeightMapRepresentation(
			TERRAIN_TILE_SIZE,
			reprTiles
		);
		const surfaceName = surface.Name ?? "Surface";
		const instance = newDefaultTerrainInstance(bim, surfaceName, representation);
		const instanceId = bim.instances.reserveNewId();
		bimPatch.instances.toAlloc.push([instanceId, instance]);
		return bimPatch;
	}
}


export function allocateRoad(bim: Bim, roads: RoadDataInput[], correction: Vector3, context: FileImporterContext
): { bimPatch: BimPatch, matixes:Map<IdBimScene, Matrix4> } | null {
    if (!roads.length) {
        context.logger.info(`No roads found in the file.`);
        return null;
    }

    function createFolder(width:number):IdBimScene{
        const IsImperial = bim.unitsMapper.isImperial();
        const {value, unit} = bim.unitsMapper.mapToConfigured({value:width, unit:'m'});

        const folderInstanceId = bim.instances.reserveNewId();
        const folderInstance = new SceneInstance();
        folderInstance.name = `Roads ${value.toFixed(1)} ${unit}`;
        bimPatch.instances.toAlloc.push([folderInstanceId, folderInstance]);
        return folderInstanceId;
    }

    let bimPatch = new BimPatch();
    const positionPatches: Map<IdBimScene, Matrix4> = new Map();

    const roadWidthFolders: Map<number, IdBimScene>= new Map();
    let currentFolderId:IdBimScene = -1;

    for (const road of roads) {

        if(roadWidthFolders.has(road.Width)){
            currentFolderId = roadWidthFolders.get(road.Width) || -1
        }else{
            currentFolderId = createFolder(road.Width);
            roadWidthFolders.set(road.Width, currentFolderId);
        }

        const instanceId = bim.instances.reserveNewId();
        const instance = bim.instances.archetypes.newDefaultInstanceForArchetype('road');
        instance.spatialParentId = currentFolderId;

        const props = [
			BimProperty.NewShared({path: ['road', 'width'], value: road.Width, unit: 'm', }),
		];
		instance.properties.applyPatch(props.map(p => [p._mergedPath, p]));
        let closed = false;
        const graphId = bim.graphGeometries.idsProvider.reserveNewId();
        const pts = road.Points;

        //if road is closed loop
        const distance = pts[0].distanceTo(pts[pts.length-1]);
        if(distance < contoursSimplifyingEps && pts.length>2){
            pts.pop();
            closed = true;
        }

        
        if(pts.length<2){
            continue;
        }

        const graphPoints = new Map<IdInEntityLocal, Vector3>();
        const graphEdges = new Map<LocalIdsEdge, SegmentInterp>();

        const localId = new LocalIdsCounter();
        const segment = new SegmentInterpLinear()

        const pointsIds = localId.newIdsArray(pts.length);

        const firstPoint = pts[0].clone();
        const position = pts[0].clone().sub(correction);
        
        const m = new Matrix4().setPositionV(position);

        positionPatches.set(instanceId, m);

        for (let i = 0; i < pointsIds.length; i++) {
            const pointId = pointsIds[i]
            const point = pts[i].sub(firstPoint);

            graphPoints.set(pointId, point);

            if (i === 0) {
                continue;
            }
            const prevPointId = pointsIds[i - 1]
            const edge = LocalIdsCounter.newEdge(prevPointId, pointId);
            graphEdges.set(edge, segment);
        }

        if(closed){
            const edge = LocalIdsCounter.newEdge(pointsIds[pointsIds.length-1], pointsIds[0]);
            graphEdges.set(edge, segment);
        }

        const roadGraph = new GraphGeometry(graphPoints, graphEdges);
        instance.representationAnalytical = new BasicAnalyticalRepresentation(graphId);

        bimPatch.instances.toAlloc.push([instanceId, instance]);
        bimPatch.geometries.toAlloc.push([graphId, roadGraph]);
    }

    return {bimPatch:bimPatch, matixes:positionPatches} ;
}

export function allocateBorder(bim: Bim, borders: BorderJson[], correction: Vector2, context: FileImporterContext): BimPatch | null {
       
    if(!borders.length){
        context.logger.info(`No borders found in the file.`);
        return null;
    }

    const bimPatch = new BimPatch();

    //Add folder
    let folderInstanceId: IdBimScene = 0;

    const folders: { [name: string]: IdBimScene } = {};

    for (const border of borders) {
        if (border.points.length < 3) {
            continue;
        }
        const foldefName = border.name ?? "Borders";

        if (!folders[foldefName]) {
            // Create a new folder if it doesn't exist
            folderInstanceId = bim.instances.reserveNewId();
            const folderInstance = new SceneInstance();
            folderInstance.name = foldefName;
            bimPatch.instances.toAlloc.push([folderInstanceId, folderInstance]);
            folders[foldefName] = folderInstanceId

        }else{
            folderInstanceId = folders[foldefName];
        }

        const instanceId = bim.instances.reserveNewId();
        const instance = bim.instances.archetypes.newDefaultInstanceForArchetype('boundary');   
        instance.spatialParentId = folderInstanceId;
        instance.name = foldefName;
        

        const extrudeId = bim.extrudedPolygonGeometries.reserveNewId();
        const localId = new LocalIdsCounter();

        let pts = border.points.map(b => new Vector2(b.x - correction.x, b.y - correction.y));
        pts = PolygonUtils.simplifyContour(pts, contoursSimplifyingEps);
        const pointsIds = localId.newIdsArray(pts.length);

        const extrudePts = new Points2D(pts, pointsIds);
        const extrude = new ExtrudedPolygonGeometry(extrudePts);

        instance.representationAnalytical = new BasicAnalyticalRepresentation(extrudeId);

        bimPatch.instances.toAlloc.push([instanceId, instance]);
        bimPatch.geometries.toAlloc.push([extrudeId, extrude]);
    }

    return bimPatch;
}

export function getLayoutOrigin(layout: LayoutJson): Vector3 {

    const boundary= getLayoutPoints(layout);

    const center = boundary.getCenter();
    const correction = Vector3.fromVec2(center);

    return correction;
}

export function getLayoutMaxPoint (layout: LayoutJson ){

    const boundary = getLayoutPoints(layout);

    const max = boundary.max;
    let maxPoint = new Vector3(max.x, max.y, 0)

    return maxPoint;
}

function getLayoutPoints(layout: LayoutJson): Aabb2{
    let pointsToConsider: Vector3[] = [];

    if(layout.Contour3D.length > 0){
      const contourspoints:Vector3[] = layout.Contour3D.flatMap(x => x.points);
      IterUtils.extendArray(pointsToConsider, contourspoints);
    }
    
    if(layout.Trackers.length > 0){
        const trackersPoints:Vector3[] = layout.Trackers.map(x => Vector3.fromArray(x.Origin, 0));
        IterUtils.extendArray(pointsToConsider, trackersPoints);
    }
    
    if(layout.Roads.length > 0){
        const roadsPoints:Vector3[] = layout.Roads.flatMap(x => x.Points);
        IterUtils.extendArray(pointsToConsider, roadsPoints);
    }

    if(layout.Transformers.length > 0){
        const transfPoints:Vector3[] = layout.Transformers.map(x => Vector3.fromArray(x.Origin, 0));
        IterUtils.extendArray(pointsToConsider, transfPoints);
    }

    
    for(let i=0;i<layout.Surfaces.length; i++){
        const surface = layout.Surfaces[i];
        const center = getOriginFromSurface(surface)
        if(center!=null){
            pointsToConsider.push(center)
        }
    }

    const boundary = getPointsBoundary(pointsToConsider, "Layout points");
    
    return boundary;
}

function getOriginFromSurface(surface:SurfaceJson):Vector3 | null{
	let civilOrigin: Vector3 | null = null;

	if (surface.LinearCoordsMinMax2d === undefined) {

		let contoursAabb = Aabb2.empty();
		for (const contour of surface.ContoursPoints3d) {
			contoursAabb.union(Aabb2.calcFromArray(contour));
		}

		if (contoursAabb.isEmpty()) {
			console.error('points aabb is empty, invalid data', surface.ContoursPoints3d);
			return civilOrigin;
		}
        const center2d = contoursAabb.getCenter();
		civilOrigin = new Vector3(center2d.x, center2d.y, 0);

	} else {
		const allowedCoordsAabb2 = Aabb2.calcFromArray(surface.LinearCoordsMinMax2d);
		civilOrigin = new Vector3(
			allowedCoordsAabb2.getCenter().x,
			allowedCoordsAabb2.getCenter().y,
            0
		);
	}

	return civilOrigin;
}

function getCatalogInstances<T>(
    catalog: Catalog,
    typeIdetificator: string,
    mapFn: (si: Readonly<SceneInstance>, assetId: AssetId) => T
        = (si: Readonly<SceneInstance>) => si as T,
): T[] {
    const instances = [];
    const catalogAssets = catalog.assets.perId;
    for (const [assetId, _] of catalogAssets) {
        const instance = catalog.assets.assetsMatcher.sceneInstancePerAsset.getAssetAsSceneInstance(assetId)
        if (instance?.type_identifier === typeIdetificator) {
            instances.push(mapFn(instance, assetId));
        }
    }
    return instances;
}

export function getCatalogSkid(catalog: Catalog): SkidCatalogItem[] {
    return getCatalogInstances<SkidCatalogItem>(catalog, 'transformer', (si) => new SkidCatalogItem(si));
}

export function getCatalogTrackers(catalog: Catalog): TrackerCatalogItem[] {
    const trackers = getCatalogInstances<TrackerCatalogItem>(catalog, 'tracker', (si, assetId) => TrackerCatalogItem.newFromProperties(si, assetId));
    const fixedTilts = getCatalogInstances<TrackerCatalogItem>(catalog, 'fixed-tilt', (si, assetId) => TrackerCatalogItem.newFromProperties(si, assetId));
    const anyTrackers = getCatalogInstances<TrackerCatalogItem>(catalog, 'any-tracker', (si, assetId) => TrackerCatalogItem.newFromProps(si, assetId));

    return [...trackers, ...fixedTilts, ...anyTrackers];
}

function getInstance(instance: SceneInstance | null) : Partial<SceneInstance> | null {
    if (instance === null) {
        return null;
    }
    
    const newLocalTransform = new Transform();
    const newInstance: Partial<SceneInstance> = {
        colorTint: instance.colorTint,
        spatialParentId: instance.spatialParentId,
        localTransform: newLocalTransform,
        type_identifier: instance.type_identifier,
        properties: instance.properties.clone(),
        props: instance.props,
        name: instance.name,

    };
    
    return newInstance;
}

function getPointsBoundary(points: Vector3[], name: string): Aabb2 {
    let initialBounds: [number, number, number, number]; // minx, miny, maxx, maxy
    initialBounds = [Infinity, Infinity, -Infinity, -Infinity];
    for (const point of points) {
        if (point) {
            initialBounds[0] = Math.min(initialBounds[0], point.x);
            initialBounds[1] = Math.min(initialBounds[1], point.y);

            initialBounds[2] = Math.max(initialBounds[2], point.x);
            initialBounds[3] = Math.max(initialBounds[3], point.y);
        }
    }

    if (initialBounds.some((n) => !Number.isFinite(n))) {
        throw new Error(`${name} invalid bounds`);
    }

    const sourceLimits = Aabb2.calcFromArray(initialBounds);
    return sourceLimits;
}

export function parseLayoutWgsOrigin(
    layout: LayoutJson, 
    sendNotification: (notification: NotificationDescription) => void
): WgsProjectionOrigin | null{
    const layoutGeoData = layout.GeoData;

    if (layoutGeoData === undefined) {
        return null;
    }

    let layoutWgsOrigin: WgsProjectionOrigin | null = null;
    let { 
        ProjectionInfo:projectionInfo, 
        ReferencePoint:referencePoint, 
        ReferencePointOrigin:referencePointOrigin 
    } = layoutGeoData;

    if (layoutGeoData !== undefined) {
        if (projectionInfo !== undefined && projectionInfo.method !== "") {
            const origin = projectionInfo.getLonLatXY(sendNotification);

            if (origin) {
                return new WgsProjectionOrigin(origin.lonlat, projectionInfo, origin.xy);
            }
        } else {
            sendNotification(NotificationDescription.newBasic({
                type: NotificationType.Warning,
                source: notificationSource,
                key: 'projectionMethodMissing',
                addToNotificationsLog: true,
            }));
        }

        if(referencePoint && referencePointOrigin){

            const geoCoordinate = new WGSCoord(
                referencePoint.Latitude, 
                referencePoint.Longitude, 
                referencePoint.Altitude
            );
            const origin = new Vector3(
                referencePointOrigin.x, 
                referencePointOrigin.y,
                referencePointOrigin.z
            );
    
            layoutWgsOrigin = new WgsProjectionOrigin(geoCoordinate, projectionInfo ?? new ProjectionInfo(), origin);
            return layoutWgsOrigin;
        }
    }   
    return layoutWgsOrigin;
}

export function getTrackerName(tracker: ShadingDataInput, unitsMapper: UnitsMapper) {
    const mod = `${tracker.NumberOfModulesPerString}/${tracker.NumberOfModulesPerRow}MOD`;
    const name = tracker.Manufacturer ? `${tracker.Manufacturer} ${tracker.ModuleModel}<br/>${mod}` : `${tracker.Name}<br/>`;
    const length = unitsMapper.mapToConfigured({value: tracker.Length, unit: 'm'});
    const width = unitsMapper.mapToConfigured({value: tracker.Width, unit: 'm'});
    return `${name} ${width.value.toFixed(2)}${width.unit} × ${length.value.toFixed(2)}${length.unit}`;
}

export interface CatalogItem {
    sceneInstance: SceneInstance;
    getName: (unitsMapper: UnitsMapper) => string;
}

export class TrackerCatalogItem implements CatalogItem {
    constructor(
        readonly sceneInstance: SceneInstance,
        readonly assetId: AssetId,
        readonly length: number,
        readonly width: number,
        readonly manufacturer: string,
        readonly model: string,
        readonly stringModulesCount: number,
        readonly totalModulesCount: number,
    ) {}
    static newFromProperties(sceneInstance: SceneInstance, assetId: AssetId) {
        const isTracker = sceneInstance.type_identifier === 'tracker';
        const propertyPrefix = isTracker ? 'tracker-frame | ' : '';

        const length = sceneInstance.properties.get(`${propertyPrefix}dimensions | length`)?.as("m")!;
        const width = sceneInstance.properties.get(isTracker ? "tracker-frame | dimensions | max_width" : 'dimensions | width')?.as("m")!;
        const manufacturer = sceneInstance.properties.get(`${propertyPrefix}commercial | model`)?.asText()!;
        const model = sceneInstance.properties.get("module | model")?.asText()!;
        const stringModulesCount = sceneInstance.properties.get(`${propertyPrefix}string | modules_count`)?.asNumber()!;
        const totalModulesCount = sceneInstance.properties.get("circuit | equipment | modules_count")?.asNumber()!;
        return new TrackerCatalogItem(sceneInstance, assetId, length, width, manufacturer, model, stringModulesCount, totalModulesCount);
    }
    static newFromProps(sceneInstance: SceneInstance, assetId: AssetId) {
        const props = sceneInstance.propsAs(AnyTrackerProps);
        const length = props.tracker_frame.dimensions.length?.value ?? 0;
        const width = props.tracker_frame.dimensions.max_width?.value ?? 0;
        const manufacturer = props.tracker_frame.commercial.model.value;
        const model = props.module.model.value;
        const stringModulesCount = props.tracker_frame.string.modules_count.value;
        const totalModulesCount = sceneInstance.properties.get("circuit | equipment | modules_count")?.asNumber()!;
        return new TrackerCatalogItem(sceneInstance, assetId, length, width, manufacturer, model, stringModulesCount, totalModulesCount);
    }
    match(shadingDataInput: ShadingDataInput, tolerance: number) {
        const match = [];
        const EPSILON = 1e-3;
        const minDifference = tolerance + EPSILON;
        
        if (Math.abs(shadingDataInput.Length - this.length) < minDifference) {
            match.push(TrackerMatch.Size);
            if (shadingDataInput.Manufacturer && shadingDataInput.Manufacturer.toLowerCase() === this.manufacturer.toLowerCase()) {
                match.push(TrackerMatch.Manufacturer);
            }
            if (shadingDataInput.ModuleModel && shadingDataInput.ModuleModel.toLowerCase() === this.model.toLowerCase()) {
                match.push(TrackerMatch.Model);
            }
            if (shadingDataInput.NumberOfModulesPerString === this.stringModulesCount
                && shadingDataInput.NumberOfModulesPerRow === this.totalModulesCount) {
                match.push(TrackerMatch.Modules);
            }
        }
        return match;
    }

    getName(unitsMapper: UnitsMapper) {
        const convertedLength = unitsMapper.mapToConfigured({
            value: this.length,
            unit: "m",
        });
        const convertedWidth = unitsMapper.mapToConfigured({
            value: this.width,
            unit: "m",
        });

        return [
            this.manufacturer,
            this.model,
            "<br/>",
            `${this.stringModulesCount}/${this.totalModulesCount}MOD`,
            `${convertedWidth.value.toFixed(2)}${convertedWidth.unit} ×`,
            `${convertedLength.value.toFixed(2)}${convertedLength.unit}`
        ].join(" ");
    }

    getShortName() {
        return [
            this.manufacturer,
            this.model,
            `${this.stringModulesCount}/${this.totalModulesCount}MOD`,
        ].join(" ");
    }
    
    getHighlightedName(match: TrackerMatch[] = [], unitsMapper: UnitsMapper) {
        const convertedLength = unitsMapper.mapToConfigured({
            value: this.length,
            unit: "m",
        });
        const convertedWidth = unitsMapper.mapToConfigured({
            value: this.width,
            unit: "m",
        });

        return [
            this.wrapProp(
                this.manufacturer,
                match.includes(TrackerMatch.Manufacturer)
            ),
            this.wrapProp(
                this.model,
                match.includes(TrackerMatch.Model)
            ),
            "<br/>",
            this.wrapProp(
                `${this.stringModulesCount}/${this.totalModulesCount}MOD`,
                match.includes(TrackerMatch.Modules)
            ),
            this.wrapProp(`${convertedWidth.value.toFixed(2)}${convertedWidth.unit} ×`, false),
            this.wrapProp(
                `${convertedLength.value.toFixed(2)}${convertedLength.unit}`,
                match.includes(TrackerMatch.Size)
            ),
        ].join(" ");
    }

    wrapProp(value: string | number, isMatched: boolean) {
        return `<span class="${isMatched ? 'matched-prop' : 'not-matched-prop'}">${value}</span>`;
    }
}

export class SkidCatalogItem implements CatalogItem {
    readonly model;
    readonly power;
    readonly voltage;
    constructor(
        readonly sceneInstance: SceneInstance
    ) {
        this.model = sceneInstance.properties.get("commercial | model")?.asText()!;
        this.power = sceneInstance.properties.get("output | power")!;
        this.voltage = sceneInstance.properties.get("output | mv_voltage")!;
    }

    getName(unitsMapper: UnitsMapper) {
        return `${this.model} ${this.power.valueUnitUiString(unitsMapper)} ${this.voltage.valueUnitUiString(unitsMapper)}`;
    }
}
