import { Bim, BinsBuilder, getInRowIndex, LocalIdsCounter, PolylineGeometry, SceneInstance, TerrainHeightMapRepresentation, TerrainInstanceTypeIdent, WgsProjectionOrigin } from "bim-ts";
import {
    AnyTrackerProps,
    BasicAnalyticalRepresentation,
    calculateStdRepresentationLocalBBox,
    getCharCodesForPileIndex,
    getRowIndex,
    pileCrossSectionToString,
    StdMeshRepresentation,
    TrackerPilesCollection,
} from "bim-ts";
import type { BimImageType, IdBimScene, IdInEntityLocal, SceneImageRepresentation } from "bim-ts";
import { GraphGeometry } from "bim-ts";
import { Vector3, Vector2, Aabb, KrMath, Euler, Matrix4 } from "math-ts";
import { getTrackerDimensions, InstanceIsTracker } from "../instanceUtils";
import { MetersToFeet, Vector2ToFeet, Vector3ToFeet } from "./DxfUnitsConverters";
import type { FileExporterContext } from "ui-bindings";
import { IterUtils, RGBA, Success } from "engine-utils-ts";
import { getClosestColorFromAutocadPalette } from "./DxfColor";
import { DxfExporterSettings } from "./DxfFileExporter";
import { ConvertTerrainToTin } from "src/xml/TinCreator";

export const pileLayer = "pvfarm_piles";

export const boundaryLayer = "pvfarm_boundary";
export const roadLayer = "pvfarm_road";
export const trenchLayer = "pvfarm_trenches";
export const wireLayer = "pvfarm_trenches";
export const equipmentLayer = "pvfarm_equipment";
export const trackersLayer = "pvfarm_trackers";
export const annotationLayer = "pvfarm_annotations";

export type TableTextCell = {
    content: string;
    width: number;
};
export type TableSymbolCell = {
    symbol: string;
    color: number;
    width: number;
};
export type TableRow = (TableTextCell | TableSymbolCell)[];
export type Table = TableRow[];

export interface TrackerData {
    graphicData: DxfEquipmentData;
    modulsNum: number;
    color: number;
    layer: string;
    parameters: Map<string, { promt: string; value: string | number }>;
}

export interface TrackerType {
    type: string;
    graphicData: DxfEquipmentData;
    color: number;
    layer: string;
    parameters: Map<string, { promt: string; value: string | number }>;
    trackers: TrackerData[];
}

export interface DxfInstanceData {
    layer?: string;
    layerColour?: number;
}
export interface DxfImageData extends DxfInstanceData {
    name:string;
    insertionPoint:Vector3;
    heigh:number;
    width:number;
    scale:Vector3;
    worldSize:Vector2;
    rotation: Matrix4;

}

export interface DxfPolylineData extends DxfInstanceData {
    width: number;
    closed: boolean;
    color?: number;
    paperspace?:boolean;
    vertices: Vector3[] | Vector2[];
}

export interface DxfEquipmentData extends DxfInstanceData {
    name: string;
    origin: Vector3;
    rotation: number;
    length: number;
    polylines?: DxfPolylineData[];
}

export interface layoutTrackerData{
    trackerTypes:TrackerType[];
    blocksData:BlockData[];
    freeTrackers:TrackerData[];
}

export interface BlockData {
    parameters: Map<string, { promt: string; value: string | number }>;
    transformerName: string;
    transformerData: DxfEquipmentData;
    color: number;
    layer: string;
    blockName: string;
    trackersData: TrackerData[];
}

export interface DxfPileData {
    parentId:string;
    type: string;
    color: number;
    origin: Vector3;
    index: number;
    layer: string;
    rowIndex: string;
    rowCode: string;
}



export class DataService {
    private binsBuilder: BinsBuilder;
    public bim:Bim;
    public settings:DxfExporterSettings;
    public context: FileExporterContext;
    constructor(
        bim: Bim,
        settings: DxfExporterSettings,
        context: FileExporterContext,
        private pileCollection: TrackerPilesCollection
    ) {
        this.bim = bim;
        this.settings = settings;
        this.context = context;
        this.binsBuilder = new BinsBuilder(bim, pileCollection);
    }

    *setBins() {
        yield* this.binsBuilder.fillWithPiles();
    }

    getPilesData() {
        const projectCaresianOrigin =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3();
        const pilesData: DxfPileData[] = [];

        let index = 0;

        //By Tracker
      
        const bins = this.binsBuilder.binsPerTracker;
     
        for (const [i, binTables] of bins) {
            //By bin
            for (const binTable of binTables) {
                for (const [_, item] of binTable.items) {
                    const type = item.name;
                    const color = getClosestColorFromAutocadPalette(
                        item.color
                    ).paletteIndex;

                    for (const pileId of item.pileIds) {
                        const pile = this.pileCollection.peekById(pileId);
                        if (!pile) {
                            this.context.logger.error(
                                `Can't get pile ${pileId} instance!`
                            );
                            continue;
                        }

                        const tracker = this.bim.instances.peekById(
                            pile.parentId
                        );
                        if (!tracker) {
                            this.context.logger.error(
                                `Can't get tracker ${pile.parentId} instance!`
                            );
                            continue;
                        }

                        const trackerProps = tracker.props as AnyTrackerProps;

                        const trackerRowIndex = trackerProps.position?.row_index
                            ? getRowIndex(
                                  trackerProps.position.row_index.value
                              ) +
                              "." +
                              getInRowIndex(
                                  trackerProps.position.row_index.value
                              )
                            : undefined;

                        const pileRowIndex =
                            trackerProps.piles?.indices &&
                            trackerProps.piles?.indices.values[pile.inArrayIndex]
                                ? getRowIndex(
                                      trackerProps.piles.indices.values[
                                          pile.inArrayIndex
                                      ]!
                                  )
                                : undefined;
                        let pileRowCode: number[] | undefined = undefined;
                        if (pileRowIndex !== undefined) {
                            pileRowCode = [];
                            getCharCodesForPileIndex(
                                pileRowIndex - 1,
                                pileRowCode
                            );
                        }

                        const rowCode = `${trackerRowIndex ?? "N/A"}`;
                        const rowIndex = `${
                            pileRowCode
                                ? String.fromCharCode(...pileRowCode)
                                : "N/A"
                        }`;

                        let origin = pile.position.clone();
                        origin.add(projectCaresianOrigin);

                        pilesData.push({
                            parentId: pile.parentId.toString(),
                            color,
                            type,
                            origin,
                            index: ++index,
                            layer: pileLayer,
                            rowCode,
                            rowIndex,
                        });
                    }
                }
            }
        }

        return pilesData;
    }

    getPilesTableData(): Table | undefined {
        const isImperial = this.bim.unitsMapper.isImperial();

        const tableData: Table = [
            [
                { content: "Type ID", width: 15 },
                { content: "SYMBOL", width: 15 },
                { content: "COUNT", width: 15 },
                { content: "PILE PROFILE", width: 18 },
                {
                    content: `MAX REVEAL, ${isImperial ? "ft" : "m"}`,
                    width: 18,
                },
                { content: `EMBEDMENT, ${isImperial ? "ft" : "m"}`, width: 18 },
            ],
        ];

        for (const [i, binTables] of this.binsBuilder.binsPerTracker) {
            tableData.push([{ width: 99, content: i }]);
            for (const binTable of binTables) {
                for (const item of binTable.getSortedItems()) {
                    const name = item.name;
                    const count = item.pileIds.length.toString();

                    let maxRevealData = item.reveal[1];
                    let maxEmbedment = item.embedment[1];
                    let minEmbedment = item.embedment[0];

                    if (isImperial) {
                        maxEmbedment = MetersToFeet(maxEmbedment);
                        maxRevealData = MetersToFeet(maxRevealData);
                        minEmbedment = MetersToFeet(minEmbedment);
                    }

                    const embedment = `${minEmbedment.toFixed(
                        2
                    )} - ${maxEmbedment.toFixed(2)}`;

                    const color = getClosestColorFromAutocadPalette(
                        item.color
                    ).paletteIndex;
                    const profile = item.profileInScene
                        ? pileCrossSectionToString(item.profileInScene)
                        : "";

                    const tableRow: TableRow = [
                        { width: 15, content: name },
                        { width: 15, symbol: name, color },
                        { width: 15, content: count },
                        { width: 18, content: profile },
                        { width: 18, content: maxRevealData.toFixed(2) },
                        { width: 18, content: embedment },
                    ];

                    tableData.push(tableRow);
                }
            }
        }

        return tableData;
    }

    getWiringData(): DxfPolylineData[] {
        const data: DxfPolylineData[] = [];
        const wireData = getCategory(
            this.bim,
            this.settings,
            "wire",
            "lv-wire"
        );
        const origin3d =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3();

        const layerColour = 0;

        for (const [wireId, wire] of wireData) {
            const type = wire.type_identifier;
            let layerName = `pvfarm_${type}`;

            if (type === "wire") {
                const material = wire.properties
                    .get("commercial | material")
                    ?.asText();
                const size = wire.properties.get("commercial | size")?.asText();
                layerName = `${layerName}_${material}_${size}`;
            } else {
                const p1 = wire.properties
                    .get("specification | type")
                    ?.asText();
                const p2 = wire.properties
                    .get("specification | gauge")
                    ?.asText();
                const p3 = wire.properties
                    .get("specification | material")
                    ?.asText();

                layerName = `${layerName}_${p1}_${p2}_${p3}`;
            }

            layerName = layerName.replaceAll("/", "-");

            const wirePolylinePoints = getPointsFromPolylineBasedInstance(
                this.bim,
                wire
            );
            if (!wirePolylinePoints) {
                console.log(
                    `Can't get polyline geometry from instance ${wireId}`
                );
                continue;
            }

            wirePolylinePoints.forEach((p) => p.add(origin3d));

            const wireColor = getInstanceColor(wire);

            data.push({
                layer: layerName,
                layerColour,
                width: 0,
                color: wireColor,
                closed: false,
                vertices: wirePolylinePoints,
            });
        }

        return data;
    }

    getRoadData(): DxfPolylineData[] {
        const data: DxfPolylineData[] = [];
        const roadIds = getCategory(this.bim, this.settings, "road");
        const closed = false;
        const origin3d =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3();

        for (const [roadId, road] of roadIds) {
            const vertices = getPointsFromGraphBasedInstance(this.bim, road);

            if (!vertices) {
                console.log(
                    `Can't get polyline geometry from instance ${roadId}`
                );
                continue;
            }

            let width = 0;
            let layerName = "";
            if (this.bim.unitsMapper.isImperial()) {
                width = road.properties.get("road | width")?.as("ft") ?? 0;
                layerName = `${roadLayer}_${width}ft`;
            } else {
                width = road.properties.get("road | width")?.as("m") ?? 0;
                layerName = `${roadLayer}_${width}m`;
            }

            const layerColour = 6;

            for (const pl of vertices) {
                pl.forEach((p) => p.add(origin3d));

                data.push({
                    layer: layerName,
                    layerColour,
                    width,
                    closed,
                    vertices: pl,
                });
            }
        }
        return data;
    }

    getTrenchData(): DxfPolylineData[] {
        const origin3d =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3();
        const data: DxfPolylineData[] = [];
        const layerColour = 15;
        const closed = false;

        const trenchIds = getCategory(this.bim, this.settings, "trench");
        for (const [trenchId, trench] of trenchIds) {
            const vertices = getPointsFromPolylineBasedInstance(
                this.bim,
                trench
            );
            if (!vertices) {
                console.log(
                    `Can't get polyline geometry from instance ${trenchId}`
                );
                continue;
            }

            vertices.forEach((p) => p.add(origin3d));

            const width =
                trench.properties.get("dimensions | width")?.as("ft") ?? 0;

            data.push({
                layer: trenchLayer,
                layerColour,
                width,
                closed,
                vertices,
            });
        }

        return data;
    }

    getBoundaryData(): DxfPolylineData[] {
        const data: DxfPolylineData[] = [];
        const origin3d =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3(0, 0, 0);
        const origin = new Vector2(origin3d.x, origin3d.y);

        const closed = true;

        const layerColour = 2;
        const width = 1;

        const selectedIds = this.settings.export_only_selected
            ? this.bim.instances.getSelected()
            : this.bim.instances.readAll().map((i) => i[0]);

        const boundaries = this.bim.extractBoundaries();

        for (const boundary of boundaries) {
            if (selectedIds.includes(boundary.bimObjectId)) {
                const vertices: Vector2[] = [];

                for (let p of boundary.pointsWorldSpace) {
                    if (isNaN(p.x) || isNaN(p.y)) {
                        this.context.logger.error(
                            "point in border is filtered due to NaN coordinate"
                        );
                    } else {
                        p = p.add(origin);
                        vertices.push(p);
                    }
                }
                data.push({
                    layer: boundaryLayer,
                    layerColour,
                    width,
                    closed,
                    vertices,
                });
            }
        }

        return data;
    }

    getEquipmentData(): DxfEquipmentData[] {
        const collectedCategories = [
            "transformer",
            "inverter",
            "substation",
            "combiner-box",
            "sectionalizing-cabinet",
        ];
        const equipments: [IdBimScene, SceneInstance][] = getCategory(
            this.bim,
            this.settings,
            ...collectedCategories
        );
        const equipmentData: DxfEquipmentData[] = [];

        const layerColour = 7;

        for (const [eqId, equipment] of equipments) {
            const equipmentType = equipment.type_identifier;

            const children =
                this.bim.instances.spatialHierarchy.iteratorOfChildrenOf(eqId);
            if (
                equipmentType === "transformer" &&
                Array.from(children).length > 0
            ) {
                continue;
            }
            let blockName = "";

            switch (equipmentType) {
                case "transformer":
                    blockName = getTransformerName(equipment);
                case "combiner-box":
                    blockName = `pvfarm_${equipment.name}`;
                    break;
                default:
                    blockName = `pvfarm_${equipmentType}`;
                    break;
            }

            const graphicData = this.getEqipmentGraphicData(equipment, eqId);
            graphicData.layer = equipmentLayer;
            graphicData.layerColour = layerColour;
            graphicData.name = blockName;
            if (graphicData.polylines !== undefined) {
                for (const cont of graphicData.polylines) {
                    cont.layer = equipmentLayer;
                    cont.layerColour = layerColour;
                }
            }

            equipmentData.push(graphicData);
        }

        return equipmentData;
    }

    getTrackersData(): layoutTrackerData {
        const selectedIds = this.settings.export_only_selected
            ? this.bim.instances.getSelected()
            : this.bim.instances.readAll().map((i) => i[0]);

        const blocksData: BlockData[] = [];
        const freeTrackers: TrackerData[] = [];

        //Get selected transformers
        let trasformers = getCategory(this.bim, this.settings, "transformer");
        trasformers = trasformers.sort();

        const groupNames: Map<number, number> = new Map<number, number>();

        for (const [transformerId, transformer] of trasformers) {
            const blockData = this.getTransformerData(
                transformerId,
                groupNames
            );
            if (!blockData) {
                continue;
            }

            //Get all children of transformator;
            const childrenArray = getAllChildrenTrackersAndFixedTilts(
                this.bim,
                transformerId
            );

            for (const trackerId of childrenArray) {
                const index = selectedIds.findIndex((d) => d === trackerId);
                if (index > 0) {
                    const trackerData = this.getTrackerData(trackerId);
                    if (trackerData) {
                        blockData.trackersData.push(trackerData);
                        blockData.color = trackerData.color;
                    }else{
                        console.log(`Can not export tracker ${trackerId}!`)
                    }

                    selectedIds.splice(index, 1);
                    if (selectedIds.length == 0) {
                        break;
                    }
                }
            }
              
            blockData.parameters.set("NUMBEROFTRACKERS", {
                promt: "Number of Trackers",
                value: blockData.trackersData.length,
            });

            blocksData.push(blockData);
        }

        //Get trackers without trasformer data
        if (selectedIds.length != 0) {
            for(const freeTrackerId of selectedIds){
                const freeTrackerData = this.getTrackerData(freeTrackerId);
                if(freeTrackerData){
                    freeTrackers.push(freeTrackerData)
                }
                
            }
        }


        return { trackerTypes:[], blocksData, freeTrackers };
    }

    private getTransformerData(
        transformerId: IdBimScene,
        groupNames: Map<number, number>
    ): BlockData | undefined {
        const transformerInstance = this.bim.instances.peekById(transformerId);

        if (
            !transformerInstance ||
            !transformerInstance.type_identifier.includes("transformer")
        ) {
            return;
        }
        const transformerName = transformerInstance.name;
        const groupColor = getInstanceColor(transformerInstance);

        const powerParam =
            transformerInstance.properties
                .get("circuit | block_capacity | dc_power")
                ?.as("kW") ?? 0;
        const transformerPower = Math.round(powerParam).toString() + " kW";

        const blockNumber =
            transformerInstance.properties
                .get("circuit | position | block_number")
                ?.asNumber() ?? transformerId;

        const currentValue = groupNames.get(blockNumber) ?? 0;
        groupNames.set(blockNumber, currentValue + 1);
        const blockCount = groupNames.get(blockNumber)!;

        const baseName = `pvfarm_block_${blockNumber}`;
        const blockName =
            blockCount > 1 ? `${baseName}_${currentValue + 1}` : baseName;

        const layer = `pvfarm_solar_plant`;

        const transformerData = this.getEqipmentGraphicData(
            transformerInstance,
            transformerId
        );
        transformerData.name = getTransformerName(transformerInstance);
        transformerData.layer = layer;

        const parameters = new Map<
            string,
            { promt: string; value: string | number }
        >();
        parameters.set("GROUPNUMBER", { promt: "Number", value: blockNumber });
        parameters.set("GROUPTRANSFORMERID", {
            promt: "Transformer Id",
            value: transformerId,
        });
        parameters.set("GROUPPOWER", {
            promt: "Power",
            value: transformerPower,
        });

        return {
            transformerName,
            transformerData,
            color: groupColor,
            layer,
            blockName,
            trackersData: [],
            parameters,
        };
    }

    private getEqipmentGraphicData(
        instance: SceneInstance,
        id: IdBimScene
    ): DxfEquipmentData {
        const result = calculateStdRepresentationLocalBBox(
            instance.representation,
            this.bim.allBimGeometries
        );

        if (!(result instanceof Success)) {
            throw new Error(
                `${instance.name} with id [${id}] has no representation!`
            );
        }

        const equipmentColor = 0;//getInstanceColor(instance);

        const bBox = result.value;
        const vertices = bBox.get2DCornersAtZ(0) as Vector3[];
        const length = bBox.maxy() - bBox.miny();

        let origin = bBox.getCenter_t();
        origin.applyMatrix4(instance.worldMatrix);
        origin.z = 0;
        
        let rotation = getInstanceRotation(instance);

        const polylineData: DxfPolylineData = {
            closed: true,
            width: 0,
            vertices,
            color: equipmentColor
        };

        return { name: instance.name, origin, rotation, length, polylines: [polylineData] };
    }

    private getTrackerData(trackerId: IdBimScene): TrackerData | undefined {
        const tracker = this.bim.instances.peekById(trackerId);

        if (!InstanceIsTracker(tracker) || !tracker) {
            return;
        }

        const representation = tracker.representation;
        if (representation === null) {
            return;
        }

        const { length, width } = getTrackerDimensions(tracker);

        const {
            modulsNum,
            manufacturer,
            moduleModel,
            modulePower,
            numberOfModulesPerString,
            numberOfModulesPerRow,
        } = this.getTrackerModuleProperties(tracker);

        const type = tracker.type_identifier === "any-tracker" ? "tracker" : tracker.type_identifier

        const name = sanitizeBlockName(
            `pvfarm_${type}_${manufacturer}_${modulsNum}_${moduleModel}`
        );            

        const parametersMap = this.buildParametersMap(
            trackerId,
            name,
            length,
            width,
            numberOfModulesPerString,
            numberOfModulesPerRow,
            moduleModel,
            modulePower,
            manufacturer
        );

        const trackerColor = getInstanceColor(tracker);

        const graphicData = this.getEqipmentGraphicData(tracker, trackerId);
        graphicData.name = name;

        const trackerData: TrackerData = {
            graphicData,
            modulsNum,
            layer: trackersLayer,
            color: trackerColor,
            parameters: parametersMap,
        };

        return trackerData;
    }

    private getTrackerModuleProperties(tracker: any): {
        modulsNum: number;
        manufacturer: string;
        moduleModel: string;
        modulePower: number;
        numberOfModulesPerString: number;
        numberOfModulesPerRow: number;
    } {
        let modulsNum = 0;
        let manufacturer = "";
        let moduleModel = "";
        let modulePower = 0;
        let numberOfModulesPerString = 0;
        let numberOfModulesPerRow = 0;

        if (tracker.props instanceof AnyTrackerProps) {
            modulePower = tracker.props.module.maximum_power.value;
            moduleModel = tracker.props.module.model.value;
            modulsNum =
                tracker.props.tracker_frame.modules.modules_count_x.value;
            numberOfModulesPerString =
                tracker.props.tracker_frame.string.modules_count_x.value;
            manufacturer = tracker.props.module.manufacturer.value;
            numberOfModulesPerRow =
                tracker.props.tracker_frame.string.modules_count_y.value;
        } else {
            modulePower =
                tracker.properties.get("module | maximum_power")?.asNumber() ??
                0;
            modulsNum =
                tracker.properties
                    .get("circuit | equipment | modules_count")
                    ?.asNumber() ?? 0;
            manufacturer =
                tracker.properties.get("commercial | manufacturer")?.asText() ??
                "";
            moduleModel =
                tracker.properties.get("module | model")?.asText() ?? "";
            numberOfModulesPerString =
                tracker.properties.get("modules | count_x")?.asNumber() ?? 0;
            numberOfModulesPerRow =
                tracker.properties.get("modules | count_y")?.asNumber() ?? 0;
        }

        return {
            modulsNum,
            manufacturer,
            moduleModel,
            modulePower,
            numberOfModulesPerString,
            numberOfModulesPerRow,
        };
    }

    private buildParametersMap(
        trackerId: IdBimScene,
        name: string,
        length: number,
        width: number,
        numberOfModulesPerString: number,
        numberOfModulesPerRow: number,
        moduleModel: string,
        modulePower: number,
        manufacturer: string
    ): Map<string, { promt: string; value: string | number }> {
        const parametersMap: Map<
            string,
            { promt: string; value: string | number }
        > = new Map();

        parametersMap.set("TRACKERID", {
            promt: "Tracker Id",
            value: trackerId.toString(),
        });
        parametersMap.set("TRACKERNAME", {
            promt: "Tracker Name",
            value: name,
        });
        parametersMap.set("WIDTH", { promt: "Tracker Width", value: width });
        parametersMap.set("LENGTH", { promt: "Tracker Length", value: length });
        parametersMap.set("NUMBER_OF_MODULES_PER_STRING", {
            promt: "Number of modules per string",
            value: numberOfModulesPerString,
        });
        parametersMap.set("NUMBER_OF_MODULES_PER_ROW", {
            promt: "Number of modules per row",
            value: numberOfModulesPerRow,
        });
        parametersMap.set("MODULE_MODEL", {
            promt: "Module model",
            value: moduleModel.replaceAll("/", "-"),
        });
        parametersMap.set("MODULE_POWER", {
            promt: "Module power",
            value: modulePower,
        });
        parametersMap.set("MANUFACTURER", {
            promt: "Manufacturer",
            value: manufacturer.replaceAll("/", "-"),
        });

        return parametersMap;
    }

    *getTerraineFaces(trianglesSize: number) {
        const selectedIds = this.bim.instances.readAll().map((i) => i[0]);
        const terrainInstances = this.bim.instances
            .peekByTypeIdent(TerrainInstanceTypeIdent)
            .filter((x) => selectedIds.includes(x[0]));

        for (const [id, terrainInstance] of terrainInstances) {
            if (
                !(
                    terrainInstance.representation instanceof
                    TerrainHeightMapRepresentation
                )
            ) {
                continue;
            }

            const tinSurfaceResult = ConvertTerrainToTin(
                this.bim,
                id,
                trianglesSize
            );
            if (tinSurfaceResult instanceof Success) {
                yield tinSurfaceResult.value;
            }
        }
    }

    getImages(): DxfImageData[] {
        const imagesInstances = this.bim.instances.peekByTypeIdent("image");
        const origin =
            this.bim.instances.getSceneOrigin()?.cartesianCoordsOrigin ??
            new Vector3();
        const isImperial = this.bim.unitsMapper.isImperial();
        const data: DxfImageData[] = [];
        for (const [instanceId, instance] of imagesInstances) {
            const name = instance.name;
            
            const rotation = new Matrix4().extractRotation(instance.worldMatrix);
            const scale = instance.localTransform.scale;
            const representation =
                instance.representation as SceneImageRepresentation;
            let worldSize = representation.worldSize.clone();
            let insertionPoint = instance.worldMatrix.extractPosition();
            insertionPoint.sub(new Vector3((worldSize.x*scale.x)/2, (worldSize.y*scale.y)/2));
            insertionPoint.add(origin);

            const imgs: BimImageType[] = [];
            SceneInstance.imageIdsReferences(instance, imgs);
            for (const imgId of imgs) {
                const img = this.bim.bimImages.peekById(imgId);
                const ref = img?.assetRef;
                const inLine = img?.inline;
                if (inLine) {
                    let heigh = inLine.height;
                    let width = inLine.width;

                    if (isImperial) {
                        insertionPoint = Vector3ToFeet(insertionPoint);
                        worldSize = Vector2ToFeet(worldSize);
                    }

                    data.push({
                        name,
                        insertionPoint,
                        heigh,
                        width,
                        scale,
                        worldSize,
                        rotation,
                    });
                }
            }
        }

        return data;
    }

    getTotalBounds() {
        const selectedIds = this.settings.export_only_selected
            ? this.bim.instances.getSelected()
            : this.bim.instances.readAll().map((i) => i[0]);

        const geosAabbs = this.bim.allBimGeometries.aabbs.poll();

        const instances = this.bim.instances.peekByIds(selectedIds);
        const totalBounds = Aabb.empty();
        const reusedWmBounds = Aabb.empty();
        for (const [id, inst] of instances) {
            const representation =
                inst.representationAnalytical ?? inst.representation;
            if(representation === null){
                continue;
            }
            let localAabb: Aabb | undefined;
            if (
                representation instanceof StdMeshRepresentation
            ) {
                localAabb = representation.aabb(geosAabbs);
            } else if (
                representation instanceof BasicAnalyticalRepresentation
            ) {
                localAabb = representation.aabb(geosAabbs);
            } else {
                this.context.logger.warn(
                    "Unknown representation type",
                    representation
                );
            }
            if (!localAabb) {
                continue;
            }

            reusedWmBounds.copy(localAabb).applyMatrix4(inst.worldMatrix);
            totalBounds.union(reusedWmBounds);
        }

        totalBounds.expandByScalar(totalBounds.getSize().xy().length() * 0.01);

        const totalBoundsSize = totalBounds.getSize();
        if (!totalBoundsSize.isFinite()) {
            return new Aabb(-100,-100,0,100,100,0);
        }
        if (totalBoundsSize.xy().length() > 50_000) {
            throw new Error("Total bounds size is too big");
        }

        return totalBounds;
    }
}

function getPointsFromPolylineBasedInstance(bim:Bim, instance:SceneInstance): Vector3[] | undefined{

    if (!instance) {
        return;
    }

    let represent = instance.representationAnalytical;

    if (!represent) {
        return;
    }
    const geometry = bim.allBimGeometries.peekById(represent.geometryId);

    if (!(geometry instanceof PolylineGeometry)) {
        console.log("Instance geometry is not Polyline!")
        return;
    }
    
    const matrix = instance.worldMatrix;
    const points = Vector3.arrayFromFlatArray(geometry.points3d);
    for(const p of points){
        p.applyMatrix4(matrix);
    }

    return points;

}



function getPointsFromGraphBasedInstance(
    bim: Bim,
    instance: SceneInstance
): Vector3[][] | undefined {
    function traverseBranch(
        graphGeometry: GraphGeometry,
        startPoint: IdInEntityLocal,
        matrix: Matrix4
    ): Vector3[] {
        const polyline: Vector3[] = [];
        let currentPoint = startPoint;
        let previousPoint: IdInEntityLocal | null = null;

        while (true) {
            const rawPoint = graphGeometry.points.get(currentPoint);
            if (!rawPoint) {
                break;
            }

            const transformedPoint = rawPoint.clone().applyMatrix4(matrix);
            polyline.push(transformedPoint);

            const neighbors =
                graphGeometry.getPointsConnectedToPoint(currentPoint);

            if (neighbors.length < 3) {
                visitedPoints.add(currentPoint);
            }

            if (neighbors.length > 2) {
                break;
            }

            const nextPoint = neighbors.find(
                (id) => id !== previousPoint && !visitedPoints.has(id)
            );
            if (nextPoint === undefined) {
                break;
            }

            previousPoint = currentPoint;
            currentPoint = nextPoint;
        }

        return polyline;
    }

    if (!instance) {
        return;
    }
    const represent = instance.representationAnalytical;
    if (!represent) {
        return;
    }
    const graphGeometry = bim.allBimGeometries.peekById(represent.geometryId);
    if (!(graphGeometry instanceof GraphGeometry)) {
        return;
    }
    const polylines: Vector3[][] = [];
    const visitedPoints = new Set<number>();
    const matrix = instance.worldMatrix;

    const pointConnectionCount = new Map<IdInEntityLocal, number>();
    for (const [edgeId] of graphGeometry.edges) {
        const [id1, id2] = LocalIdsCounter.edgeToTuple(edgeId);
        pointConnectionCount.set(id1, (pointConnectionCount.get(id1) || 0) + 1);
        pointConnectionCount.set(id2, (pointConnectionCount.get(id2) || 0) + 1);
    }

    const isLoop = [...pointConnectionCount.values()].every(
        (count) => count === 2
    );

    if (isLoop) {
        for (const [pointId, _] of graphGeometry.points) {
            if (visitedPoints.has(pointId)) {
                continue;
            }

            const polyline = traverseBranch(graphGeometry, pointId, matrix);

            if (polyline.length > 1) {
                polylines.push(polyline);
            }
        }
    } else {
        for (const [pointId, _] of graphGeometry.points) {
            if (visitedPoints.has(pointId)) {
                continue;
            }
            const connectedPoints =
                graphGeometry.getPointsConnectedToPoint(pointId);

            if (connectedPoints.length !== 2) {
                const polyline = traverseBranch(graphGeometry, pointId, matrix);

                if (polyline.length > 1) {
                    polylines.push(polyline);
                }
            }
        }
    }

    return polylines;
}


function getInstanceColor(instance: SceneInstance) {
    const colorTint =
        instance.colorTint !== 0
            ? instance.colorTint
            : RGBA.parseFromHexString("#FFFFFF");
    const dxfColor = getClosestColorFromAutocadPalette(colorTint).paletteIndex;
    return dxfColor;
}

function getAllChildrenTrackersAndFixedTilts(bim: Bim, instanceId: IdBimScene) {
    const childrenCollection: IdBimScene[] = [];
    function collectChildren(instanceId: IdBimScene) {
        const inst = bim.instances.peekById(instanceId);
        const isTracker =
            inst?.type_identifier.includes("tracker") ?? false;
        const isFixedTilt =
            inst?.type_identifier.includes("fixed-tilt") ?? false;
        if (isTracker || isFixedTilt) {
            childrenCollection.push(instanceId);
        }

        if(inst?.type_identifier === "wire"){
            return;
        }

        const children =
            bim.instances.spatialHierarchy.iteratorOfChildrenOf(instanceId);
        
        
        const childrenArray = Array.from(children);
        if (childrenArray.length > 0) {
            childrenArray.forEach((childId) => collectChildren(childId));
        }
    }
    collectChildren(instanceId);

    return childrenCollection;
}



function getCategory(
    bim: Bim,
    settings: DxfExporterSettings,
    ...categories: string[]
): [IdBimScene, SceneInstance][] {

    const instansesIds: [IdBimScene, Readonly<SceneInstance>][] = [];
    const selectedIds = settings.export_only_selected ? bim.instances.getSelected() : bim.instances.readAll().map((i) => i[0]);

    for (const typeIdent of categories) {
        const filteredData = bim.instances
            .peekByTypeIdent(typeIdent)
            .filter((x) => selectedIds.includes(x[0]));

        IterUtils.extendArray(instansesIds, filteredData)

            
    }

    return instansesIds;
}



function isTrackerType(type: TrackerType, data: TrackerData) {
    for (const [tag, parameter] of type.parameters) {
        if (tag === "TRACKERID") {
            continue;
        }
        const trackerParam = data.parameters.get(tag)?.value;
        const typeParam = parameter.value;
        if (trackerParam !== typeParam) {
            return false;
        }
    }
    if (type.color !== data.color) {
        return false;
    }

    return true;
}

function getTransformerName(transformerInst: SceneInstance): string {
    let powerParam = 0;
    switch (transformerInst.type_identifier) {
        case "transformer":
            powerParam =
                transformerInst.properties.get("output | power")?.as("kW") || 0;
            break;
        case "inverter":
            powerParam =
                transformerInst.properties.get("inverter | max_power")?.as("kW") || 0;
            break;
        default:
            break;
    }

    const modelParam = transformerInst.properties
        .get("commercial | model")
        ?.asText();

    powerParam = Math.round(powerParam);

    const transformerBlockName = sanitizeBlockName(
        `pvfarm_skid_type_${modelParam}_${powerParam}kW`
    );

    return transformerBlockName;
}



function getInstanceRotation(instance:SceneInstance){
    const transformRotation = new Euler().setFromRotationMatrix(
        instance.worldMatrix
    );
    return KrMath.radToDeg(transformRotation.z);
}

export function sanitizeBlockName(name:string){
    name = name.replace(/[^a-zA-Z0-9*()$_-]/g, '');

    if (name.length > 255) {
        name = name.substring(0, 255);
    }
    return name;
}