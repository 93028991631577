import { FixedTiltTypeIdent, TrackerTypeIdent } from "bim-ts";
import { RGBA, RGBAHex } from "engine-utils-ts";
import { Aabb2, PointToPolygon, PolygonUtils, Vector2 } from "math-ts";
1
export function convertPxToMm(v: number) {
    return v * 420 / 1190
}

export const SolarArraysTypes = [TrackerTypeIdent, FixedTiltTypeIdent, "any-tracker"];

export const SubstationColor = RGBA.new(0.8, 0, 0, 1);
export const RoadColor = RGBA.new(0.08, 0.035, 0.24, 1);
export const EquipmentDefaultColor = RGBA.new(0.08, 0.035, 0.24, 1);
export const IncludeBoundaryColor = RGBA.new(0, 0.1, 0.9, 1);
export const ExcludeBoundaryBorderColor = RGBA.new(0.5, 0, 0, 1);
export const ExcludeBoundaryColor = RGBA.new(0.9, 0.8, 0.8, 1);

export class PalettePerType {
    private _counter = 0;
    private readonly _perType: Map<string | number, RGBAHex> = new Map();

    constructor(readonly colors: RGBAHex[]){

    }

    getNext(type: string | number){
        if(this._perType.has(type)){
            return this._perType.get(type)!;
        } else {
            const color = this.colors[this._counter++ % this.colors.length];
            this._perType.set(type, color);
            return color;
        }
    }

    getUsedColors() {
        return Array.from(this._perType.values());
    }
}

const colorsGradient: RGBAHex[] = [
    RGBA.newRGB(0.05, 0.8, 0.05),
    RGBA.newRGB(0.05, 0.05, 0.99),
    RGBA.newRGB(0.9, 0.05, 0.05),
];

export function createPalette(rowsCount: number, gradient: RGBAHex[] = colorsGradient){
    const colors: RGBAHex[] = [];
    for (let i = 0; i < rowsCount; i++) {
        const color = RGBA.lerpCustomMidPoint(
            gradient[0],
            gradient[2],
            (i / (rowsCount - 1)),
            gradient[1],
        );

        colors.push(color)
    }

    return new PalettePerType(colors);
}

function canFitRectangleAtCenter(
    polygon: Vector2[],
    center: Vector2,
    size: Vector2
): boolean {
    const halfW = size.x / 2;
    const halfH = size.y / 2;

    const corners: Vector2[] = [
        new Vector2(center.x - halfW, center.y - halfH),
        new Vector2(center.x + halfW, center.y - halfH),
        new Vector2(center.x + halfW, center.y + halfH),
        new Vector2(center.x - halfW, center.y + halfH),
    ];

    return corners.every(
        (p) => PolygonUtils.isPointInsidePolygon(polygon, p) !== PointToPolygon.Outside
    );
}

export function fitMaxRectangleInPolygon(
    polygon: Vector2[],
    aspectRatio: number,
    tolerance: number = 1e-6
) {
    const bbox = Aabb2.empty().setFromPoints(polygon);
    const center = PolygonUtils.getCentroid(polygon);

    let low = 0;
    let high = Math.min(bbox.height(), bbox.width() / aspectRatio);

    let bestH = 0;

    while (high - low > tolerance) {
        const mid = (low + high) / 2;
        const w = aspectRatio * mid;
        if (canFitRectangleAtCenter(polygon, center, new Vector2(w, mid))) {
            bestH = mid;
            low = mid;
        } else {
            high = mid;
        }
    }

    return {
        size: new Vector2(aspectRatio * bestH, bestH),
        center: center,
    };
}