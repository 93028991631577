import { DxfObject } from "./DxfEntities";

export class DXFNode {
    children: DXFNode[] = [];
    private _dxfObject?: DxfObject;
    closeNode?: DXFNode;

    type: string;
    handle?: string;

    get dxfObject(): DxfObject | undefined {
        return this._dxfObject;
    }

    set dxfObject(dxfObject: DxfObject | undefined) {
        if (dxfObject) {
            const dxfType = dxfObject.type;
            if (this.type !== dxfType) {
                throw new Error(
                    `The node type ${this.type} and dxf type ${dxfType} don't match!`
                );
            }

            this._dxfObject = dxfObject;
            this.handle = (dxfObject as any)["handle"] as string | undefined;
        }
    }

    constructor(data: string | DxfObject) {
        if (typeof data === "string") {
            this.type = data;
        } else {
            this.type = data.type;
            this.dxfObject = data;
        }
    }

    addChild(child: DXFNode|DxfObject): void {
        if(child instanceof DXFNode){
            this.children.push(child);
        }else{
            this.children.push(new DXFNode(child))
        }
    }

    *[Symbol.iterator](): Generator<DXFNode> {
        yield this;
        for (const child of this.children) {
            yield* child;
        }
    }

    findChildByHandle(handle: string): DXFNode | undefined {
        return this.children.find((child) => child.handle === handle);
    }

    //(recursive search)
    findDescendantByHandle(handle: string): DXFNode | undefined {
        if (this.handle === handle) {
            return this;
        }
        for (const child of this.children) {
            const result = child.findDescendantByHandle(handle);
            if (result) {
                return result;
            }
        }
        return undefined;
    }

    findNodesByType(type: string): DXFNode[] {
        const result: DXFNode[] = [];

        if (this.type === type) {
            result.push(this);
        }

        for (const child of this.children) {
            result.push(...child.findNodesByType(type));
        }
        
        return result;
    }

    traverse(callback: (node: DXFNode) => void): void {
        callback(this);
        for (const child of this) {
            child.traverse(callback);
        }
    }

    filter(predicate: (node: DXFNode) => boolean): DXFNode[] {
        const result: DXFNode[] = [];

        for (const child of this) {
            result.push(...child.filter(predicate));
        }

        return result;
    }

    /**
     * Finds the first node that satisfies the given predicate.
     * @param predicate A function that takes a DXFNode and returns a boolean.
     * @returns The first DXFNode that matches the predicate, or undefined if no match is found.
     */
    find(predicate: (node: DXFNode) => boolean): DXFNode | undefined {
        if (predicate(this)) {
            return this;
        }

        for (const child of this.children) {
            const result = child.find(predicate);
            if (result) {
                return result;
            }
        }

        return undefined;
    }
}