import type { Vector2 } from "math-ts";


export interface DemSurface {
    TypeA: DemHeader;
    TypeB: DemProfile[];
}

export interface DemHeader {
    QuadrangleName: string // 144 characters
    DemLevelCode: number; //always 1
    PatternCode: number; //always 1
    PlanimetricReferenceSystemCode: number; //always 0
    ZoneCode: number;
    ProjectParameters: number[]; // by defoult it shuould contain 15 zeros
    UnitsCodePlanimetric: UnitsCode; //3 represents arc-seconds// 2 represents meters
    UnitsCodeElevation: UnitsCode; //2 represents meters
    PoligonSides: number; //in most cases is 4
    DemBounds: Vector2[]; //x and y of 4 corners of the surface
    MinElevation: number;
    MaxElevation: number;
    Angle: number; // Counterclockwise angle (in radians)
    AccuracyCode: number; //1 by defoult
    SpatalResolution: { width: number, length: number, height: number };
    Profiles: { rows: number, columns: number } //rows is always 1 and columns is number of profiles
}

export interface DemProfile {
    Row: number; //row number always 1
    Column: number; //number of column
    NumberOfElevations: number;
    ProfileColumns: number; //always 1
    Coordinate: Vector2; //coordinates of the first elevation
    LokalElevation: number;
    MinProfileElevation: number;
    MaxProfileElevation: number;
    Elevations: number[];
}

export enum UnitsCode {
    feet = 1,
    Meters = 2,
    ArcSeconds,
}