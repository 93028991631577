import type { DefaultMap, RGBAHex} from 'engine-utils-ts';
import { ScopedLogger } from 'engine-utils-ts';
import { Builder, ByteBuffer } from 'flatbuffers';
import type { Transform } from 'math-ts';
import type { ObjectsPickingSerializer } from 'verdata-ts';

import type { IdBimScene, ObjectRepresentation, SceneInstance, SceneInstancePatch, SceneInstances } from '../';
import type { BimProperty } from '../bimDescriptions/BimProperty';
import { PropertiesCollection } from '../bimDescriptions/PropertiesCollection';
import { EmptyPropsStub } from '../properties/EmptyPropsStub';
import type { PropsGroupBase } from '../properties/Props';
import { PropsFieldFlags } from '../properties/PropsGroupComplexDefaults';
import type { BasicAnalyticalRepresentation } from '../representation/Representations';
import type { SceneInstancePropsShapeType, SceneInstancesArchetypes } from '../scene/SceneInstancesArhetypes';
import { SceneObjectInstance } from '../schema/scene-object-instance';
import { SceneObjectInstanceCollection } from '../schema/scene-object-instance-collection';
import { serializeTypeIdentifierVersion } from './ConfigSerializer';
import { FlatbufCommon } from './FlatbufCommon';
import { LegacyPropsDeserializer, LegacyPropsSerializer } from './LegacyPropertiesSerializer';
import { PropsGroupsDeserializer, PropsGroupsSerializer } from './PropsGroupsSerializer';
import { PropsDeserializer } from './PropsSerializer';
import {
    createAnalyticalFlatbufReprObjFromTypeId, createFlatbufReprObjectFromTypeId,
    InterningReprDeserializer, InterningReprSerializer
} from './RepresentationsSerializer';

export class SceneInstanceSerializable implements SceneInstancePropsShapeType {
    constructor(
        public type_identifier: string,
        public name: string,
        public colorTint: RGBAHex | 0,
        public localTransform: Transform,
        public properties: PropertiesCollection,
        public representation: ObjectRepresentation | null,
        public representationAnalytical: BasicAnalyticalRepresentation | null,
        public spatialParentId: IdBimScene,
        public electricParentId: IdBimScene,
        public hierarchySortKey: number,
        public connectedTo: Readonly<IdBimScene[]> | null = null,
        public props: PropsGroupBase,
    ) {}

    static fromFull(self: SceneInstance): SceneInstanceSerializable {
        return new SceneInstanceSerializable(
            self.type_identifier,
            self.name,
            self.colorTint,
            self.localTransform,
            self.properties,
            self.representation?.isRuntimeGenerated() ? null : self.representation,
            self.representationAnalytical?.isRuntimeGenerated() ? null : self.representationAnalytical,
            self.spatialParentId,
            self.electricalParentId,
            self.hierarchySortKey,
            self.connectedTo?.length ? self.connectedTo : null,
            self.props.cloneWithoutFlags(PropsFieldFlags.SkipSerialization),
        );
    }

    static toPartialFull(
        self: SceneInstanceSerializable
    ): Partial<SceneInstance> {
        return {
            type_identifier: self.type_identifier,
            name: self.name,
            colorTint: self.colorTint,
            localTransform: self.localTransform,
            properties: self.properties,
            representation: self.representation,
            representationAnalytical: self.representationAnalytical,
            spatialParentId: self.spatialParentId,
            electricalParentId: self.electricParentId,
            hierarchySortKey: self.hierarchySortKey,
            connectedTo: self.connectedTo,
            props: self.props,
        };
    }

    static withDifferentFields(
        self: SceneInstanceSerializable,
        other: Partial<SceneInstanceSerializable>
    ): SceneInstanceSerializable {
        const connectionTo = other.connectedTo?.length
            ? other.connectedTo
            :   self.connectedTo?.length
                ? self.connectedTo
                : null;
        return new SceneInstanceSerializable(
            other.type_identifier ?? self.type_identifier,
            other.name ?? self.name,
            other.colorTint ?? self.colorTint,
            other.localTransform ?? self.localTransform,
            other.properties ?? self.properties,
            other.representation ?? self.representation,
            other.representationAnalytical ?? self.representationAnalytical,
            other.spatialParentId ?? self.spatialParentId,
            other.electricParentId ?? self.electricParentId,
            other.hierarchySortKey ?? self.hierarchySortKey,
            connectionTo,
            other.props ?? self.props,
        );
    }
}

export enum SceneInstancesVersions{
    None,
    RenameTypeIdentifier,
    AddMaxSlopeProperty,
    AddCostBSToLWDebugWire,
    AddMaxSlopeToTrackerAndFixedTilt,
    AddMinEmbedmentToTrackerAndFixedTilt,
    AddFXMRAndSkid,
    RenameLvWireType,
    AddAssetSelectToMinWiringOnTransformers,
    AddVersionPerType,
    FixInvalidLocalTransform,
    RecenterTracker,
    DeprecateSavingAutoGeneratedRepresentation,
    ModuleEnergyCoeffsFixes,
    RenameEnergyProperties,
    DeprecateSavingAutoGeneratedRepresentation2,
    RemoveFXMRAndSkid,
    RemoveCircuitLvWiringProps,
}


export class SceneInstancesPatchesAfterMigrations<D> {
    constructor(
        readonly postMigrationPatchesData: D,
        readonly createPatchesToApplyFn: (
            perId: SceneInstances, patchesData: D
        ) => [IdBimScene, SceneInstancePatch][],
    ) { };
}


export class SceneInstancesSerializer implements ObjectsPickingSerializer<SceneInstanceSerializable>
{
    constructor(
        private readonly migrationFn: (
            inst: SceneInstanceSerializable,
            version: SceneInstancesVersions,
            id: IdBimScene,
            patchesToApply: DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>
        ) => SceneInstanceSerializable,
        private readonly archetypes: SceneInstancesArchetypes,
        readonly patchesToApply: DefaultMap<SceneInstancesVersions, SceneInstancesPatchesAfterMigrations<any>>
    ) {}

    serialize(objects: [number, SceneInstanceSerializable][]): Uint8Array {

        const logger = new ScopedLogger('instances-ser');
        const builder = new Builder(objects.length * 100);

        const legacyPropsSerializer = new LegacyPropsSerializer(builder);

        const reprS = new InterningReprSerializer(builder);

        const versionPerType = this.archetypes.getVersionPerTypeIdentifier();
        const propsSerializer = new PropsGroupsSerializer({logger, builder, strictGroupsTypes: true});

        const idsOffset = FlatbufCommon.writeInt32Vector(builder, objects.map(t => t[0]));
        const objectsOffset = SceneObjectInstanceCollection.createCollectionVector(
            builder, objects.map((t) => writeObj(builder, t[1], legacyPropsSerializer, reprS, propsSerializer)),
        );

        const objectsVersionsOffset = SceneObjectInstanceCollection.createVersionPerTypeIdentifierVector(
            builder,
            versionPerType.map((v) =>
                serializeTypeIdentifierVersion(builder, v)
            )
        );

        const propsGroupsTypesVersionsOffset = propsSerializer.serializeGroupsTypesVersions();
        const customPropsTypesVerisonsOffset = propsSerializer.propsSerializer.serializeCustomTypesVersions();

        SceneObjectInstanceCollection.startSceneObjectInstanceCollection(builder);
        SceneObjectInstanceCollection.addFormatVersion(builder, SceneInstancesVersions.RemoveCircuitLvWiringProps);
        SceneObjectInstanceCollection.addIds(builder, idsOffset);
        SceneObjectInstanceCollection.addCollection(builder, objectsOffset);
        SceneObjectInstanceCollection.addVersionPerTypeIdentifier(builder, objectsVersionsOffset);
        SceneObjectInstanceCollection.addPropsGroupsVersionsPerTypeIdent(builder, propsGroupsTypesVersionsOffset);
        SceneObjectInstanceCollection.addCustomPropsVersionsPerTypeIdent(builder, customPropsTypesVerisonsOffset);
        const root = SceneObjectInstanceCollection.endSceneObjectInstanceCollection(builder);
        builder.finish(root);
        return builder.asUint8Array().slice();
    }

    deserialize(buffer: Uint8Array, idsToDeserialize: {has(id: number): boolean}): [number, SceneInstanceSerializable][] {

        const rts = SceneObjectInstanceCollection.getRootAsSceneObjectInstanceCollection(new ByteBuffer(buffer));
        const legacyPropsDes = new LegacyPropsDeserializer();
        const reprDes = new InterningReprDeserializer();

        const customPropsTypesVersionsFlat = rts.customPropsVersionsPerTypeIdent();
        const customPropsTypesVersions = customPropsTypesVersionsFlat ? FlatbufCommon.readTypeIdentsVersions(customPropsTypesVersionsFlat) : new Map<string, number>();
        const propsDeserializer = new PropsDeserializer({
            logger: new ScopedLogger('instances-props'),
            customTypesVersions: customPropsTypesVersions
        });

        const groupsTypesVersions = rts.propsGroupsVersionsPerTypeIdent();
        const propsGroupsVersions = groupsTypesVersions ? FlatbufCommon.readTypeIdentsVersions(groupsTypesVersions) : new Map<string, number>();
        const propsGroupsDeserializer = new PropsGroupsDeserializer({
            logger: new ScopedLogger('instances-props'),
            groupsTypesVersions: propsGroupsVersions,
            expectGroupsAraysLegacyBug: false,
            propsDeserializer: propsDeserializer
        });

        const version = rts.formatVersion();
        const versionPerType = new Map<string, number>();
        for (let i = 0; i < rts.versionPerTypeIdentifierLength(); i++) {
            const typeIdentVersion = rts.versionPerTypeIdentifier(i)!;
            versionPerType.set(
                typeIdentVersion.typeIdentifier()!,
                typeIdentVersion.version()
            );
        }

        const res: [number, SceneInstanceSerializable][] = [];
        for (let i = 0, il = rts.idsLength(); i < il; ++i) {
            const id = rts.ids(i)!;
            if (!idsToDeserialize.has(id)) {
                continue;
            }
            const t = rts.collection(i)!;
            const obj = readObj(t, legacyPropsDes, reprDes, propsGroupsDeserializer, this.archetypes);
            const migratedObj = this.migrationFn(obj, version, id, this.patchesToApply);
            this.archetypes.migrate(
                migratedObj,
                versionPerType.get(migratedObj.type_identifier) ?? 0
            );
            res.push([
                id,
                migratedObj
            ]);
        }
        return res;
    }
}
function writeObj(
    builder: Builder,
    g: SceneInstanceSerializable,
    legacyPropsSerializer: LegacyPropsSerializer,
    reprSerializer: InterningReprSerializer,
    propsSerializer: PropsGroupsSerializer,
): number {
    let legacyPropsOffset = 0;
    const properties = g.properties.asArray();
    if (properties) {
        legacyPropsOffset = SceneObjectInstance.createPropertiesVector(
            builder, properties.map(p => legacyPropsSerializer.serializeProp(builder, p))
        );
    }
    const repr = reprSerializer.writeRepresentation(g.representation);
    const reprAnal = reprSerializer.writeRepresentationAnalytical(g.representationAnalytical);
    const typeIdent = g.type_identifier ? legacyPropsSerializer.serializeString(builder, g.type_identifier) : 0;
    const nameIdent = g.name ? legacyPropsSerializer.serializeString(builder, g.name) : 0;

    let connectedToOffset = 0;
    if (g.connectedTo?.length) {
        connectedToOffset = SceneObjectInstance.createConnectedToVector(builder, g.connectedTo as number[]);
    }

    const propsOffset = propsSerializer.serializePropertyGroup(g.props);

    SceneObjectInstance.startSceneObjectInstance(builder);
    SceneObjectInstance.addTypeIdentifier(builder, typeIdent);
    SceneObjectInstance.addName(builder, nameIdent);
    SceneObjectInstance.addColorTint(builder, g.colorTint);
    SceneObjectInstance.addProperties(builder, legacyPropsOffset);

    SceneObjectInstance.addRepresentationType(builder, repr[1]);
    SceneObjectInstance.addRepresentation(builder, repr[0]);
    SceneObjectInstance.addRepresentationAnalyticalType(builder, reprAnal[1]);
    SceneObjectInstance.addRepresentationAnalytical(builder, reprAnal[0]);

    SceneObjectInstance.addSpatialParentId(builder, g.spatialParentId);
    SceneObjectInstance.addElectricParentId(builder, g.electricParentId);
    SceneObjectInstance.addLocalTransform(builder, FlatbufCommon.writeTransform(builder, g.localTransform));

    SceneObjectInstance.addHierarchySortKey(builder, g.hierarchySortKey);
    SceneObjectInstance.addConnectedTo(builder, connectedToOffset);

    SceneObjectInstance.addProps(builder, propsOffset);

    return SceneObjectInstance.endSceneObjectInstance(builder);
}
function readObj(
    pl: SceneObjectInstance,
    legacyPropsDes: LegacyPropsDeserializer,
    reprDes: InterningReprDeserializer,
    propsDeserializer: PropsGroupsDeserializer,
    instancesArchetypes: SceneInstancesArchetypes,
): SceneInstanceSerializable {
    const legacyProps: BimProperty[] = [];
    for (let i = 0; i < pl.propertiesLength(); ++i) {
        const p = pl.properties(i)!;
        const prop = legacyPropsDes.deserProp(p);
        if (prop) {
            legacyProps.push(prop);
        }
    }

    const reprTy = pl.representationType();
    const reprObject = createFlatbufReprObjectFromTypeId(reprTy);

    const reprAnTy = pl.representationAnalyticalType();
    const reprAnObject = createAnalyticalFlatbufReprObjFromTypeId(reprAnTy);

    let connectedTo: IdBimScene[] | null = null;
    if (pl.connectedToLength() > 0) {
        connectedTo = [];
        for (let i = 0; i < pl.connectedToLength(); ++i) {
            connectedTo.push(pl.connectedTo(i) as IdBimScene);
        }
        Object.freeze(connectedTo)
    }

    const propsFlat = pl.props();
    let props = propsFlat ? propsDeserializer.deserializePropsGroup(propsFlat) : null;

    const typeIdent = pl.typeIdentifier();
    if (typeIdent && props == null) {
        const defaultPropsClass = instancesArchetypes.getPropsClassFor(typeIdent);
        if (defaultPropsClass) {
            props = new defaultPropsClass({});
        }
    }

    return new SceneInstanceSerializable(
        typeIdent ?? "",
        pl.name() ?? "",
        pl.colorTint() as RGBAHex,
        FlatbufCommon.readTransform(pl.localTransform()!),
        new PropertiesCollection(legacyProps.length ? legacyProps : null),
        reprObject ? reprDes.readRepresentation(reprTy, pl.representation(reprObject)) : null,
        reprAnObject ? reprDes.readRepresentationAnalytical(reprAnTy, pl.representationAnalytical(reprAnObject)) : null,
        pl.spatialParentId(),
        pl.electricParentId(),
        pl.hierarchySortKey(),
        connectedTo,
        props ?? EmptyPropsStub,
    );
}
