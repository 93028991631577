import type { IdBimGeo, IdBimMaterial, RepresentationBase, SceneInstance } from 'bim-ts';
import { StdInstancedMeshesRepresentation, StdMeshRepresentation } from 'bim-ts';
import { IterUtils, LegacyLogger } from 'engine-utils-ts';

import type { EngineMaterialId } from '../pools/EngineMaterialId';
import {
	InObjFullId, InObjIdType, InObjLocalId,
} from '../scene/EngineSceneIds';
import type { ESOHandle } from '../scene/ESOsCollection';
import { newLodGroupLocalIdent } from '../scene/LodGroups';
import type { SubmeshAllocArgs} from '../scene/Submeshes2';
import {
	LodMask
} from '../scene/Submeshes2';
import { ESO } from './ESO';
import type { ESOsHandlerInputs } from './ESOsHandlerBase';
import { ESOsHandlerBase } from './ESOsHandlerBase';
import type { SubmeshesCreationOutput, SubmeshesCreationResources} from './ESSO';
import {
	ESSO
} from './ESSO';
import { SharedSelectHighlightJobUpdater } from './ESSO_HighlightUpdaters';
import { SharedStdRenderJobUpdater } from './SubmeshesStd';
import { ESSO_TextAnnotation } from './ESSO_TextAnnotation';
import { Quaternion, Transform, Vec3One } from 'math-ts';
import type { StdStaticAnnotationsCalculator } from '../annotations/StdStaticAnnotationsCalculator';
import { ESSO_TextAnnotationFrame } from './ESSO_TextAnnotationFrame';
import { Matrix4 } from 'math-ts';

export class ESO_StdStatic extends ESO {

	constructor(
		sceneInstanceRef: Readonly<SceneInstance>,
	) {
		super(sceneInstanceRef);
	}
}

interface SubmeshInstancesDescription {
	geometryId: IdBimGeo,
	materialId: IdBimMaterial,
	transforms: Matrix4[],
}

export interface ESSO_StdStaticRepr {
	std: SubmeshInstancesDescription[],
	lod: SubmeshInstancesDescription[]|null,
	enableLodAfterDetailSize: number|null,
}

export class ESSO_StdSelf extends ESSO<ESSO_StdStaticRepr> {

	createSubmeshes(resoures: SubmeshesCreationResources, output: SubmeshesCreationOutput): void {
		
		// do we have lods
		const lodRepr = this.repr.lod;
		const lodGroupLocalIdent = newLodGroupLocalIdent(0, this.repr?.enableLodAfterDetailSize ?? 0.001);
		const lodMask = lodRepr ? LodMask.Lod0 : LodMask.All;
		// const instancingGroups = SubmeshesInstancingGroup.splitStdSubmeshesIntoGroups(this.repr.std);

		for(const instancingGroup of this.repr.std) {
			const engineGeoId = resoures.geometries.mapBimIdToEngineId(instancingGroup.geometryId);
			if (engineGeoId === undefined) {
				resoures.logger.batchedError('engine geo is absent', instancingGroup.geometryId);
				continue;
			}

			const materialId = resoures.materials.mapBimIdToEngineId(instancingGroup.materialId);
			if (materialId === undefined) {
				resoures.logger.batchedError('engine material is absent', instancingGroup.materialId);
				continue;
			}

			const allocArgs: SubmeshAllocArgs = {
				lodMask: lodMask,
				lodGroupLocalIdent,
				descr: {
					geoId: engineGeoId,
					materialId: materialId as unknown as EngineMaterialId,
					localTransforms: instancingGroup.transforms,
					mainRenderJobUpdater: SharedStdRenderJobUpdater,
					overlayRenderJobUpdater: SharedSelectHighlightJobUpdater,
				},
				id: this.id,
				subObjectRef: this,
			};
			output.submeshes.push(allocArgs);
		}

		if (lodRepr) {
			for (const instancingGroup of lodRepr) {
				const engineGeoId = resoures.geometries.mapBimIdToEngineId(instancingGroup.geometryId);
				if (engineGeoId === undefined) {
					resoures.logger.batchedError('lod engine geo is absent', instancingGroup.geometryId);
					continue;
				}
	
				const materialId = resoures.materials.mapBimIdToEngineId(instancingGroup.materialId);
				if (materialId === undefined) {
					resoures.logger.batchedError('lod engine material is absent', instancingGroup.materialId);
					continue;
				}
				const allocArgs: SubmeshAllocArgs = {
					lodMask: LodMask.Lod1 | LodMask.Lod2,
					lodGroupLocalIdent,
					descr: {
						geoId: engineGeoId,
						materialId: materialId as unknown as EngineMaterialId,
						localTransforms: instancingGroup.transforms,
						mainRenderJobUpdater: SharedStdRenderJobUpdater,
						overlayRenderJobUpdater: SharedSelectHighlightJobUpdater
					},
					id: this.id,
					subObjectRef: this,
				}
				output.submeshes.push(allocArgs);
			}
		}
	}

	isRepresentationTheSame(repr: ESSO_StdStaticRepr): boolean {
		return this.repr.lod == repr.lod && this.repr.std == repr.std;
	}
}


export class ESO_StdStaticHandler extends ESOsHandlerBase<ESO_StdStatic> {
	constructor(
		identifier: string,
		args: ESOsHandlerInputs,
		annotationsCalculator: StdStaticAnnotationsCalculator
	) {
		super(identifier, args, annotationsCalculator);
	}

	tryCreateESO(instance: SceneInstance): ESO_StdStatic | undefined {
		if (
			instance.representation instanceof StdMeshRepresentation || 
			instance.representation instanceof StdInstancedMeshesRepresentation
		) {
			return new ESO_StdStatic(instance);
        }
		return undefined;
	}

	esosTypesToHandle() {
		return [ESO_StdStatic];
	}

	createSubObjectsFor(objectsToRealloc: [ESOHandle, ESO_StdStatic][]): Iterable<[ESOHandle, ESSO<any>[]]> {
		return IterUtils.mapIter(
			objectsToRealloc,
			([handle, eso]) => {
				const repr = eso.bimRepresentation();
				const instancedGroups = SubmeshesInstancingGroup.convertStdReprToInstancedGroupsRepr(repr);
				if (!instancedGroups){
					this.logger.batchedError('unsupported repr', repr);
					return [handle, []];
				}
				const subobjs: ESSO<any>[] = [new ESSO_StdSelf(
					eso,
					InObjFullId.new(handle, SelfId_0),
					instancedGroups,
					null
				)];

				const annotationRepr = this.getAnnotation(handle, eso);
				if (annotationRepr) {
					const position = annotationRepr.position.clone().sub(eso.worldMatrix.extractPosition());
					const rotation = new Quaternion().setFromRotationMatrix(eso.worldMatrix).invert();
					position.applyQuaternion(rotation);
					
					const text = new ESSO_TextAnnotation(
						eso,
						InObjFullId.new(handle, SelfId_1),
						annotationRepr.textRepr,
						new Matrix4().compose(position, rotation, Vec3One),
					);
					const frame = new ESSO_TextAnnotationFrame(
						eso,
						InObjFullId.new(handle, SelfId_2),
						annotationRepr.textRepr.blockOptions,
						new Matrix4().compose(position, rotation, Vec3One)
					);
					
					subobjs.push(text, frame);
				}

				return [handle, subobjs]
			}
		);
	}

}


export function addSubmeshToInstancingGroups(groups: SubmeshesInstancingGroup[], geoId: IdBimGeo, matId: IdBimMaterial, transform: Transform | Matrix4 | null): void {
	let group: SubmeshesInstancingGroup | undefined = undefined;
	for (const existingGroup of groups) {
		if (existingGroup.materialId === matId && existingGroup.geometryId == geoId) {
			group = existingGroup;
		}
	}
	if (group === undefined) {
		group = new SubmeshesInstancingGroup(matId, geoId);
		groups.push(group);
	}

	let matrix: Matrix4 | null = null;

	if (transform instanceof Transform) {
		matrix = transform.toMatrix4(new Matrix4());
	} else if (transform instanceof Matrix4) {
		matrix = transform;
	} else {
		matrix = null;
	}

	group.transforms.push(matrix);
}

export class SubmeshesInstancingGroup {
	constructor(
        readonly materialId: IdBimMaterial,
		readonly geometryId: IdBimGeo,
        readonly transforms: (Matrix4 | null)[] = [],
    ) {
    }

	static convertStdReprToInstancedGroupsRepr(r: StdMeshRepresentation|StdInstancedMeshesRepresentation|RepresentationBase|null): ESSO_StdStaticRepr|null {
		let esoRerp: ESSO_StdStaticRepr;
		if (r instanceof StdMeshRepresentation) {
			let std: SubmeshInstancesDescription[] = [];
			let lod: SubmeshInstancesDescription[]|null = null;
			for (const submesh of r.submeshes) {
				addSubmeshToInstancingGroups(std, submesh.geometryId, submesh.materialId, submesh.transform);
			}
			if (r.lod1) {
				lod = [];
				for (const submesh of r.lod1.submeshes) {
					addSubmeshToInstancingGroups(lod, submesh.geometryId, submesh.materialId, submesh.transform);
				}
			}
			esoRerp = {
				std,
				lod,
				enableLodAfterDetailSize: r.lod1?.enableAfterDetailSize ?? null,
			};
		} else if (r instanceof StdInstancedMeshesRepresentation ) {
			let std: SubmeshInstancesDescription[] = r.groups;
			let lod: SubmeshInstancesDescription[]|null = null;
			if (r.lod1) {
				lod = [];
				for (const submesh of r.lod1.submeshes) {
					addSubmeshToInstancingGroups(lod, submesh.geometryId, submesh.materialId, submesh.transform);
				}
			}
			esoRerp = {
				std,
				lod,
				enableLodAfterDetailSize: r.lod1?.enableAfterDetailSize ?? null,
			};
		} else {
			LegacyLogger.deferredError(`unexpected repr type ${r}`, r);
			return null;
		}
		return esoRerp;
	}
}

const SelfId_0 =  InObjLocalId.new(InObjIdType.ObjSelf, 0);
const SelfId_1=  InObjLocalId.new(InObjIdType.ObjSelf, 1);
const SelfId_2 =  InObjLocalId.new(InObjIdType.ObjSelf, 2);
