export interface Point {
    x: number;
    y: number;
}


function is_ccw(a: Point, b: Point, c: Point): boolean {
    return (b.x - a.x) * (c.y - b.y) - (c.x - b.x) * (b.y - a.y) > 0;
}


function vclip(x: number, polygon: Point[][]): [number, number][] {
    const values: number[] = [];
    for (const contour of polygon) {
        const n = contour.length;
        if (n < 3) { continue; }
        for (let i = 0; i < n; ++i) {
            const a = contour.at(i - 1)!, b = contour[i];
            if ((a.x < x && x < b.x) || (b.x < x && x < a.x)) {
                values.push((b.y * (x - a.x) + a.y * (b.x - x)) / (b.x - a.x));
            } else if (a.x == x) {
                const c = contour.at(i - 2)!;
                if ((c.x < x && x < b.x) || (b.x < x && x < c.x) ||
                    ((x == b.x || x == c.x) && is_ccw(c, a, b))) {
                    values.push(a.y);
                }
            }
        }
    }
    values.sort((a, b) => a - b);
    const result: [number, number][] = []
    for (let i = 0; i < values.length; i += 2) {
        result.push([values[i], values[i + 1]]);
    }
    return result;
}


function find_x_min(polygon: Point[][]): number {
    let result = Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.min(result, point.x);
        }
    }
    return result;
}


function find_x_max(polygon: Point[][]): number {
    let result = -Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.max(result, point.x);
        }
    }
    return result;
}


interface Domain {
    offset: number;
    step: number;
    indices: number[];
    pairs: [number, number][];
}


function is_in(
    i: number,
    y_min: number,
    y_max: number,
    domain: Domain
): boolean {
    i -= domain.offset;
    if (i < 0 || i >= domain.indices.length) { return false; }
    const beg = domain.indices[i];
    const end = i + 1 < domain.indices.length ?
        domain.indices[i + 1] : domain.pairs.length;
    for (let j = beg; j < end; ++j) {
        const pair = domain.pairs[j];
        if (pair[0] <= y_min && y_max <= pair[1]) {
            return true;
        }
    }
    return false;
}


function make_domain(
    contours: Array<Array<Point>>,
    step: number,
    width: number
): Domain {
    const radius = width / 2;
    const offset = Math.ceil((find_x_min(contours) + radius) / step);
    const n = Math.floor((find_x_max(contours) - radius) / step) - offset + 1;
    const indices: number[] = [];
    const pairs: [number, number][] = [];
    let x = offset * step;
    for (let _ = 0; _ < n; _++) {
        indices.push(pairs.length);
        const fst = vclip(x - radius, contours);
        const snd = vclip(x + radius, contours);
        let i = 0;
        let j = 0;
        while (i < fst.length && j < snd.length) {
            const lhs = Math.max(fst[i][0], snd[j][0]);
            const rhs = Math.min(fst[i][1], snd[j][1]);
            if (lhs < rhs) { pairs.push([lhs, rhs]); }
            if (fst[i][1] > snd[j][1]) {
                ++j;
            } else {
                ++i;
            }
        }
        x += step;
    }
    return { offset, step, indices, pairs };
}


function hclip(y: number, polygon: Point[][]): [number, number][] {
    const values: number[] = [];
    for (const contour of polygon) {
        const n = contour.length;
        if (n < 3) { continue; }
        for (let i = 0; i < n; ++i) {
            const a = contour.at(i - 1)!, b = contour[i];
            if ((a.y < y && y < b.y) || (b.y < y && y < a.y)) {
                values.push((b.x * (y - a.y) + a.x * (b.y - y)) / (b.y - a.y));
            } else if (a.y == y) {
                const c = contour.at(i - 2)!;
                if ((c.y < y && y < b.y) || (b.y < y && y < c.y) ||
                    ((y == b.y || y == c.y) && is_ccw(c, a, b))) {
                    values.push(a.x);
                }
            }
        }
    }
    values.sort((a, b) => a - b);
    const result: [number, number][] = []
    for (let i = 0; i < values.length; i += 2) {
        result.push([values[i], values[i + 1]]);
    }
    return result;
}


function find_y_min(polygon: Point[][]): number {
    let result = Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.min(result, point.y);
        }
    }
    return result;
}


function find_y_max(polygon: Point[][]): number {
    let result = -Infinity;
    for (const contour of polygon) {
        for (const point of contour) {
            result = Math.max(result, point.y);
        }
    }
    return result;
}


function group_pairs(
    pairs: [number, number][],
    gap: number
): [number, number][][] {
    const n = pairs.length;
    if (n == 0) { return []; }
    const result: [number, number][][] = [];
    let i = 0;
    for (let j = 1; j < n; ++j) {
        if (pairs[j - 1][1] + gap < pairs[j][0]) {
            result.push(pairs.slice(i, j));
            i = j;
        }
    }
    result.push(pairs.slice(i));
    return result
}


function offset_pairs(
    pairs: [number, number][],
    offset: number
): [number, number][] {
    const n = pairs.length;
    if (n === 0) { return []; }
    const result: [number, number][] = [];
    const gap = 2 * offset;
    let i = 0;
    for (let j = 1; j < n; ++j) {
        if (pairs[j - 1][1] + gap < pairs[j][0]) {
            result.push([pairs[i][0] - offset, pairs[j - 1][1] + offset]);
            i = j;
        }
    }
    result.push([pairs[i][0] - offset, pairs.at(-1)![1] + offset]);
    return result;
}


export interface Sample {
    v: number; // value
    o: number; // offset
    h: number; // height
}


function select_sample(
    i: number,
    y: number,
    samples: Sample[],
    domain: Domain
): number | null {
    for (let j = 0; j < samples.length; ++j) {
        const sample = samples[j];
        const y_min = y + sample.o
        const y_max = y_min + sample.h
        if (is_in(i, y_min, y_max, domain)) {
            return j;
        }
    }
    return null;
}


interface Item {
    y: number;
    y_min: number;
    y_max: number;
    score: number;
    lower_rank: number;
    upper_rank: number;
}


function make_items(
    pairs: [number, number][],
    y: number,
    offset: number,
    samples: Sample[],
    max_rank: number,
    find_score: (value: number) => number,
    domain: Domain
): Item[] {
    const step = Math.max(...samples.map((sample) => sample.o + sample.h));
    const nc = pairs.length;
    const values = Array.from({ length: 2 * max_rank },
        () => Array<number>(nc).fill(0));
    const limits: number[] = Array(2 * max_rank).fill(y);
    for (const [c, [x_min, x_max]] of pairs.entries()) {
        const beg = Math.ceil(x_min / domain.step);
        const end = Math.floor(x_max / domain.step) + 1;
        for (let i = beg; i < end; ++i) {
            for (let j = 0; j < max_rank; ++j) {
                let y_min = y - offset - (j + 1) * step;
                let index = select_sample(i, y_min, samples, domain);
                if (index != null) {
                    const sample = samples[index];
                    values[j][c] += sample.v;
                    limits[j] = Math.min(limits[j], y_min + sample.o);
                }
                y_min = y + offset + j * step;
                index = select_sample(i, y_min, samples, domain);
                if (index != null) {
                    const sample = samples[index];
                    values[j + max_rank][c] += sample.v;
                    limits[j + max_rank] = Math.max(limits[j + max_rank],
                        y_min + sample.o + sample.h);
                }
            }
        }
    }
    const fst = [{ value: Array(nc).fill(0), y_min: y - offset, rank: 0 }];
    let total = Array<number>(nc).fill(0);
    for (let i = 0; i < max_rank; ++i) {
        if (values[i].reduce((acc, curr) => acc + curr, 0) > 0) {
            for (let c = 0; c < nc; ++c) {
                total[c] += values[i][c];
            }
            fst.push({ value: [...total], y_min: limits[i], rank: i + 1 });
        }
    }
    const snd = [{ value: Array(nc).fill(0), y_max: y + offset, rank: 0 }];
    total = Array<number>(nc).fill(0);
    for (let i = 0; i < max_rank; ++i) {
        if (values[i + max_rank].reduce((acc, curr) => acc + curr, 0) > 0) {
            for (let c = 0; c < nc; ++c) {
                total[c] += values[i + max_rank][c];
            }
            snd.push({
                value: [...total], y_max: limits[i + max_rank],
                rank: i + 1
            });
        }
    }
    const result: Item[] = [];
    for (const a of fst) {
        for (const b of snd) {
            let score = 0;
            for (let c = 0; c < nc; ++c) {
                score += find_score(a.value[c] + b.value[c]);
            }
            if (score) {
                result.push({
                    y, y_min: a.y_min, y_max: b.y_max, score,
                    lower_rank: a.rank, upper_rank: b.rank
                });
            }
        }
    }
    return result;
}



function bisect_left<T>(
    array: T[],
    value: number,
    key: (arg: T) => number
): number {
    let lo = 0, hi = array.length;
    while (lo < hi) {
        const mid = lo + ((hi - lo) >>> 1);
        if (key(array[mid]) < value) {
            lo = mid + 1;
        } else {
            hi = mid;
        }
    }
    return lo;
}


function select_disjoint_items(
    items: { y_min: number, y_max: number, score: number }[],
    gap: number
): number[] {
    const n = items.length;
    if (n == 0) { return []; }
    const indices = [...Array(n).keys()];
    indices.sort((a, b) => items[a].y_min - items[b].y_min);
    interface Record {
        index: number;
        next: number;
        score: number;
    }
    let index = indices[n - 1];
    const records: Record[] = [{
        index, next: n, score: items[index].score
    }];
    for (let i = n - 2; i >= 0; i--) {
        let record = records[records.length - 1];
        index = indices[i];
        let score = items[index].score;
        const j = bisect_left(indices, items[index].y_max + gap,
            (i) => items[i].y_min);
        if (j < n) { score += records[n - 1 - j].score; }
        if (score > record.score) { record = { index, next: j, score }; }
        records.push(record)
    }
    records.reverse();
    const result: number[] = [];
    let i = 0;
    while (i < n) {
        result.push(records[i].index);
        i = records[i].next;
    }
    return result;
}


export interface Tracker {
    x: number;
    y: number;
    s: number; // sample's index
    r: number; // tracker's row
}


function make_trackers(
    x_min: number,
    x_max: number,
    y: number,
    offset: number,
    samples: Sample[],
    lower_rank: number,
    upper_rank: number,
    domain: Domain
): Tracker[] {
    const step = Math.max(...samples.map((sample) => sample.o + sample.h));
    const beg = Math.ceil(x_min / domain.step);
    const end = Math.floor(x_max / domain.step) + 1;
    const result: Tracker[] = [];
    for (let i = beg; i < end; ++i) {
        const x = i * domain.step;
        for (let j = 0; j < lower_rank; ++j) {
            const y_min = y - offset - (j + 1) * step;
            const index = select_sample(i, y_min, samples, domain);
            if (index != null) {
                result.push({ x, y: y_min, s: index, r: -(j + 1) });
            }
        }
        for (let j = 0; j < upper_rank; ++j) {
            const y_min = y + offset + j * step;
            const index = select_sample(i, y_min, samples, domain);
            if (index != null) {
                result.push({ x, y: y_min, s: index, r: j + 1 });
            }
        }
    }
    return result;
}


export interface Layout {
    trackers: Tracker[];
    lines: [Point, Point][];
}


function fill(
    contours: Point[][],
    samples: Sample[],
    max_rank: number,
    tracker_width: number,
    row_to_row: number,
    row_to_line: number,
    offset: number,
    gap: number,
    step: number,
    min_block_value: number,
    max_block_value: number
): Layout[] {
    const y_min = find_y_min(contours);
    const size = find_y_max(contours) - y_min;
    const n = Math.floor(size / step);
    let y = y_min + (size - (n - 1) * step) / 2;
    const domain = make_domain(contours, row_to_row, tracker_width);
    const find_score = (value: number) => Math.min(
        Math.floor(value / min_block_value) * max_block_value, value);
    const items: Item[] = [];
    for (let i = 0; i < n; ++i) {
        const pairs = offset_pairs(hclip(y, contours), offset);
        items.push(...make_items(pairs, y, row_to_line, samples, max_rank,
            find_score, domain));
        y += step;
    }
    const indices = select_disjoint_items(items, gap);
    const result: Layout[] = [];
    for (const index of indices) {
        const item = items[index];
        const pair_groups = group_pairs(hclip(item.y, contours), 2 * offset);
        for (const pairs of pair_groups) {
            const trackers = make_trackers(pairs[0][0] - offset,
                pairs[pairs.length - 1][1] + offset, item.y,
                row_to_line, samples, item.lower_rank, item.upper_rank,
                domain);
            const lines: [Point, Point][] = pairs.map((pair) => {
                return [{ x: pair[0], y: item.y }, { x: pair[1], y: item.y }];
            });
            result.push({ trackers, lines });
        }
    }
    return result;
}


function shift_and_fill(
    contours: Point[][],
    samples: Sample[],
    max_rank: number,
    tracker_width: number,
    row_to_row: number,
    row_to_line: number,
    offset: number,
    gap: number,
    step: number,
    tan: number,
    min_block_value: number,
    max_block_value: number
): Layout[] {
    if (tan == 0.0) {
        return fill(contours, samples, max_rank, tracker_width, row_to_row,
            row_to_line, offset, gap, step, min_block_value, max_block_value);
    }

    function shift<T extends Point>(src: T, k: number): T {
        return { ...src, y: src.y + k * src.x };
    }

    const sec = Math.hypot(1, tan);
    const layouts = fill(
        contours.map((contour) => contour.map((point) => shift(point, -tan))),
        samples, max_rank, tracker_width, row_to_row, row_to_line * sec,
        offset, gap * sec, step * sec, min_block_value, max_block_value);
    return layouts.map((layout) => {
        return {
            trackers: layout.trackers.map((tracker) => shift(tracker, tan)),
            lines: layout.lines.map((line) => [shift(line[0], tan),
            shift(line[1], tan)])
        };
    });
}


export interface Input {
    contours: Point[][];
    samples: Sample[];
    max_rank: number;
    tracker_width: number;
    row_to_row: number;
    row_to_line: number;
    offset: number;
    gap: number;
    step: number;
    tan?: number;
    min_block_value: number;
    max_block_value: number;
}


export function solve({
    contours,
    samples,
    max_rank,
    tracker_width,
    row_to_row,
    row_to_line,
    offset,
    gap,
    step,
    tan,
    min_block_value,
    max_block_value
}: Input): Layout[] {
    const indices = [...Array(samples.length).keys()];
    indices.sort((a, b) => samples[b].v - samples[a].v);
    samples = indices.map((i) => samples[i]);
    let result: Layout[] = [];
    if (tan === undefined) {
        let record: number = 0;
        const n = 32;
        for (let i = 0; i <= n; ++i) {
            const tan = Math.tan(Math.PI * (2 * i - n) / (4 * n));
            const layouts = shift_and_fill(contours, samples, max_rank, 
                tracker_width, row_to_row, row_to_line, offset, gap, step, tan,
                min_block_value, max_block_value);
            let total_value = 0;
            for (const layout of layouts) {
                for (const tracker of layout.trackers) {
                    total_value += samples[tracker.s].v;
                }
            }
            if (total_value > record) {
                result = layouts;
                record = total_value;
            }
        }
    } else {
        result = shift_and_fill(contours, samples, max_rank, tracker_width,
            row_to_row, row_to_line, offset, gap, step, tan,
            min_block_value, max_block_value);
    }
    return result.map((layout) => {
        const trackers = layout.trackers.map((tracker) => {
            return { ...tracker, s: indices[tracker.s] };
        });
        return { ...layout, trackers };
    });
}


function group_trackers_in_row(
    trackers: { x: number }[],
    indices: number[],
    max_group_length: number,
    max_group_size: number
): number[][] {
    const n = indices.length;
    const penalties: number[] = [0];
    const ends: number[] = [n];
    for (let beg = n - 1; beg >= 0; beg--) {
        const x_max = trackers[indices[beg]].x + max_group_length;
        let penalty = (max_group_size - 1) ** 2 +
            penalties[penalties.length - 1];
        let end = beg + 1;
        const max_size = Math.min(max_group_size, penalties.length);
        for (let size = 2; size <= max_size; size++) {
            if (trackers[indices[beg + size - 1]].x > x_max) {
                break;
            }
            const value = (max_group_size - size) ** 2 +
                penalties[penalties.length - size];
            if (value < penalty) {
                end = beg + size;
                penalty = value;
            }
        }
        penalties.push(penalty);
        ends.push(end);
    }
    ends.reverse();
    const result: number[][] = [];
    let beg = 0;
    while (beg < n) {
        const end = ends[beg];
        result.push(indices.slice(beg, end));
        beg = end;
    }
    return result;
}


export function group_trackers(
    trackers: { x: number, r: number }[],
    max_group_length: number,
    max_group_size: number
): number[][] {
    const indices = Array.from({ length: trackers.length }, (_, i) => i);
    indices.sort((a, b) => {
        const dr = trackers[a].r - trackers[b].r;
        return dr !== 0 ? dr : trackers[a].x - trackers[b].x;
    });
    const result: number[][] = [];
    let beg = 0;
    for (let i = 0; i < indices.length; i++) {
        if (trackers[indices[beg]].r !== trackers[indices[i]].r) {
            result.push(...group_trackers_in_row(trackers,
                indices.slice(beg, i), max_group_length, max_group_size));
            beg = i;
        }
    }
    result.push(...group_trackers_in_row(trackers, indices.slice(beg),
        max_group_length, max_group_size));
    return result;
}


function signed_area(a: Point, b: Point, c: Point): number {
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
}

function overlapped(a: number, b: number, c: number, d: number): boolean {
    if (a > b) { [a, b] = [b, a]; }
    if (c > d) { [c, d] = [d, c]; }
    return Math.max(a, c) <= Math.min(b, d);
}


function crossed(a: Point, b: Point, c: Point, d: Point): boolean {
    return overlapped(a.x, b.x, c.x, d.x) &&
        overlapped(a.y, b.y, c.y, d.y) &&
        signed_area(a, b, c) * signed_area(a, b, d) <= 0 &&
        signed_area(c, d, a) * signed_area(c, d, b) <= 0;
}


function inner(p: Point, contour: Point[]): boolean {
    let cntr = 0, j = contour.length - 1;
    for (let i = 0; i < contour.length; ++i) {
        const a = contour[j], b = contour[i];
        if ((((b.y > p.y) != (a.y > p.y)) && (p.x < (a.x - b.x) * (p.y - b.y) /
            (a.y - b.y) + b.x))) {
            ++cntr;
        }
        j = i;
    }
    return cntr % 2 == 1;
}


function test(
    contour: Point[],
    x_min: number,
    y_min: number,
    x_max: number,
    y_max: number
): boolean {
    const box: Point[] = [{ x: x_min, y: y_min }, { x: x_max, y: y_min },
    { x: x_max, y: y_max }, { x: x_min, y: y_max }];
    for (let i2 = 0, i1 = box.length - 1; i2 < box.length; i2++) {
        const a = box[i1];
        const b = box[i2];
        for (let j2 = 0, j1 = contour.length - 1; j2 < contour.length; ++j2) {
            const c = contour[j1];
            const d = contour[j2];
            j1 = j2;
            if (crossed(a, b, c, d)) { return true; }
        }
        i1 = i2;
    }
    for (let p of box) {
        if (inner(p, contour)) { return true; }
    }
    for (let p of contour) {
        if (inner(p, box)) { return true; }
    }
    return false;
}


export function trim_tracker(
    contour: Point[],
    tracker: Tracker,
    width: number,
    samples: Sample[],
    eps: number = 1.0e-3
): Tracker | undefined {
    const x = tracker.x;
    const x_min = x - width / 2;
    const x_max = x + width / 2;
    const y = tracker.y;
    const y_min = y + samples[tracker.s].o;
    const y_max = y_min + samples[tracker.s].h;
    let index: number | undefined = undefined;
    let record = 0;
    for (let i = 0; i < samples.length; i++) {
        const sample = samples[i];
        if (record > sample.v) { continue; }
        const y1 = y + sample.o;
        if (y1 < y_min - eps * sample.h) { continue; }
        const y2 = y1 + sample.h;
        if (y2 > y_max + eps * sample.h) { continue; }
        if (test(contour, x_min, y1, x_max, y2)) { continue; }
        index = i;
        record = sample.v;
    }
    if (index !== undefined) { return { ...tracker, s: index }; }
    return undefined;
}