import {
    AttrFlag,
    BlockTypeFlag,
    HorizontalJustification,
    VerticalJustification,
} from "./DxfEnums";
import { Vector3, Vector2, Aabb2 } from "math-ts";
import {
    annotationLayer,
    DxfImageData,
    DxfPolylineData,
    Table,
} from "./DxfDataService";
import { DxfFileManager } from "./DxfFileManager";
import { getPileBlockName, heightInProjectUnits } from "./DxfService";
import { DXFNode } from "./DxfFileStructure";
import {
    Dxf3DFace,
    DxfArc,
    DxfAttribute,
    DxfAttributeDefinition,
    DxfBlock,
    DxfBlockRecord,
    DxfCircle,
    DxfCommonObject,
    DxfDictionary,
    DxfHatch,
    DxfImage,
    DxfImageDefinition,
    DxfImageDefReactor,
    DxfInsert,
    DxfLayout,
    DxfLine,
    DxfLwPolyline,
    DxfSeqEnd,
    DxfSortentsTable,
    DxfSpline,
    DxfText,
    DxfViewport,
    DxfVPort,
} from "./DxfEntities";
import {
    BoundaryPathData,
    CircleBoundary,
    DxfMargin,
    GradientData,
    PolylineBoundary,
} from "./DxfEnityProps";
import { DxfProperty } from "./DxfProperties";

type PileType =
    | "SAP"
    | "SAPD"
    | "SAE"
    | "SAED"
    | "SC"
    | "SCD"
    | "SMP"
    | "HAP"
    | "HAPD"
    | "HAE"
    | "HAED"
    | "HC"
    | "HCD"
    | "HMP";
export interface dxfTextData {
    text: string;
    position: Vector2;
    rotation?: number;
    height?: number;
    horizontalJustification?: HorizontalJustification;
    verticalJustification?: VerticalJustification;
    color?: number;
    layer?: string;
    paperSpace?: boolean;
}

export interface splineData {
    knots: Vector2[];
    layer?: string;
    paperSpace?: boolean;
}

export interface attributeInsertData {
    tag: string;
    text: string;
    position: Vector2 | Vector3;
    layer?: string;
    rotation?: number;
    heigh?: number;
    horizontalJustification?: HorizontalJustification;
    verticalJustification?: VerticalJustification;
    attrFlag?: AttrFlag;
}

export interface blockInsertData {
    blockName: string | DXFNode;
    insertPoint: Vector3 | Vector2;
    rotation?: number;
    layer?: string;
    color?: number;
}

export class DxfHandle {
    private static _handle: number = 30;

    static handle():string{
        this._handle++;
        return this._handle.toString(16)
    }

    static getHandle():DxfProperty {
        this._handle++;
        return new DxfProperty(5, this._handle.toString(16));
    }
    static getDimStyleHandle():DxfProperty {
        this._handle++;
        return new DxfProperty(105, this._handle.toString(16));
    }
}

export class DXFNodeFactory {
    private static getEntitySectionOrBlock(
        manager: DxfFileManager,
        blockName?: string
    ) {
        let parent: DXFNode | undefined = undefined;
        if (blockName !== undefined) {
            const block = manager.getBlock(blockName);
            if (block !== undefined) {
                parent = block;
            } else {
                parent = manager.getSection("ENTITIES");
                console.log(
                    `There is no block named ${blockName}! Entity will be add to ENTITIES section!`
                );
            }
        } else {
            parent = manager.getSection("ENTITIES");
        }
        return parent;
    }

    public static createViewPort(
        manager: DxfFileManager,
        layoutBlockNode: DXFNode,
        size: Vector2,
        center: Vector2
    ) {
        const layoutBlock = layoutBlockNode.dxfObject as DxfBlock | undefined;
        if (!layoutBlock) {
            throw new Error("Layout is not correct");
        }

        const ownerName = layoutBlock.blockName;
        const paperSpaceBlockDef = manager.getBlockRecord(ownerName)
            ?.dxfObject as DxfBlockRecord | undefined;
        if (!paperSpaceBlockDef) {
            throw new Error(`Block reference ${ownerName} is not correct!`);
        }
        const paperSpaceBlockDefHandle = paperSpaceBlockDef.handle;
        const viewportHandle = DxfHandle.handle();
        const viewportDictHandle = DxfHandle.handle();

        const viewPortProps: Partial<DxfViewport> = {
            handle: viewportHandle,
            handleToOwner: paperSpaceBlockDefHandle,
            centerPoint: new Vector3(center.x, center.y),
            width: Math.abs(size.x),
            height: Math.abs(size.y),
            viewCenter: center,
            viewHeight: Math.abs(size.x),
        };

        const dxfViewPort = new DxfViewport(viewPortProps);
        dxfViewPort.addXDictionary(viewportDictHandle);

        if (ownerName === "*Paper_Space") {
            layoutBlockNode = manager.getSection("ENTITIES");
        }

        manager.addNode({ parent: layoutBlockNode, dxfObject: dxfViewPort });

        const dictProps: Partial<DxfDictionary> = {
            handle: viewportDictHandle,
            ownerHandle: viewportHandle,
            duplicateRecordCloningFlag: 1,
        };
        const dict = new DxfDictionary(dictProps);

        manager.addDictionary(dict);
    }

    public static createPaperLayout(
        manager: DxfFileManager,
        name: string,
        sheetSize?: Vector2,
        limits?: Aabb2,
        number?: number
    ) {
        function calculateScaleToFitObject(objectSize: Vector2): number {
            const scaleX = getRealSheetSizeX(shitViewOffset) / objectSize.x;
            const scaleY = getRealSheetSizeY(shitViewOffset) / objectSize.y;
            const scale = Math.min(scaleX, scaleY);

            return scale;
        }

        function calculateImageOrigin(
            limits: Aabb2,
            scale: number = 1
        ): Vector2 {
            const sheetCenter: Vector2 = new Vector2(
                getRealSheetSizeX() / 2,
                getRealSheetSizeY() / 2
            );
            const objectCenter = limits
                .getCenter()
                .clone()
                .multiplyScalar(scale);
            const shift = sheetCenter.clone().sub(objectCenter);
            shift.divideScalar(scale);
            return shift;
        }

        function getRealSheetSizeX(offset: number = 0) {
            return (
                sheetSizeX -
                unprintableMargin.left -
                unprintableMargin.right -
                2 * offset
            );
        }
        function getRealSheetSizeY(offset: number = 0) {
            return (
                sheetSizeY -
                unprintableMargin.top -
                unprintableMargin.bottom -
                2 * offset
            );
        }

        const shitViewOffset = 20;
        const sheetSizeX = sheetSize ? sheetSize.x : 594;
        const sheetSizeY = sheetSize ? sheetSize.y : 420;
        const plotPaperSize = new Vector2(sheetSizeX, sheetSizeY);

        const unprintableMargin: DxfMargin = {
            left: 7.5,
            bottom: 20,
            right: 7.5,
            top: 20,
        };

        const paperspaces = manager
            .getBlocksNames()
            .filter((name) => name.startsWith("*Paper_Space"));

        const blockName = `*Paper_Space${paperspaces.length}`;

        const block = manager.addBlock(blockName, BlockTypeFlag.None);
        const blockRecord = manager.getBlockRecord(blockName)?.dxfObject as
            | DxfBlockRecord
            | undefined;
        if (!blockRecord) {
            throw new Error(`Can not find block record ${blockName}`);
        }
        const blockRecordHandle = blockRecord.handle;

        const objects = manager.getSection("OBJECTS");

        const layoutDict = manager.getDictionaryByName("ACAD_LAYOUT")
            ?.dxfObject as DxfDictionary;

        if (!layoutDict) {
            console.log(`Layout dictionary didn't find`);
            return block;
        }

        const dictHandle = layoutDict.handle;

        const layoutHandle = DxfHandle.handle();
        const layoutProps: Partial<DxfLayout> = {
            handle: layoutHandle,
            printerName: "none_device",
            paperSize: "ISO_A2_(594.00_x_420.00_MM)",
            plotViewName: " ",
            unprintableMargin,
            layoutName: name,
            ownerHandle: dictHandle,
            associatedPaperSpaceBlockHandle: blockRecordHandle,
            lastActiveViewportHandle: "0",
            tabOrder: number ?? 0,
            plotPaperSize,
            plotOrigin: new Vector2(),
            plotWindowArea: {
                upperRight: new Vector2(),
                lowerLeft: new Vector2(),
            },
            customPrintScale: { numerator: 1, denominator: 1 },
            plotLayoutFlags: 692,
            plotPaperUnits: 1,
        };

        if (limits) {
            const customScale = calculateScaleToFitObject(limits.getSize());
            layoutProps.scaleFactor = customScale;
            const imageOrigin = calculateImageOrigin(limits, customScale);
            layoutProps.paperImageOrigin = imageOrigin;
        }

        const dxfLayout = new DxfLayout(layoutProps);
        dxfLayout.addAcadReactor(dictHandle);

        layoutDict.entries.push({ entryName: name, entryHandle: layoutHandle });

        manager.addNode({ parent: objects, dxfObject: dxfLayout });

        return block;
    }

    public static createImageInsert(
        manager: DxfFileManager,
        data: DxfImageData
    ): string {
        const imageName = data.name;
        const objects = manager.getSection("OBJECTS");
        const entities = manager.getSection("ENTITIES");

        const dicts = manager.getDictionaries();
        const presentImageDictNode = dicts.find((dict) =>
            (dict.dxfObject as DxfDictionary).entries.find(
                (e) => e.entryName === imageName
            )
        );

        let imageDefHandle: string = DxfHandle.handle();
        const imageHandle = DxfHandle.handle();
        const imageDefReactorHandle = DxfHandle.handle();

        let imageDefinition: DXFNode | undefined = undefined;

        const pixelsizeX = data.worldSize.x / data.width;
        const pixelsizeY = data.worldSize.y / data.heigh;
        const imageSize = new Vector2(data.width, data.heigh);
        const pixelSize = new Vector2(pixelsizeX, pixelsizeY);
        const fileName = `./${data.name}`;

        if (!presentImageDictNode) {
            const imageDefProps: Partial<DxfImageDefinition> = {
                handle: imageDefHandle,
                fileName,
                imageSize,
                pixelSize,
            };
            const imageDef = new DxfImageDefinition(imageDefProps);

            imageDefinition = manager.addNode({
                parent: objects,
                dxfObject: imageDef,
            });

            const dictHandle = DxfHandle.handle();

            const dictProps: Partial<DxfDictionary> = {
                handle: dictHandle,
                ownerHandle: "A",
                duplicateRecordCloningFlag: 1,
            };
            const imageDict = new DxfDictionary(dictProps);
            imageDict.entries.push({
                entryHandle: imageDefHandle,
                entryName: imageName,
            });
            manager.addDictionary(imageDict, "ACAD_IMAGE_DICT");
        } else {
            const presentImageDict =
                presentImageDictNode.dxfObject as DxfDictionary;
            imageDefHandle =
                presentImageDict.entries.find((x) => x.entryName === imageName)
                    ?.entryHandle ?? "";

            imageDefinition = manager
                .getSection("OBJECTS")
                .find(
                    (x) =>
                        (x.dxfObject as DxfCommonObject).handle ===
                        imageDefHandle
                );
        }

        const uVector = new Vector3(pixelsizeX * data.scale.x, 0);
        const vVector = new Vector3(0, pixelsizeX * data.scale.y);

        uVector.applyMatrix4Rotation(data.rotation);
        vVector.applyMatrix4Rotation(data.rotation);
        const clipBoundaryVertices = [imageSize.subScalar(0.5)];

        const imageProps: Partial<DxfImage> = {
            handle: imageHandle,
            insertionPoint: data.insertionPoint,
            uVector,
            vVector,
            imageSize,
            clipBoundaryVertices,
            imageDefHandle,
            imageDefReactorHandle,
            displayProperties: 1,
            clippingState: 0,
            brightness: 50,
            contrast: 50,
            fade: 0,
            clippingBoundaryType: 1,
        };
        const image = new DxfImage(imageProps);

        manager.addNode({ parent: entities, dxfObject: image });

        const imageDefReactopProps: Partial<DxfImageDefReactor> = {
            handle: imageDefReactorHandle,
            ownerHandle: imageHandle,
            associatedImageObjectId: imageHandle,
        };
        const imageDefReactor = new DxfImageDefReactor(imageDefReactopProps);

        manager.addNode({
            parent: objects,
            dxfObject: imageDefReactor,
        });

        if (imageDefinition) {
            const dxfImageDefinition =
                imageDefinition.dxfObject as DxfImageDefinition;
            dxfImageDefinition.addAcadReactor(imageDefReactorHandle);
        }

        return imageHandle;
    }

    public static createSortenTable(
        manager: DxfFileManager,
        handels: string[]
    ) {
        if (handels.length < 2) {
            return;
        }

        const sortetsTableDictHandle = DxfHandle.handle();
        const sortetsTableHandle = DxfHandle.handle();
        const modelSpaseHandle = (
            manager.getBlockRecord("*Model_Space")?.dxfObject as DxfBlockRecord
        ).handle;

        const dict = new DxfDictionary({
            handle: sortetsTableDictHandle,
            hardOwnerFlag: true,
        });

        dict.addEntry(sortetsTableHandle, "ACAD_SORTENTS");

        manager.addDictionary(dict);

        const sortentsTable = new DxfSortentsTable({
            handle: sortetsTableHandle,
            ownerHandle: sortetsTableDictHandle,
            sortentsOwnerHandle: modelSpaseHandle,
            sortentOrder: handels,
        });

        const objects = manager.getSection("OBJECTS");
        manager.addNode({ parent: objects, dxfObject: sortentsTable });
    }

    public static create3dFace(
        manager: DxfFileManager,
        layer: string,
        color: number,
        points: Vector3[],
        parentBlockName?: string
    ) {
        if (points.length < 3 || points.length > 4) {
            return;
        }
        const [firstCorner, secondCorner, thirdCorner] = points;
        const face = new Dxf3DFace({
            layer,
            colorNumber: color,
            firstCorner,
            secondCorner,
            thirdCorner,
        });

        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        manager.addNode({ parent, dxfObject: face });
    }

    public static createLine(
        manager: DxfFileManager,
        start: Vector2,
        end: Vector2,
        layer: string = "0",
        parentBlockName?: string
    ): DXFNode {
        const line = new DxfLine({
            layer,
            startPoint: new Vector3(start.x, start.y),
            endPoint: new Vector3(end.x, end.y),
        });

        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        return manager.addNode({ parent: parent, dxfObject: line });
    }

    public static createCircle(
        manager: DxfFileManager,
        center: Vector2,
        radius: number,
        layer: string = "0",
        parentBlockName?: string,
        paperSpace: boolean = false
    ) {
        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
            ? manager.getBlockRecord(parentBlockName)?.handle
            : undefined;

        const circle = new DxfCircle({
            layer,
            handleToOwner: ownerHandle,
            center: new Vector3(center.x, center.y),
            radius,
            paperSpace,
        });

        return manager.addNode({ parent, dxfObject: circle });
    }

    public static createPolyLine(
        manager: DxfFileManager,
        props: DxfPolylineData,
        parentBlockName?: string
    ) {
        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const handleToOwner: string | undefined = parentBlockName
            ? manager.getBlockRecord(parentBlockName)?.handle
            : undefined;

        const polylineProps: Partial<DxfLwPolyline> = {
            layer: props.layer,
            constantWidth: props.width,
            colorNumber: props.color,
            handleToOwner,
            paperSpace: props.paperspace ?? false,
        };
        const polyline = new DxfLwPolyline(polylineProps);

        for (const vert of props.vertices) {
            polyline.addVertex(vert);
        }

        polyline.closed = props.closed;

        const polylineNode = manager.addNode({ parent, dxfObject: polyline });

        return polylineNode;
    }

    public static createBlockInsert(
        manager: DxfFileManager,
        blockInsertData: blockInsertData,
        parentBlockName?: string,
        paperSpace: boolean = false
    ): DXFNode | undefined {
        let blockName: string = "";
        if (typeof blockInsertData.blockName === "string") {
            blockName = blockInsertData.blockName;
        } else {
            blockName = (blockInsertData.blockName.dxfObject as DxfBlock)
                .blockName;
        }

        const insertionPoint = new Vector3(
            ...blockInsertData.insertPoint.asArray()
        );

        const insertProps: Partial<DxfInsert> = {
            blockName,
            layer: blockInsertData.layer,
            insertionPoint,
            rotationAngle: blockInsertData.rotation,
            colorNumber: blockInsertData.color,
            paperSpace,
        };

        const insert = new DxfInsert(insertProps);

        const blockDef = manager.getBlock(blockName);
        if (blockDef) {
            insert.hasAttributes = blockDef.children.some(
                (nodes) => nodes.type === "ATTDEF"
            );
        }

        const parent = this.getEntityParentNode(manager, parentBlockName);

        if (parent === undefined) {
            return;
        }

        return manager.addNode({ parent, dxfObject: insert });
    }

    public static createBlock(
        manager: DxfFileManager,
        name: string,
        layer: string = "0",
        type: BlockTypeFlag = BlockTypeFlag.None,
        annotative: boolean = false
    ) {
        return manager.addBlock(name, type, layer, annotative);
    }

    private static getEntityParentNode(
        manager: DxfFileManager,
        blockName?: string
    ): DXFNode | undefined {
        let parent: DXFNode;

        if (blockName !== undefined) {
            const block = manager.getBlock(blockName);
            if (block !== undefined) {
                parent = block;
            } else {
                return;
            }
        } else {
            parent = manager.getSection("ENTITIES");
        }
        return parent;
    }

    public static setView(
        manager: DxfFileManager,
        center: Vector2,
        width?: number,
        height?: number
    ) {
        const vport = manager.getViewPort("*ACTIVE")?.dxfObject as
            | DxfVPort
            | undefined;
        if (vport !== undefined) {
            vport.viewCenter = center;

            if (width && height) {
                vport.viewHeight = height;
                vport.viewAspectRatio = width / height;
            }
        }
    }

    public static createLayer(
        manager: DxfFileManager,
        name: string,
        colour?: number
    ) {
        manager.addLayer(name, colour);
    }

    public static addAttributeDefinition(
        manager: DxfFileManager,
        blockName: string,
        tag: string,
        promt: string,
        origin: Vector2 | Vector3 = new Vector3(),
        textRotation: number = 0,
        height: number = 10,
        layer?: string,
        textStyle?: string
    ): DXFNode | undefined {
        const blockDefNode = manager.getBlock(blockName);

        if (blockDefNode === undefined) {
            return;
        }
        const blockDef = blockDefNode.dxfObject as DxfBlock;

        blockDef.addBlockFlag(BlockTypeFlag.NonConstantAttributes);

        const firstAlignmentPoint = new Vector3(...origin.asArray());
        const secondAlignmentPoint = new Vector3(...origin.asArray());
        const ownerHandle = blockDefNode.handle ?? undefined;
        const attDefProps: Partial<DxfAttributeDefinition> = {
            handleToOwner: ownerHandle,
            firstAlignmentPoint,
            secondAlignmentPoint,
            textRotation,
            tagString: tag,
            promptString: promt,
            textStyleName: textStyle,
            layer,
            textHeight: height,
            horizontalJustification: HorizontalJustification.Center,
            verticalTextJustificationType: VerticalJustification.Middle,
        };
        const attDef = new DxfAttributeDefinition(attDefProps);

        if (!blockDefNode.findNodesByType("SEQEND").length) {
            const seqEnd = new DxfSeqEnd(DxfHandle.handle());
            this.AddSeqEnd(manager, blockDefNode);
        }

        return manager.addNode({ parent: blockDefNode, dxfObject: attDef });
    }

    public static AddSeqEnd(manager: DxfFileManager, block?: string | DXFNode) {
        let parent: DXFNode | undefined = undefined;

        if (block instanceof DXFNode) {
            parent = block;
        } else {
            parent = this.getEntitySectionOrBlock(manager, block);
        }

        const ownerHandle = parent.handle
            ? parent.handle
            : manager.getBlockRecord("*Model_Space")?.handle;

        const seqEnd = new DxfSeqEnd(DxfHandle.handle());
        seqEnd.handleToOwner = ownerHandle;
        parent.addChild(seqEnd);
    }

    public static AddAttribute(
        manager: DxfFileManager,
        ownerHandle: string,
        attrData: attributeInsertData,
        parentBlockName?: string
    ) {
        const widthFactor = 0.78; //hardcoded for Arial textStyle

        const textHeight = attrData.heigh ?? 0.8;
        const textRotation = attrData.rotation || 0;
        const attrFlag = attrData.attrFlag ?? AttrFlag.Invisible;

        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const handleToOwner = parent.handle
            ? parent.handle
            : manager.getBlockRecord("*Model_Space")?.handle;
        const startPoint = new Vector3(...attrData.position.asArray());
        const attrProps: Partial<DxfAttribute> = {
            handleToOwner,
            attributeTag: attrData.tag,
            defaultValue: attrData.text,
            layer: attrData.layer,
            startPoint,
            alignmentPoint: startPoint,
            textHeight,
            textRotation,
        };
        const attr = new DxfAttribute(attrProps);
        attr.addFlag(attrFlag);

        const positionX = attrData.position.x;
        const positionY = attrData.position.y;

        if (
            attrData.horizontalJustification !== undefined &&
            attrData.horizontalJustification !== HorizontalJustification.Left
        ) {
            // Calculate string length based on text height and width factor
            const textLength = attrData.text.length * textHeight * widthFactor;

            attr.horizontalJustification = attrData.horizontalJustification;

            // Adjust alignment point for non-zero justification
            let alignX = positionX;
            if (
                attrData.horizontalJustification ===
                HorizontalJustification.Center
            ) {
                alignX -= textLength / 2; // Center-aligned
            } else if (
                attrData.horizontalJustification ===
                HorizontalJustification.Right
            ) {
                alignX -= textLength; // Right-aligned
            }
            (attr.alignmentPoint ??= startPoint).x = alignX;
        }

        if (
            attrData.verticalJustification !== undefined &&
            attrData.verticalJustification !== VerticalJustification.Bottom
        ) {
            attr.verticalJustification = attrData.verticalJustification;

            let alignY = positionY;

            if (
                attrData.verticalJustification === VerticalJustification.Middle
            ) {
                alignY -= textHeight / 2;
            } else if (
                attrData.verticalJustification === VerticalJustification.Top
            ) {
                alignY -= textHeight;
            }

            (attr.alignmentPoint ??= startPoint).y = alignY;
        }

        return manager.addNode({ parent, dxfObject: attr });
    }

    public static createText(
        manager: DxfFileManager,
        data: dxfTextData,
        parentBlockName?: string
    ) {
        let parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const handleToOwner = parent.handle
            ? parent.handle
            : manager.getBlockRecord("*Model_Space")?.handle;
        const firstAlignmentPoint = new Vector3(...data.position.asArray());
        const textProps: Partial<DxfText> = {
            handleToOwner,
            firstAlignmentPoint,
            secondAlignmentPoint: firstAlignmentPoint,
            textString: data.text,
            textHeight: data.height,
            layer: data.layer,
            horizontalJustification: data.horizontalJustification,
            verticalJustification: data.verticalJustification,
            rotation: data.rotation,
            colorNumber: data.color,
            textStyleName: "Arial",
            paperSpace: data.paperSpace ?? false,
        };
        const text = new DxfText(textProps);

        return manager.addNode({ parent, dxfObject: text });
    }

    public static createPileTable(
        manager: DxfFileManager,
        name: string,
        layer: string,
        tableData: Table,
        isImperial:boolean,
        
    ) {
        
        const rowHeight = heightInProjectUnits(6, isImperial);
        const blockName = name;
        
        this.createBlock(manager, blockName, layer, BlockTypeFlag.None, true);

        const width = tableData[0].reduce((sum, x) => sum + heightInProjectUnits(x.width, isImperial), 0);

        const heigh = tableData.length * rowHeight;
        const textHeight = heightInProjectUnits(1.5, isImperial)

        for (let rowIndex = 0; rowIndex < tableData.length; rowIndex++) {
            let currentX = 0;
            const row = tableData[rowIndex];

            const currentY = -rowHeight * rowIndex;

            const start = new Vector2(0, currentY);
            const end = new Vector2(width, currentY);

            this.createLine(manager, start, end, layer, blockName);
         
            for (let colIndex = 0; colIndex < row.length; colIndex++) {
                const cell = row[colIndex];
                const cellwidth = heightInProjectUnits(cell.width, isImperial)

                const x = currentX + cellwidth / 2;
                const y = currentY - rowHeight / 2;
                if ("content" in cell) {
                    const textData: dxfTextData = {
                        text: cell.content,
                        position: new Vector2(x, y),
                        height: textHeight,
                        layer,
                        horizontalJustification: HorizontalJustification.Center,
                    };

                    this.createText(manager, textData, blockName);
                } else {
                    const pileType = cell.symbol as PileType;
                    if (!pileType) {
                        console.log(`Cant identify pile type ${cell.symbol}`);
                        continue;
                    }

                    const blocDefIcon = DXFNodeFactory.createPileIcon(
                        manager,
                        pileType,
                        "pvfarm_piles",
                        cell.color,
                        isImperial
                    );
                    if (blocDefIcon) {
                        const pileInsertData: blockInsertData = {
                            blockName: blocDefIcon,
                            insertPoint: new Vector2(x, y),
                            layer,
                        };

                        this.createBlockInsert(
                            manager,
                            pileInsertData,
                            blockName
                        );
                    }
                }

                const colStart = new Vector2(currentX, currentY);
                const colEnd = new Vector2(currentX, currentY - rowHeight);

                this.createLine(manager, colStart, colEnd, layer, blockName);

                currentX += cellwidth;
            }
        }

        this.createLine(
            manager,
            new Vector2(width, 0),
            new Vector2(width, -heigh),
            layer,
            blockName
        );
        this.createLine(
            manager,
            new Vector2(0, -heigh),
            new Vector2(width, -heigh),
            layer,
            blockName
        );
    }

    public static createPileIcon(
        manager: DxfFileManager,
        name: PileType,
        layer: string,
        color: number,
        isImperial: boolean,
    ): DXFNode | undefined {
        let node: DXFNode | undefined = undefined;
        const diameter = heightInProjectUnits(3, isImperial);
        const attrHeight = heightInProjectUnits(0.5, isImperial);
        const blockIconName = getPileBlockName(name, color);
        if (manager.getBlocksNames().includes(blockIconName)) {
            return manager.getBlock(blockIconName)!;
        }

        switch (name) {
            case "SAP": {
                node = this.addPileIconSAP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAPD": {
                node = this.addPileIconSAPD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAE": {
                node = this.addPileIconSAE(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SAED": {
                node = this.addPileIconSAED(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SC": {
                node = this.addPileIconSC(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SCD": {
                node = this.addPileIconSCD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "SMP": {
                node = this.addPileIconSMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAP": {
                node = this.addPileIconHAP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAPD": {
                node = this.addPileIconHAPD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAE": {
                node = this.addPileIconHAE(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HAED": {
                node = this.addPileIconSMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HC": {
                node = this.addPileIconHAED(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HCD": {
                node = this.addPileIconHCD(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }
            case "HMP": {
                node = this.addPileIconHMP(
                    manager,
                    blockIconName,
                    diameter,
                    color,
                    layer
                );
                break;
            }

            default:
                break;
        }

        const codeAttr = DXFNodeFactory.addAttributeDefinition(
            manager,
            blockIconName,
            "PILEROWCODE",
            "Row code",
            new Vector3(0, heightInProjectUnits(0.3, isImperial)),
            0,
            attrHeight,
            annotationLayer,
            "Arial"
        );

        const indexAttr = DXFNodeFactory.addAttributeDefinition(
            manager,
            blockIconName,
            "PILEROWINDEX",
            "Row index",
            new Vector3(0, -heightInProjectUnits(0.3, isImperial)),
            0,
            attrHeight,
            annotationLayer,
            "Arial"
        );

        return node;
    }

    public static addPileIconSAP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );
        this.addCircle(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSAPD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 1, color, pileLayer, blockDef);
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSAE(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCaps(manager, diameter, color, pileLayer, blockDef, 0);

        return blockDef;
    }

    public static addPileIconSAED(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCaps(
            manager,
            diameter,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconSC(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );
        this.addStar(manager, diameter, color, pileLayer, name);

        return blockDef;
    }

    public static addPileIconSCD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCross(
            manager,
            diameter,
            diameter / 12,
            color,
            pileLayer,
            blockDef
        );

        return blockDef;
    }

    public static addPileIconSMP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addDiamond(manager, diameter, color, pileLayer, blockDef);
        return blockDef;
    }

    public static addPileIconHAP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 0, color, pileLayer, blockDef);
        this.addCircle(manager, diameter / 2, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHAPD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCircle(manager, diameter, 1, color, pileLayer, blockDef);
        this.addCircle(manager, diameter / 1, 1, color, pileLayer, blockDef);

        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHAE(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCaps(manager, diameter, color, pileLayer, blockDef, 0);
        this.addCaps(manager, diameter / 2, color, pileLayer, blockDef, 0);

        return blockDef;
    }

    public static addPileIconHAED(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addCaps(
            manager,
            diameter,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCaps(
            manager,
            diameter / 2,
            color,
            pileLayer,
            blockDef,
            diameter / 10
        );
        this.addCross(manager, diameter, 0, color, pileLayer, blockDef);

        return blockDef;
    }

    public static addPileIconHC(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );
        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCaps(manager, diameter, color, pileLayer, blockDef, 2);

        return blockDef;
    }

    public static addPileIconHCD(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );

        this.addStar(manager, diameter, color, pileLayer, name);
        this.addCross(
            manager,
            diameter,
            diameter / 12,
            color,
            pileLayer,
            blockDef
        );
        this.addCaps(manager, diameter, color, pileLayer, blockDef, 2);

        return blockDef;
    }

    public static addPileIconHMP(
        manager: DxfFileManager,
        name: string,
        diameter: number,
        color: number,
        pileLayer: string
    ): DXFNode {
        const blockDef = manager.addBlock(
            name,
            BlockTypeFlag.None,
            pileLayer,
            true
        );
        this.addDiamond(manager, diameter, color, pileLayer, blockDef);
        this.addDiamond(manager, diameter / 2, color, pileLayer, blockDef);
        return blockDef;
    }

    private static addStar(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        parent: string
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const sideWidth = diameter / 6;

        const dxfPolylineData: DxfPolylineData = {
            layer: pileLayer,
            color: color,
            closed: true,
            width: 0,
            vertices: [
                new Vector3(-intersection1, intersection1 - sideWidth),
                new Vector3(-intersection1, intersection1), //vertex 1
                new Vector3(-intersection1 + sideWidth, intersection1),
                new Vector3(0, 0 + sideWidth),
                new Vector3(intersection1 - sideWidth, intersection1),
                new Vector3(intersection1, intersection1), //vertex 2
                new Vector3(intersection1, intersection1 - sideWidth),
                new Vector3(0 + sideWidth, 0),
                new Vector3(intersection1, -intersection1 + sideWidth),
                new Vector3(intersection1, -intersection1), //vertex 3
                new Vector3(intersection1 - sideWidth, -intersection1),
                new Vector3(0, -sideWidth),
                new Vector3(-intersection1 + sideWidth, -intersection1),
                new Vector3(-intersection1, -intersection1), //vertex 4
                new Vector3(-intersection1, -intersection1 + sideWidth),
                new Vector3(-sideWidth, 0),
            ],
        };

        DXFNodeFactory.createPolyLine(manager, dxfPolylineData, parent);
    }

    private static addCross(
        manager: DxfFileManager,
        diameter: number,
        offset: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ): void {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;
        const commonLineProps: Partial<DxfLine> = {
            thickness: 2,
            layer: pileLayer,
            colorNumber: color,
        };

        const line1 = new DxfLine(commonLineProps);
        line1.startPoint = new Vector3(
            -intersection1 + offset,
            -intersection1 + offset
        );
        line1.endPoint = new Vector3(
            intersection1 - offset,
            intersection1 - offset
        );

        const line2 = new DxfLine(commonLineProps);
        line2.startPoint = new Vector3(
            intersection1 - offset,
            -intersection1 + offset
        );
        line2.endPoint = new Vector3(
            -intersection1 + offset,
            intersection1 - offset
        );

        manager.addNode({ parent: blockDef, dxfObject: line1 });
        manager.addNode({ parent: blockDef, dxfObject: line2 });
    }

    private static addCaps(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode,
        offset: number
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const intersection1 = (radius * sqrt2) / 2;

        const commonLineProps: Partial<DxfLine> = {
            thickness: 2,
            layer: pileLayer,
            colorNumber: color,
        };

        const line1 = new DxfLine(commonLineProps);
        line1.startPoint = new Vector3(-intersection1, -intersection1 + offset);
        line1.endPoint = new Vector3(-intersection1, intersection1 - offset);

        const line2 = new DxfLine(commonLineProps);
        line2.startPoint = new Vector3(-intersection1 + offset, intersection1);
        line2.endPoint = new Vector3(intersection1 - offset, intersection1);

        const line3 = new DxfLine(commonLineProps);
        line3.startPoint = new Vector3(intersection1, intersection1 - offset);
        line3.endPoint = new Vector3(intersection1, -intersection1 + offset);

        const line4 = new DxfLine(commonLineProps);
        line4.startPoint = new Vector3(intersection1 - offset, -intersection1);
        line4.endPoint = new Vector3(-intersection1 + offset, -intersection1);

        manager.addNode({ parent: blockDef, dxfObject: line1 });
        manager.addNode({ parent: blockDef, dxfObject: line2 });
        manager.addNode({ parent: blockDef, dxfObject: line3 });
        manager.addNode({ parent: blockDef, dxfObject: line4 });
    }

    private static addDiamond(
        manager: DxfFileManager,
        diameter: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ) {
        const radius = diameter / 2;
        const sqrt2 = Math.sqrt(2);
        const point1 = (radius * sqrt2) / 2;
        const point2 = point1 * (3 / 4);

        const commonLineProps: Partial<DxfLine> = {
            thickness: 2,
            layer: pileLayer,
            colorNumber: color,
        };

        const line1 = new DxfLine(commonLineProps);
        line1.startPoint = new Vector3(-point2, 0);
        line1.endPoint = new Vector3(0, point1);

        const line2 = new DxfLine(commonLineProps);
        line2.startPoint = new Vector3(0, point1);
        line2.endPoint = new Vector3(point2, 0);

        const line3 = new DxfLine(commonLineProps);
        line3.startPoint = new Vector3(point2, 0);
        line3.endPoint = new Vector3(0, -point1);

        const line4 = new DxfLine(commonLineProps);
        line4.startPoint = new Vector3(0, -point1);
        line4.endPoint = new Vector3(-point2, 0);

        manager.addNode({ parent: blockDef, dxfObject: line1 });
        manager.addNode({ parent: blockDef, dxfObject: line2 });
        manager.addNode({ parent: blockDef, dxfObject: line3 });
        manager.addNode({ parent: blockDef, dxfObject: line4 });
    }

    private static addCircle(
        manager: DxfFileManager,
        diameter: number,
        offset: number,
        color: number,
        pileLayer: string,
        blockDef: DXFNode
    ) {
        const radius = diameter / 2;
        const angleRadians = Math.asin(offset / radius);
        const angleDegrees = angleRadians * (180 / Math.PI);

        const commonArcProps: Partial<DxfArc> = {
            radius,
            layer: pileLayer,
            colorNumber: color,
        };

        const arc1 = new DxfArc(commonArcProps);
        arc1.startAngle = 45 + angleDegrees;
        arc1.endAngle = 135 - angleDegrees;

        const arc2 = new DxfArc(commonArcProps);
        arc2.startAngle = 135 + angleDegrees;
        arc2.endAngle = 225 - angleDegrees;

        const arc3 = new DxfArc(commonArcProps);
        arc3.startAngle = 225 + angleDegrees;
        arc3.endAngle = 315 - angleDegrees;

        const arc4 = new DxfArc(commonArcProps);
        arc4.startAngle = 315 + angleDegrees;
        arc4.endAngle = 45 - angleDegrees;

        manager.addNode({ parent: blockDef, dxfObject: arc1 });
        manager.addNode({ parent: blockDef, dxfObject: arc2 });
        manager.addNode({ parent: blockDef, dxfObject: arc3 });
        manager.addNode({ parent: blockDef, dxfObject: arc4 });
    }

    public static addRoundHatch(
        manager: DxfFileManager,
        radius: number,
        center: Vector2,
        color: number,
        layer: string,
        parentBlockName?: string,
        paperSpace: boolean = false
    ) {
        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
            ? manager.getBlockRecord(parentBlockName)?.handle
            : undefined;

        const hatchProperties: Partial<DxfHatch> = {
            paperSpace,
            layer,
            handleToOwner: ownerHandle,
            colorNumber: color,
        };
        const hatch = new DxfHatch(hatchProperties);
        const boundaryData = new BoundaryPathData();
        const hatchContour = new CircleBoundary(center, radius);
        boundaryData.addBoundary(hatchContour);

        hatch.boundaryPaths.push(boundaryData);
        hatch.gradientData = new GradientData();

        return manager.addNode({ parent, dxfObject: hatch });
    }

    public static addRectangleHatch(
        manager: DxfFileManager,
        vertices: Vector2[],
        color: number,
        layer: string,
        parentBlockName?: string,
        paperspace: boolean = false
    ) {
        if (vertices.length < 3) {
            return;
        }

        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
            ? manager.getBlockRecord(parentBlockName)?.handle
            : undefined;

        const hatchProperties: Partial<DxfHatch> = {
            paperSpace: paperspace,
            layer,
            handleToOwner: ownerHandle,
            colorNumber: color,
        };

        const boundaryData = new BoundaryPathData();
        const hatchContour = new PolylineBoundary();
        for (let i = 0; i < vertices.length; i++) {
            hatchContour.addVertex(vertices[i]);
        }
        hatchContour.isClosed = true;

        boundaryData.addBoundary(hatchContour);

        const hatch = new DxfHatch(hatchProperties);
        hatch.boundaryPaths.push(boundaryData);
        hatch.gradientData = new GradientData();

        return manager.addNode({ parent, dxfObject: hatch });
    }

    public static addSpline(
        manager: DxfFileManager,
        splineData: splineData,
        parentBlockName?: string
    ) {
        const degree = splineData.knots.length > 3 ? 3 : 2;
        const vectorSet = new Set<Vector2>();
        for (const knot of splineData.knots) {
            vectorSet.add(knot);
        }

        const parent = this.getEntitySectionOrBlock(manager, parentBlockName);
        const ownerHandle: string | undefined = parentBlockName
            ? manager.getBlockRecord(parentBlockName)?.handle
            : undefined;

        const splineProps: Partial<DxfSpline> = {
            handleToOwner: ownerHandle,
            layer: splineData.layer,
            splineFlag: 8,
            degree,
            numberOfKnots: vectorSet.size * 2,
            numberOfControlPoints: vectorSet.size,
        };

        const spline = new DxfSpline(splineProps);

        for (let i = 0; i < vectorSet.size; i++) {
            spline.knots.push(0);
        }

        for (let i = 0; i < vectorSet.size; i++) {
            spline.knots.push(1);
        }

        for (const position of vectorSet) {
            const position3d = Vector3.fromVec2(position);
            spline.controlPoints.push(position3d);
        }

        return manager.addNode({ parent, dxfObject: spline });
    }
}
