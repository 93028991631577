import type { SceneInstance } from '../';
export type Material = 'aluminum' | 'copper'
export const materials = ['aluminum', 'copper'] as const

// R - resistency Om/kft.
// A - amp.
export const _gauges = {
    // copper cable gauges                Resist at 25C   DEPREC.
    "#14 cu"    : { material: 'copper'  , R: 2.62  ,   A: 25 ,   BuriedAmpPerT: [[60, 15 ], [75, 20 ], [90, 25 ]], AboveAmPerT: [[60, 25 ], [75, 30 ], [90, 35  ]] },
    "#12 cu"    : { material: 'copper'  , R: 1.65  ,   A: 25 ,   BuriedAmpPerT: [[60, 20 ], [75, 25 ], [90, 30 ]], AboveAmPerT: [[60, 30 ], [75, 35 ], [90, 40  ]] },
    "#10 cu"    : { material: 'copper'  , R: 1.04  ,   A: 30 ,   BuriedAmpPerT: [[60, 30 ], [75, 35 ], [90, 40 ]], AboveAmPerT: [[60, 40 ], [75, 50 ], [90, 55  ]] },
    "#8 cu"     : { material: 'copper'  , R: 0.654 ,   A: 40 ,   BuriedAmpPerT: [[60, 40 ], [75, 50 ], [90, 55 ]], AboveAmPerT: [[60, 60 ], [75, 70 ], [90, 80  ]] },
    "#6 cu"     : { material: 'copper'  , R: 0.41  ,   A: 55 ,   BuriedAmpPerT: [[60, 55 ], [75, 65 ], [90, 75 ]], AboveAmPerT: [[60, 80 ], [75, 95 ], [90, 105 ]] },
    "#4 cu"     : { material: 'copper'  , R: 0.259 ,   A: 70 ,   BuriedAmpPerT: [[60, 70 ], [75, 85 ], [90, 95 ]], AboveAmPerT: [[60, 105], [75, 125], [90, 140 ]] },
    "#3 cu"     : { material: 'copper'  , R: 0.205 ,   A: 95 ,   BuriedAmpPerT: [[60, 85 ], [75, 100], [90, 115]], AboveAmPerT: [[60, 120], [75, 145], [90, 165 ]] },
    "#2 cu"     : { material: 'copper'  , R: 0.162 ,   A: 95 ,   BuriedAmpPerT: [[60, 95 ], [75, 115], [90, 130]], AboveAmPerT: [[60, 140], [75, 170], [90, 190 ]] },
    "#1 cu"     : { material: 'copper'  , R: 0.129 ,   A: 110,   BuriedAmpPerT: [[60, 110], [75, 130], [90, 145]], AboveAmPerT: [[60, 165], [75, 195], [90, 220 ]] },
    "#1/0 cu"   : { material: 'copper'  , R: 0.102 ,   A: 125,   BuriedAmpPerT: [[60, 125], [75, 150], [90, 170]], AboveAmPerT: [[60, 195], [75, 230], [90, 260 ]] },
    "#2/0 cu"   : { material: 'copper'  , R: 0.0811,   A: 145,   BuriedAmpPerT: [[60, 145], [75, 175], [90, 195]], AboveAmPerT: [[60, 225], [75, 265], [90, 300 ]] },
    "#3/0 cu"   : { material: 'copper'  , R: 0.0642,   A: 165,   BuriedAmpPerT: [[60, 165], [75, 200], [90, 225]], AboveAmPerT: [[60, 260], [75, 310], [90, 350 ]] },
    "#4/0 cu"   : { material: 'copper'  , R: 0.0509,   A: 195,   BuriedAmpPerT: [[60, 195], [75, 230], [90, 260]], AboveAmPerT: [[60, 300], [75, 360], [90, 405 ]] },
    "250MCM cu" : { material: 'copper'  , R: 0.0431,   A: 215,   BuriedAmpPerT: [[60, 215], [75, 255], [90, 290]], AboveAmPerT: [[60, 340], [75, 405], [90, 455 ]] },
    "350MCM cu" : { material: 'copper'  , R: 0.0308,   A: 260,   BuriedAmpPerT: [[60, 260], [75, 310], [90, 350]], AboveAmPerT: [[60, 420], [75, 505], [90, 570 ]] },
    // aluminum cable gauges
    "#10 al"    : { material: 'aluminum', R: 1.7000,   A: 25 ,   BuriedAmpPerT: [[60, 25 ], [75, 30 ], [90, 35 ]], AboveAmPerT: [[60, 35 ], [75, 40 ], [90, 45  ]] },
    "#8 al"     : { material: 'aluminum', R: 1.0700,   A: 35 ,   BuriedAmpPerT: [[60, 35 ], [75, 40 ], [90, 45 ]], AboveAmPerT: [[60, 45 ], [75, 55 ], [90, 60  ]] },
    "#6 al"     : { material: 'aluminum', R: 0.6740,   A: 40 ,   BuriedAmpPerT: [[60, 40 ], [75, 50 ], [90, 55 ]], AboveAmPerT: [[60, 60 ], [75, 75 ], [90, 85  ]] },
    "#4 al"     : { material: 'aluminum', R: 0.4240,   A: 50 ,   BuriedAmpPerT: [           [75, 65 ]           ], AboveAmPerT: [           [75, 100]            ] },
    "#1 al"     : { material: 'aluminum', R: 0.2110,   A: 85 ,   BuriedAmpPerT: [[60, 85 ], [75, 100], [90, 115]], AboveAmPerT: [[60, 130], [75, 155], [90, 175 ]] },
    "#1/0 al"   : { material: 'aluminum', R: 0.1680,   A: 100,   BuriedAmpPerT: [[60, 100], [75, 120], [90, 135]], AboveAmPerT: [[60, 150], [75, 180], [90, 205 ]] },
    "#2/0 al"   : { material: 'aluminum', R: 0.1330,   A: 115,   BuriedAmpPerT: [[60, 115], [75, 135], [90, 150]], AboveAmPerT: [[60, 175], [75, 210], [90, 235 ]] },
    "#3/0 al"   : { material: 'aluminum', R: 0.1050,   A: 130,   BuriedAmpPerT: [[60, 130], [75, 155], [90, 175]], AboveAmPerT: [[60, 200], [75, 240], [90, 270 ]] },
    "#4/0 al"   : { material: 'aluminum', R: 0.0836,   A: 150,   BuriedAmpPerT: [[60, 150], [75, 180], [90, 205]], AboveAmPerT: [[60, 235], [75, 280], [90, 315 ]] },
    "250MCM al" : { material: 'aluminum', R: 0.0708,   A: 170,   BuriedAmpPerT: [[60, 170], [75, 205], [90, 230]], AboveAmPerT: [[60, 265], [75, 315], [90, 355 ]] },
    "300MCM al" : { material: 'aluminum', R: 0.0590,   A: 210,   BuriedAmpPerT: [[60, 195], [75, 230], [90, 260]], AboveAmPerT: [[60, 290], [75, 350], [90, 395 ]] },
    "350MCM al" : { material: 'aluminum', R: 0.0505,   A: 210,   BuriedAmpPerT: [[60, 210], [75, 250], [90, 280]], AboveAmPerT: [[60, 330], [75, 395], [90, 445 ]] },
    "400MCM al" : { material: 'aluminum', R: 0.0442,   A: 210,   BuriedAmpPerT: [[60, 225], [75, 270], [90, 305]], AboveAmPerT: [[60, 355], [75, 425], [90, 480 ]] },
    "500MCM al" : { material: 'aluminum', R: 0.0354,   A: 260,   BuriedAmpPerT: [[60, 260], [75, 310], [90, 350]], AboveAmPerT: [[60, 405], [75, 485], [90, 545 ]] },
    "600MCM al" : { material: 'aluminum', R: 0.0295,   A: 285,   BuriedAmpPerT: [[60, 285], [75, 340], [90, 385]], AboveAmPerT: [[60, 455], [75, 540], [90, 615 ]] },
    "700MCM al" : { material: 'aluminum', R: 0.0253,   A: 285,   BuriedAmpPerT: [[60, 315], [75, 375], [90, 425]], AboveAmPerT: [[60, 500], [75, 595], [90, 670 ]] },
    "750MCM al" : { material: 'aluminum', R: 0.0236,   A: 320,   BuriedAmpPerT: [[60, 320], [75, 385], [90, 435]], AboveAmPerT: [[60, 515], [75, 620], [90, 700 ]] },
    "1000MCM al": { material: 'aluminum', R: 0.0177,   A: 375,   BuriedAmpPerT: [[60, 375], [75, 445], [90, 500]], AboveAmPerT: [[60, 625], [75, 750], [90, 845 ]] },
    "1250MCM al": { material: 'aluminum', R: 0.0142,   A: 375,   BuriedAmpPerT: [[60, 405], [75, 485], [90, 545]], AboveAmPerT: [[60, 710], [75, 855], [90, 965 ]] },
    "1500MCM al": { material: 'aluminum', R: 0.0118,   A: 375,   BuriedAmpPerT: [[60, 435], [75, 520], [90, 585]], AboveAmPerT: [[60, 795], [75, 950], [90, 1070]] },
    // custom cable
    "busbar"    : { material: ''        , R: 0.0001,   A: 10000, BuriedAmpPerT: [           [75, 10000]         ], AboveAmPerT: [           [75, 10000]          ] },
} as const;



export type GaugeId = keyof typeof _gauges;
export type AmpPerTemperature = [temp: number, amp: number];
export type Gauge = {
    material: 'aluminum' | 'copper' | '',
    R: number,
    A: number,
    BuriedAmpPerT: AmpPerTemperature[],
    AboveAmPerT: AmpPerTemperature[],
};
export const gauges = <any>_gauges as { [key in GaugeId]: Gauge };

export interface TemperatureConductorParams {
    temperature: number;
    isBuried: boolean;
};
export function getAmpByTemperature(
    gaugeId: GaugeId,
    { isBuried, temperature }: TemperatureConductorParams,
) {
    const gauge = gauges[gaugeId];
    const amps = isBuried ? gauge.BuriedAmpPerT : gauge.AboveAmPerT

    // if exact match found, return it
    const exact = amps.find(x => x[0] === temperature);
    if (exact) return exact[1];

    // find last element that is smaller than given temperature
    const smaller = [...amps].reverse() .find(x => x[0] < temperature);
    // find first element that is bigger than given temperature
    const bigger = [...amps].find(x => x[0] > temperature);


    // if no smaller nor bigger present return
    // just return nominal amp
    if (!smaller && !bigger) {
        console.error(
            'no amp found for', gaugeId,
            'returning nominal', gauge.A,
        );
        return gauge.A;
    }

    // if one of smaller or bigger not present, then return another one
    // meaning temperature is outside of the range, so limit is taken
    if (!smaller || !bigger)
        return (smaller || bigger)![1];

    // both `smaller` and `bigger` present
    // use lineal interpolation to find a point within the range
    const dT = bigger[0] - smaller[0];
    const dA = bigger[1] - smaller[1];
    const dt = temperature - smaller[0]
    const da = dt*dA/dT;
    const result = smaller[1] + da;
    return result
}

export function getResistanceByTemperature(
    gaugeId: GaugeId,
    temperature: number,
) {
    const gauge = gauges[gaugeId];
    const nominalTemperature = 25;
    // resistance at temperature T depends on resistance at
    // temperature of 25 deg_C
    const resistance =
        gauge.R *
        (234.5 + temperature) /
        (234.5 + nominalTemperature);
    return resistance;
}

const copperGaugeSequence = Object.keys(gauges)
    .filter(x => gauges[x as GaugeId].material === 'copper') as GaugeId[];
const aluminumGaugeSequence = Object.keys(gauges)
    .filter(x => gauges[x as GaugeId].material === 'aluminum') as GaugeId[];
const nextGaugeMap: Map<GaugeId, GaugeId> = new Map();
for (let i = 0; i < copperGaugeSequence.length-1; i++)
    nextGaugeMap.set(copperGaugeSequence[i], copperGaugeSequence[i+1])
for (let i = 0; i < aluminumGaugeSequence.length-1; i++)
    nextGaugeMap.set(aluminumGaugeSequence[i], aluminumGaugeSequence[i+1])

export function getNextGauge(gauge: GaugeId): GaugeId | null {
    const nextGaugeId = nextGaugeMap.get(gauge);
    if (!nextGaugeId) return null;
    const nextGauge = gauges[nextGaugeId];
    if (!nextGauge) return null;
    return nextGaugeId
}
export const gaugeIds = Object.keys(gauges) as Array<GaugeId>;
export const selGauge = (id: GaugeId) => id
export const selMater = (mat: Material) => mat

export function formatCableType(gauge: GaugeId): string {
    return gauge;
}

export function parseCableType(str: string): GaugeId | null {
    const gaugeId = str as GaugeId
    if (gauges[gaugeId]) return gaugeId;
    return null
}


export function getGaugeBasedOnCurrentAndMaterial(
    minCurrent: number,
    initialGauge: GaugeId,
    temperatureParams: TemperatureConductorParams,
): GaugeId {
    let gaugeId: GaugeId = initialGauge;
    while (true) {
        const curGauge = gauges[gaugeId];
        let amp = curGauge.A;
        if (temperatureParams) {
            amp = getAmpByTemperature(gaugeId, temperatureParams);
        }
        if (gaugeId && curGauge && amp > minCurrent)
            break;
        const nextGaugeId = nextGaugeMap.get(gaugeId);
        if (!nextGaugeId || !gauges[nextGaugeId])
            break;
        gaugeId = nextGaugeId;
    }
    return gaugeId;
}


export enum NodeType {
    Semifixed,
    Fixed,
    Flexible,
}

export enum Constraints {
    DoNotCrossTracker = "no tracker cross",
    Horizontal = "horizontal",
    Vertical = "vertical",
    AboveGround = "above ground",
    BelowGround = "below ground",
}

export enum ConductorType {
    Extender = "Extender",
    MultiHarness = "MultiHarness",
    Whip = "Whip",
    TrunkBus = "TrunkBus",
    DcFeeder = "DcFeeder",
    AcFeeder = "AcFeeder",
    BusBars = "BusBars",
}

export const merginCableTypes = [
    ConductorType.TrunkBus,
    ConductorType.MultiHarness,
];

export const getConductorName = (type: ConductorType, short = false): string => {
    const names = {
        [ConductorType.Extender    ]: ['Extender' ,'Ext'  ],
        [ConductorType.Whip        ]: ['Whip'     ,'Whip' ],
        [ConductorType.DcFeeder    ]: ['DcFeeder' ,'DcF'  ],
        [ConductorType.AcFeeder    ]: ['AcFeeder' ,'AcF'  ],
        [ConductorType.MultiHarness]: ['MultiHarn','MulH' ],
        [ConductorType.TrunkBus    ]: ['TrunkBus' ,'TruB' ],
        [ConductorType.BusBars     ]: ['BusBars'  ,'BBar' ],
    };
    const name = names[type];
    if (short) return name[1];
    return name[0];

}

export const _NODES = {
    string_exit: {
        name: 'String Exit Node',
        type: NodeType.Semifixed,
        short: 'Str',
    },
    end_of_multiharness: {
        name: 'End of MultiHarness',
        type: NodeType.Semifixed,
        short: 'EndOfHarness',
    },
    end_of_tracker: {
        name: 'End of Tracker',
        type: NodeType.Semifixed,
        short: 'EndOfTracker',
    },
    cb_si_circuit_exit: {
        name: 'CB/SI Circuit Exit Node',
        type: NodeType.Fixed,
        short: 'CB/SI',
    },
    ac_combiner_exit: {
        name: 'AC Combiner Box Exit Node',
        type: NodeType.Fixed,
        short: 'ACCB',
    },
    block_circuit_exit: {
        name: 'Block Circuit Exit Node',
        type: NodeType.Fixed,
        short: 'BlockExit',
    },
    utility: {
        name: 'Utility Node',
        type: NodeType.Flexible,
        short: 'Util',
    },
    inverter: {
        name: 'Inverter Node',
        type: NodeType.Fixed,
        short: 'Invert',
    },
    end_of_group: {
        name: 'Grouping Node',
        type: NodeType.Semifixed,
        short: 'EndOfGroup',
    }
}
export interface Node {
    name: string;
    type: NodeType;
    short: string;
}
export type NodeName = keyof typeof _NODES;
export const nodeNames = Object.keys(_NODES) as Array<NodeName>
export const patternStepNames = nodeNames
export const NODES = <any>_NODES as { [key in NodeName]: Node };

export const _PATTERNS = {
    // Type1 with extra utility node between cb_si and inverter
    //Type_1: {
    //    displayName: 'Type 1',
    //    description: 'The pattern with horizontal trunkbus and combiners near with the circuit',
    //    tempSchemePatternId: 'BRIGHT_ARROW',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        NODES.utility,
    //        NODES.inverter,
    //        NODES.block_circuit_exit
    //    ],
    //    // C-TrunkBusH-DcFeeder-DcFeeder
    //    conductors: [
    //        { in: 0, out: 1, type: ConductorType.Conductor,
    //            contraints: [Constraints.Vertical, Constraints.AboveGround] },
    //        { in: 1, out: 2, type: ConductorType.TrunkBus,
    //            contraints: [Constraints.AboveGround] },
    //        { in: 2, out: 3, type: ConductorType.DcFeeder,
    //            contraints: [Constraints.AboveGround] },
    //        { in: 3, out: 4, type: ConductorType.DcFeeder,
    //            contraints: [Constraints.Vertical, Constraints.BelowGround] },
    //        { in: 4, out: 5, type: ConductorType.BusBars,
    //            contraints: [] }
    //    ]
    //},

    // Type_1
    CI_HorTrunkbus: {
        displayName: 'Central inverter with horizontal trunkbus',
        description: 'The pattern with horizontal trunkbus and combiners near with the circuit',
        tempSchemePatternId: 'BRIGHT_ARROW',
        nodes: [
            NODES.string_exit,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            NODES.inverter,
            NODES.block_circuit_exit
        ],
        // C-TrunkBusH-DcFeeder-DcFeeder
        conductors: [
            {
                in: 0, out: 1,
                type: ConductorType.Extender,
                contraints: [
                    Constraints.Vertical, Constraints.AboveGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#10 AWG', material: 'copper' },
                bimConnectTo: 'in',
            },
            {
                in: 1, out: 2,
                type: ConductorType.TrunkBus,
                contraints: [Constraints.AboveGround, Constraints.Horizontal],
                gauge: selGauge("350MCM al"),
                defaultWire: { gauge: '350 kcmil', material: 'aluminum' },
            },
            {
                in: 2, out: 3,
                type: ConductorType.DcFeeder,
                contraints: [Constraints.AboveGround, Constraints.BelowGround],
                gauge: selGauge("750MCM al"),
                defaultWire: { gauge: '750 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
            {
                in: 3, out: 4,
                type: ConductorType.BusBars,
                contraints: ([] as Constraints[]),
                gauge: selGauge("busbar"),
            }
        ]
    },
    // Type_2
    CI_HorTrunkbus_Trench: {
        displayName: 'Central inverter with horizontal trunkbus and combiners near with the trench',
        description: 'The pattern with horizontal trunkbus and combiners near with the trench',
        tempSchemePatternId: 'BRIGHT_ARROW_SECOND',
        nodes: [
            NODES.string_exit,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            NODES.inverter,
            NODES.block_circuit_exit
        ],
        // C-TrunkBusH-DcFeeder
        conductors: [
            {
                gauge: selGauge("#10 cu"),
                in: 0, out: 1, type: ConductorType.Extender,
                contraints: [
                    Constraints.Vertical,
                    Constraints.AboveGround
                ],
                defaultWire: { gauge: '#10 AWG', material: 'copper' },
                bimConnectTo: 'in',
            },
            {
                gauge: selGauge("350MCM al"),
                in: 1, out: 2, type: ConductorType.TrunkBus,
                contraints: [Constraints.AboveGround, Constraints.Horizontal],
                defaultWire: { gauge: '350 kcmil', material: 'aluminum' }
            },
            {
                gauge: selGauge('750MCM al'),
                in: 2, out: 3, type: ConductorType.DcFeeder,
                contraints: [Constraints.BelowGround],
                defaultWire: { gauge: '750 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
            {
                in: 3, out: 4, type: ConductorType.BusBars,
                contraints: ([] as Constraints[]),
                gauge: selGauge("busbar"),
            }
        ]
    },
    //// Type_3
    //CI_VertTrunkbus: {
    //    displayName: 'Central inverter with single vertical trunkbus',
    //    description: 'The pattern with vertical trunkbus and combiners near with trenches',
    //    tempSchemePatternId: 'BRIGHT_ARROW_THIRD',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        NODES.inverter,
    //        NODES.block_circuit_exit
    //    ],
    //    // C-TrunkBusV-DcFeeder
    //    conductors: [
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 0, out: 1, type: ConductorType.Extender,
    //            contraints: [
    //                Constraints.AboveGround,
    //                Constraints.Horizontal
    //            ],
    //        },
    //        {
    //            gauge: selGauge("350MCM al"),
    //            in: 1, out: 2, type: ConductorType.TrunkBus,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //        },
    //        {
    //            gauge: selGauge('750MCM al'),
    //            in: 2, out: 3, type: ConductorType.DcFeeder,
    //            contraints: [Constraints.BelowGround],
    //        },
    //        {
    //            in: 3, out: 4, type: ConductorType.BusBars,
    //            contraints: ([] as Constraints[]),
    //            gauge: selGauge("busbar"),
    //        }
    //    ]
    //},

    // Type3_v2
    CI_MultVertTrunkbus: {
        displayName: 'Central inverter with vertical trunkbus for each trackers column',
        description: 'The pattern with possible multiple vertical trunkbuses',
        tempSchemePatternId: 'BRIGHT_ARROW_THIRD',
        nodes: [
            NODES.string_exit,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            NODES.inverter,
            NODES.block_circuit_exit
        ],
        // C-TrunkBusV-DcFeeder
        conductors: [
            {
                gauge: selGauge("350MCM al"),
                in: 0, out: 1, type: ConductorType.TrunkBus,
                contraints: [
                    Constraints.AboveGround,
                    Constraints.Vertical
                ],
                defaultWire: { gauge: '#10 AWG', material: 'copper' }
            },
            {
                gauge: selGauge("350MCM al"),
                in: 1, out: 2, type: ConductorType.TrunkBus,
                contraints: [
                    Constraints.Horizontal,
                    Constraints.AboveGround
                ],
                defaultWire: { gauge: '350 kcmil', material: 'aluminum' }
            },
            {
                gauge: selGauge('750MCM al'),
                in: 2, out: 3, type: ConductorType.DcFeeder,
                contraints: [Constraints.BelowGround],
                defaultWire: { gauge: '750 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
            {
                in: 3, out: 4, type: ConductorType.BusBars,
                contraints: ([] as Constraints[]),
                gauge: selGauge("busbar"),
            }
        ]
    },

    //Type_4: {
    //    displayName: 'Type 4',
    //    description: 'The pattern was used in the Milford project',
    //    tempSchemePatternId: 'MILFORD',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.end_of_multiharness,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        // road fix
    //        //NODES.utility,
    //        NODES.inverter,
    //        NODES.block_circuit_exit
    //    ],
    //    // MultiHarness-Extender-Whip-DcFeeder
    //    conductors: [
    //        {
    //            in: 0, out: 1, type: ConductorType.MultiHarness,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 1, out: 2, type: ConductorType.Extender,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ]
    //        },
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 2, out: 3, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Horizontal,
    //                Constraints.BelowGround
    //            ]
    //        },
    //        {
    //            gauge: selGauge("750MCM al"),
    //            in: 3, out: 4, type: ConductorType.DcFeeder,
    //            contraints: [
    //                Constraints.BelowGround
    //            ]
    //        },
    //        {
    //            in: 4, out: 5, type: ConductorType.BusBars,
    //            contraints: ([] as Constraints[]),
    //            gauge: selGauge("busbar"),
    //        }
    //    ]
    //},

    //Type_4v2: {
    //    displayName: 'Type 4v2 with extra Whip',
    //    description: 'The pattern was used in the Milford project',
    //    tempSchemePatternId: 'MILFORD',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.end_of_multiharness,
    //        NODES.end_of_tracker,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        // road fix
    //        //NODES.utility,
    //        NODES.inverter,
    //        NODES.block_circuit_exit
    //    ],
    //    // MultiHarness-Extender-Whip-DcFeeder
    //    conductors: [
    //        {
    //            in: 0, out: 1, type: ConductorType.MultiHarness,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 1, out: 2, type: ConductorType.Extender,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ]
    //        },
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 2, out: 3, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.BelowGround
    //            ]
    //        },
    //        {
    //            gauge: selGauge("#10 cu"),
    //            in: 3, out: 4, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Horizontal,
    //                Constraints.BelowGround
    //            ]
    //        },
    //        {
    //            gauge: selGauge("750MCM al"),
    //            in: 4, out: 5, type: ConductorType.DcFeeder,
    //            contraints: [
    //                Constraints.BelowGround
    //            ]
    //        },
    //        {
    //            in: 5, out: 6, type: ConductorType.BusBars,
    //            contraints: ([] as Constraints[]),
    //            gauge: selGauge("busbar"),
    //        }
    //    ]
    //},

    //Type_4v3:
    CI_Multiharness: {
        displayName: 'Central inverter with multiharnesses',
        description: 'The pattern was used in the Milford project',
        tempSchemePatternId: 'MILFORD',
        nodes: [
            NODES.string_exit,
            NODES.end_of_multiharness,
            NODES.end_of_group,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            // road fix
            //NODES.utility,
            NODES.inverter,
            NODES.block_circuit_exit
        ],
        // MultiHarness-Extender-Whip-DcFeeder
        conductors: [
            {
                in: 0, out: 1, type: ConductorType.MultiHarness,
                contraints: [
                    Constraints.Vertical,
                    Constraints.AboveGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#10 AWG', material: 'copper' }
            },
            {
                gauge: selGauge("#10 cu"),
                in: 1, out: 2, type: ConductorType.Extender,
                contraints: [
                    Constraints.AboveGround
                ],
                defaultWire: { gauge: '#8 AWG', material: 'copper' }
            },
            {
                gauge: selGauge("#10 cu"),
                in: 2, out: 3, type: ConductorType.Whip,
                contraints: [
                    Constraints.BelowGround,
                    Constraints.Vertical
                ],
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                bimConnectTo: 'in',
            },
            {
                gauge: selGauge("#10 cu"),
                in: 3, out: 4, type: ConductorType.Whip,
                contraints: [
                    Constraints.Horizontal,
                    Constraints.BelowGround
                ],
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                bimConnectTo: 'inin',
            },
            {
                gauge: selGauge("750MCM al"),
                in: 4, out: 5, type: ConductorType.DcFeeder,
                contraints: [
                    Constraints.BelowGround
                ],
                defaultWire: { gauge: '750 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
            {
                in: 5, out: 6, type: ConductorType.BusBars,
                contraints: ([] as Constraints[]),
                gauge: selGauge("busbar"),
            }
        ]
    },

    //Type_5: {
    //    displayName: 'Type 5',
    //    description: 'The pattern was used in the Lancaster project',
    //    tempSchemePatternId: 'MILFORD',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.end_of_multiharness,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        NODES.ac_combiner_exit,
    //        NODES.block_circuit_exit
    //    ],
    //    // MultiHarness-Extender-Whip-AcFeeder-AcFeeder
    //    conductors: [
    //        {
    //            in: 0, out: 1, type: ConductorType.MultiHarness,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 1, out: 2, type: ConductorType.Extender,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 2, out: 3, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Horizontal,
    //                Constraints.BelowGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 3, out: 4, type: ConductorType.AcFeeder,
    //            contraints: [Constraints.BelowGround],
    //            gauge: selGauge("250MCM cu"),
    //        },
    //        {
    //            in: 4, out: 5, type: ConductorType.AcFeeder,
    //            contraints: [Constraints.BelowGround],
    //            gauge: selGauge("750MCM al"),
    //        }
    //    ]
    //},

    //Type_5v2: {
    //    displayName: 'Type 5v2 with extra Whip',
    //    description: 'The pattern was used in the Lancaster project',
    //    tempSchemePatternId: 'MILFORD',
    //    nodes: [
    //        NODES.string_exit,
    //        NODES.end_of_multiharness,
    //        NODES.end_of_tracker,
    //        NODES.utility,
    //        NODES.cb_si_circuit_exit,
    //        NODES.ac_combiner_exit,
    //        NODES.block_circuit_exit
    //    ],
    //    // MultiHarness-Extender-Whip-AcFeeder-AcFeeder
    //    conductors: [
    //        {
    //            in: 0, out: 1, type: ConductorType.MultiHarness,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 1, out: 2, type: ConductorType.Extender,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.AboveGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 2, out: 3, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Vertical,
    //                Constraints.BelowGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 3, out: 4, type: ConductorType.Whip,
    //            contraints: [
    //                Constraints.Horizontal,
    //                Constraints.BelowGround
    //            ],
    //            gauge: selGauge("#10 cu"),
    //        },
    //        {
    //            in: 4, out: 5, type: ConductorType.AcFeeder,
    //            contraints: [Constraints.BelowGround],
    //            gauge: selGauge("250MCM cu"),
    //        },
    //        {
    //            in: 5, out: 6, type: ConductorType.AcFeeder,
    //            contraints: [Constraints.BelowGround],
    //            gauge: selGauge("750MCM al"),
    //        }
    //    ]
    //},

    //Type_5v3
    SI_Multiharness: {
        displayName: 'String inverter with Multiharnesses',
        description: 'The pattern was used in the Lancaster project',
        tempSchemePatternId: 'MILFORD',
        nodes: [
            NODES.string_exit,
            NODES.end_of_multiharness,
            NODES.end_of_group,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            NODES.ac_combiner_exit,
            NODES.block_circuit_exit
        ],
        // MultiHarness-Extender-Whip-AcFeeder-AcFeeder
        conductors: [
            {
                in: 0, out: 1, type: ConductorType.MultiHarness,
                contraints: [
                    Constraints.Vertical,
                    Constraints.AboveGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#10 AWG', material: 'copper' }
            },
            {
                in: 1, out: 2, type: ConductorType.Extender,
                contraints: [
                    Constraints.AboveGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' }
            },
            {
                in: 2, out: 3, type: ConductorType.Whip,
                contraints: [
                    Constraints.Vertical,
                    Constraints.BelowGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                bimConnectTo: 'in',
            },
            {
                in: 3, out: 4, type: ConductorType.Whip,
                contraints: [
                    Constraints.Horizontal,
                    Constraints.BelowGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                bimConnectTo: 'inin',
            },
            {
                in: 4, out: 5, type: ConductorType.AcFeeder,
                contraints: [Constraints.BelowGround],
                gauge: selGauge("250MCM cu"),
                defaultWire: { gauge: '250 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
            {
                in: 5, out: 6, type: ConductorType.AcFeeder,
                contraints: [Constraints.BelowGround],
                gauge: selGauge("750MCM al"),
                defaultWire: { gauge: '750 kcmil', material: 'aluminum' },
                bimConnectTo: 'in',
            },
        ]
    },

    // new Pattern
    CI_Multiharness_BusEW: {
        displayName: 'Central inverter -> EW TrunkBus -> Multiharness',
        description: 'Central inverter -> EW TrunkBus -> Multiharness',
        tempSchemePatternId: 'CI_Multiharness_BusEW',
        nodes: [
            NODES.string_exit,
            NODES.end_of_multiharness,
            NODES.utility,
            NODES.cb_si_circuit_exit,
            NODES.inverter,
            NODES.block_circuit_exit
        ],
        conductors: [
            {
                in: 0, out: 1, type: ConductorType.MultiHarness,
                contraints: [
                    Constraints.Vertical,
                    Constraints.AboveGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#10 AWG', material: 'copper' }
            },
            {
                in: 1, out: 2, type: ConductorType.Extender,
                contraints: [
                    Constraints.AboveGround,
                    Constraints.Vertical
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                bimConnectTo: 'in',
            },
            {
                in: 2, out: 3, type: ConductorType.TrunkBus,
                contraints: [
                    Constraints.Horizontal,
                    Constraints.BelowGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                //bimConnectTo: 'in',
            },
            {
                in: 3, out: 4, type: ConductorType.DcFeeder,
                contraints: [
                    Constraints.BelowGround
                ],
                gauge: selGauge("#10 cu"),
                defaultWire: { gauge: '#8 AWG', material: 'copper' },
                //bimConnectTo: 'inin',
            },
            {
                in: 4, out: 5,
                type: ConductorType.BusBars,
                contraints: ([] as Constraints[]),
                gauge: selGauge("busbar"),
            },
        ]
    },
} satisfies Record<string, Pattern>;

export interface ConductorInPattern {
    in: number;
    out: number;
    type: ConductorType;
    contraints: Constraints[];
    gauge: GaugeId;
    defaultWire?: WireProps;

    bimConnectTo?: 'in' | 'out' | 'inin';
}
interface WireProps {
    gauge: string;
    material: string;
}
export interface Pattern {
    displayName: string;
    description: string;
    tempSchemePatternId: string;
    nodes: Node[];
    conductors: ConductorInPattern[];
}
export type PatternName = keyof typeof _PATTERNS;
export const patternNames = Object.keys(_PATTERNS) as Array<PatternName>;
export type PatternStep = Node;
export const PATTERNS = <any>_PATTERNS as { [key in PatternName]: Pattern };

export function getNodeNameByValue(node: PatternStep): NodeName {
  return patternStepNames.find(x => NODES[x] === node)!
}

export interface PatternMaterialsConfig {
    gauge: number;
}

export interface PatternConfig {
    patternId: PatternName;
    pattern: Pattern;
    defaultCables: Array<PatternMaterialsConfig>;
    multiharness?: MultiharnessConfig;
    temperature: number;
    patternInstance: SceneInstance;
}

export interface MultiharnessConfig {
    divs: number[];
    /**
     * If set, both negative and positive multiharnes parts will be
     * of the same length.
     */
    same_length: boolean;
}
