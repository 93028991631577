import type {
	CachingNetworkClient, NetworkLoaderConfig, ObservableObject, UndoStack, VersionedValue} from 'engine-utils-ts';
import { LazyDerived, LogLevel,
	ProjectNetworkClient, ScopedLogger, VersionedInvalidator,
} from 'engine-utils-ts';
import type { UiBindings} from 'ui-bindings';
import { DialogDescription, buildFromLazyConfigObject } from 'ui-bindings';

import type {
	PersistedCollectionConfig, VerDataPersistedCollection,
} from './VerDataPersistedCollection';
import type { NewVersionAdditionalContext, NewVersionDescription, ObjectVersion, ObjectVersionsRange, VerDataStatus} from './VerDataSyncerImpl';
import {
	VerDataSyncerImpl, VerDataSyncStatus,
} from './VerDataSyncerImpl';
import { VerdataUrls } from './VerdataUrls';
import type { ProjectHistory } from './WireProjectHistory';
import type { VerDataState } from './WireVerDataState';

export enum VerDataIdentifier {
	Project = 'Project',
	Catalog = 'Catalog'
}

export interface VerdataSyncConstrParams {
	identifier: string,
	undoStack?: UndoStack,
	verbose?: boolean,
}

export interface NetworkParams {
	networkConfig?: Partial<NetworkLoaderConfig>,
}

export type VerDataSyncerEvent = 'beforeSync' | 'afterSync' | 'afterSaveNewVersion' | 'dispose';

export interface VerDataStateVersion extends VersionedValue  {
	identifier: string;
	haveUpdatesForSave(): boolean;
	saveNewVersion: (descriptionOpt: Partial<NewVersionDescription>) => Promise<void>;
	getCurrentVersionId(): number;
}

interface NewVersionContext {
	needToSave: boolean;
	additionalContext: NewVersionAdditionalContext | null;
}

export class VerDataSyncer implements VerDataStateVersion {

	identifier: string;

	readonly uiBindings: UiBindings;

	private _impl: VerDataSyncerImpl;

	history: ObservableObject<ProjectHistory>;
	status: ObservableObject<VerDataStatus>;
	state: ObservableObject<VerDataState>;

	lazyNewVersionContext: LazyDerived<NewVersionContext>;
	stateToSaveInvalidator: VersionedInvalidator;
	verDataSyncerInvalidator: VersionedInvalidator;
	dependencies: VerDataStateVersion[];
	lastLoadedStateVersion: number;

	private constructor({identifier, impl}: {identifier: string, impl: VerDataSyncerImpl}) {
		if (!(impl instanceof VerDataSyncerImpl)) {
			throw new Error('use newAsync constructor method');
		}
		this.identifier = identifier;
		this._impl = impl;
		this.uiBindings = impl.uiBindings;

		this.history = impl.history;
		this.status = impl.status;
		this.state = impl.state;

		this.stateToSaveInvalidator = new VersionedInvalidator();
		this.dependencies = [];
		this.lastLoadedStateVersion = this.stateToSaveInvalidator.version();
		this.verDataSyncerInvalidator = new VersionedInvalidator([this.stateToSaveInvalidator, this.status]);
		this.status.observeObject({
			settings: {
				immediateMode: true,
			},
			onPatch:({currentValueRef}) => {
				if(currentValueRef.syncStatus === VerDataSyncStatus.Loaded) {				
					this.lastLoadedStateVersion = this.stateToSaveInvalidator.version();
				}
			}
		});

		this.lazyNewVersionContext = LazyDerived.new0(
			'lazy-dependencies-state', 
			this.dependencies, () => {
				let additionalContext: NewVersionAdditionalContext | null = null;
				let needToSave: boolean = false;
				if(this.dependencies.length){
					additionalContext = {
						versionPerIdentifier: new Map()
					}
					const verdataSyncersWithUpdates:VerDataStateVersion[] = [];
					for (const dep of this.dependencies) {
						if(dep.haveUpdatesForSave()){
							verdataSyncersWithUpdates.push(dep);
						}
						if(!additionalContext.versionPerIdentifier.has(dep.identifier)){
							additionalContext.versionPerIdentifier.set(dep.identifier, dep.getCurrentVersionId());
						} else {
							console.error(this.identifier + ' vds have duplicate in dependencies', this.dependencies);
						}
					}
					needToSave = verdataSyncersWithUpdates.length > 0;
				}
			return {
				needToSave,
				additionalContext
			};
		});

		// this.projectState = ObservablesUtils.wrapObservableForApei(impl.projectState);
	}

	static async newAsync(args: VerdataSyncConstrParams): Promise<VerDataSyncer> {

		const undoStack = args.undoStack ?? null;
		const impl = new VerDataSyncerImpl(
			undoStack,
			new ScopedLogger('vds', args.verbose ? LogLevel.Debug : LogLevel.Warn),
			args.identifier
		);
		return new VerDataSyncer({impl, identifier: args.identifier});
	}

	addListener(eventName: VerDataSyncerEvent, callback: Function) {
		return this._impl.namedEvents.legacy_subscribe(eventName, callback);
	}

	async init(projNetworkClient: ProjectNetworkClient) {
		if (!(projNetworkClient instanceof ProjectNetworkClient)) {
			return Promise.reject("provide network client or network settings");
		}
		return this._impl.init(projNetworkClient);
	}

	dispose(): void {
		this._impl.dispose();
	}

	attachCollections(collections: [Partial<PersistedCollectionConfig>, VerDataPersistedCollection<any, any>][]) {
		const promises = collections.map(([config, coll]) => this.attachCollectionForSync(config, coll));
		return Promise.all(promises);
	}

	attachCollectionForSync<T extends Object, Context>(
		config: Partial<PersistedCollectionConfig>,
		collection: VerDataPersistedCollection<T, Context>)
	: Promise<void> {
		this.stateToSaveInvalidator.addDependency(collection.stateInvalidator);
		return this._impl.attachCollectionForSync(config, collection);
	}

	clear() {
		this._impl.clear();
	}

	haveUpdatesForSave(): boolean {
		return this.stateToSaveInvalidator.version() !== this.lastLoadedStateVersion;
	}

	canStartSaveNow(): boolean {
		return this.state.poll().canStartNewTask() && this.haveUpdatesForSave();
	}

	canStartEditVersionNow(): boolean {
		return this.state.poll().canStartNewTask();
	}

	addDependency(dep: VerDataStateVersion){
		this.dependencies.push(dep);
	}

	async saveNewVersion(descriptionOpt: Partial<NewVersionDescription>): Promise<void> {
		const state = this.lazyNewVersionContext.poll();
		if(state.needToSave){
			const ui = buildFromLazyConfigObject({
				configObj: LazyDerived.fromMutatingObject(()=>{ 
					return {
						configSample: {},
						context: undefined
					};
				}),
				patchCallback: () =>{}
			});
			this.uiBindings.dialogsStream.pushNext(new DialogDescription({
				name: 'Do you want to save changes in the Catalog?',
				context: undefined,
				uiSource: ui,
				userActions: [
					{ 
						name: 'yes', 
						action: async () => {
							for (const dep of this.dependencies) {
								await dep.saveNewVersion({});
							}
							await this._impl.saveNewVersion(descriptionOpt || {}, this.lazyNewVersionContext.poll().additionalContext);
						}
					},
					{ 
						name: 'no', 
						action: async () => {
							await this._impl.saveNewVersion(descriptionOpt || {}, this.lazyNewVersionContext.poll().additionalContext);
						}
					}
				]
			}));
		} else {
			await this._impl.saveNewVersion(descriptionOpt || {}, this.lazyNewVersionContext.poll().additionalContext);
		}
	}

	getCurrentVersionId(): number {
		return this.status?.poll()?.activeVersionId ?? 0;
	}

	async loadLastVersion() {
		if (this.status.currentValue().syncStatus == VerDataSyncStatus.None) {
			throw new Error('initialize verdata syncer before loading version');
		}
		const versions = this._impl.history.poll().versions;
		if (!versions || versions.length == 0) {
			this._impl._logger.info('empty history, nothing to load');
			return;
		}
		return this.loadVersion(versions[versions.length - 1].id);
	}
	async loadVersion(versionId: number): Promise<void> {
		await this._impl.loadVersion(versionId);
	}
	findObjects(perCollectionIds: Iterable<[string, number[]]>): Map<string, ObjectVersionsRange[]> {
		return this._impl.findObjects(perCollectionIds);
	}
	findObjectsInCollection(collectionIdent: string, ids: number[]): ObjectVersionsRange[] | undefined {
		const collectionIds:[string, number[]] = [collectionIdent, ids];
		const perCollection = this._impl.findObjects([collectionIds]);
		return perCollection.get(collectionIdent);
	}
	getObjectsIdsInVersion(collectionsIdent: string[], version: number): Map<string, number[]> {
		const perCollection = this._impl.getObjectsIdsInVersion(collectionsIdent, version);
		return perCollection;
	}
	async loadObjects<T extends Object>(collectionIdent: string, objects: ObjectVersion[]): Promise<[number, T][]> {
		return await this._impl.LoadObjects<T>(collectionIdent, objects);
	}
	async loadObjectsVersions<T extends Object>(
		collectionIdent: string, 
		versions: number[], 
	): Promise<[projectVersion: number, objects: [number, T][]][]> {
		return await this._impl.LoadObjectsVersions<T>(collectionIdent, versions);
	}

	getCachingClient(): CachingNetworkClient | null {
		return this._impl.cachingNetClient!;
	}

	async uploadFile(filename: string, file: ArrayBuffer): Promise<string> {
		const nc = this._impl.cachingNetClient?.client;
		if (!nc) {
			throw new Error('network client not initialized');
		}
		const formData = new FormData();
		formData.append("blob", new Blob([file]), filename);
		console.log(formData);
		var response = await (await nc.put(VerdataUrls.uploadFiles(), formData)).json();
		return response[filename];
	}

	async getDebugZip():Promise<ArrayBuffer>{
		const nc = this._impl.cachingNetClient?.client;
		if (!nc) {
			throw new Error('network client not initialized');
		}
		const response = await nc.get(VerdataUrls.debugZip());
		if (response.ok) {
			return await response.arrayBuffer();
		} else {
			throw new Error(response.statusText);
		}
	}

	version(): number {
		return this.verDataSyncerInvalidator.version();
	}
}
