export type DxfPropertyType = string | number

export type PropertyEntry = {
    code: number;
    value: DxfPropertyType;
};

export class DxfProperty {
    readonly code: number;
    private _value: string | number | undefined;

    constructor(code: number, value?: string | number) {
        this.code = code;
        this._value = value;
        
    }

    set value(value: string | number) {
        this._value = value;
    }

    get value(): string | number | undefined {
        return this._value;
    }

    get property(): {code:number, value:string | number} | null{
        if(this.value === undefined){
            return null;
        }else{
            return {code:this.code, value:this.value};
        }
    }

    public appedValue(){
        if (typeof this._value === 'number') {
            this._value++;
        }
    }
}


export class DxfProperties {
    private _properties: PropertyEntry[] = [];

    constructor();
    constructor(prop: PropertyEntry);
    constructor(prop: PropertyEntry[]);
    constructor(
        propertyMap?: PropertyEntry | PropertyEntry[]
    ) {
        if (propertyMap !== undefined) {
            if (Array.isArray(propertyMap)) {
                this._properties = propertyMap;
            } else if ("code" in propertyMap && "value" in propertyMap) {
                this._properties.push(propertyMap as PropertyEntry);
            } 
        }
    }

    private getProperty(code: number): PropertyEntry[] {
        const entries: PropertyEntry[] = [];
        for (const prop of this._properties) {
            if (prop.code === code) {
                entries.push(prop);
            }
        }
        return entries;
    }

    // Getter method to retrieve values for a given code
    get(code: number): DxfPropertyType | string[] | undefined {
        const entries = this.getProperty(code);

        if (entries.length === 0) {
            return undefined;
        }
        const res =
            entries.length > 1
                ? entries.map((entry) => entry.value.toString())
                : entries[0].value;

        return res;
    }
    
    get length():number {
        return this._properties.length;
    }
    remove(code: number, removeAll = false){
        const indexes:number[] = [];
        for(let i = 0; i<this._properties.length; i++){
            if(this._properties[i].code === code){
                indexes.push(i);
                if(!removeAll){
                    break;
                }
            }
        }

        for (let i = indexes.length - 1; i >= 0; i--) {
            this._properties.splice(indexes[i], 1);
        }
    }


    set(code: number, value: string | number, rewrite: boolean = true): void {
        if (!rewrite) {
            this._properties.push({ code, value });
        } else {
            const prop = this.getProperty(code);
            if (prop.length === 0) {
                this._properties.push({ code, value });
            } else {
                prop[0].value = value;
            }
        }
    }

    setAtIndex(index?: number, ...props: PropertyEntry[]) {
        const currentProperties = this.entries();
        // Check if the index is within bounds
        if(index === undefined){
            index = currentProperties.length-1
        }
        if (index < 0 || index > currentProperties.length) {
            throw new Error("Index out of bounds");
        }
        currentProperties.splice(index, 0, ...props);
        this._properties = currentProperties;
    }

    // Method to list all properties
    entries(): PropertyEntry[] {
        return this._properties;
    }
}
