import { IrregularHeightmapGeometry, RegularHeightmapGeometry, TerrainElevation, TerrainTileId, WGSCoord } from "bim-ts";
import { ExecutionThreadPreference, JobExecutor, registerExecutor } from "engine-utils-ts";
import { Aabb2, PointsInPolygonChecker, PolygonUtils, Vector2 } from "math-ts";
import { Matrix4 } from "math-ts";
import { WGSConverter } from "../WGSConverter";


interface CreateGeoTiffExecutorArgs {
    boundary: Vector2[] | undefined,
    yInterval: { min: number, max: number },
    bbox: Aabb2,
    dy: number,
    dx: number,
    width: number,
    datum: string | null,
    tilesSize: number,
    geoTiles: Map<TerrainTileId, RegularHeightmapGeometry>,
    worldMatrix: Matrix4,
}


export class CreateGeoTiffExecutor extends JobExecutor<CreateGeoTiffExecutorArgs, Float32Array> {
	execute(args: CreateGeoTiffExecutorArgs): Float32Array {
        const image = new Float32Array((args.yInterval.max - args.yInterval.min + 1) * args.width);
        const invWM = new Matrix4().getInverse(args.worldMatrix);
        const checker = args.boundary ? new PointsInPolygonChecker([args.boundary], []) : undefined;

        let index = 0;
        let point: Vector2;
        const localPoint = new Vector2();
        for (let iy = args.yInterval.max; iy >= args.yInterval.min; iy--) {
            const y = args.bbox.min.y + iy * args.dy;
            
            for (let ix = 0; ix < args.width; ix++, index++) {
                const x = args.bbox.min.x + ix * args.dx;
    
                image[index] = NaN;
                if (args.datum) {
                    point = WGSConverter.projectWgsToFlatMap(new WGSCoord(y, x)!, args.datum);
                } else {
                    point = new Vector2(x, y);
                }
                if (checker && !checker.isPointInside(point)) {
                    continue;
                }
                
                localPoint.copy(point).applyMatrix4(invWM);
                
                const tileId = TerrainTileId.newFromPoint(localPoint, args.tilesSize);
                const tile = args.geoTiles.get(tileId);

                if (tile !== undefined) {
                    const tileOffset = tileId.localOffset(args.tilesSize);
                    const elevation = tile.sampleInLocalSpaceSingular(localPoint.sub(tileOffset));

                    if (elevation.distToRealSample < 0.5 * tile.segmentSizeInMeters) {
                        image[index] = elevation.elevation! + args.worldMatrix.elements[14];
                    }
                }
            }
        }

        return image;
	}

	executionPreference(_: CreateGeoTiffExecutorArgs): ExecutionThreadPreference {
        return ExecutionThreadPreference.WorkerThread;
	}
}
registerExecutor(CreateGeoTiffExecutor);