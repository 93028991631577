import type { Bim } from "../Bim";
import type { SceneInstanceShapeMigration } from "../scene/SceneInstancesArhetypes";
import type { BimPropertyData } from "../bimDescriptions/BimProperty";
import { BimProperty } from "../bimDescriptions/BimProperty";
import { Failure, IterUtils, LazyDerived, ObjectUtils, Success } from "engine-utils-ts";
import { SolverObjectInstance } from "../runtime/SolverObjectInstance";
import type { NamedBimPropertiesGroup} from "../bimDescriptions/NamedBimPropertiesGroup";
import { extractValueUnitPropsGroup, flattenNamedPropsGroups } from "../bimDescriptions/NamedBimPropertiesGroup";
import type { PropertiesGroupFormatters } from "../bimDescriptions/PropertiesGroupFormatter";
import { PropertiesGroupFormatter } from "../bimDescriptions/PropertiesGroupFormatter";
import type { CostsConfigProvider, ExpandLegacyPropsWithCostTableLinks} from "src/cost-model/capital";
import { createFocusLinkOnSample, mergeCostComponents, sumCostComponents } from "src/cost-model/capital";
import { DcWireIdProps, createDefaultDCCostsPerMeter } from "src/cost-model/capital/tables/categories/electrical-subtotal/lv";
import { InstanceCostsGlobal } from "./EquipmentCommon";
import { PUI_GroupNode } from "ui-bindings";
import type { AssetId, BasicAnalyticalRepresentation, Catalog, SceneInstance} from "../";
import { NumberProperty, PolylineGeometry, PropsFieldArrayOf, PropsGroupBase, PropsGroupsRegistry, StringProperty } from "../";
import { SolverObjectInstanceWithChildren } from "src/runtime/SolverObjectInstanceWithChildren";
import { AnyKeyPropsGroup } from "src/properties/Props";
import { lvWireStds } from '../constants';


export const LvWireTypeIdent = 'lv-wire';

export class NestedLvWiresGroup extends PropsGroupBase {
    length: NumberProperty
    material: StringProperty
    type: StringProperty
    gauge: StringProperty
    losses: NumberProperty
    _power: NumberProperty;
    voltage_drop: NumberProperty;
    resistivity: NumberProperty;

    constructor(args: Partial<NestedLvWiresGroup>) {
        super();
        this.length = args.length ?? NumberProperty.new({ value: 0, unit: 'm', isReadonly: true });
        this.material = args.material ?? StringProperty.new({ value: '', isReadonly: true  });
        this.type = args.type ?? StringProperty.new({ value: '', isReadonly: true  });
        this.gauge = args.gauge ?? StringProperty.new({ value: '', isReadonly: true  });
        this.losses = args.losses ?? NumberProperty.new({ value: 0, unit: 'W', isReadonly: true  });
        this._power = args._power ?? NumberProperty.new({ value: 0, unit: 'W', isReadonly: true  });
        this.voltage_drop = args.voltage_drop ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true  });
        this.resistivity = args.resistivity ?? NumberProperty.new({ value: 0, unit: 'Ohm/kft', isReadonly: true  });
    }
}

PropsGroupsRegistry.register({
    class: NestedLvWiresGroup,
    complexDefaults: {}
});

export class NestedLvWires extends PropsGroupBase {
    _wires: NestedLvWiresGroup[]
    get wires() {
        const obj = new AnyKeyPropsGroup()
        for (const group of this._wires) {
            const title = `${group.gauge.value} ${group.material.value} ${group.type.value}`;
            obj[title] = group;
        }
        return obj
    }
    total_losses: NumberProperty;
    av_voltage_drop: NumberProperty;
    constructor(args: Partial<NestedLvWires>) {
        super()
        this._wires = args._wires ?? [];
        this.total_losses = args.total_losses ?? NumberProperty.new({ value: 0, unit: 'W', isReadonly: true });
        this.av_voltage_drop = args.av_voltage_drop ?? NumberProperty.new({ value: 0, unit: '%', isReadonly: true });
    }
}
PropsGroupsRegistry.register({
    class: NestedLvWires,
    complexDefaults: {
        _wires: new PropsFieldArrayOf({}, NestedLvWiresGroup),
        wires: new PropsFieldArrayOf({}, AnyKeyPropsGroup),
    }
});


export class LvWireProps extends PropsGroupBase {
    constructor(args: Partial<LvWireProps>) {
        super()
    }
}

PropsGroupsRegistry.register({
    class: LvWireProps,
    complexDefaults: {}
});

function registerNestedLvWiringAggregation(bim: Bim) {
    const allIdentifiers = [
        'any-tracker', 'combiner-box', 'inverter',
        'transformer', 'sectionalizing-cabinet',
        'mv-wire', 'substation', 'fixed-tilt', 'tracker'
    ]

    const LvWireDefaultProps = {
        type: new BimProperty({ path: ['specification', 'type'], value: '' }),
        material: new BimProperty({ path: ['specification', 'material'], value: '' }),
        gauge: new BimProperty({ path: ['specification', 'gauge'], value: '' }),
        length: new BimProperty({ path: ['computed_result', 'length'], value: 0, unit: 'm' }),
        losses: new BimProperty({ path: ['computed_result', 'losses'], value: 0, unit: 'W' }),
        power: new BimProperty({ path: ['computed_result', 'power'], value: 0, unit: 'W' }),
        phase: new BimProperty({ path: ['specification', 'phase'], value: '' }),
        resistivity: new BimProperty({ path: ['specification', 'resistivity'], value: 0, unit: 'Ohm/kft' }),
    } satisfies NamedBimPropertiesGroup;

    const zeroPower = NumberProperty.new({ value: 0, unit: 'W', isReadonly: true });

    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstanceWithChildren({
        solverIdentifier: 'sum-lv-wiring-specs',
        objectsDefaultArgs: {
            propsInOut: new AnyKeyPropsGroup(),
            legacyProps: {
                dcPower: new BimProperty({ path: ['circuit', 'aggregated_capacity', 'dc_power'], value: 0, unit: 'W' }),
            },
        },
        objectsIdentifier: allIdentifiers,
        solverFunction: (obj, globalArgs, [lvWires, ...other]) => {
            const propsInOut = obj.propsInOut;

            const prevNestedLvWires = propsInOut['lv_wiring'];
            if (prevNestedLvWires && !(prevNestedLvWires instanceof NestedLvWires)) {
                return {};
            }

            const groups: NestedLvWiresGroup[] = []

            for (const lvWire of lvWires) {
                const legacyProps = lvWire.legacyProps as typeof LvWireDefaultProps;
                const phase = legacyProps.phase.asText();
                groups.push(new NestedLvWiresGroup({
                    type: StringProperty.new({ value: legacyProps.type.asText(), isReadonly: true }),
                    gauge: StringProperty.new({ value: legacyProps.gauge.asText(), isReadonly: true }),
                    material: StringProperty.new({ value: legacyProps.material.asText(), isReadonly: true }),
                    length: NumberProperty.new({ ...legacyProps.length.asNumberWithUnit(), isReadonly: true }),
                    losses: NumberProperty.new({ ...legacyProps.losses.asNumberWithUnit(), isReadonly: true }),
                    _power: phase !== 'phase 1' && phase !== 'negative' ? zeroPower : NumberProperty.new({ ...legacyProps.power.asNumberWithUnit(), isReadonly: true }),
                    resistivity: NumberProperty.new({ ...legacyProps.resistivity.asNumberWithUnit(), isReadonly: true })
                }))
            }

            for (const group of other) {
                for (const obj of group) {
                    const nestedLvWires = obj.propsInOut?.getAtPath(['lv_wiring']);
                    if (!(nestedLvWires instanceof NestedLvWires)) {
                        continue;
                    }
                    groups.push(...nestedLvWires._wires);
                }
            }

            const grouped = IterUtils.groupBy(
                groups,
                (x) => `${x.gauge.value}/${x.material.value}/${x.type.value}`,
            )


            const _wires: NestedLvWiresGroup[] = [];
            for (const [_, g] of grouped) {
                if (!g.length) {
                    continue;
                }
                const sumLength = NumberProperty.unitBasedSum(g.map(x => x.length));
                const sumLosses = NumberProperty.unitBasedSum(g.map(x => x.losses));
                const sumPower = NumberProperty.unitBasedSum(g.map(x => x._power));
                const sample = g[0];
                const newItem = new NestedLvWiresGroup({
                    material: sample.material,
                    type: sample.type,
                    gauge: sample.gauge,
                    length: sumLength instanceof Success
                        ? NumberProperty.new({ ...sumLength.value, isReadonly: true })
                        : NumberProperty.new({ value: 0, unit: 'm', isReadonly: true }),
                    losses: sumLosses instanceof Success
                        ? NumberProperty.new({ ...sumLosses.value, isReadonly: true })
                        : zeroPower,
                    _power: sumPower instanceof Success
                        ? NumberProperty.new({ ...sumPower.value, isReadonly: true })
                        : zeroPower,
                    resistivity: sample.resistivity,
                });
                let voltageDrop = newItem.losses.as('W') / newItem._power.as('W');
                voltageDrop = Number.isFinite(voltageDrop) ? voltageDrop : 0;
                newItem.voltage_drop = NumberProperty.new({ value: voltageDrop * 100, unit: '%', isReadonly: true })
                _wires.push(newItem);
            }

            if (!_wires.length) {
                propsInOut['lv_wiring'] = null;
                return {}
            }

            const lv_wiring = new NestedLvWires({ _wires });
            const totalLossesR = NumberProperty.unitBasedSumV2(_wires.map(x => x.losses));
            const totalPowerWatt = obj.legacyProps.dcPower.as('W')
            const totalLosses = totalLossesR instanceof Success ? NumberProperty.new({ ...totalLossesR.value, isReadonly: true }) : zeroPower;
            const totalPower = NumberProperty.new({ value: totalPowerWatt, unit: 'W', isReadonly: true })
            const avVoltageDropValue = 100 * totalLosses.as('W') / totalPower.as('W');
            const voltageDrop = NumberProperty.new({
                value: avVoltageDropValue || 0,
                unit: '%',
                isReadonly: true,
            })
            lv_wiring.av_voltage_drop = voltageDrop;
            lv_wiring.total_losses = totalLosses;

            propsInOut['lv_wiring'] = lv_wiring;

            return {}

        },
        childrenIdWithArgs: [
            {
                identifier: 'lv-wire',
                newPropsDefaults: new AnyKeyPropsGroup(),
                legacyPropsDefaults: LvWireDefaultProps,
            },
            ...allIdentifiers.map(identifier => ({
                identifier,
                newPropsDefaults: new AnyKeyPropsGroup(),
                legacyPropsDefaults: {}
            }))
        ],
    }))
}

export function registerLvWire(bim: Bim){
	bim.instances.archetypes.registerArchetype(
		{
			type_identifier: LvWireTypeIdent,
			mandatoryProps: [
                { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", },
                { path: ['cost_bs', 'level 2'], value: "DC", },
			],
			propsShapeMigrations: migrations(),
			propsClass: LvWireProps,
		}
	);
	registerNestedLvWiringAggregation(bim);
	registerLvWireLengthSolver(bim);
	registerLvWireLossDropSolver(bim);
}

export function registerLvWireCatalogRelatedSolvers(bim: Bim, catalog: Catalog) {
    registerLvWireSpecificationSolver(bim, catalog);
}



function migrations() : SceneInstanceShapeMigration[]{
	return [
		{
			toVersion: 1,
			validation: {
				updatedProps: [
					{path: ['cost_bs', 'level 1']},
					{path: ['cost_bs', 'level 2']},
				],
				deletedProps: [],
			},
			patch: (inst)=>{
				inst.properties.applyPatch([
					['cost_bs | level 1', { path: ['cost_bs', 'level 1'], value: "ELECTRICAL SUBTOTAL", }],
					['cost_bs | level 2', { path: ['cost_bs', 'level 2'], value: "DC", }],
				])
			}
		}
	]
}

export const LvWirePricingRelatedPropsExtra = {
    length: BimProperty.NewShared({
        path: ['computed_result', 'length'],
        value: 0,
        unit: 'ft',
    }),
    type: BimProperty.NewShared({
        path: ['specification', 'type'],
        value: '',
    }),
};

const LvWireSpecPricingArgs = {
    gauge: BimProperty.NewShared({
        path: ['specification', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['specification', 'material'],
        value: 'unknown_material'
    }),
};

export const priceRelatedPropsGrouping = {
    LvWireSpecPricingArgs,
    LvWirePricingRelatedPropsExtra
}

export function registerLvWirePriceSolver(bim: Bim, costs: CostsConfigProvider) {
    const global = LazyDerived.new1(
        'global',
        [bim.unitsMapper],
        [costs.lazyInstanceCostsByType(LvWireTypeIdent)],
        ([costs]) => {
            return new InstanceCostsGlobal(costs, bim.unitsMapper);
        }
    )

    const LvWirePricingSharedArgGlobaIdent = 'lv-wire-pricing-shared-arg-global-ident';
    bim.runtimeGlobals.registerByIdent(LvWirePricingSharedArgGlobaIdent, global)

    const [flattenedProps, unflatten] = flattenNamedPropsGroups({
        LvWirePricingRelatedPropsExtra,
        DcWireIdProps,
        LvWireSpecProps,
    });
    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        cache: true,
        solverIdentifier: 'lv-wire-pricing-solver',
        objectsDefaultArgs: {
            legacyProps: flattenedProps,
        },
        objectsIdentifier: LvWireTypeIdent,
        globalArgsSelector: {
            [LvWirePricingSharedArgGlobaIdent]: InstanceCostsGlobal,
        },
        solverFunction: (props, globals) => {
            const legacyPropsGroups = unflatten(props.legacyProps);
            const sharedReply = globals[LvWirePricingSharedArgGlobaIdent];
            if (sharedReply instanceof Failure) {
                return {}
            }
            const shared = sharedReply.value;
            const currentIdProps = extractValueUnitPropsGroup(legacyPropsGroups.DcWireIdProps);
            const costPerMeter = sumCostComponents(mergeCostComponents(
                shared.costs.find(x => ObjectUtils.areObjectsEqual(x.props, currentIdProps))?.costs,
                createDefaultDCCostsPerMeter(),
            ));

            const extraProps = legacyPropsGroups.LvWirePricingRelatedPropsExtra;
            const length = extraProps.length.as('m');
            const cableType = extraProps.type.asText() || 'generic';


            let fullCost = length * costPerMeter;
            fullCost = isFinite(fullCost) ? fullCost : 0;


            const ref = bim.keyPropertiesGroupFormatter.formatNamedProps(LvWireTypeIdent, legacyPropsGroups.LvWireSpecProps)

            const patch: BimPropertyData[] = [
                {
                    path: ['cost', 'cost_item'],
                    value: ref ?? 'unknown',
                    readonly: true,
                },
                {
                    path: ['cost', 'cable_type'],
                    value: cableType,
                    readonly: true,
                },
                {
                    path: ['cost', 'total_cost'],
                    unit: 'usd',
                    value: fullCost,
                    readonly: true,
                },
            ];
            return {
                legacyProps: patch,
                removeProps: [
                    BimProperty.MergedPath(['cost', 'status']),
                    BimProperty.MergedPath(['cost', 'has_zero_costs']),
                    BimProperty.MergedPath(['cost', 'length_unit']),
                    BimProperty.MergedPath(['cost', 'per_length_cost']),
                ]
            };
        }
    }));
}

export function registerLvWireLengthSolver(bim: Bim) {
    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        solverIdentifier: 'lv-wire-length-solver',
        objectsDefaultArgs: {
            representationAnalytical: null as BasicAnalyticalRepresentation | null,
        },
        objectsIdentifier: LvWireTypeIdent,
        solverFunction: (props, globals) => {
            let lengthMeters = 0;
            if (props.representationAnalytical?.geometryId) {
                const geometry = bim.allBimGeometries.peekById(props.representationAnalytical.geometryId)
                if (geometry instanceof PolylineGeometry) {
                    lengthMeters = geometry.length();
                }
            }

            const patch: BimPropertyData[] = [
                {
                    path: ['computed_result', 'length'],
                    value: lengthMeters,
                    unit: 'm',
                    readonly: true,
                },
            ];
            return {
                legacyProps: patch,
            };
        }
    }));
}

export function registerLvWireSpecificationSolver(bim: Bim, catalog: Catalog) {
    const globalIdent = 'instance-per-asset-id';
    const instancePerAssetId = LazyDerived.new0(
        globalIdent,
        [catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId],
        () => {
            const result = new Map<AssetId, SceneInstance>()
            for (const assetId of catalog.assets.sceneInstancePerAsset.assetIdToSceneInstanceId.poll().keys()) {
                const sceneInstance = catalog.assets.sceneInstancePerAsset.getAssetAsSceneInstance(assetId);
                if (sceneInstance) {
                    result.set(assetId, sceneInstance)
                }
            }
            return new SceneInstancePerAssetGlobal(result);
        }
    )
    bim.runtimeGlobals.registerByIdent(globalIdent, instancePerAssetId)
    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        cache: true,
        solverIdentifier: 'lv-wire-specification-solver',
        objectsDefaultArgs: {
            legacyProps: {
                specAssetId: new BimProperty({ path: ['specification', 'asset'], value: -1 }),
                temperature: new BimProperty({ path: ['specification', 'temperature'], value: 0 }),
                isBuried: new BimProperty({ path: ['specification', 'isBuried'], value: false }),
                type: new BimProperty({ path: ['specification', 'type'], value: '' }),
            }
        },
        globalArgsSelector: {
            [globalIdent]: SceneInstancePerAssetGlobal
        },
        objectsIdentifier: LvWireTypeIdent,
        solverFunction: (props, globals) => {
            const result = globals[globalIdent];
            if (!(result instanceof Success)) {
                return {};
            }
            const map = result.value.map;
            const instance = map.get(props.legacyProps.specAssetId.asNumber())
            if (!instance) {
                return {}
            }
            const assetSpec = instance.properties.extractPropertiesGroup({
                gauge: new BimProperty({ path: ['wire', 'gauge'], value: 'unknown' }),
                material: new BimProperty({ path: ['wire', 'material'], value: 'unknown' })
            })

            const assetName = assetSpec.gauge.asText() + ' ' + assetSpec.material.asText();

            const gaugeObj = lvWireStds.flatMap(x => x[1])
                .find(x => x.material === assetSpec.material.asText() && assetSpec.gauge.asText() === x.name);
            if (!gaugeObj) {
                return {}
            }

            const temperature = NumberProperty.new({ ...props.legacyProps.temperature.asNumberWithUnit() })

            const resistivity = props.legacyProps.type.asText() === 'AcFeeder'
                ? gaugeObj.approxDcResistivityByTemperature(temperature)
                : gaugeObj.approxAcResistivityByTemperature(temperature)
            const ampacity = gaugeObj.approxAmpacityByTemperature(temperature, props.legacyProps.isBuried.asBoolean())

            const patch: BimPropertyData[] = [
                { value: assetSpec.material.asText(), path: ['specification', 'material'] },
                { value: assetSpec.gauge.asText(), path: ['specification', 'gauge'] },
                { value: assetName, path: ['specification', 'assetName'] },
                { value: resistivity.value, unit: resistivity.unit, path: ['specification', 'resistivity'] },
                { value: gaugeObj.stc.reactance.value, unit: gaugeObj.stc.reactance.unit, path: ['specification', 'reactance'] },
                { value: ampacity?.value ?? 0, unit: 'A', path: ['specification', 'ampacity'] },
            ];

            return {
                legacyProps: patch,
            };
        }
    }));
}

export function registerLvWireLossDropSolver(bim: Bim) {
    bim.reactiveRuntimes.registerRuntimeSolver(new SolverObjectInstance({
        cache: true,
        solverIdentifier: 'lv-wire-loss-drop-solver',
        objectsDefaultArgs: {
            legacyProps: {
                length: new BimProperty({ path: ['computed_result', 'length'], value: 0, unit: 'm' }),
                current: new BimProperty({ path: ['computed_result', 'operating_current'], value: 0, unit: 'A' }),
                resistivity: new BimProperty({ path: ['specification', 'resistivity'], value: 0, unit: 'Om/kft' }),
                power: new BimProperty({ path: ['computed_result', 'power'], value: 0, unit: 'W' }),
                mergingLength: new BimProperty({ path: ['computed_result', 'merging_length'], value: 0, unit: 'm' }),
                mergingGroups: new BimProperty({ path: ['computed_result', 'merging_groups'], value: 0 }),
            }
        },
        objectsIdentifier: LvWireTypeIdent,
        solverFunction: (props, globals) => {

            const I = props.legacyProps.current.as('A');
            const L = props.legacyProps.length.as('m');
            const R = props.legacyProps.resistivity.as('Om/m');
            const losses = props.legacyProps.mergingLength.as('m') === 0
                ? findBasicLossts(I, L, R)
                : findMerginLosses(
                    I,
                    props.legacyProps.mergingLength.as('m'),
                    props.legacyProps.mergingGroups.asNumber(),
                    R,
                    L
                )

            const drop = losses / props.legacyProps.power.as('W');

            const patch: BimPropertyData[] = [
                { value: losses, unit: 'W', path: ['computed_result', 'losses'] },
                { value: drop*100, unit: '%', path: ['computed_result', 'voltage_drop'] },
            ];
            return {
                legacyProps: patch,
            };
        }
    }));
}

function findBasicLossts(
    currentAmp: number,
    lengthMeters: number,
    resistivityOhmPerMeter: number,
) {
    return currentAmp**2 * lengthMeters * resistivityOhmPerMeter;
}

function findMerginLosses(
    totalCurrentAmp: number,
    mergingLengthMeters: number,
    mergingSegments: number,
    resistivityOhmPerMeter: number,
    totalLengthMeter: number,
) {
    const R = resistivityOhmPerMeter;
    const segmentLength = mergingLengthMeters / (mergingSegments - 1);
    let lossesWatt = findBasicLossts(totalCurrentAmp, Math.max(0, totalLengthMeter - mergingLengthMeters), R);
    for (let i = 0; i < mergingSegments - 1; i++) {
        const I = (i + 1) * totalCurrentAmp/mergingSegments;
        lossesWatt += findBasicLossts(I, segmentLength, R);
    }
    return lossesWatt;
}


export const LvWireSpecProps = {
    gauge: BimProperty.NewShared({
        path: ['specification', 'gauge'],
        value: 'unknown_model'
    }),
    material: BimProperty.NewShared({
        path: ['specification', 'material'],
        value: 'unknown_material'
    }),
};

export function registerLvWireKeyPropertiesGroupFormatter(group: PropertiesGroupFormatters) {
    group.register(
        LvWireTypeIdent,
        new PropertiesGroupFormatter(
            LvWireSpecProps,
            (props) => {
                return Array.from(new Set([
                    props.gauge,
                    props.material
                ].map(x => x.asText()))).join(' ');
            }
        )
    )
}

export const expandLvLegacyPropsWithCostTableLinks: ExpandLegacyPropsWithCostTableLinks = (params) => {
    const sis = Array.from(params.bim.instances.peekByIds(params.ids).values());
    const groupByModule = Array.from(IterUtils.groupBy(
        sis,
        (o) => {
            const props = o.properties.extractPropertiesGroup(DcWireIdProps)
            return [props.gauge.asText(), props.material.asText(), props.type.asText()].join('/');
        },
    ))
    if (groupByModule.length !== 1) {
        return;
    }
    createFocusLinkOnSample({
        costModelFocusApi: params.costModelFocusApi,
        sample: sis[0],
        targetPui: PUI_GroupNode.tryGetNestedChild(params.pui, ['cost']),
        label: 'Setup wire costs'
    })
}

export class SceneInstancePerAssetGlobal {
    constructor(
        public map: Map<AssetId, SceneInstance>,
    ) {}
}

